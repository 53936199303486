import React, { useEffect, useState } from "react";
import { Label } from "reactstrap";
import { withTranslation } from "react-i18next";
import AsyncSelect from "react-select/async";
import "./style.scss";

const AsyncSelectCustom = ({
  item,
  error,
  optionsCallback,
  handleChange,
  handleError,
  t,
}) => {
  const [err, setError] = useState(error);

  useEffect(() => {
    setError(error);
  });
  console.log(err, item.conditionTitle);
  return (
    <div
      className={`async-select-wrapper ${err ? "error" : ""}`}
      style={{ marginBottom: "10px" }}
    >
      <Label for={item.conditionTitle}>
        {" "}
        {t(`widget.condition_title.${item.conditionTitle.toLowerCase()}`)}
      </Label>
      <AsyncSelect
        defaultValue={{
          value: item.conditionValue,
          label: item.conditionValue,
        }}
        cacheOptions
        required
        loadOptions={optionsCallback}
        defaultOptions
        onChange={(e) => handleChange(e, item)}
        placeholder={t("all.meter.meter_serial")}
        onFocus={() => handleError(item.conditionTitle)}
      />
    </div>
  );
};

const tr = withTranslation()(AsyncSelectCustom);
export default tr;
