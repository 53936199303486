import React from "react";

const SvgAjoutBleu = (props: any) => (
  <svg
    version="1.1"
    id="Calque_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 25.2 29.8"
    {...props}
  >
    <g>
      <g>
        <polygon className="st0" points="14.3,3.7 11,4.6 14.3,4.6 		" />
        <path
          className="st0"
          d="M1.6,29.8h10.6c-0.1-0.3-0.1-0.6-0.1-0.9v-0.4H1.6c-0.1,0-0.2-0.1-0.2-0.2v-22c0-0.1,0-0.1,0.1-0.1
                    c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1l14.1-4.5c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0.1,0.1,0.1,0.2v3.7c0,0.4,0.3,0.7,0.7,0.7
                    h3.1c0.1,0,0.2,0.1,0.2,0.2v13.1l1.4-1.4V6.2c0-0.9-0.7-1.6-1.6-1.6h-2.4v-3c0-0.5-0.2-1-0.6-1.3c-0.4-0.3-0.9-0.4-1.4-0.3
                    L1.3,4.5C1,4.6,0.7,4.8,0.5,5C0.2,5.3,0,5.7,0,6.2v22C0,29.1,0.7,29.8,1.6,29.8z"
        />
      </g>
      <path
        className="st0"
        d="M25,19.5c-0.3-0.3-0.7-0.3-1,0l-7.6,7.6v-2.4c0-0.4-0.3-0.7-0.7-0.7s-0.7,0.3-0.7,0.7v4.1
            c0,0.4,0.3,0.7,0.7,0.7h4.1c0.4,0,0.7-0.3,0.7-0.7c0-0.3-0.2-0.6-0.5-0.7c-0.1,0-0.2,0-0.2,0h-2.4l7.6-7.7
            C25.2,20.2,25.2,19.7,25,19.5z"
      />
    </g>
  </svg>
);

export default SvgAjoutBleu;
