import moment from "moment";
import fileDownload from "js-file-download";
import { pdiConstants } from "../_constants";
import pdiService from "../_services/pdi.service";
import _ from "lodash";

/**
 * Récupère tous les PDIs du site courant
 *
 * @method getAll
 * @param siteId Id site
 * @returns {Object} Les PDIs
 */
function getAll(siteId: number) {
  function request() {
    return { type: pdiConstants.GETALL_REQUEST };
  }
  function success(pdis: Object) {
    return { type: pdiConstants.GETALL_SUCCESS, pdis };
  }
  function failure(error: Object) {
    return { type: pdiConstants.GETALL_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    pdiService
      .getAll(siteId)
      .then((pdis: Object) => dispatch(success(pdis)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function getFullPdi(pdiId: number) {
  function request() {
    return { type: pdiConstants.GET_FULL_REQUEST };
  }
  function success(pdi: Object) {
    return { type: pdiConstants.GET_FULL_SUCCESS, pdi };
  }
  function failure(error: Object) {
    return { type: pdiConstants.GET_FULL_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    pdiService
      .getFullPdi(pdiId)
      .then((pdi: Object) => dispatch(success(pdi)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

/**
 * Récupère tous les PDIs du site courant
 *
 * @method getAllWithChildren
 * @param siteId Id site
 * @returns {Object} Les PDIs
 */
function getAllWithChildren(siteId: number, limit: number) {
  function request() {
    return { type: pdiConstants.GETALLWITHCHILDREN_REQUEST };
  }
  function success(pdis: Object) {
    return { type: pdiConstants.GETALLWITHCHILDREN_SUCCESS, pdis };
  }
  function failure(error: Object) {
    return { type: pdiConstants.GETALLWITHCHILDREN_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    pdiService
      .getAllWithChildren(siteId, limit)
      .then((pdis: Object) => dispatch(success(pdis)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

/**
 * Récupère tous les PDIs du site courant
 *
 * @method getAllWithChildren
 * @param siteId Id site
 * @returns {Object} Les PDIs
 */
function getAllWithChildrenWithRangeDate(siteId: number, endDate: any) {
  function request() {
    return { type: pdiConstants.GETALLWITHCHILDREN_RANGEDATE_REQUEST };
  }
  function success(pdis: Object) {
    return { type: pdiConstants.GETALLWITHCHILDREN_RANGEDATE_SUCCESS, pdis };
  }
  function failure(error: Object) {
    return { type: pdiConstants.GETALLWITHCHILDREN_RANGEDATE_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    pdiService
      .getAllWithChildrenWithRangeDate(siteId, endDate)
      .then((pdis: Object) => dispatch(success(pdis)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}
/**
 * Récupère tous les PDIs du site courant
 *
 * @method getAllWithChildren
 * @param siteId Id site
 * @param filterClause
 * @returns {Object} Les PDIs
 */
function getAllWithChildrenWithFilters(
  siteId: number,
  filterClause: any,
  limit: number
) {
  function request() {
    return { type: pdiConstants.GETALLWITHCHILDREN_WITHFILTERS_REQUEST };
  }
  function success(pdis: Object) {
    return { type: pdiConstants.GETALLWITHCHILDREN_WITHFILTERS_SUCCESS, pdis };
  }
  function failure(error: Object) {
    return { type: pdiConstants.GETALLWITHCHILDREN_WITHFILTERS_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    pdiService
      .getAllWithChildrenFilters(siteId, filterClause, limit)
      .then((pdis: Object) => dispatch(success(pdis)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function getRemotePdi(
  siteId: number,
  filterClause: any,
  sizePerPage: number,
  currentPage: number,
  separatorWhereAnd?: boolean,
  orderTable?: any,
  fastFilters?: any,
  columnDisplay?: any,
  ascMode?: boolean,
  launchRequestId?: number,
  context?: any,
  meterNotInRound?: boolean
) {
  function request() {
    return {
      type: pdiConstants.GET_REMOTE_PDI_REQUEST,
      launchRequestId: launchRequestId || -1,
    };
  }
  function success(pdis: Object) {
    return {
      type: pdiConstants.GET_REMOTE_PDI_SUCCESS,
      pdis,
      launchRequestId: launchRequestId || -1,
      context,
    };
  }
  function failure(error: Object) {
    return { type: pdiConstants.GET_REMOTE_PDI_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    if (context === "radio") {
      filterClause.push({
        filter: {
          label: "Numéro de série radio",
          value: "radio.serial",
          type: "text",
        },
        operator: {
          label: "N'est pas vide",
          value: "notVide",
          noEntry: true,
        },
        userInput: null,
        num: 0,
        filterApplied: true,
      });
    }
    pdiService
      .getRemotePdi(
        siteId,
        filterClause,
        sizePerPage,
        currentPage,
        orderTable,
        fastFilters,
        columnDisplay,
        ascMode,
        separatorWhereAnd,
        meterNotInRound
      )
      .then((pdis: Object) => dispatch(success(pdis)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

let intervalRemotePdiId: NodeJS.Timeout | null = null;
function getRemotePdiCsvStatus(siteId: number) {
  function request() {
    return { type: pdiConstants.EXPORT_PDI_CSV_STATUS_REQUEST };
  }
  function success(exports: Object) {
    return { type: pdiConstants.EXPORT_PDI_CSV_STATUS_SUCCESS, exports };
  }
  function failure(error: Object) {
    return { type: pdiConstants.EXPORT_PDI_CSV_STATUS_FAILURE, error };
  }

  return (dispatch: Function) => {
    dispatch(request());
    pdiService
      .getRemotePdiCsvStatus(siteId)
      .then((exportType: any) => {
        if (_.isNull(intervalRemotePdiId)) {
          intervalRemotePdiId = setInterval(() => {
            dispatch(getRemotePdiCsvStatus(siteId));
          }, 10000);
        }
        if (_.get(exportType, "csv") != null) {
          dispatch(success(exportType));
          const date = moment().format("YYYYMMDD_HHmm");
          fileDownload(exportType.csv, `export_${date}.csv`);
          clearInterval(intervalRemotePdiId);
          intervalRemotePdiId = null;
        }
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function exportPdiCsv(
  siteId: number,
  filterClause: any,
  orderTable: any,
  fastFilters: any,
  columnsDisplay: any,
  listAlarms: any,
  optionOr: boolean
) {
  function request() {
    return { type: pdiConstants.EXPORT_PDI_CSV_REQUEST };
  }
  function failure(error: Object) {
    return { type: pdiConstants.EXPORT_PDI_CSV_FAILURE, error };
  }

  return (dispatch: Function) => {
    dispatch(request());
    pdiService
      .getRemotePdiCsv(
        siteId,
        filterClause,
        orderTable,
        fastFilters,
        columnsDisplay,
        listAlarms,
        optionOr
      )
      .then(() => {
        dispatch(getRemotePdiCsvStatus(siteId));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function exportPdiDat(
  siteId: number,
  exportType: string,
  filterClause: any,
  orderTable: any,
  fastFilters: any,
  optionOr: boolean
) {
  function request() {
    return { type: pdiConstants.EXPORT_PDI_DAT_REQUEST };
  }
  function success(exports: Object) {
    return { type: pdiConstants.EXPORT_PDI_DAT_SUCCESS, exports };
  }
  function failure(error: Object) {
    return { type: pdiConstants.EXPORT_PDI_DAT_FAILURE, error };
  }

  return (dispatch: Function) => {
    dispatch(request());
    pdiService
      .getRemotePdiDat(
        siteId,
        exportType,
        filterClause,
        orderTable,
        fastFilters,
        optionOr
      )
      .then((blob: any) => {
        dispatch(success(blob.content));
        const url = window.URL.createObjectURL(blob.content);
        const a = document.createElement("a");
        a.href = url;
        a.download = blob.filename;
        a.click();
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function exportPdiDatToFactu(
  siteId: number,
  filterClause: any,
  orderTable: any,
  fastFilters: any,
  optionOr: boolean
) {
  function request() {
    return { type: pdiConstants.EXPORT_PDI_DAT_TO_FACTU_REQUEST };
  }
  function success(abonneLines: Array<Object>) {
    return { type: pdiConstants.EXPORT_PDI_DAT_TO_FACTU_SUCCESS, abonneLines };
  }
  function failure(error: Object) {
    return { type: pdiConstants.EXPORT_PDI_DAT_TO_FACTU_FAILURE, error };
  }

  return (dispatch: Function) => {
    dispatch(request());
    pdiService
      .sendRemotePdiDatToFactu(
        siteId,
        filterClause,
        orderTable,
        fastFilters,
        optionOr
      )
      .then((abonneLines) => dispatch(success(abonneLines)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

/**
 * Récupère tous les PDIs du site courant
 *
 * @method getStock
 * @param siteId Id site
 * @returns {Object} Les PDIs
 */
function getStock(siteId: number) {
  function request() {
    return { type: pdiConstants.GETSTOCK_REQUEST };
  }
  function success(pdis: Object) {
    return { type: pdiConstants.GETSTOCK_SUCCESS, pdis };
  }
  function failure(error: Object) {
    return { type: pdiConstants.GETSTOCK_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    pdiService
      .getStock(siteId)
      .then((pdis: Object) => dispatch(success(pdis)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

/**
 * Récupère les infos du PDI dont l'id est passé
 * en paramètre
 *
 * @method getInfos
 * @param {number} siteId Id du site
 * @param {number} pdiId Id du PDI
 * @returns {Object} Les infos du PDI
 */
function getInfos(siteId: number, pdiId: number) {
  function request() {
    return { type: pdiConstants.GETINFO_REQUEST };
  }
  function success(fetchedPdi: Object) {
    return { type: pdiConstants.GETINFO_SUCCESS, fetchedPdi };
  }
  function failure(error: Object) {
    return { type: pdiConstants.GETINFO_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    pdiService
      .getInfos(siteId, pdiId)
      .then((fetchedPdi: Object) => dispatch(success(fetchedPdi)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

/**
 * Récupère les infos du PDI dont l'id est passé
 * en paramètre
 *
 * @method getInfos
 * @param {number} siteId Id du site
 * @param {number} pdiId Id du PDI
 * @returns {Object} Les infos du PDI
 */
function linkPdisToLocation(locationId: number, pdiList: Array<any>) {
  function request() {
    return { type: pdiConstants.LINKPDI_REQUEST };
  }
  function success(movedPdi: Object) {
    return { type: pdiConstants.LINKPDI_SUCCESS, movedPdi };
  }
  function failure(error: Object) {
    return { type: pdiConstants.LINKPDI_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    pdiService
      .linkPdisToLocation(locationId, pdiList)
      .then((pdiList: Array<any>) => dispatch(success(pdiList)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

/**
 * Récupère les infos du PDI dont l'id est passé
 * en paramètre
 *
 * @method getInfos
 * @param {number} siteId Id du site
 * @param {number} pdiId Id du PDI
 * @returns {Object} Les infos du PDI
 */
function unlinkPdisToLocation(
  locationId: number,
  pdiList: Array<any>,
  actionsList: Array<any>
) {
  function request() {
    return { type: pdiConstants.UNLINKPDI_REQUEST };
  }
  function success(unlinkActions: Object) {
    return { type: pdiConstants.UNLINKPDI_SUCCESS, unlinkActions };
  }
  function failure(error: Object) {
    return { type: pdiConstants.UNLINKPDI_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    pdiService
      .unlinkPdisToLocation(locationId, pdiList, actionsList)
      .then((unlinkActions: Array<any>) => dispatch(success(unlinkActions)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

/**
 * Récupère les infos du PDI dont l'id est passé
 * en paramètre
 *
 * @method getInfos
 * @param {number} siteId Id du site
 * @param {number} pdiId Id du PDI
 * @returns {Object} Les infos du PDI
 */
function linkAllPdisToLocation(locationId: number, filterclause: any) {
  function request() {
    return { type: pdiConstants.LINKPDI_ALL_REQUEST };
  }
  function success(movedPdi: Object) {
    return { type: pdiConstants.LINKPDI_ALL_SUCCESS, movedPdi };
  }
  function failure(error: Object) {
    return { type: pdiConstants.LINKPDI_ALL_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    pdiService
      .linkAllPdisToLocation(locationId, filterclause)
      .then((pdiList: Array<any>) => dispatch(success(pdiList)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function getMapIdToSerial(
  body: { filters: any[]; meterNotInRound: boolean },
  locCode: string,
  searchForSite: number,
  clickId: number
) {
  function request() {
    return { type: pdiConstants.MAPID_TO_SERIAL_REQUEST };
  }
  function success(mapIdToSerial: Object) {
    return {
      type: pdiConstants.MAPID_TO_SERIAL_SUCCESS,
      mapIdToSerial,
      clickId,
    };
  }
  function failure(error: Object) {
    return { type: pdiConstants.MAPID_TO_SERIAL_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    pdiService
      .getMapIdToSerial(body, locCode, searchForSite)
      .then((mapIdToSerial: any) => dispatch(success(mapIdToSerial)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

/**
 * Récupère les infos du PDI dont l'id est passé
 * en paramètre
 *
 * @method getInfos
 * @param {number} siteId Id du site
 * @param {number} pdiId Id du PDI
 * @returns {Object} Les infos du PDI
 */
function unlinkAllPdisToLocation(
  locationId: number,
  filterclause: Array<any>,
  actionList: Array<any>
) {
  function request() {
    return { type: pdiConstants.UNLINKPDI_ALL_REQUEST };
  }
  function success(unlinkActions: Object) {
    return { type: pdiConstants.UNLINKPDI_ALL_SUCCESS, unlinkActions };
  }
  function failure(error: Object) {
    return { type: pdiConstants.UNLINKPDI_ALL_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    pdiService
      .unlinkAllPdisToLocation(locationId, filterclause, actionList)
      .then((unlinkActions: Array<any>) => dispatch(success(unlinkActions)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

/**
 * Récupère les infos du PDI dont l'id est passé
 * en paramètre
 *
 * @method getInfos
 * @param {number} siteId Id du site
 * @param {number} pdiId Id du PDI
 * @returns {Object} Les infos du PDI
 */
function unlinkActionsPdisToLocation(locationId: number, pdiList: Array<any>) {
  function request() {
    return { type: pdiConstants.UNLINKACTIONSPDI_REQUEST };
  }
  function success(unlinkActions: Object) {
    return { type: pdiConstants.UNLINKACTIONSPDI_SUCCESS, unlinkActions };
  }
  function failure(error: Object) {
    return { type: pdiConstants.UNLINKACTIONSPDI_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    pdiService
      .unlinkActionsPdisToLocation(locationId, pdiList)
      .then((unlinkActions: Array<any>) => dispatch(success(unlinkActions)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function unlinkActionsAllPdisToLocation(
  locationId: number,
  filters: Array<any>
) {
  function request() {
    return { type: pdiConstants.UNLINKACTIONSPDI_REQUEST };
  }
  function success(unlinkActions: Object) {
    return { type: pdiConstants.UNLINKACTIONSPDI_SUCCESS, unlinkActions };
  }
  function failure(error: Object) {
    return { type: pdiConstants.UNLINKACTIONSPDI_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    pdiService
      .unlinkActionsAllPdisToLocation(locationId, filters)
      .then((unlinkActions: Array<any>) => dispatch(success(unlinkActions)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

/**
 * Récupère les infos du PDI dont l'id est passé
 * en paramètre
 *
 * @method getInfos
 * @param {number} siteId Id du site
 * @param {number} pdiId Id du PDI
 * @returns {Object} Les infos du PDI
 */
function createPdi(newPdi: any) {
  function request() {
    return { type: pdiConstants.CREATEPDI_REQUEST };
  }
  function success(createdPdi: Object) {
    return { type: pdiConstants.CREATEPDI_SUCCESS, createdPdi };
  }
  function failure(error: Object) {
    return { type: pdiConstants.CREATEPDI_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    pdiService
      .createPdi(newPdi)
      .then((createdPdi: Array<any>) => dispatch(success(createdPdi)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function addGpsPosition(pdiId: any) {
  function request() {
    return { type: pdiConstants.ADD_GPS_POSITION_REQUEST };
  }
  function success(pdi: Object) {
    return { type: pdiConstants.ADD_GPS_POSITION_SUCCESS, pdi };
  }
  function failure(error: Object) {
    return { type: pdiConstants.ADD_GPS_POSITION_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    pdiService
      .addGpsPosition(pdiId)
      .then((pdi: any) => dispatch(success(pdi)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function updateInfo(
  id: any,
  editableField: string,
  value: string,
  extra: string
) {
  function request() {
    return {
      type: pdiConstants.UPDATE_INFO_REQUEST,
      editableField,
      value,
      extra,
    };
  }
  function success(pdi: Object) {
    return { type: pdiConstants.UPDATE_INFO_SUCCESS, pdi };
  }
  function failure(error: Object) {
    return { type: pdiConstants.UPDATE_INFO_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    pdiService
      .updateInfo(id, editableField, value)
      .then((pdi: any) => dispatch(success(pdi)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

/**
 * Vide le state des PDIs
 *
 * @method clear
 */
function clear() {
  return {
    type: pdiConstants.CLEAR,
  };
}
function clearRangeDate() {
  return {
    type: pdiConstants.CLEAR_RANGEDATE,
  };
}

function clearFactuDat() {
  return {
    type: pdiConstants.EXPORT_PDI_DAT_TO_FACTU_CLEAR,
  };
}

const pdiActions = {
  getAll,
  getAllWithChildren,
  getAllWithChildrenWithFilters,
  getStock,
  getInfos,
  linkPdisToLocation,
  linkAllPdisToLocation,
  unlinkActionsPdisToLocation,
  unlinkActionsAllPdisToLocation,
  unlinkPdisToLocation,
  unlinkAllPdisToLocation,
  createPdi,
  getFullPdi,
  addGpsPosition,
  clear,
  clearRangeDate,
  getAllWithChildrenWithRangeDate,
  getRemotePdi,
  exportPdiCsv,
  getRemotePdiCsvStatus,
  exportPdiDat,
  getMapIdToSerial,
  updateInfo,
  exportPdiDatToFactu,
  clearFactuDat,
};

export default pdiActions;
