import React, { useState, useEffect, useReducer } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import synchroAction from "../_actions/synchro.actions";
import synchroComAction from "../_actions/synchrocom.actions";
import gestionnaireAction from "../_actions/gestionnaire.actions";
import Loading from "../_animations/Loading";
import { tourneeActions } from "../_actions";
import DragDrop from "../_components/DragDrop/DragDrop";
import ErrorBand from "../Bands/Error";
import HeaderSynchro from "../_components/DragDrop/HeaderSynchro";
import Chip from "../_components/Chip";

let interval = null;

const Synchronisation = (props) => {
  const {
    dispatch,
    match,
    roundsUp,
    devicesUp,
    t,
    i18n,
    error,
    comError,
    loadingList,
    loadingLock,
  } = props;
  const { params } = match;

  const [filter, setFilter] = useState({ round: "", device: "" });
  const [checked, setChecked] = useState(false);
  const [stickyButton, setStickyButton] = useState(false);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const getCurrentInfo = () => {
    dispatch(synchroComAction.getLabels(params.GestionnaireId));
    dispatch(synchroComAction.getCurrentCommunication(params.GestionnaireId));
    dispatch(synchroComAction.getCurrentRounds(params.GestionnaireId));
    dispatch(synchroAction.getListDeviceError());
  };

  const handleKeyboardInput = (e) => {
    const code = e.keyCode ? e.keyCode : e.which;
    if (code === 91 || code === 17) {
      setChecked(!checked);
    }
  };

  useEffect(() => {
    localStorage.setItem("synchroFilter-fast", JSON.stringify(filter));
  }, [filter]);

  useEffect(() => {
    dispatch(synchroAction.getAllDevice(params.GestionnaireId));
    dispatch(synchroAction.getAllRoundFull(params.GestionnaireId));
    dispatch(synchroComAction.getCurrentVersion());
    dispatch(synchroComAction.getMiniVersion());
    dispatch(synchroComAction.getCurrentCommunication(params.GestionnaireId));
    dispatch(synchroComAction.getLabels(params.GestionnaireId));

    document.addEventListener("keydown", handleKeyboardInput, false);
    dispatch(gestionnaireAction.getGestionnaire(params.GestionnaireId));
    dispatch(tourneeActions.getRotateOption());

    interval = setInterval(getCurrentInfo, 5000);

    const mountFastFilters = localStorage.getItem("synchroFilter-fast");
    if (mountFastFilters) {
      setFilter(JSON.parse(mountFastFilters));
    }

    window.addEventListener("resize", (e) => {
      forceUpdate();
    });

    return () => {
      document.removeEventListener("keydown", handleKeyboardInput, false);
      window.onscroll = null;
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    clearInterval(interval);
    if (loadingLock) {
      interval = setInterval(getCurrentInfo, 1000);
    } else {
      interval = setInterval(getCurrentInfo, 5000);
    }
  }, [loadingLock]);

  const handleChangeChecked = (check) => {
    setChecked(check);
  };

  const handleChange = (e, round) => {
    if (round) {
      setFilter({
        round: e.target.value,
        device: filter.device,
      });
    } else {
      setFilter({
        round: filter.round,
        device: e.target.value,
      });
    }
  };

  const displayButton = (e) => {
    if (e.clientY > window.innerHeight - 20) {
      setStickyButton(true);
    } else {
      setStickyButton(false);
    }
  };

  const handleChangeAdd = (onDragEnd) => {
    if (onDragEnd) {
      setChecked(false);
    } else {
      setChecked(!checked);
    }
  };
  return (
    <div onKeyDown={handleKeyboardInput}>
      {(error || comError) && (
        <Chip severity={"error"} message={error || comError} />
      )}
      {(!roundsUp || !devicesUp) && (
        <Loading message="Chargement de la synchro en cours ..." />
      )}
      {roundsUp && devicesUp && (
        <div
          className="drag-and-drop"
          id="drag-drop-synchro"
          onMouseOver={displayButton}
        >
          <HeaderSynchro />
          <DragDrop
            filter={filter}
            checked={checked}
            handleChangeChecked={handleChangeAdd}
            stickyButton={stickyButton}
            handleFilterChange={handleChange}
            handleChange={handleChangeChecked}
          />
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  const {
    users,
    authentication,
    alert,
    locations,
    synchro,
    currentState,
    gestionnaire,
    synchrocom,
  } = state;
  const { roundsUp, devicesUp, error } = synchro;
  const { error: comError, loadingList, loadingLock } = synchrocom;
  const { user } = authentication;
  return {
    user,
    users,
    alert,
    locations,
    currentState,
    gestionnaire,
    roundsUp,
    devicesUp,
    error,
    comError,
    loadingList,
    loadingLock,
  };
}

const mapping = connect(mapStateToProps)(Synchronisation);

const connectedUser = withRouter(mapping);
const tr = withTranslation()(connectedUser);
export default tr;
