import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { UncontrolledTooltip } from "reactstrap";
import moment from "moment";
import styled from "styled-components";
import _ from "lodash";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SvgCalendrierVert from "../../../SvgComponents/CalendrierVert";
import SvgBatterieVert from "../../../SvgComponents/BatterieVerte";
import SvgAntenneOn from "../../../SvgComponents/SaphirAntenneOn";
import SvgAntenneOff from "../../../SvgComponents/SaphirAntenneOff";
import ValidateButtons from "./ValidateButtons";
import SvgCroixNoir from "../../../SvgComponents/croixNoir";
import synchroComActions from "../../../_actions/synchrocom.actions";
import SvgSaphir from "../../../SvgComponents/SaphirOrange";
import BestLabel from "./BestLabel";
import SvgSaphirWarning from "../../../SvgComponents/SaphirAttention";

const SynchroBattery = styled.div`
  background-color: ${(props) => (props.lvlcolor ? props.lvlcolor : "#d3d3d3")};
  width: ${(props) => props.x}px;
  height: 16px;
  position: absolute;
  margin: 3px;
`;

const DeviceInfo = (props) => {
  const { task, synchrocom, dispatch, match, t } = props;
  const { labels, errorList } = synchrocom;
  const { params } = match;
  const { GestionnaireId } = params;

  const {
    batteryLevel: battery,
    online,
    updateDate: updatedDate,
    saphirVersion: version,
  } = task;

  const [affectedLabels, setAffectedLabels] = useState([]);
  const [waitingLabels, setWaitingLabels] = useState([]);

  const updateAffectedLabels = () => {
    setAffectedLabels(
      labels?.loaded
        .filter((el) => el.device === task.serial)
        .map((el) => {
          el.type = "loaded";
          return el;
        })
    );
  };

  const updateWaitingLabels = () => {
    setWaitingLabels(
      _.concat(
        labels?.affect
          .filter((el) => el.device === task.serial)
          .map((el) => {
            el.type = "affect";
            return el;
          }),
        labels?.disaffect
          .filter((el) => el.device === task.serial)
          .map((el) => {
            el.type = "dissaffect";
            return el;
          })
      )
    );
  };

  useEffect(() => {
    updateAffectedLabels();
    updateWaitingLabels();
  }, [labels]);

  useEffect(() => {
    if (
      labels?.loaded.filter((el) => el.device === task.serial).length !==
      affectedLabels?.length
    ) {
      updateAffectedLabels();
    }
    if (
      _.concat(
        labels?.affect.filter((el) => el.device === task.serial),
        labels?.disaffect.filter((el) => el.device === task.serial)
      ).length !== waitingLabels.length
    ) {
      updateWaitingLabels();
    }
  });

  const batteryColor = (percent) => {
    if (percent <= 20) {
      return "red";
    }
    if (percent > 20 && percent <= 50) {
      return "orange";
    }
    if (percent > 50) {
      return "greenyellow";
    }
    if (percent === -1) {
      return "lightgrey";
    }
    return "";
  };

  const BatteryDisplay = () => {
    const isBigScreen = useMediaQuery({ query: "(min-width: 1550px)" });
    const isNormalScreen = useMediaQuery({ query: "(max-width: 1549px)" });

    return (
      <>
        {isBigScreen && (
          <div>
            <h5 style={{ marginBottom: 0 }}>
              {battery && <b>{`${battery}%`}</b>}
            </h5>
          </div>
        )}
        {isNormalScreen && (
          <div>
            <h5 style={{ marginBottom: 0 }}>
              {battery && <b>{`${battery}%`}</b>}
            </h5>
          </div>
        )}
      </>
    );
  };

  const versionCheck = (version, type) => {
    const myVersion = version.split(".");
    const miniVersion = _.get(synchrocom, "version.minimum", "").split(".");
    const currentVersion = _.get(synchrocom, "version.current", "").split(".");
    const length = myVersion.length;
    if (!version) {
      return 1;
    }
    const result = [];
    if (type === "current") {
      for (let i = 0; i < length; i++) {
        result[i] =
          parseInt(myVersion[i], 10) - parseInt(currentVersion[i], 10);
      }
    } else {
      for (let i = 0; i < length; i++) {
        result[i] = parseInt(myVersion[i], 10) - parseInt(miniVersion[i], 10);
      }
    }
    if (result[0] > 0) {
      return 0;
    }
    if (result[0] < 0) {
      return -1;
    }
    if (result[1] > 0) {
      return 0;
    }
    if (result[1] < 0) {
      return -1;
    }
    if (result[2] > 0) {
      return 0;
    }
    if (result[2] < 0) {
      return -1;
    }

    return 0;
  };

  const ResponsInfoDisplay = () => {
    const isBigScreen = useMediaQuery({ query: "(min-width: 1450px)" });
    const isNormalScreen = useMediaQuery({ query: "(max-width: 1449px)" });
    const versionInfo = {
      svg: <SvgSaphir fill="#CE7830" height="2em" />,
      tooltip: t("all.device.device_own_last_version"),
    };

    if (version && versionCheck(version, "current") === -1) {
      versionInfo.svg = <SvgSaphirWarning fill="#CE7830" height="2em" />;
      versionInfo.tooltip = t("all.device.device_not_own_last_version");
    }

    if (version && versionCheck(version, "minimum") === -1) {
      versionInfo.svg = <SvgSaphirWarning fill="#F24141" height="2em" />;
      versionInfo.tooltip = t("all.device.device_with_obsolete_version");
    }

    return (
      <>
        {(isBigScreen ||
          (waitingLabels.length === 0 && affectedLabels?.length === 0)) && (
          <div
            style={{
              display: "flex",
              textAlign: "center",
              height: "45%",
              marginBottom: "2%",
              alignItems: "center",
            }}
          >
            <div className="synchro-date">
              {updatedDate && (
                <div
                  className="synchro-date-box"
                  style={{
                    padding: "5px 0",
                    display: "flex",
                    lineHeight: "normal",
                  }}
                >
                  <div>
                    <SvgCalendrierVert height="2.3em" />
                  </div>
                  <b
                    style={{ marginLeft: "10px" }}
                    id={`last-communication-${task.id}`}
                  >
                    {moment
                      .utc(updatedDate)
                      .format(t("all.date_format.date_and_time"))}
                  </b>
                  <UncontrolledTooltip
                    placement="bottom"
                    target={`last-communication-${task.id}`}
                  >
                    {" "}
                    {t("all.device.last_login_date")}
                  </UncontrolledTooltip>
                </div>
              )}
              {synchrocom && !updatedDate && (
                <div style={{ textAlign: "center", color: "#000" }}>
                  {t("device_info.text.first_com")}
                </div>
              )}
            </div>
            <div className="synchro-version">
              <div
                className="synchro-version-container"
                id={`synchro-version-${task.id}`}
              >
                {versionInfo.svg}
                {version && (
                  <UncontrolledTooltip
                    placement="bottom"
                    target={`synchro-version-${task.id}`}
                  >
                    {" "}
                    {versionInfo.tooltip}
                  </UncontrolledTooltip>
                )}
                <b style={{ marginLeft: "5px", textAlign: "center" }}>
                  {version || "?"}
                </b>
              </div>
            </div>
          </div>
        )}
        {isNormalScreen &&
          (waitingLabels.length > 0 || affectedLabels?.length > 0) && (
            <div
              style={{
                display: "block",
                textAlign: "center",
                height: "45%",
                marginBottom: "2%",
                alignItems: "center",
              }}
            >
              <div className="synchro-date" style={{ width: "100%" }}>
                {updatedDate && (
                  <div
                    className="synchro-date-box"
                    style={{
                      padding: "5px 0",
                      display: "flex",
                      lineHeight: "normal",
                    }}
                  >
                    <span
                      style={{ marginLeft: "10px" }}
                      id={`last-communication-${task.id}`}
                    >
                      {moment
                        .utc(updatedDate)
                        .format(t("all.date_format.date_and_time"))}
                    </span>
                    <UncontrolledTooltip
                      placement="bottom"
                      target={`last-communication-${task.id}`}
                    >
                      {t("all.device.last_login_date")}
                    </UncontrolledTooltip>
                  </div>
                )}
                {synchrocom && !updatedDate && (
                  <span style={{ textAlign: "center", color: "#000" }}>
                    {t("device_info.text.first_com")}
                  </span>
                )}
              </div>
              <div
                className="synchro-version"
                style={{ borderLeft: "none", width: "100%" }}
              >
                <div
                  className="synchro-version-container"
                  id={`synchro-version-${task.id}`}
                  style={{ padding: 0 }}
                >
                  {version && (
                    <UncontrolledTooltip
                      placement="bottom"
                      target={`synchro-version-${task.id}`}
                    >
                      {" "}
                      {versionInfo.tooltip}
                    </UncontrolledTooltip>
                  )}
                  <span style={{ marginLeft: "5px", textAlign: "center" }}>
                    {t("all.text.version")}: {version || "?"}
                  </span>
                </div>
              </div>
            </div>
          )}
      </>
    );
  };

  const disaffectAll = () => {
    dispatch(synchroComActions.disaffectAllForDevice(GestionnaireId, task.id));
  };

  const error = errorList?.find((err) => err.deviceSerial === task.id);

  return (
    <div
      style={{
        display:
          waitingLabels.length > 0 || affectedLabels.length > 0
            ? "flex"
            : "block",
        height: "100%",
        width: "100%",
      }}
    >
      <div
        style={{
          height: "100%",
          width:
            waitingLabels.length > 0 || affectedLabels.length > 0
              ? "50%"
              : "100%",
        }}
      >
        <ResponsInfoDisplay />
        <div
          style={{
            display: "flex",
            textAlign: "center",
            height: "45%",
            alignItems: "center",
          }}
        >
          <div className="synchro-batterie">
            <div>
              <div className="battery-component">
                {battery && battery > 0 && (
                  <SynchroBattery
                    x={36 * (battery / 100)}
                    lvlcolor={batteryColor(battery)}
                  />
                )}
                {battery && battery > 0 && (
                  <SynchroBattery x={0} lvlcolor={-1} />
                )}
                <SvgBatterieVert width="4em" />
              </div>
              <BatteryDisplay />
            </div>
          </div>
          <div className="synchro-online">
            <div className="online-component" id={`synchro-online-${task.id}`}>
              {online ? (
                <>
                  <SvgAntenneOn width="2em" />
                  <UncontrolledTooltip
                    placement="bottom"
                    target={`synchro-online-${task.id}`}
                  >
                    {" "}
                    {t("all.device.connected")}
                  </UncontrolledTooltip>
                </>
              ) : (
                <>
                  <SvgAntenneOff width="2em" fill="#D3D3D3" />
                  <UncontrolledTooltip
                    placement="bottom"
                    target={`synchro-online-${task.id}`}
                  >
                    {" "}
                    {t("all.device.disconnected")}
                  </UncontrolledTooltip>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {(waitingLabels.length > 0 || affectedLabels.length > 0) && (
        <div className="label-list-container">
          <div
            className="labels-container"
            onScroll={(e) => {
              e.stopPropagation();
            }}
            style={{ height: waitingLabels.length > 0 ? "100px" : "130px" }}
          >
            {waitingLabels?.length > 0 && (
              <>
                <span
                  style={{
                    width: "100%",
                    borderBottom: "1px solid lightgrey",
                    marginTop: "5px",
                  }}
                >
                  {t("device_info.text.waiting_action", {
                    count: waitingLabels.length,
                  })}
                </span>
                {waitingLabels?.map(
                  (el) => el && <BestLabel label={el} withCode isDevice />
                )}
              </>
            )}
            {affectedLabels?.length > 0 && (
              <>
                <div
                  className="flex-box"
                  style={{
                    width: "100%",
                    borderBottom: "1px solid lightgrey",
                    marginBottom: "5px",
                    justifyContent: "space-between",
                  }}
                >
                  <span>
                    {t("device_info.text.x_loaded_round", {
                      count: affectedLabels.length,
                    })}
                  </span>
                  <div className="red-cross">
                    <div className="svg" onClick={() => disaffectAll()}>
                      <SvgCroixNoir height="1.7em" fill="#dc3545" />
                    </div>
                    <span className="span">
                      {t(
                        "tournee_ressources.column_exported_tooltip.unload_all"
                      )}
                    </span>
                  </div>
                </div>
                {affectedLabels.map(
                  (el) =>
                    el && (
                      <BestLabel
                        label={el}
                        loaded
                        withCode
                        isDevice
                        err={
                          error?.errorDetail && error?.errorDetail[el.tournee]
                        }
                      />
                    )
                )}
              </>
            )}
          </div>
          {waitingLabels.length > 0 && (
            <ValidateButtons
              task={task}
              online={online}
              isRound={false}
              waitingLabels={waitingLabels}
              gestionnaireId={GestionnaireId}
            />
          )}
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  const { synchrocom } = state;
  return {
    synchrocom,
  };
}

const mapping = connect(mapStateToProps)(DeviceInfo);

const connectedTask = withRouter(mapping);
const tr = withTranslation()(connectedTask);
export default tr;
