import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import moment from "moment";
import SvgChartBarVerticalNotAvailable from "../../SvgComponents/ChartBarVerticalNotAvailable";

const BarChart = ({ data, labels, adaptivFormat, t }) => {
  const [format, setFormat] = useState(t("all.date_format.date"));

  useEffect(() => {
    if (data.length > 0 && adaptivFormat) {
      const date = new Date(labels[0]);
      if (
        typeof date.getUTCHours() === "number" &&
        !isNaN(date.getUTCHours())
      ) {
        setFormat(t("all.date_format.date_and_time"));
      }
    }
  }, [data]);

  const options = {
    emptyOverlay: {
      message: t("all.text.no_data_available"),
    },
    legend: {
      display: false,
    },
    maintainAspectRatio: false,
    responsive: true,
    layout: {
      padding: {
        top: 20,
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        display: "auto",
        color(ctx) {
          return "black";
        },
        borderWidth: 0,
        anchor: "end",
        clamp: true,
        align: "end",
      },
      t1: true,
    },
  };

  const dataS = {
    labels: labels.map((el) => moment.utc(el).format(format)),
    datasets: [
      {
        data,
        backgroundColor: "#80dfff",
        hoverBackgroundColor: "#43c8fc",
      },
    ],
  };

  return data.length > 0 ? (
    <Bar data={dataS} options={options} />
  ) : (
    <SvgChartBarVerticalNotAvailable height={"300px"} />
  );
};

export default BarChart;
