import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Divider } from "@material-ui/core";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import _ from "lodash";
import { withRouter } from "react-router";
import { compose } from "redux";
import { UncontrolledTooltip } from "reactstrap";
import SvgPoubelleBleu from "../SvgComponents/PoubelleBleu";
import SvgEditionBleu from "../SvgComponents/EditionBleu";
import RoundButton from "../_components/RoundButton";
import {
  importActions,
  localeActions,
  rndTemplateActions,
  tourneeActions,
} from "../_actions";
import {
  TemplateDto,
  TemplatesState,
} from "../_interfaces/TourneeCreationTypes";
import ErrorBand from "../Bands/Error";
import SuccessBand from "../Bands/Success";
import LoadingBand from "../Bands/Loading";
import { SilexImportState } from "../_interfaces/imports";
import ImportFileTab from "./ImportFileTab";
import ImportFactuTab from "./ImportFactuTab";
import ImportTournee from "../SvgComponents/ImportTournee";
import ImportFacturation from "../SvgComponents/ImportFacturation";

const NewTourneePage: React.FC<
  {
    dispatch: Function;
    gestionnaire: any;
    imports: any;
    rndTemplate: TemplatesState;
    tournee: any;
    user: any;
  } & RouteComponentProps &
    WithTranslation
> = ({
  dispatch,
  gestionnaire,
  history,
  imports,
  match,
  rndTemplate,
  t,
  tournee,
  user,
}) => {
  const [loadingMessage, setLoadingMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [activeTab, setActiveTab] = useState<string>("file-import");
  const [isSilexImport, setIsSilexImport] = useState<boolean>(false);
  const admin = user.role.name === "DIOPTASE";

  useEffect(() => {
    const roleList = ["DIOPTASE", "SUPERADMIN", "ADMIN", "USER"];
    if (!roleList.includes(user.role.name)) {
      history.push("/forbidden");
    }
    dispatch(localeActions.load());
    dispatch(rndTemplateActions.getAllTemplates());
    dispatch(tourneeActions.getImportOptions());
    return () => {
      dispatch(rndTemplateActions.clearError());
      dispatch(rndTemplateActions.clearSuccess());
    };
  }, []);

  useEffect(() => {
    if (rndTemplate.success) {
      setSuccessMessage(
        t(`tournee_creation.success.${rndTemplate.success}`, {
          successTarget: rndTemplate.successTarget!.name,
        })
      );
      setTimeout(() => {
        dispatch(rndTemplateActions.clearSuccess());
      }, 5000);
    }
    if (rndTemplate.error) {
      setTimeout(() => {
        dispatch(rndTemplateActions.clearError());
      }, 5000);
    }
  }, [rndTemplate]);

  useEffect(() => {
    if (imports.error && !imports) {
      setTimeout(() => {
        dispatch(importActions.clearError());
      }, 5000);
    }
  }, [imports]);

  const getStateString = () => {
    if (!imports.importState || imports.importState.numberOfRounds == 0) {
      return ` - ${t("import_silex.loading_band.analysis_in_progress")}`;
    } else {
      const importState: SilexImportState = imports.importState;
      if (importState.actualRoundNumber === importState.numberOfRounds + 1) {
        return ` - ${t("import_silex.loading_band.validating_import")}`;
      } else {
        return ` - ${t("import_silex.loading_band.import_in_progress", {
          label: importState.actualRoundLabel,
          code: importState.actualRoundCode,
          number: importState.actualRoundNumber,
          numberOfRound: importState.numberOfRounds,
        })}`;
      }
    }
  };

  const getErrorBandOptions = (error: {
    translationId: string;
    errorValue: any;
  }) => {
    switch (error.translationId) {
      case "template_still_linked":
        return { rndList: error.errorValue.join(", ") };
      default:
        return {};
    }
  };

  return (
    <>
      <div className="col-12">
        {(rndTemplate.error ||
          imports.error ||
          imports.importFromFactu?.silexErrorList?.length > 0) && (
          <ErrorBand
            message={
              imports.importFromFactu?.silexErrorList?.length > 0
                ? t("import_silex.error_band.facturation_errors_detected")
                : imports.error
                ? imports.error === "facturation_unreachable"
                  ? t("import_silex.error_band.facturation_unreachable")
                  : t("import_silex.error_band.import_error")
                : typeof rndTemplate.error === "string"
                ? rndTemplate.error
                : t(
                    `tournee_creation.error_band.${rndTemplate.error.translationId}`,
                    {
                      ...getErrorBandOptions(rndTemplate.error),
                    }
                  )
            }
          />
        )}
        {(rndTemplate.success ||
          (imports.importFromFactu?.roundWithAbonnes?.length > 0 &&
            imports.importFromFactu?.silexErrorList.length == 0)) && (
          <SuccessBand message={successMessage} />
        )}
        {(imports.loading || rndTemplate.loading) && loadingMessage !== "" && (
          <LoadingBand
            message={loadingMessage + (isSilexImport ? getStateString() : "")}
          />
        )}
      </div>
      <div className="new-tournee-container">
        {/* Partie import silex */}
        <div className="main-tournee-sub-container" style={{ gap: 0 }}>
          {tournee.importOption?.import_round_facturation_enabled && (
            <div className="tab-container">
              <div
                className={
                  "tab" + (activeTab === "file-import" ? " active" : "")
                }
                onClick={() => setActiveTab("file-import")}
              >
                <>
                  <ImportTournee
                    width={"2.5vh"}
                    fill={activeTab === "file-import" ? "#32bead" : "#6c757d"}
                  />
                  {t("import_silex.tab_label.file_import")}
                </>
              </div>
              <div
                className={
                  "tab" + (activeTab === "moonstone-import" ? " active" : "")
                }
                onClick={() => setActiveTab("moonstone-import")}
              >
                <ImportFacturation
                  width={"2.5vh"}
                  fill={
                    activeTab === "moonstone-import" ? "#32bead" : "#6c757d"
                  }
                />
                {t("import_silex.tab_label.facturation_import")}
              </div>
            </div>
          )}
          <div
            className={
              "table-info-container import-side" +
              (tournee.importOption?.import_round_facturation_enabled
                ? " with-tab"
                : "")
            }
          >
            {(!tournee.importOption?.import_round_facturation_enabled ||
              activeTab === "file-import") && (
              <ImportFileTab
                dispatch={dispatch}
                gestionnaire={gestionnaire}
                history={history}
                imports={imports}
                match={match}
                rndTemplate={rndTemplate}
                setIsSilexImport={setIsSilexImport}
                setLoadingMessage={setLoadingMessage}
                t={t}
                tournee={tournee}
                user={user}
              />
            )}
            {tournee.importOption?.import_round_facturation_enabled &&
              activeTab === "moonstone-import" && (
                <ImportFactuTab
                  dispatch={dispatch}
                  history={history}
                  imports={imports}
                  match={match}
                  setLoadingMessage={setLoadingMessage}
                  setSuccessMessage={setSuccessMessage}
                  tournee={tournee}
                  t={t}
                />
              )}
          </div>
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state: any) {
  const {
    authentication,
    imports,
    gestionnaire: { gestionnaire },
    rndTemplate,
    tournee,
  } = state;
  const { user } = authentication;
  return {
    imports,
    gestionnaire,
    rndTemplate,
    tournee,
    user,
  };
}

const connectedNewTourneePage = compose(
  withRouter,
  connect(mapStateToProps)
)(NewTourneePage);
const tr = withTranslation()(connectedNewTourneePage);
export default tr;
