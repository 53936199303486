import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import DragIcone from "./TaskComponent/DragIcone";
import TaskButtons from "./TaskComponent/TaskButtons";
import RoundInfo from "./TaskComponent/RoundInfo";
import DeviceInfo from "./TaskComponent/DeviceInfo";
import synchrocomActions from "../../_actions/synchrocom.actions";
import MessageCom from "./TaskComponent/MessageCom";
import CheckBox from "./TaskComponent/CheckBox";
import { Spinner } from "reactstrap";
import SvgCroixNoir from "../../SvgComponents/croixNoir";
import ErrorLayout from "./TaskComponent/ErrorLayout";
import HoverLayout from "./TaskComponent/HoverLayout";
import synchrox1 from "./TaskComponent/synchrox1.svg";
import synchrox2 from "./TaskComponent/synchrox2.svg";
import synchrox3 from "./TaskComponent/synchrox3.svg";

const nodeStyle = {
  display: "flex",
  marginRight: "5px",
};

const BestTask = (props) => {
  const {
    task,
    dispatch,
    drag,
    match,
    column,
    multi,
    multiList,
    loadingList,
    roundsLoading,
    labels,
    loadingLock,
    errorList,
  } = props;
  const { hasCommunication } = task;
  const { params } = match;
  const { GestionnaireId } = params;

  const [enter, setEnter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [partialZone, setPartialZone] = useState(false);
  const [tampCanvas, setTampCanvas] = useState(null);

  const col = task.serial ? "device" : "round";
  const reversedColumn = task.serial ? "round" : "device";

  const isLastChecked = multi && multiList[0] === task?.id;

  let isAffect = labels
    ? column === "round"
      ? labels.affect.find((el) => el.tournee === drag && el.device === task.id)
      : labels.affect.find((el) => el.tournee === task.id && el.device === drag)
    : [];
  if (!isAffect) {
    isAffect = labels
      ? column === "round"
        ? labels.loaded.find(
            (el) => el.tournee === drag && el.device === task.id
          )
        : labels.loaded.find(
            (el) => el.tournee === task.id && el.device === drag
          )
      : [];
  }

  const error = errorList?.find((err) => err?.errorDetail[task.id]);

  const drawCanvas = () => {
    const canvas = document.createElement("canvas");

    canvas.width = 130;
    canvas.height = 120;

    const ctx = canvas.getContext("2d");

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    const roundNbr = multiList?.length;

    const img = new Image();
    img.onload = function () {
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

      // Définir le style du texte
      ctx.font = "bold 14px Arial";
      ctx.fillStyle = "white";
      ctx.textAlign = "center";
      ctx.fontStyle = "bold";

      let text = task.content;

      if (text.length > 14) {
        text = text.substring(0, 14 - 3) + "...";
      }
      // Écrire le texte sur le canvas
      ctx.fillText(
        text,
        canvas.width / 2,
        roundNbr > 2 ? 70 : roundNbr === 2 ? 75 : 85
      );
    };
    img.src = roundNbr > 2 ? synchrox3 : roundNbr === 2 ? synchrox2 : synchrox1;

    setTampCanvas(canvas);
  };

  useEffect(() => {
    if (!drag) setEnter(false);
  }, [drag]);

  useEffect(() => {
    if (task.id) {
      if (loadingList?.includes(task.id)) {
        setLoading(true);
      } else if (
        !hasCommunication &&
        (!roundsLoading || !roundsLoading[task.id])?.isLoading &&
        (!loadingLock || (loadingLock && !loadingLock[task.id]))
      ) {
        setLoading(false);
      }
    }
  }, [loadingList, loadingLock]);

  useEffect(() => {
    if (task.serial) {
      if (hasCommunication) setLoading(true);
      else if (!loadingLock || (loadingLock && !loadingLock[task.id])) {
        setLoading(false);
      }
    }
  }, [hasCommunication]);

  useEffect(() => {
    if (task.rndCode) {
      if (roundsLoading && roundsLoading[task.id]?.isLoading) {
        console.log(roundsLoading);
        setLoading(true);
      } else if (!loadingLock || (loadingLock && !loadingLock[task.id])) {
        setLoading(false);
      }
    }
  }, [roundsLoading]);

  useEffect(() => {
    if (col === "device" && !hasCommunication && !loadingLock && loading) {
      setLoading(false);
    }
  });

  useEffect(() => {
    drawCanvas();
  }, []);

  useEffect(() => {
    drawCanvas();
  }, [multiList]);

  const removeDeviceError = () => {
    dispatch(synchrocomActions.removeDeviceError(task.id));
  };

  const dragStart = (e) => {
    const doc = document.getElementById(`task-${task.id}`);

    doc.style.borderRadius = 0;
    doc.style.border = "none";
    doc.style.backgroundColor = "rgba(255,255,255, 0)";

    doc.classList.add("none");

    const global = document.getElementById(`global-${task.id}`);
    global.style.display = "none";

    doc.appendChild(tampCanvas);

    const dt = event.dataTransfer;
    dt.setData("text/plain", "Data to Drag");
    dt.setDragImage(tampCanvas, 65, 20);

    setTimeout(function () {
      doc.style.borderRadius = "10px";
      doc.style.border = "1px solid #31c6b3";
      doc.style.backgroundColor = "#ffffff";
      global.style.display = "block";

      doc.classList.add("none");

      doc.removeChild(tampCanvas);
    }, 1);
    dispatch(synchrocomActions.dragStart(task.id, col));
  };

  const dragEnter = () => {
    setEnter(true);
  };

  const dragLeave = () => {
    setEnter(false);
  };

  const drop = (e) => {
    if (!loading) {
      if (column !== col && !isAffect) {
        const round = task.serial ? drag : task.id;
        const device = task.serial ? task.id : drag;
        let activMod = JSON.parse(localStorage.getItem("synchro-mode"));
        if (activMod.selection === "both") {
          const dim = e.target.getBoundingClientRect();
          if (e.clientX >= dim.left + dim.width / 2) {
            activMod.selection = "complete";
          } else {
            activMod.selection = "partiel";
          }
        }
        if (multi) {
          switch (multi) {
            case "device":
              multiList.forEach((deviceId) =>
                dispatch(
                  synchrocomActions.addAffect(
                    [round],
                    GestionnaireId,
                    deviceId,
                    activMod.selection === "partiel"
                  )
                )
              );
              break;
            case "round":
              dispatch(
                synchrocomActions.addAffect(
                  multiList,
                  GestionnaireId,
                  device,
                  activMod.selection === "partiel"
                )
              );
              break;
            default:
          }
        } else {
          dispatch(
            synchrocomActions.addAffect(
              [round],
              GestionnaireId,
              device,
              activMod.selection === "partiel"
            )
          );
        }
      }
      dispatch(synchrocomActions.dragEnd(task.id));
    }
  };

  const dragEnd = (e) => {
    dispatch(synchrocomActions.dragEnd(task.id));
  };

  const dragOver = (e) => {
    e.preventDefault();
    const dim = e.target.getBoundingClientRect();
    if (e.clientX >= dim.left + dim.width / 2) {
      partialZone && setPartialZone(false);
    } else {
      !partialZone && setPartialZone(true);
    }
  };

  const getBackground = () => {
    if (loading) {
      return "#333";
    }
    if (
      (multiList && multiList.includes(task.id) && !isLastChecked) ||
      isAffect
    ) {
      return "lightgrey";
    }
    if (column === col) {
      return "rgba(255,255,255)";
    }
    if (enter) {
      return "lightgreen";
    }
    return "rgba(255,255,255)";
  };
  return (
    <div className={"node-wrapper"} style={nodeStyle}>
      {multi && multi !== reversedColumn && (
        <div style={{ marginRight: "5px" }}>
          <CheckBox id={task.id} col={col} />
        </div>
      )}
      <div
        className={`task-wrapper ${isLastChecked && "multi"}`}
        draggable
        onMouseEnter={drawCanvas}
        onDragStart={dragStart}
        onDragEnter={dragEnter}
        onDragLeave={dragLeave}
        onDragOver={dragOver}
        onDrop={drop}
        onDragEnd={dragEnd}
        style={{
          backgroundColor: getBackground(),
          opacity: isAffect ? 0.5 : 1,
        }}
        id={`task-${task.id}`}
      >
        {error && (
          <ErrorLayout
            errorList={JSON.parse(error.errorDetail[task.id])}
            rndCode={task.id}
          />
        )}
        {enter && col === "device" && !isAffect ? (
          <HoverLayout partial={partialZone} task={task} />
        ) : (
          <div
            className={"event-wrapper"}
            style={{
              pointerEvents: drag || loading ? "none" : "all",
              zIndex: 1000,
            }}
            id={`global-${task.id}`}
          >
            <div className="task-header">
              <div className="left">
                <DragIcone />
                <h3>{task.content}</h3>
                <h4 style={{ color: loading ? "#31c6b3" : "#000" }}>
                  {task.serial || task.rndCode}
                </h4>
              </div>
              <div className="right">
                {loading && col === "round" ? (
                  <Spinner
                    animation="grow"
                    size="2em"
                    role="status"
                    aria-hidden="true"
                    style={{
                      position: "absolute",
                      right: "10px",
                      color: "#31c6b3",
                    }}
                  />
                ) : loading ? (
                  <div />
                ) : Array.isArray(errorList) &&
                  errorList.map((el) => el.deviceSerial).includes(task.id) ? (
                  <div
                    className="svg"
                    onClick={removeDeviceError}
                    style={{
                      height: "30px",
                      width: "30px",
                      marginRight: "10px",
                      marginLeft: "auto",
                      cursor: "pointer",
                    }}
                  >
                    <SvgCroixNoir fill="white" />
                  </div>
                ) : (
                  <TaskButtons task={task} />
                )}
              </div>
            </div>
            {loading ? (
              <MessageCom
                isDevice={col === "device"}
                message={task.currentCommand}
              />
            ) : (
              <div className="task-body" id={`body-${task.id}`}>
                {task.rndCode ? (
                  <RoundInfo task={task} />
                ) : (
                  <DeviceInfo task={task} />
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { synchrocom } = state;
  const {
    drag,
    column,
    multi,
    multiList,
    loadingList,
    roundsLoading,
    labels,
    loadingLock,
    errorList,
  } = synchrocom;
  return {
    drag,
    column,
    multi,
    multiList,
    loadingList,
    roundsLoading,
    labels,
    loadingLock,
    errorList,
  };
}

const mapping = connect(mapStateToProps)(BestTask);

const connectedTask = withRouter(mapping);
const tr = withTranslation()(connectedTask);
export default tr;
