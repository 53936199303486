import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import SvgWarning from "../../../SvgComponents/SvgWarning";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import filterFactory from "react-bootstrap-table2-filter";
import BootstrapTable from "react-bootstrap-table-next";
import SvgCroixNoir from "../../../SvgComponents/croixNoir";
import synchroComActions from "../../../_actions/synchrocom.actions";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  maxHeight: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const ErrorLayout = ({ errorList, rndCode, match, dispatch, t }) => {
  const { params } = match;
  const [open, setOpen] = useState(false);
  const [columns, setColumns] = useState([]);

  const templateA = [
    {
      dataField: "type",
      text: t("all.column_text.name"),
      formatter: (cell) => {
        return t(`import_silex.error_text.${cell}`);
      },
    },
    {
      dataField: "lineType",
      text: t("import_silex.column_label.types"),
      sort: true,
    },
    {
      dataField: "line",
      text: t("import_silex.column_label.positions"),
      sort: true,
    },
    {
      dataField: "identification",
      text: t("import_silex.columns.identifiant"),
      sort: true,
    },
    {
      dataField: "description",
      text: t("import_silex.column_label.description"),
      formatter: (_, row) => {
        let translationKey = "",
          number;
        if (row.translationKey) {
          let splitTranslationKey = row.translationKey.split("_");
          number = splitTranslationKey.pop();
          if (!Number.isNaN(parseInt(number))) {
            translationKey = "." + splitTranslationKey.join("_");
          } else {
            translationKey = "." + row.translationKey;
          }
        }
        return t(`import_silex.error_text.${row.type}${translationKey}`, {
          value: row.value,
          number,
        });
      },
    },
  ];

  const templateB = [
    {
      dataField: "identifiant",
      text: t("import_silex.columns.identifiant"),
    },
    {
      dataField: "fieldName",
      text: t("import_silex.columns.field"),
      formatter: (cell) => t(`import_silex.error_data_field.${cell}`),
    },
    {
      dataField: "valueTemoin",
      text: t("import_silex.columns.temoin_value"),
      type: "number",
    },
    {
      dataField: "valueGenerated",
      text: t("import_silex.columns.generated_value"),
    },
  ];

  useEffect(() => {
    if (errorList) {
      setColumns(
        errorList.translationId !== "import_error" ? templateA : templateB
      );
    }
  }, []);

  const handleClick = () => {
    dispatch(synchroComActions.removeDeviceError(rndCode));
  };

  return (
    <div className={"info-layout-wrapper"}>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={"synchro-modal"}
      >
        <Box sx={style}>
          <SvgWarning height={"50px"} />
          <h5>{t("synchronisation.error_list", { tournee: rndCode })}</h5>
          <div className={"table-container"}>
            <BootstrapTable
              keyField="identification"
              data={errorList.errorValue}
              columns={columns}
              bootstrap4
              bordered
              condensed
              hover
              filter={filterFactory()}
              striped
              rowClasses="clickable"
            />
          </div>
        </Box>
      </Modal>
      <div className={"strip-band top"} />
      <div className={"content"}>
        <div className={"svg-box"} onClick={handleClick}>
          <SvgCroixNoir fill={"#ad1c2d"} height={"30px"} />
        </div>
        <SvgWarning height={"25px"} />
        <h5>{t("synchronisation.error_handling", { tournee: rndCode })}</h5>
        <button onClick={() => setOpen(true)}>
          {t("conflict_handling.conflict_open_button")}
        </button>
      </div>
      <div className={"strip-band bot"} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapping = connect(mapStateToProps)(ErrorLayout);
const connectedLayout = withRouter(mapping);
const tr = withTranslation()(connectedLayout);
export default tr;
