import React, { Component } from "react";
import isArray from "lodash/isArray";
import { AvField, AvForm, AvGroup } from "availity-reactstrap-validation";
import { Button, Col, Label, Row } from "reactstrap";
import Select from "react-select";
import { connect } from "react-redux";
import { autocompleteActions, meterActions, vmeterActions } from "../_actions";
import Stats from "./Stats";
import DateInterval from "./DateInterval";
import translate from "../_helpers/locale-helpers";
import { formatAlarmTypes } from "../_helpers/alarms-types-helper";
import { locales } from "../_interfaces/reducers";
import _ from "lodash";
import { withTranslation } from "react-i18next";
import AsyncSelectCustom from "./AsyncSelectCustom";

interface State {
  values: Object;
  name: string;
  alarms: Array<Object> | null | undefined;
  options: Array<any>;
  form?: any;
}

interface Condition {
  conditionValue: any;
  conditionType: any;
}

interface Props {
  locales: locales;
  form: {
    name: string;
    templateOption: {
      dataSourceProperty: {
        displayProperty: {
          condition: Condition[];
        };
        displayValue: string;
      };
    };
  };
  update?: Function;
  nextStep?: Function;
  locationId: number;
  isActive?: boolean;
  hashKey?: string;
  dispatch: Function;
  meters: any;
  t: Function;
}

/**
 * @class WidgetOptionComponent
 * @extends {Component}
 */
class WidgetOptionComponent extends Component<Props & {}, any> {
  /**
   * Définit les valeurs du formulaire, et créé
   * l'instance
   *
   * @param {Object} props Propriétés
   * @constructor
   * @memberof WidgetOptionComponent
   */
  constructor(props: any) {
    super(props);
    const { form } = this.props;
    const values: any = {};
    form.templateOption.dataSourceProperty.displayProperty.condition.forEach(
      (cond: any) => {
        values[cond.conditionTitle] =
          cond.conditionValue != null && cond.conditionValue.length !== 0
            ? cond.conditionValue
            : cond.conditionDefaultValue;
        if (cond.conditionType === "DateInterval") {
          if (null == cond.conditionValue) {
            cond.conditionValue = {
              name: "",
              value: null,
              zoom: [],
            };
          }
          values[`${cond.conditionTitle}Name`] = cond.conditionValue.name;
          values[`${cond.conditionTitle}Zoom`] = cond.conditionValue.zoom;
          values[`${cond.conditionTitle}Value`] = cond.conditionValue.value;
        }
      }
    );
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.handleInvalidSubmit = this.handleInvalidSubmit.bind(this);
    this.loadOptions = this.loadOptions.bind(this);
    this.state = {
      values,
      name: form.name,
      alarms: [],
      options: [],
      error: {},
    };
  }

  static getDerivedStateFromProps(props: any, state: any) {
    const { meters, vmeters } = props;
    let copyState = _.cloneDeep(state);
    if (meters.items && vmeters.items) {
      copyState.options = _.concat(
        meters.items.map((item: any) => ({
          value: item.serial,
          label: item.serial,
        })),
        vmeters.items.map((item: any) => ({
          value: item.name,
          label: item.name,
        }))
      );
    }
    return copyState;
  }

  /**
   * Gère la multi sélection des alarmes
   *
   * @param {Array} types Types
   * @method handleChangeSelect
   * @memberof WidgetOptionComponent
   */
  handleChangeSelect = (types: any) => {
    const { values }: any = this.state;
    values.AlarmType = types.map((el: any) => el.value);
    this.setState({
      values,
      alarms: types,
    });
  };

  /**
   * Gère le comportement à la soumission
   * invalide du formulaire
   *
   * @param {Object} event Evènement
   * @param {Object} errors Erreurs
   * @param {Object} values Valeurs du formulaire
   * @memberof WidgetOptionComponent
   */
  handleInvalidSubmit(event: any, errors: any, values: any) {
    this.setState({ values });
  }

  /**
   * Gère le comportement à la soumission du
   * formulaire, met à jour les informations
   * et passe à l'étape suivante
   *
   * @param {Object} event Evènement
   * @param {Object} values Valeurs du formulaire
   * @memberof WidgetOptionComponent
   */
  handleValidSubmit(event: any, values: any) {
    this.setState({ values });
    const { form, update, nextStep, dispatch, locationId } = this.props;
    const { templateOption } = form;
    const { dataSourceProperty } = templateOption;
    const { displayProperty } = dataSourceProperty;
    const { condition } = displayProperty;
    const err = {};
    condition.forEach((cond: any, i: number) => {
      const condCopy = cond;
      condCopy.conditionValue =
        values[cond.conditionTitle] || condCopy.conditionValue;
      condition[i] = condCopy;
      if (condition[i].conditionType === "DateInterval") {
        condCopy.conditionValue = {
          name: values[`${cond.conditionTitle}Name`],
          value: values[`${cond.conditionTitle}Value`],
          zoom: values[`${cond.conditionTitle}Zoom`],
        };
      }
      if (condCopy.conditionValue === "") {
        err[condCopy.conditionTitle] = true;
      }
    });
    console.log(
      condition,
      !(_.keys[err] && _.keys[err].length > 0),
      _.keys(err),
      err
    );
    this.setState({
      name: values.name,
      error: err,
    });

    if (
      update != undefined &&
      nextStep != undefined &&
      !(_.keys(err) && _.keys(err).length > 0)
    ) {
      console.log(
        "name: ",
        values.name,
        " templateOption: ",
        form.templateOption
      );
      update("name", values.name);
      update("templateOption", form.templateOption);
      nextStep();
    }
  }

  componentDidMount() {
    const { dispatch, locationId, meters } = this.props;
    if (!meters.items) {
      dispatch(meterActions.getAllWithChildren(locationId));
      dispatch(vmeterActions.getAllWithChildren(locationId));
    }
  }

  handleInputChange = (e: any, item: any) => {
    const { form, update, nextStep } = this.props;
    const { templateOption } = form;
    const { dataSourceProperty }: any = templateOption;
    const { displayProperty }: any = dataSourceProperty;
    const { condition } = displayProperty;
    if (
      dataSourceProperty.displayValue === "MeterMultiReadingConsumption" ||
      dataSourceProperty.displayValue === "MeterMultiReadingValue" ||
      dataSourceProperty.displayValue === "RadioReadingValue" ||
      dataSourceProperty.displayValue === "MeterYield"
    ) {
      condition.find(
        (el) => el.conditionTitle === item.conditionTitle
      ).conditionValue = e.value;
    } else {
      condition.find(
        (el) => el.conditionTitle === "MeterSerialNumber"
      ).conditionValue = e.value;
    }
    this.setState({ form: form });
  };

  filterOptions = (inputValue: string) => {
    const { options } = this.state;
    return _.slice(
      options.filter((option: any) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      ),
      0,
      20
    );
  };

  loadOptions = (inputValue: any, callback: any) => {
    setTimeout(() => {
      callback(this.filterOptions(inputValue));
    }, 1000);
  };

  handleError = (title) => {
    const { error } = this.state;
    const copy = _.cloneDeep(error);
    copy[title] = null;
    this.setState({
      error: copy,
    });
  };

  /**
   * Construit tout le formulaire, en fonction des types
   * de champs
   *
   * @returns {JSX} Le composant
   * @method render
   * @memberof WidgetOptionComponent
   */
  render() {
    const { isActive, form, locales, t } = this.props;
    const { values, name, error }: any = this.state;
    let { alarms } = this.state;
    if (isArray(values.AlarmType) && values.AlarmType.length > 0) {
      alarms = values.AlarmType.map((el: any) => ({
        label: translate("fr", "alarmType", el, locales.locale),
        value: el,
      }));
    }

    if (!isActive) return null;
    return (
      <div>
        <AvForm
          onValidSubmit={this.handleValidSubmit}
          onInvalidSubmit={this.handleInvalidSubmit}
          className="config-form"
        >
          <AvField
            name="name"
            value={name}
            label={t("all.column_text.name")}
            required
            helpMessage={t("all.text.required")}
            errorMessage={t("widget_option.error_msg.widget_name_error")}
          />
          {form.templateOption.dataSourceProperty.displayProperty.condition.map(
            (item: any) => (
              <div key={item.conditionTitle}>
                {item.conditionType === "String" &&
                  !item.conditionMandatory && (
                    <AvField
                      key={item.conditionTitle}
                      name={item.conditionTitle}
                      value={values[item.conditionTitle]}
                      label={translate(
                        "fr",
                        "conditionTitle",
                        item.conditionTitle,
                        locales.locale
                      )}
                    />
                  )}

                {item.conditionType === "String" && item.conditionMandatory && (
                  <AvField
                    key={item.conditionTitle}
                    name={item.conditionTitle}
                    value={values[item.conditionTitle]}
                    label={translate(
                      "fr",
                      "conditionTitle",
                      item.conditionTitle,
                      locales.locale
                    )}
                    required
                    helpMessage={t("all.text.required")}
                    errorMessage="Le champ est obligatoire"
                  />
                )}

                {item.conditionType === "AutoCompleteString" &&
                  item.conditionMandatory && (
                    <AsyncSelectCustom
                      item={item}
                      optionsCallback={this.loadOptions}
                      handleChange={this.handleInputChange}
                      handleError={this.handleError}
                      error={error[item.conditionTitle]}
                    />
                  )}

                {item.conditionType === "Date" && !item.conditionMandatory && (
                  <AvGroup key={item.conditionTitle}>
                    <Label for={item.conditionTitle}>
                      {" "}
                      {t(
                        `widget.condition_title.${item.conditionTitle.toLowerCase()}`
                      )}
                    </Label>
                    <AvField
                      name={item.conditionTitle}
                      type="date"
                      value={values[item.conditionTitle]}
                    />
                  </AvGroup>
                )}

                {item.conditionType === "Date" && item.conditionMandatory && (
                  <AvGroup key={item.conditionTitle}>
                    <Label for={item.conditionTitle}>
                      {" "}
                      {t(
                        `widget.condition_title.${item.conditionTitle.toLowerCase()}`
                      )}
                    </Label>
                    <AvField
                      name={item.conditionTitle}
                      required
                      helpMessage={t("all.text.required")}
                      errorMessage="Le champ est obligatoire"
                      value={values[item.conditionTitle]}
                      type="date"
                    />
                  </AvGroup>
                )}

                {item.conditionType === "DateInterval" &&
                  item.conditionMandatory && (
                    <DateInterval
                      conditions={item.conditionHelper}
                      form={form}
                      title={item.conditionTitle}
                      values={values}
                      locales={locales}
                    />
                  )}

                {item.conditionType === "CheckList" &&
                  item.conditionMandatory && (
                    <AvGroup key={item.conditionTitle}>
                      <Label for={item.conditionTitle}>
                        {" "}
                        {t(
                          `widget.condition_title.${item.conditionTitle.toLowerCase()}`
                        )}
                      </Label>
                      <Select
                        options={formatAlarmTypes()}
                        isMulti
                        onChange={this.handleChangeSelect}
                        value={alarms}
                      />
                      <AvField
                        type="hidden"
                        name={item.conditionTitle}
                        value={values[item.conditionTitle]}
                      />
                    </AvGroup>
                  )}
              </div>
            )
          )}
          <Row>
            <Col md="4">
              <Stats {...this.props} />
            </Col>
            <Col md="8" className="text-right">
              <Button
                type="submit"
                color="primary"
                style={{ marginLeft: "5px" }}
              >
                {t("all.button.next")}
              </Button>
            </Col>
          </Row>
        </AvForm>
        <div />
      </div>
    );
  }

  private getConditionTitle(item: any): string {
    let pos: number = item.conditionTitle.indexOf("_");
    if (pos !== -1) {
      return item.conditionTitle.substring(0, pos + 1);
    }
    return item.conditionTitle;
  }
}

function mapStateToProps(state: any) {
  const { locales, meters, vmeters } = state;
  return {
    locales,
    meters,
    vmeters,
  };
}
const connectedWidgetOptionComponent = connect(mapStateToProps)(
  WidgetOptionComponent
);
const tr = withTranslation()(connectedWidgetOptionComponent);
export default tr;
