import React, { Component, useState } from "react";
import _ from "lodash";
import { withTranslation } from "react-i18next";
import { translate } from "../_helpers";
import locale from "../_shared/Locale.json";
import Slider from "@mui/material/Slider";

const SeuilComponent = (props) => {
  const { handleChange, unit, alarmTemplates, t } = props;

  const index = _.get(props, "alarmTemplates.content")
    ? props.alarmTemplates.content.dataSourceProperty.condition.findIndex(
        (el: any) => el.conditionTitle === "RangeConsumption"
      )
    : null;

  const [seuil, setSeuil]: any = useState({
    inf: _.isNumber(
      _.get(
        props,
        `alarmTemplates.content.dataSourceProperty.condition[${index}].conditionValue.inf`
      )
    )
      ? _.get(
          props,
          `alarmTemplates.content.dataSourceProperty.condition[${index}].conditionValue.inf`
        )
      : "0",
    sup: _.isNumber(
      _.get(
        props,
        `alarmTemplates.content.dataSourceProperty.condition[${index}].conditionValue.sup`
      )
    )
      ? _.get(
          props,
          `alarmTemplates.content.dataSourceProperty.condition[${index}].conditionValue.sup`
        )
      : "100",
  });

  const handleSeuilValue = (e: any, value: any) => {
    const newSeuil = seuil;
    newSeuil.inf = value[0];
    newSeuil.sup = value[1];
    setSeuil(newSeuil);
    handleChange(newSeuil);
  };

  function valuetext(value: number) {
    return `${value}%`;
  }

  return (
    <div className="wrapper">
      <h5>
        {t("all.text.threshold")} ({translate("fr", "unit", unit, locale)})
      </h5>
      <div className="flex-box">
        <Slider
          getAriaLabel={() => "Seuil"}
          value={[seuil.inf, seuil.sup]}
          onChange={handleSeuilValue}
          getAriaValueText={valuetext}
          valueLabelFormat={valuetext}
          valueLabelDisplay="on"
        />
      </div>
    </div>
  );
};

const tr = withTranslation()(SeuilComponent);
export default tr;
