import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import "./style.scss";

const PasswordInput = ({ handleChange, value, submitted, t }) => {
  useEffect(() => {
    const password = document.getElementById("password-input");
    const toggler = document.getElementById("toggler");
    const showHidePassword = () => {
      if (password.type === "password") {
        password.setAttribute("type", "text");
        toggler.classList.remove("fa-eye-slash");
      } else {
        toggler.classList.add("fa-eye-slash");
        password.setAttribute("type", "password");
      }
    };
    toggler.addEventListener("click", showHidePassword);
  }, []);
  return (
    <div
      className={
        "form-group" +
        (submitted && !value ? " has-error" : "") +
        " password-input-wrapper"
      }
    >
      <input
        type="password"
        className="form-control"
        name="password"
        placeholder={t("all.user.pwd")}
        value={value}
        onChange={handleChange}
        id={"password-input"}
      />
      <i id="toggler" className="far fa-eye fa-eye-slash" />
      {submitted && !value && (
        <div className="help-block">Le mot de passe est obligatoire</div>
      )}
    </div>
  );
};

const tr = withTranslation()(PasswordInput);
export default tr;
