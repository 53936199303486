import React, { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { importActions } from "../_actions";
import SvgConflit from "../SvgComponents/Conflit";

const style = {
  wrapper: {
    color: "#000",
    width: "100%",
    paddingTop: "20px",
  },
  flex: {
    display: "flex",
    flexDirection: "column",
    padding: "0 40px",
    marginTop: "10px",
  },
};

const ImportConflict = ({ formats, name, file, user, dispatch, t }) => {
  const [formatIndex, setFormatIndex] = useState(0);

  const handleChange = (e) => {
    setFormatIndex(e.target.value);
  };

  const submit = () => {
    const formData = new FormData();
    formData.append("file", file);
    dispatch(
      importActions.importConstructeur(
        formData,
        user.userName,
        formats[formatIndex]
      )
    );
  };

  return (
    <div style={style.wrapper} className={"conflict-container flex vertical"}>
      <div className={"flex"}>
        <SvgConflit width={"30px"} />
        <h2 style={style.h2}>{t("import_manufacturer.conflict.resolution")}</h2>
      </div>

      <div style={style.flex}>
        <span>{t("import_manufacturer.conflict.select")}</span>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="format-label">
            {t("import_manufacturer.conflict.format")}
          </InputLabel>
          <Select
            labelId="format-label"
            id="demo-simple-select-standard"
            value={formatIndex}
            onChange={handleChange}
            label={t("import_manufacturer.conflict.format")}
            style={{ padding: "8px 14px" }}
            placeholder={t("import_manufacturer.conflict.format")}
            sx={{
              boxShadow: "none",
              ".MuiOutlinedInput-notchedOutline": { borderColor: "#F2AE2E" },
            }}
          >
            {formats.map((el, index) => (
              <MenuItem value={index}>{el}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <Button
        variant="contained"
        className={"success"}
        style={{ width: "130px", marginTop: "30px", alignSelf: "center" }}
        onClick={submit}
      >
        Valider
      </Button>
    </div>
  );
};

function mapStateToProps(state) {
  const { imports, authentication } = state;
  const { manufacturer } = imports;
  const { formats, import_filename } = manufacturer;
  const { user } = authentication;
  return {
    formats,
    name: import_filename,
    user,
  };
}

const connectedComponent = connect(mapStateToProps)(ImportConflict);
const tr = withTranslation()(connectedComponent);
export default tr;
