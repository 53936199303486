import React, { useEffect, useState } from "react";
import Alert from "@mui/material/Alert";
import "./style.scss";
import { connect } from "react-redux";

/* Severity
 * success / info / warning / error
 */

const Chip = ({ message, severity, dispatch }) => {
  const [display, setDisplay] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setDisplay(false);
      setTimeout(() => {
        dispatch({ type: "CLEAR_ERROR" });
      }, 1500);
    }, 15000);
  }, []);

  return (
    <div className={`chip-wrapper ${display ? "open" : "hide"}`}>
      <Alert severity={severity || "success"}>{message}</Alert>
    </div>
  );
};

function mapStateToProps(state) {
  return {};
}
const connectedComponent = connect(mapStateToProps)(Chip);
export default connectedComponent;
