import { roundReportConstants } from "../_constants";
import { ReportSetting } from "../_interfaces/RoundReportTypes";
import roundReportService from "../_services/roundReport.service";

function getAllReport(roundCode: string) {
  function request() {
    return { type: roundReportConstants.GET_ALL_REPORT_REQUEST };
  }
  function success(allRoundReport: any) {
    return {
      type: roundReportConstants.GET_ALL_REPORT_SUCCESS,
      allRoundReport,
    };
  }
  function failure(error: Object) {
    return { type: roundReportConstants.GET_ALL_REPORT_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    roundReportService
      .getAllReport(roundCode)
      .then((allRoundReport: any) => dispatch(success(allRoundReport)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function getReport(
  reportKey: string,
  rndCode: string,
  reportSetting: ReportSetting,
  filters: any,
  optionor: string
) {
  function request() {
    return { type: roundReportConstants.GET_REPORT_REQUEST };
  }
  function success(roundReport: any) {
    return {
      type: roundReportConstants.GET_REPORT_SUCCESS,
      roundReport,
      reportKey,
    };
  }
  function failure(error: Object) {
    return { type: roundReportConstants.GET_REPORT_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    roundReportService
      .getReport(reportKey, rndCode, { ...reportSetting, filters, optionor })
      .then((roundReport: any) => {
        const fileData = roundReport.fileData;
        const byteCharacters = atob(fileData);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        if (byteArray.length / 1024 > 10000) {
          const blob = new Blob([byteArray], { type: "text" });
          const url: string = URL.createObjectURL(blob);
          const a: HTMLAnchorElement = document.createElement("a");
          a.href = url;
          a.download = reportKey + ".pdf";
          console.log(a);
          a.click();
        }
        console.log(
          byteArray.length,
          " OCTET ",
          byteArray.length / 1024,
          " MO"
        );
        dispatch(success(roundReport));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function clear() {
  return {
    type: roundReportConstants.CLEAR,
  };
}

const roundReportActions = {
  getAllReport,
  getReport,
  clear,
};

export default roundReportActions;
