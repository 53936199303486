import React, { Component } from "react";
import { connect } from "react-redux";
import DoughtnutChart from "./DoughnutChart";
import BarChart from "./BarChart";
import { localeActions, locationActions, tourneeActions } from "../_actions";
import { locales, locations } from "../_interfaces/reducers";
import _ from "lodash";
import "chartjs-plugin-datalabels";
import Chart from "chart.js";
import { urlGiveAway } from "../_helpers/statistique-helper";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

Chart.helpers.merge(Chart.defaults.global.plugins.datalabels, {
  labels: {
    title: {
      font: {
        weight: "bold",
      },
    },
  },

  formatter: function (value: any, context: any) {
    return value !== 0 ? value : "";
  },
});

interface Props {
  locales: locales;
  dispatch: any;
  locationId: number;
  match: any;
  locations: locations;
  location: any;
  history: any;
  drag: Boolean;
  handleChange: any;
  tournee: any;
  t: Function;
}

interface State {
  dougDataS: any;
  barDataS: any;
  init: boolean;
}

const colorList = {
  radioModelReads: {
    colorList: ["#ffd000", "#ff8800", "#ff5900", "#b81f00", "#6e1200", "#333"],
    hoverColorList: [
      "#ffdf4f",
      "#ffad4f",
      "#ff8340",
      "#ff6040",
      "#752c1d",
      "#666",
    ],
  },
  amrStatut: {
    colorList: ["#91E0F2", "#B2EF9B", "#A63C76", "#333"],
    hoverColorList: ["#53ABC4", "#D7D7D7", "#6AC3AF", "#666"],
  },
  consumptionState: {
    colorList: ["#B2EF9B", "#31c6b3", "#34827a", "#333"],
    hoverColorList: ["#53ABC4", "#D7D7D7", "#6AC3AF", "#666"],
  },
};

const stubDoughtnut = [
  {
    title: "Statut des indexés",
    labels: [
      "Consommation faible",
      "Consommation forte",
      "Consommation nulle",
      "Consommation normale",
      "Pas d'index",
    ],
    data: [147, 272, 876, 2806, 53],
    colorList: ["#333", "lightgrey", "#A7751D", "#FF931E", "#F9C264"],
    hoverColorList: ["#666", "#D7D7D7", "#C7751D", "#DFA137", "#FAD89D"],
  },
];

class TourneeStat extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      dougDataS: [],
      barDataS: [],
      init: false,
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleBarClick = this.handleBarClick.bind(this);
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    const { tournee, t } = props;
    const newState: any = _.cloneDeep(state);
    const convertList = TourneeStat.getConvertList(t);
    newState.dougDataS = [];
    newState.barDataS = [];
    if (tournee.info) {
      const modifiedMetersOnly =
        tournee.info.modifiedMeters - tournee.info.indexedMeters;
      newState.dougDataS.push({
        title: t("tournee_stat.container_title.status_edited"),
        labels: [
          t("all.text.indexed_plural"),
          t("tournee_stat.container_label.edited_not_indexed_plural"),
          t("tournee_stat.container_label.not_edited_not_indexed_plural"),
        ],
        data: [
          tournee.info.indexedMeters,
          modifiedMetersOnly,
          tournee.info.totalCpt -
            tournee.info.indexedMeters -
            modifiedMetersOnly,
        ],
        colorList: ["#ffd1a1", "#FF931E", "#9c5913"],
        hoverColorList: ["#d4c6b8", "#C7751D", "#a36d34"],
      });
    }
    if (tournee.stats && tournee.stats.doughnutChart) {
      tournee.stats.doughnutChart.map((el: any) => {
        newState.dougDataS.push({
          title: convertList[el.title],
          labels: _.keys(el.data).map((label: any) =>
            convertList[label] ? convertList[label] : label
          ),
          data: _.values(el.data),
          colorList: colorList[el.title].colorList,
          hoverColorList: colorList[el.title].hoverColorList,
        });
      });
      tournee.stats.barChart.map((el: any) =>
        newState.barDataS.push({
          title: t("tournee_stat.container_title.nb_meter_year"),
          labels: _.keys(el.data),
          data: _.values(el.data),
          label: t("tournee_stat.container_title.nb_meter"),
          backgroundColor: "#31c6b3",
          borderColor: "#333",
          hoverBackgroundColor: "#333",
          hoverBorderColor: "#31c6b3",
        })
      );
    }
    console.log(props, state);
    return newState;
  }

  static getConvertList(t: any) {
    return {
      radioModelReads: t("tournee_stat.container_title.radio_type"),
      amrStatut: t("tournee_stat.container_title.radio_read_state"),
      amrWithoutRead: t("tournee_stat.container_state_read.not_read"),
      notAmr: t("tournee_stat.container_state_read.not_radio"),
      withAlarm: t("tournee_stat.container_state_read.read_with_alarm"),
      withoutAlarm: t("tournee_stat.container_state_read.read_with_no_alarm"),
      consumptionState: t("tournee_stat.container_title.cons_state"),
      "Conso nulle": t("all.read_meter.empty_cons"),
      "Conso forte": t("all.read_meter.high_cons"),
      "Conso faible": t("all.read_meter.low_cons"),
      "Conso normale": t("all.read_meter.normal_cons"),
      OK: t("all.text.ok"),
    };
  }

  componentDidMount() {
    const { dispatch, match } = this.props;
    const { tourneeCode, locationId } = match.params;
    dispatch(localeActions.load());
    console.log(tourneeCode);
    if (tourneeCode) {
      dispatch(tourneeActions.getTournee(tourneeCode));
      dispatch(tourneeActions.getStats(tourneeCode));
    }
    dispatch(locationActions.get(locationId));
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ) {
    const { locations, dispatch } = this.props;
    const { init } = this.state;
    if (locations.fetchedLocation && !init) {
      dispatch(tourneeActions.getStats(locations.fetchedLocation.code));
      locations.fetchedLocation.code !== "TOURNEES" &&
        dispatch(tourneeActions.getTournee(locations.fetchedLocation.code));
      this.setState({ init: true });
    }
  }

  handleClick(event: any, chart: any, chartInfo: any) {
    const { match, history, t } = this.props;
    const { params } = match;
    const { locationId } = params;
    console.log(this.props, this.state);
    if (chart[0]) {
      if (chartInfo.title === t("tournee_stat.container_title.radio_type")) {
        if (locationId) {
          history.push(
            `/locations/${locationId}/fiche?radioType=${chart[0]._model.label}`
          );
        } else {
          history.push(
            `/gestionnaires/${params.GestionnaireId}/synchronisation/tournees/${params.tourneeCode}/fiche?radioType=${chart[0]._model.label}`
          );
        }
      } else {
        if (locationId) {
          const urlOption = urlGiveAway(chart[0]._model.label, t);
          history.push(`/locations/${locationId}/fiche?${urlOption}`);
        } else {
          const urlOption = urlGiveAway(chart[0]._model.label, t);
          history.push(
            `/gestionnaires/${params.GestionnaireId}/synchronisation/tournees/${params.tourneeCode}/fiche?${urlOption}`
          );
        }
      }
    }
  }

  handleBarClick(event: any, chart: any) {
    const { match, history } = this.props;
    const { params } = match;
    const { locationId } = params;
    if (chart[0]) {
      if (locationId) {
        history.push(
          `/locations/${locationId}/fiche?annee=${chart[0]._model.label}`
        );
      } else {
        history.push(
          `/gestionnaires/${params.GestionnaireId}/synchronisation/tournees/${params.tourneeCode}/fiche?annee=${chart[0]._model.label}`
        );
      }
    }
  }

  render() {
    const { dougDataS, barDataS } = this.state;
    console.log(dougDataS);
    return (
      <div className="statistique-tournee container-fluid">
        {dougDataS.map((el: any, index: any) => (
          <DoughtnutChart
            index={index}
            chartInfo={el}
            handleClick={this.handleClick}
          />
        ))}
        {barDataS.map((el: any) => (
          <BarChart chartInfo={el} handleClick={this.handleBarClick} />
        ))}
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication, meters, locations, locales, radios, pdis, tournee } =
    state;
  const { user } = authentication;

  return {
    alert,
    user,
    meters,
    radios,
    pdis,
    locations,
    locales,
    tournee,
  };
}

const connectedTourneeFiches = withRouter(
  connect(mapStateToProps)(TourneeStat)
);
const tr = withTranslation()(connectedTourneeFiches);
export default tr;
