import React, { useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import moment from "moment";
import {
  PopoverBody,
  PopoverHeader,
  UncontrolledPopover,
  UncontrolledTooltip,
} from "reactstrap";
import { withTranslation } from "react-i18next";
import getC from "../TourneeFiches/Picto_correspondance";
import NoFilled from "../Message/NoFilled";
import ArrowRightVert from "../SvgComponents/FlecheAller";
import { tourneeActions } from "../_actions";
import { history } from "../_helpers";

function TourneeInfo(props: any) {
  const { pdis, t, meters, dispatch, tournee } = props;
  const info: any = _.get(pdis, "fetchedPdi.general.tournee");
  const type = _.get(
    _.find(
      _.get(meters, "allMetersInfo"),
      (meter) => meter.general.endDate !== null
    ),
    "general.fluidType"
  );

  useEffect(() => {
    dispatch(
      tourneeActions.getLocationIdWithCode(
        _.get(pdis, "fetchedPdi.general.tournee.codeTournee")
      )
    );
  }, []);

  const handleClick = () => {
    console.log(tournee, props);
    history.push({
      pathname: `/locations/${tournee?.locationId}`,
    });
  };
  return (
    <>
      {info && (
        <div className="tournee-info-container">
          <div className="c35 flex-box">
            <div style={{ width: "100%" }}>
              <h3>{info.libelleTournee}</h3>
              <div className="flex-ref">
                <span>{info.codeTournee}</span>
                <div className="round" id="go-to-round" onClick={handleClick}>
                  <ArrowRightVert width="18px" height="auto" />
                </div>
                <UncontrolledTooltip target="go-to-round">
                  {t(`all.round.go_to_round`)}
                </UncontrolledTooltip>
              </div>
            </div>
          </div>
          <div className="c10">
            <span id="status_releve">
              {getC(info.status, { width: "60%" })}
            </span>
          </div>
          <UncontrolledTooltip target="status_releve">
            {t(`releve_meter_status.text.${info.status}`)}
          </UncontrolledTooltip>

          <div className="c25">
            <span>{t("columns.currentDate")} </span>
            <h3>
              {moment.utc(info.currentReleveDate).isValid() ? (
                moment.utc(info.currentReleveDate).format(`DD/MM/YYYY HH:mm`)
              ) : (
                <NoFilled />
              )}
            </h3>
          </div>
          <div className="c20">
            <span>
              {t("columns.currentIndex")}{" "}
              {type && (
                <span
                  dangerouslySetInnerHTML={{
                    __html: t(`meter.unit.${type}`, {
                      interpolation: { escapeValue: false },
                    }),
                  }}
                />
              )}
            </span>
            <h3>
              {Number.isFinite(info.currentReleveIndex) ? (
                info.currentReleveIndex
              ) : (
                <NoFilled />
              )}
            </h3>
          </div>
          <div className="c20">
            <span>
              {t("columns.consumption")}{" "}
              {type && (
                <span
                  dangerouslySetInnerHTML={{
                    __html: t(`meter.unit.${type}`, {
                      interpolation: { escapeValue: false },
                    }),
                  }}
                />
              )}
            </span>
            <h3>
              {Number.isFinite(info.currentReleveConso) ? (
                info.currentReleveConso
              ) : (
                <NoFilled />
              )}
            </h3>
          </div>
          <div className="c20">
            <span>{t("all.text.phone", { count: _.size(info.loaded) })}</span>
            <h3
              style={
                _.size(info.loaded) > 1
                  ? { color: "#31c6b3", textDecoration: "underline" }
                  : {}
              }
              className={_.size(info.loaded) > 1 ? "clickable" : ""}
            >
              <span id="devicesNumber">
                {_.size(info.loaded) === 1
                  ? info.loaded
                  : _.size(info.loaded) > 1
                  ? _.size(info.loaded)
                  : t("all.text.none_male")}
              </span>
            </h3>
            {_.size(info.loaded) > 1 && (
              <UncontrolledPopover
                trigger="legacy"
                placement="top"
                target="devicesNumber"
              >
                <PopoverHeader>
                  {t("tournee_band.text.list_devices")}
                </PopoverHeader>
                <PopoverBody style={{ display: "grid" }}>
                  <ul style={{ paddingInlineStart: "10px" }}>
                    {info.loaded.map((el) => (
                      <li>{el}</li>
                    ))}
                  </ul>
                </PopoverBody>
              </UncontrolledPopover>
            )}
          </div>
        </div>
      )}
    </>
  );
}

function mapStateToProps(state: any) {
  const { pdis, meters, tournee } = state;

  return {
    pdis,
    meters,
    tournee,
  };
}

const TourneeInfoConnected = connect(mapStateToProps)(TourneeInfo);
const tr = withTranslation()(TourneeInfoConnected);
export default tr;
