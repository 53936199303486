import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import sensorActions from "../_actions/sensor.actions";
import Loading from "../_animations/Loading";
import BurgerHeader from "../SvgComponents/BurgerHeader";
import { locationActions } from "../_actions";
import SvgCapteur from "../SvgComponents/Capteur";

class SensorList extends Component<any> {
  componentDidMount() {
    const {
      dispatch,
      match: {
        params: { locationId },
      },
    } = this.props;
    dispatch(sensorActions.getAllByLocationId(locationId));
    dispatch(locationActions.get(locationId));
  }

  render() {
    const {
      sensor,
      match: {
        params: { locationId },
      },
      t,
    } = this.props;
    const linkToLinkSensor = `/locations/${locationId}/sensor/link`;
    const unlinkToLinkSensor = `/locations/${locationId}/sensor/unlink`;
    const columns = [
      {
        dataField: "info.sensorSerial",
        text: t("sensor.text.serial"),
        sort: true,
        filter: textFilter({
          placeholder: t("sensor.text.serial"),
        }),
      },
      {
        dataField: "info.sensorDevEUI",
        text: t("telereleveinfo.text.deveui"),
        sort: true,
        filter: textFilter({
          placeholder: t("telereleveinfo.text.deveui"),
        }),
      },
      {
        dataField: "info.type",
        text: t("sensor.text.sensor_type"),
        sort: true,
        filter: textFilter({
          placeholder: t("sensor.text.sensor_type"),
        }),
        formatter: (cell: string) => t(`sensor.type.${cell}`),
      },
    ];
    return (
      <div className="col-12">
        {sensor.items ? (
          <div className="mask-alarm table-info-container">
            {" "}
            <div>
              <h2>
                <span>
                  <SvgCapteur height="1.2em" width="1.2em" fill="#31c6b3" />
                </span>
                {t("sensor.text.sensor_plural")}
                {false && (
                  <UncontrolledDropdown
                    className="float-right"
                    style={{ position: "absolute", right: "30px", top: "10px" }}
                  >
                    <DropdownToggle nav>
                      <BurgerHeader fill="#808080" height="1em" width="1em" />
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem href={linkToLinkSensor}>
                        {t("all.text.link")}
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem href={unlinkToLinkSensor}>
                        {t("all.text.unlink")}
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                )}
              </h2>
            </div>
            <BootstrapTable
              keyField="id"
              data={sensor.items}
              columns={columns}
              bootstrap4
              bordered={false}
              hover
              rowClasses="clickable"
              rowEvents={{
                onClick: (e, row) => {
                  const {
                    history,
                    match: {
                      params: { locationId },
                    },
                  } = this.props;
                  history.push({
                    pathname: `/locations/${locationId}/sensor/${row.info.sensorId}`,
                  });
                },
              }}
              filter={filterFactory()}
              pagination={sensor.items.length > 10 ? paginationFactory() : null}
            />
          </div>
        ) : (
          <Loading />
        )}
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { sensor } = state;
  return {
    sensor,
  };
}

const wt: any = connect(mapStateToProps)(SensorList);
export default withTranslation()(withRouter(wt));
