import React, { Component, Fragment } from "react";
import moment from "moment";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import Switch from "rc-switch";
import cloneDeep from "lodash/cloneDeep";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { connect } from "react-redux";
import history from "../_helpers/history";
import { formatAlarmData } from "../_helpers/alarms-types-helper";
import AlarmSelector from "../Meter/AlarmSelector";
import chartTemplate from "../_shared/ChartTemplate.json";
import alarmTemplate from "../_shared/AlarmTemplate.json";

import { Chart, convertDate } from "../Widget";
import { formatAdditionalData } from "../_helpers/statements-helper";
import {
  colorActions,
  localeActions,
  locationActions,
  vmeterActions,
} from "../_actions";
import CompteurVert from "../SvgComponents/CompteurVert";
import HistoriqueVert from "../SvgComponents/HistoriqueVert";
import AlarmeVert from "../SvgComponents/AlarmeVert";
import Edition from "../SvgComponents/EditionBleu";
import DerniereReleveVert from "../SvgComponents/DerniereReleveVert";
import { colors, locales, locations } from "../_interfaces/reducers";
import ListTools from "../List/ListTools";
import TinyGmap from "../_components/TinyGmap";
import { chart2table, translate } from "../_helpers";
import { widgetConstants } from "../_constants";
import { downloadCSV } from "../FileDownload";
import { Sheet } from "../_entities/sheet";
import { PrintableSourceSheet } from "../SourceSheet";
import { printMultiSheet } from "../_helpers/pdf-helper";
import { VLastRead } from "../_entities/virtual";
import { User } from "../_entities/user";
import ErrorBand from "../Bands/Error";
import LoadingBand from "../Bands/Loading";
import BestDateComponent from "../_components/BestDateComponent";
import SvgChartBarVerticalNotAvailable from "../SvgComponents/ChartBarVerticalNotAvailable";
import { withTranslation } from "react-i18next";
import Loading from "../_animations/Loading";
import _ from "lodash";
import BarChart from "./BarChart";
import AlarmBarChart from "./AlarmBarChart";

interface Props {
  vmeters: any;
  locations: locations;
  location: any;
  dispatch: Function;
  match: any;
  locales: locales;
  colors: colors;
  user: User;
  t: Function;
  dashboards: any;
  users: any;
}

interface State {
  template: any;
  consumption: any;
  alarm: any;
  graphType: string;
  isOpen: boolean;
  onlyValid: boolean;
  readsFiltered: any[];
  consoFiltered: any[];
  alarmsFiltered: any[];
  isMasked: boolean;
}

/**
 * Page d'information d'un compteur virtuel
 *
 * @class VirtualMeter
 * @extends Component
 */
class VirtualMeter extends Component<Props, State> {
  static getDerivedStateFromProps(props: Props, state: State) {
    const { vmeters } = props;
    const { fetchedVMeter } = vmeters;
    if (fetchedVMeter) {
      if (fetchedVMeter.statements && !state.onlyValid) {
        state.readsFiltered = fetchedVMeter.statements;
      }
      if (fetchedVMeter.consumption && !state.onlyValid) {
        state.consoFiltered = fetchedVMeter.consumption;
      }
      if (fetchedVMeter.alarms && !state.onlyValid) {
        state.alarmsFiltered = fetchedVMeter.alarms;
      }
    }
    return state;
  }

  /**
   * @constructor
   * @param {Props} props Props du composant
   */
  constructor(props: Props) {
    super(props);

    const defaultMinDate = moment()
      .startOf("month")
      .format(`${moment.HTML5_FMT.DATE} ${moment.HTML5_FMT.TIME_SECONDS}`);
    const defaultMaxDate = moment()
      .endOf("month")
      .format(`${moment.HTML5_FMT.DATE} ${moment.HTML5_FMT.TIME_SECONDS}`);
    const alarm: any = cloneDeep(alarmTemplate);
    const template: any = cloneDeep(chartTemplate);
    template.dataSourceProperty.displayUnit = "m3";
    template.dataSourceProperty.displayProperty.condition[1].conditionValue =
      defaultMinDate;
    template.dataSourceProperty.displayProperty.condition[2].conditionValue =
      defaultMaxDate;
    template.dataSourceName = "Meter";

    template.dataSourceProperty.displayProperty.condition[0].conditionTitle =
      "MeterSerialNumber";
    template.dataSourceProperty.displayValue = "MeterReadingValue";
    template.dataSourceProperty.displayID = widgetConstants.READINDEXWIDGET;

    const consumption: any = cloneDeep(template);
    consumption.dataSourceProperty.displayID =
      widgetConstants.READCONSUMTIONWIDGET;
    consumption.dataSourceProperty.displayUnit = "m3";
    template.dataSourceProperty.displayValue = "MeterConsumptionValue";

    alarm.dataSourceProperty.displayProperty.condition[1].conditionValue =
      defaultMinDate;
    alarm.dataSourceProperty.displayProperty.condition[2].conditionValue =
      defaultMaxDate;
    alarm.dataSourceProperty.displayID = widgetConstants.VIRTUALALARMWIDGET;

    this.state = {
      template,
      consumption,
      alarm,
      graphType: template.dataSourceProperty.displayProperty.displayType,
      isOpen: false,
      onlyValid: false,
      readsFiltered: [],
      consoFiltered: [],
      alarmsFiltered: [],
      isMasked: false,
    };
  }

  /**
   * @method componentDidMount
   */
  componentDidMount() {
    const { dispatch, match, location, locations } = this.props;
    const { locationId } = match.params;
    const params = location.search;
    const { template, consumption, alarm } = this.state;
    dispatch(localeActions.load());
    dispatch(colorActions.load());
    dispatch(locationActions.get(locationId));
    dispatch(
      vmeterActions.getFullInfos(
        locationId,
        params,
        template,
        consumption,
        alarm
      )
    );
  }

  /**
   * @method componentWillUnmount
   */
  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(locationActions.clear());
    dispatch(vmeterActions.clear());
  }

  /**
   * Teste les permissions d'édition de compteur virtuel
   *
   * @method couldEditOrDeleteVirtualMeter
   * @returns {boolean} Le test
   */
  couldEditOrDeleteVirtualMeter = () => {
    const { user, locations, dashboards, users } = this.props;

    const roleList = ["DIOPTASE", "SUPERADMIN", "ADMIN"];
    if (roleList.includes(user.role.name)) {
      return true;
    }

    // si la personne est propriétaire du dashboard elle peut ajouter
    if (dashboards.dashboard.owner.id === user.id) {
      return true;
    }

    if (locations.fetchedLocation && locations.fetchedLocation.tournee) {
      // CETTE LIGNE ALEXIS
      return true;
    }

    if (
      users &&
      users.fetchedUser &&
      users.fetchedUser.profils.find(
        (el: any) =>
          el.profil.permissions.find(
            (permission: any) => permission.name === "edit.virtualMeter"
          ) && el.locationCode === locations.fetchedLocation.code
      )
    ) {
      return true;
    }

    return false;
  };

  /**
   * Affiche la liste des compteurs physiques associés
   *
   * @method generateHistoric
   * @returns {JSX} La liste
   */
  generateHistoric = () => {
    const { vmeters, match, t } = this.props;
    const { locationId } = match.params;

    const rowEvents = {
      onClick: (e: Object, row: any) => {
        history.push(`/locations/${locationId}/meters/info?id=${row.id}`);
      },
    };
    const data = _.get(vmeters, "fetchedVMeter.meterList");
    const columns = ListTools.getEmptyColumns(
      ListTools.typeOfList.VMeters_ListMeter
    ).map((el) => ({
      ...el,
      text: t(`columns.${el.dataField}`),
    }));

    return (
      <Fragment>
        {data && data.length > 0 && (
          <div className="table-info-container" style={{ marginLeft: "10px" }}>
            <div>
              <h2>
                <span>
                  <CompteurVert height="1em" width="1em" fill="#31c6b3" />
                </span>
                {data.length} {t("all.meter.linked_meter_plural")}
              </h2>
              <BootstrapTable
                keyField="id"
                data={data}
                rowEvents={rowEvents}
                columns={columns}
                bootstrap4
                bordered
                condensed
                hover
                striped
                rowClasses="clickable"
                pagination={data.length > 10 ? paginationFactory() : null}
              />
            </div>
          </div>
        )}
        <br />
      </Fragment>
    );
  };

  /**
   * Met à jour les différents templates (reads, conso, alarms) et fait
   * les requêtes associées
   *
   * @method updateTemplate
   * @param {any} statementsTemplate Template des lectures
   * @param {any} consoTemplate Template des consommations
   * @param {any} alarmsTemplate Template des alarmes
   */
  updateTemplate = (value: any, type: string) => {
    const { template, alarm, consumption } = this.state;
    const {
      dispatch,
      vmeters,
      locations,
      match: {
        params: { locationId },
      },
    } = this.props;
    const newValue = `${value} 00:00:00`;
    switch (type) {
      case "dateMin":
        template.dataSourceProperty.displayProperty.condition[1].conditionValue =
          newValue;
        consumption.dataSourceProperty.displayProperty.condition[1].conditionValue =
          newValue;
        alarm.dataSourceProperty.displayProperty.condition[1].conditionValue =
          newValue;
        this.setState({
          template,
          alarm,
        });
        break;
      case "dateMax":
        template.dataSourceProperty.displayProperty.condition[2].conditionValue =
          newValue;
        consumption.dataSourceProperty.displayProperty.condition[2].conditionValue =
          newValue;
        alarm.dataSourceProperty.displayProperty.condition[2].conditionValue =
          newValue;
        this.setState({
          template,
          alarm,
        });
        break;
      default:
        template.dataSourceProperty.displayProperty.displayZoom = value;
        alarm.dataSourceProperty.displayProperty.displayZoom = value;
        consumption.dataSourceProperty.displayProperty.displayZoom = value;
        this.setState({
          template,
          alarm,
          consumption,
        });
    }
    dispatch(
      vmeterActions.getStatementsAndAlarms(
        locationId,
        template,
        consumption,
        alarm,
        vmeters.fetchedVMeter
      )
    );
  };

  /**
   * Met à jour le template d'alarme et fait la requête associée
   *
   * @method updateAlarms
   * @param {any} alarm Template d'alarme
   */
  updateAlarms = (alarm: any) => {
    const { dispatch, vmeters, match } = this.props;
    const { locationId } = match.params;
    this.setState({
      alarm,
    });
    dispatch(vmeterActions.getAlarms(locationId, alarm, vmeters.fetchedVMeter));
  };

  /**
   * Renvoie les informations additionnelles de base
   *
   * @method getAdditionalInfo
   * @param {string}
   * @returns {Object} Les infos additionnelles
   */
  getAdditionalInfo = (type: string) => {
    return {
      type,
    };
  };

  /**
   * Affiche la tooltip des relèves, et gère celles invalides
   *
   * @method validReadsTooltip
   * @param {any} tooltipModel Modèle natif de tooltip
   * @param {any} additionalInfo Informations additionnelles
   */
  validReadsTooltip = (tooltipModel: any, additionalInfo: any) => {
    const { vmeters } = this.props;
    const data =
      vmeters && vmeters.fetchedVMeter && vmeters.fetchedVMeter.meterList;
    if (tooltipModel.opacity > 0) {
      const { footer, title } = tooltipModel;
      const existing = additionalInfo.values.find(
        (it: any) => it.date === title[0]
      );
      if (existing && !existing.valid) {
        footer.push("Lecture invalide");
        footer.push(
          `${existing.meterRead}/${
            data && data.length > 0 ? data.length : "?"
          } compteurs relevés`
        );
        tooltipModel.footerFontColor = "red";
        tooltipModel.width = 170;
        tooltipModel.height = 75;
      }
    }
  };

  /**
   * Affiche la tooltip des alarmes, et gère les multiples compteurs
   * pour une alarme
   *
   * @method alarmsTooltip
   * @param {any} tooltipModel Modèle natif de tooltip
   * @param {string} zoom Zoom
   */
  alarmsTooltip = (tooltipModel: any, zoom: string) => {
    if (tooltipModel.opacity > 0) {
      const { vmeters, locales }: any = this.props;
      const date = tooltipModel.title[0];
      const label = tooltipModel.body[0].lines[0].split(":");
      const filteredAlarms = vmeters.fetchedVMeter.alarms.filter(
        (it: any) => convertDate(it.date, zoom) === date
      );
      const meters: any = [];
      filteredAlarms.forEach((a: any) => {
        const alarmLabel: any = a.data.find(
          (l: any) =>
            translate("fr", "alarmType", l.type, locales.locale) === label[0]
        );
        if (undefined !== alarmLabel) {
          alarmLabel.meters.forEach((it: any) => {
            meters.push(it.serial);
          });
        }
      });
      label[1] = meters.join(", ");
      tooltipModel.footer[0] = label[1];
    }
  };

  /**
   * Gère l'affichage/masquage de la modal d'impression
   *
   * @method showPrint
   */
  showPrint = () => {
    const { isOpen } = this.state;

    this.setState({
      isOpen: !isOpen,
    });
  };

  /**
   * Adapte les données à l'affichage d'impression
   *
   * @method parseSheetData
   * @param {any} labels Labels des lectures
   * @param {any} values Valeurs des lectures
   * @param {any} labelsConso Labels des consommations
   * @param {any} valuesConso Valeurs des consommations
   * @param {any} dataAlarms Données des alarmes
   * @param {any} baseAdditionnal Données additionnelles
   * @param {string} zoom Zoom
   * @returns {Sheet} Les données de la feuille d'impression
   */
  parseSheetData = (
    labels: any,
    values: any,
    labelsConso: any,
    valuesConso: any,
    dataAlarms: any,
    baseAdditionnal: any,
    zoom: string
  ): Sheet => {
    const additionnalConso = cloneDeep(baseAdditionnal);
    additionnalConso.type = widgetConstants.READCONSUMTIONWIDGET;
    if (undefined !== dataAlarms && dataAlarms !== null) {
      dataAlarms.additionnal = {
        type: widgetConstants.VIRTUALALARMWIDGET,
      };
      dataAlarms.tooltip = (tooltipModel: any) => {
        this.alarmsTooltip(tooltipModel, zoom);
      };
    }
    return {
      index: {
        labels,
        values,
        additionnal: baseAdditionnal,
        tooltip: (tooltipModel: any) => {
          this.validReadsTooltip(tooltipModel, baseAdditionnal);
        },
      },
      conso: {
        labels: labelsConso,
        values: valuesConso,
        additionnal: additionnalConso,
        tooltip: (tooltipModel: any) => {
          this.validReadsTooltip(tooltipModel, baseAdditionnal);
        },
      },
      alarm: dataAlarms,
    };
  };

  /**
   * Filtre les dates invalides
   *
   * @method filterInvalidData
   */
  filterInvalidData = () => {
    const { onlyValid } = this.state;
    let { readsFiltered, consoFiltered } = this.state;

    if (onlyValid) {
      readsFiltered = readsFiltered.filter((it) => {
        if (it.meterCount - it.indexCount === 0) {
          return it;
        }
      });
      consoFiltered = consoFiltered.filter((it) => {
        if (it.meterCount - it.indexCount === 0) {
          return it;
        }
      });

      this.setState({
        readsFiltered,
        consoFiltered,
      });
    }
  };

  /**
   * Affiche toute la partie des graphes
   *
   * @method generateIntervalSelector
   * @returns {JSX} Le contenu
   */
  generateIntervalSelector = () => {
    const { locales, colors, vmeters, t } = this.props;
    const {
      template,
      consumption,
      graphType,
      alarm,
      isOpen,
      readsFiltered,
      consoFiltered,
      alarmsFiltered,
      isMasked,
    } = this.state;
    let { onlyValid } = this.state;
    const additionalInfo = {
      values: [],
      colors: [],
      context: "vmeter",
      type: widgetConstants.READINDEXWIDGET,
    };
    const fetchElement: any =
      vmeters && vmeters.fetchedVMeter && vmeters.fetchedVMeter;
    const labels = readsFiltered && readsFiltered.map((it: any) => it.date);
    const labelsConso =
      consoFiltered && consoFiltered.map((it: any) => it.date);
    let values =
      readsFiltered &&
      readsFiltered.map((it: any) => {
        return {
          value: it.index,
          meterCount: it.meterCount,
          indexCount: it.indexCount,
        };
      });

    const additionalDataConso = cloneDeep(additionalInfo);
    additionalDataConso.type = widgetConstants.READCONSUMTIONWIDGET;

    const { dataSourceProperty } = template;
    const { dataSourceProperty: dtSourceConso } = consumption;

    const zoom =
      dataSourceProperty &&
      dataSourceProperty.displayProperty &&
      dataSourceProperty.displayProperty.displayZoom;
    const customZooms = ["Hour", "Day", "Week", "Month", "Year"];

    if (values && values instanceof Array && values.length > 0) {
      values = formatAdditionalData(values, labels, additionalInfo, zoom);
    }

    const dataAlarms: any = formatAlarmData(
      alarmsFiltered,
      locales,
      colors,
      isMasked
    );

    let valuesConso = values.map((index, i) => {
      if (values[i - 1]) {
        return index - values[i - 1];
      }
      return 0;
    });

    const printData: any = this.parseSheetData(
      labels,
      values,
      labelsConso,
      valuesConso,
      dataAlarms,
      additionalInfo,
      zoom
    );

    let name =
      fetchElement && fetchElement.general ? fetchElement.general.serial : "";
    if (undefined === name) {
      name = fetchElement.general.name;
    }

    const { displayUnit } = dataSourceProperty;
    const { displayUnit: unitConso } = dtSourceConso;

    const startDate =
      dataSourceProperty &&
      dataSourceProperty.displayProperty &&
      dataSourceProperty.displayProperty.condition &&
      dataSourceProperty.displayProperty.condition[1] &&
      dataSourceProperty.displayProperty.condition[1].conditionValue;

    const endDate =
      dataSourceProperty &&
      dataSourceProperty.displayProperty &&
      dataSourceProperty.displayProperty.condition &&
      dataSourceProperty.displayProperty.condition[2] &&
      dataSourceProperty.displayProperty.condition[2].conditionValue;

    console.log(valuesConso, values);

    return (
      <div className="container">
        {readsFiltered && dataAlarms && locales.locale && (
          <div>
            <Modal isOpen={isOpen} toggle={this.showPrint} size="lg">
              <ModalHeader toggle={this.showPrint}>
                {t(
                  onlyValid
                    ? "all.meter.export_data_from_valid"
                    : "all.text.export_data_from_x",
                  { name: name }
                )}
              </ModalHeader>
              <ModalBody>
                <Button
                  onClick={(e: any) => {
                    printMultiSheet("print", name);
                  }}
                >
                  {t("all.text.save_pdf")}
                </Button>
                <PrintableSourceSheet
                  locales={locales}
                  data={printData}
                  name={name}
                  zoom={zoom}
                  isMeter
                  graphType={graphType}
                  startDate={startDate}
                  endDate={endDate}
                  t={t}
                />
              </ModalBody>
            </Modal>
            <div className="table-info-container">
              <h2>
                <span>
                  <HistoriqueVert height="1em" width="1em" fill="#31c6b3" />
                </span>
                {t("all.read_meter.read_history")}
              </h2>{" "}
              <div>
                <h5 style={{ marginTop: "20px" }}>
                  {t("virtual_meter.text.only_valid_read_plural")}{" "}
                  <Switch
                    style={{ marginLeft: "5px" }}
                    onChange={(checked: boolean, e: any) => {
                      onlyValid = !onlyValid;
                      this.setState(
                        {
                          onlyValid,
                        },
                        () => {
                          this.filterInvalidData();
                        }
                      );
                    }}
                    defaultChecked={onlyValid}
                  />
                </h5>
              </div>
              {_.get(vmeters, "fetchedVMeter.statements.length", 0) +
                _.get(vmeters, "fetchedVMeter.consumption.length", 0) +
                _.get(vmeters, "fetchedVMeter.alarms.length", 0) ===
              0 ? (
                <Button disabled>{t("all.text.preview_pdf")}</Button>
              ) : (
                <Button onClick={(e: any) => this.showPrint()}>
                  {t("all.text.preview_pdf")}
                </Button>
              )}
              <Button
                style={{ marginLeft: "15px" }}
                onClick={(e: any) => {
                  Object.keys(printData).forEach((it: any) => {
                    const toExport = chart2table(printData[it], it, t);
                    downloadCSV(
                      toExport.columns,
                      toExport.data,
                      `${name}-${it}`
                    );
                  });
                }}
              >
                {t("all.text.export_row_data")}
              </Button>
              <br />
              <br />
              <BestDateComponent
                dateMin={startDate}
                dateMax={endDate}
                handleChange={this.updateTemplate}
                zoom={zoom}
              />
              <h3 className="title-chart">
                {t("all.meter.index")} -{" "}
                {translate("fr", "unit", displayUnit, locales.locale)} -{" "}
                {t(`best_date_component.zoom_option.${zoom.toLowerCase()}`)}
              </h3>
              <div style={{ display: "flex" }}>
                {vmeters.loadingRead ? (
                  <LoadingBand message={t("virtual_meter.text.load_index")} />
                ) : _.size(labels) === 0 ? (
                  <SvgChartBarVerticalNotAvailable
                    height="300px"
                    fill="#E5E5E4"
                    style={{
                      maxWidth: "-webkit-fill-available",
                      margin: "auto",
                    }}
                  />
                ) : (
                  <div className="chart-container" style={{ height: "300px" }}>
                    {/*@ts-ignore*/}
                    <BarChart labels={labels} data={values} zoom={zoom} />
                  </div>
                )}
              </div>
              <h3 className="title-chart">
                {t("all.read_meter.cons")} -{" "}
                {translate("fr", "unit", unitConso, locales.locale)} -{" "}
                {t(`best_date_component.zoom_option.${zoom.toLowerCase()}`)}
              </h3>
              <div style={{ display: "flex" }}>
                {vmeters.loadingConso ? (
                  <LoadingBand
                    message={t("virtual_meter.text.load_consumption")}
                  />
                ) : _.size(labels) === 0 ? (
                  <SvgChartBarVerticalNotAvailable
                    height="300px"
                    fill="#E5E5E4"
                    style={{
                      maxWidth: "-webkit-fill-available",
                      margin: "auto",
                    }}
                  />
                ) : (
                  <div className="chart-container" style={{ height: "300px" }}>
                    {/*@ts-ignore*/}
                    <BarChart labels={labels} data={valuesConso} zoom={zoom} />
                  </div>
                )}
              </div>
            </div>
            <br />
            <div className="table-info-container">
              <h2>
                <span>
                  <AlarmeVert height="1em" width="1em" fill="#31c6b3" />
                </span>
                {t("all.alarm_meter.alarm_history_plural")} -{" "}
                {t(`best_date_component.zoom_option.${zoom.toLowerCase()}`)}
              </h2>{" "}
              <p style={{ fontSize: "16px" }}>
                {t("all.alarm_meter.show_masked_alarm_plural")} :
                <span>
                  <Switch
                    style={{ marginLeft: "10px" }}
                    onChange={() => {
                      this.setState({
                        isMasked: !this.state.isMasked,
                      });
                    }}
                    defaultChecked={isMasked}
                  />
                </span>
              </p>
              <AlarmSelector
                template={alarm}
                update={this.updateAlarms}
                locales={locales}
              />
              <div style={{ display: "flex" }}>
                {vmeters.loadingAlarms ? (
                  <LoadingBand message={t("virtual_meter.text.load_alarms")} />
                ) : _.size(_.get(alarmsFiltered, "data")) === 0 ? (
                  <SvgChartBarVerticalNotAvailable
                    height="300px"
                    fill="#E5E5E4"
                    style={{
                      maxWidth: "-webkit-fill-available",
                      margin: "auto",
                    }}
                  />
                ) : (
                  <div className="chart-container" style={{ height: "300px" }}>
                    {/*@ts-ignore*/}
                    <AlarmBarChart
                      colors={colors}
                      data={alarmsFiltered}
                      zoom={zoom}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  /**
   * Gère le comportement au clic sur une relève en vue semaine
   * pour zoomer dessus
   *
   * @method onClickWeek
   * @param {Object} event Evènement
   * @param {Array<any>} item Valeurs
   */
  onClickWeek = (event: Object, item: Array<any>) => {
    if (item && item.length > 0) {
      const { template, consumption, alarm } = this.state;
      if (template.dataSourceProperty.displayProperty.displayZoom === "Week") {
        const { _model: model } = item[0];
        const momentLabel = moment(model.label, "DD/MM/YYYY");
        const newStart = momentLabel
          .clone()
          .startOf("week")
          .format(`${moment.HTML5_FMT.DATE} ${moment.HTML5_FMT.TIME_SECONDS}`);
        const newEnd = momentLabel
          .clone()
          .endOf("week")
          .format(`${moment.HTML5_FMT.DATE} ${moment.HTML5_FMT.TIME_SECONDS}`);

        template.dataSourceProperty.displayProperty.condition[1].conditionValue =
          newStart;
        template.dataSourceProperty.displayProperty.condition[2].conditionValue =
          newEnd;
        template.dataSourceProperty.displayProperty.displayZoom = "Day";

        consumption.dataSourceProperty.displayProperty.condition[1].conditionValue =
          newStart;
        consumption.dataSourceProperty.displayProperty.condition[2].conditionValue =
          newEnd;
        consumption.dataSourceProperty.displayProperty.displayZoom = "Day";

        alarm.dataSourceProperty.displayProperty.condition[1].conditionValue =
          newStart;
        alarm.dataSourceProperty.displayProperty.condition[2].conditionValue =
          newEnd;
        alarm.dataSourceProperty.displayProperty.displayZoom = "Day";

        this.updateTemplate(template, consumption);
      }
    }
  };

  /**
   * Vérifie s'il existe des compteurs non géolocalisés
   *
   * @method checkGeoMeterList
   * @param {Array<any>} meterList Liste des compteurs
   * @returns {boolean} Le test
   */
  checkGeoMeterList = (meterList: Array<any>) => {
    if (meterList.length > 0) {
      const check = meterList.find((it) => it.gpsPosition !== null);
      return check !== undefined;
    }
    return false;
  };

  /**
   * Affiche le bloc des dernières relèves
   *
   * @method getLastRead
   * @param {VLastRead} last Données des dernières relèves
   * @param {Array<any>} meterList Liste des compteurs
   * @param {boolean} best Meilleure relève
   * @returns {JSX} Le bloc
   */
  getLastRead = (last: VLastRead, meterList: any[], best: boolean) => {
    const { t } = this.props;
    const data = {
      index: [
        {
          id: 1,
          index: last.index && last.index.toFixed(3),
          average:
            last.index > 0
              ? (
                  last.index / (meterList.length > 1 ? meterList.length : 1)
                ).toFixed(3)
              : 0,
        },
      ],
      conso: [
        {
          id: 1,
          conso: last.consumption && last.consumption.toFixed(3),
          average:
            last.averageConsumption && last.averageConsumption.toFixed(3),
        },
      ],
    };
    const columns = {
      index: [
        {
          dataField: "index",
          text: t("all.meter.stack_index_plural"),
        },
        {
          dataField: "average",
          text: t("columns.average"),
        },
      ],
      conso: [
        {
          dataField: "conso",
          text: t("all.read_meter.cons"),
        },
        {
          dataField: "average",
          text: t("columns.average"),
        },
      ],
    };
    return (
      <Fragment>
        <div>
          <br />
          <div className="table-info-container">
            <h2>
              <span>
                <DerniereReleveVert height="1em" width="1em" fill="#31c6b3" />
              </span>
              {!best
                ? t("all.read_meter.last_read")
                : t("virtual_meter.text.last_best_read")}{" "}
              {last.meterCount - last.indexCount > 0 && (
                <Fragment>
                  <br />
                  <span
                    style={{
                      color: "#FF7F50",
                      display: "inline",
                      fontWeight: "bold",
                      paddingLeft: "30px",
                    }}
                  >
                    {" "}
                    {t("virtual_meter.text.invalid").toUpperCase()}
                  </span>
                </Fragment>
              )}
            </h2>
            <div className="information-container">
              {last.meterCount - last.indexCount !== 0 && (
                <p>
                  <span className="infoLibelle">
                    {t("virtual_meter.text.read_meter_plural")}
                  </span>{" "}
                  <br /> &nbsp;{" "}
                  <span className="infoDisplay">{`${last.indexCount} / ${last.meterCount}`}</span>
                </p>
              )}
              <p>
                <span className="infoLibelle">
                  {t("all.read_meter.read_last_date")}
                </span>{" "}
                <br /> &nbsp;{" "}
                <span className="infoDisplay">
                  {moment(last.date).format(t("all.date_format.date_and_time"))}{" "}
                </span>
              </p>
              <span className="infoLibelle">
                {t("all.text.information_plural")}
              </span>{" "}
              <br />
              <BootstrapTable
                keyField="id"
                data={data.index}
                columns={columns.index}
                bootstrap4
                bordered
                condensed
                hover
                striped
              />
              {last.meterCount - last.indexCount === 0 && (
                <BootstrapTable
                  keyField="id"
                  data={data.conso}
                  columns={columns.conso}
                  bootstrap4
                  bordered
                  condensed
                  hover
                  striped
                />
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  /**
   * Rend le composant
   *
   * @method render
   */
  render() {
    const { vmeters, match, t } = this.props;
    const { locationId } = match.params;

    const intervalSelector = this.generateIntervalSelector();
    const historic = this.generateHistoric();
    const lastRead =
      vmeters && vmeters.fetchedVMeter && vmeters.fetchedVMeter.lastRead;
    const last = lastRead && lastRead.last;
    const best = lastRead && lastRead.best;

    return (
      <div className="col-md-12">
        {vmeters && vmeters.loading && <Loading />}
        {vmeters && vmeters.error && <ErrorBand message={vmeters.error} />}
        {vmeters && vmeters.fetchedVMeter && vmeters.fetchedVMeter.general && (
          <div className="row">
            <div className="col-lg-4">
              <div className="presentation-container">
                <div className="presentation-header">
                  <Row>
                    <Col md="9" lg="10">
                      <span className="presentation-title">
                        {t("all.meter.virtual_meter_name")}
                      </span>
                      <span className="presentation-main-title">
                        {vmeters.fetchedVMeter.general.name}
                      </span>
                    </Col>
                    {this.couldEditOrDeleteVirtualMeter() && (
                      <Col style={{ margin: "auto" }} md="3" lg="2">
                        <a
                          href={`/locations/${locationId}/virtuals/edit?id=${vmeters.fetchedVMeter.general.id}`}
                        >
                          <div
                            id="addWidget"
                            className="round"
                            role="presentation"
                          >
                            <Edition className="add" fill="curentcolor" />
                            <UncontrolledTooltip
                              placement="bottom"
                              target="addWidget"
                            >
                              {" "}
                              {t("all.meter.edit_virtual_meter")}
                            </UncontrolledTooltip>
                          </div>
                        </a>
                      </Col>
                    )}
                  </Row>
                  <div className="clearfix" />
                </div>
                <div
                  className="presentation-body"
                  style={{ minHeight: "100px" }}
                >
                  <div className="display-info">
                    <div className="row" style={{ marginLeft: "10px" }}>
                      <div className="col-md-3" style={{ marginTop: "10px" }}>
                        <HistoriqueVert
                          height="2em"
                          width="2em"
                          stroke="#31c6b3"
                          fill="#31c6b3"
                          strokeWidth="0"
                        />{" "}
                      </div>
                      <div className="col-md-9">
                        <p>
                          <span className="infoLibelle">
                            {t("all.date.update_date")}
                          </span>{" "}
                          <br /> &nbsp;{" "}
                          <span className="infoDisplay">
                            {moment(
                              vmeters.fetchedVMeter.general.updatedAt
                            ).format(
                              `${t("all.date_format.date_with_time", {
                                time: moment.HTML5_FMT.TIME_SECONDS,
                              })}`
                            )}{" "}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              {historic}
              <div className="loading-last-read">
                {last &&
                  Object.keys(last).length > 0 &&
                  this.getLastRead(
                    last,
                    vmeters.fetchedVMeter.meterList,
                    false
                  )}
                {vmeters.laodingLastRead ? (
                  <LoadingBand
                    message={t("virtual_meter.text.load_last_best_read")}
                    style={{ marginTop: "2vh" }}
                  />
                ) : (
                  best &&
                  Object.keys(best).length > 0 &&
                  last &&
                  last.date !== best.date &&
                  this.getLastRead(best, vmeters.fetchedVMeter.meterList, true)
                )}
              </div>
              {this.checkGeoMeterList(vmeters.fetchedVMeter.meterList) && (
                <div className="col-12">
                  <br />
                  <div className="presentation-container-map-meter">
                    <TinyGmap
                      lat={null}
                      lng={null}
                      markersToCreate={vmeters.fetchedVMeter.meterList}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="col-lg-8">{intervalSelector}</div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication, vmeters, locations, locales, colors } = state;
  const { user } = authentication;

  return {
    alert,
    user,
    locations,
    locales,
    vmeters,
    colors,
  };
}

const connectedVirtualMeter = connect(mapStateToProps)(VirtualMeter);
const tr = withTranslation()(connectedVirtualMeter);
export default tr;
