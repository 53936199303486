import React, { Component, ComponentType } from "react";
import { connect } from "react-redux";
import "chartjs-plugin-datalabels";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { localeActions, locationActions, tourneeActions } from "../_actions";
import {
  Button,
  Col,
  Collapse,
  ListGroup,
  ListGroupItem,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import _ from "lodash";
import SvgEye from "../SvgComponents/Eye";
import roundReportActions from "../_actions/roundReport.action";
import Loading from "../_animations/Loading";
import PDFViewer from "./PdfViewer";
import SvgPleinEcranVert from "../SvgComponents/PleinEcranVert";
import SvgCloseEye from "../SvgComponents/CloseEye";
import { Radio, withStyles } from "@material-ui/core";
import FiltreVert from "../SvgComponents/FiltreVert";
import ListTools from "../List/ListTools";
import StaticList from "../TourneeFiches/StaticList";
import getC from "../TourneeFiches/Picto_correspondance";
import { translateBasicAlarm } from "../_helpers/locale-helpers";
import locale from "../_shared/Locale.json";
import listOperator from "../_shared/OperatorList";
import { QueryBuilder } from "../QueryBuilder";
import { getFiltersRequest } from "../QueryBuilder/FilterLSManager";
import SwapVertIcon from "@material-ui/icons/SwapVert";
import { Props, ReportSetting, State } from "../_interfaces/RoundReportTypes";
import { compose } from "redux";

class RoundReport extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.getReportPdfTitle = this.getReportPdfTitle.bind(this);
    this.getFields = this.getFields.bind(this);

    this.state = {
      selectedReport: "",
      reportPdf: null,
      init: false,
      updatedReport: false,
      reportFilter: "",
    };
  }

  static getDerivedStateFromProps(props: any, state: State) {
    if (props.roundReports.reportProcessing) {
      return {
        reportPdf: null,
      };
    }

    if (
      state.updatedReport &&
      props.roundReports &&
      props.roundReports.report &&
      props.roundReports.report.fileData != null
    ) {
      const fileData = props.roundReports.report.fileData;
      const byteCharacters = atob(fileData);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      return {
        reportPdf: fileData
          ? window.URL.createObjectURL(
              new File([byteArray], "rapport.pdf", { type: "application/pdf" })
            )
          : null,
        updatedReport: false,
      };
    }

    if (!state.init && _.get(props, "roundReports.allReport")) {
      return {
        reportSetting: _.map(props.roundReports.allReport, (report) => {
          let newReportSetting: ReportSetting = { report: report.name };
          if (_.size(report.availableOrder) > 0)
            newReportSetting.orderType = "";
          if (_.size(report.availableOrder) > 0)
            newReportSetting.orderAsc = true;
          if (report.orientation) newReportSetting.orientation = "y";
          return newReportSetting;
        }),
        init: true,
      };
    }
  }

  componentDidMount() {
    const { dispatch, match } = this.props;
    const { tourneeCode, locationId } = match.params;
    if (tourneeCode) {
      dispatch(tourneeActions.getTournee(tourneeCode));
      dispatch(roundReportActions.getAllReport(tourneeCode));
    }
    dispatch(localeActions.load());
    if (locationId) {
      dispatch(locationActions.get(locationId));
    }
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    const { dispatch, match, locations, roundReports } = this.props;
    const { locationId } = match.params;
    if (
      locationId &&
      locations.fetchedLocation &&
      !roundReports.loading &&
      !roundReports.allReport
    ) {
      dispatch(roundReportActions.getAllReport(locations.fetchedLocation.code));
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(roundReportActions.clear());
  }

  getReport(reportKey: string) {
    const { dispatch, match, locations } = this.props;
    const { reportSetting } = this.state;
    const { tourneeCode } = match.params;
    let keyStorage = `filter-${ListTools.typeOfList.Fiche}-${
      tourneeCode || _.get(locations, "rndCode")
    }`;
    dispatch(
      roundReportActions.getReport(
        reportKey,
        tourneeCode || _.get(locations, "fetchedLocation.code"),
        _.find(reportSetting, ["report", reportKey]),
        getFiltersRequest(keyStorage),
        localStorage.getItem(
          `filter-${ListTools.typeOfList.Fiche}-${tourneeCode}_optionor`
        ) === "true"
          ? "or"
          : "and"
      )
    );

    this.setState({
      reportSettingGenerated: _.find(reportSetting, ["report", reportKey]),
      updatedReport: true,
      reportFilter: "",
    });
  }

  handleClick(reportKey: string) {
    this.setState({
      selectedReport: reportKey,
    });

    this.getReport(reportKey);
  }

  handleReportSetting(
    reportKey: string,
    orderType: string,
    isAscButton: boolean
  ) {
    const { reportSetting } = this.state;
    console.log("handleChangeRADIO : ", this.state);
    let existingReportSetting = _.cloneDeep(
      _.find(reportSetting, ["report", reportKey])
    );
    if (existingReportSetting) {
      if (existingReportSetting.orderType === orderType) {
        if (isAscButton) {
          existingReportSetting.orderAsc = !existingReportSetting.orderAsc;
        } else {
          //Reset DEFAULT setting
          existingReportSetting.orderType = "";
          existingReportSetting.orderAsc = true;
        }
      } else {
        existingReportSetting.orderType = orderType;
        existingReportSetting.orderAsc = true;
      }

      this.updateReport(existingReportSetting);
    }
  }

  updateReport(newReport: ReportSetting) {
    const { reportSetting } = this.state;
    const newReportSetting = reportSetting;
    const reportKey = newReport.report;
    if (newReport && newReportSetting) {
      _.remove(newReportSetting, function (r) {
        return _.get(r, "report") === reportKey;
      });

      newReportSetting.push(newReport);
      this.setState({
        reportSetting: newReportSetting,
      });
    } else {
      console.log("No report setting found for : ", reportKey);
    }
  }

  handleReportOrientation(reportKey: string, orientation: string) {
    const { reportSetting } = this.state;
    let existingReportSetting = _.cloneDeep(
      _.find(reportSetting, ["report", reportKey])
    );
    if (existingReportSetting) {
      existingReportSetting.orientation = orientation;
      this.updateReport(existingReportSetting);
    }
  }

  onFilterClick(reportKey: string) {
    const { reportFilter } = this.state;
    const newReportKey = _.isEqual(reportFilter, reportKey) ? "" : reportKey;

    this.setState({
      reportFilter: newReportKey,
    });
  }
  getRapportErrorMessage(): string {
    const { roundReports, t } = this.props;
    let error = t("rapport.error.generic");
    if (
      roundReports &&
      roundReports.report &&
      roundReports.report.cause != null
    ) {
      switch (roundReports.report.cause) {
        case "no_data":
          return t("rapport.error.no_data");
        default:
          return t("rapport.error.generic");
      }
    }
    return error;
  }
  getReportPdfTitle() {
    const { locales, t } = this.props;
    const { reportSettingGenerated } = this.state;
    let title = t("rapport.name." + _.get(reportSettingGenerated, "report"));
    const orderBy = _.get(reportSettingGenerated, "orderType")
      ? t("columns." + _.get(reportSettingGenerated, "orderType"))
      : "";

    return (
      <>
        {title}{" "}
        {!_.isEmpty(orderBy) && (
          <span className="orderBy">{` - ${t(
            "rapport.option.sorted_by"
          )} : ${orderBy}`}</span>
        )}
      </>
    );
  }

  getFields() {
    const { t } = this.props;
    return ListTools.getDefaultColumns(ListTools.typeOfList.LightFiche).map(
      (champ) => {
        const typeData = ListTools.findSpecificType(champ);
        return {
          label: t(`columns.${champ}`),
          value: champ,
          type: typeData,
          classes: champ === "hasGpsPosition" ? "center-td" : "",
          opts:
            champ === "ficheState"
              ? StaticList.etatFiche.map((el) => {
                  return {
                    value: el.picto,
                    label: (
                      <div>
                        {getC(el.picto, { height: "1.5em", width: "1.5em" })}
                        {el.label}
                      </div>
                    ),
                  };
                })
              : champ === "consoState"
              ? StaticList.consoState
              : champ === "alarms"
              ? translateBasicAlarm("fr", locale).sort((a, b) => {
                  if (a.label < b.label) return -1;
                  if (a.label === b.label) return 0;
                  return 1;
                })
              : [],
        };
      }
    );
  }

  render() {
    const { roundReports, locales, tournee, t } = this.props;
    const { selectedReport, reportPdf, reportSetting, reportSettingGenerated } =
      this.state;

    const CustomRadio = withStyles({
      root: {
        color: "#6c757d",
        "&$checked": {
          color: "#31c6b3",
        },
      },
      checked: {},
    })((props: any) => <Radio color="default" {...props} />);
    return (
      <div className="reportContainer">
        <h1>{t("bread_crumb.name.report_plural")}</h1>
        {tournee.info && (
          <div className="filter-container">
            <QueryBuilder
              sendListFiltered={() => {}}
              listData={[]}
              listOperator={listOperator}
              listFilters={this.getFields()}
              idContext={ListTools.typeOfList.Fiche}
              save={true}
              idSite={null}
              tournee={tournee}
            />
          </div>
        )}
        <Row>
          {(!roundReports || roundReports.loading) && <Loading />}
          {locales &&
            roundReports &&
            !roundReports.loading &&
            _.size(roundReports.allReport) > 0 && (
              <>
                <Col className="roundedContainer reportList" md="3">
                  <ListGroup>
                    {_.map(roundReports.allReport, (report) => {
                      return (
                        <>
                          <ListGroupItem action>
                            <div className="reportName">
                              <span style={{ fontWeight: "bold" }}>
                                {t(`rapport.name.${report.name}`)}
                              </span>
                              {!_.isEmpty(
                                _.get(
                                  _.find(reportSetting, [
                                    "report",
                                    report.name,
                                  ]),
                                  "orderType"
                                )
                              ) && (
                                <>
                                  <br />
                                  <span className="reportOrderBy">
                                    {t("rapport.option.sorted_by")} :{" "}
                                    {t(
                                      "columns." +
                                        _.get(
                                          _.find(reportSetting, [
                                            "report",
                                            report.name,
                                          ]),
                                          "orderType",
                                          ""
                                        )
                                    )}
                                  </span>
                                </>
                              )}
                            </div>
                            <div className="choiceReportButton">
                              {_.size(report.availableOrder) > 0 && (
                                <Button
                                  id={
                                    "settingButton_" +
                                    report.name.replace(" ", "")
                                  }
                                  className={
                                    _.isEqual(
                                      report.name,
                                      _.get(this, "state.reportFilter")
                                    )
                                      ? "activeButton"
                                      : "reportButton"
                                  }
                                  disabled={roundReports.reportProcessing}
                                  style={{ padding: 0 }}
                                  onClick={() =>
                                    this.onFilterClick(report.name)
                                  }
                                >
                                  <SwapVertIcon
                                    height="20px"
                                    width="20px"
                                    style={{
                                      marginTop: "3px",
                                      fill: _.isEqual(
                                        report.name,
                                        _.get(this, "state.reportFilter")
                                      )
                                        ? "white"
                                        : "black",
                                    }}
                                  />
                                  <UncontrolledTooltip
                                    placement="bottom"
                                    target={
                                      "settingButton_" +
                                      report.name.replace(" ", "")
                                    }
                                  >
                                    {t("rapport.option.sort_by")}
                                  </UncontrolledTooltip>
                                </Button>
                              )}
                              <Button
                                id={
                                  "previewButton_" +
                                  report.name.replace(" ", "")
                                }
                                className={
                                  selectedReport === report.name
                                    ? "activeButton"
                                    : "reportButton"
                                }
                                onClick={() =>
                                  !roundReports.reportProcessing &&
                                  this.handleClick(report.name)
                                }
                                disabled={roundReports.reportProcessing}
                                style={{ padding: 0 }}
                              >
                                {roundReports.reportProcessing ? (
                                  <SvgCloseEye
                                    height="30px"
                                    width="30px"
                                    fill={
                                      _.isEqual(selectedReport, report.name)
                                        ? "white"
                                        : "black"
                                    }
                                  />
                                ) : (
                                  <SvgEye
                                    height="20px"
                                    width="20px"
                                    fill={
                                      _.isEqual(selectedReport, report.name)
                                        ? "white"
                                        : "black"
                                    }
                                  />
                                )}
                                <UncontrolledTooltip
                                  placement="bottom"
                                  target={
                                    "previewButton_" +
                                    report.name.replace(" ", "")
                                  }
                                >
                                  {t("rapport.option.view_pdf")}
                                </UncontrolledTooltip>
                              </Button>
                            </div>
                          </ListGroupItem>
                          <Collapse
                            isOpen={_.isEqual(
                              report.name,
                              _.get(this, "state.reportFilter")
                            )}
                          >
                            <div>
                              {false && (
                                <>
                                  <div
                                    id={
                                      "horizontalReportSetting_" +
                                      report.name.replace(" ", "")
                                    }
                                    className="horizontalA4"
                                    onClick={() => {}}
                                  />
                                  <UncontrolledTooltip
                                    placement="bottom"
                                    target={
                                      "horizontalReportSetting_" +
                                      report.name.replace(" ", "")
                                    }
                                  >
                                    Format paysage
                                  </UncontrolledTooltip>
                                  <div
                                    id={
                                      "verticalReportSetting_" +
                                      report.name.replace(" ", "")
                                    }
                                    className="verticalA4"
                                    style={{ border: "2px #31c6b3 solid" }}
                                    onClick={() => {}}
                                  />
                                  <UncontrolledTooltip
                                    placement="bottom"
                                    target={
                                      "verticalReportSetting_" +
                                      report.name.replace(" ", "")
                                    }
                                  >
                                    Format portrait
                                  </UncontrolledTooltip>
                                </>
                              )}
                              <ListGroup className="settingListGroup">
                                {report.availableOrder.map((order) => (
                                  <ListGroupItem action>
                                    <CustomRadio
                                      checked={
                                        !!_.find(reportSetting, {
                                          report: report.name,
                                          orderType: order,
                                        })
                                      }
                                      onClick={() =>
                                        this.handleReportSetting(
                                          report.name,
                                          order,
                                          false
                                        )
                                      }
                                      name={report.name + "_switch_" + order}
                                      size="small"
                                    />
                                    <div
                                      className="orderName"
                                      onClick={() =>
                                        this.handleReportSetting(
                                          report.name,
                                          order,
                                          false
                                        )
                                      }
                                    >
                                      {t("columns." + order)}
                                    </div>
                                    <span
                                      className={
                                        "clickable " +
                                        (_.get(
                                          _.find(reportSetting, {
                                            report: report.name,
                                            orderType: order,
                                          }),
                                          "orderAsc"
                                        ) === undefined
                                          ? "arrowOrder"
                                          : !!_.get(
                                              _.find(reportSetting, {
                                                report: report.name,
                                                orderType: order,
                                              }),
                                              "orderAsc"
                                            )
                                          ? "arrowOrderASC"
                                          : "arrowOrderDESC")
                                      }
                                      onClick={() =>
                                        this.handleReportSetting(
                                          report.name,
                                          order,
                                          true
                                        )
                                      }
                                    />
                                  </ListGroupItem>
                                ))}
                              </ListGroup>
                            </div>
                          </Collapse>
                        </>
                      );
                    })}
                  </ListGroup>
                </Col>
                <Col>
                  {roundReports &&
                    roundReports.report &&
                    roundReports.report.successful === false && (
                      <div className={"round-report-error-container"}>
                        <div className={"round-report-error-head"}>!</div>
                        <h3>{this.getRapportErrorMessage()}</h3>
                      </div>
                    )}
                  <Col className="roundedContainer" id={"preview-report"}>
                    {reportPdf ? (
                      <div className="pdfContainer">
                        <h2 className="reportPdfTitle">
                          {this.getReportPdfTitle()}
                        </h2>
                        <PDFViewer url={reportPdf} height={"700px"} />
                        <div
                          id={"fullscreen"}
                          onClick={() => window.open(reportPdf)}
                        >
                          <div className="svgContent">
                            <SvgPleinEcranVert
                              className="add svgFullScreen"
                              fill="#fff"
                              height="1.3em"
                              width="1.3em"
                            />
                          </div>
                        </div>
                        <UncontrolledTooltip
                          placement="bottom"
                          target={"fullscreen"}
                        >
                          {t("rapport.option.full_screen")}
                        </UncontrolledTooltip>
                      </div>
                    ) : (
                      <div className="noReportSelected">
                        {roundReports && roundReports.reportProcessing ? (
                          <Loading />
                        ) : (
                          <>
                            <h3>{t("rapport.option.preview_pdf")}</h3>
                            {roundReports &&
                            roundReports.report &&
                            roundReports.report.successful === false ? (
                              <div>{t("rapport.error.generic")}</div>
                            ) : (
                              <div>{t("rapport.text.no_report_selected")}</div>
                            )}
                          </>
                        )}
                      </div>
                    )}
                  </Col>
                </Col>
              </>
            )}
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const {
    authentication,
    meters,
    locations,
    locales,
    radios,
    pdis,
    tournee,
    roundReports,
  } = state;
  const { user } = authentication;

  return {
    alert,
    user,
    meters,
    radios,
    pdis,
    locations,
    locales,
    tournee,
    roundReports,
  };
}

const connectedRoundReport = compose<ComponentType<any>>(
  withRouter,
  connect(mapStateToProps)
)(RoundReport);
export default withTranslation()(connectedRoundReport);
