import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import _ from "lodash";
import {
  Button,
  PopoverBody,
  PopoverHeader,
  UncontrolledPopover,
} from "reactstrap";
import { WarningRounded } from "@material-ui/icons";

const AdvancedExportTrigger = (props) => {
  const {
    row,
    listExportRound,
    t,
    tournee,
    exportTournee,
    exportTourneeCsv,
    exportTourneeTeleo,
    exportFactu,
    target,
  } = props;
  const [isAdvancedModeOpen, setAdvancedMode] = useState<boolean>(false);
  return (
    <UncontrolledPopover
      trigger="legacy"
      placement="left"
      style={{ width: 200 }}
      target={target}
      on
      id={row ? `popover-${row.id}` : "mutli-export-popup"}
    >
      <PopoverHeader style={{ color: "primary" }}>
        {t("tournee.text.export_round_csv_custom")}
      </PopoverHeader>
      <PopoverBody style={{ display: "grid" }}>
        {!isAdvancedModeOpen ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="button-box">
              <p>Dioptase</p>
              <Button
                color="info"
                onClick={(e) => exportTournee(e, row)}
                style={{ margin: 0 }}
              >
                {t("tournee.text.export_dat")}
              </Button>
            </div>
            {(_.get(tournee, "options.export_round_csv_custom") === "true" ||
              _.get(tournee, "options.export_round_csv_custom") === true) &&
              Array.isArray(listExportRound) &&
              listExportRound.length && (
                <div className="button-box">
                  <p>CSV</p>
                  {_.sortBy(listExportRound).map((el) => (
                    <Button
                      key={el}
                      color="info"
                      onClick={(e) => exportTourneeCsv(e, row, el)}
                      style={{ margin: 0, marginTop: "10px" }}
                    >
                      {el}
                    </Button>
                  ))}
                </div>
              )}
            {(_.get(tournee, "options.export_round_teleo") === "true" ||
              _.get(tournee, "options.export_round_teleo") === true) && (
              <div className="button-box">
                <p>Veolia</p>
                <Button
                  color="info"
                  onClick={(e) => exportTourneeTeleo(e, row)}
                  style={{ margin: 0, marginTop: "10px" }}
                >
                  {t("all.text.export_to_teleo")}
                </Button>
              </div>
            )}
            {(_.get(tournee, "options.export_round_factu") === "true" ||
              _.get(tournee, "options.export_round_factu") === true) && (
              <div className="button-box">
                <p>{t("all.text.factu")}</p>
                <Button
                  color="info"
                  onClick={(e) => exportFactu(e, row, true)}
                  style={{ margin: 0, marginTop: "10px" }}
                >
                  {tournee.facturation}
                </Button>
                <p
                  style={{
                    padding: "10px",
                    textAlign: "right",
                    width: "100%",
                    textDecoration: "underline",
                    color: "blue",
                    cursor: "pointer",
                  }}
                  onClick={() => setAdvancedMode(true)}
                >
                  Avancé
                </p>
              </div>
            )}
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <WarningRounded width={20} color={"error"} />
            <p style={{ margin: "5px 0", color: "red" }}>
              {t("tournee.text.facturation.no_filter_export")}
            </p>
            <Button
              color="danger"
              onClick={(e) => {
                exportFactu(e, row, false);
                setAdvancedMode(false);
              }}
            >
              &#x26A0; {tournee.facturation}
            </Button>
            <Button
              style={{ margin: "20px 0" }}
              outline
              onClick={() => setAdvancedMode(false)}
            >
              {t("all.button.cancel")}
            </Button>
          </div>
        )}
      </PopoverBody>
    </UncontrolledPopover>
  );
};

function mapStateToProps(state) {
  const { tournee } = state;
  return {
    tournee,
  };
}

const mapping = connect(mapStateToProps)(AdvancedExportTrigger);

const connectedUser = withRouter(mapping);
const tr = withTranslation()(connectedUser);
export default tr;
