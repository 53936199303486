import React, { useEffect, useState } from "react";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import Select, { components, MultiValueGenericProps } from "react-select";
import SvgCapteur from "../SvgComponents/Capteur";
import sensorActions from "../_actions/sensor.actions";
import { locationActions } from "../_actions";
import SvgAjoutBleu from "../SvgComponents/AjoutBleu";

import "./style.scss";
import { Modal, Button } from "reactstrap";
import _ from "lodash";

import history from "../_helpers/history";
import clusterList from "./stub";

const CustomModal = ({
  setToggle,
  toggle,
  sensors,
  handleChange,
  handleValid,
  t,
}) => {
  console.log(sensors);
  const sensorList = sensors.map((el) => ({
    label: el.info.sensorSerial,
    value: el,
  }));
  console.log(sensorList);
  return (
    <Modal
      isOpen={toggle}
      toggle={() => setToggle(!toggle)}
      className="sensor-dashboard-modal"
    >
      <div className="title">
        <h5>Groupe capteurs</h5>
      </div>
      <div className="modal-select-name">
        <TextField
          label={t("all.text.name")}
          margin="normal"
          onChange={(e) => handleChange("name", e.target.value)}
        />
      </div>
      <div className="select-sensors">
        <Select
          closeMenuOnSelect={false}
          styles={{
            multiValueLabel: (base, i) => {
              return {
                ...base,
                backgroundColor: i.index % 2 === 0 ? "#add8e6" : "#b7dadd",
                color: "black",
              };
            },
          }}
          placeholder={`${t("sensor.text.sensor_plural")}...`}
          isMulti
          options={sensorList}
          onChange={(list) =>
            handleChange(
              "sensors",
              list.map((el) => el.label)
            )
          }
        />
      </div>
      <div className="sensor-modal-validation">
        <Button onClick={() => setToggle(false)}>
          {t("all.button.cancel")}
        </Button>
        <Button
          onClick={() => {
            handleValid();
            setToggle(false);
          }}
        >
          {t("all.button.validate")}
        </Button>
      </div>
    </Modal>
  );
};

const SensorDashboard = (props) => {
  const { t, sensor, dispatch, match } = props;
  const { params } = match;

  const [toggle, setToggle] = useState(false);
  const [content, setContent] = useState({
    name: "",
    sensors: [],
  });

  useEffect(() => {
    dispatch(sensorActions.getAll());
    dispatch(locationActions.get(params.locationId));
  }, []);

  const columns = [
    {
      dataField: "name",
      text: t("sensor.text.serial"),
      sort: true,
      filter: textFilter({
        placeholder: t("sensor.text.serial"),
      }),
    },
    {
      dataField: "sensors",
      text: t("telereleveinfo.text.deveui"),
      sort: true,
      filter: textFilter({
        placeholder: t("telereleveinfo.text.deveui"),
      }),
      formatter: (cell) => cell.map((el) => el.info.sensorSerial).join(", "),
    },
  ];

  const handleChange = (key, value) => {
    const clone = _.cloneDeep(content);
    clone[key] = value;
    setContent(clone);
  };

  const validate = () => {
    console.log(content);
    // dispatch
  };

  return (
    <div className="sensor-dashboard-container">
      {sensor.items && (
        <CustomModal
          handleChange={handleChange}
          handleValid={validate}
          toggle={toggle}
          setToggle={setToggle}
          sensors={sensor.items}
          t={t}
        />
      )}
      <div className="title">
        <SvgCapteur height="3.5em" fill="#31c6b3" />
        <h2>{t("sensor.dashboard")}</h2>
      </div>
      <div className="add-dashboard" onClick={() => setToggle(true)}>
        <SvgAjoutBleu height="1.5em" />
      </div>
      <div className="dashboard-sensor-container">
        {sensor.items && (
          <BootstrapTable
            keyField="id"
            data={clusterList}
            columns={columns}
            bootstrap4
            bordered={false}
            hover
            rowClasses="clickable"
            rowEvents={{
              onClick: (e, row) => {
                history.push({
                  pathname: `/locations/${params.locationId}/sensor/dashboard/${row.id}`,
                });
              },
            }}
            filter={filterFactory()}
            pagination={sensor.items.length > 10 ? paginationFactory() : null}
          />
        )}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { sensor } = state;
  return {
    sensor,
  };
}

export default withTranslation()(
  withRouter(connect(mapStateToProps)(SensorDashboard))
);
