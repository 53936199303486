import React, { Component, useState } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import moment from "moment";
import { withTranslation } from "react-i18next";
import { eachDayOfInterval, format, isSameDay } from "date-fns";
import { Grow, Tooltip } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import NoFilled from "../Message/NoFilled";
import SvgLastRead from "../SvgComponents/LastRead";
import StrongCons from "../SvgComponents/StrongCons";
import SmallStrongCons from "../SvgComponents/SmallStrongCons";
import SmallWeakCons from "../SvgComponents/SmallWeakCons";
import WeakCons from "../SvgComponents/WeakCons";
import NormalConso from "../SvgComponents/NormalConso";
import { tourneeActions } from "../_actions";
import { getDaysInInterval } from "../_helpers/date-helper";
import Manuel from "../SvgComponents/Manuel";
import Manual from "../Sensor/Manual";
import GoutteTelereleve from "../SvgComponents/GoutteTelereleve";
import Telereleve from "../SvgComponents/Telereleve";
import ReleveRadio from "../SvgComponents/ReleveRadio";
import ReleveManuelle from "../SvgComponents/ReleveManuelle";
import Estimation from "../SvgComponents/Estimation";

interface Props {
  pdi: any;
  updateLastRead: Function;
  meters: any;
  t: Function;
  radios: any;
  dateMin?: any;
  dateMax?: any;
  dispatch: Function;
  tournee: any;
  pdis: any;
  lastPertinentRead: any;
}

interface State {
  lastRead: any;
  init: boolean;
  chronoOrderedReads: any;
  isRealPrevActive: boolean;
}

class LastReading extends Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      init: false,
      lastRead: props.lastRead,
      chronoOrderedReads: [],
      isRealPrevActive: false,
    };
  }

  componentDidMount() {
    const { pdi, updateLastRead, dispatch, pdis } = this.props;
    const lastRead = _.find(pdi.meterList, (el) => el.endDate === null);
    const info = _.get(pdis, "fetchedPdi.general.tournee");
    if (info && info.codeTournee) {
      dispatch(
        tourneeActions.getFicheTournee(
          info.codeTournee,
          lastRead.serial.replace("/", "$S")
        )
      );
    }
    this.setState({
      init: true,
      lastRead,
    });
    updateLastRead(lastRead);
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(tourneeActions.clear());
  }

  getArrowCons(tendency: number) {
    switch (true) {
      case tendency > 50:
        return <StrongCons width="100%" fill="#31c6b3" height="100%" />;
      case tendency > 25:
        return <SmallStrongCons width="100%" fill="#31c6b3" height="100%" />;
      case tendency < -25:
        return <SmallWeakCons width="100%" fill="#31c6b3" height="100%" />;
      case tendency < -50:
        return <WeakCons width="100%" fill="#31c6b3" height="100%" />;
      default:
        return <NormalConso width="100%" fill="#31c6b3" height="100%" />;
    }
  }

  getReadTypeIcon(type: string, iconSize: number = 30) {
    switch (true) {
      case type === "T":
        return <Telereleve width={`${iconSize || 40}px`} />;
      case type === "A":
        return <ReleveRadio width={`${iconSize || 40}px`} />;
      case type === "M":
        return <ReleveManuelle width={`${iconSize || 40}px`} />;
      case type === "E":
        return <Estimation width={`${iconSize || 40}px`} />;
      default:
        return <NoFilled />;
    }
  }

  getActiveMeter() {
    const { meters } = this.props;
    const { lastRead } = this.state;
    let lastReadInfo;
    if (meters && meters.allMetersInfo && lastRead) {
      lastReadInfo = meters.allMetersInfo.find(
        (el) => el.general.id === lastRead.id && el.lastRead !== null
      );
    }
    return lastReadInfo;
  }

  render() {
    const { meters, t, tournee } = this.props;
    const { isRealPrevActive } = this.state;
    const tendency = _.get(tournee, "fetchedFiche.read.tendency");
    const lastReadInfo = this.getActiveMeter();
    const pulseWeight = lastReadInfo && lastReadInfo.general.model.pulseWeight;
    let nbDec = 0;
    const lastPertinentRead = lastReadInfo
      ? lastReadInfo.previousPertinantRead
      : null;
    if (pulseWeight && pulseWeight < 1) {
      nbDec = pulseWeight.toString().split(".")[1].length;
    }
    const areReadsSameDay = isSameDay(
      _.get(lastReadInfo, "previousRead.date"),
      _.get(lastReadInfo, "lastRead.date")
    );

    let previousRead = _.get(lastReadInfo, "previousRead");
    const currentRead = _.get(lastReadInfo, "lastRead");

    if (
      areReadsSameDay &&
      _.get(lastPertinentRead, "meterIndex") !== undefined &&
      !isRealPrevActive
    ) {
      previousRead = lastPertinentRead;
    }

    const daysBtwCurrentAndLastRead = getDaysInInterval(
      _.get(previousRead, "date"),
      _.get(currentRead, "date")
    );

    const currentRadio = _.find(
      _.get(lastReadInfo, "radioList"),
      (radio) => radio.endDate === null
    );
    const fluidType = _.get(lastReadInfo, "general.fluidType");
    const getConsumption = (meter: any, previousMeter: any) => {
      const wheels =
        _.get(lastReadInfo, "general.revisedWheels") ||
        _.get(lastReadInfo, "general.model.wheels");
      const index = _.get(meter, "meterIndex");
      const previousIndex = _.get(previousMeter, "meterIndex");
      return index >= previousIndex
        ? index - previousIndex
        : index - previousIndex + Math.pow(10, wheels);
    };
    return (
      <div
        className="last-read-pdi-container own-container flex-box"
        style={{ alignItems: "center", justifyContent: "center" }}
      >
        <div
          className="c15 flex-box"
          style={{ width: "20%", flexDirection: "column", padding: "0 2%" }}
        >
          <div style={{ display: "flex", margin: "10px 0" }}>
            <SvgLastRead
              height="2em"
              fill="#31c6b3"
              style={{ marginRight: 10 }}
            />
            <h3>{t("all.read_meter.last_read")}</h3>
          </div>
          <div className="center-content">
            <span>{t("all.meter.serial")}</span>
            <h3>
              {_.get(lastReadInfo, "general.serial")
                ? lastReadInfo.general.serial
                : "..."}
            </h3>
          </div>
          {currentRadio && (
            <div className="center-content">
              <span>{t("all.meter.radio_serial")}</span>
              <h3>
                {_.get(currentRadio, "serial")
                  ? _.get(currentRadio, "serial")
                  : "..."}
              </h3>
            </div>
          )}
        </div>
        {meters && meters.allMetersInfo && (
          <>
            <div className="c15" style={{ width: "80%", padding: 0 }}>
              <section className="conso-display">
                <section
                  className="previous"
                  style={!previousRead ? { width: "50%" } : {}}
                >
                  {previousRead ? (
                    <>
                      <div className="horizontal-line">
                        <div
                          style={
                            _.get(previousRead, "type")
                              ? { padding: "0 10%" }
                              : {}
                          }
                        >
                          {!_.get(previousRead, "type") &&
                            t("all.read_meter.read_method")}
                          <Tooltip
                            title={`${t("all.read_meter.read_method")} : 
                          ${
                            _.get(previousRead, "type")
                              ? t(
                                  `all.read_meter.type.${_.get(
                                    previousRead,
                                    "type"
                                  )}`
                                )
                              : t("all.text.not_specified")
                          }
                       `}
                          >
                            <i>
                              {this.getReadTypeIcon(
                                _.get(previousRead, "type")
                              )}
                            </i>
                          </Tooltip>
                        </div>
                        <div>
                          <span>
                            {areReadsSameDay &&
                            !isRealPrevActive &&
                            lastPertinentRead
                              ? t(
                                  "fiche_detail.title.closest_reference_index",
                                  {
                                    daysBack: daysBtwCurrentAndLastRead,
                                  }
                                )
                              : t("fiche_detail.title.previous_index")}{" "}
                            {fluidType && (
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: t(`meter.unit.${fluidType}`, {
                                    interpolation: { escapeValue: false },
                                  }),
                                }}
                              />
                            )}
                          </span>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <h3>
                              {Number.isFinite(
                                _.get(previousRead, "meterIndex")
                              ) ? (
                                _.get(previousRead, "meterIndex")
                              ) : (
                                <NoFilled />
                              )}
                            </h3>
                            {areReadsSameDay && lastPertinentRead && (
                              <Tooltip
                                title={
                                  <span style={{ fontSize: 11 }}>
                                    {isRealPrevActive
                                      ? t(
                                          "last_reading.text.tooltip_real_previous_index"
                                        )
                                      : t(
                                          "last_reading.text.tooltip_reference_index"
                                        )}
                                  </span>
                                }
                                TransitionComponent={Grow}
                                arrow
                                placement="top-start"
                                id="sameDayTooltip"
                              >
                                <div
                                  className={`real-prev-switch ${
                                    !isRealPrevActive
                                      ? "real-switch-on"
                                      : "real-switch-off"
                                  }`}
                                  onClick={() =>
                                    this.setState({
                                      isRealPrevActive: !isRealPrevActive,
                                    })
                                  }
                                >
                                  <i>
                                    <InfoOutlined />
                                  </i>
                                </div>
                              </Tooltip>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="horizontal-line">
                        <div>
                          <span>{t("all.read_meter.read_previous_date")}</span>
                          <h3>
                            {moment(
                              lastReadInfo && lastReadInfo.previousRead
                                ? previousRead.date
                                : "..."
                            ).isValid() ? (
                              moment(lastReadInfo && previousRead.date).format(
                                t("all.date_format.date_and_time")
                              )
                            ) : (
                              <NoFilled />
                            )}
                          </h3>
                        </div>
                        <div>
                          <span>{t("add_widget.text.source")}</span>
                          <h3>
                            {_.get(previousRead, "source") ? (
                              _.get(previousRead, "source")
                            ) : (
                              <NoFilled />
                            )}
                          </h3>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="noFilled" style={{ textAlign: "center" }}>
                      {t("last_reading.text.no_previous_read")}
                    </div>
                  )}
                </section>
                <section
                  className="average"
                  style={!previousRead ? { width: "0%" } : {}}
                >
                  <div id="triangle" className="start-arrow" />
                  {previousRead && (
                    <div className="content">
                      <div>
                        <span style={{ maxHeight: "90%", overflowY: "clip" }}>
                          {daysBtwCurrentAndLastRead === 1
                            ? t("all.read_meter.total_conso_in_day")
                            : t("all.read_meter.total_conso_in_period", {
                                totalDays:
                                  daysBtwCurrentAndLastRead > 29
                                    ? "+30"
                                    : daysBtwCurrentAndLastRead,
                              })}
                        </span>
                        <h3>
                          {Number.isFinite(_.get(currentRead, "consumption")) &&
                          Number.isFinite(_.get(currentRead, "meterIndex")) &&
                          Number.isFinite(_.get(previousRead, "meterIndex")) ? (
                            getConsumption(currentRead, previousRead).toFixed(
                              nbDec
                            )
                          ) : (
                            <NoFilled />
                          )}
                        </h3>
                      </div>
                      <div>
                        <span>{t("all.read_meter.tendency")}</span>
                        {tendency !== undefined ? (
                          this.getArrowCons(tendency)
                        ) : (
                          <NoFilled />
                        )}
                      </div>
                      <div>
                        <span>{t("all.read_meter.daily_avg_cons")}</span>
                        <h3>
                          {Number.isFinite(_.get(currentRead, "consumption")) &&
                          Number.isFinite(_.get(currentRead, "meterIndex")) &&
                          Number.isFinite(_.get(previousRead, "meterIndex")) ? (
                            (
                              getConsumption(currentRead, previousRead) /
                              daysBtwCurrentAndLastRead
                            ).toFixed(nbDec)
                          ) : (
                            <NoFilled />
                          )}
                        </h3>
                      </div>
                    </div>
                  )}
                  {previousRead && <div id="triangle" className="end-arrow" />}
                </section>
                <section
                  style={!previousRead ? { width: "50%" } : {}}
                  className={`current ${
                    _.get(currentRead, "type") === "T" ||
                    _.get(currentRead, "type") === "A"
                      ? "telereleve-radio"
                      : _.get(currentRead, "type") === "M"
                      ? "manuelle"
                      : _.get(currentRead, "type") === "E"
                      ? "estimation"
                      : "unknown-method"
                  }`}
                >
                  {currentRead ? (
                    <>
                      <div className="horizontal-line">
                        <div style={{ padding: "0 10%" }}>
                          <Tooltip
                            title={`${t("all.read_meter.read_method")} : 
                          ${
                            _.get(currentRead, "type")
                              ? t(
                                  `all.read_meter.type.${_.get(
                                    currentRead,
                                    "type"
                                  )}`
                                )
                              : t("all.text.not_specified")
                          }
                       `}
                          >
                            <i>
                              {this.getReadTypeIcon(_.get(currentRead, "type"))}
                            </i>
                          </Tooltip>
                        </div>
                        <div>
                          <span>
                            {t("fiche_detail.title.current_index")}{" "}
                            {fluidType && (
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: t(`meter.unit.${fluidType}`, {
                                    interpolation: { escapeValue: false },
                                  }),
                                }}
                              />
                            )}
                          </span>
                          <h3>
                            {Number.isFinite(
                              _.get(currentRead, "meterIndex")
                            ) ? (
                              _.get(currentRead, "meterIndex")
                            ) : (
                              <NoFilled />
                            )}
                          </h3>
                        </div>
                      </div>
                      <div className="horizontal-line">
                        <div>
                          <span>{t("all.read_meter.read_last_date")}</span>
                          <h3>
                            {moment(
                              lastReadInfo && currentRead
                                ? currentRead.date
                                : "..."
                            ).isValid() ? (
                              moment(lastReadInfo && currentRead.date).format(
                                t("all.date_format.date_and_time")
                              )
                            ) : (
                              <NoFilled />
                            )}
                          </h3>
                        </div>
                        <div>
                          <span>{t("add_widget.text.source")}</span>
                          <h3>
                            {_.get(currentRead, "source") || <NoFilled />}
                          </h3>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="noFilled" style={{ textAlign: "center" }}>
                      {t("last_reading.text.no_last_read")}
                    </div>
                  )}
                </section>
              </section>
            </div>
          </>
        )}
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { meters, radios, pdis, tournee } = state;

  return {
    meters,
    radios,
    pdis,
    tournee,
  };
}

const LastReadingPDI = connect(mapStateToProps)(LastReading);
const tr = withTranslation()(LastReadingPDI);
export default tr;
