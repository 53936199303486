export default {
  "read.readMethod": {
    M: "Manuelle",
    A: "Radio",
    T: "Télérelève",
    E: "Estimé",
  },
  "currentRead.readMethod": {
    M: "Manuelle",
    A: "Radio",
    T: "Télérelève",
    E: "Estimé",
  },
  "meter.fluidType": {
    W: "Eau",
    C: "Eau froide",
    H: "Eau chaude",
    E: "Electricité",
    G: "Gaz",
    T: "Thermique",
    O: "Autre",
  },
};
