import React, { ComponentType } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, {
  Comparator,
  textFilter,
} from "react-bootstrap-table2-filter";
import { Col, Row, UncontrolledTooltip } from "reactstrap";
import AlarmeVert from "../SvgComponents/AlarmeVert";
import PoubelleBleue from "../SvgComponents/PoubelleBleu";
import EditionBleue from "../SvgComponents/EditionBleu";
import {
  alarmActions,
  localeActions,
  locationActions,
  userActions,
} from "../_actions";
import confirm from "../_components";
import AlarmeCreator from "../Alarm/AlarmCreator";
import { alarms, locales, locations } from "../_interfaces/reducers";
import { User } from "../_entities/user";
import { Alert } from "../_entities/alert";
import Switch from "rc-switch";
import history from "../_helpers/history";
import "rc-switch/assets/index.css";
import LoadingBand from "../Bands/Loading";
import ErrorBand from "../Bands/Error";
import SvgEye from "../SvgComponents/Eye";
import SvgInformation from "../SvgComponents/InformationVert";
import _ from "lodash";
import { withTranslation } from "react-i18next";
import { translate } from "../_helpers";
import { compose } from "redux";

interface Props {
  dispatch: Function;
  alarms: alarms;
  locales: locales;
  user: User;
  history: Array<string>;
  match: any;
  locations: locations;
  users: any;
  t: Function;
}

interface State {
  availableUsers: Array<User>;
  toUpdate: Alert | {};
  isOpen: boolean;
}

/**
 * Liste des templates
 *
 * @class AlarmsComponent
 * @extends {React.Component<Props>}
 */
class AlarmsComponent extends React.Component<Props, State> {
  /**
   * @param {Props} props Propriétés
   * @constructor
   * @memberof AlarmsComponent
   */
  constructor(props: Props) {
    super(props);
    this.delete = this.delete.bind(this);
    this.state = {
      availableUsers: [],
      toUpdate: {},
      isOpen: false,
    };
  }

  /**
   * Charge les traductions et les alarmes
   * au montage du composant
   *
   * @method componentDidMount
   * @memberof AlarmsComponent
   */
  componentDidMount() {
    const { dispatch, match, user, history, locations } = this.props;
    const { locationId } = match.params;
    dispatch(userActions.get(user.id));
    dispatch(locationActions.get(locationId));
    dispatch(localeActions.load());
    dispatch(userActions.getAll());
  }

  componentDidUpdate(prevProps: Props, prevState: State, snapshot: any) {
    const { locations, dispatch, users, user } = this.props;
    const { fetchedLocation } = locations;
    if (
      !prevProps.alarms.items &&
      !prevProps.alarms.loading &&
      !prevProps.alarms.error &&
      fetchedLocation
    ) {
      const { code } = fetchedLocation;
      if (undefined !== code) {
        dispatch(alarmActions.getAll(code));
      }
    }
    const roleList = ["DIOPTASE", "SUPERADMIN", "ADMIN"];
    if (
      !roleList.includes(user.role.name) &&
      locations.fetchedLocation &&
      !locations.fetchedLocation.tournee && //CETTE LIGNE ALEXIS
      users.fetchedUser &&
      !users.fetchedUser.profils.find(
        (el) =>
          el.locationCode === locations.fetchedLocation.code &&
          el.profil.permissions.length > 0
      )
    ) {
      history.push("/forbidden");
    }
  }

  /**
   * Vide le state redux des alarmes au
   * démontage du composant
   *
   * @method componentWillUnmount
   * @memberof AlarmsComponent
   */

  /**
   * Gère les droits d'edition des alertes
   *
   * @returns {boolean} Le contrôle
   * @method couldEditOrDeleteAlert
   * @memberof AlarmsComponent
   */
  couldEditOrDeleteAlert = () => {
    const { user, locations, users } = this.props;

    if (["DIOPTASE", "SUPERADMIN", "ADMIN"].includes(user.role.name)) {
      return true;
    }

    if (locations.fetchedLocation && locations.fetchedLocation.tournee) {
      //CETTE LIGNE ALEXIS
      return true;
    }

    if (users?.items?.length > 0) {
      const actualUser = _.find(users.items, (el: any) => el.id === user.id);
      if (
        actualUser.profils.find(
          (el: any) =>
            el.profil.permissions.find(
              (permission: any) => permission.name === "edit.alert"
            ) && el.locationCode === locations.fetchedLocation.code
        )
      ) {
        return true;
      }
    }

    return false;
  };

  /**
   * Gère l'ouverture et fermeture de la modal
   *
   * @method toggle
   * @memberof AlarmCreatorComponent
   */
  toggleUpdate = () => {
    const { dispatch } = this.props;
    const { isOpen } = this.state;
    this.setState({
      isOpen: !isOpen,
      toUpdate: {},
    });
  };

  /**
   * Formate les alarmes
   *
   * @returns {Array<Object>}
   * @method representItem
   * @memberof AlarmsComponent
   */
  representItem = () => {
    const { alarms, users } = this.props;
    const { availableUsers } = this.state;
    return alarms?.items?.map((it: any) => {
      let alarm = {
        id: it.id,
        name: `${it.content.name}`,
        type: it.content.alertName,
        users: it.users.map((u: any) =>
          users?.items?.find((user: any) => user.id === u)
        ),
        unit: it.content.dataSourceProperty.alertUnit,
        isActive: it.isActive,
        locationCode: it.locationCode,
      };
      return alarm;
    });
  };

  /**
   * Gère la suppression du template donné
   *
   * @async
   * @param {Object} e Evènement
   * @param {*} template Template
   * @method delete
   * @memberof AlarmsComponent
   */
  async delete(e: any, alarm: any) {
    const { dispatch, alarms, t } = this.props;
    const { Fragment } = React;

    const result = await confirm({
      title: (
        <Fragment>
          <strong>{t("all.alert.deactivate_alert")}</strong>
        </Fragment>
      ),
      message: t("all.alert.irreversible_action_deactivate_alert"),
      confirmText: t("all.button.delete"),
      confirmColor: "danger",
      cancelColor: "primary",
      cancelText: t("all.button.cancel"),
    });
    if (result) {
      dispatch(alarmActions.toDelete(alarm));
    }
  }

  /**
   * Methode de rendu
   *
   * @method render
   */
  render() {
    const { alarms, match, locations, dispatch, locales, t } = this.props;
    const { availableUsers, isOpen, toUpdate } = this.state;
    const { locationId } = match.params;

    const alarmFilter = textFilter({
      placeholder: t("alarms.filter_placeholder.alert_filter"), // custom the input placeholder
      className: "alarm-custom-text-filter", // custom classname on input
      defaultValue: "", // default filtering value
      comparator: Comparator.LIKE, // default is Comparator.LIKE
    });
    const columns = [
      {
        dataField: "name",
        text: t("all.alarm_meter.alarm"),
        filter: alarmFilter, // apply text filter
      },
      {
        dataField: "type",
        text: t("all.alert.alert_type"),
        sort: true,
        headerStyle: () => ({ verticalAlign: "top" }),
        formatter: (type: string, row: any) => {
          return (
            <span>{translate("fr", "alarmName", type, locales.locale)}</span>
          );
        },
      },
      {
        dataField: "unit",
        text: t("all.text.unity"),
        sort: true,
        headerStyle: () => ({ verticalAlign: "top" }),
        formatter: (unit: string, row: any) => {
          return <span>{translate("fr", "unit", unit, locales.locale)}</span>;
        },
      },
      {
        dataField: "users",
        text: t("all.user.user_plural"),
        sort: true,
        headerStyle: () => ({ verticalAlign: "top" }),
        formatter: (users: any, row: any) => {
          let userNames = users
            .map((it: any) => (it && it.email ? it.email : ""))
            .join(", ");
          if (users.length < 5) {
            return <span>{userNames}</span>;
          } else {
            return (
              <div className="row-user-table flex-box">
                <span>
                  {users.length} {t("all.user.user_plural")}
                </span>
                <div className="svg" id={`alarm-row-${row.id}`}>
                  <SvgInformation height="1em" fill="#333" />
                  <UncontrolledTooltip
                    placement="bottom"
                    target={`alarm-row-${row.id}`}
                  >
                    {" "}
                    {users.map((user: any) => (
                      <div style={{ textAlign: "left" }}>{user.email}</div>
                    ))}
                  </UncontrolledTooltip>
                </div>
              </div>
            );
          }
        },
      },
      {
        dataField: "action",
        isDummyField: true,
        align: "center",
        headerStyle: () => ({ width: "240px", verticalAlign: "top" }),
        text: "",
        formatter: (cellContent: any, row: Alert) => {
          console.log(row, cellContent);
          return (
            <Row>
              <Col xs="3" style={{ margin: "auto" }}>
                {this.couldEditOrDeleteAlert() ? (
                  <Switch
                    onChange={(checked: boolean, e: any) => {
                      console.log(row);
                      row.isActive = !row.isActive;
                      console.log(row);
                      dispatch(alarmActions.setActive(row));
                    }}
                    checked={row.isActive}
                  />
                ) : (
                  <Switch disabled checked={row.isActive} />
                )}
              </Col>
              <Col xs="3">
                <div
                  id={`alarm${row.id}-reports`}
                  className="clickable round"
                  role="presentation"
                  onClick={(e) => {
                    history.push(
                      `/locations/${locationId}/alerts/${row.id}/reports/`
                    );
                  }}
                >
                  <SvgEye height="1.3em" width="1.3em" />
                  <UncontrolledTooltip
                    placement="bottom"
                    target={`alarm${row.id}-reports`}
                  >
                    {" "}
                    {t("all.alert.view_report_plural")}
                  </UncontrolledTooltip>
                </div>
              </Col>
              <Col xs="3">
                {this.couldEditOrDeleteAlert() ? (
                  <div
                    id={`alarm${row.id}-edit`}
                    className="clickable round"
                    role="presentation"
                    onClick={(e) => {
                      history.push(
                        `/locations/${locationId}/alerts/editalert?id=${row.id}`
                      );
                    }}
                  >
                    <EditionBleue height="1em" width="1em" />
                    <UncontrolledTooltip
                      placement="bottom"
                      target={`alarm${row.id}-edit`}
                    >
                      {" "}
                      {t("all.alert.edit_alert")}
                    </UncontrolledTooltip>
                  </div>
                ) : (
                  <div
                    id={`alarm${row.id}-edit`}
                    className="round-disabled"
                    role="presentation"
                  >
                    <EditionBleue height="1em" width="1em" fill="white" />
                  </div>
                )}
              </Col>
              <Col xs="3">
                {this.couldEditOrDeleteAlert() ? (
                  <div
                    id={`alarm${row.id}-remove`}
                    className="clickable round delete-alert"
                    role="presentation"
                    onClick={(e) => this.delete(e, row)}
                  >
                    <PoubelleBleue height="1em" width="1em" />
                    <UncontrolledTooltip
                      placement="bottom"
                      target={`alarm${row.id}-remove`}
                    >
                      {" "}
                      {t("all.alert.delete_alert")}
                    </UncontrolledTooltip>
                  </div>
                ) : (
                  <div
                    id={`alarm${row.id}-remove`}
                    className="round-disabled delete-alert"
                    role="presentation"
                  >
                    <PoubelleBleue height="1em" width="1em" fill="white" />
                  </div>
                )}
              </Col>
            </Row>
          );
        },
      },
    ];
    return (
      <div className="col-12">
        {alarms.loading && <LoadingBand message="Chargement des alertes ..." />}
        {alarms.error && <ErrorBand message={alarms.error} />}
        {alarms?.items && locations && locations.fetchedLocation && (
          <div className="table-info-container">
            {" "}
            <div>
              <h2>
                <span>
                  <AlarmeVert height="1em" width="1em" fill="#31c6b3" />
                </span>
                {t("all.alert.alert_plural")} :
                {this.couldEditOrDeleteAlert() && (
                  <span className="addItem float-right">
                    {" "}
                    <AlarmeCreator
                      locationId={locationId}
                      locationCode={locations.fetchedLocation.code}
                      availableUsers={availableUsers}
                    />
                  </span>
                )}
              </h2>
            </div>
            <BootstrapTable
              keyField="id"
              data={this.representItem()}
              columns={columns}
              bootstrap4
              bordered={false}
              hover
              filter={filterFactory()}
              pagination={
                alarms.items?.length > 10 ? paginationFactory() : null
              }
            />
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication, alarms, alert, locales, locations, users } = state;
  const { user } = authentication;
  return {
    user,
    alert,
    alarms,
    locales,
    locations,
    users,
  };
}

const connectedAlarms = compose<ComponentType<any>>(
  withRouter,
  connect(mapStateToProps)
)(AlarmsComponent);
const tr = withTranslation()(connectedAlarms);
export default tr;
