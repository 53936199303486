import React from "react";

const Estimation = (props: any) => (
  <svg viewBox="0 0 105.1 105.1" {...props}>
    <path
      fill={props.fill}
      d="M26.4,69.5V25.7h-8.5v34.8c2.2,3.6,5.1,6.7,8.5,9.1Z"
    />
    <path
      fill={props.fill}
      d="M41.7,40.8h-8.5v32.4c2.7,1,5.5,1.7,8.5,1.9v-34.3Z"
    />
    <path
      fill={props.fill}
      d="M57.9,25.7h-8.5v49.2c3-.5,5.9-1.4,8.5-2.7V25.7Z"
    />
    <path
      fill={props.fill}
      d="M73.3,55.1v-14.3h-8.5v26.6c3.8-3.3,6.7-7.5,8.5-12.3Z"
    />
    <path
      fill={props.fill}
      d="M103.8,97.6l-25.3-25.3c6.3-7.6,10-17.4,10-28.1C88.6,19.8,68.7,0,44.3,0S0,19.8,0,44.3s19.8,44.3,44.3,44.3,20.4-3.8,28.1-10l25.3,25.2c1.7,1.7,4.5,1.7,6.2,0,1.7-1.7,1.7-4.5,0-6.2h0ZM44.3,79.8c-19.6,0-35.5-15.9-35.5-35.5S24.7,8.8,44.3,8.8s35.5,15.9,35.5,35.5-15.9,35.5-35.5,35.5h0ZM44.3,79.8"
    />
  </svg>
);

export default Estimation;
