import React, { Component, Fragment } from "react";
import moment from "moment";
import cloneDeep from "lodash/cloneDeep";
import { connect } from "react-redux";
import {
  Alert,
  Button,
  ButtonGroup,
  Card,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import _ from "lodash";
import { withTranslation } from "react-i18next";
import { format, isAfter, isBefore, isSameDay } from "date-fns";
import chartTemplate from "../_shared/ChartTemplate.json";
import alarmTemplate from "../_shared/AlarmTemplate.json";
import {
  colorActions,
  localeActions,
  locationActions,
  meterActions,
  pdiActions,
} from "../_actions";
import maskActions from "../_actions/mask.actions";
import { SourceSheetMeter } from "./SourceSheetInstance";
import NbCompteurVert from "../SvgComponents/NbCompteurVert";
import { widgetConstants } from "../_constants";
import LoadingBand from "../Bands/Loading";
import ErrorBand from "../Bands/Error";
import { reverseAlarm } from "../_helpers/locale-helpers";
import LastReading from "../pdi_vue/LastReading";
import Historique from "../pdi_vue/Historique";
import Alarmes from "../pdi_vue/Alarmes";
import SecondDateComponent from "../_components/BestDateComponent";
import BestDateComponent from "../MasksAlarmCreator/BestDateComponent";
import AlarmRadioButton from "../pdi_vue/AlarmRadioButton";
import NoFilled from "../Message/NoFilled";
import SvgDoigtVert from "../SvgComponents/DoigtVert";
import TeleReleveInfo from "../_components/TeleReleveInfo";
import radioActions from "../_actions/radio.actions";
import ListTools from "../List/ListTools";
import SvgAjoutBleu from "../SvgComponents/AjoutBleu";
import TourneeBand from "./TourneeBand";
import Mapbox from "../Mapbox/components/Mapbox";
import StrongCons from "../SvgComponents/StrongCons";
import SmallStrongCons from "../SvgComponents/SmallStrongCons";
import SmallWeakCons from "../SvgComponents/SmallWeakCons";
import WeakCons from "../SvgComponents/WeakCons";
import NormalConso from "../SvgComponents/NormalConso";
import Telereleve from "../SvgComponents/Telereleve";
import ReleveRadio from "../SvgComponents/ReleveRadio";
import ReleveManuelle from "../SvgComponents/ReleveManuelle";
import { getDaysInInterval } from "../_helpers/date-helper";
import HistoChart from "./HistoChart";
import SvgCalendar from "../SvgComponents/HistoriqueVert";
import GaleryPhoto from "./GaleryPhoto";
import EditionBleu from "../SvgComponents/EditionBleu";
import StyledInput from "../FicheDetail/useFullComponent/StyledInput";
import SvgCroixNoir from "../SvgComponents/croixNoir";
import SvgValidation from "../SvgComponents/ValidationVert";
import StyledSelect from "../FicheDetail/useFullComponent/StyledSelect";
import tourneeActions from "../_actions/tournee.actions";
import StyledTextArea from "../FicheDetail/useFullComponent/StyledTextArea";
import ArrowFicheButton from "../_components/ArrowFicheButton";
import MaximoBT from "./MaximoBT";

interface LastPertinentRead {
  date: string;
  meterIndex: number;
  type: string;
  index: number;
  radioIndex: number;
  source?: string;
}

interface Props {
  pdis: any;
  locations: any;
  dispatch: Function;
  match: any;
  location: any;
  meters: any;
  locales: any;
  t: Function;
  tournee: any;
  interval: any;
  user: any;
  users: any;
}

interface State {
  activeTab: any;
  template: any;
  graphType: string;
  consumption: any;
  alarm: any;
  lastRead: any;
  oldMeterActive: any;
  isOver: any;
  isOpen: boolean;
  alarmType: Array<any>;
  dateMin: any;
  dateMax: any;
  zoom: any;
  maskName: any;
  alarmTimeType: any;
  offSetFixed: number;
  modalDate: any;
  dateSet: boolean;
  lastPertinentRead: any;
  usedStartDate: any;
  usedEndDate: any;
  displayTelereleve: any;
  displayTerrain: any;
  init: boolean;
}

/**
 * @class SourceSheetPDIComponent
 * @extends {Component}
 */
class SourceSheetPDIComponent extends Component<Props & {}, any> {
  myRef: any;
  static meterTemplate = {
    id: "Meter",
    dataSourceName: "Meter",
    conditionTitle: "MeterSerialNumber",
    displayValue: "MeterReadingValue",
    displayID: widgetConstants.READINDEXWIDGET,
  };

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ) {
    const {
      dateSet,
      lastRead,
      template,
      usedStartDate: oldStartDate,
      usedEndDate: oldEndDate,
      zoom: oldZoom,
      dateMin,
      dateMax,
    } = prevState;
    const { activeTab: currentActiveTab, init } = this.state;
    const { meters, interval: oldInterval } = prevProps;
    const {
      dispatch,
      meters: currentMeters,
      match,
      locations,
      interval,
      pdis,
      tournee,
    } = this.props;
    const { locationId, pdiId } = match.params;

    if (!dateSet && _.values(meters.allMetersInfo).length > 0 && lastRead) {
      const meterFound = _.values(meters.allMetersInfo).filter(
        (m) => m.general.serial === lastRead.serial
      )[0];
      const dateLastRead = moment.utc(
        _.get(meterFound, "lastRead.date") || moment()
      );
      this.handleChange(
        dateLastRead.format(`${moment.HTML5_FMT.DATE}`),
        "dateMax",
        false
      );
      this.handleChange(
        dateLastRead.add(-1, "month").format(`${moment.HTML5_FMT.DATE}`),
        "dateMin",
        false
      );
      this.setState({
        dateSet: true,
      });
    }
    if (
      prevProps.meters !== meters &&
      meters.allMetersInfo &&
      meters.allMetersInfo.length > 0
    ) {
      const serial =
        meters.allMetersInfo[meters.allMetersInfo.length - 1].general.serial;
      dispatch(radioActions.getRadioInfoWithSerial(serial));
    }
    if (
      !meters.allMetersInfo &&
      !init &&
      locations.fetchedLocation &&
      pdis.fetchedPdi
    ) {
      const content = {
        dateMin: moment().subtract(1, "month").utc(),
        dateMax: moment().utc(),
        zoom: template.dataSourceProperty.displayProperty.displayZoom.toUpperCase(),
      };
      dispatch(
        meterActions.getAllMetersFromPdi(
          match.params.pdiId,
          locations.fetchedLocation.code,
          content
        )
      );
      console.log("EDDDITT INFOS", this.props);
      this.setState({ init: true });
    }
    if (
      _.size(meters.allMetersInfo) !== _.size(currentMeters.allMetersInfo) &&
      !lastRead
    ) {
      const currentMeter = currentMeters?.allMetersInfo?.find(
        (el: any) => el.general.endDate === null
      );
      const d =
        currentMeter?.lastRead != null
          ? new Date(_.get(currentMeter, "lastRead.date"))
          : new Date();
      const dMin = _.cloneDeep(d);
      dMin.setHours(0, 0, 0, 0);
      dMin.setMonth(d.getMonth() - 1);
      const dMax = _.cloneDeep(d);
      dMax.setHours(23, 59, 59, 999);
      const content = {
        dateMin: `${moment(dMin.toString()).format().split("+")[0]}.000Z`,
        dateMax: `${moment(dMax.toString()).format().split("+")[0]}.999Z`,
        zoom: template.dataSourceProperty.displayProperty.displayZoom.toUpperCase(),
      };
      const copyTemplate = _.cloneDeep(template);
      copyTemplate.dataSourceProperty.displayProperty.condition[1].conditionValue =
        moment(_.get(currentMeter, "lastRead.date", moment()))
          .subtract(1, "month")
          .utc();
      copyTemplate.dataSourceProperty.displayProperty.condition[2].conditionValue =
        moment(_.get(currentMeter, "lastRead.date", moment())).utc();
      dispatch(
        meterActions.getAllMetersFromPdi(
          match.params.pdiId,
          locations?.fetchedLocation?.code,
          content
        )
      );
      this.setState({
        lastRead: currentMeter.lastRead,
        template: copyTemplate,
      });
    }
    if (interval !== oldInterval) {
      const dMin = new Date(interval.current.startDate);
      dMin.setHours(0, 0, 0, 0);
      const dMax = new Date(interval.current.endDate);
      dMax.setHours(23, 59, 59, 999);
      const content = {
        dateMin: `${moment(dMin.toString()).format().split("+")[0]}.000Z`,
        dateMax: `${moment(dMax.toString()).format().split("+")[0]}.999Z`,
        zoom: interval.current.zoom.toUpperCase(),
      };
      dispatch(
        meterActions.getAllMetersFromPdi(
          match.params.pdiId,
          locations.fetchedLocation.code,
          content
        )
      );
    }

    if (prevProps.match.params.pdiId !== pdiId) {
      dispatch(meterActions.clear());
      dispatch(pdiActions.getFullPdi(pdiId));
      dispatch(maskActions.getMaskByPdiId(pdiId, locationId));
      dispatch(localeActions.load());
      dispatch(colorActions.load());
      dispatch(locationActions.get(locationId));
      dispatch(tourneeActions.getMessagesMarquages());
      this.setState({ dateSet: false, init: false });
    }
  }

  /**
   * Met les informations de base dans le template,
   * et créé l'instance
   *
   * @constructor
   * @memberof SourceSheetPDIComponent
   */
  constructor(props: Props) {
    super(props);
    window.scrollTo(0, 0);
    this.myRef = React.createRef();
    const defaultMinDate = moment()
      .subtract(1, "month")
      .format(`${moment.HTML5_FMT.DATE} ${moment.HTML5_FMT.TIME_SECONDS}`);
    const defaultMaxDate = moment().format(
      `${moment.HTML5_FMT.DATE} ${moment.HTML5_FMT.TIME_SECONDS}`
    );
    const alarm: any = cloneDeep(alarmTemplate);
    const template: any = cloneDeep(chartTemplate);
    template.dataSourceProperty.displayUnit = "m3";
    template.dataSourceProperty.displayProperty.condition[1].conditionValue =
      null;
    template.dataSourceProperty.displayProperty.condition[2].conditionValue =
      null;
    template.dataSourceName = "Meter";

    template.dataSourceProperty.displayProperty.condition[0].conditionTitle =
      "MeterSerialNumber";
    template.dataSourceProperty.displayValue = "MeterReadingValue";
    template.dataSourceProperty.displayID = widgetConstants.READINDEXWIDGET;

    const consumption: any = cloneDeep(template);
    consumption.dataSourceProperty.displayID =
      widgetConstants.READCONSUMTIONWIDGET;
    consumption.dataSourceProperty.displayUnit = "m3";
    template.dataSourceProperty.displayValue = "MeterConsumptionValue";

    alarm.dataSourceProperty.displayProperty.condition[1].conditionValue =
      defaultMinDate;
    alarm.dataSourceProperty.displayProperty.condition[2].conditionValue =
      defaultMaxDate;
    alarm.dataSourceProperty.displayID = widgetConstants.VIRTUALALARMWIDGET;

    this.state = {
      activeTab: null,
      template,
      consumption,
      alarm,
      graphType: "",
      lastRead: null,
      oldMeterActive: null,
      isOver: "",
      isOpen: false,
      alarmType: [],
      dateMin: moment().toISOString(false),
      dateMax: moment().add(1, "days").toISOString(false),
      zoom: "DAY",
      maskName: "",
      alarmTimeType: [],
      offSetFixed: -1,
      modalDate: "",
      dateSet: false,
      displayTelereleve: true,
      displayTerrain: true,
      lastPertinentRead: null,
      usedStartDate: null,
      usedEndDate: null,
      edit: null,
      editableFieldValue: null,
      init: false,
    };
    this.onSelect = this.onSelect.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.updateLastRead = this.updateLastRead.bind(this);
    this.handleChangeAlarm = this.handleChangeAlarm.bind(this);
    this.dateDecroissant = this.dateDecroissant.bind(this);
    this.handleChart = this.handleChart.bind(this);
    this.handleDate = this.handleDate.bind(this);
    this.handleZoom = this.handleZoom.bind(this);
    this.handleTypeAlarm = this.handleTypeAlarm.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.addGpsPosition = this.addGpsPosition.bind(this);
    this.getActualUsedDates = this.getActualUsedDates.bind(this);
    this.validChange = this.validChange.bind(this);
  }

  /**
   * Récupère les éléments nécessaires au composant
   * au montage du composant
   *
   * @method componentDidMount
   * @memberof SourceSheetPDIComponent
   */
  componentDidMount() {
    const { dispatch, match, locations, location } = this.props;
    const { template, consumption, alarm } = this.state;
    const { locationId, pdiId } = match.params;
    const params = location.search;
    dispatch(pdiActions.getFullPdi(pdiId));
    dispatch(maskActions.getMaskByPdiId(pdiId, locationId));
    dispatch(localeActions.load());
    dispatch(colorActions.load());
    dispatch(locationActions.get(locationId));
    dispatch(tourneeActions.getMessagesMarquages());
    console.log(this.props);
    window.addEventListener("scroll", this.handleScroll, true);
    // this.setState({ statut: JSON.parse(localStorage.getItem('statut')) });
  }

  /**
   * Nettoie le state redux au démontage
   *
   * @method componentWillUnmount
   * @memberof SourceSheetPDIComponent
   */
  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(locationActions.clear());
    dispatch(meterActions.clear());
    dispatch(pdiActions.clear());
    // localStorage.setItem('statut', this.state.statut);
    window.removeEventListener("scroll", this.handleScroll);
  }

  /**
   * Récupère les infos de géolocalisation du PDI
   * s'il en possède, sinon prend celles du site
   *
   * @method getGPS
   * @memberof SourceSheetPDIComponent
   * @returns {Object} les coordonnées GPS
   */
  getGPS = () => {
    const { pdis, locations } = this.props;
    const gpsData: any = {};
    if (pdis.fetchedPdi && pdis.fetchedPdi.general) {
      if (
        undefined !== pdis.fetchedPdi.general.gpsPosition &&
        pdis.fetchedPdi.general.gpsPosition !== null
      ) {
        gpsData.pdi = pdis.fetchedPdi.general.gpsPosition;
      }
    } else if (
      locations.fetchedLocation &&
      locations.fetchedLocation.content &&
      undefined !== locations.fetchedLocation.content.gpsPosition &&
      locations.fetchedLocation.content.gpsPosition !== null
    ) {
      gpsData.location = {
        lat: locations.fetchedLocation.content.gpsPosition.lat,
        lng: locations.fetchedLocation.content.gpsPosition.lng,
      };
    }
    return gpsData;
  };

  getActualUsedDates = (usedStartDate, usedEndDate) => {
    this.setState({
      usedStartDate,
      usedEndDate,
    });
  };

  /**
   * Convertie l'état du PDI d'une donnée numérique
   * à textuelle
   *
   * @method convertPDIStatus
   * @memberof SourceSheetPDIComponent
   * @returns {String} L'état
   */
  convertPDIStatus = (status: number | null | undefined) => {
    const { t } = this.props;
    switch (status) {
      case 1:
        return t("all.text.active");
      case 0:
        return t("all.text.inactive");
      default:
        return t("all.text.not_define");
    }
  };

  addGpsPosition() {
    const { dispatch, match } = this.props;
    const { pdiId } = match.params;
    dispatch(pdiActions.addGpsPosition(pdiId));
  }

  validChange() {
    const { dispatch, pdis } = this.props;
    const { edit, editableFieldValue } = this.state;
    console.log(edit, editableFieldValue);
    let value = null;
    switch (edit) {
      case "marquage":
        value = editableFieldValue.value;
        break;
      case "note":
        value = editableFieldValue;
        break;
      default:
        break;
    }
    console.log(pdis.fetchedPdi.general.id, edit, value);
    dispatch(
      pdiActions.updateInfo(
        pdis.fetchedPdi.general.id,
        edit,
        value,
        editableFieldValue.label
      )
    );
    this.setState({
      edit: null,
      editableFieldValue: null,
    });
  }

  generatePDIInfos = () => {
    const { match, pdis, tournee, user, users, locations, t } = this.props;
    const { edit, editableFieldValue } = this.state;
    const { fetchedPdi } = pdis;
    const gps = this.getGPS();

    const couldBeEdit = () => {
      if (
        _.get(users, "fetchedUser.profils", []).find(
          (p: any) =>
            p.locationCode === _.get(locations, "fetchedLocation.code") &&
            p.profil.permissions.find((el: any) => el.name === "edit.message")
        )
      ) {
        return true;
      }
      return ["DIOPTASE", "SUPERADMIN", "ADMIN"].includes(user.role.name);
    };

    return (
      <div>
        {pdis && pdis.fetchedPdi && pdis.fetchedPdi.general && (
          <div className="flex-box-pdi" id="fiche-pdi-width">
            <div className="info-pdi" style={{ marginRight: "1%" }}>
              <div>
                <div
                  className="presentation-container bi-container"
                  style={{ marginBottom: 0 }}
                >
                  <div
                    className="presentation-header"
                    style={{ backgroundSize: "800px" }}
                  >
                    <div className="ref-pdi">
                      <span className="presentation-title">
                        {t("all.pdi.pdi_ref")}
                      </span>
                      <span className="presentation-main-title">
                        {fetchedPdi.general.reference ||
                          t("all.text.not_specified")}{" "}
                      </span>
                    </div>
                  </div>
                  <div className="presentation-body">
                    <div>
                      <p>
                        <span className="infoLibelle">
                          {t("all.text.state")}
                        </span>{" "}
                        <br />
                        <span className="infoDisplay">
                          {fetchedPdi.general.state === 1
                            ? t("all.text.active")
                            : t("all.text.inactive")}{" "}
                        </span>
                      </p>
                      <p className="marginLine">
                        <span className="infoLibelle">
                          {t("all.location.address")}
                        </span>{" "}
                        <br />
                        <span className="infoDisplay">
                          {(
                            fetchedPdi.general.address.streetNumber +
                            fetchedPdi.general.address.streetNumberAdditionnal +
                            fetchedPdi.general.address.street
                          ).length > 0 ? (
                            <div>
                              {[
                                [
                                  "streetNumber",
                                  "streetNumberAdditionnal",
                                  "street",
                                ],
                                ["complement"],
                                ["zipcode", "city"],
                                ["country"],
                              ]
                                .filter((line) =>
                                  _.some(
                                    line,
                                    (field) =>
                                      !_.isEmpty(
                                        _.get(
                                          fetchedPdi,
                                          `general.address.${field}`,
                                          ""
                                        ) || ""
                                      )
                                  )
                                )
                                .map((line) => (
                                  <div>
                                    {line
                                      .filter(
                                        (field) =>
                                          !_.isEmpty(
                                            _.get(
                                              fetchedPdi,
                                              `general.address.${field}`,
                                              ""
                                            ) || ""
                                          )
                                      )
                                      .map((field) => (
                                        <span style={{ marginRight: "5px" }}>
                                          {_.get(
                                            fetchedPdi,
                                            `general.address.${field}`
                                          )}
                                        </span>
                                      ))}
                                  </div>
                                ))}
                            </div>
                          ) : (
                            <NoFilled />
                          )}
                        </span>
                      </p>
                      {_.get(fetchedPdi, "general.subscriber.firstname") && (
                        <p className="marginLine">
                          <span className="infoLibelle">
                            {t("all.text.subscriber")}
                          </span>{" "}
                          <br />
                          <span className="infoDisplay">
                            {_.get(fetchedPdi, "general.subscriber.firstname")}{" "}
                            {_.get(fetchedPdi, "general.subscriber.lastname")}
                          </span>
                        </p>
                      )}
                      {fetchedPdi.general.clpInformations && (
                        <p className="marginLine">
                          <span className="infoLibelle">
                            {t("columns.clpInformations")}
                          </span>{" "}
                          <br />
                          <span
                            className="infoDisplay"
                            style={{ whiteSpace: "pre" }}
                          >
                            {ListTools.formatInformationsClpMtr(
                              fetchedPdi.general.clpInformations
                            )}
                          </span>
                        </p>
                      )}

                      {/** Marquage */}
                      <p className="marginLine">
                        <div className="flex-box">
                          <span className="infoLibelle">
                            {t("all.text.marquage")}
                          </span>
                          {couldBeEdit() &&
                            _.get(tournee, `editInfos.marquages`, []) && (
                              <div
                                className="svg-box"
                                onClick={() =>
                                  this.setState({ edit: "marquage" })
                                }
                              >
                                <EditionBleu height="1em" fill="black" />
                              </div>
                            )}
                        </div>
                        {edit === "marquage" ? (
                          <div className="flex-box">
                            <StyledSelect
                              optionList={_.get(
                                tournee,
                                `editInfos.marquages`,
                                []
                              ).map(({ code, label }) => ({
                                label,
                                value: code,
                              }))}
                              placeholder="Marquage"
                              handleChange={(e) =>
                                this.setState({ editableFieldValue: e })
                              }
                              value={
                                editableFieldValue ||
                                _.get(tournee, "editInfos.marquages", []).find(
                                  ({ label }) =>
                                    label ===
                                    _.get(fetchedPdi, "general.marquage")
                                ) ||
                                {}
                              }
                            />
                            <div className="button-group">
                              <div
                                className="sticky-button"
                                onClick={() =>
                                  this.setState({
                                    editableFieldValue: null,
                                    edit: null,
                                  })
                                }
                              >
                                <span>{t("all.button.cancel")}</span>
                                <SvgCroixNoir height="1.7em" />
                              </div>
                              <div
                                className="sticky-button"
                                onClick={this.validChange}
                              >
                                <span>{t("all.button.register")}</span>
                                <SvgValidation height="1em" />
                              </div>
                            </div>
                          </div>
                        ) : (
                          fetchedPdi.general.marquage && (
                            <span className="infoDisplay">
                              {fetchedPdi.general.marquage}
                            </span>
                          )
                        )}
                      </p>

                      {/** Note */}
                      <p className="marginLine">
                        <div className="flex-box">
                          <span className="infoLibelle">
                            {t("all.text.note")}
                          </span>
                          {couldBeEdit() && (
                            <div
                              className="svg-box"
                              onClick={() =>
                                this.setState({
                                  edit: "note",
                                  editableFieldValue: fetchedPdi.general.note,
                                })
                              }
                            >
                              <EditionBleu height="1em" fill="black" />
                            </div>
                          )}
                        </div>
                        {edit === "note" ? (
                          <div className="flex-box">
                            <StyledTextArea
                              maxlength={500}
                              value={editableFieldValue}
                              handleChange={(e: any) =>
                                this.setState({
                                  editableFieldValue: _.get(e, "target.value"),
                                })
                              }
                            />
                            <div className="button-group">
                              <div
                                className="sticky-button"
                                onClick={() =>
                                  this.setState({
                                    editableFieldValue: null,
                                    edit: null,
                                  })
                                }
                              >
                                <span>{t("all.button.cancel")}</span>
                                <SvgCroixNoir height="1.7em" />
                              </div>
                              <div
                                className="sticky-button"
                                onClick={this.validChange}
                              >
                                <span>{t("all.button.register")}</span>
                                <SvgValidation height="1em" />
                              </div>
                            </div>
                          </div>
                        ) : (
                          fetchedPdi.general.note && (
                            <span
                              className="infoDisplay"
                              style={{ whiteSpace: "pre-wrap" }}
                            >
                              {fetchedPdi.general.note}
                            </span>
                          )
                        )}
                      </p>

                      {fetchedPdi.general.parentReference && (
                        <>
                          {/** Référence parent */}
                          <p className="marginLine">
                            <div className="flex-box">
                              <span className="infoLibelle">
                                {t("pdi.PDI_PARENT_REFERENCE")}
                              </span>
                            </div>
                            {fetchedPdi.general.parentReference && (
                              <span className="infoDisplay">
                                {fetchedPdi.general.parentReference}
                              </span>
                            )}
                            {fetchedPdi.general.parentId && (
                              <ArrowFicheButton
                                id="addDashboard"
                                url={`/locations/${match.params.locationId}/pdi/${fetchedPdi.general.parentId}`}
                                tooltipText={t("all.radio.go_to_radio")}
                              />
                            )}
                          </p>

                          {/** Référence parent */}
                          <p className="marginLine">
                            <div className="flex-box">
                              <span className="infoLibelle">
                                {t("pdi.PDI_PARENT_RELATION")}
                              </span>
                            </div>
                            {fetchedPdi.general.parentRelation && (
                              <span className="infoDisplay">
                                {fetchedPdi.general.parentRelation}
                              </span>
                            )}
                          </p>
                        </>
                      )}

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "end",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            width: "fit-content",
                            color: "lightgrey",
                            margin: "0 10px",
                          }}
                        >
                          {fetchedPdi.general.updateDate ? (
                            <p style={{ margin: 0 }}>
                              <span>{t("all.date.updated_on")}</span>
                              <span
                                style={{ fontWeight: "bolder", fontSize: 14 }}
                              >
                                {format(
                                  fetchedPdi.general.updateDate,
                                  "dd/MM/yyyy à HH:mm"
                                )}
                              </span>
                            </p>
                          ) : (
                            <span>Aucune date de mise à jour</span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="map-pdi">
              <div
                style={{ backgroundColor: "lightgrey" }}
                className="map-container"
              >
                {_.get(pdis, "fetchedPdi.general.gpsPosition") ? (
                  /* <GoogleMapReactC
                    initialCenter={{
                      lat: _.get(gps, 'pdi.lat', _.get(gps, 'location.lat')),
                      lng: _.get(gps, 'pdi.lng', _.get(gps, 'location.lng')),
                    }}
                    zoom={16}
                    centerMarker={{
                      lat: _.get(gps, 'pdi.lat'),
                      lng: _.get(gps, 'pdi.lng'),
                      icon: 'home_marker',
                    }}
                    yesIWantToUseGoogleMapApiInternals
                  /> */

                  <Mapbox
                    initialMap={{
                      baseLng: _.get(
                        gps,
                        "pdi.lng",
                        _.get(gps, "location.lng")
                      ),
                      baseLat: _.get(
                        gps,
                        "pdi.lat",
                        _.get(gps, "location.lat")
                      ),
                      baseZoom: 16,
                    }}
                    style={{ width: "100%", height: "100%" }}
                    homeMarker
                  />
                ) : (
                  <div
                    style={{ margin: "auto", backgroundColor: "lightgrey" }}
                    className="add-coordinates-container"
                  >
                    {pdis.gpsLoading && (
                      <LoadingBand message={t("all.pdi.loading_location")} />
                    )}
                    {pdis.error && <ErrorBand message={pdis.error} />}
                    {!pdis.gpsLoading && (
                      <div className="flex-box">
                        <h2>{t("all.pdi.add_location")}</h2>
                        <div
                          className="ajout-gps"
                          onClick={this.addGpsPosition}
                        >
                          <SvgAjoutBleu height="3em" />
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  dateDecroissant() {
    const { meters, pdis } = this.props;
    const { activeTab } = this.state;
    let listFinal = [];
    if (meters && meters.allMetersInfo) {
      const listDate = meters.allMetersInfo.map((meter: any) => ({
        id: meter.general.id,
        date: _.get(meter, "general.endDate"),
        serial: _.get(meter, "general.serial"),
      }));
      listFinal = listDate.sort((a: any, b: any) => {
        const date1 = a.date ? new Date(a.date) : new Date();
        const date2 = b.date ? new Date(b.date) : new Date();
        return date2.valueOf() - date1.valueOf();
      });
    }
    if (
      listFinal.length > 0 &&
      listFinal.length ===
        _.values(pdis && pdis.fetchedPdi && pdis.fetchedPdi.meterList).length &&
      activeTab === null
    ) {
      this.setState({
        activeTab: listFinal[0].id,
      });
    }
    return listFinal;
  }

  /**
   * Construit le bloc des compteurs, avec la gestion
   * des onglets
   *
   * @method generateMeters
   * @memberof SourceSheetPDIComponent
   * @returns {JSX} le bloc des compteurs
   */
  generateMeters = () => {
    const { pdis, locations, meters, t } = this.props;
    const { activeTab } = this.state;
    const displayList: any = this.dateDecroissant();
    const oldMeters: any = displayList
      .filter((el: any, index: any) => index !== 0)
      .map((el: any) => ({ id: el.id, serial: el.serial }));
    return (
      <div className="col-md-12" style={{ padding: "0" }}>
        {pdis.error && <ErrorBand message={pdis.error} />}
        {pdis.fetchedPdi &&
          pdis.fetchedPdi.meterList &&
          locations.fetchedLocation && (
            <div className="table-info-container generateMeter-pdi">
              {meters && meters.loading && (
                <LoadingBand
                  message={t("source_sheet_pdi.text.meter_loading")}
                />
              )}

              <div className="flex-box">
                <h2
                  style={{
                    marginTop: "0.5rem",
                    paddingTop: "5px",
                    display: meters && meters.loading ? "none" : "block",
                  }}
                >
                  <span>
                    <NbCompteurVert height="1em" width="1em" fill="#31c6b3" />
                  </span>
                </h2>
                <Nav tabs style={{ borderBottom: 0 }}>
                  {displayList.map((meter: any, index: number) => (
                    <div>
                      {index < 2 && (
                        <NavItem
                          key={meter.id}
                          style={{
                            boxShadow:
                              meter.id === activeTab
                                ? "5px 1px 5px 0px #31c6b3"
                                : null,
                            borderRadius: "5px",
                          }}
                        >
                          <NavLink
                            className={classnames({
                              active: activeTab === meter.id,
                            })}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              this.toggleMeter(meter.id);
                            }}
                          >
                            <h3
                              style={{ marginTop: "0.5rem", color: "#31c6b3" }}
                            >
                              {index === 0
                                ? t("historique_compteur.text.actual_meter")
                                : t("fiche_detail.title.old_meter_plural")}
                            </h3>
                          </NavLink>
                        </NavItem>
                      )}
                    </div>
                  ))}
                </Nav>
              </div>
              <TabContent activeTab={activeTab}>
                {_.values(
                  pdis && pdis.fetchedPdi && pdis.fetchedPdi.meterList
                ).map((meter) => (
                  <TabPane tabId={meter.id} key={meter.id}>
                    {activeTab === meter.id && (
                      <Card
                        style={{
                          borderTop: "none",
                          backgroundColor: "#ebecf1",
                          paddingTop: "15px",
                        }}
                      >
                        {displayList &&
                          displayList.length > 0 &&
                          displayList[0].id === meter.id &&
                          this.loadMeter(
                            meter.id,
                            meter.serial,
                            locations.fetchedLocation.id,
                            pdis
                          )}
                        {displayList &&
                          displayList.length > 0 &&
                          displayList[0].id !== meter.id &&
                          this.loadOldMeters(
                            oldMeters,
                            locations.fetchedLocation.id
                          )}
                      </Card>
                    )}
                  </TabPane>
                ))}
              </TabContent>
            </div>
          )}
      </div>
    );
  };

  /**
   * Change le compteur actif
   *
   * @method toggleMeter
   * @memberof SourceSheetPDIComponent
   */
  toggleMeter = (tab: any) => {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  /**
   * Charge la fiche du compteur dont l'id est passé
   * en paramètre
   *
   * @method loadMeter
   * @memberof SourceSheetPDIComponent
   * @param {String} id Identifiant du compteur
   * @param {String} serial Numéro de série du compteur
   * @param {Integer} locationId Identifiant du site
   * @returns {JSX} la fiche compteur
   */

  loadMeter = (id: any, serial: any, locationId: number, pdis: any) => {
    const { alarm, template, consumption, lastPertinentRead } = this.state;

    return (
      <SourceSheetMeter
        sourcesheetContext="pdi"
        meterId={id}
        key={id + serial}
        locationId={locationId}
        pdiAlarm={alarm}
        pdiTemplate={template}
        pdiConsumption={consumption}
        withoutDateComponent
        lastPertinentRead={lastPertinentRead}
        fullPdi={pdis}
      />
    );
  };

  getArrowCons(tendency: number) {
    switch (true) {
      case tendency > 50:
        return <StrongCons width="100%" fill="#31c6b3" height="100%" />;
      case tendency > 25:
        return <SmallStrongCons width="100%" fill="#31c6b3" height="100%" />;
      case tendency < -25:
        return <SmallWeakCons width="100%" fill="#31c6b3" height="100%" />;
      case tendency < -50:
        return <WeakCons width="100%" fill="#31c6b3" height="100%" />;
      default:
        return <NormalConso width="100%" fill="#31c6b3" height="100%" />;
    }
  }

  loadOldMeters = (meters: any, locationId: number) => {
    const { alarm, template, consumption, oldMeterActive } = this.state;
    const meterBar = this.generateMeterBar(meters);
    if (oldMeterActive === null) {
      this.setState({
        oldMeterActive: meters[0],
      });
    }
    return (
      <div className="old-meter-displayer">
        {meters && meters.length > 1 && meterBar}
        {oldMeterActive && (
          <SourceSheetMeter
            sourcesheetContext="pdi"
            meterId={oldMeterActive.id}
            key={oldMeterActive.id + oldMeterActive.serial}
            locationId={locationId}
            pdiAlarm={alarm}
            pdiTemplate={template}
            pdiConsumption={consumption}
            withoutDateComponent
          />
        )}
      </div>
    );
  };

  updateLastRead(lastRead: any) {
    this.setState({
      activeTab: lastRead.id,
    });
  }

  handleChange(value: any, type: string, refresh = true) {
    const {
      template,
      alarm,
      consumption,
      lastRead,
      dateMin,
      dateMax,
      usedStartDate,
      usedEndDate,
    } = this.state;
    const { dispatch, meters, match, pdis, locations } = this.props;
    const { locationId } = match.params;
    let newValue = "";
    let dMin: any = new Date(usedStartDate);
    let dMax: any = new Date(usedEndDate);

    switch (type) {
      case "dateMin":
        newValue = `${value} 00:00:00`;
        dMin = new Date(newValue);
        template.dataSourceProperty.displayProperty.condition[1].conditionValue =
          newValue;
        consumption.dataSourceProperty.displayProperty.condition[1].conditionValue =
          newValue;
        alarm.dataSourceProperty.displayProperty.condition[1].conditionValue =
          newValue;
        this.setState({
          template,
          alarm,
          consumption,
        });
        break;
      case "dateMax":
        newValue = `${value} 23:59:59`;
        dMax = new Date(newValue);
        template.dataSourceProperty.displayProperty.condition[2].conditionValue =
          newValue;
        consumption.dataSourceProperty.displayProperty.condition[2].conditionValue =
          newValue;
        alarm.dataSourceProperty.displayProperty.condition[2].conditionValue =
          newValue;
        this.setState({
          template,
          alarm,
          consumption,
        });
        break;
      default:
        template.dataSourceProperty.displayProperty.displayZoom = value;
        alarm.dataSourceProperty.displayProperty.displayZoom = value;
        consumption.dataSourceProperty.displayProperty.displayZoom = value;
        this.setState({
          template,
          alarm,
          consumption,
        });
    }
  }

  handleChangeAlarm(alarmTemplate: any) {
    const { lastRead } = this.state;
    const { dispatch, locations, match } = this.props;
    const content = {
      dateMin: moment(
        alarmTemplate.dataSourceProperty.displayProperty.condition[1]
          .conditionValue
      ),
      dateMax: moment(
        alarmTemplate.dataSourceProperty.displayProperty.condition[2]
          .conditionValue
      ),
      zoom: alarmTemplate.dataSourceProperty.displayProperty.displayZoom.toUpperCase(),
    };

    if (locations.fetchedLocation && lastRead) {
      dispatch(
        meterActions.getAllMetersFromPdi(
          match.params.pdiId,
          locations.fetchedLocation.code,
          content
        )
      );
    }
    this.setState({
      alarm: alarmTemplate,
    });
  }

  onSelect(key: Element, side: boolean, elementWidth: number) {
    if (side) {
      key.scrollTo({
        top: 0,
        left: key.scrollLeft + elementWidth,
        behavior: "smooth",
      });
    } else {
      key.scrollTo({
        top: 0,
        left: key.scrollLeft - elementWidth,
        behavior: "smooth",
      });
    }
  }

  generateMeterBar = (meters: any) => {
    const { isOver, oldMeterActive } = this.state;

    const arrow = {
      left: "<",
      right: ">",
    };
    const doc = document.getElementById("pdi-button-group");
    const dimension = doc ? doc.getBoundingClientRect() : undefined;
    return (
      <div className="pdi-old-meter">
        <div className="flex-box">
          <h3
            onClick={() => this.onSelect(doc, false, dimension.width / 2)}
            style={{
              visibility: doc
                ? doc.scrollLeft > dimension.width / 4
                  ? "visible"
                  : "hidden"
                : "hidden",
            }}
          >
            {arrow.left}
          </h3>
          <ButtonGroup id="pdi-button-group">
            {meters.map((el: any, index: any) => {
              return (
                <Button
                  id={el.serial}
                  color="primary"
                  onMouseLeave={() => this.setState({ isOver: "" })}
                  onMouseEnter={() => this.setState({ isOver: el.serial })}
                  onClick={() => this.setState({ oldMeterActive: el })}
                  style={{
                    borderRight:
                      index !== meters.length - 1
                        ? "2px solid #34827a"
                        : "1px solid #31c6b3",
                    backgroundColor:
                      oldMeterActive && oldMeterActive.serial === el.serial
                        ? "#31c6b3"
                        : "white",
                    color:
                      isOver === el.serial
                        ? oldMeterActive && oldMeterActive.serial === el.serial
                          ? "white"
                          : "#31c6b3"
                        : "black",
                    borderRadius:
                      index === 0
                        ? "10px 0 0 10px"
                        : index === meters.length - 1
                        ? "0 10px 10px 0"
                        : "none",
                    minWidth: dimension ? `${dimension.width / 4}px` : "100px",
                  }}
                >
                  <h4>{el.serial}</h4>
                </Button>
              );
            })}
          </ButtonGroup>
          <h3
            onClick={() => this.onSelect(doc, true, dimension.width / 2)}
            style={{
              visibility: doc
                ? doc.scrollLeft > dimension.width - dimension.width / 4
                  ? "hidden"
                  : "visible"
                : "hidden",
            }}
          >
            {arrow.right}
          </h3>
        </div>
        <br />
      </div>
    );
  };

  showModal = () => {
    const { isOpen } = this.state;

    this.setState({
      isOpen: !isOpen,
      maskName: "",
    });
  };

  handleChart(chartTemplates: any, dataList: any) {
    const { locales, pdis, meters } = this.props;
    if (chartTemplates.length > 0) {
      const alarmType = _.uniq(
        chartTemplates
          .filter(
            (template: any) =>
              template._chart.config.data.datasets[template._datasetIndex].data[
                template._index
              ] === 1
          )
          .map(
            (template: any) =>
              reverseAlarm(template._model.datasetLabel, locales.locale).split(
                "."
              )[0]
          )
      );
      // const alarmType = _.uniq(chartTemplates.map((template: any) => reverseAlarm(template._model.datasetLabel, locales.locale).split('.')[0]));
      this.setState({
        isOpen: true,
        alarmType,
        alarmTimeType: alarmType,
        maskName: `${_.values(dataList.values)
          .map((it) => it.label.split(" ")[0])
          .join()}_${meters.allMetersInfo[0].general.serial}_${
          pdis.fetchedPdi.general.id
        }`,
        dateMin: dataList.labels[chartTemplates[0]._index],
        dateMax: moment
          .utc(dataList.labels[chartTemplates[0]._index])
          .endOf("day")
          .add(-1, "second")
          .toISOString(),
      });
    }
  }

  handleTypeAlarm(type: string) {
    const { alarmTimeType } = this.state;
    let newList = _.cloneDeep(alarmTimeType);
    if (newList.find((el) => el === type)) {
      newList = newList.filter((el) => el !== type);
    } else {
      newList.push(type);
    }
    this.setState({ alarmTimeType: newList });
  }

  handleDate(dateMin: any, dateMax: any) {
    this.setState({
      dateMin,
      dateMax,
    });
  }

  handleZoom(zoom: string) {
    this.setState({
      zoom,
    });
  }

  onSubmit = () => {
    const { dispatch, pdis, match, locales, locations } = this.props;
    const { maskName, alarmType, dateMin, dateMax, alarmTimeType, template } =
      this.state;
    if (maskName.length > 0) {
      const tradAlarm: any = alarmTimeType.map((alarmName: any) => alarmName);
      const content = {
        dateMin: moment(
          template.dataSourceProperty.displayProperty.condition[1]
            .conditionValue
        ),
        dateMax: moment(
          template.dataSourceProperty.displayProperty.condition[2]
            .conditionValue
        )
          .endOf("day")
          .add(-1, "second"),
        zoom: template.dataSourceProperty.displayProperty.displayZoom.toUpperCase(),
      };
      dispatch(
        maskActions.createMaskFromChart(
          maskName,
          dateMin,
          dateMax,
          [parseInt(match.params.pdiId)],
          null,
          tradAlarm,
          locations.fetchedLocation.code,
          "select",
          content
        )
      );
    }
    this.setState({
      isOpen: false,
      maskName: "",
      alarmTimeType: [],
    });
  };

  handleScroll = (event: any) => {
    const { offSetFixed } = this.state;
    const doc: any = document.getElementById("fixed-date-component");
    const conso: any = document.getElementById("fixed-date-component-conso");
    if (doc) {
      const info = doc.getBoundingClientRect();
      if (info.y <= 90 && offSetFixed === -1) {
        doc.style.top = "90px";
        doc.style.position = "fixed";
        doc.style.marginLeft = "-10px";
        doc.style.width = `calc(100% - 260px)`;
        doc.style.borderRadius = "0";
        this.setState({
          offSetFixed:
            (event.path && event.path[1].pageYOffset) || window.scrollY,
        });
      } else if (offSetFixed !== -1) {
        if (
          ((event.path && event.path[1].pageYOffset) || window.scrollY) <=
          offSetFixed
        ) {
          doc.style.position = "initial";
          doc.style.width = "100%";
          doc.style.borderRadius = "10px";
          doc.style.marginLeft = "0";
          this.setState({
            offSetFixed: -1,
          });
          if (conso) conso.style.display = "flex";
        } else {
          doc.style.position = "fixed";
          doc.style.width = `calc(100% - 260px)`;
          doc.style.borderRadius = "0";
          doc.style.marginLeft = "-10px";
          doc.style.top = "90px";
          if (conso) conso.style.display = "none";
        }
      }
    }
  };

  getReadTypeIcon(
    type: string,
    iconSize: number = 30,
    color: string = "#2E3643"
  ) {
    switch (true) {
      case type === "T":
        return <Telereleve fill={color} width={`${iconSize || 40}px`} />;
      case type === "A":
        return <ReleveRadio fill={color} width={`${iconSize || 40}px`} />;
      case type === "M":
        return <ReleveManuelle fill={color} width={`${iconSize || 40}px`} />;
      default:
        return <NoFilled />;
    }
  }

  render() {
    const pdiInfos = this.generatePDIInfos();
    const meters = this.generateMeters();
    const {
      pdis,
      t,
      locales,
      locations,
      meters: metersProps,
      tournee,
    } = this.props;
    const {
      template,
      alarm,
      isOpen,
      alarmType,
      dateMin,
      dateMax,
      zoom,
      alarmTimeType,
      offSetFixed,
      maskName,
      activeTab,
      lastPertinentRead,
      usedStartDate,
      usedEndDate,
      displayTelereleve,
      displayTerrain,
    } = this.state;
    const currentMeter =
      metersProps.allMetersInfo &&
      metersProps.allMetersInfo.find(
        (el: any) => el.general.endDate === null && el.lastRead !== null
      );
    let nbDec = 0;
    const pulseWeight = currentMeter && currentMeter.general.model.pulseWeight;
    if (pulseWeight && pulseWeight < 1) {
      nbDec = pulseWeight.toString().split(".")[1].length;
    }
    const readsChronoOrdered = _.orderBy(
      _.defaultTo(_.get(currentMeter, "reads"), []),
      "date",
      "asc"
    );
    const oldestReading = _.first(readsChronoOrdered);
    const mostRecentReading = _.last(readsChronoOrdered);

    const daysBetween =
      getDaysInInterval(
        _.get(oldestReading, "date"),
        _.get(mostRecentReading, "date")
      ) - 1;
    const tendency = _.get(tournee, "fetchedFiche.read.tendency");
    const isIntervalInvalid: boolean =
      moment(usedStartDate).isAfter(usedEndDate);
    metersProps.allMetersInfo &&
      metersProps.allMetersInfo.find((el: any) => el.general.id === activeTab);

    const meterList =
      metersProps &&
      metersProps.allMetersInfo &&
      metersProps.allMetersInfo.map((meter) => ({
        label: meter.general.serial,
        datas: meter.consumption
          .filter(
            (el) =>
              new Date(meter.general.startDate).getTime() <
              new Date(el.date).getTime()
          )
          .map((el) => {
            if (
              meter.releveTerrains.find(
                (ter) =>
                  new Date(ter.currentDate).getTime() ===
                  new Date(`${el.date}.000+0000`).getTime()
              )
            ) {
              return {
                value: el.consumption,
                land: true,
              };
            }
            return el.consumption;
          })
          .filter((el) => {
            if (displayTelereleve && displayTerrain) return true;
            if (!displayTelereleve && !displayTerrain) return false;
            if (!displayTelereleve && el.land) return true;
            if (!displayTerrain && !el.land) return true;
            return false;
          }),
      }));
    const labels =
      metersProps &&
      metersProps.allMetersInfo &&
      metersProps.allMetersInfo
        .map((meter) =>
          meter.consumption
            .filter(
              (el) =>
                new Date(meter.general.startDate).getTime() <
                new Date(el.date).getTime()
            )
            .map((el) => `${el.date}.000+0000`)
            .filter((el) => {
              const isLand = meter.releveTerrains.find(
                (rel) =>
                  new Date(rel.currentDate).getTime() === new Date(el).getTime()
              );
              if (displayTelereleve && displayTerrain) return true;
              if (!displayTelereleve && !displayTerrain) return false;
              if (!displayTelereleve && displayTerrain && isLand) return true;
              if (!displayTerrain && displayTelereleve && !isLand) return true;
              return false;
            })
        )
        .flat();

    const alarms =
      metersProps &&
      metersProps.allMetersInfo &&
      metersProps.allMetersInfo
        .map((meter) => {
          return meter.alarms.data
            .map((el) =>
              Object.keys(el.types).map((alarm) => {
                const start = labels.findIndex(
                  (date) =>
                    new Date(date).getTime() ===
                    new Date(`${el.date}.000+0000`).getTime()
                );
                let indexOfEnd = start;
                if (start >= 0) {
                  while (
                    meter.alarms.data.find(
                      (data) =>
                        new Date(`${data.date}.000+0000`).getTime() ===
                          new Date(labels[indexOfEnd + 1]).getTime() &&
                        Object.keys(data.types).includes(alarm)
                    )
                  ) {
                    indexOfEnd++;
                  }
                }
                if (start !== indexOfEnd) {
                  return {
                    type: alarm,
                    data: { start, end: indexOfEnd },
                  };
                }
                return {
                  type: alarm,
                  data: start,
                };
              })
            )
            .flat();
        })
        .flat();

    const alarmList =
      metersProps &&
      metersProps.allMetersInfo &&
      _.uniq(
        metersProps.allMetersInfo
          .map((meter) =>
            meter.alarms.data.map((el) => Object.keys(el.types)).flat()
          )
          .flat()
      )
        .sort()
        .map((el) => {
          const newList: any = [];
          alarms.forEach((alarm) => {
            if (
              alarm.type === el &&
              (alarm.data.start ||
                !alarms
                  .filter((el) => el.data.start >= 0)
                  .find(
                    (a) =>
                      a.data.start <= alarm.data &&
                      a.data.end >= alarm.data &&
                      a.type === alarm.type
                  ))
            ) {
              newList.push(alarm.data);
            }
          });
          return {
            type: el,
            data: newList,
          };
        });

    const getConsumption = (meter: any, previousMeter: any) => {
      const wheels =
        _.get(currentMeter, "general.revisedWheels") ||
        _.get(currentMeter, "general.model.wheels");
      const index = _.get(meter, "meterIndex");
      const previousIndex = _.get(previousMeter, "meterIndex");
      return index >= previousIndex
        ? index - previousIndex
        : index - previousIndex + Math.pow(10, wheels);
    };

    return (
      <div className="fichePdi container-fluid">
        {pdis.loading && (
          <LoadingBand message="Chargement des informations du PDI" />
        )}
        {pdis.error && <ErrorBand message={pdis.error} />}
        {pdis && pdis.fetchedPdi && (
          <Fragment>
            <Modal isOpen={isOpen} toggle={this.showModal} size="lg">
              <ModalHeader toggle={this.showModal}>
                {t("source_sheet_pdi.text.alarm_mask")}:
              </ModalHeader>
              <ModalBody>
                <FormGroup>
                  <Label>{t("all.mask.mask_name")}</Label>
                  <Input
                    type="text"
                    name="email"
                    placeholder={t("all.mask.mask_name")}
                    defaultValue={maskName}
                    onChange={(e) =>
                      this.setState({ maskName: e.target.value })
                    }
                  />
                </FormGroup>
                {locales.locale && (
                  <AlarmRadioButton
                    handleChange={this.handleTypeAlarm}
                    typeSelected={alarmTimeType}
                    alarmType={alarmType}
                    locale={locales.locale}
                    t={t}
                  />
                )}
                <div className="modal-mask-alarm">
                  <BestDateComponent
                    handleZoom={this.handleZoom}
                    zoom={zoom}
                    noZoom
                    dateMin={dateMin}
                    dateMax={dateMax}
                    handleDate={this.handleDate}
                  />
                </div>
                <div className="button-submit-date">
                  <Button color="primary" onClick={this.onSubmit}>
                    {t("all.button.validate")}
                  </Button>{" "}
                  <Button color="danger" onClick={this.showModal}>
                    {t("all.button.cancel")}
                  </Button>{" "}
                </div>
              </ModalBody>
            </Modal>
            {pdiInfos}
            {locations.fetchedLocation && (
              <>
                {pdis.fetchedPdi && <TeleReleveInfo type="PDI" />}
                {pdis.fetchedPdi && <TourneeBand />}
                {pdis.fetchedPdi && (
                  <LastReading
                    pdi={pdis.fetchedPdi}
                    updateLastRead={this.updateLastRead}
                    lastPertinentRead={lastPertinentRead}
                  />
                )}
                {offSetFixed > 0 && (
                  <div className="second-date-component" style={{ padding: 0 }}>
                    <div className="flex-box">
                      <div className="title-date">
                        <div className="svg-container">
                          <SvgDoigtVert height="2em" fill="#31c6b3" />
                        </div>
                        <h3>{t("source_sheet_pdi.text.display_interval")}</h3>
                      </div>
                      <div style={{ width: "100%" }}>
                        <div>
                          <div className="conso-display">
                            <div
                              className={`previous ${
                                _.get(oldestReading, "source") === "T" ||
                                _.get(oldestReading, "source") === "A"
                                  ? "telereleve-radio"
                                  : _.get(oldestReading, "source") === "M"
                                  ? "manuelle"
                                  : "unknown-method"
                              }`}
                            >
                              {oldestReading ? (
                                <div className="horizontal-line">
                                  <div>
                                    <span>
                                      {t("fiche_detail.title.start_index")}{" "}
                                      {_.get(
                                        currentMeter,
                                        "general.fluidType"
                                      ) && (
                                        <span
                                          dangerouslySetInnerHTML={{
                                            __html: t(
                                              `meter.unit.${_.get(
                                                currentMeter,
                                                "general.fluidType"
                                              )}`,
                                              {
                                                interpolation: {
                                                  escapeValue: false,
                                                },
                                              }
                                            ),
                                          }}
                                        />
                                      )}{" "}
                                    </span>
                                    <h3>
                                      {Number.isFinite(
                                        _.get(oldestReading, "meterIndex")
                                      ) ? (
                                        _.get(oldestReading, "meterIndex")
                                      ) : (
                                        <NoFilled />
                                      )}
                                    </h3>
                                    <span>
                                      {_.get(oldestReading, "date") &&
                                        `(${moment(
                                          _.get(oldestReading, "date")
                                        ).format(
                                          t("all.date_format.date_and_time")
                                        )})`}
                                    </span>
                                  </div>
                                  {_.get(oldestReading, "source") &&
                                    this.getReadTypeIcon(
                                      _.get(oldestReading, "source"),
                                      20
                                    )}
                                </div>
                              ) : (
                                <div
                                  className="noFilled"
                                  style={{ textAlign: "center" }}
                                >
                                  {t("last_reading.text.no_previous_read")}
                                </div>
                              )}
                            </div>
                            <div className="average">
                              <div
                                id="triangle"
                                className={`start-arrow ${
                                  _.get(oldestReading, "source") === "T" ||
                                  _.get(oldestReading, "source") === "A"
                                    ? "telereleve-radio"
                                    : _.get(oldestReading, "source") === "M"
                                    ? "manuelle"
                                    : "unknown-method"
                                }`}
                              />
                              {_.get(oldestReading, "meterIndex") !==
                              _.get(mostRecentReading, "meterIndex") ? (
                                <div className="content">
                                  <div>
                                    <span>{t("all.read_meter.cons")}</span>
                                    <h3>
                                      {Number.isFinite(
                                        _.get(oldestReading, "meterIndex")
                                      ) &&
                                      Number.isFinite(
                                        _.get(mostRecentReading, "meterIndex")
                                      ) ? (
                                        (
                                          _.get(
                                            mostRecentReading,
                                            "meterIndex"
                                          ) - _.get(oldestReading, "meterIndex")
                                        ).toFixed(nbDec)
                                      ) : (
                                        <NoFilled />
                                      )}
                                    </h3>
                                  </div>
                                  <div>
                                    <span>{t("all.read_meter.tendency")}</span>
                                    {tendency !== undefined ? (
                                      this.getArrowCons(tendency)
                                    ) : (
                                      <NoFilled />
                                    )}
                                  </div>
                                  <div>
                                    <span>
                                      {t("all.read_meter.daily_avg_cons")}
                                    </span>
                                    <h3>
                                      {Number.isFinite(
                                        _.get(oldestReading, "meterIndex")
                                      ) &&
                                      Number.isFinite(
                                        _.get(mostRecentReading, "meterIndex")
                                      ) ? (
                                        (
                                          (_.get(
                                            mostRecentReading,
                                            "meterIndex"
                                          ) -
                                            _.get(
                                              oldestReading,
                                              "meterIndex"
                                            )) /
                                          daysBetween
                                        ).toFixed(nbDec)
                                      ) : (
                                        <NoFilled />
                                      )}
                                    </h3>
                                  </div>
                                </div>
                              ) : isIntervalInvalid ? (
                                <div className="noFilled">
                                  {t("source_sheet_pdi.text.invalid_interval")}
                                </div>
                              ) : (
                                <div className="noFilled">
                                  {t(
                                    "source_sheet_pdi.text.averages_unavailable"
                                  )}
                                </div>
                              )}
                              <div id="triangle" className="end-arrow" />
                            </div>
                            <div
                              className={`current ${
                                _.get(mostRecentReading, "source") === "T" ||
                                _.get(mostRecentReading, "source") === "A"
                                  ? "telereleve-radio"
                                  : _.get(mostRecentReading, "source") === "M"
                                  ? "manuelle"
                                  : "unknown-method"
                              }`}
                            >
                              {Number.isFinite(
                                _.get(mostRecentReading, "meterIndex")
                              ) && _.get(currentMeter, "reads").length > 1 ? (
                                <div className="horizontal-line">
                                  <div>
                                    <span>
                                      {t("fiche_detail.title.end_index")}{" "}
                                      {_.get(
                                        currentMeter,
                                        "general.fluidType"
                                      ) && (
                                        <span
                                          dangerouslySetInnerHTML={{
                                            __html: t(
                                              `meter.unit.${_.get(
                                                currentMeter,
                                                "general.fluidType"
                                              )}`,
                                              {
                                                interpolation: {
                                                  escapeValue: false,
                                                },
                                              }
                                            ),
                                          }}
                                        />
                                      )}
                                    </span>
                                    <h3>
                                      {Number.isFinite(
                                        _.get(mostRecentReading, "meterIndex")
                                      ) &&
                                      _.get(currentMeter, "reads").length >
                                        1 ? (
                                        _.get(mostRecentReading, "meterIndex")
                                      ) : (
                                        <NoFilled />
                                      )}
                                    </h3>
                                    <span>
                                      {_.get(mostRecentReading, "date") &&
                                        _.get(currentMeter, "reads").length >
                                          1 &&
                                        `(${moment(
                                          _.get(mostRecentReading, "date")
                                        ).format(
                                          t("all.date_format.date_and_time")
                                        )})`}
                                    </span>
                                  </div>
                                  {_.get(mostRecentReading, "source") &&
                                    _.get(mostRecentReading, "source") != "" &&
                                    _.get(oldestReading, "meterIndex") !==
                                      _.get(mostRecentReading, "meterIndex") &&
                                    this.getReadTypeIcon(
                                      _.get(mostRecentReading, "source"),
                                      20
                                    )}
                                </div>
                              ) : (
                                <div
                                  className="noFilled"
                                  style={{ textAlign: "center" }}
                                >
                                  {t("last_reading.text.no_last_read")}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div
                  className="second-date-component"
                  id="fixed-date-component"
                >
                  <div className="flex-box">
                    <div className="title-date">
                      <div className="svg-container">
                        <SvgDoigtVert height="2em" fill="#31c6b3" />
                      </div>
                      <h3>{t("source_sheet_pdi.text.display_interval")}</h3>
                    </div>
                    <div style={{ width: "80%" }}>
                      {template.dataSourceProperty.displayProperty.condition[1]
                        .conditionValue && (
                        <SecondDateComponent
                          dateMin={
                            template.dataSourceProperty.displayProperty
                              .condition[1].conditionValue
                          }
                          dateMax={
                            template.dataSourceProperty.displayProperty
                              .condition[2].conditionValue
                          }
                          zoom={
                            template.dataSourceProperty.displayProperty
                              .displayZoom
                          }
                          handleChange={this.handleChange}
                          getDates={this.getActualUsedDates}
                        />
                      )}
                      {offSetFixed < 0 && (
                        <div>
                          <div
                            className="conso-display"
                            id="fixed-date-component-conso"
                          >
                            <div
                              className={`previous ${
                                readsChronoOrdered.length === 1
                                  ? "blank-read"
                                  : _.get(oldestReading, "source") === "T" ||
                                    _.get(oldestReading, "source") === "A"
                                  ? "telereleve-radio"
                                  : _.get(oldestReading, "source") === "M"
                                  ? "manuelle"
                                  : "unknown-method"
                              }`}
                            >
                              {oldestReading &&
                              readsChronoOrdered.length > 1 ? (
                                <div className="horizontal-line">
                                  <div>
                                    <span>
                                      {t("fiche_detail.title.start_index")}{" "}
                                      {_.get(
                                        currentMeter,
                                        "general.fluidType"
                                      ) && (
                                        <span
                                          dangerouslySetInnerHTML={{
                                            __html: t(
                                              `meter.unit.${_.get(
                                                currentMeter,
                                                "general.fluidType"
                                              )}`,
                                              {
                                                interpolation: {
                                                  escapeValue: false,
                                                },
                                              }
                                            ),
                                          }}
                                        />
                                      )}{" "}
                                    </span>
                                    <h3>
                                      {Number.isFinite(
                                        _.get(oldestReading, "meterIndex")
                                      ) ? (
                                        _.get(oldestReading, "meterIndex")
                                      ) : (
                                        <NoFilled />
                                      )}
                                    </h3>
                                    <span>
                                      {_.get(oldestReading, "date") &&
                                        `(${moment(
                                          _.get(oldestReading, "date")
                                        ).format(
                                          t("all.date_format.date_and_time")
                                        )})`}
                                    </span>
                                  </div>
                                  {_.get(oldestReading, "source") &&
                                    this.getReadTypeIcon(
                                      _.get(oldestReading, "source"),
                                      20
                                    )}
                                </div>
                              ) : (
                                <div
                                  className="noFilled"
                                  style={{ textAlign: "center" }}
                                >
                                  {readsChronoOrdered.length == 1
                                    ? t("last_reading.text.no_other_read")
                                    : t("last_reading.text.no_previous_read")}
                                </div>
                              )}
                            </div>
                            <div
                              className={`average ${
                                readsChronoOrdered.length != 1
                                  ? "blank-read"
                                  : _.get(oldestReading, "source") === "T" ||
                                    _.get(oldestReading, "source") === "A"
                                  ? "telereleve-radio"
                                  : _.get(oldestReading, "source") === "M"
                                  ? "manuelle"
                                  : "unknown-method"
                              }`}
                            >
                              <div
                                id="triangle"
                                className={`start-arrow ${
                                  readsChronoOrdered.length === 1
                                    ? "blank-read"
                                    : _.get(oldestReading, "source") === "T" ||
                                      _.get(oldestReading, "source") === "A"
                                    ? "telereleve-radio"
                                    : _.get(oldestReading, "source") === "M"
                                    ? "manuelle"
                                    : "unknown-method"
                                }`}
                              />
                              {readsChronoOrdered &&
                              readsChronoOrdered.length === 1 ? (
                                <div
                                  className={`previous ${
                                    _.get(oldestReading, "source") === "T" ||
                                    _.get(oldestReading, "source") === "A"
                                      ? "telereleve-radio"
                                      : _.get(oldestReading, "source") === "M"
                                      ? "manuelle"
                                      : "unknown-method"
                                  }`}
                                  style={{ width: "100%" }}
                                >
                                  <div
                                    className="horizontal-line"
                                    style={{ alignItems: "center" }}
                                  >
                                    <div>
                                      <span>
                                        {t("all.meter.index")}{" "}
                                        {_.get(
                                          currentMeter,
                                          "general.fluidType"
                                        ) && (
                                          <span
                                            dangerouslySetInnerHTML={{
                                              __html: t(
                                                `meter.unit.${_.get(
                                                  currentMeter,
                                                  "general.fluidType"
                                                )}`,
                                                {
                                                  interpolation: {
                                                    escapeValue: false,
                                                  },
                                                }
                                              ),
                                            }}
                                          />
                                        )}{" "}
                                      </span>
                                      <h3>
                                        {Number.isFinite(
                                          _.get(oldestReading, "meterIndex")
                                        ) ? (
                                          _.get(oldestReading, "meterIndex")
                                        ) : (
                                          <NoFilled />
                                        )}
                                      </h3>
                                      <span>
                                        {_.get(oldestReading, "date") &&
                                          `(${moment(
                                            _.get(oldestReading, "date")
                                          ).format(
                                            t("all.date_format.date_and_time")
                                          )})`}
                                      </span>
                                    </div>
                                    {_.get(oldestReading, "source") &&
                                      this.getReadTypeIcon(
                                        _.get(oldestReading, "source"),
                                        20
                                      )}
                                  </div>
                                </div>
                              ) : _.get(currentMeter, "reads") &&
                                _.get(currentMeter, "reads").length > 1 &&
                                _.get(oldestReading, "date") !==
                                  _.get(mostRecentReading, "date") ? (
                                <div className="content">
                                  <div>
                                    <span>{t("all.read_meter.cons")}</span>
                                    <h3>
                                      {Number.isFinite(
                                        _.get(oldestReading, "meterIndex")
                                      ) &&
                                      Number.isFinite(
                                        _.get(mostRecentReading, "meterIndex")
                                      ) ? (
                                        getConsumption(
                                          mostRecentReading,
                                          oldestReading
                                        ).toFixed(nbDec)
                                      ) : (
                                        <NoFilled />
                                      )}
                                    </h3>
                                  </div>
                                  <div>
                                    <span>{t("all.read_meter.tendency")}</span>
                                    {tendency !== undefined ? (
                                      this.getArrowCons(tendency)
                                    ) : (
                                      <NoFilled />
                                    )}
                                  </div>
                                  <div>
                                    <span>
                                      {t("all.read_meter.daily_avg_cons")}
                                    </span>
                                    <h3>
                                      {Number.isFinite(
                                        _.get(oldestReading, "meterIndex")
                                      ) &&
                                      Number.isFinite(
                                        _.get(mostRecentReading, "meterIndex")
                                      ) ? (
                                        (
                                          getConsumption(
                                            mostRecentReading,
                                            oldestReading
                                          ) / daysBetween
                                        ).toFixed(nbDec)
                                      ) : (
                                        <NoFilled />
                                      )}
                                    </h3>
                                  </div>
                                </div>
                              ) : isIntervalInvalid ? (
                                <div className="noFilled">
                                  {t("source_sheet_pdi.text.invalid_interval")}
                                </div>
                              ) : (
                                <div className="noFilled">
                                  {t(
                                    "source_sheet_pdi.text.averages_unavailable"
                                  )}
                                </div>
                              )}
                              <div
                                id="triangle"
                                className={`end-arrow ${
                                  readsChronoOrdered.length != 1
                                    ? "blank-read"
                                    : _.get(oldestReading, "source") === "T" ||
                                      _.get(oldestReading, "source") === "A"
                                    ? "telereleve-radio"
                                    : _.get(oldestReading, "source") === "M"
                                    ? "manuelle"
                                    : "unknown-method"
                                }`}
                              />
                            </div>
                            <div
                              className={`current ${
                                readsChronoOrdered.length === 1
                                  ? "blank-read"
                                  : _.get(mostRecentReading, "source") ===
                                      "T" ||
                                    _.get(mostRecentReading, "source") === "A"
                                  ? "telereleve-radio"
                                  : _.get(mostRecentReading, "source") === "M"
                                  ? "manuelle"
                                  : "unknown-method"
                              }`}
                            >
                              {Number.isFinite(
                                _.get(mostRecentReading, "meterIndex")
                              ) && _.get(currentMeter, "reads").length > 1 ? (
                                <div className="horizontal-line">
                                  <div>
                                    <span>
                                      {t("fiche_detail.title.end_index")}{" "}
                                      {_.get(
                                        currentMeter,
                                        "general.fluidType"
                                      ) && (
                                        <span
                                          dangerouslySetInnerHTML={{
                                            __html: t(
                                              `meter.unit.${_.get(
                                                currentMeter,
                                                "general.fluidType"
                                              )}`,
                                              {
                                                interpolation: {
                                                  escapeValue: false,
                                                },
                                              }
                                            ),
                                          }}
                                        />
                                      )}
                                    </span>
                                    <h3>
                                      {Number.isFinite(
                                        _.get(mostRecentReading, "meterIndex")
                                      ) &&
                                      _.get(currentMeter, "reads").length >
                                        1 ? (
                                        _.get(mostRecentReading, "meterIndex")
                                      ) : (
                                        <NoFilled />
                                      )}
                                    </h3>
                                    <span>
                                      {_.get(mostRecentReading, "date") &&
                                        _.get(currentMeter, "reads").length >
                                          1 &&
                                        `(${moment(
                                          _.get(mostRecentReading, "date")
                                        ).format(
                                          t("all.date_format.date_and_time")
                                        )})`}
                                    </span>
                                  </div>
                                  {_.get(mostRecentReading, "source") &&
                                    _.get(oldestReading, "date") !==
                                      _.get(mostRecentReading, "date") &&
                                    this.getReadTypeIcon(
                                      _.get(mostRecentReading, "source"),
                                      20
                                    )}
                                </div>
                              ) : (
                                <div
                                  className="noFilled"
                                  style={{ textAlign: "center" }}
                                >
                                  {readsChronoOrdered.length == 1
                                    ? t("last_reading.text.no_other_read")
                                    : t("last_reading.text.no_last_read")}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {pdis?.fetchedPdi && <MaximoBT />}
                <div className="histo-chart-wrapper">
                  <div className="title flex-box">
                    <SvgCalendar height="2em" fill="#31c6b3" />
                    <h3>{t("all.read_meter.cons_history")}</h3>
                    <ButtonGroup>
                      <Button
                        style={{ marginLeft: "15px" }}
                        outline={!displayTelereleve}
                        onClick={() =>
                          this.setState({
                            displayTelereleve: !displayTelereleve,
                          })
                        }
                      >
                        {t("all.read_meter.type.T")}
                      </Button>
                      <Button
                        style={{ marginLeft: "1px" }}
                        outline={!displayTerrain}
                        onClick={() =>
                          this.setState({ displayTerrain: !displayTerrain })
                        }
                      >
                        {t("source_sheet_pdi.text.terrain")}
                      </Button>
                    </ButtonGroup>
                  </div>
                  {meterList &&
                  alarms &&
                  alarmList &&
                  template &&
                  labels.length > 0 ? (
                    <HistoChart
                      meterList={meterList}
                      labels={labels}
                      alarms={alarmList}
                      zoom={
                        template.dataSourceProperty.displayProperty.displayZoom
                      }
                    />
                  ) : (
                    <Alert color="warning">
                      {t("source_sheet_pdi.text.no_data_display")}
                    </Alert>
                  )}
                </div>
                <GaleryPhoto />
                {false && (
                  <Alarmes
                    alarm={alarm}
                    update={this.handleChangeAlarm}
                    handleChart={this.handleChart}
                  />
                )}
                {meters}
              </>
            )}
          </Fragment>
        )}
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const {
    authentication,
    pdis,
    locations,
    locales,
    meters,
    tournee,
    picture,
    interval,
    users,
  } = state;
  const { user } = authentication;

  return {
    alert,
    user,
    locations,
    locales,
    pdis,
    meters,
    tournee,
    picture,
    interval,
    users,
  };
}

const SourceSheetPDI = connect(mapStateToProps)(SourceSheetPDIComponent);
const tr = withTranslation()(SourceSheetPDI);
export default tr;
