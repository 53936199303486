import _ from "lodash";
import synchroConstants from "../_constants/synchro.constants";
import importConstants from "../_constants/import.constants";
import synchroComConstants from "../_constants/synchrocom.constants";

export default function synchro(state: any = {}, action: any) {
  switch (action.type) {
    case synchroComConstants.GETCOMMUNICATION_REQUEST:
      return {
        ...state,
      };
    case synchroComConstants.GETCOMMUNICATION_SUCCESS:
      if (state.devices) {
        action.currentState.forEach((el: any) => {
          if (state.devices[el.idPortable]) {
            _.merge(state.devices[el.idPortable], el);
          }
        });
      }
      return {
        ...state,
        currentStatus: action.currentState,
      };
    case synchroComConstants.GETCOMMUNICATION_FAILURE:
      return {
        ...state,
        error: "Error with current communication",
      };
    case synchroConstants.GETALLROUND_FULL_REQUEST:
      return {
        ...state,
      };
    case synchroConstants.GETALLROUND_FULL_SUCCESS:
      const mergeData = _.merge(
        _.cloneDeep(state.tasks) || {},
        action.rounds || {}
      );
      state.roundsUp = true;
      return {
        ...state,
        tourneeRessources: action.rounds,
        tasks: mergeData,
      };
    case synchroConstants.GETALLROUND_FULL_FAILURE:
      return {
        ...state,
        error: "Error get round",
      };

    case synchroConstants.GETALLDEVICES_REQUEST:
      state.loadingDevices = true;
      return {
        ...state,
      };
    case synchroConstants.GETALLDEVICES_SUCCESS:
      state.loadingDevices = false;
      const fusionDevices = _.merge(
        _.cloneDeep(state.tasks) || {},
        action.devices || {}
      );
      const newDevices = _.cloneDeep(action.devices);
      if (state.currentStatus) {
        state.currentStatus.forEach((el: any) => {
          if (newDevices[el.idPortable]) {
            _.merge(newDevices[el.idPortable], el);
          }
        });
      }
      return {
        ...state,
        devices: newDevices,
        devicesUp: true,
        tasks: fusionDevices,
      };

    case synchroConstants.GETALLDEVICES_FAILURE:
      return {
        error: "Error get devices",
      };

    case synchroConstants.UPDATENAME_REQUEST:
      return {
        ...state,
      };
    case synchroConstants.UPDATENAME_SUCCESS:
      const updateDevice: any = _.entries(action.device)[0];
      (
        _.entries(state.tasks).find((el) => el[0] === updateDevice[0]) as any
      )[1].content = updateDevice[1].content;
      return {
        ...state,
        tasks: state.tasks,
      };
    case synchroConstants.UPDATENAME_FAILURE:
      return {
        error: "Error update name",
      };

    case synchroConstants.GETALLMESSAGES_REQUEST:
      return {
        loading: true,
      };
    case synchroConstants.GETALLMESSAGES_SUCCESS:
      const messagesList = action.messages.filter((el) => el.code !== "  ");
      return {
        messages: messagesList,
      };
    case synchroConstants.GETALLMESSAGES_FAILURE:
      return {
        error: "Code d'erreur: 10011, serveur erreur",
      };
    case synchroConstants.GETALLMARQUAGES_REQUEST:
      return {
        loading: true,
      };
    case synchroConstants.GETALLMARQUAGES_SUCCESS:
      const marquagesList = action.marquages.filter((el) => el.code !== "  ");
      return {
        marquages: marquagesList,
      };
    case synchroConstants.GETALLMARQUAGES_FAILURE:
      return {
        error: "Code d'erreur: 10012, serveur erreur",
      };
    case synchroConstants.UPDATE_UNDROP_REQUEST:
      state.unDrop = [];
      return {
        ...state,
      };
    case synchroConstants.UPDATE_UNDROP_SUCCESS:
      state.unDrop = action.devicesId;
      return {
        ...state,
      };

    case synchroConstants.GET_FULLAFFECT_REQUEST:
      return {
        ...state,
      };
    case synchroConstants.GET_FULLAFFECT_SUCCESS:
      const newState = _.cloneDeep(state);
      _.entries(action.fullAffect).forEach((el: any) => {
        if (
          newState.tasks &&
          newState.tasks[el[0]] &&
          newState.tasks[el[0]].affectList
        ) {
          newState.tasks[el[0]].affectList = [];
          _.cloneDeep(el[1]).forEach((t) =>
            newState.tasks[el[0]].affectList.push(t)
          );
        }
      });
      return {
        ...newState,
      };
    case synchroConstants.GET_FULLAFFECT_FAILURE:
      return {
        error: "Code d'erreur: 10022, serveur erreur",
      };
    case synchroConstants.ADD_MESSAGES_REQUEST:
      return {
        ...state,
      };
    case synchroConstants.ADD_MESSAGES_SUCCESS:
      state.messages = state.messages.filter(
        (markS: any) =>
          !action.messages.find((markA: any) => markA.code === markS.code)
      );
      state.messages = _.concat(state.messages, action.messages);
      return {
        ...state,
      };
    case synchroConstants.ADD_MESSAGES_FAILURE:
      return {
        ...state,
        error: "Impossible d'ajouter le/les message(s)",
      };
    case synchroConstants.ADD_MARQUAGES_REQUEST:
      return {
        ...state,
      };
    case synchroConstants.ADD_MARQUAGES_SUCCESS:
      state.marquages = state.marquages.filter(
        (markS: any) =>
          !action.marquages.find((markA: any) => markA.code === markS.code)
      );
      state.marquages = _.concat(state.marquages, action.marquages);
      return {
        ...state,
      };
    case synchroConstants.ADD_MARQUAGES_FAILURE:
      return {
        ...state,
        error: "Impossible d'ajouter le/les marquage(s)",
      };
    case synchroConstants.GET_DEVICE_REQUEST:
      return {
        ...state,
      };
    case synchroConstants.GET_DEVICE_SUCCESS:
      return {
        device: action.serial,
        ...state,
      };
    case synchroConstants.GET_DEVICE_FAILURE:
      return {
        ...state,
        error: "Impossible de récupérer les informations du téléphone",
      };
    case synchroConstants.LOGIN_REQUEST:
      return {
        ...state,
      };
    case synchroConstants.LOGIN_SUCCESS:
      return {
        ...state,
      };
    case synchroConstants.LOGIN_FAILURE:
      return {
        ...state,
        error: "Impossible de s'autentifier",
      };
    case synchroConstants.LOGOUT_REQUEST:
      return {
        ...state,
      };
    case synchroConstants.LOGOUT_SUCCESS:
      return {
        ...state,
      };
    case synchroConstants.LOGOUT_FAILURE:
      return {
        error: "Problème de déconnexion",
        ...state,
      };
    case importConstants.IMPORT_MESSAGES_SUCCESS:
      const oldList = state.messages.filter((markS: any) =>
        action.data.find((markA: any) => markA.code === markS.code)
      );
      const newList = action.data.filter(
        (el: any) =>
          !state.messages.find((message: any) => el.code === message.code)
      );

      state.messages = state.messages.filter(
        (markS: any) =>
          !action.data.find((markA: any) => markA.code === markS.code)
      );

      state.messages = _.concat(state.messages, action.data).map((el: any) => {
        if (oldList.find((old: any) => old.code === el.code)) {
          el.old = true;
        }
        if (newList.find((newM: any) => newM.code === el.code)) {
          el.new = true;
        }
        return el;
      });

      return {
        ...state,
      };
    case importConstants.IMPORT_MESSAGES_FAILURE:
      return {
        error: action.error,
      };
    case importConstants.IMPORT_MARQUAGES_SUCCESS:
      const oldListM = state.marquages.filter((markS: any) =>
        action.data.find((markA: any) => markA.code === markS.code)
      );
      const newListM = action.data.filter(
        (el: any) =>
          !state.marquages.find((marquage: any) => el.code === marquage.code)
      );

      state.marquages = state.marquages.filter(
        (markS: any) =>
          !action.data.find((markA: any) => markA.code === markS.code)
      );

      state.marquages = _.concat(state.marquages, action.data).map(
        (el: any) => {
          if (oldListM.find((old: any) => old.code === el.code)) {
            el.old = true;
          }
          if (newListM.find((newM: any) => newM.code === el.code)) {
            el.new = true;
          }
          return el;
        }
      );
      return {
        ...state,
      };
    case importConstants.IMPORT_MARQUAGES_FAILURE:
      return {
        error: action.error,
      };
    case synchroConstants.DATA_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case synchroComConstants.ROTATE_ROUND_SUCCESS: {
      if (
        state.tourneeRessources &&
        state.tourneeRessources[action.roundCode]
      ) {
        state.tourneeRessources[action.roundCode].indexedMeter = 0;
        state.tourneeRessources[action.roundCode].modifiedMeter = 0;
      }
      return {
        ...state,
      };
    }
    case synchroComConstants.COM_ROUND_REQUEST:
      return {
        ...state,
      };
    case synchroComConstants.COM_ROUND_FAILURE:
      return {
        ...state,
        error: "Erreur synchronisation",
      };
    case synchroComConstants.COM_ROUND_SUCCESS:
      action.idList.forEach((deviceId) => {
        if (state.devices[deviceId]) {
          state.devices[deviceId].hasCommunication = true;
        }
      });
      return {
        ...state,
      };
    case synchroComConstants.COM_DEVICE_REQUEST:
      return {
        ...state,
      };
    case synchroComConstants.COM_DEVICE_FAILURE:
      return {
        ...state,
        error: "Erreur synchronisation",
      };
    case synchroComConstants.COM_DEVICE_SUCCESS:
      if (state.devices[action.deviceId]) {
        state.devices[action.deviceId].hasCommunication = true;
      }
      return {
        ...state,
      };
    case "CLEAR_ERROR":
      return {
        ...state,
        error: null,
      };
    case synchroConstants.CLEAR:
      return {};
    default:
      return state;
  }
}
