import React, { FocusEventHandler, MouseEventHandler, useState } from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  DateTimePicker,
  LocalizationProvider,
  renderTimeViewClock,
} from "@mui/x-date-pickers";
import moment, { Moment } from "moment";
import "moment/locale/es";
import "moment/locale/fr";
import { frFR, esES } from "@mui/x-date-pickers/locales";
import { WithTranslation, withTranslation } from "react-i18next";

const DateTimeInput: React.FC<
  {
    disabled?: boolean;
    id: string;
    label?: string;
    max?: Moment;
    min?: Moment;
    name: string;
    onChange: (userInput: any) => void;
    value: string;
    withHours?: boolean;
  } & WithTranslation
> = ({
  disabled = false,
  i18n,
  id,
  label,
  max,
  min,
  name,
  onChange,
  value,
  withHours = false,
}) => {
  const frLocal =
    frFR.components.MuiLocalizationProvider.defaultProps.localeText;
  const esLocal =
    esES.components.MuiLocalizationProvider.defaultProps.localeText;

  return (
    <LocalizationProvider
      dateAdapter={AdapterMoment}
      adapterLocale={i18n.language}
      localeText={
        i18n.language === "fr"
          ? frLocal
          : i18n.language === "es"
          ? esLocal
          : undefined
      }
    >
      {withHours ? (
        <DateTimePicker
          disabled={disabled}
          maxDateTime={max ? max : undefined}
          minDateTime={min ? min : undefined}
          name={name}
          onChange={(e: Moment | null, c) => {
            console.log(e);
            onChange(e?.format("YYYY-MM-DDTHH:mm"));
          }}
          slotProps={{
            textField: {
              size: "small",
              label: label,
            },
          }}
          sx={{
            ".MuiInputBase-input": { fontSize: "12.8px" },
            ".MuiButtonBase-root": { height: "34px", width: "34px" },
          }}
          value={moment(value)}
          minutesStep={5}
          viewRenderers={{
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock,
          }}
        />
      ) : (
        <DatePicker
          disabled={disabled}
          maxDate={max ? max : undefined}
          minDate={min ? min : undefined}
          name={name}
          onChange={(e: Moment | null, c) => {
            onChange(e?.format("YYYY-MM-DD"));
          }}
          slotProps={{
            textField: {
              size: "small",
              label: label,
            },
          }}
          sx={{
            ".MuiInputBase-input": { fontSize: "12.8px" },
            ".MuiButtonBase-root": { height: "34px", width: "34px" },
          }}
          value={moment(value)}
        />
      )}
    </LocalizationProvider>
  );
};

export default withTranslation()(DateTimeInput);
