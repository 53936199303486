import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Button, Col, Row } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import Sticky from "react-sticky-fill";
import { Link } from "react-router-dom";
import _ from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import { withTranslation } from "react-i18next";
import { REGEXVIRTUAL, translate } from "../_helpers";
import { alert } from "../_interfaces/reducers";
import UserSelector from "./UserSelector";
import userActions from "../_actions/user.actions";
import AlarmOptions from "./AlarmOptions";
import localeActions from "../_actions/locale.actions";
import alarmActions from "../_actions/alarm.actions";
import { alertActions, locationActions, vmeterActions } from "../_actions";
import SelectionComponent from "../MasksAlarmCreator/SelectionComponent";
import ListTools from "../List/ListTools";
import listOperator from "../_shared/OperatorList";
import { QueryBuilder } from "../QueryBuilder";
import pdiActions from "../_actions/pdi.actions";
import CompteurVert from "../SvgComponents/CompteurVert";
import maskActions from "../_actions/mask.actions";
import meterActions from "../_actions/meter.actions";
import {
  getFiltersRequest,
  getLightFilters,
} from "../QueryBuilder/FilterLSManager";
import locale from "../_shared/Locale.json";
import WarningBand from "../Bands/Warning";
import RemoteTable from "../RemoteTable/RemoteTable";
import Loading from "../_animations/Loading";

interface Props {
  users: any;
  locales: any;
  locations: any;
  alert: alert;
  alarms: any;
  match: any;
  dispatch: any;
  history: any;
  pdis: any;
  mask: any;
  location: any;
  user: any;
  t: Function;
  meters: any;
  vmeters: any;
}

const itemList = ["Selection", "Filtres", "Tous"];

export class AlarmConfigurator extends Component<Props, any> {
  constructor(props: Props) {
    super(props);

    const sourceColumns: any[] = ListTools.getDefaultColumns(
      ListTools.typeOfList.Alert
    ).map((champ: any) => {
      return {
        dataField: champ,
        text: _.get(locale, `fr.pdi.${champ}`),
        sort: true,
        classes: champ.includes("address") ? "" : "crystalList-column",
        //        formatter: _.get(ListTools.formatField, champ),
        filter: textFilter({
          placeholder: _.get(locale, `fr.pdi.${champ}`),
          delay: 500,
        }),
      };
    });

    this.state = {
      name: "",
      userList: [],
      alarmTemplates: {},
      alarmType: props.history.location.search.slice(7),
      isSelect: "Selection",
      availableMeters: [],
      ruleList: [],
      sourceColumns: [],
      meterList: [],
      metersToRemove: [],
      init: false,
      listMeter: [],
      displayingColumns: sourceColumns,
      displayNoMeterSelected: false,
    };

    this.handleSelect = this.handleSelect.bind(this);
    this.getExistingMetersList = this.getExistingMetersList.bind(this);
    this.handleChangeSeuil = this.handleChangeSeuil.bind(this);
    this.handleChangePeriode = this.handleChangePeriode.bind(this);
    this.handleLimiteConso = this.handleLimiteConso.bind(this);
    this.handleRefWitnessMeters = this.handleRefWitnessMeters.bind(this);
    this.handleHourInterval = this.handleHourInterval.bind(this);
    this.handleAlarm = this.handleAlarm.bind(this);
    this.receiveData = this.receiveData.bind(this);
    this.rowStyle = this.rowStyle.bind(this);
    this.clickOnRow = this.clickOnRow.bind(this);
  }

  componentDidMount() {
    const { dispatch, match, user } = this.props;
    const { alarmType, alarmTemplates } = this.state;
    const { params } = match;
    const locationId = params.locationId;
    dispatch(userActions.getAll());
    dispatch(userActions.get(user.id));
    dispatch(localeActions.load());
    dispatch(locationActions.get(locationId));
    dispatch(vmeterActions.getAll(locationId));
    dispatch(maskActions.getAvailableAlarms());
    dispatch(alarmActions.getAllType());
    dispatch(meterActions.getAll(locationId));
    if (!match.path.includes("editalert")) {
      alarmActions
        .getAllTemplates()
        .then((alarmTemplates) => {
          const realTemplate: any = alarmTemplates.find(
            (el: any) => _.get(el, "content.alertName") === alarmType
          );
          const index = _.get(
            realTemplate,
            "content.dataSourceProperty.condition"
          ).findIndex((el: any) => el.conditionTitle === "Period");
          if (
            index &&
            _.get(realTemplate, ".content.dataSourceProperty.condition") &&
            realTemplate.content.dataSourceProperty.condition[index]
          ) {
            realTemplate.content.dataSourceProperty.condition[
              index
            ].conditionValue = { value: "1", name: "Month" };
            realTemplate.content.dataSourceProperty.condition[
              index
            ].conditionDefaultValue = "Month";
          }
          this.setState({ alarmTemplates: realTemplate });
        })
        .catch((error: any) => {
          dispatch(alertActions.error(error));
        });
    }
    const keyStorage = `filter-${ListTools.typeOfList.Alert}-${locationId}`;
    this.setState({
      ruleList: getLightFilters(keyStorage),
    });
    dispatch(
      pdiActions.getRemotePdi(
        locationId,
        getFiltersRequest(keyStorage),
        10,
        1,
        !(localStorage.getItem(`${keyStorage}_optionor`) === "true"),
        {
          field: null,
          way: null,
        },
        {},
        null
      )
    );
  }

  componentDidUpdate(prevProps: Props, prevState: any, snapshot: any) {
    const { locations, dispatch, match, users, user, history } = this.props;
    const { fetchedLocation } = locations;
    if (
      match.path.includes("editalert") &&
      !prevProps.alarms.fetchedAlarm &&
      !prevProps.alarms.loading &&
      fetchedLocation
    ) {
      const { code } = fetchedLocation;
      if (undefined !== code) {
        const alarmId = Number(location.search.replace("?id=", ""));
        dispatch(alarmActions.get(alarmId, code));
      }
    }
    const roleList = ["DIOPTASE", "SUPERADMIN", "ADMIN"];
    if (
      users.fetchedUser &&
      !roleList.includes(user.role.name) &&
      !locations.fetchedLocation.tournee && // CETTE LIGNE ALEXIS
      !users.fetchedUser.profils.find(
        (el: any) =>
          el.profil.permissions.find(
            (permission: any) => permission.name === "edit.alert"
          ) && el.locationCode === locations.fetchedLocation.code
      )
    ) {
      history.push("/forbidden");
    }

    if (this.props.alarms.savedAlarm) {
      history.push({
        pathname: `${
          history.location.pathname.match(/\/locations\/\d+/)[0]
        }/alerts`,
        search: ``,
      });
    }
  }

  static getDerivedStateFromProps(props: Props, state: any) {
    const copyState = _.cloneDeep(state);
    if (props.pdis && props.pdis.items && props.pdis.items.length > 0) {
      const sourceColumns: any[] = ListTools.getDefaultColumns(
        ListTools.typeOfList.Alert
      ).map((champ: any) => {
        return {
          dataField: champ,
          text: _.get(locale, `fr.pdi.${champ}`),
          sort: true,
          classes: champ.includes("address") ? "" : "crystalList-column",
          formatter: ListTools.findFormatter(champ),
          filter: textFilter({
            placeholder: _.get(locale, `fr.pdi.${champ}`),
            delay: 500,
          }),
        };
      });

      copyState.sourceColumns = sourceColumns;
    }
    if (
      props.alarms &&
      props.alarms.fetchedAlarm &&
      props.alarms.fetchedAlarm.content &&
      !state.init &&
      props.pdis &&
      props.pdis.items &&
      props.users &&
      props.users.items
    ) {
      copyState.alarmTemplates = props.alarms.fetchedAlarm;
      const indexMeters =
        props.alarms.fetchedAlarm.content.dataSourceProperty.condition.findIndex(
          (condition: any) => condition.conditionTitle === "meters"
        );
      if (
        indexMeters !== -1 &&
        props.alarms.fetchedAlarm.content.dataSourceProperty.condition[
          indexMeters
        ].conditionValue
      ) {
        copyState.meterList =
          props.alarms.fetchedAlarm.content.dataSourceProperty.condition[
            indexMeters
          ].conditionValue.map((serialMeter: string) =>
            props.pdis.items.find(
              (pdi: any) => pdi.meter.serial === serialMeter
            )
          );
      }
      copyState.userList = props.alarms.fetchedAlarm.users.map((el: any) =>
        props.users.items.findIndex((user: any) => el === user.id)
      );
      copyState.name = props.alarms.fetchedAlarm.content.name;
      copyState.init = true;

      let isSelect = "Selection";
      if (props.alarms.fetchedAlarm.filterClause) {
        isSelect = "Filtres";
      }
      if (
        !props.alarms.fetchedAlarm.filterClause &&
        !(copyState.meterList.length > 0)
      ) {
        isSelect = "Tous";
      }
      copyState.isSelect = isSelect;

      copyState.existingMeters = copyState.meterList =
        props.alarms.fetchedAlarm.content.dataSourceProperty.condition[
          indexMeters
        ].conditionValue || [];
      copyState.listMeter = copyState.meterList =
        props.alarms.fetchedAlarm.content.dataSourceProperty.condition[
          indexMeters
        ].conditionValue || [];
    }
    return copyState;
  }

  handleInvalidSubmit = (event: Object, errors: Object, values: any) => {
    this.setState({
      name: values.virtualName,
    });
  };

  handleValidSubmit = (event: Object, values: any, errors: any) => {
    const { name, alarmTemplates, meterList, ruleList, isSelect, listMeter } =
      this.state;
    const {
      locations,
      dispatch,
      match,
      history,
      match: {
        params: { locationId },
        path,
      },
    } = this.props;
    this.setState({ clickOnSave: true });
    const keyStorage = `filter-${ListTools.typeOfList.Alert}-${locationId}`;
    const pathIdentity = path.slice(30);
    const newTemplate = alarmTemplates;
    newTemplate.content.name = name;
    newTemplate.active = true;
    newTemplate.locationCode = locations.fetchedLocation.content.code;
    const index = _.get(
      alarmTemplates,
      "content.dataSourceProperty.condition",
      {}
    ).findIndex((el: any) => el.conditionTitle === "meters");
    if (_.size(newTemplate.users) === 0) {
      return;
    }
    if (
      _.get(alarmTemplates, "content.alertName") !== "GapConsumptionComparison"
    ) {
      switch (isSelect) {
        case "Selection":
          if (_.size(listMeter) === 0) {
            return;
          }
          newTemplate.content.dataSourceProperty.condition[
            index
          ].conditionValue = listMeter;
          newTemplate.mode = "select";
          break;
        case "Filtres":
          newTemplate.content.dataSourceProperty.condition[
            index
          ].conditionValue = null;
          const filters = getFiltersRequest(keyStorage);
          filters.forEach((e) => {
            delete e.filter.filter;
            if (_.isArray(e.userInput))
              e.userInput = _.join(
                e.userInput.map((el) => el.value),
                "|"
              );
            if (_.isPlainObject(e.userInput)) e.userInput = e.userInput.value;
          });
          newTemplate.filterClause = filters;
          newTemplate.mode = "filter";
          break;
        case "Tous":
          newTemplate.content.dataSourceProperty.condition[
            index
          ].conditionValue = null;
          newTemplate.mode = "all";
          break;
      }
    }

    if (match.path.includes("editalert")) {
      dispatch(alarmActions.update(newTemplate));
    } else {
      dispatch(alarmActions.save(newTemplate));
    }
  };

  componentWillUnmount() {
    const {
      dispatch,
      locations,
      match: {
        params: { locationId },
      },
    } = this.props;
    localStorage.removeItem(
      `filter-${ListTools.typeOfList.Alert}-${locationId}`
    );
    dispatch(alarmActions.clear());
    dispatch(alarmActions.getAll(locations.fetchedLocation.code));
    dispatch(pdiActions.clear());
  }

  getHeaderOfList = () => {
    const { meterList, name, vmeter, alarmType } = this.state;
    const { match, alert, locales, history, alarms, pdis, t } = this.props;
    const { params } = match;
    const locationId = params.locationId;
    return (
      <Fragment>
        <Sticky style={{ top: "90px", zIndex: "190" }}>
          <div
            className="presentation-container virtual-meter-info"
            style={{ border: "0.5px solid #ccc" }}
          >
            <div className="presentation-header">
              <span className="presentation-title">
                {t("alarm_configurator.title.alert_edition")}
              </span>
              {locales && locales.locale && (
                <span
                  style={{ color: "white" }}
                  className="presentation-main-title"
                >
                  {_.get(alarms, "fetchedAlarm.content.alertName")
                    ? translate(
                        "fr",
                        "alarmName",
                        alarms.fetchedAlarm.content.alertName,
                        locales.locale
                      )
                    : translate("fr", "alarmName", alarmType, locales.locale)}
                </span>
              )}
            </div>
            <div className="presentation-body" style={{ background: "none" }}>
              <Col md="12">
                <AvForm
                  onValidSubmit={this.handleValidSubmit}
                  onInvalidSubmit={this.handleInvalidSubmit}
                >
                  <Row>
                    <Col md="4">
                      <AvField
                        name="virtualName"
                        placeholder={t(
                          "alarm_configurator.placeholder.alert_name"
                        )}
                        type="text"
                        validate={{
                          pattern: { value: REGEXVIRTUAL },
                          maxLength: {
                            value: 100,
                            errorMessage:
                              "Le nom ne doit pas faire plus de 100 caractères",
                          },
                        }}
                        required
                        errorMessage={t(
                          "all.text.required_field_character_condition"
                        )}
                        onChange={(event: any) => {
                          this.setState({
                            name: event.target.value.replace(" ", "_"),
                          });
                        }}
                        value={
                          _.get(alarms, "fetchedAlarm.content.alertName")
                            ? alarms.fetchedAlarm.content.name
                            : name
                        }
                      />
                    </Col>
                    <Col md={{ size: 4, offset: 4 }}>
                      <div className="float-right">
                        <Button type="button" className="danger">
                          <Link to={`/locations/${locationId}/alerts`}>
                            {t("all.button.cancel")}
                          </Link>
                        </Button>
                        <Button
                          disabled={name.length === 0}
                          type="submit"
                          style={{ marginLeft: "5px" }}
                        >
                          {t("all.button.register")}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </AvForm>
              </Col>
              <div className="clearfix" />
            </div>
          </div>
        </Sticky>
      </Fragment>
    );
  };

  receiveData() {
    const { dispatch, match } = this.props;
    const { locationId } = match.params;
    const keyStorage = `filter-${ListTools.typeOfList.Alert}-${locationId}`;
    if (!_.isEqual(this.state.ruleList, getLightFilters(keyStorage))) {
      this.setState({ ruleList: getLightFilters(keyStorage) });
    }
  }

  getQueryBuilder() {
    const { pdis, match, t } = this.props;
    const { locationId } = match.params;
    const { sourceColumns } = this.state;
    const listFields = sourceColumns
      .map((col: any) => {
        const typeData = ListTools.findSpecificType(col.dataField);

        return {
          label: col.text,
          value: col.dataField,
          type: typeData,
        };
      })
      .sort((a: any, b: any) => {
        if (a.label < b.label) return -1;
        if (a.label === b.label) return 0;
        return 1;
      });

    return (
      <div className="filter-container" style={{ marginTop: "10px" }}>
        {pdis.items && (
          <QueryBuilder
            sendListFiltered={this.receiveData}
            listData={pdis.items}
            listOperator={listOperator}
            listFilters={listFields}
            idContext={ListTools.typeOfList.Alert}
            save={false}
            idSite={locationId}
          />
        )}
      </div>
    );
  }

  formatColumns() {
    return ListTools.getDefaultColumns(ListTools.typeOfList.Alert).map(
      (champ: any) => ({
        dataField: champ,
        text: _.get(locale, `fr.pdi.${champ}`),
        sort: true,
        classes: champ.includes("address") ? "" : "crystalList-column",
        //        formatter: _.get(ListTools.formatField, champ),
        filter: textFilter({
          placeholder: _.get(locale, `fr.pdi.${champ}`),
          delay: 500,
        }),
      })
    );
  }

  getExistingMetersList() {
    const { mask } = this.props;
    const { meterList, options, mtrKey, metersToRemove: mtrRm } = this.state;
    const { fetchedMask } = mask;
    const associatedMeters =
      fetchedMask && fetchedMask.listPdi ? fetchedMask.listPdi : [];
    const selected = mtrRm.map((it: any) => it.id);
    const nonSelectable = meterList
      .filter((it: any) => {
        const exists = associatedMeters.find((m: any) => it.meter.id === m.id);
        if (exists === undefined) {
          return it;
        }
      })
      .map((mtr: any) => mtr.id);
    const selectRow = {
      mode: "checkbox",
      classes: "regular-checkbox checkbox-remove",
      clickToSelect: true,
      nonSelectable,
      selected,
      selectionHeaderRenderer: ({ mode, ...props }: any) => {
        if (props.indeterminate) {
          return (
            <input
              className="all-cb regular-checkbox checkbox-remove-all"
              type={mode}
              defaultChecked
            />
          );
        }
        return props.checked ? (
          <input
            className="all-cb regular-checkbox checkbox-remove"
            type={mode}
            defaultChecked
          />
        ) : (
          <input
            className="all-cb regular-checkbox checkbox-remove"
            type={mode}
          />
        );
      },
      onSelect: (row: any, isSelect: any, rowIndex: any, e: any) => {
        const { metersToRemove } = this.state;
        const mtrsCopy = _.cloneDeep(metersToRemove);

        const existing = mtrsCopy.find((it: any) => it.id === row.id);
        if (isSelect) {
          if (existing === undefined) {
            mtrsCopy.push(row);
          }
        } else if (existing !== undefined) {
          const mtrIndex = mtrsCopy.indexOf(existing);
          mtrsCopy.splice(mtrIndex, 1);
        }

        this.setState({ metersToRemove: mtrsCopy });
      },
      onSelectAll: (isSelect: boolean, rows: any, e: any) => {
        const { metersToRemove } = this.state;
        const mtrsCopy = _.cloneDeep(metersToRemove);

        rows.forEach((row: any) => {
          const existing = mtrsCopy.find((it: any) => it.id === row.id);
          if (existing === undefined && isSelect) {
            mtrsCopy.push(row);
          }
          if (existing !== undefined && !isSelect) {
            const mtrIndex = mtrsCopy.indexOf(existing);
            mtrsCopy.splice(mtrIndex, 1);
          }
        });
        this.setState({ metersToRemove: mtrsCopy });
      },
    };
    const columns: any = this.formatColumns();
    columns[0].headerStyle = () => ({ width: "80%" });
    return (
      <Col md="12">
        <BootstrapTable
          keyField="id"
          key={mtrKey}
          selectRow={selectRow}
          data={meterList}
          bootstrap4
          bordered={false}
          columns={columns}
          hover
          filter={filterFactory()}
          headerClasses="crystalList-column"
          pagination={paginationFactory(options)}
        />
      </Col>
    );
  }

  rowStyle(row) {
    const { listMeter, existingMeters } = this.state;
    const rowSerial = _.get(row, "meter.serial");
    const isSelected = listMeter.includes(rowSerial);
    const linked = existingMeters ? existingMeters.includes(rowSerial) : false;
    if (isSelected && linked) {
      return { backgroundColor: "#b9cfe4" };
    }
    if (isSelected) {
      return { backgroundColor: "#bddbd1" };
    }
    if (linked) {
      return { backgroundColor: "#f4c5bb" };
    }
    return {};
  }

  clickOnRow(e: Object, row: any) {
    let copyListMeter = _.cloneDeep(this.state.listMeter);
    const selectedMeterSerial = _.get(row, "meter.serial");
    if (copyListMeter.includes(selectedMeterSerial)) {
      copyListMeter = copyListMeter.filter((el) => el !== selectedMeterSerial);
    } else {
      copyListMeter = _.concat(copyListMeter, [selectedMeterSerial]);
    }
    this.setState({ listMeter: copyListMeter });
  }

  handleSelect(users: any) {
    const { alarmTemplates } = this.state;
    const newTemplate = _.cloneDeep(alarmTemplates);
    const userList = (users || []).map((el: any) => el.value.id);
    newTemplate.users = userList;
    this.setState({
      userList: (users || []).map((el: any) => el.value.id),
      alarmTemplates: newTemplate,
    });
  }

  handleChangeSeuil(seuil: any) {
    const { alarmTemplates } = this.state;
    const newTemplate = alarmTemplates;
    const index = _.get(
      alarmTemplates,
      "content.dataSourceProperty.condition"
    ).findIndex((el: any) => el.conditionTitle === "RangeConsumption");
    newTemplate.content.dataSourceProperty.condition[index].conditionValue =
      seuil;
    this.setState({
      alarmTemplates: newTemplate,
    });
  }

  handleChangePeriode(period: any) {
    const { alarmTemplates } = this.state;
    const newTemplate = alarmTemplates;
    const index = _.get(
      alarmTemplates,
      "content.dataSourceProperty.condition"
    ).findIndex((el: any) => el.conditionTitle === "Period");
    newTemplate.content.dataSourceProperty.condition[index].conditionValue =
      period;
    newTemplate.content.dataSourceProperty.condition[
      index
    ].conditionDefaultValue = period.name;
    this.setState({
      alarmTemplates: newTemplate,
    });
  }

  handleHourInterval(interval: any) {
    const { alarmTemplates } = this.state;
    const newTemplate = alarmTemplates;
    const index = _.get(
      alarmTemplates,
      "content.dataSourceProperty.condition"
    ).findIndex((el: any) => el.conditionTitle === "HourPeriod");
    newTemplate.content.dataSourceProperty.condition[index].conditionValue =
      interval;
    this.setState({
      alarmTemplates: newTemplate,
    });
  }

  handleAlarm(value: any) {
    const { alarmTemplates } = this.state;
    const newTemplate = alarmTemplates;
    if (value) {
      const index = _.get(
        alarmTemplates,
        "content.dataSourceProperty.condition"
      ).findIndex((el: any) => el.conditionTitle === "AlarmType");
      newTemplate.content.dataSourceProperty.condition[index].conditionValue =
        value.map((el: any) => el.value);
    }
    this.setState({
      alarmTemplates: newTemplate,
    });
  }

  handleLimiteConso(percent: any) {
    const { alarmTemplates } = this.state;
    const newTemplate = alarmTemplates;
    const index = _.get(
      alarmTemplates,
      "content.dataSourceProperty.condition"
    ).findIndex((el: any) => el.conditionTitle === "GapConsumption");
    newTemplate.content.dataSourceProperty.condition[index].conditionValue =
      percent;
    this.setState({
      alarmTemplates: newTemplate,
    });
  }

  handleRefWitnessMeters(meters: any) {
    const { alarmTemplates } = this.state;
    const newTemplate = alarmTemplates;
    const indexRef = _.get(
      alarmTemplates,
      "content.dataSourceProperty.condition"
    ).findIndex((el: any) => el.conditionTitle === "MeterSerialNumberRef");
    const indexWit = _.get(
      alarmTemplates,
      "content.dataSourceProperty.condition"
    ).findIndex((el: any) => el.conditionTitle === "MeterSerialNumber_1");

    newTemplate.content.dataSourceProperty.condition[indexRef].conditionValue =
      meters.ref;
    newTemplate.content.dataSourceProperty.condition[indexWit].conditionValue =
      meters.witness;
    this.setState({
      alarmTemplates: newTemplate,
    });
  }

  render() {
    const {
      users,
      alert,
      locales,
      pdis,
      alarms,
      meters,
      vmeters,
      match: {
        params: { locationId },
      },
      t,
    } = this.props;
    const {
      userList,
      alarmTemplates,
      alarmType,
      isSelect,
      availableMeters,
      meterList,
      existingMeters,
      displayingColumns,
      listMeter,
      displayNoMeterSelected,
      displayNoAlarmSelected,
    } = this.state;

    const listAssociated = _.uniqBy(
      _.concat(
        (existingMeters || []).map((el) => ({ "meter.serial": el })),
        (listMeter || []).map((el) => ({ "meter.serial": el }))
      ),
      "meter.serial"
    ).map((el) => ({
      ...el,
      isSelected: listMeter.includes(_.get(el, "meter.serial")),
      linked: (existingMeters || []).includes(_.get(el, "meter.serial")),
    }));

    const displayListSelectedColumns = displayingColumns
      .filter((el) => el.dataField.includes("meter.serial"))
      .map((el) => ({
        ...el,
        formatter: ListTools.formatSerialWithAction,
      }));

    let explaination = "";
    if (pdis.items && pdis.items.length) {
      switch (isSelect) {
        case itemList[0]:
          explaination = t("all.meter.selection_explanation");
          break;
        case itemList[1]:
          explaination = t("all.text.filter_explanation");
          break;
        case itemList[2]:
          explaination = t("all.meter.confirm_x_selection_explanation", {
            count: pdis.countTotal,
          });
      }
    }

    return (
      <div className="alarm-configurator">
        {(!pdis.items || !_.get(alarmTemplates, "content")) && <Loading />}
        {locales.locale &&
          pdis &&
          pdis.items &&
          vmeters.items &&
          alarmTemplates &&
          _.get(alarmTemplates, "content") && (
            <>
              {this.getHeaderOfList()}
              <UserSelector
                displayNotSet={_.get(this.state, "clickOnSave")}
                users={users.items}
                handleSelect={this.handleSelect}
                selectedUsers={userList}
              />
              <AlarmOptions
                locales={locales}
                alarmTemplates={alarms.fetchedAlarm || alarmTemplates}
                handleChangeSeuil={this.handleChangeSeuil}
                handleChangePeriode={this.handleChangePeriode}
                handleLimiteConso={this.handleLimiteConso}
                handleRefWitnessMeters={this.handleRefWitnessMeters}
                handleHourInterval={this.handleHourInterval}
                handleAlarm={this.handleAlarm}
                pdis={pdis.items}
                listMeters={_.concat(
                  pdis.items.map((el) => el.meter.serial),
                  vmeters.items.map((el) => el.name)
                )}
                typesAlarm={alarms.types}
                meters={meters.items}
              />
              {!["GapConsumptionComparison"].includes(
                _.get(alarmTemplates, "content.alertName")
              ) && (
                <div className="selected-content">
                  <SelectionComponent
                    handleMod={(newMode: string) => {
                      if (newMode === "Filtres") {
                        this.setState({
                          meterList: availableMeters,
                          isSelect: newMode,
                        });
                      } else {
                        this.setState({
                          meterList: [],
                          isSelect: newMode,
                        });
                      }
                    }}
                    activedMod={isSelect}
                    totalMeters={pdis.items.length}
                  />
                  <div className="select-option">
                    <div className="preview">
                      <h4>{explaination}</h4>
                    </div>

                    {isSelect !== "Tous" && (
                      <>
                        {this.getQueryBuilder()}
                        <div style={{ marginTop: "10px" }}>
                          <Row
                            className="virtual-meter-editor"
                            style={{
                              justifyContent:
                                isSelect === "Filtres" ? "center" : "left",
                              marginBottom: "1vh",
                              padding: "15px",
                            }}
                          >
                            <div
                              style={{
                                maxWidth:
                                  isSelect === "Filtres" ? "100%" : "66.66%",
                                width: "100%",
                              }}
                            >
                              <div className="crystalList-container">
                                <div
                                  className="table-info-container"
                                  style={{ position: "relative" }}
                                >
                                  <h2>
                                    <span>
                                      <CompteurVert
                                        height="1em"
                                        width="1em"
                                        stroke="#31c6b3"
                                        fill="white"
                                        strokeWidth="1"
                                      />
                                    </span>
                                    {pdis.count} compteurs disponibles
                                  </h2>
                                  <br />
                                  <RemoteTable
                                    keyStorage={`filter-${ListTools.typeOfList.Alert}-${locationId}`}
                                    displayingColumns={displayingColumns}
                                    rowStyle={
                                      isSelect === "Selection"
                                        ? (row) => this.rowStyle(row)
                                        : null
                                    }
                                    clickOnRow={this.clickOnRow}
                                    ruleList={this.state.ruleList}
                                    optionOr={
                                      localStorage.getItem(
                                        `filter-${ListTools.typeOfList.Alert}-${locationId}_optionor`
                                      ) === "true"
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            {isSelect !== "Filtres" && (
                              <Col md="4" style={{ paddingRight: 0 }}>
                                <div className="table-info-container">
                                  <h2>
                                    <span>
                                      <CompteurVert
                                        height="1em"
                                        width="1em"
                                        stroke="#31c6b3"
                                        fill="white"
                                        strokeWidth="1"
                                      />
                                    </span>
                                    {_.size(listMeter)} compteurs selectionnés
                                  </h2>
                                  <br />
                                  <Fragment>
                                    {(displayNoMeterSelected ||
                                      _.get(this.state, "clickOnSave")) &&
                                      _.size(listMeter) === 0 && (
                                        <WarningBand message="Veuillez selectionner au moins 1 compteur" />
                                      )}
                                    {_.size(listAssociated) > 0 && (
                                      <BootstrapTable
                                        keyField="id"
                                        key="mtrassociated"
                                        rowClasses="clickable"
                                        data={listAssociated}
                                        bootstrap4
                                        bordered={false}
                                        columns={displayListSelectedColumns}
                                        rowEvents={{ onClick: this.clickOnRow }}
                                        hover
                                        filter={filterFactory()}
                                        headerClasses="crystalList-column"
                                        pagination={paginationFactory()}
                                        rowStyle={this.rowStyle}
                                      />
                                    )}
                                  </Fragment>
                                </div>
                              </Col>
                            )}
                          </Row>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const {
    locales,
    locations,
    alert,
    alarms,
    users,
    pdis,
    mask,
    authentication,
    meters,
    vmeters,
  } = state;
  const { user } = authentication;
  return {
    users,
    locales,
    locations,
    alert,
    alarms,
    pdis,
    mask,
    user,
    meters,
    vmeters,
  };
}

const connectedAlarmConfigurator = connect(mapStateToProps)(AlarmConfigurator);
const tr = withTranslation()(connectedAlarmConfigurator);
export default tr;
