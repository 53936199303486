import React from "react";
const SvgPdf = (props: any) => (
  <svg viewBox="0 0 20.44 20.52" {...props}>
    <path
      fill={"#be1622"}
      d="M18.64,6.72v-2.07s0-.04-.01-.05c0-.02,0-.04-.01-.06-.02-.07-.05-.14-.1-.19L14.3.13c-.05-.05-.12-.08-.19-.1-.02,0-.04,0-.06-.01-.02,0-.04-.01-.06-.01H3.46c-.24,0-.43.19-.43.43v6.23h-.81c-1.23,0-2.23,1-2.23,2.23v4.32c0,.06.01.12.02.18.09,1.15,1.04,2.05,2.21,2.05h.81v4.65c0,.24.19.43.43.43h14.75c.24,0,.43-.19.43-.43v-4.69c1.02-.2,1.8-1.1,1.8-2.19v-4.32c0-1.08-.78-1.96-1.8-2.17ZM14.43,1.47l2.75,2.75h-2.75V1.47ZM3.9.86h9.67v3.79c0,.24.19.43.43.43h3.79v1.58H3.9V.86ZM3.9,19.66v-4.22h13.89v4.22H3.9Z"
    />
    <path
      fill={"#fff"}
      d="M3.58,13.78v-4.89h1.72c.23,0,.45.04.65.11.2.08.38.18.53.32.15.14.27.3.35.49.09.19.13.4.13.63s-.04.44-.13.63-.2.35-.35.49c-.15.14-.33.24-.53.32s-.42.11-.65.11h-.8v1.8h-.92ZM5.31,11.11c.24,0,.42-.07.55-.21.12-.14.18-.29.18-.47,0-.09-.01-.17-.04-.25-.03-.08-.08-.15-.14-.22-.06-.06-.14-.11-.23-.15-.09-.04-.2-.05-.32-.05h-.81v1.35h.81Z"
    />
    <path
      fill={"#fff"}
      d="M8.24,8.89h1.65c.39,0,.74.06,1.04.18.31.12.57.29.79.5.22.22.39.47.5.78.12.3.17.63.17.99s-.06.7-.17,1-.28.56-.5.77c-.22.21-.48.38-.79.5-.31.12-.66.18-1.04.18h-1.65v-4.89ZM9.85,12.9c.27,0,.51-.04.71-.11s.38-.18.51-.32c.14-.14.24-.3.31-.5.07-.19.1-.41.1-.65s-.03-.45-.1-.65c-.07-.19-.17-.36-.31-.5-.14-.14-.31-.24-.51-.32-.2-.08-.44-.11-.71-.11h-.69v3.14h.69Z"
    />
    <path
      fill={"#fff"}
      d="M13.76,8.89h3.1v.87h-2.18v1.19h1.96v.87h-1.96v1.95h-.92v-4.89Z"
    />
  </svg>
);

export default SvgPdf;
