import React, { useState, useEffect, useRef } from "react";
import SvgTournees from "../../SvgComponents/TourneesVertes";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  UncontrolledDropdown,
} from "reactstrap";
import BurgerHeader from "../../SvgComponents/BurgerHeader";
import ValidationVert from "../../SvgComponents/ValidationVert";
import SvgTelephone from "../../SvgComponents/TelephoneVert";
import {
  DataProvider,
  LayoutProvider,
  RecyclerListView,
} from "recyclerlistview/web";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import BestTask from "./BestTask";
import _ from "lodash";
import {
  getLocalStorage,
  setLocalStorage,
} from "../../_helpers/localStorage-helper";

const sortedList = (tasks, labels, filter, sort) => {
  let copyTasks = _.cloneDeep(tasks);
  if (filter.length > 0) {
    copyTasks = copyTasks.filter(
      (task) =>
        task.content.toUpperCase().includes(filter.toUpperCase()) ||
        (task.serial
          ? task.serial.toUpperCase().includes(filter.toUpperCase())
          : task.rndCode.toUpperCase().includes(filter.toUpperCase()))
    );
  }
  switch (sort.type) {
    case "code":
      copyTasks = copyTasks.sort((a, b) => {
        if (a.serial) {
          return a.serial.toUpperCase() > b.serial.toUpperCase() ? 1 : -1;
        }
        return a.rndCode.toUpperCase() > b.rndCode.toUpperCase() ? 1 : -1;
      });
      break;
    case "libelle":
      copyTasks = copyTasks.sort((a, b) =>
        a.content.toUpperCase() > b.content.toUpperCase() ? 1 : -1
      );
      break;
    case "actions":
      const rounds = _.concat(
        labels.affect.map((el) => el.tournee),
        labels.disaffect.map((el) => el.tournee)
      );
      const devices = _.concat(
        labels.affect.map((el) => el.device),
        labels.disaffect.map((el) => el.device)
      );
      copyTasks = copyTasks.sort((a, b) => {
        if (a.serial) {
          const occuA = devices.filter((el) => el === a.serial).length;
          const occuB = devices.filter((el) => el === b.serial).length;
          return occuB - occuA;
        }
        const occuA = rounds.filter((el) => el === a.rndCode).length;
        const occuB = rounds.filter((el) => el === b.rndCode).length;
        return occuB - occuA;
      });
      break;
    case "charges":
      const loadedRounds = labels.loaded.map((el) => el.tournee);
      const loadedDevices = labels.loaded.map((el) => el.device);
      copyTasks = copyTasks.sort((a, b) => {
        if (a.serial) {
          const occuA = loadedDevices.filter((el) => el === a.serial).length;
          const occuB = loadedDevices.filter((el) => el === b.serial).length;
          return occuB - occuA;
        }
        const occuA = loadedRounds.filter((el) => el === a.rndCode).length;
        const occuB = loadedRounds.filter((el) => el === b.rndCode).length;
        return occuB - occuA;
      });
      break;
    default:
      break;
  }
  if (sort.reverse) {
    copyTasks = _.reverse(copyTasks);
  }

  return copyTasks;
};

const Col = (props) => {
  const recyclerRef = useRef(null);
  const { column, synchro, labels, errorList, roundsLoading, match, t } = props;
  const [filter, setFilter] = useState("");
  const [sortConfig, setSortConfig] = useState({});
  const [data, setData] = useState(null);
  const [layout, setLayout] = useState(
    new LayoutProvider(
      () => {
        return "VSEL";
      },
      (type, dim) => {
        const doc = document.getElementById(
          `column-drag-${isRound ? "round" : "device"}`
        );
        const docInfo = doc.getBoundingClientRect();
        switch (type) {
          case "VSEL":
            dim.width = docInfo.width - 40;
            dim.height = 180;
            break;
          default:
            dim.width = 0;
            dim.height = 0;
        }
      }
    )
  );
  const { params } = match;
  const isRound = column === "round";

  const sortedRounds = () => {
    return Object.entries(synchro.tourneeRessources)
      .map((el) => el[1])
      .sort((a, b) => {
        return errorList?.find((el) => el.errorDetail[a.id])
          ? -1
          : errorList?.find((el) => el.errorDetail[b.id])
          ? 1
          : 0;
      })
      .sort((a, b) => {
        return (
          roundsLoading &&
          (roundsLoading[a.id]?.isLoading
            ? -1
            : roundsLoading[b.id]?.isLoading
            ? 1
            : 0)
        );
      });
  };

  useEffect(() => {
    const taskList = isRound
      ? sortedRounds()
      : Object.entries(synchro.devices)
          .map((el) => el[1])
          .sort((a, b) => {
            return a.online ? -1 : b.online ? 1 : 0;
          });
    setData(
      new DataProvider((r1, r2) => {
        return r1 !== r2;
      }).cloneWithRows(taskList)
    );
    const initSort = getLocalStorage(
      `synchro-${params.gestionnaireId}-sort-${column}`
    );
    if (initSort) setSortConfig(initSort);
    window.addEventListener("resize", () => {
      setLayout(
        new LayoutProvider(
          () => {
            return "VSEL";
          },
          (type, dim) => {
            const doc = document.getElementById(
              `column-drag-${isRound ? "round" : "device"}`
            );
            const docInfo = doc.getBoundingClientRect();
            switch (type) {
              case "VSEL":
                dim.width = docInfo.width - 40;
                dim.height = 180;
                break;
              default:
                dim.width = 0;
                dim.height = 0;
            }
          }
        )
      );
    });
  }, []);

  const updateData = () => {
    const taskList = isRound
      ? sortedRounds()
      : Object.entries(synchro.devices)
          .map((el) => el[1])
          .sort((a, b) => {
            return a.online ? -1 : b.online ? 1 : 0;
          });
    setData(
      new DataProvider((r1, r2) => {
        return r1 !== r2;
      }).cloneWithRows(sortedList(taskList, labels, filter, sortConfig))
    );
  };

  useEffect(() => {
    updateData();
  }, [filter || sortConfig]);

  useEffect(() => {
    updateData();
  }, [synchro]);

  useEffect(() => {
    setLocalStorage(
      `synchro-${params.gestionnaireId}-sort-${column}`,
      sortConfig
    );
  }, [sortConfig]);

  const handleFilterChange = (e) => {
    e.preventDefault();
    setFilter(e.target.value);
  };

  const rowRenderer = (type, row, a, b) => {
    return <BestTask task={row} />;
  };

  const svg = isRound ? (
    <SvgTournees fill="#31c6b3" height="2.5em" />
  ) : (
    <SvgTelephone fill="#31c6b3" height="2.5em" />
  );

  return (
    <div
      className="col-md-6"
      id={`column-drag-${isRound ? "round" : "device"}`}
      style={{
        margin: "0 5px",
        maxWidth: "calc(50% - 10px)",
        padding: 0,
        zIndex: isRound ? 3 : 2,
      }}
    >
      <div className="information-container-header">
        <div className="header-drag-column">
          <div className="title">
            {svg}
            <h3>{t(`synchronisation.${column}`)}</h3>
          </div>
          <Input
            onChange={(e) => handleFilterChange(e, true)}
            placeholder={
              isRound
                ? t("all.filter.round_filter_plural")
                : t("all.filter.device_filter_plural")
            }
            defaultValue={filter}
          />
          <div style={{ zIndex: 10, marginLeft: "10px" }}>
            <UncontrolledDropdown className="float-right">
              <DropdownToggle nav>
                <BurgerHeader fill="#31c6b3" height="1.5em" />
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem
                  onClick={() => setSortConfig({ type: "default" })}
                >
                  {t("columns.text.date_added")}
                  {sortConfig.type === "default" && !sortConfig.reverse && (
                    <ValidationVert
                      fill="#31c6b3"
                      height="1em"
                      style={{ marginLeft: "3ch" }}
                    />
                  )}
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem
                  onClick={() =>
                    setSortConfig({ type: "code", reverse: false })
                  }
                >
                  {t("columns.text.code_abc_order")}
                  {sortConfig.type === "code" && !sortConfig.reverse && (
                    <ValidationVert
                      fill="#31c6b3"
                      height="1em"
                      style={{ marginLeft: "3ch" }}
                    />
                  )}
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem
                  onClick={() => setSortConfig({ type: "code", reverse: true })}
                >
                  {t("columns.text.code_abc_disorder")}
                  {sortConfig.type === "code" && sortConfig.reverse && (
                    <ValidationVert
                      fill="#31c6b3"
                      height="1em"
                      style={{ marginLeft: "3ch" }}
                    />
                  )}
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem
                  onClick={() =>
                    setSortConfig({ type: "libelle", reverse: false })
                  }
                >
                  {t("columns.text.wording_abc_order")}
                  {sortConfig.type === "libelle" && !sortConfig.reverse && (
                    <ValidationVert
                      fill="#31c6b3"
                      height="1em"
                      style={{ marginLeft: "3ch" }}
                    />
                  )}
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem
                  onClick={() =>
                    setSortConfig({ type: "libelle", reverse: true })
                  }
                >
                  {t("columns.text.wording_abc_disorder")}
                  {sortConfig.type === "libelle" && sortConfig.reverse && (
                    <ValidationVert
                      fill="#31c6b3"
                      height="1em"
                      style={{ marginLeft: "3ch" }}
                    />
                  )}
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem
                  onClick={() =>
                    setSortConfig({ type: "actions", reverse: false })
                  }
                >
                  {t("columns.text.actions_max_to_min")}
                  {sortConfig.type === "actions" && !sortConfig.reverse && (
                    <ValidationVert
                      fill="#31c6b3"
                      height="1em"
                      style={{ marginLeft: "3ch" }}
                    />
                  )}
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem
                  onClick={() =>
                    setSortConfig({ type: "actions", reverse: true })
                  }
                >
                  {t("columns.text.actions_min_to_max")}
                  {sortConfig.type === "actions" && sortConfig.reverse && (
                    <ValidationVert
                      fill="#31c6b3"
                      height="1em"
                      style={{ marginLeft: "3ch" }}
                    />
                  )}
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem
                  onClick={() =>
                    setSortConfig({ type: "charges", reverse: false })
                  }
                >
                  {t("columns.text.loaded_max_to_min")}
                  {sortConfig.type === "charges" && !sortConfig.reverse && (
                    <ValidationVert
                      fill="#31c6b3"
                      height="1em"
                      style={{ marginLeft: "3ch" }}
                    />
                  )}
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem
                  onClick={() =>
                    setSortConfig({ type: "charges", reverse: true })
                  }
                >
                  {t("columns.text.loaded_min_to_max")}
                  {sortConfig.type === "charges" && sortConfig.reverse && (
                    <ValidationVert
                      fill="#31c6b3"
                      height="1em"
                      style={{ marginLeft: "3ch" }}
                    />
                  )}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </div>
      </div>

      <div>
        {data && data.getAllData().length > 0 && (
          <div
            className="information-container column-synchronisation"
            id={isRound ? "popup-scroll" : ""}
            style={{
              maxHeight: window.innerHeight - 230,
              height: data.getAllData().length * 205,
            }}
          >
            <span className="infoLibelle">
              <RecyclerListView
                ref={recyclerRef}
                style={{ flex: 1 }}
                dataProvider={data}
                rowRenderer={rowRenderer}
                layoutProvider={layout}
                updateRenderAheadOffset={0}
                contentContainerStyle={{
                  overflow: "none",
                }}
              />
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { users, authentication, alert, locations, synchro, synchrocom } =
    state;
  const { user } = authentication;
  const { labels, errorList, roundsLoading } = synchrocom;
  return {
    user,
    users,
    alert,
    locations,
    synchro,
    labels,
    errorList,
    roundsLoading,
  };
}

const mapping = connect(mapStateToProps)(Col);

const connectedCol = withRouter(mapping);
const tr = withTranslation()(connectedCol);
export default tr;
