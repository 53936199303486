import React, { useEffect, useState } from "react";
import {
  Collapse,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
} from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CompteurBlanc from "../SvgComponents/CompteurBlanc";
import PdiBlanc from "../SvgComponents/PdiBlanc";
import { getActiveClassByPath } from "../_helpers";
import ColonneVert from "../SvgComponents/ColonneVert";
import SvgImportBleu from "../SvgComponents/ImportBleu";
import { withTranslation } from "react-i18next";
import { importActions } from "../_actions";

const SidebarStockComponent = ({ location, imports, t, dispatch }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    dispatch(importActions.getImportManufacturer());
  }, []);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="sidebar-location">
      <Navbar
        className="sidebar-location-wrapper sidebar-stock"
        dark
        expand="md"
      >
        <div>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav navbar>
              <NavbarBrand
                href="/stock/resources"
                className="center"
                style={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {t("sidebar_stock.nav_title.stock")}
              </NavbarBrand>
              <NavItem>
                <NavLink
                  className={getActiveClassByPath(
                    location.pathname,
                    "resources"
                  )}
                  href="/stock"
                >
                  <ColonneVert
                    fill="currentcolor"
                    stroke="currentcolor"
                    height="1em"
                    width="1em"
                    style={{ marginRight: "5px" }}
                  />
                  {t("sidebar_stock.nav_link.resources_list")}
                </NavLink>
              </NavItem>
              {imports?.enabled && (
                <NavItem>
                  <NavLink
                    className={getActiveClassByPath(
                      location.pathname,
                      "import"
                    )}
                    href="/stock/import"
                  >
                    <SvgImportBleu
                      fill="currentcolor"
                      stroke="currentcolor"
                      height="1em"
                      width="1em"
                      style={{ marginRight: "5px" }}
                    />
                    {t("all.text.import_mfr")}
                  </NavLink>
                </NavItem>
              )}
              {false && (
                <div>
                  <NavItem>
                    <NavLink
                      className={getActiveClassByPath(
                        location.pathname,
                        "createmeter"
                      )}
                      href="/stock/createmeter"
                    >
                      <CompteurBlanc
                        fill="currentcolor"
                        stroke="currentcolor"
                        height="1em"
                        width="1em"
                      />
                      {t("sidebar_stock.nav_link.create_meter")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={getActiveClassByPath(
                        location.pathname,
                        "createpdi"
                      )}
                      href="/stock/createpdi"
                    >
                      <PdiBlanc
                        fill="currentcolor"
                        stroke="currentcolor"
                        height="1em"
                        width="1em"
                      />
                      {t("sidebar_stock.nav_link.create_pdi")}
                    </NavLink>
                  </NavItem>
                </div>
              )}
            </Nav>
          </Collapse>
        </div>
      </Navbar>
    </div>
  );
};

function mapStateToProps(state: any) {
  const { authentication, dashboards, locations, imports } = state;
  const { user } = authentication;
  return {
    user,
    dashboards,
    locations,
    imports,
  };
}

const mapping: any = connect(mapStateToProps)(SidebarStockComponent);

const connectedSidebarStock = withRouter(mapping);
const tr = withTranslation()(connectedSidebarStock);
export default tr;
