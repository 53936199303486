import React, { useState, useEffect } from "react";
import SvgTelephoneVert from "../../../SvgComponents/TelephoneVert";
import SvgSynchroPartielle from "../../../SvgComponents/SynchroPartielle";
import SvgSynchroComplete from "../../../SvgComponents/SynchroComplete";
import SvgArrowRightVert from "../../../SvgComponents/ArrowRightVert";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

const style = {
  wrapper: {
    position: "relative",
    height: "100%",
    width: "100%",
    display: "flex",
    pointerEvents: "none",
  },
  w50: {
    height: "100%",
    width: "50%",
    position: "absolute",
    top: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  h2: {
    color: "#000",
    margin: 0,
  },
  span: {
    color: "#fff",
    textAlign: "center",
    fontSize: "14px",
  },
  flex: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
};

const CompleteLayout = ({ task, t }) => (
  <div style={{ ...style.wrapper, backgroundColor: "#92A68C" }}>
    <div style={style.flex}>
      <SvgSynchroComplete height={"50px"} fill={"#fff"} />
      <h2 style={{ ...style.h2, margin: "5px 0" }}>
        {t("synchronisation.option.complete_sync")}
      </h2>
      <span style={style.span}>
        {t("synchronisation.assign_rounds", { device: task.id })}
      </span>
    </div>
  </div>
);

const PartialLayout = ({ task, t }) => (
  <div style={{ ...style.wrapper, backgroundColor: "#4A6365" }}>
    <div style={style.flex}>
      <SvgSynchroPartielle height={"50px"} fill={"#fff"} />
      <h2 style={{ ...style.h2, margin: "5px 0" }}>
        {t("synchronisation.option.partial_sync")}
      </h2>
      <span style={style.span}>
        {t("synchronisation.assign_unread.from_rounds", { device: task.id })}
      </span>
    </div>
  </div>
);

const HoverLayout = ({ task, partial, mode, t }) => {
  return mode === "both" ? (
    <div style={style.wrapper}>
      <div
        style={{
          ...style.w50,
          left: 0,
          backgroundColor: partial ? "#4A6365" : "#fff",
        }}
      >
        <h2 style={style.h2}>{t("synchronisation.option.partial_sync")}</h2>
        {partial && (
          <span style={style.span}>
            {t("synchronisation.assign_unread.from_rounds", {
              device: task.id,
            })}
          </span>
        )}
      </div>
      <div
        style={{
          ...style.w50,
          left: "50%",
          backgroundColor: !partial ? "#92A68C" : "#fff",
        }}
      >
        <h2 style={style.h2}>{t("synchronisation.option.complete_sync")}</h2>
        {!partial && (
          <span style={style.span}>
            {t("synchronisation.assign_rounds", { device: task.id })}
          </span>
        )}
      </div>
    </div>
  ) : mode === "complete" ? (
    <CompleteLayout t={t} task={task} />
  ) : (
    <PartialLayout t={t} task={task} />
  );
};

function mapStateToProps(state) {
  const { synchrocom } = state;
  const { mode } = synchrocom;
  return {
    mode,
  };
}

const mapping = connect(mapStateToProps)(HoverLayout);

const connectedTask = withRouter(mapping);
const tr = withTranslation()(connectedTask);
export default tr;
