const importConstants = {
  IMPORT_REQUEST: "IMPORT_REQUEST",
  IMPORT_SUCCESS: "IMPORT_SUCCESS",
  IMPORT_FAILURE: "IMPORT_FAILURE",

  ANALYSE_REQUEST: "ANALYSE_REQUEST",
  ANALYSE_SUCCESS: "ANALYSE_SUCCESS",
  ANALYSE_FAILURE: "ANALYSE_FAILURE",

  IMPORT_SILEX_REQUEST: "IMPORT_SILEX_REQUEST",
  IMPORT_SILEX_SUCCESS: "IMPORT_SILEX_SUCCESS",
  IMPORT_SILEX_FAILURE: "IMPORT_SILEX_FAILURE",

  IMPORT_SILEX_ERROR_REQUEST: "IMPORT_SILEX_ERROR_REQUEST",
  IMPORT_SILEX_ERROR_SUCCESS: "IMPORT_SILEX_ERROR_SUCCESS",
  IMPORT_SILEX_ERROR_FAILURE: "IMPORT_SILEX_ERROR_FAILURE",

  IMPORT_MANUFACTURER_REQUEST: "IMPORT_MANUFACTURER_REQUEST",
  IMPORT_MANUFACTURER_SUCCESS: "IMPORT_MANUFACTURER_SUCCESS",
  IMPORT_MANUFACTURER_FAILURE: "IMPORT_MANUFACTURER_FAILURE",

  GET_IMPORT_MANUFACTURER_REQUEST: "GET_IMPORT_MANUFACTURER_REQUEST",
  GET_IMPORT_MANUFACTURER_SUCCESS: "GET_IMPORT_MANUFACTURER_SUCCESS",
  GET_IMPORT_MANUFACTURER_FAILURE: "GET_IMPORT_MANUFACTURER_FAILURE",

  IMPORT_LORA_REQUEST: "IMPORT_LORA_REQUEST",
  IMPORT_LORA_SUCCESS: "IMPORT_LORA_SUCCESS",
  IMPORT_LORA_FAILURE: "IMPORT_LORA_FAILURE",

  IMPORT_SILEX_CHANGEST3_REQUEST: "IMPORT_SILEX_CHANGEST3_REQUEST",
  IMPORT_SILEX_CHANGEST3_SUCCESS: "IMPORT_SILEX_CHANGEST3_SUCCESS",
  IMPORT_SILEX_CHANGEST3_FAILURE: "IMPORT_SILEX_CHANGEST3_FAILURE",

  IMPORT_SILEX_CHANGEST7_REQUEST: "IMPORT_SILEX_CHANGEST7_REQUEST",
  IMPORT_SILEX_CHANGEST7_SUCCESS: "IMPORT_SILEX_CHANGEST7_SUCCESS",
  IMPORT_SILEX_CHANGEST7_FAILURE: "IMPORT_SILEX_CHANGEST7_FAILURE",

  IMPORT_SILEX_CHANGEST5T6_REQUEST: "IMPORT_SILEX_CHANGEST5T6_REQUEST",
  IMPORT_SILEX_CHANGEST5T6_SUCCESS: "IMPORT_SILEX_CHANGEST5T6_SUCCESS",
  IMPORT_SILEX_CHANGEST5T6_FAILURE: "IMPORT_SILEX_CHANGEST5T6_FAILURE",

  IMPORT_TEST_REQUEST: "IMPORT_TEST_REQUEST",
  IMPORT_TEST_SUCCESS: "IMPORT_TEST_SUCCESS",
  IMPORT_TEST_FAILURE: "IMPORT_TEST_FAILURE",

  IMPORT_RESTORE_REQUEST: "IMPORT_RESTORE_REQUEST",
  IMPORT_RESTORE_SUCCESS: "IMPORT_RESTORE_SUCCESS",
  IMPORT_RESTORE_FAILURE: "IMPORT_RESTORE_FAILURE",

  IMPORT_CONFIRM_REQUEST: "IMPORT_CONFIRM_REQUEST",
  IMPORT_CONFIRM_SUCCESS: "IMPORT_CONFIRM_SUCCESS",
  IMPORT_CONFIRM_FAILURE: "IMPORT_CONFIRM_FAILURE",

  IMPORT_RUNNING_REQUEST: "IMPORT_RUNNING_REQUEST",
  IMPORT_RUNNING_SUCCESS: "IMPORT_RUNNING_SUCCESS",
  IMPORT_RUNNING_FAILURE: "IMPORT_RUNNING_FAILURE",

  IMPORT_MESSAGES_REQUEST: "IMPORT_MESSAGES_REQUEST",
  IMPORT_MESSAGES_SUCCESS: "IMPORT_MESSAGES_SUCCESS",
  IMPORT_MESSAGES_FAILURE: "IMPORT_MESSAGES_FAILURE",

  IMPORT_MARQUAGES_REQUEST: "IMPORT_MARQUAGES_REQUEST",
  IMPORT_MARQUAGES_SUCCESS: "IMPORT_MARQUAGES_SUCCESS",
  IMPORT_MARQUAGES_FAILURE: "IMPORT_MARQUAGES_FAILURE",

  IMPORT_REWORKED_REQUEST: "IMPORT_REWORKED_REQUEST",
  IMPORT_REWORKED_SUCCESS: "IMPORT_REWORKED_SUCCESS",
  IMPORT_REWORKED_FAILURE: "IMPORT_REWORKED_FAILURE",

  GET_IMPORT_WARNINGS_REQUEST: "GET_IMPORT_WARNINGS_REQUEST",
  GET_IMPORT_WARNINGS_SUCCESS: "GET_IMPORT_WARNINGS_SUCCESS",
  GET_IMPORT_WARNINGS_FAILURE: "GET_IMPORT_WARNINGS_FAILURE",

  IMPORT_STATE_REQUEST: "IMPORT_STATE_REQUEST",
  IMPORT_STATE_SUCCESS: "IMPORT_STATE_SUCCESS",
  IMPORT_STATE_FAILURE: "IMPORT_STATE_FAILURE",

  REPORT_DOWNLOAD_REQUEST: "REPORT_DOWNLOAD_REQUEST",
  REPORT_DOWNLOAD_SUCCESS: "REPORT_DOWNLOAD_SUCCESS",
  REPORT_DOWNLOAD_FAILURE: "REPORT_DOWNLOAD_FAILURE",

  LAUNCH_IMPORT_FROM_FACTURATION_REQUEST:
    "LAUNCH_IMPORT_FROM_FACTURATION_REQUEST",
  LAUNCH_IMPORT_FROM_FACTURATION_SUCCESS:
    "LAUNCH_IMPORT_FROM_FACTURATION_SUCCESS",
  LAUNCH_IMPORT_FROM_FACTURATION_ERROR: "LAUNCH_IMPORT_FROM_FACTURATION_ERROR",

  LAUNCH_INTERVENTION_IMPORT_FROM_FACTURATION_REQUEST:
    "LAUNCH_INTERVENTION_IMPORT_FROM_FACTURATION_REQUEST",
  LAUNCH_INTERVENTION_IMPORT_FROM_FACTURATION_SUCCESS:
    "LAUNCH_INTERVENTION_IMPORT_FROM_FACTURATION_SUCCESS",
  LAUNCH_INTERVENTION_IMPORT_FROM_FACTURATION_FAILURE:
    "LAUNCH_INTERVENTION_IMPORT_FROM_FACTURATION_FAILURE",

  GET_INTERVENTION_USERS_REQUEST: "GET_INTERVENTION_USERS_REQUEST",
  GET_INTERVENTION_USERS_SUCCESS: "GET_INTERVENTION_USERS_SUCCESS",
  GET_INTERVENTION_USERS_FAILURE: "GET_INTERVENTION_USERS_FAILURE",

  ADD_INTERVENTION_USERS_REQUEST: "ADD_INTERVENTION_USERS_REQUEST",
  ADD_INTERVENTION_USERS_SUCCESS: "ADD_INTERVENTION_USERS_SUCCESS",
  ADD_INTERVENTION_USERS_FAILURE: "ADD_INTERVENTION_USERS_FAILURE",

  EDIT_INTERVENTION_USERS_REQUEST: "EDIT_INTERVENTION_USERS_REQUEST",
  EDIT_INTERVENTION_USERS_SUCCESS: "EDIT_INTERVENTION_USERS_SUCCESS",
  EDIT_INTERVENTION_USERS_FAILURE: "EDIT_INTERVENTION_USERS_FAILURE",

  DELETE_INTERVENTION_USERS_REQUEST: "DELETE_INTERVENTION_USERS_REQUEST",
  DELETE_INTERVENTION_USERS_SUCCESS: "DELETE_INTERVENTION_USERS_SUCCESS",
  DELETE_INTERVENTION_USERS_FAILURE: "DELETE_INTERVENTION_USERS_FAILURE",

  CANCEL_CONFIG_IMPORT_REQUEST: "CANCEL_CONFIG_IMPORT_REQUEST",

  CLEAR_ERROR: "IMPORT_CLEAR_ERROR",

  CLEAR: "IMPORT_CLEAR",

  CLEAR_INTERVENTION: "CLEAR_INTERVENTION",
};

export default importConstants;
