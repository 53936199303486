import React, { useEffect, useState } from "react";
import { textFilter } from "react-bootstrap-table2-filter";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import { withTranslation } from "react-i18next";
import BootstrapTable from "react-bootstrap-table-next";
import SvgCroixNoir from "../SvgComponents/croixNoir";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Input } from "@mui/material";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Alert from "@mui/material/Alert";
import Chip from "../_components/Chip";

const bodyProperties = ["reportedby", "assetnum", "parent", "intitule"];

const Popup = ({ open, toggle, t, onRegister, onChange, body }) => {
  return (
    <Modal
      isOpen={open}
      toggle={toggle}
      backdrop
      size="lg"
      className={"maximo-popup"}
    >
      <ModalHeader toggle={toggle}>
        {t("stock_importation.importation_report.action_creation")}
      </ModalHeader>
      <ModalBody>
        <div className="body-container">
          {bodyProperties.map((it, index) => (
            <div
              className={`input-box ${
                index === 0 || index === 1 ? "disable" : "enable"
              }`}
            >
              <span>{t(`maximo.${it}`)}</span>
              <Input
                placeholder={t(`maximo.${it}`)}
                onChange={(e) => onChange(it, e.target.value)}
                defaultValue={body[it]}
                disabled={index === 0 || index === 1}
              />
            </div>
          ))}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={toggle}>
          {t("all.button.cancel")}
        </Button>
        <Button color="primary" onClick={onRegister}>
          {t("all.button.register")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const MaximoBT = ({ t, pdis, user }) => {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [body, setBody] = useState({
    intitule: null,
    parent: null,
    assetnum: pdis?.fetchedPdi?.general?.reference,
    reportedby: user?.userName,
  });
  const [headers, setHeaders] = useState(null);
  const [error, setError] = useState("");
  const [chipMessage, setChipMessage] = useState(null);
  const { maximo } = window["runConfig"];
  const { enabled, username, password, url } = maximo;

  const toggle = () => {
    setOpen(!open);
  };

  const handleChange = (key, value) => {
    const copy = _.cloneDeep(body);
    copy[key] = value;
    setBody(copy);
  };

  const register = () => {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Basic ${btoa(`${username}:${password}`)}`
    );
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };
    try {
      fetch(`${url}eau/bt-create?json=${JSON.stringify(body)}`, requestOptions)
        .then((response) => response.text())
        .then((response) => {
          getMaximoList(headers);
          setChipMessage("Ajout MaximoBT réussi !");
        })
        .catch((error) => {
          console.error(error);
          setError(error.toString());
        });
    } catch (e) {
      console.log("error: ", e);
      setError(e.toString());
    }

    toggle();
  };

  const getMaximoList = (header) => {
    const requestOptions = {
      method: "GET",
      headers: header,
    };

    try {
      fetch(`${url}eau/bt-consult?assetnum=${body.assetnum}`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          const json = JSON.parse(result);
          setData(json.response);
        })
        .catch((error) => {
          console.error(error);
          setError(error.toString());
        });
    } catch (e) {
      setError(e.toString());
    }
  };

  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Basic ${btoa(`${username}:${password}`)}`
    );
    myHeaders.append("Content-Type", "application/json");
    getMaximoList(myHeaders);
    setHeaders(myHeaders);
  }, []);

  useEffect(() => {
    setTimeout(() => setError(null), 30000);
  }, [error]);

  const columns = [
    {
      dataField: "numero",
      sort: true,
      text: t("all.text.number"),
      classes: "crystalList-column",
      filter: textFilter({
        placeholder: t("all.text.number"),
        delay: 500,
      }),
    },
    {
      dataField: "statut",
      sort: true,
      text: t("import_silex.column_header.round_status"),
      classes: "crystalList-column",
      filter: textFilter({
        placeholder: t("import_silex.column_header.round_status"),
        delay: 500,
      }),
    },
    {
      dataField: "description",
      sort: true,
      text: t("import_silex.column_label.description"),
      classes: "crystalList-column",
      filter: textFilter({
        placeholder: t("import_silex.column_label.description"),
        delay: 500,
      }),
    },
  ];
  return enabled ? (
    <div className={"maximo-container"}>
      {error && (
        <Alert severity="error" style={{ marginBottom: "10px" }}>
          {error}
        </Alert>
      )}
      <Popup
        open={open}
        toggle={toggle}
        t={t}
        onChange={handleChange}
        onRegister={register}
        body={body}
      />
      <div className={"top"}>
        <div className={"title"}>
          <h3>Maximo BT</h3>
        </div>
        <div className={"add-button"} onClick={toggle}>
          <SvgCroixNoir height={"2em"} />
        </div>
      </div>
      <div className={"maximo-table"}>
        {data.length > 0 ? (
          <BootstrapTable
            id={"pdi-tab"}
            keyField="numero"
            bootstrap4
            bordered={false}
            hover
            data={data}
            columns={columns}
            filter={filterFactory()}
            pagination={paginationFactory()}
          />
        ) : (
          <Alert severity="warning">{t("maximo.none")}</Alert>
        )}
      </div>
      {chipMessage && <Chip message={chipMessage} />}
    </div>
  ) : null;
};

function mapStateToProps(state) {
  const { pdis, authentication } = state;
  const { user } = authentication;
  return {
    pdis,
    user,
  };
}

const mapping = connect(mapStateToProps)(MaximoBT);
const connectedComponent = withRouter(mapping);
const tr = withTranslation()(connectedComponent);
export default tr;
