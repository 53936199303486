import React, {
  ChangeEvent,
  Reducer,
  useEffect,
  useReducer,
  useState,
} from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import TemplateCreationPart from "./TemplateCreationPart";
import TagPart from "./TagPart";
import { locales } from "../_interfaces/reducers";
import { localeActions, rndTemplateActions } from "../_actions";
import { Button } from "reactstrap";
import { RouteComponentProps, StaticContext } from "react-router";
import {
  TemplateCreationFormType,
  TemplateDto,
  TemplatesState,
  TourneeCreationFormType,
} from "../_interfaces/TourneeCreationTypes";
import { AvForm, AvField } from "availity-reactstrap-validation";
import ErrorBand from "../Bands/Error";
import SuccessBand from "../Bands/Success";
import LoadingBand from "../Bands/Loading";
import { template } from "lodash";

const TemplateCreationPage: React.FC<
  {
    rndTemplate: TemplatesState;
    locales: locales;
    dispatch: Function;
  } & RouteComponentProps<
    { GestionnaireId: string },
    StaticContext,
    {
      from?: string;
      template?: TemplateDto;
      form: {
        tourneeForm: TourneeCreationFormType;
        listMeter: { [meterId: number]: string };
      };
      type?: "subscriber" | "meter";
    }
  > &
    WithTranslation
> = ({ rndTemplate, location, locales, dispatch, history, t }) => {
  const [focusedLine, setFocusedLine] = useState<number>(0);
  const [loadingMessage, setLoadingMessage] = useState<string>("");
  const [isLoaded, setIsloaded] = useState<boolean>(false);
  const [isPageDisabled, setIsPageDisabled] = useState<boolean>(false);

  const templateFormReducer = (
    state: TemplateCreationFormType,
    action:
      | { type: keyof Omit<TemplateCreationFormType, "id">; text: string }
      | { type: "default"; template: TemplateCreationFormType }
  ) => {
    if (action.type === "default") {
      return { ...action.template };
    } else {
      return { ...state, [action.type]: action.text };
    }
  };

  const [templateForm, dispatchTemplateForm] = useReducer<
    Reducer<
      TemplateCreationFormType,
      | { type: keyof Omit<TemplateCreationFormType, "id">; text: string }
      | { type: "default"; template: TemplateCreationFormType }
    >
  >(templateFormReducer, {
    name: "",
    line1: "",
    line2: "",
    line3: "",
    line4: "",
    line5: "",
    defaultInfo: "",
    type: location.state.type || "subscriber",
  });

  const handleCancel = () => {
    history.push(location.state.from!, { form: location.state.form });
  };

  const convertFormLineToLineDto = (line: string) => {
    return line.replaceAll(/#\{\(.*?\)\}#/g, (correspondance: string) => {
      return correspondance.replace('#{({"id":', "{{").replace(/,.*$/, "}}");
    });
  };

  const submit = () => {
    const templateDto: TemplateDto = {
      id: templateForm.id,
      name: templateForm.name,
      lines: [
        convertFormLineToLineDto(templateForm.line1.trim()),
        convertFormLineToLineDto(templateForm.line2.trim()),
        convertFormLineToLineDto(templateForm.line3.trim()),
        convertFormLineToLineDto(templateForm.line4.trim()),
        templateForm.type === "subscriber"
          ? convertFormLineToLineDto(templateForm.line5.trim())
          : "",
      ],
      defaultInfo:
        templateForm.defaultInfo.trim() != ""
          ? templateForm.defaultInfo
          : t("tournee_creation.placeholder.default_info"),
      type: templateForm.type,
    };
    if (templateDto.id) {
      setLoadingMessage(t("tournee_creation.loading_band.template_update"));
      dispatch(rndTemplateActions.updateTemplate(templateDto));
    } else {
      setLoadingMessage(t("tournee_creation.loading_band.template_creation"));
      dispatch(rndTemplateActions.createTemplate(templateDto));
    }
  };

  const findSelectedTagIds = () => {
    const listSelectedTagIds: number[] = [];
    const lineConcat = templateForm.line1
      .concat(templateForm.line2)
      .concat(templateForm.line3)
      .concat(templateForm.line4)
      .concat(templateForm.line5);
    lineConcat.replaceAll(/#\{\(.*?\)\}#/g, (correspondance: string) => {
      const tagId = correspondance.replace('#{({"id":', "").replace(/,.*$/, "");
      listSelectedTagIds.push(Number(tagId));
      return `{{${tagId}}}`;
    });
    return listSelectedTagIds;
  };

  const convertDtoLineToFormLine = (line: string) => {
    return line.replaceAll(/\{\{\d+\}\}/g, (correspondance: string) => {
      const id = Number(correspondance.substring(2, correspondance.length - 2));
      const tag = rndTemplate.tags!.find((el) => el.id === id)!;
      return `#{({"id":${tag.id},"value":"${tag.name}","size":${tag.size}})}#`;
    });
  };

  const getErrorBandOptions = (error: {
    translationId: string;
    errorValue: any;
  }) => {
    switch (error.translationId) {
      case "tag_not_found":
        return {
          tag:
            (rndTemplate.tags &&
              rndTemplate.tags.find((tag) => tag.id == error.errorValue)) ||
            error.errorValue,
        };
      case "tag_still_linked":
        return {
          templates: error.errorValue.join(", "),
        };
      case "errors_in_tag":
        return {
          errors: error.errorValue
            .map((el: string) => t(`tournee_creation.input_error.${el}`))
            .join(", "),
        };
    }
  };

  useEffect(() => {
    dispatch(localeActions.load());
    dispatch(rndTemplateActions.getAllTags());
    dispatch(rndTemplateActions.getAllTemplates());
    return () => {
      dispatch(rndTemplateActions.clearError());
      dispatch(rndTemplateActions.clearSuccess());
    };
  }, []);

  useEffect(() => {
    if (
      location.state?.template &&
      rndTemplate.tags &&
      location.state?.template.id !== templateForm.id
    ) {
      const newTemplate: TemplateCreationFormType = {
        id: location.state.template.id,
        name: location.state.template.name,
        line1: convertDtoLineToFormLine(location.state.template.lines[0]),
        line2: convertDtoLineToFormLine(location.state.template.lines[1]),
        line3: convertDtoLineToFormLine(location.state.template.lines[2]),
        line4: convertDtoLineToFormLine(location.state.template.lines[3]),
        line5: convertDtoLineToFormLine(location.state.template.lines[4]),
        defaultInfo: location.state.template.defaultInfo,
        type: location.state.template.type,
      };
      dispatchTemplateForm({ type: "default", template: newTemplate });
      setIsloaded(true);
    }

    if (
      rndTemplate.loading ||
      (rndTemplate.success &&
        (rndTemplate.success === "templateCreation" ||
          rndTemplate.success === "templateUpdate"))
    ) {
      setIsPageDisabled(true);
    } else {
      setIsPageDisabled(false);
    }
  }, [rndTemplate]);

  useEffect(() => {
    if (rndTemplate.success) {
      const timeout = setTimeout(() => {
        dispatch(rndTemplateActions.clearSuccess());
        if (
          rndTemplate.success === "templateCreation" ||
          rndTemplate.success === "templateUpdate"
        ) {
          dispatch(rndTemplateActions.clearError());
          handleCancel();
        }
      }, 5000);
      return () => clearTimeout(timeout);
    }
  }, [rndTemplate.success]);

  useEffect(() => {
    if (rndTemplate.error) {
      const timeout = setTimeout(() => {
        dispatch(rndTemplateActions.clearError());
      }, 5000);
      return () => clearTimeout(timeout);
    }
  }, [rndTemplate.error]);

  return (
    <div className="col-12">
      {rndTemplate.error && (
        <ErrorBand
          message={
            typeof rndTemplate.error === "string"
              ? rndTemplate.error
              : t(
                  `tournee_creation.error_band.${rndTemplate.error.translationId}`,
                  {
                    ...getErrorBandOptions(rndTemplate.error),
                  }
                )
          }
        />
      )}
      {rndTemplate.success && (
        <SuccessBand
          message={t(`tournee_creation.success.${rndTemplate.success}`, {
            successTarget: rndTemplate.successTarget!.name,
          })}
        />
      )}
      {rndTemplate.loading && loadingMessage !== "" && (
        <LoadingBand message={loadingMessage} />
      )}
      <div className="table-info-container col-12">
        <div className="template-header-container">
          <h2>{t("tournee_creation.container_label.template")}</h2>
          <div className="template-buttons-container">
            <Button
              className={"import-silex-selection-button selected cancel"}
              disabled={isPageDisabled}
              onClick={handleCancel}
            >
              {t("all.button.cancel")}
            </Button>
            <Button
              className={"import-silex-selection-button selected"}
              disabled={isPageDisabled}
              form="myForm"
            >
              {t("all.button.register")}
            </Button>
          </div>
        </div>

        <div className="template-main-container">
          <TemplateCreationPart
            tags={rndTemplate.tags || []}
            dispatchFormChange={dispatchTemplateForm}
            templateForm={templateForm}
            focusedLine={focusedLine}
            setFocusedLine={setFocusedLine}
            isLoaded={isLoaded}
            submit={submit}
            location={location}
            disabled={isPageDisabled}
          />
          <TagPart
            tags={rndTemplate.tags || []}
            locales={locales}
            setLoadingMessage={setLoadingMessage}
            loading={rndTemplate.loading === true}
            findSelectedTagIds={findSelectedTagIds}
            disabled={isPageDisabled}
          />
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state: any) {
  const { rndTemplate, locales } = state;
  return {
    rndTemplate,
    locales,
  };
}

const connectedTemplateCreationPage =
  connect(mapStateToProps)(TemplateCreationPage);
const tr = withTranslation()(connectedTemplateCreationPage);

export default tr;
