import React, { ReactElement, useEffect } from "react";
import { UncontrolledPopover, PopoverHeader, PopoverBody } from "reactstrap";
import { withTranslation } from "react-i18next";

const StepPopover: React.FC<{
  id: string;
  target: string;
  open: boolean;
  onClose: () => void;
  confirm?: boolean;
  setConfirm?: React.Dispatch<React.SetStateAction<boolean>>;
  withTwoSteps?: boolean;
  t: Function;
  permission?: boolean;
  action: (e: any) => void;
  title: string;
  warningText?: any;
  warningElement?: Element | ReactElement;
  confirmText: string;
  confirmElement?: Element | ReactElement;
  confirmButtonText?: string;
  onlyButton?: boolean;
  fade?: boolean;
  threatLevel?: string;
}> = ({
  id,
  target,
  open,
  onClose,
  confirm,
  setConfirm,
  withTwoSteps,
  t,
  permission = false,
  action,
  title,
  warningText,
  warningElement,
  confirmText,
  confirmButtonText,
  confirmElement,
  onlyButton,
  fade = true,
  threatLevel,
}) => {
  useEffect(() => {
    window.addEventListener("click", (e: any) => {
      if (e.target && e.target.id !== id) {
        onClose();
        setConfirm && setConfirm(false);
      }
    });
    return window.removeEventListener("click", (e: any) => {
      if (e.target && e.target.id !== id) {
        onClose();
        setConfirm && setConfirm(false);
      }
    });
  }, []);

  const threatColor = (): string => {
    switch (threatLevel) {
      case "warning":
        return "orange";
      default:
        return "red";
    }
  };

  return (
    <UncontrolledPopover
      id={id}
      target={target}
      fade={fade}
      trigger="legacy"
      placement="auto"
      isOpen={open}
    >
      <div style={{ pointerEvents: "none" }}>
        {permission && (
          <div
            style={{
              width: "100%",
            }}
          >
            <PopoverHeader
              style={{
                display: "flex",
                alignItems: "center",
                color: threatColor(),
              }}
            >
              <p
                style={{
                  fill: threatColor(),
                  margin: "0 10px",
                  fontSize: "20px",
                }}
              >
                &#x26A0;
              </p>
              <p style={{ margin: 0 }}>{title}</p>
            </PopoverHeader>
            <PopoverBody>
              {confirm && (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        width: 9,
                        height: 9,
                        borderRadius: 100,
                        border: `1px solid ${threatColor()}`,
                        background: threatColor(),
                        margin: 10,
                      }}
                    />
                    <div
                      style={{
                        width: 9,
                        height: 9,
                        borderRadius: 100,
                        border: `1px solid ${threatColor()}`,
                        margin: 10,
                        background: threatColor(),
                      }}
                    />
                  </div>
                  {warningText && warningText}
                  {warningElement && warningElement}
                  <div
                    style={{
                      margin: "10px 0",
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      pointerEvents: "auto",
                    }}
                  >
                    <button
                      type="button"
                      className="btn btn-outline-dark"
                      onClick={(e) => {
                        e.stopPropagation();
                        onClose();
                        setConfirm && setConfirm(false);
                      }}
                    >
                      {t("all.button.cancel")}
                    </button>
                    {action && (
                      <button
                        type="button"
                        className="btn btn-danger"
                        style={{ margin: "0 10px" }}
                        onClick={action}
                      >
                        {confirmButtonText || t("all.button.delete")}
                      </button>
                    )}
                  </div>
                </>
              )}
              {!confirm && (
                <>
                  {withTwoSteps && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          width: 9,
                          height: 9,
                          borderRadius: 100,
                          border: `1px solid ${threatColor()}`,
                          background: threatColor(),
                          margin: 10,
                        }}
                      />
                      <div
                        style={{
                          width: 9,
                          height: 9,
                          borderRadius: 100,
                          border: `1px solid ${threatColor()}`,
                          margin: 10,
                        }}
                      />
                    </div>
                  )}
                  <p style={{ margin: "10px 0", textAlign: "center" }}>
                    {confirmText}
                  </p>
                  {confirmElement && confirmElement}
                  <div
                    style={{
                      margin: "10px 0",
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      pointerEvents: "auto",
                    }}
                  >
                    {!onlyButton ? (
                      <>
                        <button
                          type="button"
                          className="btn btn-outline-dark"
                          onClick={(e) => {
                            e.stopPropagation();
                            onClose();
                          }}
                        >
                          {t("all.button.cancel")}
                        </button>
                        <button
                          type="button"
                          className={`btn btn-${threatLevel || "danger"}`}
                          style={{ margin: "0 10px" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            if (withTwoSteps) {
                              setConfirm && setConfirm(true);
                            } else {
                              action(e);
                            }
                          }}
                        >
                          {withTwoSteps
                            ? t("all.button.next")
                            : confirmButtonText || t("all.button.delete")}
                        </button>
                      </>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-outline-dark"
                        onClick={(e) => {
                          e.stopPropagation();
                          onClose();
                        }}
                      >
                        {t("all.text.ok")}
                      </button>
                    )}
                  </div>
                </>
              )}
            </PopoverBody>
          </div>
        )}
      </div>
    </UncontrolledPopover>
  );
};

const tr = withTranslation()(StepPopover);

export default tr;
