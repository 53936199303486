import React, { MouseEventHandler } from "react";
import SvgCroixNoir from "../SvgComponents/croixNoir";
import { UncontrolledTooltip } from "reactstrap";

const Tag: React.FC<{
  key: string;
  name: string;
  onClick: MouseEventHandler<HTMLDivElement>;
  onDelete?: () => void;
  isSelected?: boolean;
  contentEditable?: boolean;
  deleteDisabled?: boolean;
  buttonPopoverText?: string;
  popoverText?: string;
  disabled?: boolean;
}> = ({
  key,
  name,
  onClick,
  onDelete,
  buttonPopoverText,
  isSelected = false,
  contentEditable = false,
  deleteDisabled = false,
  popoverText,
  disabled = false,
}) => {
  const tagId = `tag-id-${key}`;
  const buttonId = `tag-delete-button-${key}`;

  return (
    <>
      <div
        id={tagId}
        onClick={disabled ? () => null : onClick}
        className={
          "tag" +
          (isSelected ? " selected" : "") +
          (disabled ? " disabled" : "")
        }
        contentEditable={contentEditable}
      >
        <div className="tag-name">{name}</div>
        {onDelete && (
          <>
            <div
              id={buttonId}
              className={
                "delete-button" +
                (deleteDisabled || disabled ? " disabled" : "")
              }
              onClick={(e) => {
                if (!(deleteDisabled || disabled)) {
                  e.stopPropagation();
                  onDelete();
                }
              }}
            >
              <SvgCroixNoir
                height="1.4em"
                fill={
                  deleteDisabled || disabled
                    ? "#acabab"
                    : isSelected
                    ? "#FFF"
                    : "#000"
                }
              />
            </div>
            {deleteDisabled && buttonPopoverText && !disabled && (
              <UncontrolledTooltip placement="top" target={buttonId}>
                {buttonPopoverText}
              </UncontrolledTooltip>
            )}
          </>
        )}
      </div>
      {disabled && popoverText && (
        <UncontrolledTooltip placement="top" target={tagId}>
          {popoverText}
        </UncontrolledTooltip>
      )}
    </>
  );
};

export default Tag;
