import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledAlert,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap";
import randex from "randexp";
import BootstrapTable from "react-bootstrap-table-next";
import MaterialSelect from "@material-ui/core/Select";
import moment from "moment";
import _ from "lodash";
import Select from "react-select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { withTranslation } from "react-i18next";
import {
  alertActions,
  gestionnaireActions,
  locationActions,
  userActions,
} from "../_actions";
import PasswordChange from "./PasswordChange";
import InformationUserChange from "./InformationUserChange";
import GestionUtilisateurVert from "../SvgComponents/GestionUtilisateurBlanc";
import Lock from "../SvgComponents/Lock";
import EditionBleu from "../SvgComponents/EditionBleu";
import { CrystalPermission, User } from "../_entities/user";
import BurgerHeader from "../SvgComponents/BurgerHeader2";
import { REGEXP8 } from "../_helpers";
import { locations } from "../_interfaces/reducers";

import confirm from "../_components";

import LoadingBand from "../Bands/Loading";
import ErrorBand from "../Bands/Error";

import SvgPdiVert from "../SvgComponents/PdiVert";
import profilActions from "../_actions/profil.actions";
import PoubelleBleue from "../SvgComponents/PoubelleBleu";
import SvgAjoutBleu from "../SvgComponents/AjoutBleu";
import SvgCleVerte from "../SvgComponents/CleVert";

import SwitchButton from "../_components/SwitchButton";

const UserComponent = (props) => {
  const {
    gestionnaire,
    users,
    locations,
    user,
    history,
    dispatch,
    match,
    usertoEdit,
    alert,
    profil,
    t,
    authentication,
  } = props;

  const [editionOpen, setEditionOpen] = useState(false);
  const [passwordOpen, setPasswordOpen] = useState(false);
  const [passwordUpdated, setPasswordUpdated] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [oldPermissions, setOldPermissions] = useState([]);
  const [data, setData] = useState([]);
  const [gestionnaireData, setGestionnaireData] = useState([]);
  const [userPerm, setUserPerm] = useState([]);
  const [deleteList, setDeleteList] = useState([]);
  const [generate, setGenerate] = useState(null);
  const [actualUser, setActualUser] = useState(users?.fetchedUser);
  const [actualPerm, setActualPerm] = useState({
    code_loc: "",
    profil_id: "",
  });

  useEffect(() => {
    console.log(user, match.params.userId);
    if (
      user.role.name === "USER" &&
      match.params.userId !== user.id.toString()
    ) {
      history.push("/forbidden");
    }
    dispatch(profilActions.getAll());
    dispatch(userActions.get(match.params.userId));
    dispatch(locationActions.getAll("STOCK"));
    dispatch(gestionnaireActions.getAllGestionnaires(match.params.userId));
    return () => {
      dispatch(locationActions.clear());
    };
  }, [match.params.userId]);

  useEffect(() => {
    setData(locations.items);
  }, [locations]);

  useEffect(() => {
    setActualUser(users.fetchedUser);
  }, [users]);

  useEffect(() => {
    gestionnaire.gestionnaires &&
      setGestionnaireData(gestionnaire.gestionnaires);
  }, [gestionnaire.gestionnaires]);

  const edit = () => {
    dispatch(
      userActions.clear(
        users.fetchedUser,
        users.permissions,
        users.lastUpdatedInfo
      )
    );
    setEditionOpen(!editionOpen);
    if (!editionOpen) {
      dispatch(alertActions.clear());
    }
  };

  const editPassword = () => {
    dispatch(
      userActions.clear(
        users.fetchedUser,
        users.permissions,
        users.lastUpdatedInfo
      )
    );
    setPasswordOpen(!passwordOpen);
    setGenerate(undefined);
    if (!passwordOpen) {
      dispatch(alertActions.clear());
    }
  };

  const couldEdit = () => {
    if (
      user.role.userName === users.fetchedUser.role.userName &&
      user.id === users.fetchedUser.id
    ) {
      return true;
    }

    if (user.role.name === "DIOPTASE") {
      return true;
    }

    if (
      user.role.name === "SUPERADMIN" &&
      (users.fetchedUser.role.name === "ADMIN" ||
        users.fetchedUser.role.name === "USER")
    ) {
      return true;
    }

    if (user.role.name === "ADMIN" && users.fetchedUser.role.name === "USER") {
      return true;
    }

    return false;
  };

  const couldBeEdit = () => {
    return ["DIOPTASE", "SUPERADMIN", "ADMIN"].includes(user.role.name);
  };

  const block = () => {
    dispatch(alertActions.clear());
    dispatch(
      userActions.blockOrUnblockInEditionContext(users.fetchedUser, user)
    );
  };

  const generateFunc = () => {
    setPasswordOpen(!passwordOpen);
    setGenerate(generatePassword());
    dispatch(alertActions.clear());
  };

  const generatePassword = () => {
    return randex.randexp(REGEXP8);
  };

  async function reinit() {
    const result = await confirm({
      title: (
        <Fragment>
          <strong>Réinitialiser le mot de passe </strong>
        </Fragment>
      ),
      message:
        "Réinitialiser le mot de passe de l'utilisateur en utilisant la procédure de réinitialisation de mot de passe ? ",
      confirmText: "Réinitialiser",
      confirmColor: "danger",
      cancelColor: "primary",
      cancelText: "Abandonner",
    });
    if (result) {
      dispatch(
        userActions.reinitPassword(
          users.fetchedUser,
          users.permissions,
          users.lastUpdatedInfo
        )
      );
    }
  }

  const handleSubmit = () => {
    if (actualPerm.code_loc !== "" && actualPerm.profil_id !== "") {
      dispatch(
        profilActions.addProfilToUser([actualPerm], users.fetchedUser.id)
      );
    }
    if (deleteList.length > 0) {
      dispatch(
        profilActions.deleteProfilLinks(deleteList, users.fetchedUser.id)
      );
    }
    setUserPerm([]);
    setDeleteList([]);
    setOpenModal(!openModal);
  };

  const deleteFunc = (row) => {
    const newList = _.cloneDeep(deleteList);
    newList.push(row.locationId);
    setDeleteList(newList);
    setOpenModal(!openModal);
  };

  const openModalFunc = () => {
    setOpenModal(!openModal);
    setUserPerm([]);
    setDeleteList([]);
  };

  const handleChange = (e, type) => {
    const newPerm = _.cloneDeep(actualPerm);
    if (type === "loc") {
      newPerm.code_loc = e.value;
    } else {
      newPerm.profil_id = e.value;
    }
    setActualPerm(newPerm);
  };

  const updateProfil = (row, value) => {
    const newPerm = {
      profil_id: row.props.value,
      code_loc: value.profil.locationCode,
    };
    dispatch(profilActions.addProfilToUser([newPerm], users.fetchedUser.id));
  };

  const handleAccess = (row, checked) => {
    const listPerm = [];
    if (!checked) {
      listPerm.push({
        userId: users.fetchedUser.id,
        gestionnaireId: row.id,
      });
      dispatch(
        profilActions.addGestionnairePermission(listPerm, match.params.userId)
      );
    } else {
      listPerm.push(row.access);
      dispatch(
        profilActions.deleteGestionnairePermission(
          listPerm,
          match.params.userId
        )
      );
    }
  };

  const handledeviceDeletionPermission = (row, checked) => {
    if (row.access) {
      const copyRow = _.cloneDeep(row.access);
      copyRow.deviceDeletionAllowed = !row.access.deviceDeletionAllowed;
      dispatch(
        profilActions.switchDeviceDeletionPermission(
          copyRow,
          match.params.userId
        )
      );
    } else {
      dispatch(
        profilActions.addGestionnairePermission(
          [
            {
              userId: users.fetchedUser.id,
              gestionnaireId: row.id,
              deviceDeletionAllowed: true,
            },
          ],
          match.params.userId
        )
      );
    }
  };
  let datas = [];
  if (locations.items && users.fetchedUser) {
    datas = locations.items
      .map((el) => ({
        name: el.name,
        locationId: el.id,
        profil:
          actualUser?.profils?.find(
            (profil) => profil.locationCode === el.code
          ) || el.code,
      }))
      .filter((el) => el.name !== "STOCK" && el.profil.locationCode);
  }
  const profilColumns = [
    {
      dataField: "name",
      text: t("all.location.location"),
    },
    {
      dataField: "profil",
      editable: true,
      text: t("all.user.profile_plural"),
      formatter: (cellContent, row) => {
        return (
          <Fragment>
            <FormControl>
              {couldBeEdit() ? (
                <MaterialSelect
                  onChange={(event, value) => updateProfil(value, row)}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  defaultValue={cellContent.profil ? cellContent.profil.id : 0}
                  value={
                    userPerm.find(
                      (el) =>
                        el.code_loc === cellContent.profil ||
                        el.code_loc === cellContent.profil.id
                    )
                      ? userPerm.find(
                          (el) =>
                            el.code_loc === cellContent.profil ||
                            el.code_loc === cellContent.profil.id
                        ).profil_id
                      : cellContent.profil
                      ? cellContent.profil.id
                      : 0
                  }
                >
                  {profil.items &&
                    profil.items.map((el) => (
                      <MenuItem value={el.id}>{el.name}</MenuItem>
                    ))}
                </MaterialSelect>
              ) : (
                <span>{cellContent.profil ? cellContent.profil.name : ""}</span>
              )}
            </FormControl>
          </Fragment>
        );
      },
    },
  ];

  const gestionnaireColumns = [
    {
      dataField: "name",
      text: t("all.text.name"),
    },
    {
      dataField: "nbPhone",
      editable: true,
      text: t("user.text.nb.phone"),
    },
    {
      dataField: "nbRound",
      editable: true,
      text: t("user.text.nb.deliveries"),
    },
    {
      dataField: "access",
      editable: true,
      text: t("user.text.authorization"),
      formatter: (cellContent, row) => {
        const checked = row.access;
        return (
          <div style={{ marginLeft: "20px" }}>
            {couldBeEdit() ? (
              <SwitchButton
                switchClass="synchroSwitch"
                checked={checked}
                handleChange={() => handleAccess(row, checked)}
                onColor="#409F95"
                offColor="#999999"
                offHandleColor="#CCCCCC"
                onHandleColor="#31C6B3"
                disabled={gestionnaire.loading}
              />
            ) : (
              <div
                style={{
                  height: "20px",
                  width: "20px",
                  backgroundColor: checked ? "#31C6B3" : "#dc3545",
                  borderRadius: "50%",
                }}
              />
            )}
          </div>
        );
      },
    },
    {
      dataField: "deletionPermission",
      editable: true,
      text: t("edit_device.main_title.delete_phone"),
      formatter: (cellContent, row) => {
        const checked = row.access ? row.access.deviceDeletionAllowed : false;
        return (
          <div style={{ marginLeft: "20px" }}>
            {couldBeEdit() ? (
              <SwitchButton
                switchClass="synchroSwitch"
                checked={checked}
                handleChange={() =>
                  handledeviceDeletionPermission(row, checked)
                }
                onColor="#409F95"
                offColor="#999999"
                offHandleColor="#CCCCCC"
                onHandleColor="#31C6B3"
              />
            ) : (
              <div
                style={{
                  height: "20px",
                  width: "20px",
                  backgroundColor: checked ? "#31C6B3" : "#dc3545",
                  borderRadius: "50%",
                }}
              />
            )}
          </div>
        );
      },
    },
  ];

  const roleList = ["DIOPTASE", "SUPERADMIN", "ADMIN"];
  if (roleList.includes(user.role.name)) {
    const delColumn = {
      dataField: "",
      editable: true,
      text: "",
      formatter: (cellContent, row) => {
        return (
          <Fragment>
            <div
              id={`profil-remove${row.id}`}
              className="clickable round"
              role="presentation"
              onClick={(e) => deleteFunc(row)}
              style={{ float: "right", marginRight: "20px" }}
            >
              <PoubelleBleue height="1em" width="1em" />
              <UncontrolledTooltip
                placement="bottom"
                target={`profil-remove${row.id}`}
              >
                {" "}
                {t("user.text.remove_profile")}
              </UncontrolledTooltip>
            </div>
          </Fragment>
        );
      },
    };
    profilColumns.push(delColumn);
  }
  const locOptions =
    locations &&
    locations.items &&
    locations.items
      .map((el) => ({ value: el.code, label: el.name }))
      .filter((el) => el.value !== "STOCK");
  const proOptions =
    profil &&
    profil.items &&
    profil.items.map((el) => ({ value: el.id, label: el.name }));
  return (
    <>
      <Fragment>
        <Modal isOpen={openModal} toggle={openModalFunc} backdrop size="lg">
          <ModalHeader toggle={openModalFunc}>
            <SvgCleVerte height="1em" width="1em" fill="currentcolor" /> &nbsp;{" "}
            {deleteList.length === 0
              ? t("all.user.add_profile")
              : t("user.text.remove_profile")}
          </ModalHeader>
          <ModalBody>
            {deleteList.length === 0 ? (
              <div className="modal-profil flex-box">
                <div className="site">
                  <h5>{t("all.location.location")}</h5>
                  <Select
                    className="basic-single"
                    options={locOptions}
                    placeholder={t("user.text.select_location")}
                    onChange={(e) => handleChange(e, "loc")}
                  />
                </div>
                <div className="profil">
                  <h5>{t("all.user.profile")}</h5>
                  <Select
                    className="basic-single"
                    options={proOptions}
                    placeholder={t("user.text.select_profile")}
                    onChange={(e) => handleChange(e, "pro")}
                  />
                </div>
              </div>
            ) : (
              <span>{t("user.text.remove_permission_to_user")}</span>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={openModalFunc}>
              {t("all.button.cancel")}
            </Button>
            <Button color="success" onClick={handleSubmit}>
              {t("all.button.save")}
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </Fragment>
      <div className="row" style={{ marginBottom: "2vh" }}>
        <div className="col-md-12">
          {alert.message && (
            <UncontrolledAlert className={`alert ${alert.type}`}>
              {alert.message}
            </UncontrolledAlert>
          )}
        </div>
        {users.loading && (
          <LoadingBand message="Chargement des informations de l'utilisateur" />
        )}
        {users.error && <ErrorBand message={users.error} />}
        {users.fetchedUser && (
          <>
            <div className="col-md-6" style={{ paddingLeft: 0 }}>
              <div
                className="information-container-header"
                style={{ marginLeft: 0 }}
              >
                <h2>
                  {" "}
                  <GestionUtilisateurVert
                    height="1em"
                    width="1em"
                    stroke="currentcolor"
                    fill="currentcolor"
                    strokeWidth="0"
                  />{" "}
                  {t("all.user.user")}{" "}
                  {users.fetchedUser.isAccountLocked
                    ? " [" + t("all.text.locked").toUpperCase() + "]"
                    : ""}
                  <div
                    className="float-right row"
                    style={{ marginRight: "15px" }}
                  >
                    {users.fetchedUser &&
                      couldEdit() &&
                      !users.fetchedUser.isAccountLocked &&
                      !users.fetchedUser.ldap && (
                        <div>
                          <div
                            className="round clickable"
                            onClick={editPassword}
                            role="presentation"
                            id="passwordChange"
                          >
                            {" "}
                            <Lock fill="#2c303b" height="1em" width="1em" />
                            <UncontrolledTooltip
                              placement="bottom"
                              target="passwordChange"
                            >
                              {" "}
                              {t("all.text.edit_password")}
                            </UncontrolledTooltip>
                            <PasswordChange
                              usertoEdit={users.fetchedUser}
                              edit={editPassword}
                              isOpen={passwordOpen}
                              updated={passwordUpdated}
                              generate={generate}
                            />
                          </div>
                        </div>
                      )}
                    {users.fetchedUser &&
                      couldEdit() &&
                      !users.fetchedUser.isAccountLocked && (
                        <div>
                          <div
                            className="round clickable"
                            onClick={edit}
                            role="presentation"
                            id="userEdit"
                          >
                            <EditionBleu
                              height="1em"
                              width="1em"
                              fill="#2c303b"
                            />
                            <UncontrolledTooltip
                              placement="bottom"
                              target="userEdit"
                            >
                              {" "}
                              {t("all.text.edit_information")}
                            </UncontrolledTooltip>
                          </div>
                          <InformationUserChange
                            usertoEdit={users.fetchedUser}
                            edit={edit}
                            isOpen={editionOpen}
                          />
                        </div>
                      )}
                    {users.fetchedUser &&
                      couldBeEdit() &&
                      !users.fetchedUser.ldap && (
                        <div>
                          <div className="burger-user">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                style={{ padding: "0 0 2px 7px" }}
                              >
                                {" "}
                                <BurgerHeader
                                  className="burger-user-icon"
                                  fill="#2c303b"
                                  height="1.5em"
                                  width="1.5em"
                                />
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem>
                                  <span className="clickable" onClick={block}>
                                    {" "}
                                    {users.fetchedUser.isAccountLocked
                                      ? t("all.text.unlock")
                                      : t("all.text.lock")}
                                  </span>
                                </DropdownItem>
                                {!users.fetchedUser.isAccountLocked && (
                                  <Fragment>
                                    <DropdownItem divider />
                                    <DropdownItem>
                                      <span
                                        className="clickable"
                                        onClick={generateFunc}
                                      >
                                        {" "}
                                        {t("user.text.generate_pwd")}
                                      </span>
                                    </DropdownItem>

                                    <DropdownItem divider />
                                    <DropdownItem>
                                      <span
                                        className="clickable"
                                        onClick={reinit}
                                      >
                                        {" "}
                                        {t("user.text.reset_pwd")}
                                      </span>
                                    </DropdownItem>
                                  </Fragment>
                                )}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>
                        </div>
                      )}
                  </div>
                </h2>
              </div>
              <div className="information-container" style={{ marginLeft: 0 }}>
                {users.loading && (
                  <LoadingBand message="Chargement de la liste des utilisateurs" />
                )}
                {users.fetchedUser && (
                  <div className="display-info">
                    <p>
                      <span className="infoLibelle">
                        {t("all.contact.first_name")}{" "}
                      </span>{" "}
                      <br /> &nbsp;{" "}
                      <span className="infoDisplay">
                        {users.fetchedUser.firstName}{" "}
                      </span>
                    </p>
                    <p>
                      <span className="infoLibelle">
                        {t("all.contact.last_name")}
                      </span>{" "}
                      <br /> &nbsp;{" "}
                      <span className="infoDisplay">
                        {users.fetchedUser.lastName}{" "}
                      </span>
                    </p>

                    <p>
                      <span className="infoLibelle">
                        {t("all.contact.email")}
                      </span>{" "}
                      <br /> &nbsp;{" "}
                      <span className="infoDisplay">
                        {users.fetchedUser.email}
                      </span>
                    </p>
                    <p>
                      <span className="infoLibelle">{t("all.user.role")}</span>{" "}
                      <br /> &nbsp;{" "}
                      <span className="infoDisplay">
                        {" "}
                        {users.fetchedUser.role.name}{" "}
                      </span>
                    </p>
                    {actualUser && !_.isEmpty(users.fetchedUser.language) && (
                      <p>
                        <span className="infoLibelle">
                          {t("user.text.language")}
                        </span>{" "}
                        <br /> &nbsp;{" "}
                        <span className="infoDisplay">
                          {" "}
                          {users.fetchedUser.language}{" "}
                        </span>
                      </p>
                    )}
                  </div>
                )}
              </div>
            </div>
            {users.lastUpdatedInfo && (
              <div className="col-md-6" style={{ paddingRight: 0 }}>
                <div className="information-container-header">
                  <h5>{t("all.text.more_info_plural")}</h5>
                </div>
                <div className="information-container">
                  {users.fetchedUser && (
                    <div className="display-info">
                      <p>
                        <span className="infoLibelle">
                          {t("user.text.last_connection")}
                        </span>{" "}
                        <br /> &nbsp;{" "}
                        <span className="infoDisplay">
                          {moment(users.lastUpdatedInfo.lastConnexion).format(
                            "DD/MM/YYYY HH:mm:ss"
                          )}{" "}
                        </span>
                      </p>
                      <p>
                        <span className="infoLibelle">
                          {t("all.text.last_update")}
                        </span>{" "}
                        <br /> &nbsp;{" "}
                        <span className="infoDisplay">
                          {moment(users.lastUpdatedInfo.lastUpdated).format(
                            "DD/MM/YYYY HH:mm:ss"
                          )}{" "}
                        </span>
                      </p>
                    </div>
                  )}
                </div>
              </div>
            )}
            <div className="profil-container user">
              <div className="profil-header flex-box">
                <SvgPdiVert fill="#31c6b3" height="2em" />
                <h2>{t("all.user.profile")}</h2>
                {_.get(users, "fetchedUser.role.name") === "USER" &&
                  couldBeEdit() && (
                    <div className="add-profil" onClick={openModalFunc}>
                      <div>
                        <SvgAjoutBleu fill="black" height="1.5em" />
                      </div>
                    </div>
                  )}
              </div>
              <div className="profil-body">
                {locations &&
                  locations.items &&
                  _.get(users, "fetchedUser.role.name") === "USER" &&
                  couldBeEdit() && (
                    <BootstrapTable
                      keyField="id"
                      bootstrap4
                      bordered={false}
                      hover
                      data={datas}
                      columns={profilColumns}
                    />
                  )}
                {_.get(users, "fetchedUser.role.name") !== "USER" && (
                  <h5>
                    {user.id === users.fetchedUser.id
                      ? t("user.text.admin_all_locations")
                      : t("user.text.user_is_admin")}
                  </h5>
                )}
              </div>
            </div>
            <div className="profil-container user">
              <div className="profil-header flex-box">
                <SvgPdiVert fill="#31c6b3" height="2em" />
                <h2>{t("all.text.manager")}</h2>
              </div>
              <div className="profil-body">
                {gestionnaire &&
                  gestionnaire.gestionnaires &&
                  _.get(users, "fetchedUser.role.name") === "USER" &&
                  couldBeEdit() && (
                    <BootstrapTable
                      keyField="id"
                      bootstrap4
                      bordered={false}
                      hover
                      data={gestionnaireData}
                      columns={gestionnaireColumns}
                    />
                  )}
                {_.get(users, "fetchedUser.role.name") !== "USER" && (
                  <h5>
                    {user.id === users.fetchedUser.id
                      ? t("user.text.admin_all_gestionnaires")
                      : t("user.text.user_is_admin")}
                  </h5>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

function mapStateToProps(state) {
  const {
    users,
    authentication,
    alert,
    locations,
    permissions,
    gestionnaire,
    profil,
  } = state;
  const { user } = authentication;
  return {
    user,
    users,
    alert,
    locations,
    permissions,
    gestionnaire,
    profil,
  };
}

const mapping = connect(mapStateToProps)(UserComponent);

const connectedUser = withRouter(mapping);
const tr = withTranslation()(connectedUser);
export default tr;
