const synchroComConstants = {
  GETCOMMUNICATION_REQUEST: "GETCOMMUNICATION_REQUEST",
  GETCOMMUNICATION_SUCCESS: "GETCOMMUNICATION_SUCCESS",
  GETCOMMUNICATION_FAILURE: "GETCOMMUNICATION_FAILURE",

  GET_ROUNDS_REQUEST: "GET_ROUNDS_REQUEST",
  GET_ROUNDS_SUCCESS: "GET_ROUNDS_SUCCESS",
  GET_ROUNDS_FAILURE: "GET_ROUNDS_FAILURE",

  GETLABELS_REQUEST: "GET_LABELS_REQUEST",
  GETLABELS_SUCCESS: "GET_LABELS_SUCCESS",
  GETLABELS_FAILURE: "GET_LABELS_FAILURE",

  ADDAFFECT_REQUEST: "ADD_AFFECT_REQUEST",
  ADDAFFECT_SUCCESS: "ADD_AFFECT_SUCCESS",
  ADDAFFECT_FAILURE: "ADD_AFFECT_FAILURE",

  DELAFFECT_REQUEST: "DEL_AFFECT_REQUEST",
  DELAFFECT_SUCCESS: "DEL_AFFECT_SUCCESS",
  DELAFFECT_FAILURE: "DEL_AFFECT_FAILURE",

  ADDDESAFFECT_REQUEST: "ADD_DESAFFECT_REQUEST",
  ADDDESAFFECT_SUCCESS: "ADD_DESAFFECT_SUCCESS",
  ADDDESAFFECT_FAILURE: "ADD_DESAFFECT_FAILURE",

  DELDESAFFECT_REQUEST: "DEL_DESAFFECT_REQUEST",
  DELDESAFFECT_SUCCESS: "DEL_DESAFFECT_SUCCESS",
  DELDESAFFECT_FAILURE: "DEL_DESAFFECT_FAILURE",

  CANCEL_DEVICE_REQUEST: "CANCEL_DEVICE_REQUEST",
  CANCEL_DEVICE_SUCCESS: "CANCEL_DEVICE_SUCCESS",
  CANCEL_DEVICE_FAILURE: "CANCEL_DEVICE_FAILURE",

  CANCEL_ROUND_REQUEST: "CANCEL_ROUND_REQUEST",
  CANCEL_ROUND_SUCCESS: "CANCEL_ROUND_SUCCESS",
  CANCEL_ROUND_FAILURE: "CANCEL_ROUND_FAILURE",

  GETCURRENTVERSION_REQUEST: "GETCURRENT_VERSION_REQUEST",
  GETCURRENTVERSION_SUCCESS: "GETCURRENT_VERSION_SUCCESS",
  GETCURRENTVERSION_FAILURE: "GETCURRENT_VERSION_FAILURE",

  GETMINIVERSION_REQUEST: "GETMINI_VERSION_REQUEST",
  GETMINIVERSION_SUCCESS: "GETMINI_VERSION_SUCCESS",
  GETMINIVERSION_FAILURE: "GETMINI_VERSION_FAILURE",

  SWITCH_PARTIAL_REQUEST: "SWITCH_PARTIAL_REQUEST",
  SWITCH_PARTIAL_SUCCESS: "SWITCH_PARTIAL_SUCCESS",
  SWITCH_PARTIAL_FAILURE: "SWITCH_PARTIAL_FAILURE",

  CANCEL_ALL_REQUEST: "CANCEL_ALL_REQUEST",
  CANCEL_ALL_SUCCESS: "CANCEL_ALL_SUCCESS",
  CANCEL_ALL_FAILURE: "CANCEL_ALL_FAILURE",

  COM_DEVICE_REQUEST: "COM_DEVICE_REQUEST",
  COM_DEVICE_SUCCESS: "COM_DEVICE_SUCCESS",
  COM_DEVICE_FAILURE: "COM_DEVICE_FAILURE",

  COM_ROUND_REQUEST: "COM_ROUND_REQUEST",
  COM_ROUND_SUCCESS: "COM_ROUND_SUCCESS",
  COM_ROUND_FAILURE: "COM_ROUND_FAILURE",

  DISAFFECT_ALL_REQUEST: "DISAFFECT_ALL_REQUEST",
  DISAFFECT_ALL_SUCCESS: "DISAFFECT_ALL_SUCCESS",
  DISAFFECT_ALL_FAILURE: "DISAFFECT_ALL_FAILURE",

  DISAFFECT_ALL_FOR_ROUND_REQUEST: "DISAFFECT_ALL_FOR_ROUND_REQUEST",
  DISAFFECT_ALL_FOR_ROUND_SUCCESS: "DISAFFECT_ALL_FOR_ROUND_SUCCESS",
  DISAFFECT_ALL_FOR_ROUND_FAILURE: "DISAFFECT_ALL_FOR_ROUND_FAILURE",

  ROTATE_ROUND_REQUEST: "ROTATE_ROUND_REQUEST",
  ROTATE_ROUND_SUCCESS: "ROTATE_ROUND_SUCCESS",
  ROTATE_ROUND_FAILURE: "ROTATE_ROUND_FAILURE",

  REMOVE_DEVICES_ERROR_REQUEST: "REMOVE_DEVICES_ERROR_REQUEST",
  REMOVE_DEVICES_ERROR_SUCCESS: "REMOVE_DEVICES_ERROR_SUCCESS",
  REMOVE_DEVICES_ERROR_FAILURE: "REMOVE_DEVICES_ERROR_FAILURE",

  DRAG_START_SUCCESS: "DRAG_START_SUCCESS",
  DRAG_END_SUCCESS: "DRAG_END_SUCCESS",
  MULTI_DRAG_SUCCESS: "MULTI_DRAG_SUCCESS",
  SET_MULTI_SUCCESS: "SET_MULTI_SUCCESS",
  SET_MODE_SUCCESS: "SET_MODE_SUCCESS",

  UPDATELOADER_SUCCESS: "UPDATE_LOADER_SUCCESS",
  UNLOCK_LOADING: "UNLOCK_LOADING",

  CLEAR: "SYNCHROCOM_CLEAR",
};

export default synchroComConstants;
