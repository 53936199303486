import React, { Component, ComponentType } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { textFilter } from "react-bootstrap-table2-filter";
import _ from "lodash";
import { Button, Label, Row } from "reactstrap";
import { AvField, AvForm, AvGroup } from "availity-reactstrap-validation";
import i18next from "i18next";
import moment from "moment";
import { customExportActions, locationActions } from "../_actions";
import ListTools from "../List/ListTools";
import {
  recursiveTranslate,
  translateBasicAlarm,
} from "../_helpers/locale-helpers";
import { QueryBuilder } from "../QueryBuilder";
import listOperator from "../_shared/OperatorList";
import locale from "../_shared/Locale.json";
import ColumnSelector from "../ColumnSelector/ColumnSelector";
import { getFiltersRequest } from "../QueryBuilder/FilterLSManager";
import { ColumnData } from "../_interfaces/column";
import { setLocalStorage } from "../_helpers/localStorage-helper";
import Loading from "../_animations/Loading";
import ColumnOrderSelector from "./ColumnOrderSelector";
import ReglagesBleu from "../SvgComponents/ReglagesBleu";
import { compose } from "redux";
import StaticList from "../TourneeFiches/StaticList";

const externalFields = ["generatedDate", "generatedHour", "nameSite"];

const readMethods = [
  {
    label: "Manuelle",
    value: "M",
  },
  {
    label: "Radio",
    value: "A",
  },
  {
    label: "Télérelève",
    value: "T",
  },
];

class CustomExportAdd extends Component<any, any> {
  constructor(props) {
    super(props);
    this.getQueryBuilder = this.getQueryBuilder.bind(this);
    this.getColumnSelector = this.getColumnSelector.bind(this);
    this.getColumnOrderSelector = this.getColumnOrderSelector.bind(this);
    this.addFieldToFilename = this.addFieldToFilename.bind(this);
    this.exportFilenameResultSample =
      this.exportFilenameResultSample.bind(this);
    this.getGeneralConfig = this.getGeneralConfig.bind(this);
    this.findFieldsInExportName = this.findFieldsInExportName.bind(this);

    this.state = {
      exportName: "",
      exportFilename: "",
      reload: !props.history.location.search.includes("id"),
      columnOpen: false,
    };
  }

  componentDidMount() {
    const {
      dispatch,
      match: {
        params: { locationId },
      },
      history,
    } = this.props;
    const { reload } = this.state;
    // CLEAN LOCAL STORAGE
    localStorage.removeItem(
      `filter-${ListTools.typeOfList.CustomExport}-${locationId}`
    );
    localStorage.removeItem(
      `columns-${locationId}-${ListTools.typeOfList.CustomExport}`
    );
    localStorage.removeItem(
      `order-${locationId}-${ListTools.typeOfList.CustomExport}`
    );
    // END CLEAN
    if (!reload) {
      const exportId = Number.parseInt(
        history.location.search.replace("?", "").replace("id=", "")
      );
      dispatch(customExportActions.getOneCustomExport(exportId));
    }
    dispatch(locationActions.get(locationId));
  }

  componentDidUpdate(
    prevProps: Readonly<{}>,
    prevState: Readonly<{}>,
    snapshot?: any
  ) {
    const {
      customexport,
      history,
      match: {
        params: { locationId },
      },
    } = this.props;
    if (customexport.savedCustomExport) {
      history.push({
        pathname: `/locations/${locationId}/customexport`,
      });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      match: {
        params: { locationId },
      },
      t,
    } = nextProps;
    if (nextProps.customexport.fetchedCustomExport && !prevState.reload) {
      setLocalStorage(
        `filter-${ListTools.typeOfList.CustomExport}-${locationId}`,
        {
          ruleList: nextProps.customexport.fetchedCustomExport.exportFilters,
        }
      );
      setLocalStorage(
        `columns-${locationId}-${ListTools.typeOfList.CustomExport}`,
        nextProps.customexport.fetchedCustomExport.exportColumns.map((el) => ({
          label: t(`columns.${el}`),
          value: el,
        }))
      );
      return {
        exportName: nextProps.customexport.fetchedCustomExport.exportName,
        exportFilename:
          nextProps.customexport.fetchedCustomExport.exportFilename,
        exportColumns: nextProps.customexport.fetchedCustomExport.exportColumns,
        exportFilters: nextProps.customexport.fetchedCustomExport.exportFilters,
        exportOrder: nextProps.customexport.fetchedCustomExport.exportOrder,
        reload: true,
      };
    }
  }

  static generateFiltersByListFields(listFields: Array<string>) {
    return listFields
      .map(({ path }: any) => {
        let options: any[] = [];
        // S'il s'agit d'un type qui ne peut pas être determiné en regardant le type de la valeur, renseigné dans la liste specificFieldType
        const typeData = ListTools.findSpecificType(path);
        if (typeData === ListTools.specificType.selectAlarmTypeBasic) {
          options = translateBasicAlarm("fr", locale).sort((a, b) => {
            if (a.label < b.label) return -1;
            if (a.label === b.label) return 0;
            return 1;
          });
        } else if (typeData == ListTools.specificType.selectContValue) {
          const splitField = path.split(".");
          options = _.get(StaticList, splitField[splitField.length - 1]);
        }
        return {
          label: i18next.t(`columns.${path}`),
          value: path,
          type: typeData,
          opts: options.length > 0 ? options : undefined,
          filter: textFilter({ placeholder: i18next.t(`columns.${path}`) }),
        };
      })
      .sort((a, b) => {
        if (a.label < b.label) return -1;
        if (a.label === b.label) return 0;
        return 1;
      });
  }

  addFieldToFilename(field: string) {
    const { exportFilename } = this.state;
    if (!exportFilename.includes(field)) {
      this.setState({
        exportFilename: `${exportFilename}${
          !_.isEmpty(exportFilename) ? "_" : ""
        }[${field}]`,
      });
    }
  }

  getQueryBuilder() {
    const {
      match: {
        params: { locationId },
      },
    } = this.props;
    const translatedKeys = recursiveTranslate(
      "fr",
      "pdi",
      locale.fr.pdi,
      locale
    );
    const listFields =
      CustomExportAdd.generateFiltersByListFields(translatedKeys);

    return (
      <div className="filter-container">
        <QueryBuilder
          sendListFiltered={(exportFilters) => {
            const { exportFilename } = this.state;
            let cloneFilename = _.cloneDeep(exportFilename);
            const filtersRequest = getFiltersRequest(
              `filter-${ListTools.typeOfList.CustomExport}-${locationId}`,
              false
            );
            const fields = this.findFieldsInExportName();
            fields
              .filter(
                ([f]) =>
                  !filtersRequest.find(
                    (filter) => filter.filter.value === f.slice(1, -1)
                  ) && !externalFields.includes(f.slice(1, -1))
              )
              .forEach(
                ([f]) =>
                  (cloneFilename = cloneFilename
                    .replace(`_${f}`, "")
                    .replace(f, ""))
              );
            this.setState({ exportFilters, exportFilename: cloneFilename });
          }}
          defaultShow
          listData={[]}
          listOperator={listOperator}
          listFilters={listFields}
          idContext={ListTools.typeOfList.CustomExport}
          save={false}
          idSite={locationId}
          autosave
          isOpen
        />
      </div>
    );
  }

  getColumnSelector() {
    const {
      match: {
        params: { locationId },
      },
    } = this.props;
    const translatedKeys = recursiveTranslate(
      "fr",
      "pdi",
      locale.fr.pdi,
      locale
    );
    const sourceColumns = translatedKeys.map((champ: any) => {
      const regex = new RegExp("date");
      const elem: any = {
        dataField: champ.path,
        text: champ.value,
        sort: true,
        classes:
          champ.path === "address.concatAdress" ? "" : "crystalList-column",
        filter: textFilter({
          placeholder: champ.value,
        }),
      };
      if (regex.test(champ.path)) {
        elem.sortFunc = ListTools.sortTableDates;
      }
      return elem;
    });
    return (
      <div className="filter-container">
        {sourceColumns && (
          <ColumnSelector
            listColumns={sourceColumns}
            changeDisplay={(columns: Array<ColumnData>) => {
              this.setState({
                exportColumns: columns.map((el) => el.dataField),
              });
            }}
            localColumns={[]}
            idSite={locationId}
            resetDefaultColumns={[]}
            type={ListTools.typeOfList.CustomExport}
            defaultShow
            hideResetButton
            numberBeforeShowClear={0}
            isOpen
          />
        )}
      </div>
    );
  }

  getColumnOrderSelector() {
    const {
      match: {
        params: { locationId },
      },
    } = this.props;
    const { exportOrder } = this.state;
    const translatedKeys = recursiveTranslate(
      "fr",
      "pdi",
      locale.fr.pdi,
      locale
    );
    const listFields = translatedKeys.map(({ path }: any) => path);
    return (
      <div className="filter-container">
        {listFields && (
          <ColumnOrderSelector
            listFields={listFields}
            changeDisplay={(columns: Array<ColumnData>) => {
              this.setState({ exportOrder: columns });
            }}
            value={exportOrder}
            idSite={locationId}
            type={ListTools.typeOfList.CustomExport}
            defaultShow
          />
        )}
      </div>
    );
  }

  handleValidSubmit = (event: Object, values: any) => {
    const {
      dispatch,
      locations: {
        fetchedLocation: { content },
      },
      match: {
        url,
        params: { locationId },
      },
      customexport: { fetchedCustomExport },
    } = this.props;
    const { exportName, exportFilename, exportColumns, exportOrder } =
      this.state;
    const body: any = {
      exportName,
      exportFilename,
      exportColumns,
      exportOrder,
      exportFilters: getFiltersRequest(
        `filter-${ListTools.typeOfList.CustomExport}-${locationId}`,
        false
      ),
      exportExtraOptions: {
        separator:
          localStorage.getItem(
            `filter-${ListTools.typeOfList.CustomExport}-${locationId}_optionor`
          ) === "true"
            ? "or"
            : "and",
      },
    };
    if (url.includes("add")) {
      dispatch(customExportActions.saveCustomExport(body));
    } else {
      dispatch(
        customExportActions.updateCustomExport(
          body,
          fetchedCustomExport.exportId
        )
      );
    }
  };

  findFieldsInExportName() {
    const { exportFilename } = this.state;
    const regexp = /\[([a-z]|[A-Z]|\.)*\]/g;
    return [...exportFilename.matchAll(regexp)];
  }

  exportFilenameResultSample() {
    const { exportFilename } = this.state;
    const {
      match: {
        params: { locationId },
      },
      locations: { fetchedLocation },
      t,
    } = this.props;
    const regexp = /\[([a-z]|[A-Z]|\.)*\]/g;
    const exportFilters = getFiltersRequest(
      `filter-${ListTools.typeOfList.CustomExport}-${locationId}`,
      false
    );
    const listFields = [...exportFilename.matchAll(regexp)];
    let resultFilename = _.cloneDeep(exportFilename);
    listFields.forEach(([value]) => {
      const fieldSliced = value.slice(1, -1);
      let userInput = "";
      if (externalFields.includes(fieldSliced)) {
        switch (fieldSliced) {
          case "generatedDate":
            userInput = moment().format(
              t("all.date_format.date").replaceAll("/", "-")
            );
            break;
          case "generatedHour":
            userInput = moment().format("HH-mm");
            break;
          case "nameSite":
            userInput = fetchedLocation.name;
            break;
        }
      } else {
        const findFilter = exportFilters.find(
          (filter) => filter.filter.value === fieldSliced
        );
        if (findFilter.defineLater) {
          userInput = `[${t(`columns.${fieldSliced}`)}]`;
        } else if (findFilter.operator.noEntry) {
          const operatorValue = t(
            `linefilter.operator.${findFilter.filter.type.toLowerCase()}.${findFilter.operator.value.toLowerCase()}`
          );
          userInput = `(${t(`columns.${fieldSliced}`)} - ${operatorValue})`;
        } else {
          if (_.isArray(findFilter.userInput))
            userInput = _.join(
              findFilter.userInput.map((el) => el.value),
              "-"
            );
          if (_.isPlainObject(findFilter.userInput))
            userInput = findFilter.userInput.value;
        }
      }
      resultFilename = resultFilename.replace(
        value,
        userInput.replace("|", "-")
      );
    });
    return resultFilename;
  }

  getGeneralConfig() {
    const { exportName, exportFilename, exportColumns } = this.state;
    const {
      t,
      match: {
        params: { locationId },
      },
    } = this.props;
    return (
      <AvForm onValidSubmit={this.handleValidSubmit}>
        <div
          className="filter-container"
          style={{ border: "0.5px solid #ccc" }}
        >
          <Row
            style={{ margin: "0 0 0 10px", justifyContent: "space-between" }}
          >
            <Button
              color="secondary"
              size="lg"
              outline
              active
              style={{
                borderColor: "white",
                backgroundColor: "white",
                color: "black",
                display: "inline-flex",
                alignItems: "center",
                marginLeft: "-14px",
              }}
            >
              <ReglagesBleu
                height="1.5em"
                width="1.5em"
                fill="#31c6b3"
                strokeWidth="1.5"
              />
              <h2 style={{ margin: "0 10px" }}>
                {t("search.info_title.general_info_plural")}
              </h2>
            </Button>
            <div style={{ marginTop: "10px", marginRight: "10px" }}>
              <Button
                disabled={
                  _.isEmpty(exportName) ||
                  _.isEmpty(exportFilename) ||
                  _.isEmpty(exportColumns)
                }
                type="submit"
                style={{ marginLeft: "5px" }}
              >
                {t("all.button.register")}
              </Button>
            </div>
          </Row>
          <div className="presentation-body" style={{ background: "none" }}>
            <div style={{ width: "35%" }}>
              <AvGroup>
                <Label for="example">
                  {t("custom_export.text.export_name")}
                </Label>
                <AvField
                  name="customExportName"
                  placeholder={t("custom_export.text.export_name")}
                  type="text"
                  validate={{
                    maxLength: {
                      value: 100,
                      errorMessage:
                        "Le nom ne doit pas faire plus de 100 caractères",
                    },
                  }}
                  required
                  errorMessage={t(
                    "all.text.required_field_character_condition"
                  )}
                  onChange={(event: any) => {
                    this.setState({
                      exportName: event.target.value,
                    });
                  }}
                  value={exportName}
                />
              </AvGroup>
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ width: "35%" }}>
                <AvGroup>
                  <Label for="example">
                    {t("custom_export.text.export_filename")}
                  </Label>
                  <AvField
                    name="customExportFilename"
                    placeholder={t("custom_export.text.export_filename")}
                    type="text"
                    validate={{
                      maxLength: {
                        value: 100,
                        errorMessage:
                          "Le nom ne doit pas faire plus de 100 caractères",
                      },
                    }}
                    required
                    errorMessage={t(
                      "all.text.required_field_character_condition"
                    )}
                    onChange={(event: any) => {
                      this.setState({
                        exportFilename: event.target.value,
                      });
                    }}
                    value={exportFilename}
                  />
                </AvGroup>
                <span>
                  <span style={{ fontWeight: "bold" }}>
                    {t("all.text.result")} :{" "}
                  </span>
                  {this.exportFilenameResultSample()}
                </span>
              </div>
              <div style={{ marginLeft: "30px" }}>
                <Label>
                  {t("custom_export.text.add_filter_value_to_filename")}
                </Label>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {getFiltersRequest(
                    `filter-${ListTools.typeOfList.CustomExport}-${locationId}`,
                    false
                  ).map(({ filter: { label, value } }) => (
                    <div
                      className="clickable"
                      style={{
                        border: "1px dotted gray",
                        padding: "5px 10px",
                        marginRight: "10px",
                        marginBottom: "10px",
                        backgroundColor: exportFilename.includes(`[${value}]`)
                          ? "lightgray"
                          : "",
                      }}
                      onClick={() => this.addFieldToFilename(value)}
                    >
                      {label}
                    </div>
                  ))}
                  {["generatedDate", "generatedHour", "nameSite"].map((el) => (
                    <div
                      className="clickable"
                      style={{
                        border: "1px dotted gray",
                        padding: "5px 10px",
                        marginRight: "10px",
                        marginBottom: "10px",
                        backgroundColor: exportFilename.includes(`[${el}]`)
                          ? "lightgray"
                          : "",
                      }}
                      onClick={() => this.addFieldToFilename(el)}
                    >
                      {t(`custom_export.text.${el}`)}
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="clearfix" />
          </div>
        </div>
      </AvForm>
    );
  }

  render() {
    const {
      t,
      customexport,
      match: {
        url,
        params: { locationId },
      },
      locations: { fetchedLocation },
    } = this.props;
    const { reload } = this.state;

    return (
      <div className="col-md-12">
        {reload && fetchedLocation ? (
          <div>
            <div
              className="presentation-container rounded-container"
              style={{ border: "0.5px solid #ccc" }}
            >
              <div className="presentation-header rounded-band">
                <span className="presentation-title">
                  {customexport.fetchedCustomExport
                    ? url.includes("add")
                      ? t("custom_export.text.duplicate")
                      : t("custom_export.text.edit_custom_export")
                    : t("custom_export.text.add_custom_export")}
                </span>
                <span className="presentation-main-title">
                  {_.get(
                    customexport,
                    "fetchedCustomExport.exportName",
                    t("all.text.new")
                  )}
                </span>
              </div>
            </div>
            {this.getQueryBuilder()}
            {this.getColumnSelector()}
            {this.getColumnOrderSelector()}
            {this.getGeneralConfig()}
          </div>
        ) : (
          <Loading />
        )}
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { customexport, locations } = state;
  return {
    customexport,
    locations,
  };
}

const connectedCustomExportAdd = compose<ComponentType<any>>(
  withRouter,
  connect(mapStateToProps)
)(CustomExportAdd);
export default withTranslation()(connectedCustomExportAdd);
