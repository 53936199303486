import synchroComConstants from "../_constants/synchrocom.constants";
import synchroComService from "../_services/synchrocom.service";
import synchroConstants from "../_constants/synchro.constants";
import synchroService from "../_services/synchro.service";
import synchroActions from "./synchro.actions";

function getCurrentCommunication(gestionnaireId: any) {
  function request() {
    return { type: synchroComConstants.GETCOMMUNICATION_REQUEST };
  }
  function success(currentState: Object) {
    return { type: synchroComConstants.GETCOMMUNICATION_SUCCESS, currentState };
  }
  function failure(error: Object) {
    return { type: synchroComConstants.GETCOMMUNICATION_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroComService
      .getAllDevicesInfo(gestionnaireId)
      .then((state: Object) => {
        dispatch(success(state));
      })
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function getCurrentRounds(gestionnaireId: any) {
  function request() {
    return { type: synchroComConstants.GET_ROUNDS_REQUEST };
  }
  function success(rounds: any) {
    return { type: synchroComConstants.GET_ROUNDS_SUCCESS, rounds };
  }
  function failure(error: Object) {
    return { type: synchroComConstants.GET_ROUNDS_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroComService
      .getAllRoundsInfo(gestionnaireId)
      .then((state: Object) => dispatch(success(state)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function getLabels(gestionnaireId: any) {
  function request() {
    return { type: synchroComConstants.GETLABELS_REQUEST };
  }
  function success(labels: Object) {
    return { type: synchroComConstants.GETLABELS_SUCCESS, labels };
  }
  function failure(error: Object) {
    return { type: synchroComConstants.GETLABELS_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroComService
      .getLabels(gestionnaireId)
      .then((roundCode: Object) => dispatch(success(roundCode)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function addAffect(
  affect: any,
  gestionnaireId: any,
  deviceId: any,
  isPartial: boolean
) {
  function request() {
    return { type: synchroComConstants.ADDAFFECT_REQUEST, affect, deviceId };
  }
  function success(affect: Object) {
    return { type: synchroComConstants.ADDAFFECT_SUCCESS, affect, deviceId };
  }
  function failure(error: Object) {
    return {
      type: synchroComConstants.ADDAFFECT_FAILURE,
      error,
      affect,
      deviceId,
    };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroComService
      .addAffect(affect, gestionnaireId, deviceId)
      .then((affect: Object) => {
        dispatch(success(affect));
        isPartial &&
          dispatch(
            switchToPartiel(
              gestionnaireId,
              deviceId,
              affect[deviceId],
              isPartial
            )
          );
      })
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function delAffect(affect: any, gestionnaireId: any, deviceId: any) {
  function request() {
    return { type: synchroComConstants.DELAFFECT_REQUEST, affect, deviceId };
  }
  function success(affect: Object) {
    return { type: synchroComConstants.DELAFFECT_SUCCESS, affect, deviceId };
  }
  function failure(error: Object) {
    return {
      type: synchroComConstants.DELAFFECT_FAILURE,
      error,
      affect,
      deviceId,
    };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroComService
      .delAffect(affect, gestionnaireId, deviceId)
      .then((affect: Object) => dispatch(success(affect)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function addDesaffect(disaffect: any, gestionnaireId: any, deviceId: any) {
  function request() {
    return {
      type: synchroComConstants.ADDDESAFFECT_REQUEST,
      disaffect,
      deviceId,
    };
  }
  function success(disaffect: Object) {
    return { type: synchroComConstants.ADDDESAFFECT_SUCCESS, disaffect };
  }
  function failure(error: Object) {
    return {
      type: synchroComConstants.ADDDESAFFECT_FAILURE,
      error,
      disaffect,
      deviceId,
    };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroComService
      .addDesaffect(disaffect, gestionnaireId, deviceId)
      .then((desaffect: Object) => dispatch(success(desaffect)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function delDesaffect(disaffect: any, gestionnaireId: any, deviceId: any) {
  console.log(disaffect);
  function request() {
    return {
      type: synchroComConstants.DELDESAFFECT_REQUEST,
      disaffect,
      deviceId,
    };
  }
  function success(disaffect: Object) {
    return { type: synchroComConstants.DELDESAFFECT_SUCCESS, disaffect };
  }
  function failure(error: Object) {
    return {
      type: synchroComConstants.DELDESAFFECT_FAILURE,
      error,
      disaffect,
      deviceId,
    };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroComService
      .delDesaffect(disaffect, gestionnaireId, deviceId)
      .then((desaffect: Object) => dispatch(success(desaffect)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function deviceCancel(deviceId: string, gestionnaireId: any) {
  function request() {
    return { type: synchroComConstants.CANCEL_DEVICE_REQUEST, deviceId };
  }
  function success(deviceId: any) {
    return { type: synchroComConstants.CANCEL_DEVICE_SUCCESS, deviceId };
  }
  function failure(error: Object) {
    return { type: synchroComConstants.CANCEL_DEVICE_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroService
      .deviceCancel(deviceId, gestionnaireId)
      .then(() => {
        dispatch(success(deviceId));
      })
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function roundCancel(rndCode: string, gestionnaireId: any) {
  function request() {
    return { type: synchroComConstants.CANCEL_ROUND_REQUEST, rndCode };
  }
  function success(rndCode: Object) {
    return { type: synchroComConstants.CANCEL_ROUND_SUCCESS, rndCode };
  }
  function failure(error: Object) {
    return { type: synchroComConstants.CANCEL_ROUND_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroService
      .roundCancel(rndCode, gestionnaireId)
      .then(() => {
        dispatch(success(rndCode));
      })
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function getCurrentVersion() {
  function request() {
    return { type: synchroComConstants.GETCURRENTVERSION_REQUEST };
  }
  function success(version: Object) {
    return { type: synchroComConstants.GETCURRENTVERSION_SUCCESS, version };
  }
  function failure(error: Object) {
    return { type: synchroComConstants.GETCURRENTVERSION_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroService
      .getCurrentVersion()
      .then((version: Object) => dispatch(success(version)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function getMiniVersion() {
  function request() {
    return { type: synchroComConstants.GETMINIVERSION_REQUEST };
  }
  function success(version: Object) {
    return { type: synchroComConstants.GETMINIVERSION_SUCCESS, version };
  }
  function failure(error: Object) {
    return { type: synchroComConstants.GETMINIVERSION_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroService
      .getMiniVersion()
      .then((version: Object) => dispatch(success(version)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function switchToPartiel(
  gestionnaireId: any,
  deviceId: any,
  rndList: any,
  isPartial: boolean
) {
  function request() {
    return {
      type: synchroComConstants.SWITCH_PARTIAL_REQUEST,
      deviceId,
      rndList,
      isPartial,
    };
  }
  function success() {
    return { type: synchroComConstants.SWITCH_PARTIAL_SUCCESS };
  }
  function failure(error: Object) {
    return { type: synchroComConstants.SWITCH_PARTIAL_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroService
      .switchToPartiel(gestionnaireId, deviceId, rndList, isPartial)
      .then(() => dispatch(success()))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function cancelAll(gestionnaireId: any) {
  function request() {
    return { type: synchroComConstants.CANCEL_ALL_REQUEST };
  }
  function success(all: Object) {
    return { type: synchroComConstants.CANCEL_ALL_SUCCESS, all };
  }
  function failure(error: Object) {
    return { type: synchroComConstants.CANCEL_ALL_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroService
      .cancelAll(gestionnaireId)
      .then((all: Object) => dispatch(success(all)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function deviceCom(deviceId: string, gestionnaireId: any, idList: any) {
  console.log(idList);
  function request() {
    return { type: synchroComConstants.COM_DEVICE_REQUEST, deviceId, idList };
  }
  function success(communication: Object) {
    return {
      type: synchroComConstants.COM_DEVICE_SUCCESS,
      communication,
      deviceId,
      idList,
    };
  }
  function failure(error: Object) {
    return { type: synchroComConstants.COM_DEVICE_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroService
      .deviceCom(deviceId, gestionnaireId)
      .then((communication: Object) => {
        dispatch(success(communication));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function roundCom(rndCode: string, gestionnaireId: any, idList: any) {
  function request() {
    return { type: synchroComConstants.COM_ROUND_REQUEST, rndCode, idList };
  }
  function success(communication: Object) {
    return {
      type: synchroComConstants.COM_ROUND_SUCCESS,
      communication,
      rndCode,
      idList,
    };
  }
  function failure(error: Object) {
    return { type: synchroComConstants.COM_ROUND_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroService
      .roundCom(rndCode, gestionnaireId)
      .then((communication: Object) => {
        dispatch(success(communication));
      })
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function synchroDevice(deviceId: any, gestionnaireId: any, idList: any) {
  function request() {
    return { type: synchroComConstants.COM_DEVICE_REQUEST, deviceId, idList };
  }
  function success() {
    return { type: synchroComConstants.COM_DEVICE_SUCCESS, deviceId, idList };
  }
  function failure(error: Object) {
    return { type: synchroComConstants.COM_DEVICE_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroService
      .synchroDevice(deviceId, gestionnaireId)
      .then(() => {
        dispatch(success());
        dispatch(synchroActions.getAllRoundFull(gestionnaireId));
      })
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function synchroRound(rndCode: any, gestionnaireId: any, idList: any) {
  function request() {
    return { type: synchroComConstants.COM_ROUND_REQUEST, rndCode, idList };
  }
  function success() {
    return { type: synchroComConstants.COM_ROUND_SUCCESS, rndCode, idList };
  }
  function failure(error: Object) {
    return { type: synchroComConstants.COM_ROUND_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroService
      .synchroRound(rndCode, gestionnaireId)
      .then(() => {
        dispatch(success());
        dispatch(synchroActions.getAllRoundFull(gestionnaireId));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function disaffectAllForDevice(gestionnaireId: any, serial: any) {
  function request() {
    return { type: synchroComConstants.DISAFFECT_ALL_REQUEST, serial };
  }
  function success(device: any) {
    return { type: synchroComConstants.DISAFFECT_ALL_SUCCESS, device };
  }
  function failure(error: Object) {
    return { type: synchroComConstants.DISAFFECT_ALL_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroService
      .disaffectAllForDevice(gestionnaireId, serial)
      .then((device: any) => dispatch(success(device)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function disaffectAllForRound(gestionnaireId: any, rndCode: any) {
  function request() {
    return {
      type: synchroComConstants.DISAFFECT_ALL_FOR_ROUND_REQUEST,
      rndCode,
    };
  }
  function success(round: any) {
    return { type: synchroComConstants.DISAFFECT_ALL_FOR_ROUND_SUCCESS, round };
  }
  function failure(error: Object) {
    return { type: synchroComConstants.DISAFFECT_ALL_FOR_ROUND_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroService
      .disaffectAllForRound(gestionnaireId, rndCode)
      .then((device: any) => dispatch(success(device)))
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function rotateRound(roundCode: any, gestionnaireId: any) {
  function request() {
    return { type: synchroComConstants.ROTATE_ROUND_REQUEST, roundCode };
  }
  function success() {
    return { type: synchroComConstants.ROTATE_ROUND_SUCCESS, roundCode };
  }
  function failure(error: Object) {
    return { type: synchroComConstants.ROTATE_ROUND_FAILURE, error, roundCode };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroService
      .rotateRound(roundCode, gestionnaireId)
      .then((roundCodeMap: { [roundCode: string]: boolean }) => {
        dispatch(success());
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function updateTaskLoader(deviceId: string) {
  function success(deviceId: string) {
    return { type: synchroComConstants.UPDATELOADER_SUCCESS, deviceId };
  }

  return (dispatch: Function) => {
    dispatch(success(deviceId));
  };
}

function dragStart(taskId: string, col: string) {
  function success(id: any, column: string) {
    return { type: synchroComConstants.DRAG_START_SUCCESS, id, column };
  }

  return (dispatch: Function) => {
    dispatch(success(taskId, col));
  };
}

function dragEnd(taskId: string) {
  function success(id: any) {
    return { type: synchroComConstants.DRAG_END_SUCCESS, id };
  }

  return (dispatch: Function) => {
    dispatch(success(taskId));
  };
}

function multiDragChange(on: boolean | null) {
  function success() {
    return { type: synchroComConstants.MULTI_DRAG_SUCCESS, on };
  }

  return (dispatch: Function) => {
    dispatch(success());
  };
}

function setMultiDragList(id: string, col: string, alreadyIn: boolean) {
  function success() {
    return { type: synchroComConstants.SET_MULTI_SUCCESS, id, col, alreadyIn };
  }

  return (dispatch: Function) => {
    dispatch(success());
  };
}

function clear() {
  return {
    type: synchroComConstants.CLEAR,
  };
}

function removeDeviceError(serial: string) {
  function request() {
    return { type: synchroComConstants.REMOVE_DEVICES_ERROR_REQUEST };
  }
  function success(devices: any) {
    return { type: synchroComConstants.REMOVE_DEVICES_ERROR_SUCCESS, devices };
  }
  function failure(error: Object) {
    return { type: synchroComConstants.REMOVE_DEVICES_ERROR_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    synchroService
      .removeDeviceError(serial)
      .then((devices: any) => {
        dispatch(success(devices));
        dispatch(synchroActions.getListDeviceError());
      })
      .catch((error: Object) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function setSynchroMode(mode: String | null) {
  function success() {
    return { type: synchroComConstants.SET_MODE_SUCCESS, mode };
  }

  return (dispatch: Function) => {
    dispatch(success());
  };
}

const templateActions = {
  getCurrentCommunication,
  getCurrentRounds,
  updateTaskLoader,
  getLabels,
  addAffect,
  addDesaffect,
  delAffect,
  delDesaffect,
  deviceCancel,
  roundCancel,
  getCurrentVersion,
  getMiniVersion,
  switchToPartiel,
  cancelAll,
  roundCom,
  deviceCom,
  synchroRound,
  synchroDevice,
  disaffectAllForDevice,
  disaffectAllForRound,
  rotateRound,
  dragStart,
  dragEnd,
  multiDragChange,
  setMultiDragList,
  removeDeviceError,
  setSynchroMode,
  clear,
};

export default templateActions;
