import React from "react";
import { Route, Router, Switch } from "react-router-dom";
import { connect } from "react-redux";

import { isMobile } from "react-device-detect";
import { history } from "../_helpers";
import {
  DashboardLayout,
  DeviceLayout,
  EmptyLayout,
  Forbidden,
  MainLayout,
  NotFound,
  PrivateRoute,
  PublicRoute,
  Search,
  StockLayout,
  SupportLayout,
  SynchroLayout,
} from "../_components";
import HomePage from "../HomePage";
import LoginPage from "../LoginPage";
import DeviceLogin from "../LoginPage/DeviceLogin";
import DashBoard from "../DashBoard/DashBoard";
import { DisplayDashBoards, Locations } from "../DashBoards";
import Users from "../Users";
import { ResetPassword, ResetToken, User } from "../User";
import WidgetTemplates from "../WidgetTemplates";
import { Bookmarks } from "../Bookmarks";
import {
  SourceSheetMeter,
  SourceSheetPDI,
  SourceSheetRadio,
} from "../SourceSheet";
import DashboardFullScreenLayout from "../_components/DashboardLayoutFullScreen";
import { ListMeter, ListPdi, ListRadio, ListVirtualMeter } from "../List";
import { AddVirtualMeterNew, VirtualMeter } from "../VirtualMeter";
import { Notifications } from "../Notifications";
import { Alarms } from "../Alarms";
import AlarmConfigurator from "../Alarms/AlarmConfigurator";
import { Report, Reports } from "../Reports";
import { Resources, ImportStock } from "../StockResources";
import { StockImportation } from "../StockImportation";
import { CreateMeter } from "../StockCreateMeter";
import { CreatePdi } from "../StockCreatePdi";
import { LinkerPdi, UnlinkerPdi } from "../ManagePdi";
import TourneeFiches from "../TourneeFiches/TourneeFiches";
import TourneeStat from "../TourneeStatistique/TourneeStat";
import ProfilMain from "../Profil/ProfilMain";
import LoraImport from "../LoraImport/LoraImport";
import Provisionning from "../telereleve/Provisionning";
import {
  SupportGeneric,
  SupportImportBilling,
  SupportRemoteReading,
} from "../Support";

import {
  DeviceSynchro,
  Gestionnaires,
  ImportConstructeur,
  ListMarquages,
  ListMessages,
  PortablesResources,
  Synchronisation,
  TourneesResources,
} from "../SynchroResources";

import ManageAlarm from "../Alarm/ManageAlarm";

import TourneeDetail from "../TourneeDetail/TourneeDetail";
import FicheDetail from "../FicheDetail/FicheDetail";
import Synchro3SidebarLayout from "../_components/Synchro3SidebarLayout";
import ExportPdi from "../_export/ExportPdi";
import MasksAlarm from "../MasksAlarm/MasksAlarm";
import MaskAlarm from "../MasksAlarm/MaskAlarm";
import AddMask from "../MasksAlarmCreator/AddMask";
import LocationsLayout from "../_components/LocationsLayout";
import TourneeReport from "../TourneeReport/RoundReport";

import TeleLayout from "../_components/TeleLayout";
import SynchroTools from "../Support/SynchroTools";
import EnquetePageList from "../Enquetes/EnquetePageList";
import EnquetePageAdd from "../Enquetes/EnquetePageAdd";
import EnqueteLinkTournee from "../Enquetes/EnqueteLinkTournee";
import CustomExportList from "../CustomExport/CustomExportList";
import CustomExportAdd from "../CustomExport/CustomExportAdd";
import SensorList from "../Sensor/SensorList";
import SensorAlarms from "../Sensor/SensorAlarms";
import SensorGraph from "../Sensor/SensorAdeComfort";
import LogSupport from "../Support/LogSupport";
import SensorSwitch from "../Sensor/SensorSwitch";
import SensorDashboard from "../Sensor/SensorDashboard";
import Cluster from "../Sensor/Cluster";
import RemoteReadingDataBetter from "../RemoteReadingData/RemoteReadingDataBetter";
import PortailAboSupport from "../Support/PortailAboSupport";
import animInitialJson from "../_lotties/animation_initialisation_cc.json";
import initialProgressActions from "../initialisation/initialProgress.actions";
import NewTourneePage from "../TourneeCreation/NewTourneePage";
import TemplateCreationPage from "../TourneeCreation/TemplateCreationPage";
import TourneeCreationPage from "../TourneeCreation/TourneeCreationPage";
import ImportSilex from "../TourneeCreation/ImportSilex";

interface Props {
  dispatch: Function;
  initialProgress: any;
}

/**
 * Classe principale, se charge du routing
 *
 * @class App
 * @extends Component
 */
class App extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    const { dispatch } = this.props;
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(initialProgressActions.getProgress());
    window.onerror = () => {
      return true;
    };
  }

  /**
   * Rend le composant
   */
  render() {
    const { initialProgress } = this.props;
    const { progressCode } = initialProgress;

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animInitialJson,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    return (
      <Router history={history}>
        {!progressCode || progressCode === 5 ? (
          <Switch>
            <PrivateRoute
              exact
              path="/"
              layout={MainLayout}
              component={HomePage}
              isDevice={isMobile}
            />
            <PrivateRoute
              authAccepted={["SUPERADMIN", "ADMIN", "DIOPTASE"]}
              exact
              path="/widgets"
              layout={LocationsLayout}
              component={WidgetTemplates}
            />
            <PrivateRoute
              exact
              path="/users/:userId"
              layout={MainLayout}
              component={User}
            />
            <PrivateRoute
              authAccepted={["SUPERADMIN", "ADMIN", "DIOPTASE"]}
              exact
              path="/users"
              layout={LocationsLayout}
              component={Users}
            />
            <PrivateRoute
              authAccepted={["SUPERADMIN", "ADMIN", "DIOPTASE"]}
              exact
              path="/profils"
              layout={LocationsLayout}
              component={ProfilMain}
            />
            <PrivateRoute
              exact
              path="/bookmarks"
              layout={MainLayout}
              component={Bookmarks}
            />
            <PrivateRoute
              exact
              path="/search"
              layout={LocationsLayout}
              component={Search}
            />
            <PrivateRoute
              exact
              path="/notifications"
              layout={MainLayout}
              component={Notifications}
            />
            <PrivateRoute
              exact
              path="/stock"
              layout={StockLayout}
              component={Resources}
            />
            <PrivateRoute
              exact
              path="/stock/import"
              layout={StockLayout}
              component={ImportStock}
            />
            <PrivateRoute
              exact
              path="/gestionnaires"
              layout={LocationsLayout}
              component={Gestionnaires}
            />
            <PrivateRoute
              authAccepted={["DIOPTASE"]}
              exact
              path="/gestionnaires/list"
              layout={LocationsLayout}
              component={Gestionnaires}
            />
            <PrivateRoute
              exact
              path="/telereleve"
              layout={TeleLayout}
              component={Provisionning}
              authAccepted={["SUPERADMIN", "ADMIN", "DIOPTASE"]}
            />

            <PrivateRoute
              exact
              path="/telereleve/import"
              layout={TeleLayout}
              component={LoraImport}
              authAccepted={["SUPERADMIN", "ADMIN", "DIOPTASE"]}
            />

            <PrivateRoute
              authAccepted={["SUPERADMIN", "ADMIN", "DIOPTASE"]}
              exact
              path="/import"
              layout={LocationsLayout}
              component={StockImportation}
            />
            <PrivateRoute
              authAccepted={["SUPERADMIN", "ADMIN", "DIOPTASE"]}
              exact
              path="/importlora"
              layout={LocationsLayout}
              component={LoraImport}
            />
            <PrivateRoute
              exact
              path="/stock/createmeter"
              layout={StockLayout}
              authAccepted={["SUPERADMIN", "ADMIN", "DIOPTASE"]}
              component={CreateMeter}
            />
            <PrivateRoute
              exact
              path="/stock/createpdi"
              layout={StockLayout}
              authAccepted={["SUPERADMIN", "ADMIN", "DIOPTASE"]}
              component={CreatePdi}
            />
            <PrivateRoute
              exact
              path="/locations"
              layout={LocationsLayout}
              component={Locations}
            />
            <PrivateRoute
              exact
              path="/locations/:locationId/details"
              layout={DashboardLayout}
              component={RemoteReadingDataBetter}
            />
            <PrivateRoute
              exact
              path="/locations/:locationId"
              layout={DashboardLayout}
              component={RemoteReadingDataBetter}
            />
            <PrivateRoute
              exact
              path="/locations/:locationId/fiche"
              layout={DashboardLayout}
              component={TourneeFiches}
            />
            <PrivateRoute
              exact
              path="/locations/:locationId/fiche/:ficheId"
              layout={DashboardLayout}
              component={FicheDetail}
            />
            <PrivateRoute
              exact
              path="/locations/:locationId/statistique"
              layout={DashboardLayout}
              component={TourneeStat}
            />
            <PrivateRoute
              exact
              path="/locations/:locationId/report"
              layout={DashboardLayout}
              component={TourneeReport}
            />
            <PrivateRoute
              exact
              path="/locations/:locationId/dashboards"
              layout={DashboardLayout}
              component={DisplayDashBoards}
            />
            <PrivateRoute
              exact
              path="/locations/:locationId/meters"
              layout={DashboardLayout}
              component={ListMeter}
            />
            <PrivateRoute
              exact
              path="/locations/:locationId/virtuals"
              layout={DashboardLayout}
              component={ListVirtualMeter}
            />
            <PrivateRoute
              exact
              path="/locations/:locationId/virtuals/add"
              layout={DashboardLayout}
              component={AddVirtualMeterNew}
            />
            <PrivateRoute
              path="/locations/:locationId/virtuals/edit"
              layout={DashboardLayout}
              component={AddVirtualMeterNew}
            />
            <PrivateRoute
              path="/locations/:locationId/search"
              layout={DashboardLayout}
              component={Search}
            />
            <PrivateRoute
              path="/locations/:locationId/virtuals/info"
              layout={DashboardLayout}
              component={VirtualMeter}
            />
            <PrivateRoute
              exact
              path="/locations/:locationId/radios"
              layout={DashboardLayout}
              component={ListRadio}
            />
            <PrivateRoute
              exact
              path="/locations/:locationId/pdi"
              layout={DashboardLayout}
              component={ListPdi}
            />
            <PrivateRoute
              exact
              path="/locations/:locationId/pdi/link"
              layout={DashboardLayout}
              component={LinkerPdi}
            />
            <PrivateRoute
              path="/locations/:locationId/export"
              layout={DashboardLayout}
              component={ExportPdi}
              authAccepted={["DIOPTASE"]}
            />

            <PrivateRoute
              path="/locations/:locationId/sensor/dashboard/:clusterId"
              layout={DashboardLayout}
              component={Cluster}
            />
            <PrivateRoute
              path="/locations/:locationId/sensor/dashboard"
              layout={DashboardLayout}
              component={SensorDashboard}
            />
            <PrivateRoute
              path="/locations/:locationId/sensor/:sensorId"
              layout={DashboardLayout}
              component={SensorSwitch}
            />
            <PrivateRoute
              path="/locations/:locationId/sensor"
              layout={DashboardLayout}
              component={SensorList}
            />

            <PrivateRoute
              path="/locations/:locationId/sensorAlarms"
              layout={DashboardLayout}
              component={SensorAlarms}
            />
            <PrivateRoute
              path="/locations/:locationId/sensorGraph"
              layout={DashboardLayout}
              component={SensorGraph}
            />
            <PrivateRoute
              exact
              path="/gestionnaires/:GestionnaireId/synchronisation/tournees"
              layout={SynchroLayout}
              component={TourneesResources}
            />
            <PrivateRoute
              exact
              path="/gestionnaires/:GestionnaireId/synchronisation/enquetes"
              layout={SynchroLayout}
              component={EnquetePageList}
            />
            <PrivateRoute
              exact
              path="/gestionnaires/:GestionnaireId/synchronisation/enquetes/add"
              layout={SynchroLayout}
              component={EnquetePageAdd}
            />
            <PrivateRoute
              exact
              path="/gestionnaires/:GestionnaireId/synchronisation/enquetes/linkround"
              layout={SynchroLayout}
              component={EnqueteLinkTournee}
            />
            <PrivateRoute
              exact
              path="/gestionnaires/:GestionnaireId/synchronisation/enquetes/:enqueteNumPage"
              layout={SynchroLayout}
              component={EnquetePageAdd}
            />
            <PrivateRoute
              exact
              path="/gestionnaires/:GestionnaireId/synchronisation/tournees/:tourneeCode"
              layout={Synchro3SidebarLayout}
              component={TourneeDetail}
            />
            <PrivateRoute
              exact
              path="/gestionnaires/:GestionnaireId/synchronisation/tournees/:tourneeCode/fiche"
              layout={Synchro3SidebarLayout}
              component={TourneeFiches}
            />
            <PrivateRoute
              exact
              path="/gestionnaires/:GestionnaireId/synchronisation/tournees/:tourneeCode/statistique"
              layout={Synchro3SidebarLayout}
              component={TourneeStat}
            />
            <PrivateRoute
              exact
              path="/gestionnaires/:GestionnaireId/synchronisation/tournees/:tourneeCode/report"
              layout={Synchro3SidebarLayout}
              component={TourneeReport}
            />
            <PrivateRoute
              exact
              path="/gestionnaires/:GestionnaireId/synchronisation/tournees/:tourneeCode/fiche/:ficheId"
              layout={Synchro3SidebarLayout}
              component={FicheDetail}
            />
            <PrivateRoute
              exact
              path="/gestionnaires/:GestionnaireId/synchronisation/constructeur"
              layout={SynchroLayout}
              component={ImportConstructeur}
            />
            <PrivateRoute
              exact
              path="/gestionnaires/:GestionnaireId/synchronisation"
              layout={SynchroLayout}
              component={Synchronisation}
            />
            <PrivateRoute
              exact
              path="/gestionnaires/:GestionnaireId/synchronisation/silex/import"
              layout={SynchroLayout}
              component={ImportSilex}
            />
            <PrivateRoute
              exact
              path="/gestionnaires/:GestionnaireId/synchronisation/silex/new"
              layout={SynchroLayout}
              component={TourneeCreationPage}
            />
            <PrivateRoute
              exact
              path="/gestionnaires/:GestionnaireId/synchronisation/silex"
              layout={SynchroLayout}
              component={NewTourneePage}
            />
            <PrivateRoute
              exact
              path="/gestionnaires/:GestionnaireId/synchronisation/silex/template"
              layout={SynchroLayout}
              component={TemplateCreationPage}
            />
            <PrivateRoute
              path="/gestionnaires/:GestionnaireId/synchronisation/portables"
              layout={SynchroLayout}
              component={PortablesResources}
            />
            <PrivateRoute
              path="/gestionnaires/:GestionnaireId/synchronisation/portables/alarmes"
              layout={SynchroLayout}
              component={ManageAlarm}
            />
            <PrivateRoute
              path="/gestionnaires/:GestionnaireId/synchronisation/messages"
              layout={SynchroLayout}
              component={ListMessages}
            />
            <PrivateRoute
              path="/gestionnaires/:GestionnaireId/synchronisation/marquages"
              layout={SynchroLayout}
              component={ListMarquages}
            />

            <PrivateRoute
              exact
              path="/locations/:locationId/pdi/unlink"
              layout={DashboardLayout}
              component={UnlinkerPdi}
            />
            <PrivateRoute
              exact
              path="/locations/:locationId/alerts"
              layout={DashboardLayout}
              component={Alarms}
            />
            <PrivateRoute
              exact
              path="/locations/:locationId/alerts/add"
              layout={DashboardLayout}
              component={AlarmConfigurator}
            />
            <PrivateRoute
              exact
              path="/locations/:locationId/alerts/editalert"
              layout={DashboardLayout}
              component={AlarmConfigurator}
            />
            <PrivateRoute
              exact
              path="/locations/:locationId/mask"
              layout={DashboardLayout}
              component={MasksAlarm}
            />
            <PrivateRoute
              path="/locations/:locationId/mask/info"
              layout={DashboardLayout}
              component={MaskAlarm}
            />
            <PrivateRoute
              exact
              path="/locations/:locationId/mask/add"
              layout={DashboardLayout}
              component={AddMask}
            />
            <PrivateRoute
              exact
              path="/locations/:locationId/mask/editmask"
              layout={DashboardLayout}
              component={AddMask}
            />
            <PrivateRoute
              exact
              path="/locations/:locationId/customexport"
              layout={DashboardLayout}
              component={CustomExportList}
            />
            <PrivateRoute
              exact
              path="/locations/:locationId/customexport/add"
              layout={DashboardLayout}
              component={CustomExportAdd}
            />
            <PrivateRoute
              exact
              path="/locations/:locationId/customexport/editexport"
              layout={DashboardLayout}
              component={CustomExportAdd}
            />
            <PrivateRoute
              exact
              path="/locations/:locationId/alerts/:alertId/reports"
              layout={DashboardLayout}
              component={Reports}
            />
            <PrivateRoute
              exact
              path="/locations/:locationId/alerts/:alertId/reports/:reportId"
              layout={DashboardLayout}
              component={Report}
            />
            <PrivateRoute
              path="/locations/:locationId/meters/info"
              layout={DashboardLayout}
              component={SourceSheetMeter}
            />
            <PrivateRoute
              path="/locations/:locationId/radios/info"
              layout={DashboardLayout}
              component={SourceSheetRadio}
            />
            <PrivateRoute
              path="/locations/:locationId/pdi/:pdiId"
              layout={DashboardLayout}
              component={SourceSheetPDI}
            />
            <PrivateRoute
              exact
              path="/locations/:locationId/dashboards/:dashboardId"
              layout={DashboardLayout}
              component={DashBoard}
            />
            <PrivateRoute
              exact
              path="/locations/:locationId/dashboards/:dashboardId/FullScreen"
              layout={DashboardFullScreenLayout}
              component={DashBoard}
            />
            <PublicRoute
              exact
              path="/signin"
              layout={EmptyLayout}
              component={LoginPage}
            />
            <PrivateRoute
              exact
              path="/forbidden"
              layout={MainLayout}
              component={Forbidden}
            />
            <PublicRoute
              exact
              path="/resetPassword"
              layout={MainLayout}
              component={ResetPassword}
            />
            <PublicRoute
              path="/reset/:token"
              layout={EmptyLayout}
              component={ResetToken}
            />
            <PublicRoute
              exact
              path="/saphir/signin"
              layout={DeviceLayout}
              component={DeviceLogin}
            />
            <PrivateRoute
              path="/saphir/synchro"
              layout={EmptyLayout}
              component={DeviceSynchro}
              isDevice
            />
            <PrivateRoute
              authAccepted={["DIOPTASE"]}
              exact
              path="/support/importbilling"
              layout={SupportLayout}
              component={SupportImportBilling}
            />
            <PrivateRoute
              authAccepted={["DIOPTASE"]}
              exact
              path="/support/report"
              layout={SupportLayout}
              component={SupportGeneric}
            />
            <PrivateRoute
              authAccepted={["DIOPTASE"]}
              path="/support/general"
              layout={SupportLayout}
              component={SupportGeneric}
            />
            <PrivateRoute
              authAccepted={["DIOPTASE"]}
              path="/support/generate"
              layout={SupportLayout}
              component={SupportGeneric}
            />
            <PrivateRoute
              authAccepted={["DIOPTASE"]}
              path="/support/sync"
              layout={SupportLayout}
              component={SupportGeneric}
            />
            <PrivateRoute
              authAccepted={["DIOPTASE"]}
              path="/support/export"
              layout={SupportLayout}
              component={SupportGeneric}
            />
            <PrivateRoute
              authAccepted={["DIOPTASE"]}
              path="/support/script"
              layout={SupportLayout}
              component={SupportGeneric}
            />
            <PrivateRoute
              authAccepted={["DIOPTASE"]}
              path="/support/remotereading"
              layout={SupportLayout}
              component={SupportRemoteReading}
            />
            <PrivateRoute
              authAccepted={["DIOPTASE"]}
              path="/support/synchrotools"
              layout={SupportLayout}
              component={SynchroTools}
            />
            <PrivateRoute
              authAccepted={["DIOPTASE"]}
              path="/support/logs/:appname"
              layout={SupportLayout}
              component={LogSupport}
            />
            <PrivateRoute
              authAccepted={["DIOPTASE"]}
              path="/support/portailabo"
              layout={SupportLayout}
              component={PortailAboSupport}
            />
            <Route component={NotFound} />
          </Switch>
        ) : (
          // @ts-ignore
          <LoginPage justDisplay />
        )}
      </Router>
    );
  }
}

function mapStateToProps(state: any) {
  const { alert, initialProgress } = state;
  return {
    alert,
    initialProgress,
  };
}

const connectedApp = connect(mapStateToProps)(App);
export default connectedApp;
