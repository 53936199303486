import { authHeader, handleResponse } from "../_helpers";

async function getAll(rndCode: string) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let response = await fetch(`/api/alert/${rndCode}`, requestOptions);
  return handleResponse(response);
}

async function get(alertId: number, rndId: number) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let response = await fetch(
    `/api/alert/${rndId}/${alertId}/infos`,
    requestOptions
  );
  return handleResponse(response);
}

async function save(alarm: any) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(alarm),
  };
  let response = await fetch(
    `/api/alert/${alarm.locationCode}`,
    requestOptions
  );
  return handleResponse(response);
}

async function update(alarm: any) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(alarm),
  };
  let response = await fetch(
    `/api/alert/${alarm.locationCode}/${alarm.id}`,
    requestOptions
  );
  return handleResponse(response);
}

async function setActive(alarm: any) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(alarm),
  };
  let response = await fetch(
    `/api/alert/${alarm.locationCode}/${alarm.id}/${alarm.isActive}`,
    requestOptions
  );
  return handleResponse(response);
}

async function toDelete(alarm: any) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };
  let response = await fetch(
    `/api/alert/${alarm.locationCode}/${alarm.id}`,
    requestOptions
  );
  return handleResponse(response);
}

async function getAllTemplates() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let response = await fetch("/api/alert/templates", requestOptions);
  return handleResponse(response);
}

async function getAllUsers(locationId: number) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let response = await fetch(`/api/alert/${locationId}/users`, requestOptions);
  return handleResponse(response);
}

async function getAllType() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let response = await fetch(`/api/alert/alarmType`, requestOptions);
  return handleResponse(response);
}

const alarmService = {
  getAll,
  setActive,
  get,
  update,
  toDelete,
  save,
  getAllTemplates,
  getAllUsers,
  getAllType,
};

export default alarmService;
