import React from "react";

const SvgConflit = (props: any) => (
  <svg
    id="Calque_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 9.71 15.99"
    {...props}
  >
    <path
      fill={props.fill}
      className="cls-1"
      d="m6.39,4.88c.95,0,1.8-.02,2.65.01.23,0,.57.14.64.3.08.18-.03.52-.17.7-2.57,3.26-5.16,6.5-7.75,9.75-.21.27-.46.48-.81.26-.35-.21-.27-.53-.15-.84.77-1.99,1.53-3.98,2.29-5.98.07-.18.13-.36.22-.61-.88,0-1.69,0-2.5,0-.77,0-.97-.27-.71-.98C.94,5.19,1.79,2.89,2.61.58,2.76.16,2.98,0,3.42,0c1.36.02,2.72,0,4.08,0,.66,0,.86.31.6.91-.49,1.13-1,2.26-1.49,3.39-.07.16-.12.32-.22.56Zm.48-3.81c-.99,0-1.9.02-2.81,0-.38-.01-.55.11-.68.47-.62,1.78-1.27,3.55-1.91,5.32-.06.16-.1.32-.17.56.76,0,1.44.03,2.13,0,1.19-.05,1.44.18.94,1.37-.47,1.11-.87,2.25-1.3,3.37.03.01.06.03.08.04,1.63-2.05,3.26-4.1,4.95-6.22-.26-.01-.41-.03-.56-.03-.64,0-1.27.01-1.91-.01-.52-.02-.72-.34-.54-.83.05-.13.11-.26.17-.39.52-1.19,1.05-2.38,1.6-3.64Z"
    />
  </svg>
);

export default SvgConflit;
