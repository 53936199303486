import React from "react";

const SvgSynchroPartielle = (props: any) => (
  <svg viewBox="0 0 9.73 16.75" {...props}>
    <g id="Calque_2" data-name="Calque 2">
      <g id="Calque_1-2" data-name="Calque 1">
        <path
          fill={props.fill}
          d="M8.6,0H1.13A1.14,1.14,0,0,0,0,1.13V15.62a1.14,1.14,0,0,0,1.13,1.13H8.6a1.14,1.14,0,0,0,1.13-1.13V1.13A1.14,1.14,0,0,0,8.6,0ZM3.67.82H6.06A.13.13,0,0,1,6.2,1a.14.14,0,0,1-.14.14H3.67A.14.14,0,0,1,3.53,1,.13.13,0,0,1,3.67.82ZM4.86,16.18a.57.57,0,1,1,.57-.56.56.56,0,0,1-.57.56Zm4.08-1.52H.79V1.79H8.94Z"
        />
      </g>
    </g>
  </svg>
);

export default SvgSynchroPartielle;
