import React from "react";

const SvgWarning = (props: any) => (
  <svg viewBox="0 0 71.5 65.4" {...props}>
    <path
      fill={props.fill}
      d="M35.8,45.9c-2,0-3.7,1.7-3.7,3.7s1.7,3.7,3.7,3.7,3.7-1.7,3.6-3.6c0-2.1-1.6-3.8-3.6-3.8Z"
    />
    <path
      fill={props.fill}
      d="M69.8,59c2.3-4,2.3-8.7,0-12.7L46.8,6.4c-2.3-4-6.4-6.4-11-6.4s-8.7,2.4-11,6.4L1.7,46.3c-2.3,4-2.3,8.8,0,12.8,2.3,3.9,6.4,6.3,11,6.3h46c4.6,0,8.7-2.4,11.1-6.4ZM64.8,56.1c-1.3,2.2-3.5,3.5-6.1,3.5H12.7c-2.5,0-4.7-1.3-6-3.4-1.3-2.2-1.3-4.8,0-7L29.8,9.2c1.2-2.2,3.5-3.5,6-3.5s4.8,1.3,6,3.5l23,39.9c1.2,2.1,1.2,4.7,0,6.9Z"
    />
    <path
      fill={props.fill}
      d="M34.8,20.1c-1.7.5-2.8,2.1-2.8,4,0,1.2.2,2.3.2,3.5.2,4.4.5,8.8.7,13.2,0,1.5,1.2,2.6,2.7,2.6s2.7-1.2,2.7-2.7c0-.9,0-1.7,0-2.7.2-2.8.3-5.7.5-8.5,0-1.8.2-3.7.3-5.5,0-.7,0-1.2-.3-1.8-.7-1.6-2.5-2.5-4.2-2.1Z"
    />
  </svg>
);

export default SvgWarning;
