import React, { Fragment, useEffect, useState } from "react";
import DownloadArrow from "../SvgComponents/DownloadArrow";
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
  UncontrolledTooltip,
} from "reactstrap";
import { importActions, tourneeActions } from "../_actions";
import { Divider, FormControlLabel } from "@material-ui/core";
import Select from "react-select";
import BootstrapTable, {
  ColumnDescription,
  ExpandRowProps,
} from "react-bootstrap-table-next";
import { History } from "history";
import RoundButton from "../_components/RoundButton";
import _ from "lodash";
import SvgEye from "../SvgComponents/Eye";
import { match } from "react-router";
import { InterventionRoundParameters } from "../_interfaces/TourneeCreationTypes";
import Ajout from "../SvgComponents/AjoutBleu";
import makeAnimated from "react-select/animated";
import DatePicker from "react-datepicker";
import { addWeeks, isAfter, isBefore } from "date-fns";
import EditionBleu from "../SvgComponents/EditionBleu";
import PoubelleBleu from "../SvgComponents/PoubelleBleu";
import {
  CheckRounded,
  ErrorOutlineRounded,
  InfoRounded,
  WarningOutlined,
  WarningRounded,
} from "@material-ui/icons";
import ValidationVert from "../SvgComponents/ValidationVert";
import { Checkbox } from "@mui/material";
import SvgPoubelleBleu from "../SvgComponents/PoubelleBleu";
import StepPopover from "../_components/StepPopover";
import ErrorBand from "../Bands/Error";

interface InterventionImportReport {
  code: string;
  success: boolean;
  roundTestResults: Array<RoundTestResult>;
  attemptTime: number;
  errorDetails: Array<any>;
}

interface RoundTestResult {
  roundCode: string;
  listResults: Array<TestSilexResult>;
  doublonsLine: Map<String, Array<T1Error>>;
  emptySerialLine: Array<T1Error>;
  dateLineError: Array<T1Error>;
}

interface T1Error {
  serialCpt: string;
  rndCode: string;
  rndName: string;
  clpInformations: string;
  cardOrder: number;
  fieldError: string;
}
interface TestSilexResult {
  lineType: number;
  allTemoinGenerated: boolean;
  differences: Array<SilexError>;
}

interface SilexError {
  type: number;
  identifiant: string;
  fieldName: string;
  valueTemoin: string;
  valueGenerated: string;
}
const ImportFactuTab: React.FC<{
  dispatch: Function;
  history: any;
  imports: any;
  match: match<{}>;
  t: any;
  setLoadingMessage: any;
  setSuccessMessage: any;
  tournee: any;
}> = ({
  dispatch,
  history,
  imports,
  match,
  t,
  setLoadingMessage,
  setSuccessMessage,
  tournee,
}) => {
  const [successData, setSuccessData] = useState<
    {
      name: string;
      code: string;
      mtrCount: number;
    }[]
  >([]);
  const [importFactuErrors, setImportFactuErrors] = useState([]);
  const [withFactuFilter, setWithFactuFilter] = useState(false);
  const [interventionUsers, setInterventionUsers] = useState<Array<string>>([]);
  const [interventionStart, setInterventionStart] = useState<Date>();
  const [interventionEnd, setInterventionEnd] = useState<Date>();
  const [reportSectionOpen, setReportSectionOpen] = useState<boolean>(false);

  const [isUserEditOpen, setUserEditOpen] = useState<boolean>(false);
  const [selectedInterventionUser, setSelectedInterventionUser] =
    useState<string>(null);
  const [
    uneditedSelectedInterventionUser,
    setUneditedSelectedInterventionUser,
  ] = useState<string>(null);
  const [userEditionMode, setUserEditionMode] = useState<boolean>(false);

  useEffect(() => {
    dispatch(importActions.getInterventionUsers());
    dispatch(tourneeActions.isFacturationInterventionEnabled());
  }, []);

  useEffect(() => {
    if (imports?.error) {
      setTimeout(() => {
        dispatch(importActions.clearError());
      }, 3000);
    }
  }, [imports]);

  const ImportFilteredPopupTrigger = () => {
    const [open, setOpen] = useState(false);
    return (
      <span
        id={`deleteTournee`}
        role="presentation"
        style={{ marginLeft: "10px" }}
        onClick={(e) => {
          e.stopPropagation();
          setOpen(true);
        }}
      >
        <Button
          style={{
            marginBottom: "50px",
            marginRight: "20px",
            backgroundColor: "orange",
            border: "orange",
          }}
          disabled={imports.loading || imports.loading}
        >
          Relancer en filtrant les erreurs
        </Button>
        <StepPopover
          threatLevel={"warning"}
          id={`deleteTourneePopover`}
          target={`deleteTournee`}
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          permission
          action={(e) => {
            launchImport(true);
            setOpen(false);
          }}
          title={t("import_silex.facturation.error_filtering")}
          confirmText={t(
            "import_silex.facturation.error_filtering_description"
          )}
          confirmElement={
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <b
                style={{
                  color: "orange",
                  width: "fit-content",
                  margin: "0 auto",
                  marginBottom: 10,
                  textAlign: "center",
                }}
              >
                {t("import_silex.facturation.error_filtering_warning")}
              </b>
            </div>
          }
          confirmButtonText={t(
            "import_silex.facturation.error_filtering.relaunch"
          )}
        />
      </span>
    );
  };

  const columns = [
    {
      dataField: "name",
      text: t("all.text.name"),
    },
    {
      dataField: "code",
      text: t("all.column_text.code"),
    },
    {
      dataField: "mtrCount",
      text: t("tournee_stat.container_title.nb_meter"),
    },
    {
      dataField: "actions",
      text: "",
      formatter: (
        cell: any,
        row: {
          name: string;
          code: string;
          mtrCount: number;
        }
      ) => {
        return (
          <div className="template-action-cell">
            <RoundButton
              id={`template-${row.code}-edit`}
              onClick={() =>
                history.push(
                  `/gestionnaires/${_.get(
                    match,
                    "params.GestionnaireId"
                  )}/synchronisation/tournees/${row.code}`
                )
              }
              icon={<SvgEye height="1em" width="1em" />}
            />
          </div>
        );
      },
    },
  ];

  const importErrorColumns = [
    {
      dataField: "lineType",
      text: "Type de ligne",
      headerStyle: () => ({
        width: "15%",
      }),
      formatter: (cell: any, row: any) => {
        return <p>{row.lineType}</p>;
      },
    },
    {
      dataField: "line",
      headerStyle: () => ({
        width: "15%",
      }),
      text: "Numéro de ligne",
    },
    {
      dataField: "value",
      headerStyle: () => ({
        width: "10%",
      }),
      text: "Position",
    },
    {
      dataField: "translationKey",
      text: "Erreur",
      formatter: (cell: any, row: any) => {
        return t(
          "import_silex.error_text." +
            row.type +
            (row.translationKey ? "." + row.translationKey : ""),
          {
            value: row.identification,
          }
        );
      },
    },
  ];

  const launchImport = (withFilter: boolean) => {
    setLoadingMessage(
      t("import_silex.loading_band.import_facturation_loading")
    );
    dispatch(importActions.importFromFacturation(withFilter));
    setImportFactuErrors([]);
    setWithFactuFilter(false);
  };

  useEffect(() => {
    if (imports.importFromFactu?.silexErrorList?.length > 0) {
      setImportFactuErrors(imports.importFromFactu?.silexErrorList);
    }

    if (
      imports.importFromFactu?.roundWithAbonnes?.length > 0 &&
      imports.importFromFactu?.silexErrorList.length == 0
    ) {
      setSuccessData(
        imports.importFromFactu?.roundWithAbonnes?.map((round) => ({
          name: round?.line0?.libelleTournee,
          code: round?.line0?.codeTournee,
          mtrCount: round?.listAbonneLines?.length,
        }))
      );
      console.log(
        t(
          imports.importFromFactu?.roundWithAbonnes?.length > 1
            ? "import_silex.success_msg.x_loaded_silex_file_plural"
            : "import_silex.success_msg.x_loaded_silex_file",
          { count: imports.importFromFactu?.listSilex?.length }
        )
      );
      setSuccessMessage(
        t(
          imports.importFromFactu?.roundWithAbonnes?.length > 1
            ? "import_silex.success_msg.x_loaded_silex_file_plural"
            : "import_silex.success_msg.x_loaded_silex_file",
          { count: imports.importFromFactu?.roundWithAbonnes?.length }
        )
      );
    } else {
      setSuccessData([]);
    }
  }, [imports]);

  const handleInterventionQuerySubmit = () => {
    dispatch(importActions.clearInterventions());
    const query: Array<InterventionRoundParameters> = interventionUsers.map(
      (it) => {
        return {
          user: it,
          startDate: Date.UTC(
            interventionStart.getFullYear(),
            interventionStart.getMonth(),
            interventionStart.getDate(),
            0,
            0,
            0
          ),
          endDate: Date.UTC(
            interventionEnd.getFullYear(),
            interventionEnd.getMonth(),
            interventionEnd.getDate(),
            23,
            59,
            59
          ),
        };
      }
    );
    const gestionnaireId: number = _.get(match, "params.GestionnaireId", null);

    if (query && gestionnaireId && query.length != 0) {
      dispatch(
        importActions.importInterventionFromFacturation(
          query,
          _.get(match, "params.GestionnaireId")
        )
      );
    }

    setInterventionUsers([]);
    setInterventionStart(null);
    setInterventionEnd(null);
  };

  const handleUserSelectionChange = (
    value: Array<{ value: string; label: string }>
  ) => {
    setInterventionUsers(value?.map((it) => it.value));
  };

  const handleUserDeletion = (user: string) => {
    if (user != null) {
      dispatch(importActions.deleteInterventionUser(user));
    }
  };
  const handleIntervalChange = (date: Date) => {
    switch (true) {
      case isAfter(date, interventionStart) && isBefore(date, interventionEnd):
        setInterventionStart(date);
        setInterventionEnd(null);
        break;
      case (interventionEnd && isBefore(date, interventionStart)) ||
        (!interventionStart && !interventionEnd):
        setInterventionStart(date);
        break;
      case (interventionEnd != null &&
        isAfter(date, interventionEnd) &&
        interventionStart != null) ||
        (interventionStart &&
          !interventionEnd &&
          isAfter(date, interventionStart)):
        setInterventionEnd(date);
        break;
      case interventionStart &&
        !interventionEnd &&
        isBefore(date, interventionStart):
        setInterventionStart(date);
        setInterventionEnd(interventionStart);
        break;
      default:
        setInterventionEnd(null);
        setInterventionStart(null);
    }
  };
  const interventionUsersSelect = imports?.interventionUsers?.map(
    (it: string) => {
      return { label: it, value: it };
    }
  );

  const onIntUserInputChange = (e) => {
    setSelectedInterventionUser(e?.target.value);
  };

  const handleInterventionUserEdit = () => {
    if (
      uneditedSelectedInterventionUser != selectedInterventionUser &&
      selectedInterventionUser != null
    ) {
      dispatch(
        importActions.editInterventionUser(
          uneditedSelectedInterventionUser,
          selectedInterventionUser
        )
      );
    }

    setSelectedInterventionUser("");
    setUserEditionMode(false);
    setUneditedSelectedInterventionUser(null);
  };

  const handleInterventionUserAdd = () => {
    if (selectedInterventionUser || selectedInterventionUser !== "") {
      dispatch(importActions.addInterventionUsers(selectedInterventionUser));
    }
    setSelectedInterventionUser("");
    setUserEditionMode(false);
  };
  useEffect(() => {
    if (imports?.interventionsReport) {
      setTimeout(() => setReportSectionOpen(false), 10000);
      setReportSectionOpen(true);
    }
  }, [imports?.interventionsReport]);
  const usersColumns: ColumnDescription[] = [
    {
      dataField: "name",
      text: "Nom",
    },
    {
      dataField: "editColumn",
      text: "",
      style: { textAlign: "right" },
      formatter: (_, row: any) => (
        <div>
          <RoundButton
            id={"interventionEditUser"}
            icon={<EditionBleu width={".8rem"} />}
            onClick={() => {
              setUserEditionMode(true);
              setSelectedInterventionUser(row?.name);
              setUneditedSelectedInterventionUser(row?.name);
            }}
          />
          {imports?.loading ? (
            <Spinner
              animation="grow"
              size="lg"
              role="status"
              aria-hidden="true"
              color={"primary"}
            />
          ) : (
            <RoundButton
              id={"interventionDeleteUser"}
              icon={<PoubelleBleu width={".8rem"} />}
              onClick={() => handleUserDeletion(row?.name)}
            />
          )}
        </div>
      ),
    },
  ];

  const reportColumns: ColumnDescription[] = [
    {
      dataField: "code",
      text: "Nom",
    },
    {
      dataField: "isSuccess",
      text: "Status",
      formatter: (_, row: InterventionImportReport) => {
        return (
          <>
            <div
              style={{
                color: row?.success
                  ? "green"
                  : row?.roundTestResults?.length
                  ? "red"
                  : "orange",
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              {row.success ? (
                <CheckRounded />
              ) : row?.roundTestResults?.length ? (
                <WarningRounded />
              ) : (
                <InfoRounded />
              )}
              {row.success
                ? t("tournee.text.imported")
                : row?.roundTestResults?.length
                ? t("synchro.command.error")
                : t("tournee.text.notfound")}
            </div>
          </>
        );
      },
    },
    {
      dataField: "error",
      text: "Détail",
      formatter: (_, row: InterventionImportReport) => {
        console.log("detail", row?.roundTestResults?.length);
        return (
          <div className={"intervention_error_chip_container"}>
            {!row?.success &&
              row?.roundTestResults?.length > 0 &&
              row?.roundTestResults[0]?.doublonsLine?.size != 0 && (
                <p>{t("import.anomaly_text.doublons")}</p>
              )}
            {!row?.success &&
              row?.roundTestResults?.length > 0 &&
              row?.roundTestResults[0]?.emptySerialLine?.length != 0 && (
                <p>{t("all.meter.serial")}</p>
              )}
            {!row?.success &&
              row?.roundTestResults?.length > 0 &&
              row?.roundTestResults[0]?.dateLineError?.length != 0 && (
                <p>{t("import_factu.import_error.invalid_date")}</p>
              )}
            {!row?.success && !row?.roundTestResults?.length && (
              <p>{t("import_factu.import_error.intervention_notFound")}</p>
            )}
          </div>
        );
      },
    },
  ];
  const expandRow: ExpandRowProps<any> = {
    renderer: (row: InterventionImportReport) => {
      console.log(row);

      const expandableColumns: ColumnDescription[] = [
        {
          dataField: "serial",
          text: t("all.meter.serial"),
          headerStyle: () => ({ width: "10%" }),
        },
        {
          dataField: "errorType",
          text: t("import_factu.column.errorType"),
          headerStyle: () => ({ width: "20%" }),
        },
        {
          dataField: "detail",
          text: t("all.text.detail_plural"),
          headerStyle: () => ({ width: "70%" }),
        },
      ];

      let expandableData: Array<{
        serial: string;
        errorType: string;
        detail: string;
      }> = [];
      console.log(row, row?.success == false);

      if (
        row?.success == false &&
        row?.roundTestResults != null &&
        row?.roundTestResults.length > 0
      ) {
        expandableData = expandableData.concat(
          _.entries(row?.roundTestResults[0]?.doublonsLine)?.map(
            ([key, value]: [string, Array<any>]) => ({
              serial: key,
              errorType: t("import.anomaly_text.doublons"),
              detail: t("import.anomaly_text.doublonDetail", {
                value: value?.map((doublon) => doublon.rndCode)?.join(", "),
              }),
            })
          )
        );
        expandableData = expandableData.concat(
          row?.roundTestResults[0]?.dateLineError?.map((it) => ({
            serial: it.serialCpt,
            errorType: t("import_factu.import_error.invalid_date"),
            detail: t("import.anomaly_text.dateLineError"),
          }))
        );
        expandableData = expandableData.concat(
          row?.roundTestResults[0]?.emptySerialLine?.map((it) => ({
            serial: it.serialCpt,
            errorType: t("all.meter.serial"),
            detail: t(`import.anomaly_text.emptyMeterSerial`),
          }))
        );
      }

      return (
        <div className={"expand_row_inner_container"}>
          <BootstrapTable
            bootstrap4
            keyField={"serial"}
            data={expandableData}
            columns={expandableColumns}
          />
        </div>
      );
    },
    expanded:
      imports?.interventionsReport
        ?.filter(
          (it: InterventionImportReport) =>
            !it.success && it?.roundTestResults.length > 0
        )
        ?.map((it) => it?.code) || [],
    nonExpandable:
      imports?.interventionsReport
        ?.filter(
          (it: InterventionImportReport) =>
            it.success || (!it.success && it?.roundTestResults.length == 0)
        )
        ?.map((it) => it?.code) || [],
  };

  return (
    <>
      <DownloadArrow
        width="40px"
        fill="#ACABAB"
        className="dl-icon import-side-icon"
      />
      <h3 className="import-side-title">
        {t("import_silex.facturation.import.title")}
      </h3>
      <div>
        {importFactuErrors?.length > 0 && (
          <Fragment>
            <ImportFilteredPopupTrigger />
          </Fragment>
        )}
        <Button
          style={{ marginBottom: "50px" }}
          onClick={() => launchImport(false)}
          disabled={imports.loading || imports.loading}
        >
          {t("all.round.round_import_launch")}
        </Button>
      </div>
      {imports.importFromFactu?.roundWithAbonnes?.length > 0 &&
        imports.importFromFactu?.silexErrorList.length == 0 && (
          <>
            <Divider className="template-divider" />
            <div className="template-table-container">
              <BootstrapTable
                keyField="code"
                bootstrap4
                bordered={false}
                hover
                data={successData}
                columns={columns}
                headerClasses="template-table-header"
              />
            </div>
          </>
        )}
      {importFactuErrors?.length > 0 && (
        <div style={{ width: "90%", marginBottom: "20px" }}>
          <h2>Erreurs détectées</h2>
          <div style={{ overflowY: "scroll", maxHeight: "40vh" }}>
            <Divider className="template-divider" />
            <div className="template-table-container">
              <BootstrapTable
                keyField="line"
                bordered={true}
                hover
                data={importFactuErrors?.sort((a, b) => a.line - b.line)}
                columns={importErrorColumns}
                headerClasses="template-table-header"
              />
            </div>
          </div>
        </div>
      )}
      {tournee.interventionEnabled && (
        <section className={"intervention-import-container"}>
          <div className={"intervention-import-main-form"}>
            <div className={"intervention-config-user-container"}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                }}
              >
                <h3 className="import-side-title">
                  {t("import_silex.facturation.intervention.title")}
                </h3>
                <p>{t("import_silex.facturation.intervention.hint_text")}</p>
              </div>
              <div style={{ marginTop: "2rem", width: "100%" }}>
                <label id={"intervention-config-user-select"}>
                  {t("import_silex.facturation.intervention.choose_user")}
                </label>
                <div className="intervention-config-user">
                  <Select
                    className={"intervention-config-user-select"}
                    classNamePrefix={"react-select"}
                    aria-labelledby={"intervention-config-user-select"}
                    isMulti
                    value={interventionUsers?.map((e) => ({
                      label: e,
                      value: e,
                    }))}
                    components={makeAnimated()}
                    placeholder={t("all.user.user")}
                    onChange={handleUserSelectionChange}
                    noOptionsMessage={() =>
                      t("import_silex.facturation.intervention.no_user_saved")
                    }
                    options={interventionUsersSelect}
                  />
                  <div className="intervention-add-button-container">
                    <button
                      className="intervention-add-button"
                      onClick={() => setUserEditOpen(true)}
                    >
                      <Ajout className="add" fill="#6c757d" width="20px" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={"intervention-config-interval-container"}
              id={"int_datePicker"}
            >
              <DatePicker
                dropdownMode={"select"}
                selected={interventionStart}
                onChange={handleIntervalChange}
                startDate={interventionStart}
                endDate={interventionEnd}
                disabledKeyboardNavigation
                selectsRange
                inline
                locale="fr"
                utcOffset={0}
                monthsShown={2}
              />
            </div>
          </div>
          {!imports?.loading &&
            imports?.interventionsReport &&
            !imports?.interventionsReport?.length && (
              <ErrorBand message={"Aucune tournée d'intervention trouvée"} />
            )}
          {imports?.interventionsReport?.length &&
            reportSectionOpen &&
            !imports?.loading && (
              <div className={"intervention-config-report-container"}>
                <h3 style={{ marginTop: "2rem" }}>Rapport d'importation</h3>
                <BootstrapTable
                  bootstrap4
                  keyField="code"
                  data={imports?.interventionsReport}
                  columns={reportColumns}
                  bordered={false}
                  expandRow={expandRow}
                />
              </div>
            )}
          {imports.loading ? (
            <div className="int-spinner-container">
              <Spinner
                animation="grow"
                role="status"
                aria-hidden="true"
                color="info"
              />
            </div>
          ) : (
            <div style={{ display: "flex", gap: "10px" }}>
              {!reportSectionOpen && imports?.interventionsReport && (
                <Button
                  style={{ marginTop: "1rem" }}
                  onClick={() => setReportSectionOpen(!reportSectionOpen)}
                >
                  {t("import_factu.import_report.show_last_report")}
                </Button>
              )}

              <Button
                style={{ marginTop: "1rem" }}
                disabled={
                  imports.loading ||
                  interventionUsers.length == 0 ||
                  !interventionStart
                }
                onClick={handleInterventionQuerySubmit}
              >
                {t("import_silex.facturation.intervention.launch")}
              </Button>
            </div>
          )}
          <Modal
            className={"intervention-modal"}
            toggle={() => setUserEditOpen(!isUserEditOpen)}
            isOpen={isUserEditOpen}
            centered
            size="lg"
          >
            <ModalHeader>
              {t("import_silex.facturation.intervention.add_edit_user")}
            </ModalHeader>
            <ModalBody style={{ padding: "1.5rem" }}>
              <h3>
                {userEditionMode
                  ? t("users.text.edit_user")
                  : t("users.text.add_user")}
              </h3>
              <div className="add-intervention-user">
                <Input
                  placeholder={t("all.user.name")}
                  value={selectedInterventionUser}
                  onKeyUp={(keyPressEvent) => {
                    if (keyPressEvent.key == "Enter") {
                      return userEditionMode
                        ? handleInterventionUserEdit()
                        : handleInterventionUserAdd();
                    }
                  }}
                  onChange={onIntUserInputChange}
                  disabled={imports?.loading}
                />
                {imports?.loading ? (
                  <Spinner
                    animation="grow"
                    size="lg"
                    role="status"
                    aria-hidden="true"
                    color={"primary"}
                  />
                ) : userEditionMode ? (
                  <Button color="primary" onClick={handleInterventionUserEdit}>
                    Modifier
                  </Button>
                ) : (
                  <Button color="primary" onClick={handleInterventionUserAdd}>
                    Ajouter
                  </Button>
                )}
              </div>
              <h3 style={{ paddingTop: "1.5rem" }}> {t("all.user.list")}</h3>
              <div style={{ width: "80%" }}>
                <BootstrapTable
                  keyField={"name"}
                  data={imports?.interventionUsers?.map((it) => ({ name: it }))}
                  columns={usersColumns}
                  bootstrap4
                  bordered={false}
                />
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={() => setUserEditOpen(false)}>
                {t("all.button.close")}
              </Button>
            </ModalFooter>
          </Modal>
        </section>
      )}
    </>
  );
};

export default ImportFactuTab;
