import React from "react";
import { connect } from "react-redux";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { alertActions } from "../_actions";
import { alert } from "../_interfaces/reducers";
import { withRouter } from "react-router-dom";
import { WithTranslation, withTranslation } from "react-i18next";
import Stepper from "react-stepper-horizontal";
import _ from "lodash";
import DigitRoll from "digit-roll-react";
import NoPresent from "../Message/NoPresent";
import tourneeService from "../_services/tournee.service";
import tourneeConstants from "../_constants/tournee.constants";
import EditableRoll from "./EditableRoll2";
import SvgCroixNoir from "../SvgComponents/croixNoir";
import SvgValidation from "../SvgComponents/ValidationVert";
import StepPopover from "../_components/StepPopover";
import EditionBleu from "../SvgComponents/EditionBleu";
import moment from "moment/moment";
import NoFilled from "../Message/NoFilled";

interface Props {
  edit: Function;
  tournee: any;
  oldMeter: any;
  oldRead: any;
  dispatch: Function;
  isOpen: boolean;
  changeInputIndex: Function;
  updateIndex: Function;
}

interface State {
  indexOldMeter: number;
  serialMeter: string;
  wheelsMeter: number;
  indexMeter: number;
  currentStep: number;
  alert: alert;
  wrongInput: boolean;
  editIndex: boolean;
  openPopup: boolean;
  checkedOnly: boolean;
}

class InformationMeterChangeComponent extends React.Component<
  Props & React.HTMLAttributes<{}> & WithTranslation,
  State
> {
  constructor(props: Props & WithTranslation) {
    super(props);
    this.state = {
      indexOldMeter: null,
      serialMeter: null,
      wheelsMeter: 5,
      indexMeter: null,
      currentStep: 0,
      alert: { type: "", message: "", selectedAlert: "" },
      wrongInput: false,
      editIndex: false,
      openPopup: false,
      checkedOnly: true,
    };

    this.handleIndex = this.handleIndex.bind(this);
  }

  /**
   * Nettoie le state au démontage
   *
   * @method componentWillUnmount
   * @memberof DisplayDashBoardsComponent
   */
  componentWillUnmount() {
    const { dispatch } = this.props;
    this.setState({
      indexOldMeter: null,
      serialMeter: null,
      wheelsMeter: 5,
      indexMeter: null,
      currentStep: 0,
      alert: { type: "", message: "", selectedAlert: "" },
      wrongInput: false,
      editIndex: false,
      openPopup: false,
      checkedOnly: true,
    });
    dispatch(alertActions.clear());
  }

  /**
   * Gère l'action à la validation du formulaire
   *
   * @method handleValidSubmit
   */
  handleValidSubmit = () => {
    const { dispatch, oldMeter, tournee } = this.props;
    const { serialMeter, wheelsMeter, indexMeter, checkedOnly } = this.state;
    const newMeter = {
      serialNew: serialMeter,
      nbDigitNew: wheelsMeter,
      startIndexNew: indexMeter,
    };
    tourneeService
      .updateFieldFiche(
        tournee.info.code,
        oldMeter.theoricSerial,
        "changeMeter" + (checkedOnly ? "?checkedOnly=true" : ""),
        newMeter
      )
      .then((fiche: any) => {
        if (checkedOnly) {
          this.setState({ currentStep: 2, checkedOnly: false });
        } else {
          dispatch({ type: tourneeConstants.GETTOURNEEFICHE_SUCCESS, fiche });
        }
      })
      .catch((error: Object) => {
        this.setState({
          alert: {
            type: "alert-danger",
            message: "fiche_detail.error.change_meter",
            selectedAlert: "",
          },
        });
        console.log("getEditInfos::tourneeaction::289", error);
      });
  };

  close(edit: Function) {
    this.componentWillUnmount();
    return edit();
  }

  handleIndex(newIndex) {
    const { changeInputIndex } = this.props;
    if (isNaN(newIndex)) {
      this.setState({ wrongInput: true });
    } else {
      this.setState({ wrongInput: false, indexOldMeter: newIndex });
      changeInputIndex({ index: newIndex, date: new Date() });
    }
  }

  onSubmitIndex() {
    const { oldRead, updateIndex } = this.props;
    const { indexOldMeter, openPopup } = this.state;
    if (oldRead?.meterIndex > indexOldMeter && !openPopup) {
      this.setState({ openPopup: true });
    } else {
      updateIndex();
      this.setState({ editIndex: false, openPopup: false });
    }
  }

  /**
   * Rend le composant
   *
   * @method render
   */
  render() {
    const { className, isOpen, edit, oldMeter, oldRead, t, changeInputIndex } =
      this.props;
    const {
      alert,
      serialMeter,
      wheelsMeter,
      indexMeter,
      currentStep,
      wrongInput,
      editIndex,
      openPopup,
    } = this.state;
    const indexOldMeter = oldRead?.meterIndex || this.state.indexOldMeter;
    const wheels = [4, 5, 6, 7, 8];
    const digitRollList = [];
    for (let i = 0; i < wheels.length; i++) {
      digitRollList.push(
        <DigitRoll
          height={2}
          width={1}
          num={indexMeter}
          length={wheels[i]}
          divider=""
        />
      );
    }
    return (
      <div>
        <div>
          <Modal
            isOpen={isOpen}
            toggle={() => {
              this.close(edit);
            }}
            className={className}
          >
            <ModalHeader
              toggle={() => {
                this.close(edit);
              }}
            >
              {t("fiche_detail.text.edit_meter")}
            </ModalHeader>
            <AvForm onValidSubmit={this.handleValidSubmit}>
              <ModalBody>
                <div>
                  <Stepper
                    circleTop={0}
                    size={24}
                    circleFontSize={12}
                    activeColor={"#31c6b3"}
                    completeColor={"#31c6b3"}
                    steps={[{ title: "" }, { title: "" }, { title: "" }]}
                    activeStep={currentStep}
                  ></Stepper>
                  <div style={{ height: "380px", margin: "15px" }}>
                    {currentStep === 0 && (
                      <div>
                        <h4 style={{ textAlign: "center" }}>
                          {t("fiche_detail.title.old_meter")}
                        </h4>
                        <div
                          style={{
                            margin: "15px 0",
                            backgroundColor: "#EBEBEB",
                            padding: "10px",
                          }}
                        >
                          <span>
                            {t("historique_compteur.text.serial_meter")}
                          </span>
                          <div style={{ fontWeight: "bold", fontSize: "20px" }}>
                            {oldMeter.revisedSerial
                              ? oldMeter.revisedSerial
                              : oldMeter.theoricSerial}
                          </div>
                        </div>
                        <div
                          style={{
                            margin: "15px 0",
                            backgroundColor: "#EBEBEB",
                            padding: "10px",
                          }}
                        >
                          <span>{t("columns.previousMeter.deposeIndex")}</span>
                          <div className="flex-box center">
                            <div
                              className={`centerAlign ${
                                wrongInput && "wrongRoll"
                              }`}
                              style={{
                                paddingRight: "40px",
                                position: "relative",
                              }}
                            >
                              {editIndex ? (
                                <EditableRoll
                                  index={indexOldMeter}
                                  changeInputIndex={this.handleIndex}
                                  wheels={oldMeter.wheels}
                                />
                              ) : Number.isInteger(indexOldMeter) ? (
                                <DigitRoll
                                  num={indexOldMeter}
                                  length={oldMeter.wheels}
                                  divider=""
                                />
                              ) : (
                                <NoPresent
                                  msg={t(
                                    "index_wrapped.no_data.index_not_present"
                                  )}
                                  style={{ paddingTop: "20px" }}
                                />
                              )}
                              {changeInputIndex && (
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "50%",
                                    right: 0,
                                    transform: "translateY(-50%)",
                                  }}
                                  className="absolute-button-index"
                                >
                                  {editIndex ? (
                                    <div
                                      className={
                                        openPopup
                                          ? "button-group button-group-disabled"
                                          : "button-group"
                                      }
                                    >
                                      <div
                                        className="round-svg"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          if (!openPopup)
                                            this.setState({ editIndex: false });
                                        }}
                                      >
                                        <SvgCroixNoir height="1.7em" />
                                      </div>
                                      <div
                                        id="validate-index-button"
                                        className="round-svg"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          if (!openPopup) this.onSubmitIndex();
                                        }}
                                      >
                                        <SvgValidation height="1em" />
                                      </div>
                                      <StepPopover
                                        id="confirmManualPopover"
                                        target="validate-index-button"
                                        open={openPopup}
                                        onClose={() =>
                                          this.setState({ openPopup: false })
                                        }
                                        permission
                                        action={() => this.onSubmitIndex()}
                                        title={t("index_wrapped.popover.title")}
                                        confirmText={t(
                                          "index_wrapped.popover.confirm_text"
                                        )}
                                        confirmButtonText={t(
                                          "all.button.confirm"
                                        )}
                                      />
                                    </div>
                                  ) : (
                                    <div
                                      className="round-svg"
                                      onClick={() =>
                                        this.setState({ editIndex: true })
                                      }
                                    >
                                      <EditionBleu height="1em" />
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                          <div style={{ marginTop: "15px", display: "flex" }}>
                            <div>
                              <span>{t("index_wrapped.label.date")}</span>
                              {oldRead ? (
                                <h3>
                                  {moment(oldRead.date)
                                    .format("DD/MM/YYYY")
                                    .toString()}
                                </h3>
                              ) : (
                                <NoFilled />
                              )}
                            </div>
                            <div style={{ marginLeft: "20px" }}>
                              <span>{t("columns.read.readMethod")}</span>
                              {oldRead ? (
                                <h3>
                                  {oldRead.methodLetter === "A"
                                    ? t("index_wrapped.read_method.radio")
                                    : oldRead.methodLetter === "M"
                                    ? t("index_wrapped.read_method.manual")
                                    : oldRead.methodLetter === "T"
                                    ? "Telereleve"
                                    : ""}
                                </h3>
                              ) : (
                                <NoFilled />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {currentStep === 1 && (
                      <div>
                        <h4 style={{ textAlign: "center" }}>
                          {t("fiche_detail.title.new_meter")}
                        </h4>
                        {alert.message && (
                          <div className={`alert ${alert.type}`}>
                            {t(alert.message)}
                          </div>
                        )}
                        <div style={{ margin: "15px 0" }}>
                          <AvField
                            name="serial"
                            label={t("historique_compteur.text.serial_meter")}
                            required
                            maxLength={12}
                            minLength={4}
                            value={serialMeter}
                            onChange={(e) => {
                              this.setState({ serialMeter: e.target.value });
                            }}
                            errorMessage={t("add_user.field.minimum_x_size", {
                              min: 4,
                            })}
                          />
                          <AvField
                            type="select"
                            name="wheels"
                            label={t("all.meter.wheels_number")}
                            value={wheelsMeter}
                            onChange={(e) => {
                              this.setState({
                                wheelsMeter: e.target.value,
                                indexMeter: 0,
                              });
                            }}
                          >
                            {_.map(_.values(wheels), (wheel) => (
                              <option value={wheel}>{wheel}</option>
                            ))}
                          </AvField>
                          <span>{t("fiche_detail.title.install_read")}</span>
                          <div style={{ display: "flex" }}>
                            <EditableRoll
                              key={wheelsMeter}
                              index={indexMeter}
                              changeInputIndex={(index) => {
                                this.setState({ indexMeter: index });
                              }}
                              wheels={wheelsMeter}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {currentStep === 2 && (
                      <div>
                        <div
                          style={{
                            backgroundColor: "#EBEBEB",
                            padding: "10px",
                            margin: "5px 0",
                          }}
                        >
                          <h4 style={{ textAlign: "center" }}>
                            {t("fiche_detail.title.old_meter")}
                          </h4>
                          <div style={{ margin: "10px 0" }}>
                            <span>
                              {t("historique_compteur.text.serial_meter")}
                            </span>
                            <div
                              style={{
                                fontWeight: "bold",
                                fontSize: "15px",
                              }}
                            >
                              {oldMeter.revisedSerial
                                ? oldMeter.revisedSerial
                                : oldMeter.theoricSerial}
                            </div>
                          </div>
                          <div style={{ margin: "10px 0" }}>
                            <span>
                              {t("columns.previousMeter.deposeIndex")}
                            </span>
                            <div
                              style={{ fontWeight: "bold", fontSize: "10px" }}
                            >
                              {oldRead &&
                              Number.isInteger(oldRead.meterIndex) ? (
                                <DigitRoll
                                  height={2}
                                  width={1}
                                  num={oldRead.meterIndex}
                                  length={oldMeter.wheels}
                                  divider=""
                                />
                              ) : (
                                "-"
                              )}
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            backgroundColor: "aquamarine",
                            padding: "10px",
                            margin: "5px 0",
                          }}
                        >
                          <h4 style={{ textAlign: "center" }}>
                            {t("fiche_detail.title.new_meter")}
                          </h4>
                          <div style={{ margin: "10px 0" }}>
                            <span>
                              {t("historique_compteur.text.serial_meter")}
                            </span>
                            <div
                              style={{
                                fontWeight: "bold",
                                fontSize: "15px",
                              }}
                            >
                              {serialMeter}
                            </div>
                          </div>
                          <div style={{ margin: "10px 0" }}>
                            <span>{t("all.meter.wheels_number")}</span>
                            <div
                              style={{
                                fontWeight: "bold",
                                fontSize: "15px",
                              }}
                            >
                              {wheelsMeter}
                            </div>
                          </div>
                          <div style={{ margin: "10px 0" }}>
                            <span>{t("fiche_detail.title.install_read")}</span>
                            <div
                              style={{ fontWeight: "bold", fontSize: "10px" }}
                            >
                              {digitRollList[wheelsMeter - 4]}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <div style={{ display: "flex", width: "100%" }}>
                  <div style={{ width: "100%" }}>
                    {currentStep > 0 && (
                      <Button
                        style={{ margin: "0 3px" }}
                        color="primary"
                        onClick={() =>
                          this.setState({
                            currentStep: currentStep - 1,
                            checkedOnly: true,
                          })
                        }
                      >
                        {t("all.button.back")}
                      </Button>
                    )}
                  </div>
                  <div style={{ textAlign: "right" }}>
                    {currentStep < 2 && (
                      <Button
                        style={{ margin: "5px 0" }}
                        color="primary"
                        disabled={
                          currentStep === 1 &&
                          (!serialMeter || serialMeter.length < 4)
                        }
                        onClick={() => {
                          if (currentStep === 1) {
                            this.handleValidSubmit();
                          } else {
                            this.setState({ currentStep: currentStep + 1 });
                          }
                        }}
                      >
                        {t("all.button.next")}
                      </Button>
                    )}
                    {currentStep == 2 && (
                      <Button style={{ margin: "5px 0" }} color="primary">
                        {t("all.button.register")}
                      </Button>
                    )}
                    <Button
                      style={{ margin: "5px 0" }}
                      color="danger"
                      onClick={() => {
                        this.close(edit);
                      }}
                    >
                      {t("all.button.cancel")}
                    </Button>
                  </div>
                </div>
              </ModalFooter>
            </AvForm>
          </Modal>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication, alert, users } = state;
  const { user } = authentication;
  return {
    user,
    alert,
    users,
  };
}

const wt: any = connect(mapStateToProps)(InformationMeterChangeComponent);
export default withTranslation()(withRouter(wt));
