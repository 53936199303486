import React from "react";

const SvgAjoutBleu = (props: any) => (
  <svg
    version="1.1"
    id="Calque_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 32.1 29.6"
    {...props}
  >
    <g>
      <g>
        <polygon
          className="st0"
          points="9.4,14.8 9.3,14.8 9.1,15.5 8.6,16.8 10,16.8 9.6,15.5 		"
        />
        <path
          className="st0"
          d="M26.9,9.7v-3c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0-0.1-0.1-0.2-0.2-0.3l-6.1-6.1c-0.1-0.1-0.2-0.1-0.3-0.2
                    c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0H5C4.7,0,4.4,0.3,4.4,0.6v9H3.2C1.4,9.6,0,11.1,0,12.8v6.2c0,0.1,0,0.2,0,0.3
                    c0.1,1.7,1.5,3,3.2,3h1.2V29c0,0.3,0.3,0.6,0.6,0.6h14.5c-0.2-0.4-0.4-0.8-0.5-1.2H5.6v-6.1h13.6c0.5-1.3,1.8-2.2,3.3-2.2
                    c0.7,0,1.4,0.2,2,0.6l4.2-4.3l0,0l0,0c0.2-0.2,0.4-0.3,0.6-0.5v-3C29.5,11.3,28.4,10,26.9,9.7z M20.8,2.1l4,4h-4V2.1z M5.6,1.2h14
                    v5.5c0,0.3,0.3,0.6,0.6,0.6h5.5v2.3H5.6V1.2z M6.7,16.9H4.5V19h-1v-5.3h3.4v0.9H4.5v1.3h2.1V16.9z M10.8,19l-0.4-1.3h-2L7.9,19
                    H6.8l2-5.3h1.1l2,5.3H10.8z M16.1,18.9c-0.4,0.2-0.8,0.2-1.2,0.2c-0.4,0-0.8-0.1-1.1-0.2c-0.3-0.1-0.6-0.3-0.9-0.6
                    c-0.2-0.2-0.4-0.5-0.6-0.9c-0.1-0.3-0.2-0.7-0.2-1.1c0-0.4,0.1-0.8,0.2-1.1c0.1-0.3,0.3-0.6,0.6-0.9c0.2-0.2,0.5-0.4,0.9-0.6
                    c0.3-0.1,0.7-0.2,1.1-0.2c0.4,0,0.8,0.1,1.1,0.2c0.3,0.1,0.6,0.4,0.9,0.6l-0.7,0.7c-0.2-0.2-0.3-0.3-0.5-0.4
                    c-0.2-0.1-0.4-0.2-0.7-0.2c-0.2,0-0.5,0-0.7,0.1c-0.2,0.1-0.4,0.2-0.6,0.4c-0.2,0.2-0.3,0.3-0.4,0.6c-0.1,0.2-0.1,0.5-0.1,0.8
                    c0,0.3,0,0.5,0.1,0.8c0.1,0.2,0.2,0.4,0.4,0.6c0.2,0.2,0.3,0.3,0.6,0.4c0.2,0.1,0.4,0.1,0.7,0.1c0.3,0,0.6-0.1,0.8-0.2
                    c0.2-0.1,0.4-0.3,0.6-0.5l0.7,0.7C16.7,18.5,16.4,18.7,16.1,18.9z M21.4,14.6h-1.5V19h-1v-4.3h-1.5v-0.9h4V14.6z M26.1,16.9
                    c0,0.3,0,0.6-0.1,0.9c-0.1,0.3-0.2,0.5-0.4,0.7s-0.4,0.3-0.7,0.5c-0.3,0.1-0.5,0.2-0.9,0.2c-0.3,0-0.6-0.1-0.8-0.2
                    s-0.5-0.2-0.6-0.4c-0.2-0.2-0.3-0.4-0.4-0.7C22,17.5,22,17.2,22,16.9v-3.2h1v3.2c0,0.2,0,0.3,0.1,0.5c0,0.1,0.1,0.3,0.2,0.4
                    c0.1,0.1,0.2,0.2,0.3,0.3s0.3,0.1,0.5,0.1c0.2,0,0.3,0,0.5-0.1s0.2-0.1,0.3-0.3c0.1-0.1,0.2-0.2,0.2-0.4s0.1-0.3,0.1-0.5v-3.2h1
                    V16.9z"
        />
      </g>
      <path
        className="st0"
        d="M31.9,19.4c0.3-0.3,0.3-0.7,0-1c-0.3-0.3-0.7-0.3-1,0l-7.6,7.6v-2.4c0-0.4-0.3-0.7-0.7-0.7s-0.7,0.3-0.7,0.7
                v4.1c0,0.4,0.3,0.7,0.7,0.7h4.1c0.1,0,0.2,0,0.2,0c0.3-0.1,0.5-0.4,0.5-0.7c0-0.3-0.2-0.6-0.5-0.7c-0.1,0-0.1,0-0.2,0h-2.4l2.7-2.8
                L31.9,19.4z"
      />
    </g>
  </svg>
);

export default SvgAjoutBleu;
