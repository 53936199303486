import React from 'react';
export default { 
translation: { 
"all.button.submit":"Submit", 
"all.button.validate":"Validate", 
"all.button.edit":"Edit", 
"all.button.integrate":"Integrate", 
"all.button.close":"Close", 
"all.button.cancel":"Cancel", 
"all.button.delete":"Delete", 
"all.button.register":"Save", 
"all.button.change_file":"Change file", 
"all.button.search":"Search", 
"all.button.reset":"Reset", 
"all.button.back":"Back", 
"all.button.export":"Export", 
"all.button.select":"Select", 
"all.button.add_to_favorites":"Add to favorites", 
"all.button.next":"Next", 
"all.button.previous":"Previous", 
"all.button.apply":"Apply", 
"all.button.save":"Save", 
"all.button.giveup":"Abandon", 
"all.button.recup":"Retrieve", 
"all.button.confirm":"Confirm", 
"all.button.copy":"Copy", 
"all.button.paste":"Paste", 
"all.button.apply.all":"Apply everything", 
"all.button.cancel.all":"Cancel everything", 
"all.button.modification":"Modification", 
"all.button.send":"Send", 
"all.text.block":"$t(all.button.delete)", 
"all.text.factu":"Billing", 
"all.text.doc":"Documentation", 
"all.text.number":"Number", 
"all.text.exemple":"Example", 
"all.column_text.code":"$t(all.text.code)", 
"all.column_text.label":"Label", 
"all.column_text.name":"$t(all.text.name)", 
"all.column_text.state":"$t(all.text.state)", 
"all.column_text.row":"Row", 
"all.column_text.pdi_ref":"POI reference", 
"all.column_text.serial_meter":"$t(all.meter.meter_serial)", 
"all.column_text.meter":"$t(all.meter.meter)", 
"all.column_text.virtual_meter":"$t(all.text.virtual_meter)", 
"all.column_text.type":"$t(all.text.type)", 
"all.column_text.type_plural":"$t(all.text.type_plural)", 
"all.column_text.cons":"$t(all.read_meter.cons)", 
"all.column_text.cons_avg":"Average consumption", 
"all.column_text.date":"$t(all.date.date)", 
"all.column_id":"Id", 
"all.brand.itron":"Itron", 
"all.address.city":"City", 
"all.address.country":"Country", 
"all.address.complement":"Additional info", 
"all.address.street":"Street", 
"all.address.street_number":"Street number", 
"all.address.streetNumber":"$t(all.address.street_number)", 
"all.address.street_number_additionnal":"Additional", 
"all.address.streetNumberAdditionnal":"$t(all.address.street_number_additionnal)", 
"all.address.zipcode":"Zipcode", 
"all.contact.civility":"Civility", 
"all.contact.civility_male":"Mr", 
"all.contact.civility_female":"Mrs", 
"all.contact.first_name":"First name", 
"all.contact.last_name":"Last name", 
"all.contact.phone":"Phone number", 
"all.contact.email":"Email", 
"all.contact.mail":"$t(all.contact.email)", 
"all.contact.note":"$t(all.text.note)", 
"all.contact.no_contact":"No contact", 
"all.devices":"Devices", 
"all.filter.all_filters":"All filters", 
"all.filter.at_least_one_filter":"At least one filter", 
"all.filter.filter_by_serial":"Filter by serial number", 
"all.filter.filter_by_ref":"Filter by reference", 
"all.filter.filter_by_name":"Filter by name", 
"all.filter.saved_filter":"Saved filter", 
"all.filter.saved_filter_plural":"Saved filters", 
"all.filter.no_conf_filter_saved":"No configuration filter saved", 
"all.filter.delete_saved_filter":"Delete saved filter", 
"all.filter.add_filter":"Add filter", 
"all.filter.delete_filter":"Delete filter", 
"all.filter.filter":"Filter", 
"all.filter.filter_plural":"Filters", 
"all.filter.mask_filter":"Filter by mask", 
"all.filter.alarm_type_filter":"Filter by alarm type", 
"all.filter.round_filter":"Filter by route", 
"all.filter.round_filter_plural":"Filter by routes", 
"all.filter.device_filter":"Filter by device", 
"all.filter.device_filter_plural":"Filter by devices", 
"all.filter.with_hour":"With hours", 
"all.date.date":"Date", 
"all.date.date_plural":"Dates", 
"all.date.updated_on":"Updated on ", 
"all.date.update_date":"Update date", 
"all.date_format.date":"DD/MM/YYYY", 
"all.date_format.date_back":"dd/MM/yyyy", 
"all.date_format.date_with_time":"DD/MM/YYYY {{time}}", 
"all.date_format.date_and_time":"DD/MM/YYYY HH:mm", 
"all.date.today_date":"Today's date", 
"all.date.end_date":"End date", 
"all.user.user":"User", 
"all.user.user_plural":"Users", 
"all.user.profile":"Profile", 
"all.user.profile_plural":"Profiles", 
"all.user.add_profile":"Add profile", 
"all.user.delete_profile":"Delete profile", 
"all.user.profile_name":"Profile name", 
"all.user.role":"Role", 
"all.user.permission_plural":"Permissions", 
"all.user.my_info_plural":"My information", 
"all.user.logout":"Logout", 
"all.user.user_required":"User required", 
"all.user.pwd":"Password", 
"all.user.logIn":"Login", 
"all.user.name":"User name ", 
"all.user.list":"User list", 
"all.text.all":"all", 
"all.text.all_plural":"all", 
"all.text.no":"No", 
"all.text.yes":"Yes", 
"all.text.unknown":"Unknown", 
"all.text.no_data_available":"No data available", 
"all.text.indexed":"Indexed", 
"all.text.indexed_plural":"Indexed", 
"all.text.not_indexed":"Not indexed", 
"all.text.not_indexed_plural":"Not indexed", 
"all.text.modified":"Edited", 
"all.text.modified_plural":"Edited", 
"all.text.not_modified":"Not edited", 
"all.text.not_modified_plural":"Not edited", 
"all.text.gps":"located", 
"all.text.gps_plural":"located", 
"all.text.no_gps":"Not located", 
"all.text.no_gps_plural":"Not located", 
"all.text.multiple_select":"Multi-selection", 
"all.text.edited_not_indexed_plural":"$t(tournee_stat.container_label.edited_not_indexed_plural)", 
"all.text.active":"Active", 
"all.text.inactive":"Inactive", 
"all.text.latitude":"Latitude", 
"all.text.longitude":"Longitude", 
"all.text.not_possible_deletion":"Impossible to delete", 
"all.text.unity":"Unit", 
"all.text.configure":"Configure", 
"all.text.filter_explanation":"You are going to use filters that will be dynamically calculated", 
"all.text.required_field_character_condition":"Required field, it must not contain special character other than _ , no accent, and be less than 100 characters", 
"all.text.option":"Option", 
"all.text.option_plural":"Options", 
"all.text.select_file":"Select file", 
"all.text.already_exist":"Already existing", 
"all.text.imported":"Imported", 
"all.text.msg":"Messages", 
"all.text.marking":"Markings", 
"all.text.required":"Required", 
"all.text.required_field":"Required field", 
"all.text.required_field_plural":"Required fields", 
"all.text.virtual_meter":"Virtual meter", 
"all.text.note":"Note", 
"all.text.manager":"Manager", 
"all.text.manager_plural":"Managers", 
"all.text.import_mfr":"Manufacturer import", 
"all.text.silex_import":"Silex import", 
"all.text.write_note":"Write a note", 
"all.text.record":"Record", 
"all.text.record_plural":"Records", 
"all.text.update_date":"Lastest update", 
"all.text.marquage":"Marking", 
"all.text.message":"Message", 
"all.text.particular_msg":"Special message", 
"all.text.import_billing":"Heritage import", 
"all.text.notif":"Notification", 
"all.text.notif_plural":"Notifications", 
"all.text.alert_plural":"$t(all.alert.alert_plural)", 
"all.text.detail_plural":"Details", 
"all.text.stock":"Stock", 
"all.text.synchro":"Synchronisation", 
"all.text.start_new_releve":"Start new read campaign", 
"all.text.phone":"Phone", 
"all.text.phone_plural":"Phones", 
"all.text.name.export":"Export", 
"all.text.stat_plural":"Stats", 
"all.text.forbidden_access":"Unauthorized", 
"all.text.none_male":"None", 
"all.text.none_female":"Aucune", 
"all.text.name":"Name", 
"all.text.update_selected_conf":"Update selected configuration", 
"all.text.save_new_conf":"Save new configuration", 
"all.text.operator":"Operator", 
"all.text.start_date":"Start date", 
"all.text.end_date":"$t(all.date.end_date)", 
"all.text.new":"New", 
"all.text.name_lenght_max_100":"The name must not have more than 100 characters", 
"all.text.link":"Link", 
"all.text.unlink":"Unlink", 
"all.text.model":"Model", 
"all.text.export_to":"Export to", 
"all.text.export_to_csv":"Export to CSV", 
"all.text.export_to_teleo":"Teleo", 
"all.text.type_x_list_loading":"Charging {{type}} list", 
"all.text.not_specified":"Not filled out", 
"all.text.zoom":"Zoom", 
"all.text.interval":"Interval", 
"all.text.export_data_from_x":"Export data from {{name}}", 
"all.text.save_pdf":"Save PDF", 
"all.text.preview_pdf":"Preview PDF", 
"all.text.export_row_data":"Export raw data", 
"all.text.average":"Average", 
"all.text.type":"Type", 
"all.text.type_plural":"Types", 
"all.text.information":"Information", 
"all.text.information_plural":"Information", 
"all.text.state":"State", 
"all.text.start":"Start", 
"all.text.end":"End", 
"all.text.ok":"Ok", 
"all.text.more_info_plural":"More information", 
"all.text.last_update":"Lastest update", 
"all.text.unlock":"Unlock", 
"all.text.locked":"Locked", 
"all.text.lock":"Lock", 
"all.text.link_all":"Link all", 
"all.text.dissociate_all":"Dissociate all", 
"all.text.threshold":"Treshold", 
"all.text.edit_information":"Edit information", 
"all.text.edit_password":"Edit password", 
"all.text.text_email":"Text of your email", 
"all.text.mark_all_as_read":"Mark all as read", 
"all.text.title":"Title", 
"all.text.read_notification":"Read notification", 
"all.text.resource_list":"Resource list", 
"all.text.validate-_deletion_link":"Validate deleting link ", 
"all.text.widgets":"Widgets", 
"all.text.lora_import":"LoRa import", 
"all.text.code":"Code", 
"all.text.waiting_action":"Waiting action ", 
"all.text.waiting_action_plural":"Waiting actions", 
"all.text.go_back":"Go back", 
"all.text.go_backward_step":"Back to previous step", 
"all.text.greater_than":"Greater than", 
"all.text.less_than":"Less than", 
"all.text.not_define":"Undefined", 
"all.text.summary":"Summary", 
"all.text.calculation_method":"Calculation method", 
"all.text.delete_x_ask":"Delete {{type}} ?", 
"all.text.irreversible_action_will_delete_x":"This action will delete {{type}}.", 
"all.text.loading":"Loading", 
"all.text.selection":"Selection", 
"all.text.more_informations_plural":"Extra info", 
"all.text.select_all":"Select all", 
"all.text.deselect_all":"Unselect all", 
"all.text.submit_file":"Submit file ", 
"all.text.install_date":"Install date", 
"all.text.uninstall_date":"Uninstall date", 
"all.text.reference":"Reference", 
"all.text.general_male":"General", 
"all.text.previous":"Previous", 
"all.text.close":"Closed", 
"all.text.doclose":"Open", 
"all.text.open":"Open", 
"all.text.doopen":"Open", 
"all.text.humidity":"Humidity", 
"all.text.temperature":"Temperature", 
"all.text.pressure":"Pressure", 
"all.text.co2":"CO2", 
"all.text.downlink":"Downlink", 
"all.text.monday":"Monday", 
"all.text.tuesday":"Tuesday", 
"all.text.wednesday":"Wednesday", 
"all.text.thursday":"Thursday", 
"all.text.friday":"Friday", 
"all.text.saturday":"Saturday", 
"all.text.sunday":"Sunday", 
"all.text.version":"Version", 
"all.text.previous_releve":"Previous reading", 
"all.text.value":"Value", 
"all.text.field":"Field", 
"all.text.filter":"Filter", 
"all.text.result":"Result", 
"all.text.other":"Other", 
"all.text.subscriber":"Subscriber", 
"all.text.portable_informations":"Phone information", 
"all.text.portable_alias":"Alias", 
"all.text.irreversible_action_warning":"Warning, this action is irrevisble", 
"all.text.other_plural_fem":"other..", 
"all.text.mounted":"Mounted", 
"all.text.unmounted":"Unmounted", 
"all.threshold.min_x_threshold":"minimum treshold: {{value}}", 
"all.threshold.max_x_threshold":"maximum treshold: {{value}}", 
"all.alert.alert":"Alert", 
"all.alert.alert_plural":"Alerts", 
"all.alert.alert_name":"Alert name", 
"all.alert.alert_type":"Type of alert", 
"all.alert.alert_conf":"Configure alert", 
"all.alert.delete_alert":"Delete alert", 
"all.alert.edit_alert":"Edit alert", 
"all.alert.view_report_plural":"See reports", 
"all.alert.deactivate_alert":"Dismiss alert", 
"all.alert.irreversible_action_deactivate_alert":"This action will dismiss this alerte from the site", 
"all.homepage.homepage":"Homepage", 
"all.homepage.choose_homepage":"Choose homepage", 
"all.mask.mask_plural":"Masks", 
"all.mask.edit_mask":"Edit mask", 
"all.mask.delete_mask":"Delete mask", 
"all.mask.add_mask":"Add mask", 
"all.mask.mask_edition":"Edit mask", 
"all.mask.mask_name":"Mask name", 
"all.device.last_login_date":"Latest login date", 
"all.device.connected":"Connected", 
"all.device.disconnected":"Disconnected", 
"all.device.device_own_last_version":"This phone has Saphir's latest version ", 
"all.device.device_not_own_last_version":"This phone does not have Saphir's latest update", 
"all.device.device_with_obsolete_version":"This phone has an outdated version of Saphir and cannot do com", 
"all.device.edit_device":"Edit device", 
"all.device.delete_device":"Delete phone", 
"all.device.phone":"$t(all.text.phone)", 
"all.device.phone_plural":"$t(all.text.phone_plural)", 
"all.dashboard.dashboard_name":"Dashboard name", 
"all.dashboard.name_need_least_2_char":"The dashboard name is necessary and has to have at least 2 characters", 
"all.dashboard.dashboard_favorite_plural":"Favorite dashboards", 
"all.dashboard.dashboard_plural":"Dashboards", 
"all.pdi.pdi":"POI", 
"all.pdi.link_pdi_ref":"Linked POI reference", 
"all.pdi.pdi_ref":"POI reference", 
"all.pdi.link_location_pdi":"Linked locations with POI", 
"all.pdi.dissociate_location_pdi":"Dissociate locations with POI", 
"all.pdi.go_to_pdi":"Go to POI", 
"all.pdi.state_pdi":"POI state", 
"all.pdi.add_location":"Add a GPS location", 
"all.pdi.loading_location":"Loading GPS coordinates", 
"all.location.location":"Location", 
"all.location.location_plural":"Locations", 
"all.location.address":"Address", 
"all.location.contact":"Contact", 
"all.location.note":"$t(all.text.note)", 
"all.location_button.access_location":"Access location", 
"all.location.location_info":"Location info", 
"all.location.pls_first_delete_child_location":"Please delete linked locations first", 
"all.location.delete_location":"Delete location", 
"all.round.location_follow_up":"Location follow-up", 
"all.round.round":"Route", 
"all.round.round_plural":"Routes", 
"all.round.multiple_round":"Multiple rounds", 
"all.round.round_code":"Route code", 
"all.round.round_name":"Route name", 
"all.round.go_to_record":"Go to record", 
"all.round.go_to_round":"Go to route", 
"all.round.assigned_round_plural":"Assigned routes", 
"all.round.round_info":"Route info", 
"all.round.round_follow_up":"Route follow-up", 
"all.round.round_mngmt":"Route management", 
"all.round.round_import":"Route import", 
"all.round.round_import_launch":"Launch round import", 
"all.meter.serial":"Serial number", 
"all.meter.mfr":"Manufacturer", 
"all.meter.meter_serial":"Meter serial number", 
"all.meter.radio_serial":"Radio serial number", 
"all.meter.meter_serial_ref":"Meter serial number reference", 
"all.meter.meter_serial_control":"Control meter serial number ", 
"all.meter.available_meter":"Available meter", 
"all.meter.available_meter_plural":"Available meters", 
"all.meter.selection_explanation":"You are going to choose a final list of meters", 
"all.meter.confirm_x_selection_explanation":"You are going to select {{count}} on-site meter", 
"all.meter.confirm_x_selection_explanation_plural":"You are going to select {{count}} on-site meters", 
"all.meter.selected_meter":"Selected meter", 
"all.meter.selected_meter_plural":"Selected meters", 
"all.meter.real_meter_diameter":"Real diameter meter", 
"all.meter.meter_model":"Meter model", 
"all.meter.meter_manufacturer":"Meter manufacturer", 
"all.meter.meter_index":"Meter index ", 
"all.meter.index":"Index", 
"all.meter.meter_ref":"Reference meter", 
"all.meter.control_meter":"Control meter", 
"all.meter.meter":"Meter", 
"all.meter.meter_plural":"Meters", 
"all.meter.indexed_meter":"Indexed meter", 
"all.meter.indexed_meter_plural":"Indexed meters", 
"all.meter.modified_meter":"Edited meter", 
"all.meter.modified_meter_plural":"Edited meters", 
"all.meter.all_meter_nb":"Total number of meters", 
"all.meter.virtual_meter_plural":"Virtual meters", 
"all.meter.pulse_weight":"Pulse weight", 
"all.meter.wheels_number":"Number of dials", 
"all.meter.add_virtual_meter":"Add virtual meter", 
"all.meter.go_to_meter":"Go to meter file", 
"all.meter.export_data_from_valid":"$t(all.text.export_data_from) - Successful readings", 
"all.meter.edit_virtual_meter":"Edit virtual meter", 
"all.meter.delete_virtual_meter":"Delete virtual meter", 
"all.meter.virtual_meter_edition":"Virtual meter edition", 
"all.meter.virtual_meter_name":"Virtual meter name", 
"all.meter.linked_meter_plural":"Meters linked", 
"all.meter.stack_index_plural":"Growing index", 
"all.meter.meter_to_x_plural":"Meters to {{mode}}", 
"all.meter.all_meter_dissociation_easy":"The meters can be dissociated from locations without verification", 
"all.meter.total_number_read_given_period":"Total number of geolocated meters captured over the given period", 
"all.read_meter.read_last_date":"Date of latest reading", 
"all.read_meter.read_previous_date":"Date of previous reading", 
"all.read_meter.total_conso_in_day":"Consumption over a day", 
"all.read_meter.total_conso_in_period":"Consumption over {{totalDays}} days", 
"all.read_meter.daily_avg_calc_info":"Over an {{totalDays}} day period", 
"all.read_meter.daily_avg_cons_ratio":"Calculated from {{totalReads}} previous reads over {{totalDays}} days ", 
"all.read_meter.daily_avg_cons":"Daily average", 
"all.read_meter.tendency":"Tendency", 
"all.read_meter.cons":"Consumption", 
"all.read_meter.cons_gap":"Consumption difference", 
"all.read_meter.last_read":"Last reading", 
"all.read_meter.cons_history":"Consumption history", 
"all.read_meter.no_current_read":"No current reading", 
"all.read_meter.read_history":"Reading history", 
"all.read_meter.empty_cons":"Empty consumption", 
"all.read_meter.high_cons":"High consumption", 
"all.read_meter.normal_cons":"Normal consumption", 
"all.read_meter.low_cons":"Low consumption", 
"all.read_meter.cons_comparison":"Comparison of consumptions", 
"all.read_meter.max_index":"Maximum index", 
"all.read_meter.read_start_date":"Reading start date", 
"all.read_meter.read_method":"Reading method", 
"all.read_meter.cons_m3":"Consumption m<sup>3</sup>", 
"all.read_meter.cons_history_m3":"Consumption history (m<sup>3</sup>)", 
"all.read_meter.cons_history_m3_facturation":"Consumption billing history (m<sup>3</sup>)", 
"all.read_meter.cons_history_m3_radio":"Consumption radio history (m<sup>3</sup>)", 
"all.read_meter.type.M":"Manual", 
"all.read_meter.type.A":"$t(all.radio.radio)", 
"all.read_meter.type.T":"Remote reading", 
"all.read_meter.type.E":"Estimated", 
"all.read_meter.consumptions":"Consumptions", 
"all.read_meter.read_meters":"Read meters", 
"all.read_meter.not_read_meters":"Unread meters", 
"all.alarm_meter.alarm":"Alarm", 
"all.alarm_meter.alarm_plural":"Alarms", 
"all.alarm_meter.alarm_type":"Alarm types", 
"all.alarm_meter.alarms_list":"Alarm list", 
"all.alarm_meter.show_masked_alarm_plural":"Show masked alarms", 
"all.alarm_meter.alarm_history_plural":"Alarm history", 
"all.radio.radio":"Radio", 
"all.radio.radio_plural":"Radios", 
"all.radio.all_radio_nb":"Total number of radios ", 
"all.radio.current_radio":"Current radio", 
"all.radio.go_to_radio":"Go to radio", 
"all.radio.no_linked_radio":"No radio linked", 
"all.radio.radio_history":"Radio history", 
"all.radio.no_old_radio_linked":"No previous linked radio", 
"all.radio.radio_manufacturer":"Radio manufacturer", 
"all.radio.radio_model":"Radio model", 
"all.radio.mount_date":"Setup date", 
"edit_device.field_label.phone_name":"Phone name", 
"edit_device.field_error_msg.name_required_min_2_char":"The phone name is necessary and has to be more than 2 characters", 
"edit_device.main_title.edit_phone":"Edit a phone", 
"edit_device.main_title.delete_phone":"Deleting mobile device", 
"edit_device.text.code_max_2_char":"Billing code of device cannot be more than 2 characters", 
"edit_device.modal.text_confirm":"Are you sure you want to delete this device ?", 
"edit_device.modal.text_warning_confirm":"Are you really sure you want to delete this device ?", 
"edit_device.modal.text_warning_confirm_round":"Warning! This device currently contains a route", 
"edit_device.modal.text_warning_confirm_x_round":"Warning! This device currently contains {{count}} routes", 
"gestionnaires.main_title.managers":"$t(all.text.manager_plural)", 
"gestionnaires.tree_view_placeholder.search_manager":"Search a manager", 
"import_constructeur.main_title.import_mfr":"$t(all.text.name)", 
"import_silex.main_title.import_silex":"$t(all.text.silex_import)", 
"import_silex.input_label.silex_file":"Silex file", 
"import_silex.tab_label.file_import":"Round import", 
"import_silex.tab_label.facturation_import":"Importation de facturation", 
"import_silex.error_band.load_x_file_failed":"Downloading silex file {{fileName}} failed", 
"import_silex.loading_band.silex_file_loading":"Import of Silex file in progress", 
"import_silex.loading_band.import_facturation_loading":"Import for facturation in progress", 
"import_silex.error_band.import_error":"An error occurred during import", 
"import_silex.facturation.error_filtering":"Error filtering", 
"import_silex.facturation.error_filtering_warning":"Warning, some data might not be imported.", 
"import_silex.facturation.error_filtering.relaunch":"Restart", 
"import_silex.facturation.error_filtering_description":"Would you like to re-run the import, excluding the incorrect and/or erroneous data?", 
"import_silex.error_band.facturation_unreachable":"An error occurred when connecting to the billing service", 
"import_silex.error_band.facturation_errors_detected":"Errors were detected during the data import.", 
"import_silex.submission_title.submission_file":"$t(all.text.submit_file)", 
"import_silex.button.admin_submit":"Submit as admin (T2 & T4 take into account)", 
"import_silex.button.radio_file_submit":"Submit radio file", 
"import_silex.success_msg.x_loaded_silex_file":"Downloading silex file done, {{count}} route imported", 
"import_silex.success_msg.x_loaded_silex_file_plural":"Downloading silex file done, {{count}} routes imported", 
"import_silex.success_msg":"Downloading silex file done", 
"import_silex.report_title.importation_report":"Importation report", 
"import_silex.column_header.round_code":"$t(all.round.round_code)", 
"import_silex.column_header.round_name":"$t(all.round.round_name)", 
"import_silex.column_header.round_status":"Status", 
"import_silex.column_header.first_row":"First row", 
"import_silex.column_header.last_row":"Last row", 
"import_silex.status_text.already_exist":"$t(all.text.already_exist)", 
"import_silex.status_text.imported":"$t(all.text.imported)", 
"import_silex.error_band.already_exists_round":"The round {{round}} already exists.", 
"import_silex.error_band.already_exists_rounds":"The rounds {{rounds}} already exists.", 
"import_silex.loading_band.analysis_in_progress":"Analysing the current file", 
"import_silex.loading_band.import_in_progress":"Importing \"{{label}}/{{code}}\", round {{number}}/{{numberOfRound}}", 
"import_silex.loading_band.validating_import":"Validating the new data", 
"import_silex.facturation_import.title":"Import from facturation", 
"import_silex.facturation_import.button_label":"Start import", 
"import_silex.field_name.previousIndexOverMax":"The previous index exceeds the number of wheels", 
"import_silex.field_name.currentIndexOverMax":"The current index exceeds the number of wheels", 
"import_silex.field_name.radio_model_not_found":"The radio model is unknown", 
"import_silex.warning_text.meterWithUnidentifiedModelWheelsChangeIsBlockedByRadio":"The number of wheels could not be changed because the meter is linked to a radio", 
"import_silex.error_data_field.meterWheelsCantBeRevised":"The number of wheels could not be changed because the meter is certified", 
"import_silex.error_data_field.currentIndexOverMax":"The current meter index is above the maximum meter index", 
"import_silex.error_data_field.previousIndexOverMax":"The previous meter index is above the maximum meter index", 
"import_silex.error_data_field.meterAlreadyChanged":"Meter already replaced by another meter", 
"import_silex.facturation.import.title":"Round import from billing", 
"import_silex.facturation.intervention.title":"Intervention import", 
"import_silex.facturation.intervention.hint_text":"Choose the intervention dates and select a user :", 
"import_silex.facturation.intervention.choose_user":"Choose user :", 
"import_silex.facturation.intervention.no_user_saved":"No user saved", 
"import_silex.facturation.intervention.import_error":"An error occured during the intervention round import", 
"import_silex.facturation.intervention.import_success":"{{importNb}} intervention round(s) integrated", 
"import_silex.facturation.intervention.launch":"Launch intervention import", 
"import_silex.facturation.intervention.add_edit_user":"Add / edit an intervention user", 
"import_silex.facturation.intervention.no_intervention_found":"No Interventions found", 
"import_manufacturer.ERR_MNF_PAIRING_METER_SERIAL_INVALID":"Meter serial invalid", 
"import_manufacturer.ERR_MNF_PAIRING_RADIO_SERIAL_INVALID":"Radio serial invalid", 
"import_manufacturer.ERR_MNF_PAIRING_RADIO_ADDRESS_INVALID":"Radio address invalid", 
"import_manufacturer.ERR_MNF_PAIRING_RADIO_MODEL_UNKNOWN":"Radio model unknown", 
"import_manufacturer.ERR_MNF_PAIRING_METER_MODEL_UNKNOWN":"Meter model unknown", 
"import_manufacturer.ERR_MNF_PAIRING_METER_SERIAL_DUPLICATED":"Meter serial duplicated", 
"import_manufacturer.ERR_MNF_PAIRING_RADIO_SERIAL_DUPLICATED":"Radio serial duplicated", 
"import_manufacturer.ERR_MNF_PAIRING_RADIO_WITHOUT_KEY":"Radio without key", 
"import_manufacturer.ERR_MNF_PAIRING_PULSE_WEIGHT_INVALID":"Pulse weight invalid", 
"import_manufacturer.conflict.resolution":"Several formats detected", 
"import_manufacturer.conflict.select":"Select a manufacturer import format", 
"import_manufacturer.conflict.format":"Format", 
"import_manufacturer.conflict.format_with_param":"Format detected", 
"import_manufacturer.success.lines":"Lines number", 
"import_manufacturer.success.keys":"Keys added", 
"import_manufacturer.success.meters":"Meters added", 
"import_manufacturer.success.radios":"Radios added", 
"import_manufacturer.success.links":"Meter/radio pairing added", 
"import_manufacturer.success.errors":"Errors: {{value}}", 
"import_manufacturer.success":"The {{filename}} file was successfully imported", 
"import_manufacturer.failure":"Import of {{filename}} file failed", 
"list_adder.main_title.add_x_list":"Add a {{type}} list", 
"list_adder.modal_header.add_x_type":"Add {{type}}", 
"list_adder.modal_header.file_x_type":"{{type}} file", 
"list_marquages.loading_band.loading_marking":"Marking import in progress", 
"list_messages.loading_band.loading_msg":"Message import in progress", 
"list_message_add_file":"add file", 
"list_message_no_file":"No file selected", 
"list_message_or":"OR", 
"list_message_manually":"Manually add", 
"list_message_add":"Add", 
"synchronisation.loading_band.loading_rounds_":"Import of routes and phones in progress", 
"synchronisation.option.complete_sync":"Full synchronisation", 
"synchronisation.option.partial_sync":"Partial synchronization", 
"synchronisation.error_band.devices_not_connected":"Some devices linked to the route are not connected", 
"synchronisation.error_handling":"An error occurred on the round {{tournee}}", 
"synchronisation.conflict_handling":"A conflict occured on the round {{tournee}}", 
"synchronisation.error_list":"Error list on the round {{tournee}}", 
"synchronisation.assign_rounds":"Assign round(s) to device {{device}}", 
"synchronisation.assign_unread.from_rounds":"Assign only unread meters to the device {{device}}", 
"synchronisation.partial":"Partial", 
"synchronisation.full":"Full synchronisation", 
"synchronisation.full_partial":"Full and partial", 
"synchronisation.type":"Synchronisation type", 
"synchronisation.multi_selection":"Multi-select", 
"tournee_ressources.title_tab.rounds":"$t(all.round.round_plural)", 
"tournee_ressources.column_text.affected_phone":"Affected phone(s)", 
"tournee_ressources.column_text.round_code":"$t(all.column_text.code)", 
"tournee_ressources.column_exported_tooltip.unload_round":"Unloaded route", 
"tournee_ressources.column_exported_tooltip.load_round":"Uploaded route", 
"tournee_ressources.column_exported_tooltip.waiting_load":"Awaiting upload", 
"tournee_ressources.column_exported_tooltip.unload_all":"Disaffect all", 
"tournee_ressources.column_exported_tooltip.waiting_unload":"Awaiting unload", 
"tournee_ressources.column_exported_tooltip.exported_x_date":"Exported the {{date}}", 
"tournee_ressources.column_exported_tooltip.never_exported":"Round never exported", 
"tournee_ressources.column_exported_tooltip.exported_round":"Exported route", 
"tournee_ressources.tooltip.delete_round":"Delete route", 
"tournee_ressources.tooltip.export_round":"Export route", 
"tournee_ressources.tooltip.access_round_detail":"Go to route details", 
"tournee_ressources.popup_title.ask_delete_x_round":"Delete route {{roundName}} ?", 
"tournee_ressources.popup_title.some_round_cant_delete":"Some of the selected rounds cannot be deleted", 
"tournee_ressources.deletable_rounds_confirmation":"Do you nonetheless want to delete the following rounds ? ", 
"tournee_ressources.no_round_can_be_deleted":"None of the selected rounds can be deleted", 
"tournee_ressources.popup_title.ask_delete_x_round_plural":"Delete selected routes ?", 
"tournee_ressources.popup_msg.delete_x_round_data":"This irreversible action will delete all data of route {{roundName}} .", 
"tournee_ressources.popup_msg.delete_x_round_data_plural":"This irreversible action will delete all data of this routes: [ {{roundName}} ].", 
"tournee_ressources.popup_msg.delete_x_round_not_eligible":"The tracked route cannot be deleted because it is being used by a phone or not exported : {{roundNameNotEligible}}.", 
"tournee_ressources.popup_msg.delete_x_round_not_eligible_plural":"The tracked routes cannot be deleted because they are being used by a phone or not exported : [ {{roundNameNotEligible}} ].", 
"tournee_ressources.column.avancement":"Advancement", 
"synchro.command.listround":"Rounds informations recovery", 
"synchro.command.listdevice":"Device connection", 
"synchro.command.uploadround":"Loading round", 
"synchro.command.downloadround":"Download round", 
"synchro.command.downloadroundresponse":"Download round response", 
"synchro.command.closedevice":"End of communication", 
"synchro.command.closedeviceresponse":"End of communication response", 
"synchro.command.listroundresponse":"Rounds informations recovery response", 
"synchro.command.uploadroundresponse":"Upload round response", 
"synchro.command.listdeviceresponse":"Device list response", 
"synchro.command.removeround":"Unloading round", 
"synchro.command.removeroundresponse":"Unloading round response", 
"synchro.command.downloadmark":"Download marks", 
"synchro.command.downloadmessage":"Download messages", 
"synchro.command.busydevice":"Pending ....", 
"synchro.command.error":"Error", 
"synchro.command.downloadamrkey":"Downloading AMR keys", 
"tournee_ressources.delete_round":"Delete round", 
"tournee_ressources.cannot_delete_round":"Round could not be deleted", 
"tournee_ressources.round_not_selected":"This round was not selected", 
"tournee_ressources.round_pending_affectation":"round pending upload", 
"tournee_ressources.round_pending_deaffectation":"round pending unload", 
"tournee_ressources.round_modif_without_export":"round edited without having been exported", 
"tournee_ressources.round_used_by_devices":"round used by devices", 
"tournee_ressources.round_never_exported":"this round was never exoported", 
"tournee_ressources.deleting_round":"Deleting round", 
"tournee_ressources.deleting_round_plural":"Deleting multiple rounds", 
"conflict_handling.change_type.meter_change":"Meter change", 
"conflict_handling.title.singular":"Conflict", 
"conflict_handling.title.plural":"Conflicts", 
"conflict_handling.title.neutral":"Conflict(s)", 
"conflict_handling.associated_devices":"Devices linked to the conflict", 
"conflict_handling.author":"Author of this conflict resolution", 
"conflict_handling.handling.main_instruction":"Select the modifications you wish to apply to this round ", 
"conflict_handling.execution_time.delayed":"Apply during the next synchronisation ", 
"conflict_handling.execution_time.immediate":"Apply immediately", 
"conflict_handling.no_conflict_handling":"No conflict resolution", 
"conflict_handling.loading":"Conflcit resolution loading", 
"conflict_handling.no_conflict_handling_on_round":"No conflict resolution on this round", 
"conflict_handling.conflict_open_button":"Click here to learn more", 
"conflict_handling.conflict_in_round":"Ongoing conflict(s) on round", 
"conflict_handling.conflict_in_round_and_device":"Ongoing conflict(s) between this round and the following device(s)", 
"conflict_handling.no_date":"No date", 
"conflict_handling.conflict_in_device":"A conflict was generated by this device", 
"tournee_detail.info_container.round_code":"$t(all.round.round_code)", 
"tournee_detail.info_container.round_msg":"Route message", 
"tournee_detail.info_container.start_read_date":"$t(all.read_meter.read_start_date)", 
"tournee_detail.info_container.all_meter_nb":"$t(all.meter.all_meter_nb)", 
"tournee_detail.info_container.all_radio_nb":"$t(all.radio.all_radio_nb)", 
"tournee_detail.map_title.any_meter_gps":"Any geolocated meter", 
"tournee_detail.field_placeholder.write_note":"$t(all.text.write_note)", 
"tournee_detail.tooltip.edit_note":"Edit the message", 
"tournee_detail.main_title.round_name":"$t(all.round.round_name)", 
"tournee_fiche.main_title.record":"$t(all.text.record)", 
"tournee_fiche.main_title.record_plural":"$t(all.text.record_plural)", 
"tournee_fiche.tooltip.invalid_cache":"Invalidate  cache", 
"tournee_fiche.loading_band.load_round_record":"Downloading route records", 
"tournee_stat.container_title.radio_type":"AMR radio type", 
"tournee_stat.container_title.radio_read_state":"AMR reading state", 
"tournee_stat.container_title.cons_state":"Consumption state", 
"tournee_stat.container_title.status_edited":"Status of edits", 
"tournee_stat.container_state_read.not_read":"Not read AMR", 
"tournee_stat.container_state_read.not_radio":"Not AMR", 
"tournee_stat.container_state_read.read_with_alarm":"AMR Read with alarm", 
"tournee_stat.container_state_read.read_with_no_alarm":"AMR Read without alarm", 
"tournee_stat.container_label.edited_not_indexed":"Edited not indexed", 
"tournee_stat.container_label.edited_not_indexed_plural":"Edited not indexed", 
"tournee_stat.container_label.not_edited_not_indexed":"Not edited/indexed", 
"tournee_stat.container_label.not_edited_not_indexed_plural":"Not edited/indexed", 
"tournee_stat.container_title.nb_meter_year":"Number of installed meters by year", 
"tournee_stat.container_title.nb_meter":"Number of meter(s)", 
"tournee_creation.container_label.template":"Creation of your subscriber/meter information template", 
"tournee_creation.input_label.template_name":"Name of your template", 
"tournee_creation.container_label.create_tournee":"Create a route", 
"tournee_creation.container_label.template_list":"List of your templates", 
"tournee_creation.container_label.tournee_informations":"Route informations", 
"tournee_creation.container_label.tournee_template":"Route template", 
"tournee_creation.input_label.radio_without_template":"Use existing data", 
"tournee_creation.input_label.radio_with_template":"Apply a template", 
"tournee_creation.input_label.autorize_meter_change":"Autorise meter change", 
"tournee_creation.dropzone.or_drop_file":"or drop file here", 
"tournee_creation.dropzone.drop_autorized":"And release!", 
"tournee_creation.dropzone.drop_unautorized":"You can only import one file at a time", 
"tournee_creation.dropzone.incorrect_format":"Incorrect file format. Authorised formats : {{fileformats}}", 
"tournee_creation.dropzone.authorized_format":"Format {{fileformats}}", 
"tournee_creation.button.start":"Start", 
"tournee_creation.input_label.line_x":"Line {{lineNumber}}", 
"tournee_creation.input_label.information_on_default":"If the template doesn't generate any data, a default text will replace the information.", 
"tournee_creation.placeholder.default_info":"No data generated", 
"tournee_creation.container_label.template_creation":"Create your lines", 
"tournee_creation.container_label.tag_creation":"Create a tag", 
"tournee_creation.container_label.tag_modification":"Create a tag", 
"tournee_creation.container_label.list_tag":"List of your tags", 
"tournee_creation.container_label.list_tag_subtitle":"Use your tags to fill your lines", 
"tournee_creation.container_label.tag_with_start_index":"Select a specific part", 
"tournee_creation.input_label.modifiable":"Modifiable", 
"tournee_creation.input_label.unmodifiable":"Unmodifiable", 
"tournee_creation.input_label.code_placeholder":"Enter the route code", 
"tournee_creation.input_label.message_placeholder":"Enter your message", 
"tournee_creation.input_label.wheels_placeholder":"Number of wheels (Default: 5)", 
"tournee_creation.input_label.pdi_template_placeholder":"Select your POI template", 
"tournee_creation.input_label.meter_template_placeholder":"Select your meter template", 
"tournee_creation.template_popover.title":"Template deletion", 
"tournee_creation.template_popover.confirm_text":"Are you sure you want to delete this template?", 
"tournee_creation.input_error.empty_round_name":"Please enter the route name", 
"tournee_creation.input_error.round_name_already_exists":"This route name already exists", 
"tournee_creation.input_error.empty_round_code":"Please enter the route code", 
"tournee_creation.input_error.pattern_round_code":"The round code can only contains letters, numbers and underscores", 
"tournee_creation.input_error.round_code_already_exists":"This route code already exists", 
"tournee_creation.input_error.empty_template":"Please select a template", 
"tournee_creation.input_error.round_wheels_too_high":"The number of wheels must be an integer between 4 and 9", 
"tournee_creation.input_error.round_wheels_too_low":"$t(tournee_creation.input_error.round_wheels_too_high)", 
"tournee_creation.input_error.template_name_already_exists":"This template name already exists", 
"tournee_creation.input_error.empty_template_name":"Please enter a template name", 
"tournee_creation.input_error.template_name_too_long":"The name of the template is too long. Max: 30 characters", 
"tournee_creation.input_error.line_too_long":"The theoretical number of characters exceeds the maximum of 30, the excess will be ignored", 
"tournee_creation.input_error.default_info_too_long":"The default text is too long. Max: 30 characters", 
"tournee_creation.input_error.empty_tag_name":"Please enter the tag name", 
"tournee_creation.input_error.tag_name_already_exists":"This tag name already exists", 
"tournee_creation.input_error.tag_name_is_too_long":"The tag name is too long. Max: 16 characters", 
"tournee_creation.input_error.empty_tag_info":"Please select the information to be displayed", 
"tournee_creation.input_error.empty_tag_size":"Please select the tag size", 
"tournee_creation.input_error.tag_size_too_high":"The tag size must be between 1 and 30", 
"tournee_creation.input_error.tag_size_too_low":"$t(tournee_creation.input_error.tag_size_too_high)", 
"tournee_creation.input_label.type_text":"$t(enquete.field_type.A)", 
"tournee_creation.input_label.type_number":"Number", 
"tournee_creation.input_label.type_date":"$t(all.date.date)", 
"tournee_creation.success.templateCreation":"{{successTarget}} template created", 
"tournee_creation.success.templateUpdate":"{{successTarget}} template updated", 
"tournee_creation.success.templateDeletion":"{{successTarget}} template deleted", 
"tournee_creation.success.tagCreation":"{{successTarget}} tag created", 
"tournee_creation.success.tagUpdate":"{{successTarget}} tag updated", 
"tournee_creation.success.tagDeletion":"{{successTarget}} tag deleted", 
"tournee_creation.success.roundCreation":"{{tourneeName}} route created", 
"tournee_creation.loading_band.template_creation":"Template being created", 
"tournee_creation.loading_band.template_update":"Template being modified", 
"tournee_creation.loading_band.template_deletion":"Template being deleted", 
"tournee_creation.loading_band.tag_creation":"Tag being created", 
"tournee_creation.loading_band.tag_update":"Tag being modified", 
"tournee_creation.loading_band.tag_deletion":"Tag being deleted", 
"tournee_creation.loading_band.tournee_creation":"Route being created", 
"tournee_creation.error_band.tag_not_found":"Tag {{tag}} not found", 
"tournee_creation.error_band.undefined":"An error has occurred", 
"tournee_creation.error_band.tag_still_linked":"Cannot delete tag {{tag}}. The tag is still linked to following templates : {{templates}}", 
"tournee_creation.error_band.errors_in_tag":"Errors have been found in sent tag : {{errors}}", 
"tournee_creation.error_band.template_not_found":"Template {{template}} not found", 
"tournee_creation.error_band.errors_in_template":"Errors have been found in sent template : {{errors}}", 
"tournee_creation.error_band.tag_in_use":"Cannot delete tag {{tag}}. Tag in use.", 
"tournee_creation.error_band.errors_in_tournee":"Errors have been found in sent route : {{errors}}", 
"tournee_creation.error_band.template_still_linked":"Unable to delete the {{template}} template. The template is still linked to the following route: {{rndList}}", 
"tournee_creation.input_error.tournee_name_is_empty":"Route name is empty", 
"tournee_creation.input_error.tournee_name_already_exists":"Route name already exists", 
"tournee_creation.input_error.tournee_code_is_empty":"Route code is empty", 
"tournee_creation.input_error.tournee_default_wheels_too_low":"Default wheels number is below 4", 
"tournee_creation.input_error.tournee_default_wheels_too_high":"Default wheels number is over 9", 
"tournee_creation.input_error.tournee_collect_point_template_is_missing":"Subscriber template not filled in", 
"tournee_creation.input_error.tournee_meter_template_is_missing":"Meter template not filled in", 
"tournee_creation.input_error.tournee_meter_list_is_empty":"Meter's list is empty", 
"tournee_creation.input_error.tournee_meter_list_size_over_limit":"Number of meters is over 9999", 
"tournee_creation.input_info.remaining_characters_plural":"{{nbRemainingChar}} remaining characters", 
"tournee_creation.input_info.remaining_characters":"{{nbRemainingChar}} remaining character", 
"tournee_creation.popover.tag_too_long":"The tag cannot be added. The size of the tag exceeds the number of characters remaining on the line.", 
"import_factu.import_error.invalid_date":"Invalid date", 
"import_factu.import_error.intervention_notFound":"Intervention round not found", 
"import_factu.column.errorType":"Error type", 
"import_factu.import_report.show_last_report":"Display the latest import report", 
"create_meter.main_title.create_meter":"Create a meter", 
"create_meter.field_validate.serial_least_5_char":"The serial number has to contain at least 5 characters", 
"create_meter.field_validate.serial_max_20_char":"The serial number has to contain maximum 20 characters", 
"create_meter.field_validate.serial_valid":"Fill in a valid serial number", 
"create_meter.field_error_msg.need_serial":"The serial number has to be filled in", 
"create_meter.success_band.meter_created":"The meter has been created", 
"create_pdi.main_title.create_pdi":"Create a POI", 
"location_address_chooser.loading_band.search_gps":"Searching GPS coordinates ...", 
"location_address_chooser.link_container_title.link_meter":"Link a meter", 
"location_address_chooser.success_band.pdi_created":"The POI has been created", 
"location_address_chooser.warning_message.format_city_name":"Le nom de la ville ne doit pas contenir de chiffres ou de caractères spéciaux", 
"location_address_chooser.warning_message.format_streetnumber_name":"Le numéro de la voie doit contenir exclusivement des chiffres", 
"location_address_chooser.warning_message.format_zipcode_name":"Le code postal doit contenir 5 chiffres", 
"stock_importation.text.launch_by":"Launched by ", 
"stock_importation.main_title.import_billing":"$t(all.text.import_billing)", 
"stock_importation.input_file_label.import_file":"Import file (.csv,.tsv,.xls,.xlsx)", 
"stock_importation.step_1_loading_band.send_file":"Sending file ...", 
"stock_importation.step_2_loading_band.check_file":"Checking file ...", 
"stock_importation.step_3_success_band.integration_x_row_and_x_modified":"Integration successful.{{nbRow}} rows processed, including {{count}} edited", 
"stock_importation.step_3_success_band.nb_meter_archived":"{{nbRow}} archived POI", 
"stock_importation.step_3_success_band.integration_x_row_and_x_modified_plural":"Integration successful.{{nbRow}} rows processed, including {{count}} edited", 
"stock_importation.error.serial_too_long":"The serial number {{element}} is larger than 12 characters", 
"stock_importation.error.wheels_wrong_value":"The number of wheels {{element}} is not between 4 and 9", 
"stock_importation.error.date_too_old":"The date {{element}} is prior to 01/01/1970", 
"stock_importation.error.index_too_long":"The size of the {{element}} index is greater than 9", 
"stock_importation.error.depose_date_expected":"The removal date of the old meter is missing", 
"stock_importation.error.serial_number_missing":"The serial number of the old meter is missing", 
"stock_importation.error.depose_index_expected":"The removal index of the old meter is missing", 
"stock_importation.importation_report.action_meter_change":"Meter change", 
"stock_importation.importation_report.action_creation":"Creation", 
"stock_importation.importation_report.action_unauthorized":"Change rejected", 
"stock_importation.importation_report.action_modification":"Modification", 
"stock_importation.importation_report.detail_address":"Address", 
"stock_importation.importation_report.detail_start_date":"Start date", 
"stock_importation.importation_report.detail_meter_serial":"Meter serial number", 
"stock_importation.importation_report.detail_notes":"Notes", 
"stock_importation.importation_report.detail_custom_info":"Custom information", 
"stock_importation.importation_report.detail_state":"State", 
"stock_importation.importation_report.detail_revised_wheels":"Number of wheels", 
"stock_importation.importation_report.detail_meter_model":"Meter model", 
"stock_importation.importation_report.detail_empty":"Empty", 
"stock_importation.importation_report.download_button":"Download the report", 
"stock_importation.importation_report.download_loading":"Sending the current report", 
"stock_importation.importation_report.download_error":"An error occurred while sending the file", 
"stock_importation.importation_report.detail_gps_longitude":"GPS Coordinates - Longitude", 
"stock_importation.importation_report.detail_gps_latitude":"GPS Coordinates - Latitude", 
"stock_importation.importation_report.detail_gps_altitude":"GPS Coordinates - Altitude", 
"stock_importation.importation_report.detail_gps_score":"GPS Coordinates - Score", 
"stock_importation.importation_report.detail_gps_accuracy":"GPS Coordinates - Accuracy", 
"stock_importation.error.not_match_config":"The file does not match the configuration", 
"stock_importation.error.not_match_config.column_numbers_not_match":"The file does not match the configuration. The number of columns in the file does not match the number of columns in the configuration.", 
"stock_importation.error.not_match_config.first_cell_not_recognised":"The file does not match the configuration. The reference cell has not been recognised.", 
"import.loading_band.import_already_loading":"An import is already in progress", 
"import.step_1_title.first_step_load_file":"First step: loading file", 
"import.step_1_success_band.load_file":"Download of file complete", 
"import.step_1_loading_band.file_analysis":"Checking file ...", 
"import.step_2_title.second_step_check_error":"Second step : Check file errors", 
"import.list.x_row":"Row {{row}},    POI {{pdi}},    Serial number {{serial}}", 
"import.step.warning":"Warning :", 
"import.step_2_help.check_error_for_import":"Please check errors above to test the integration", 
"import.step_2_button.test_integration":"Test integration", 
"import.step_2_success_band.test_integration":"Integration test done", 
"import.step_3_title.third_step_check_integration":"Third step : Check integration problems", 
"import.step_error.error":"Errors", 
"import.step_3_help.check_error_for_finish_import":"Please check errors above to finalize integration", 
"import.step_3_loading_band.integration_file":"File integrating ...", 
"import.step_4_title.integration_report":"Integration report", 
"import.anomaly_text.emptyMeterSerial":"Empty meter serial number", 
"import.anomaly_text.dateLineError":"Incorrect date (under 1970)", 
"import.anomaly_text.emptyPDIReference":"Empty POI reference", 
"import.anomaly_text.doublonMeter":"Duplicated meter serial number", 
"import.anomaly_text.doublonPdi":"Duplicate POI reference", 
"import.anomaly_text.unfoundModel":"Model meter not found", 
"import.anomaly_text.datePoseBeforeActual":"Install date of file prior to install date in base", 
"import.anomaly_text.collectPointUsedByRound":"POI with a meter change but in the process of reading", 
"import.anomaly_text.meterUsedByRound":"Meter with a POI change but in the process of reading", 
"import.anomaly_text.doublons":"Duplicates", 
"import.anomaly_text.doublonsMeter":"Duplicate meters", 
"import.anomaly_text.doublonsPdi":"Duplicates POI", 
"import.anomaly_text.field_with_date_inf_1970":"Field with an incorrect date ", 
"import.anomaly_text.datePoseNull":"Installation date is empty", 
"import.anomaly_text.doublonDetail":"The meter is present in the routes : {{value}}", 
"import_component.input_file_label.import_file":"Import file", 
"import_component.step_2_loading_band.testing_file":"Testing file ...", 
"import_component.step_3_success_band.integration_x_row_success":"Integration successful. {{count}} row processed.", 
"import_component.step_3_success_band.integration_x_row_success_plural":"Integration successful. {{count}} rows processed.", 
"alert_content.alarm_title.alarm_mfr":"Manufacturer alarms", 
"alert_content.alarm_title.read_gap":"Reading difference", 
"alert_content.alarm_title.real_cons":"Real consumption", 
"alert_content.alarm_title.cons_avg":"Average consumption", 
"alert_content.alarm_title.cons_comparison":"$t(all.read_meter.cons_comparison)", 
"alert_content.alarm_title.cons_gap":"$t(all.read_meter.cons_gap)", 
"alert_content.alarm_title.cons_alert":"Consumption alerts", 
"alert_content.alarm_text.alarm_meter_since_integration":"Meter alarm (since last integration)", 
"alert_content.alarm_text.cons_of_period":"Consumption during the predefined period", 
"alert_content.alarm_text.read_gap_today":"Reading difference compared to today", 
"alert_content.alarm_text.cons_limit_since_integration":"Consumption limit min and/or max (m3) for all consumptions since last integration", 
"alert_content.alarm_text.m3_cons_interval_of_cons_avg":"Consumption interval min and/or max (in m3) between current average consumption and last average consumption (since last integration)", 
"alert_content.alarm_text.cons_interval_of_two_meter":"Max consumption interval (in percentage) between last consumption of meter 1 and meter 2 (since last integration)", 
"alert_content.alarm_text.pct_cons_interval_of_cons_avg":"Interval of consumption min and/or max (in pourcentage) between average consumption and periodic average consumption (for the last month/week/day)", 
"alert_content.alarm_result.result":"$t(all.text.result)", 
"alert_content.alarm_result.x_cons_gap":"Consumption difference : {{gapCons}}", 
"alert_content.meter_type.ref":"$t(all.text.reference)", 
"alert_content.meter_type.witness":"Witness", 
"alert_content.table_title.alert_meter_list_and_info":"Virtual meter alert list, and associated information", 
"alert_content.table_title.alert_virtual_meter_list_and_info":"Virtual meter alert list, and associated information", 
"alert_content.warning.no_alert_found":"No alert was found in this period", 
"alert_content.column_text.gap_last_date":"Difference since last date", 
"alert_content.column_text.to_date":"Upto", 
"alert_content.column_text.since_date":"Since", 
"alert_content.column_text.ref_cons":"Reference consumption", 
"alert_content.column_text.avg_day_cons":"average consumption per day", 
"alert_content.column_text.cons_gap":"$t(all.read_meter.cons_gap)", 
"alert_content.column_text.read_date":"Reading date", 
"alert_creator.name.rangeconsumption":"$t(all.read_meter.cons)", 
"alert_creator.name.gapconsumption":"$t(all.read_meter.cons_gap)", 
"alert_creator.name.gapconsumptioncomparison":"$t(all.read_meter.cons_comparison)", 
"alert_creator.name.alarms":"$t(alert_content.alarm_title.alarm_mfr)", 
"alert_creator.name.averagerangeconsumption":"$t(alert_content.alarm_title.cons_avg)", 
"alert_creator.name.rangerealconsumption":"$t(alert_content.alarm_title.real_cons)", 
"alert_creator.name.noreadformeteralert":"Late of meter read", 
"alert_creator.name.consumptionhour":"Consumption during a schedule", 
"alert_creator.extra_info.rangeconsumption":"Consumption limit min and/or max (m3) for last periodic average consumption (for the last month/week/day)", 
"alert_creator.extra_info.gapconsumption":"$t(alert_content.alarm_text.pct_cons_interval_of_cons_avg)", 
"alert_creator.extra_info.gapconsumptioncomparison":"$t(alert_content.alarm_text.cons_interval_of_two_meter)", 
"alert_creator.extra_info.alarms":"$t(alert_content.alarm_text.alarm_meter_since_integration)", 
"alert_creator.extra_info.averagerangeconsumption":"$t(alert_content.alarm_text.m3_cons_interval_of_cons_avg)", 
"alert_creator.extra_info.rangerealconsumption":"$t(alert_content.alarm_text.cons_limit_since_integration)", 
"alert_creator.extra_info.noreadformeteralert":"Last read of meter before today minus zoom gap", 
"alert_creator.extra_info.consumptionhour":"Consumptions has been detected during predefined schedule", 
"alert_creator.unit.number":"Alarms number", 
"alert_creator.unit.m3":"m3", 
"alert_creator.unit.percent":"Percent", 
"alert_creator.unit.time":"Time", 
"best_date_component.label.start_date":"$t(all.text.start_date)", 
"best_date_component.label.end_date":"$t(all.text.end_date)", 
"best_date_component.label.zoom":"$t(all.text.zoom)", 
"best_date_component.zoom_option.hour":"Hour", 
"best_date_component.zoom_option.day":"Day", 
"best_date_component.zoom_option.week":"Week", 
"best_date_component.zoom_option.month":"Month", 
"best_date_component.zoom_option.year":"Year", 
"best_date_component.text.custom":"Custom ", 
"best_date_component.error":"Start date must be less than end date", 
"bread_crumb.name.crystal_cloud":"CrystalCloud", 
"bread_crumb.name.location":"$t(all.location.location)", 
"bread_crumb.name.location_plural":"$t(all.location.location_plural)", 
"bread_crumb.name.round_plural":"$t(all.round.round_plural)", 
"bread_crumb.name.masks":"$t(all.mask.mask_plural)", 
"bread_crumb.name.dashboard":"Dashboard", 
"bread_crumb.name.dashboard_plural":"$t(all.dashboard.dashboard_plural)", 
"bread_crumb.name.user":"$t(all.user.user)", 
"bread_crumb.name.user_plural":"$t(all.user.user_plural)", 
"bread_crumb.name.widget":"Widget", 
"bread_crumb.name.widget_plural":"$t(all.text.widgets)", 
"bread_crumb.name.meter":"$t(all.meter.meter)", 
"bread_crumb.name.meter_plural":"$t(all.meter.meter_plural)", 
"bread_crumb.name.virtual_meter":"$t(all.text.virtual_meter)", 
"bread_crumb.name.virtual_meter_plural":"$t(all.meter.virtual_meter_plural)", 
"bread_crumb.name.radio":"$t(all.radio.radio)", 
"bread_crumb.name.radio_plural":"$t(all.radio.radio_plural)", 
"bread_crumb.name.pdi":"$t(all.pdi.pdi)", 
"bread_crumb.name.pdi_plural":"POI's", 
"bread_crumb.name.new":"$t(all.text.new)", 
"bread_crumb.name.search":"$t(all.button.search)", 
"bread_crumb.name.notif":"$t(all.text.notif)", 
"bread_crumb.name.notif_plural":"$t(all.text.notif_plural)", 
"bread_crumb.name.alert":"$t(all.alert.alert)", 
"bread_crumb.name.alert_plural":"$t(all.alert.alert_plural)", 
"bread_crumb.name.detail":"Detail", 
"bread_crumb.name.detail_plural":"$t(all.text.detail_plural)", 
"bread_crumb.name.report":"Report", 
"bread_crumb.name.report_plural":"Reports", 
"bread_crumb.name.stock":"$t(all.text.stock)", 
"bread_crumb.name.create_pdi":"Creation of POI", 
"bread_crumb.name.create_meter":"Creation of meter", 
"bread_crumb.name.billing_import":"$t(all.text.import_billing)", 
"bread_crumb.name.link_pdi":"POI link", 
"bread_crumb.name.unlink_pdi":"POI unlink", 
"bread_crumb.name.synchro":"$t(all.text.synchro)", 
"bread_crumb.name.round":"$t(all.round.round)", 
"bread_crumb.name.record":"$t(all.text.record)", 
"bread_crumb.name.record_plural":"$t(all.text.record_plural)", 
"bread_crumb.name.phone":"$t(all.text.phone)", 
"bread_crumb.name.phone_plural":"$t(all.text.phone_plural)", 
"bread_crumb.name.import_mfr":"$t(all.text.import_mfr)", 
"bread_crumb.name.silex_import":"$t(all.text.silex_import)", 
"bread_crumb.name.msg_list":"Message list", 
"bread_crumb.name.marking_list":"Marking list", 
"bread_crumb.name.manager":"$t(all.text.manager)", 
"bread_crumb.name.manager_plural":"$t(all.text.manager_plural)", 
"bread_crumb.name.export":"$t(all.text.name.export)", 
"bread_crumb.name.stat":"Stat", 
"bread_crumb.name.stat_plural":"$t(all.text.stat_plural)", 
"bread_crumb.name.forbidden_access":"$t(all.text.forbidden_access)", 
"bread_crumb.name.profile":"$t(all.user.profile)", 
"bread_crumb.name.profile_plural":"$t(all.user.profile_plural)", 
"bread_crumb.name.edit_x_virtual_meter":"Edition of {{virtualMeterName}}", 
"bread_crumb.name.edit_x_alarm":"Edition of {{alarmName}}", 
"bread_crumb.name.edit_x_mask_alarm":"Edition of {{maskAlarmName}}", 
"bread_crumb.name.edit_x_custom_export":"Edition of {{customExport}}", 
"bread_crumb.name.personal_dashboard_of_x":"Personal dashboard of {{userName}}", 
"bread_crumb.name.telereleve":"$t(all.read_meter.type.T)", 
"bread_crumb.name.provisionning":"Provisionning", 
"bread_crumb.name.support":"Support", 
"bread_crumb.name.sync":"$t(all.text.synchro)", 
"bread_crumb.name.remotereading":"$t(all.read_meter.type.T)", 
"bread_crumb.name.synchrotools":"Synchro mode tools", 
"bread_crumb.name.importbilling":"$t(all.text.import_billing)", 
"bread_crumb.name.export_brut":"Gross export", 
"bread_crumb.name.new_tournee":"$t(sidebar_synchro.nav_link.new_round)", 
"bread_crumb.name.create_tournee":"Route creation", 
"bread_crumb.name.templates":"Templates", 
"confirm.msg.you_sure":"Are you sure ?", 
"confirm.title.warning":"Warning !", 
"confirm.confirm_text.ok":"$t(all.text.ok)", 
"error_boundary.main_title.cannot_show_page":"The page could not be displayed correctly.", 
"error_boundary.data":"A data conflict has been detected", 
"forbidden.main_title.not_authorized":"$t(all.text.forbidden_access)", 
"help_modal.button_send.send_msg":"Send a message", 
"help_modal.main_title.send_msg_to_support":"Send a message to support", 
"help_modal.success_msg.msg_sent_thanks":"Mail sent, thanks for your message", 
"help_modal.field_label.msg_subject":"Subject message", 
"help_modal.field_required.min_size_10_char":"Field requires at least 10 characters", 
"help_modal.field_subject_error.max_size_10_char":"The message subject has to be at least 10 characters", 
"help_modal.field_msg_error.max_size_10_char":"Please fill in message", 
"not_found.main_title.not_found":"404 Page not found", 
"notification_modal.tooltip_notif.notif":"$t(all.text.notif)", 
"notification_modal.tooltip_notif.notif_plural":"$t(all.text.notif_plural)", 
"notification_modal.tooltip_notif.x_notif_not_read":"Notifications : {{count}} not read", 
"notification_modal.tooltip_notif.x_notif_not_read_plural":"Notifications : {{count}} not read", 
"search.main_title_label.fast_search":"Fast search", 
"search.main_title.general":"General", 
"search.input_search.serial_or_address":"Serial number or address", 
"search.info_tooltip.search_in_pdi_meter_radio":"Search in POI, meters and radio (at least 3 characters)", 
"search.search_result.result_of_search":"Results for search", 
"search.search_result.x_pdi":"POI : {{pdiResult}}", 
"search.search_result.x_meter":"Meter : {{meterResult}}", 
"search.search_result.x_meter_plural":"Meters : {{meterResult}}", 
"search.search_result.x_radio":"Radio : {{radioResult}}", 
"search.search_result.x_radio_plural":"Radio : {{radioResult}}", 
"search.info_title.general_info":"General information", 
"search.info_title.general_info_plural":"General information", 
"search.record_access_button.go_to_record":"$t(all.round.go_to_record)", 
"sidebar.nav_link.home":"Home", 
"sidebar.nav_link.location":"$t(all.location.location)", 
"sidebar.nav_link.location_plural":"$t(all.location.location_plural)", 
"sidebar.nav_link.round":"$t(all.round.round)", 
"sidebar.nav_link.round_plural":"$t(all.round.round_plural)", 
"sidebar.nav_link.profile_manager":"Manage profile", 
"sidebar.nav_link.profile_manager_plural":"Manage profiles", 
"sidebar.nav_link.user_manager":"Manage user", 
"sidebar.nav_link.user_manager_plural":"Manage users", 
"sidebar.nav_link.my_info_plural":"$t(all.user.my_info_plural)", 
"sidebar.nav_link.info_plural":"$t(all.text.information_plural)", 
"sidebar.nav_link.import_billing":"$t(all.text.import_billing)", 
"sidebar.nav_link.global_search":"Global search", 
"sidebar.nav_link.lora_import":"$t(all.text.lora_import)", 
"sidebar.nav_link.installer_manager":"Manage installer", 
"sidebar_location.nav_link.round_detail_plural":"Route details", 
"sidebar_location.nav_link.location_detail_plural":"Location details", 
"sidebar_location.nav_link.record_plural":"$t(all.text.record_plural)", 
"sidebar_location.nav_link.stat_plural":"$t(all.text.stat_plural)", 
"sidebar_location.nav_link.dashboard_plural":"$t(all.dashboard.dashboard_plural)", 
"sidebar_location.nav_link.alert_plural":"$t(all.alert.alert_plural)", 
"sidebar_location.nav_link.mask_alarm_plural":"Mask alarms", 
"sidebar_location.nav_link.meter_plural":"$t(all.meter.meter_plural)", 
"sidebar_location.nav_link.virtual_meter_plural":"$t(all.meter.virtual_meter_plural)", 
"sidebar_location.nav_link.radio":"$t(all.radio.radio)", 
"sidebar_location.nav_link.pdi":"$t(all.pdi.pdi)", 
"sidebar_location.nav_link.search":"$t(all.button.search)", 
"sidebar_location.nav_link.export":"$t(all.text.name.export)", 
"sidebar_stock.nav_title.stock":"$t(all.text.stock)", 
"sidebar_stock.nav_link.resources_list":"$t(all.text.resource_list)", 
"sidebar_stock.nav_link.create_meter":"Creation of meter", 
"sidebar_stock.nav_link.create_pdi":"Creation of POI", 
"sidebar_synchro.nav_title.synchro":"$t(all.text.synchro)", 
"sidebar_synchro.nav_link.round":"$t(all.round.round)", 
"sidebar_synchro.nav_link.round_plural":"$t(all.round.round_plural)", 
"sidebar_synchro.nav_link.phone":"$t(all.text.phone)", 
"sidebar_synchro.nav_link.phone_plural":"$t(all.text.phone_plural)", 
"sidebar_synchro.nav_link.import_mfr":"$t(all.text.import_mfr)", 
"sidebar_synchro.nav_link.msg_list":"Message list", 
"sidebar_synchro.nav_link.marking_list":"Marking list", 
"sidebar_synchro.nav_link.silex_import":"$t(all.text.silex_import)", 
"sidebar_synchro.nav_link.enquiry":"Survey", 
"sidebar_synchro.nav_link.enquiry_plural":"Surveys", 
"sidebar_synchro.nav_link.new_round":"New route", 
"sidebar_tournees.nav_link.round_detail":"Route details", 
"sidebar_tournees.nav_link.record_plural":"$t(all.text.record_plural)", 
"sidebar_tournees.nav_link.stat_plural":"$t(all.text.stat_plural)", 
"single_gmap.loading_band.loading_map":"Loading map", 
"single_gmap.failure_text.not_able_show_map":"Impossible to show map", 
"single_gmap.map_setting.language":"en", 
"single_gmap.text.no_radio":"$t(all.text.none_male)", 
"tableau_gestion.menu_option.add":"Add", 
"tableau_gestion.menu_option.unlink":"$t(all.text.unlink)", 
"tableau_gestion.menu_option.link":"$t(all.text.link)", 
"tableau_gestion.menu_option.alarm_plural":"$t(all.alarm_meter.alarm_plural)", 
"tableau_gestion.loading_band.exporting":"Export in progress...", 
"tableau_gestion.error_band.exporting":"Unable to export data", 
"tournee_navbar.nav_tooltip.synchro":"$t(all.text.synchro)", 
"tournee_navbar.nav_tooltip.stock":"$t(all.text.stock)", 
"tournee_navbar.nav_tooltip.dashboard_favorite_plural":"$t(all.dashboard.dashboard_favorite_plural)", 
"tournee_navbar.nav_tooltip.my_info_plural":"My info", 
"tournee_navbar.nav_tooltip.disconnect":"$t(all.user.logout)", 
"edit_dashboard.main_title.edit_dashboard":"Edit a dashboard", 
"edit_dashboard.name_field_label.dashboard_name":"$t(all.dashboard.dashboard_name)", 
"edit_dashboard.name_field_error_msg.name_need_least_2_char":"$t(all.dashboard.name_need_least_2_char)", 
"locations.loading_band.loading_location_info":"Loading location information", 
"locations.button.access_round":"$t(all.round.go_to_round)", 
"locations.error_msg.no_location_found":"No match", 
"locations.placeholder.search_location":"Search a location", 
"locations.placeholder.search_rounds":"Search a route", 
"locations.title.all_rounds":"All", 
"locations.text.total_nb_meter":"$t(all.meter.all_meter_nb)", 
"locations.text.total_nb_sensor":"Total number of sensor", 
"locations.text.total_nb_radio":"$t(all.radio.all_radio_nb)", 
"locations.text.no_sub_location":"No sub-location", 
"dashboard_helper.dashboard_name.my_dashboard":"My dashboard", 
"dashboard_helper.dashboard_name.dashboard_of_x":"{{userName}} dashboard ", 
"fiche_detail.label.detail_plural":"$t(all.text.detail_plural)", 
"fiche_detail.label.diameter":"Diameter", 
"fiche_detail.label.install_year":"Install year", 
"fiche_detail.label.wheel_nb":"$t(all.meter.wheels_number)", 
"fiche_detail.label.revision":"Revision", 
"fiche_detail.label.mfr":"$t(all.meter.mfr)", 
"fiche_detail.label.model":"$t(all.text.model)", 
"fiche_detail.label.msg":"$t(all.text.message)", 
"fiche_detail.label.particular_msg":"$t(all.text.particular_msg)", 
"fiche_detail.title.new_meter":"New meter", 
"fiche_detail.title.old_meter":"Old meter", 
"fiche_detail.title.old_meter_plural":"Old meters", 
"fiche_detail.label.show_old_meter":"See old meter", 
"fiche_detail.label.show_new_meter":"See new meter", 
"fiche_detail.title.meter":"$t(all.meter.meter)", 
"fiche_detail.title.revise_meter":"Revised meter", 
"fiche_detail.title.install_read":"Install index", 
"fiche_detail.title.uninstall_read":"$t(columns.previousMeter.deposeIndex)", 
"fiche_detail.title.cons_m3":"$t(all.read_meter.cons_m3)", 
"fiche_detail.title.avg":"$t(all.text.average)", 
"fiche_detail.label.radio":"$t(all.radio.radio)", 
"fiche_detail.label.new_radio":"New radio", 
"fiche_detail.label.old_radio":"Old radio", 
"fiche_detail.label.show_old_radio":"See old radio", 
"fiche_detail.label.show_new_radio":"See new radio", 
"fiche_detail.label.internal_id":"Internal user", 
"fiche_detail.label.install_date":"$t(all.text.install_date)", 
"fiche_detail.label.pulse_weight":"$t(all.meter.pulse_weight)", 
"fiche_detail.error_msg.no_radio":"No radio", 
"fiche_detail.title.historical_alarm_plural":"$t(all.alarm_meter.alarm_history_plural)", 
"fiche_detail.title.previous_alarm_plural":"Previous alarms", 
"fiche_detail.title.current_alarm_plural":"Current alarms", 
"fiche_detail.title.record_number":"Record number", 
"fiche_detail.label.marking":"$t(all.text.marquage)", 
"fiche_detail.label.last_update":"$t(all.text.update_date)", 
"fiche_detail.title.no_gps":"No GPS coordinates", 
"fiche_detail.title.historical_cons":"Consumption history (in m<1>3</1>)", 
"fiche_detail.title.start_index":"Start index", 
"fiche_detail.title.closest_reference_index":"Reference index", 
"fiche_detail.title.previous_index":"Previous index", 
"fiche_detail.title.end_index":"End index", 
"fiche_detail.title.current_index":"Current index", 
"fiche_detail.text.no_alarm":"No alarm", 
"fiche_detail.text.saisie_index":"Index input", 
"fiche_detail.title.round":"Round sheet", 
"fiche_detail.conso.range_tooltip":"The average is no longer included in the consumption range", 
"fiche_detail.conso.null_range_tooltip":"There should be no consumption on this meter", 
"fiche_detail.error.revised_serial":"An error has occurred, check that the revised number is not already in use and that it is correct!", 
"fiche_detail.text.edit_revised_serial":"Edit the meter number", 
"fiche_detail.error.edit_revised_serial_radio":"Unable to modify a meter with a radio", 
"fiche_detail.error.edit_revised_serial_index":" Unable to modify an indexed counter", 
"fiche_detail.error.edit_meter_radio":"Impossible to change a meter with a radio", 
"fiche_detail.text.edit_meter":"Change the meter", 
"fiche_detail.error.max_number":"The maximum number of the field is {{max}}", 
"fiche_detail.error.min_number":"The minimum number of the field is {{min}}", 
"fiche_detail.popover.confirm_deletion_change_meter":"Do you want to permanently delete this counter change?", 
"fiche_detail.popover.confirm_deletion_change_meter_confirm":"The readings linked to this meter will be deleted and cannot be recovered, are you sure you want to delete this meter change?", 
"fiche_detail.error.change_meter":"An error has occurred, check that the serial number is not already used and that it is correct!", 
"fiche_detail.error.remove_change_meter_radio":"Impossible to delete a meter change with a radio", 
"fiche_detail.text.remove_change_meter":"Delete meter change", 
"fiche_detail.popover.confirm_deletion_radio":"Would you like to remove the radio?", 
"fiche_detail.text.remove_radio":"Remove the radio", 
"fiche_detail.popover.confirm_deletion_revised_serial":"Do you want to remove the revised serial number?", 
"fiche_detail.popover.confirm_deletion_radio_confirm":"This action is irreversible, are you sure you want to remove this radio?", 
"index_wrapped.label.read":"Reading", 
"index_wrapped.read_method.radio":"$t(all.read_meter.type.A)", 
"index_wrapped.read_method.manual":"$t(all.read_meter.type.M)", 
"index_wrapped.label.date":"$t(all.date.date)", 
"index_wrapped.no_data.index_not_present":"Index not present", 
"index_wrapped.popover.title":"Current index lower than previous one", 
"index_wrapped.popover.confirm_text":"The current index is lower than the previous index, has the meter gone round?", 
"index_wrapped.popover.confirm_deletion_text":"Are you sure you really want to delete this read ?", 
"index_wrapped.updated_by":"Updated by : ", 
"static_list.label.not_indexed_manual_read":"Not indexed (manual reading)", 
"static_list.label.not_indexed_radio_read":"Not indexed (radio reading)", 
"static_list.label.not_indexed_telereleve_read":"Not indexed (remote reading)", 
"static_list.label.edited_manual_read":"Edited (manual reading)", 
"static_list.label.edited_radio_read":"Edited (radio reading)", 
"static_list.label.edited_with_telereleve":"Edited (remote reading)", 
"static_list.label.indexed_no_radio":"Indexed (manual reading) without radio", 
"static_list.label.indexed_with_radio":"Indexed (manual reading) with radio", 
"static_list.label.indexed_with_telereleve":"Indexed (manual reading) with remote reading", 
"static_list.label.indexed_radio_read":"Indexed (radio reading)", 
"static_list.label.indexed_telereleve_read":"Indexed (remote reading)", 
"static_list.label.indexed_radio_read_with_alarm":"Indexed with alarms (radio reading)", 
"static_list.label.indexed_telereleve_read_with_alarm":"Indexed with alarms (remote reading)", 
"static_list.label.normal":"Normal", 
"static_list.value.normal_cons":"Normal consumption", 
"static_list.label.weak":"Weak", 
"static_list.value.weak_cons":"Weak consumption", 
"static_list.label.nul":"Nul", 
"static_list.value.nul_cons":"No consumption", 
"static_list.label.strong":"Strong", 
"static_list.value.strong_cons":"Strong consumption", 
"column_list.label.pdi_ref":"$t(all.pdi.pdi_ref)", 
"column_list.label.meter":"$t(all.meter.meter)", 
"column_list.label.id_meter":"Meter id", 
"column_list.label.serial_meter":"$t(all.meter.meter_serial)", 
"column_list.label.revised_serial_meter":"Revised meter serial number", 
"column_list.label.c":"c", 
"column_list.label.city":"$t(all.address.city)", 
"column_list.label.country":"$t(all.address.country)", 
"column_list.label.index_meter":"$t(all.meter.meter_index)", 
"column_list.label.serial_radio":"$t(all.meter.radio_serial)", 
"column_list.label.alarm":"Alarm", 
"column_list.label.altitude":"Altitude", 
"column_list.label.network_code":"Network code", 
"column_list.label.round_code":"$t(all.round.round_code)", 
"column_list.label.radio_mfr":"$t(all.radio.radio_manufacturer)", 
"column_list.label.meter_mfr":"$t(all.meter.meter_manufacturer)", 
"column_list.label.last_read_date":"$t(all.read_meter.read_last_date)", 
"column_list.label.sealing_color":"Sealing color", 
"column_list.label.meter_diameter":"$t(all.meter.real_meter_diameter)", 
"column_list.label.radio_id":"Radio id", 
"column_list.label.pdi_id":"POI id", 
"column_list.label.meter_id":"Meter id", 
"bookmark_location.error_band.impossible_get_favorite":"Impossible to retrieve the favorites list", 
"bookmark.loading_band.loading_favorite_dashboard":"Loading favorites dashboards", 
"display_bookmark.link_text.dashboard_nb_x":"Dashboard {{dashboardIndex}}", 
"column_selector.no_options_msg.no_more_column_available":"No more available columns", 
"column_selector.no_options_msg.no_corresponding_column":"No corresponding columns", 
"column_selector.text.column_plural":"Columns", 
"column_selector.text.delete_saved_conf":"Delete saved configuration", 
"column_selector.text.no_saved_col_conf":"No saved column configuration", 
"home_page.title.hello":"Hello", 
"home_page.subtitle.your_dashboard":"Your favorite dashboards", 
"list_render.list_type.pdi":"$t(all.pdi.pdi)", 
"list_render.list_type.radio_plural":"$t(all.radio.radio_plural)", 
"list_render.list_type.meter_plural":"$t(all.meter.meter_plural)", 
"list_render.list_type.virtual_meter_plural":"$t(all.meter.virtual_meter_plural)", 
"list_render.delete_modal_title.delete_x_virtual_meter":"Delete {{rowName}} virtual meter ?", 
"list_tools.label.cons_state":"Consumption state", 
"list_tools.text.cons":"Consumption", 
"notifications.tooltip.delete_selected":"Delete selection", 
"notifications.loading_band.loading_notification_plural":"Loading notifications", 
"notifications.popup_delete_title.delete_selected_notification_plural":"Delete selected notifications", 
"notifications.popup_delete_title.delete_notification":"Delete the notification", 
"notifications.popup_delete_msg.sure_delete_selected_notification_plural":"This irreversible action will delete the selected notifications", 
"notifications.popup_delete_msg.sure_delete_notification":"This irreversible action will delete this notification", 
"historique_compteur.title.install_date":"Installment date", 
"historique_compteur.title.uninstall_date":"Uninstallment date", 
"historique_compteur.text.dashboard_1":"Dashboard 1", 
"historique_compteur.text.serial_meter":"Meter serial number", 
"historique_compteur.text.actual_meter":"Current meter", 
"historique_compteur.text.go_to_meter_detail":"Go to meter details", 
"porte_ouverte.label.duration_door_opening":"Opening door duration (in minutes)", 
"porte_ouverte.label.door_opening":"Opening door", 
"location_creator.title.add_location":"Add location", 
"location_creator.title.edit_location":"Edit location", 
"identity_chooser.text.location_name":"Location name", 
"identity_chooser.text.new_location_name":"New location name", 
"identity_chooser.text.link_to_location":"Link to location", 
"identity_chooser.text.select_location_to_link":"Select location to link  to your new location", 
"identity_chooser.input_option.no_location":"$t(all.text.none_male)", 
"identity_chooser.text.location_x_belong_to_first_level":"The{{locationName}} location will be a first level location.", 
"identity_chooser.text.location_x_belong_to_location_x":"The{{locationName}} location will be a sub-location of {{belongTo}}.", 
"identity_chooser.error_msg.max_length_name":"The name must have less than 25 letters", 
"identity_chooser.error_msg.max_length_name_required":"The field is required and must have less than 25 letters", 
"identity_chooser.warning_msg.no_location_to_link":"No available location to link", 
"identity_chooser.button.create_location":"Create location", 
"identity_chooser.button.edit_location":"Edit location", 
"widget.type_source.meter":"$t(all.meter.meter)", 
"widget.type_source.radio":"$t(all.radio.radio)", 
"widget.display_type.curve":"Curve ", 
"widget.display_type.histogram":"Histogram", 
"widget.display_value.meterreadingvalue":"Meter index", 
"widget.display_value.meterreadingconsumption":"Meter consumption", 
"widget.display_value.radioreadingvalue":"Radio index", 
"widget.display_value.meteralarm":"Meter alarm", 
"widget.display_value.metermultireadingvalue":"Multiple meter indexes", 
"widget.display_value.metermultireadingconsumption":"Multiple meter consumption", 
"widget.display_value.meteryield":"Consumption efficiency", 
"widget.condition_title.meterserialnumber":"$t(all.meter.meter_serial)", 
"widget.condition_title.radioserialnumber":"$t(all.meter.radio_serial)", 
"widget.condition_title.meterserialnumberref":"$t(all.meter.meter_serial_ref)", 
"widget.condition_title.meterserialnumber_":"$t(all.meter.meter_serial_control)", 
"widget.condition_title.readingdateinterval":"Reading interval ", 
"widget.condition_title.alarmtype":"$t(all.alarm_meter.alarm_type)", 
"widget.condition_title.meters":"$t(all.meter.meter_plural)", 
"widget.condition_title.rangeconsumption":"Consumption interval", 
"widget.condition_title.gapconsumption":"Consumption limit", 
"widget.condition_title.meterslistautocomplete":"Affected meters", 
"widget.condition_title.period":"Period", 
"widget.condition_title.meterserialnumber_1":"$t(all.meter.meter_serial_control)", 
"widget.graph_type.index_meter_histogram":"Meter index histogram ", 
"widget.graph_type.index_meter_curve":"Meter index curve ", 
"widget.graph_type.consumption_meter_histogram":"Meter consumption histogram ", 
"widget.graph_type.consumption_meter_curve":"Meter consumption curve ", 
"widget.graph_type.alarm_meter":"$t(widget.display_value.meteralarm)", 
"widget.graph_type.multiple_meter_consumption_histogram":"Multi-meter consumption histogram", 
"widget.graph_type.multiple_meter_consumption_curve":"Multi-meter consumption curve", 
"widget.graph_type.multiple_meter_index_histogram":"Multi-meter index histogram ", 
"widget.graph_type.multiple_meter_index_curve":"Multi-meter index consumption ", 
"widget.graph_type.index_radio_histogram":"Radio index histogram ", 
"widget.graph_type.index_radio_curve":"Radio index curve ", 
"widget.graph_type.meter_yield_curve":"Meter yield curve ", 
"widget_last.text.no_alarm_type":"$t(all.text.none_male)", 
"widget_last.title.summary":"$t(all.text.summary)", 
"widget_last.text.widget_name":"Widget name", 
"widget_last.text.widget_type":"Widget type", 
"widget_last.text.widget_display":"Display widget", 
"widget.text.not_configured":"Not configured", 
"location_creator.button.check_on_map":"Check the map", 
"location_creator.title.location_place":"Locating the site", 
"location_creator.text.address_entry":"Entry address", 
"location_creator.button.edit_address":"Edit address", 
"location_creator.text.corrected_address":"Address corrected", 
"location_creator.text.choose_address":"Choose between corrected and entry address", 
"location_creator.title.location_contact":"Contact for location", 
"location_creator.title.location_note":"Note for location", 
"location_creator.placeholder.write_note":"$t(all.text.write_note)", 
"location_creator.text.no_location_selected":"No location selected", 
"display_dashboards.title.favorite_dashboard":"$t(all.dashboard.dashboard_favorite_plural)", 
"display_dashboards.title.dashboard_plural":"$t(all.dashboard.dashboard_plural)", 
"display_dashboards.filter_placeholder.filter_by_dashboard":"Filter by dashboard", 
"display_dashboards.button.delete_dashboard":"Delete dashboard", 
"display_dashboards.button.rename_dashboard":"Rename dashboard", 
"display_dashboard.confirm_modal_title.delete_x_dashboard":"Delete {{dashboardName}} dashboard?", 
"display_dashboards.delete_modal.title":"Suppression of a dashboard", 
"display_dashboards.delete_modal. warning_confirm_dashboard":"This dashboard is {{ownerFirstName}} {{ownerLastName}}'s personal dashboard", 
"display_dashboards.delete_modal.warning_confirm":"Do you really want to delete this dashboard?", 
"display_dashboards.delete_modal.confirm":"Are you sure you want to delete this dashboard?", 
"add_dashboard.title.add_dashboard":"Add dashboard", 
"add_dashboard.text.dashboard_name":"$t(all.dashboard.dashboard_name)", 
"add_dashboard.error_msg.dashboard_name_error":"$t(all.dashboard.name_need_least_2_char)", 
"dashboard.button.switch_display_mode":"Switch to presentation mode", 
"dashboard.button.exit_display_mode":"Quit presentation mode", 
"dashboard.button.delete_widget_from_dashboard":"Delete widget from dashboard", 
"dashboard.button.delete_from_favorite":"Delete favorites", 
"add_widget.button.add_widget":"Add widget", 
"add_widget.text.reading_value":"Reading value", 
"add_widget.text.source":"Source", 
"add_group.title":"Add group", 
"widget_creator.title.configure_widget":"Configure widget", 
"widget_option.error_msg.widget_name_error":"Widget name required and must have more than 3 characters", 
"range_configurator.title.gap_consumption_percent":"Consumption limit (percentage)", 
"alarm_creator.title.create_alert":"Create an alert", 
"alarm_creator.text.add_alert":"Add alert", 
"alarms.filter_placeholder.alert_filter":"Filter by alert", 
"alarm_configurator.title.alert_edition":"Edit alert", 
"alarm_configurator.placeholder.alert_name":"$t(all.alert.alert_name)", 
"alarm_options.text.calculation_method":"$t(all.text.calculation_method)", 
"alarm_options.text.alert_name_required_with_min_length":"Alert name is required and must have at least 2 characters", 
"period_selector.text.trigger_period":"Trigger period", 
"period_selector.text.schedule_interval":"Schedule interval", 
"mask_alarm.button.access_card":"Access card", 
"mask_alarm.text.delete_mask_action":"This irreversible action will delete the mask from location", 
"mask_alarm.text.nb_days_with_alarm":"Nb of days with alarm", 
"mask_alarm.text.no_meter_associated":"No associated meter", 
"mask_alarm.text.alarm_of_meters":"Meter alarms ", 
"mask_alarm.text.no_alarm_masked":"No masked alarm ", 
"source_sheet.text.internal_number":"Internal number", 
"source_sheet.text.current_meter":"Current meter", 
"source_sheet.text.no_linked_meter":"No meter linked", 
"source_sheet.text.meter_linked_history":"Linked meters history", 
"source_sheet.text.no_old_meter_linked":"No old linked meter", 
"printable_source_sheet.text.x_data_from_x_to_x_zoom_x":"Data from {{name}} - from {{start}} to {{end}} - Zoom : {{zoom}}", 
"virtual_meter.text.last_best_read":"Latest best reading ", 
"virtual_meter.text.invalid":"Invalid", 
"virtual_meter.text.read_meter_plural":"Read meters", 
"virtual_meter.text.only_valid_read_plural":"Valid meter readings only ", 
"virtual_meter.text.load_index":"Loading indexes", 
"virtual_meter.text.load_consumption":"Loading consumptions", 
"virtual_meter.text.load_last_best_read":"Loading the latest best reading", 
"virtual_meter.text.load_alarms":"Loading alarms ", 
"alarm_selector.text.type_to_display_plural":"Types to display", 
"saving_settings_modal.text.saving_conf":"Saving configuration", 
"saving_settings_modal.text.backup_name":"Backup name", 
"source_sheet_pdi.text.alarm_mask":"Alarm mask", 
"source_sheet_pdi.text.display_interval":"Display interval", 
"source_sheet_pdi.text.meter_loading":"Meter loading", 
"source_sheet_pdi.text.terrain":"Terrain", 
"source_sheet_pdi.text.many_data_loading":" Lots of data to recover. Please wait ...", 
"source_sheet_pdi.text.invalid_interval":"Invalid interval", 
"source_sheet_pdi.text.averages_unavailable":"Average unavailable", 
"source_sheet_pdi.text.no_data_display":"No available data ", 
"source_sheet_pdi.text.display_type_read":"Display my reading type", 
"source_sheet_pdi.text-unique_read":"The time interval above only contains one read.", 
"export_pdi.text.export_preview":"Preview (the first 5 preview)", 
"export_pdi.text.refresh_preview":"Refresh preview", 
"export_pdi.text.cvs_export_progress":"CSV exporting ...", 
"export_pdi.text.cvs_export_done":"CSV export done", 
"export_pdi.text.separator":"Separator", 
"export_pdi.text.comma":"Comma", 
"export_pdi.text.semicolon":"Semicolon", 
"export_pdi.text.vertical_bar":"Verticle bar", 
"export_pdi.text.auto":"Auto", 
"export_pdi.text.releve":"Reading", 
"export_pdi.text.facturation":"Billing", 
"users.text.user_management":"User management", 
"users.text.search_user":"Search user", 
"users.text.add_user":"Add user", 
"users.text.add_tourmaline_user":"Add tourmaline user", 
"users.text.edit_user":"Edit user", 
"add_user.button.add_new_user":"Add new user", 
"add_user.field.ucid_code_error":"UCID code is required and has to have more than 16 characters", 
"add_user.field.email_unique":"Email, unique", 
"add_user.field.email_error":"Invalid email", 
"add_user.field.name_error":"Name is required and has to have more than 2 characters", 
"add_user.field.field_only_alphabetical":"Field is only alphabetical", 
"add_user.field.minimum_x_size":"Field minimum size is {{min}} characters", 
"add_user.field.maximum_x_size":"Field maximum size is {{max}} characters", 
"user.text.remove_profile":"Remove profile", 
"user.text.select_location":"Select location", 
"user.text.select_profile":"Select profile", 
"user.text.remove_permission_to_user":"You are going to remove permission to this user", 
"user.text.last_connection":"Last connection", 
"user.text.generate_pwd":"Generate password", 
"user.text.reset_pwd":"Reset password", 
"user.text.admin_all_gestionnaires":"You are admin on all managers", 
"user.text.admin_all_locations":"You are admin on all locations", 
"user.text.user_is_admin":"This user has an admin profile", 
"user.text.select_lang":"Select a language", 
"user.text.lang_choosen":"Chosen language", 
"user.text.select_role":"Select a role ", 
"user.text.please_fill_password":"Please enter a password, at least 8 characters, must contain at least one capital letter and one number", 
"user.text.new_password":"New password", 
"user.text.rewrite_new_password":"Re enter your password", 
"user.text.password_not_match":"Passwords do not match ", 
"user.text.delete":"Be careful, the deletion is definitive, the user and his dashboard will be delete, created dashboards by user will be reinserted, connection datas will be delete", 
"user.text.unlock":"This action will block the user, he will be able to connect again", 
"user.text.lock":"This action will lock the user, he will no longer be able to connect", 
"user.text.sure":"Are you sure about that", 
"user.text.nb.phone":"Number of phones", 
"user.text.nb.deliveries":"Number of deliveries", 
"user.text.authorization":"Authorization", 
"user.text.language":"Language", 
"user.text.french":"French", 
"user.text.english":"English", 
"user.text.spanish":"Spanish", 
"user.button.save.key":"Save this section", 
"location_modal.title_ask.delete_x_location":"Delete {{locationName}} location ?", 
"location_modal.text.delete_location_effect":"This irreversible action will delete all data trom this location.", 
"columns.text.date_added":"Date added (default)", 
"columns.text.abc_order":"A -> Z", 
"columns.text.abc_disorder":"Z -> A", 
"columns.text.max_to_min":"+ -> -", 
"columns.text.min_to_max":"- -> +", 
"columns.text.code_abc_order":"$t(all.text.code) $t(columns.text.abc_order)", 
"columns.text.code_abc_disorder":"$t(all.text.code) $t(columns.text.abc_disorder)", 
"columns.text.wording_abc_order":"Wording $t(columns.text.abc_order)", 
"columns.text.wording_abc_disorder":"Wording $t(columns.text.abc_disorder)", 
"columns.text.actions_max_to_min":"Actions $t(columns.text.max_to_min)", 
"columns.text.actions_min_to_max":"Actions $t(columns.text.min_to_max)", 
"columns.text.loaded_max_to_min":"Loaded $t(columns.text.max_to_min)", 
"columns.text.loaded_min_to_max":"Loaded $t(columns.text.min_to_max)", 
"avancement_chart.text.indexed_x_on_x":"$t(all.text.indexed) : {{count}} / {{totIndexed}}", 
"avancement_chart.text.indexed_x_on_x_plural":"$t(all.text.indexed_plural) : {{count}} / {{totIndexed}}", 
"avancement_chart.text.edited_x_on_x":"$t(all.text.modified) : {{count}} / {{totEdited}}", 
"avancement_chart.text.edited_x_on_x_plural":"$t(all.text.modified_plural) : {{count}} / {{totEdited}}", 
"device_info.text.waiting_action":"$t(all.text.waiting_action)", 
"device_info.text.waiting_action_plural":"$t(all.text.waiting_action_plural)", 
"device_info.text.x_loaded_round":"{{count}} loaded route", 
"device_info.text.x_loaded_round_plural":"{{count}} loaded routes", 
"device_info.text.first_com":"First communication", 
"device_info.error_text.load":"Unable to load data", 
"device_info.error_text.integrate":"Unable to integrate data", 
"device_info.error_text.contact":"Please contact support", 
"device_info_error_load_round":"Unable to load the following round(s):", 
"round_info.text.indexed":"Indexed", 
"round_info.text.remaining":"Remaining", 
"round_info.text.edited":"Edited", 
"round_info.text.indexed_x_percent_plural":"$t(all.text.indexed_plural) ({{percent}}%)", 
"round_info.text.edited_x_percent_plural":"$t(all.text.modified_plural) ({{percent}}%)", 
"round_info.text.loaded_on_x_phone":"Loaded on {{count}} phone", 
"round_info.text.loaded_on_x_phone_plural":"Loaded on {{count}} phones", 
"task_duplication.text.code_x":"code : {{code}}", 
"alarm_updater.text.alert_edition":"Alert edit", 
"conso_interval.text.threshold_required":"You must reassign at least one treshold ", 
"conso_interval.text.threshold_x":"Threshold ({{unit}})", 
"last.text.recipient_user_plural":"Destinated users", 
"manage_alarm.text.x_linked_pdi_to_location":"POI associated to the location", 
"manage_alarm.text.x_linked_pdi_to_location_plural":"POI associated to the location", 
"manage_alarm.text.x_dissociate_pdi_to_location":"POI dismissed from the location", 
"manage_alarm.text.x_dissociate_pdi_to_location_plural":"POI dismissed from the location", 
"cluster_marker_gm.title.meter_detail_plural":"Meter details", 
"cluster_meter_list.text.close_meter_list":"Close the meter list <br />", 
"google_map_react_c.text.center_selected_meter":"Focus on the selected meter <br />", 
"contact_chooser.text.lastname_only_letters":"Last name can only contain letters", 
"contact_chooser.text.firstname_only_letters":"The first name can only contain letters", 
"contact_chooser.text.phone_number_needs_10_number":"Phone number must contain 10 digits", 
"contact_chooser.text.give_name_info":"Reassign a name", 
"contact_chooser.text.give_email_info":"Reassign an email", 
"loraimport.text.nb_link_in_file":"Number of file links", 
"loraimport.text.nb_new_link_in_file":"number of new file links", 
"loraimport.text.import_lorafile_processing":"Importing of the LoRa file ", 
"loraimport.text.import_file_failed":"Loading of the {{filename}} LoRa file failed", 
"loraimport.text.import_lora":"Import LoRa", 
"loraimport.text.lorafile_process_success":"Loading of the LorRa files complete", 
"import_billing.label.header_size":"Header size", 
"import_billing.label.first_cell_row":"Line cell identification", 
"import_billing.label.first_cell_col":"Column cell indentification ", 
"import_billing.label.column_size":"Number of columns", 
"import_billing.label.end_of_first_cell":"End of first cell", 
"import_billing.label.first_line_number":"First line index", 
"import_billing.label.id_cell_row":"Line number", 
"import_billing.label.id_cell_col":"Column number", 
"import_billing.label.id_cell_content":"Content", 
"import_billing.label.id_cell":"Identification cell", 
"import_billing.label.sub":"Subscription", 
"import_billing.label.pdi":"$t(all.pdi.pdi)", 
"import_billing.label.round":"$t(all.round.round)", 
"import_billing.label.cpt":"$t(all.meter.meter)", 
"import_billing.label.else":"$t(all.text.other)", 
"import_billing.label.pdi_ref":"$t(all.text.reference)", 
"import_billing.label.pdi_state":"$t(all.text.state)", 
"import_billing.label.pdi_address_number":"Address number", 
"import_billing.label.pdi_number_additionnal":"$t(all.address.street_number_additionnal)", 
"import_billing.label.pdi_street":"Road", 
"import_billing.label.pdi_additionnal_street":"Additional road", 
"import_billing.label.pdi_complement_address":"$t(all.address.complement)", 
"import_billing.label.pdi_appart":"Apartment", 
"import_billing.label.pdi_floor":"Floor", 
"import_billing.label.pdi_building":"Building", 
"import_billing.label.pdi_stair":"Stair", 
"import_billing.label.pdi_zipcode":"$t(all.address.zipcode)", 
"import_billing.label.pdi_city":"$t(all.address.city)", 
"import_billing.label.pdi_note_plural":"Notes", 
"import_billing.label.round_code":"$t(all.round.round_code)", 
"import_billing.label.round_name":"$t(all.round.round_name)", 
"import_billing.label.cpt_number":"$t(all.meter.serial)", 
"import_billing.label.cpt_info":"Meter info", 
"import_billing.label.cpt_wheels":"Number of meter dials", 
"import_billing.label.cpt_install_date":"$t(all.text.install_date)", 
"import_billing.label.cpt_oldnumber":"Ancien numéro de série compteur", 
"import_billing.label.date_depose":"Date de dépose", 
"import_billing.label.index_depose":"$t(columns.previousMeter.deposeIndex)", 
"import_billing.label.index_pose":"$t(fiche_detail.title.install_read)", 
"import_billing.label.cpt_color_filling":"Colour sealing", 
"import_billing.label.branchement_code_reseau":"Network connection code", 
"import_billing.label.date_start_subscription":"Subscription start date", 
"import_billing.label.date_end_subscription":"Subscription end date ", 
"import_billing.label.state_subscription":"Subscription state", 
"import_billing.label.gps":"GPS", 
"import_billing.label.gps_latitude":"$t(all.text.latitude)", 
"import_billing.label.gps_longitude":"$t(all.text.longitude)", 
"import_billing.label.gps_altitude":"$t(column_list.label.altitude)", 
"import_billing.label.gps_score":"Score", 
"import_billing.label.gps_source":"$t(add_widget.text.source)", 
"import_billing.placeholder.value_select":"Please select a column name", 
"import_billing.state.removed.match":"Archive PDI with one of the states", 
"import_billing.state.inactif.match":"PDI status to inactive IF", 
"pdi.PDI_GPS_LONGITUDE":"$t(all.text.longitude)", 
"pdi.PDI_GPS_LATITUDE":"$t(all.text.latitude)", 
"pdi.PDI_GPS_SCORE":"$t(import_billing.label.gps_score)", 
"pdi.PDI_GPS_SOURCE":"$t(add_widget.text.source)", 
"pdi.PDI_GPS_ALTITUDE":"$t(column_list.label.altitude)", 
"pdi.PDI_GPS_ACCURACY":"Accuracy", 
"pdi.PDI_REF_ID":"ID reference", 
"pdi.PDI_PARENT_REFERENCE":"Parent POI reference", 
"pdi.PDI_INFO":"Subscriber info", 
"pdi.PDI_PARENT_RELATION":"Relationship with the parent POI", 
"export_round_factu":"Billing export", 
"export_round_teleo":"Téléo export", 
"nbColumns":"$t(import_billing.label.column_size)", 
"general.label.round_code":"Default DAT export code ", 
"general.label.round_default_wheels":"Default DAT export dials", 
"general.label.round_libelle":"Labelled default DAT export", 
"general.label.default_language":"Default language", 
"general.label.end_date_demo":"Demo end date", 
"general.label.in_demo_mode":"In demo mode", 
"general.label.saphir_version_current":"Current Saphir version", 
"general.label.skip_trigger_alarm":"Ignore alarm trigger date", 
"general.label.fetch_size_query":"Max size BATCH", 
"general.label.supported_language":"Supported languages (seperated by a comma)  ", 
"general.label.timezone_id":"Timezone ID", 
"general.label.archive.inactif.pdi":"Archiver pdi inconnu fichier facturation", 
"general.label.apply.if_in_round":"Appliquer changement de compteur sur compteur en cours de relève", 
"general.label.telereleve.patrimoine.last_update":"$t(all.text.update_date) patrimoine télérelève", 
"general.ignore.t2t4.mrlId":"Ignore the lines T2 and T4", 
"general.seuil.limit":"limit threshold", 
"general.only.current_read_table.pdi":"Use only last reading", 
"synchronisation.label.export_silex_encoding":"Import/Export coding file", 
"synchronisation.label.export_silex_encoding_detection_method":"Encoding detection method", 
"synchronisation.label.export_silex_name":"RTR file name ", 
"synchronisation.label.telereleve_sup_startdate":"remote reading import > start date of reading", 
"synchronisation.label.refresh_synchro_devices":"Device refresh interval", 
"synchronisation.label.remove_missing_t3":"Remove missing T3", 
"synchronisation.label.silex_export_geoloc":"RTR export with localisation (T7)", 
"synchronisation.label.silex_export_radio":"RTR export with radio (T2/T4)", 
"synchronisation.label.silex_export_enquete":"Export enquete T5/T6", 
"synchronisation.label.silex_import_allow_doublons":"Authorize doublons silex", 
"synchronisation.label.synchro_allow_creation_radiomodel":"Autoriser création Modèle radio", 
"synchronisation.label.stop_and_go":"STOP & GO", 
"synchronisation.label.import_all":"Sync test", 
"synchronisation.label.import_t0":"T0 test", 
"synchronisation.label.import_t1":"T1 test", 
"synchronisation.label.import_t2":"T2 test ", 
"synchronisation.label.import_t3":"T3 test", 
"synchronisation.label.import_t4":"T4 test", 
"synchronisation.label.import_t6":"T6 test", 
"synchronisation.label.import_t7":"T7 test", 
"synchronisation.label.saphir_version_current":"$t(general.label.saphir_version_current)", 
"synchronisation.ignore_invalid_histo_infuture":"Ignore history invalidation in the future", 
"synchronisation.ignore_previous_index_value":"Ignore the comparison between the previous index and the maximum value", 
"synchronisation.option.rotate.round":"Round rotation option ", 
"synchronisation.device":"$t(all.text.phone_plural)", 
"synchronisation.round":"$t(all.round.round_plural)", 
"remote_reading.label.default_format_lora_file":"Lora file format", 
"remote_reading.label.default_format_lora_file_column_number":"Number of columns Lora format", 
"remote_reading.label.has_remote_reading":"Remote-reading option", 
"remote_reading.label.app_eui_column_index":"EUI index app", 
"remote_reading.label.app_key_column_index":"Key index app", 
"remote_reading.label.dev_eui_column_index":"EUI index dev", 
"remote_reading.label.lora_serial_column_index":"Lora serial number", 
"remote_reading.label.radio_serial_column_index":"$t(all.meter.radio_serial)", 
"remote_reading.nbColumns":"$t(import_billing.label.column_size)", 
"report.label.logo_path":"Logo path ", 
"report.label.specific_template_directory":"Folder containing custom templates ", 
"report.label.custom_template_names":"Custom template list ", 
"support.title.report":"$t(bread_crumb.name.report)", 
"meter.unit.W":"m<sup>3</sup>", 
"meter.unit.E":"kWh", 
"meter.unit.":"$t(meter.unit.W)", 
"meter.unit.C":"$t(meter.unit.W)", 
"meter.unit.H":"$t(meter.unit.W)", 
"meter.unit.G":"$t(meter.unit.W)", 
"meter.unit.T":"$t(meter.unit.E)", 
"meter.unit.O":"$t(meter.unit.W)", 
"meter.type.W":"Water", 
"meter.type.E":"Electric ", 
"meter.type.":"$t(meter.type.W)", 
"meter.type.C":"Cold water", 
"meter.type.H":"Hot water", 
"meter.type.G":"Gaz ", 
"meter.type.T":"Thermic", 
"meter.type.O":"$t(all.text.other)", 
"last_reading.text.tooltip_real_previous_index":"The actual previous index is currently displayed and taken into account in the following calculations. Since the previous and current indexes are from the same day you can click on the button below to display the relevant reference index.", 
"last_reading.text.tooltip_reference_index":"Since the current and previous indexes are from the same day, the most recent relevant index taken into account.", 
"last_reading.text.no_last_read":"No last read", 
"last_reading.text.no_other_read":"No other read", 
"last_reading.text.no_previous_read":"No previous read", 
"last_reading.text.index_meter":"$t(widget.display_value.meterreadingvalue)", 
"tournee_band.text.list_devices":"Devices list", 
"releve_meter_status.text.red_manual":"$t(static_list.label.not_indexed_manual_read)", 
"releve_meter_status.text.red_radio":"$t(static_list.label.not_indexed_radio_read)", 
"releve_meter_status.text.yellow_manual":"$t(static_list.label.edited_manual_read)", 
"releve_meter_status.text.yellow_radio":"$t(static_list.label.edited_radio_read)", 
"releve_meter_status.text.green_manual":"$t(static_list.label.indexed_no_radio)", 
"releve_meter_status.text.green_radio":"$t(static_list.label.indexed_with_radio)", 
"releve_meter_status.text.blue_radio":"$t(static_list.label.indexed_radio_read)", 
"releve_meter_status.text.blue_telereleve":"$t(static_list.label.indexed_telereleve_read)", 
"releve_meter_status.text.purple_radio":"$t(static_list.label.indexed_radio_read_with_alarm)", 
"releve_meter_status.text.purple_telereleve":"$t(static_list.label.indexed_telereleve_read_with_alarm)", 
"sensor.text.sensor":"Sensor", 
"sensor.text.sensor_plural":"Sensors", 
"sensor.plannification":"Open planification ", 
"sensor.title.manual_management":"Manual management", 
"sensor.title.synchro":"Sync clock ", 
"sensor.title.frequency":"Frequence frame", 
"sensor.error.wrong_plage":"Incorrect time slots", 
"sensor.error.previous_date_sup":"The start of this time slot cannot be less that the previous one ", 
"sensor.error.fix_previous_plage":"Please correct the previous time slot", 
"sensor.text.minutes":"minutes", 
"sensor.text.hour":"hours", 
"sensor.error.synchro":"Synchronisation error", 
"sensor.title.vanne_status":"Valve status ", 
"sensor.title.open_planned":"Opening planned", 
"sensor.text.remove_interval":"Remove interval", 
"sensor.text.add_interval":"Add interval", 
"sensor.text.when_vanne_open":"When the valve is open", 
"sensor.text.when_vanne_close":"When the valve is closed", 
"sensor.type.STR-EV":"STREGA electrovalve", 
"sensor.type.ADE-COM":"Adeunis Comfort2", 
"sensor.type.ADE-CO2":"Adeunis Comfort CO2", 
"sensor.type.ADE-DRYC":"Adeunis Dry Contact", 
"sensor.type.ADE-DTP":"Adeunis DeltaP", 
"sensor.type.ADE-TEMP":"Adeunis Temp", 
"sensor.text.sensor_type":"Sensor type", 
"sensor.text.serial":"Sensor serial number", 
"sensor.dashboard":"Dashboard sensor", 
"sensor.data.temperature":"$t(all.text.temperature)", 
"sensor.data.temperature2":"Temperature 2", 
"sensor.data.humidity":"$t(all.text.humidity)", 
"sensor.data.co2":"$t(all.text.co2)", 
"sensor.data.pressure":"$t(all.text.pressure)", 
"sensor.data.valveOpen":"Valve open", 
"support.title.importbilling":"$t(all.text.import_billing)", 
"support.title.remotereading":"$t(all.read_meter.type.T)", 
"support.title.sync":"$t(all.text.synchro)", 
"support.title.general":"$t(all.text.general_male)", 
"support.title.column":"$t(column_selector.text.column_plural)", 
"support.title.script":"Script", 
"support.title.import":"Import", 
"support.option.duplicateAllowed":"Autorised duplicates", 
"support.subtitle.autochange":"Configuration of automatic changes", 
"support.subtitle.billing_import":"Import configuration", 
"support.autochange.truncate":"Truncate the serial number", 
"support.autochange.truncate_at":"Truncate to the ", 
"support.autochange.left":"left", 
"support.autochange.right":"right", 
"support.autochange.replace_date":"Correcting dates that are too old", 
"support.title.export":"Export", 
"replace_silex_lines":"Replacement silex line", 
"saphir_version_current":"Saphir version", 
"stop_and_go":"Stop and go", 
"refresh_synchro_devices":"Refresh synchro devices", 
"silex.export.alarm.past":"Export past alarms", 
"silex.export.change_readmethod_T_to_A":"Update RMT T -> A", 
"export_round_csv_custom":"Export round CSV custom", 
"export_round_csv_custom_format_dir":"Export round CSV custom DIR format", 
"import_round_csv_enabled":"Import CSV round enabled", 
"import_round_csv_custom":"Custom round import CSV", 
"import_round_facturation_enabled":"Enable billing import", 
"export_round_csv_encoding":"Export round CSV encoding", 
"support.option.comma":"Comma", 
"support.option.semicolon":"Semicolon", 
"support.option.tabulation":"Tabulation", 
"script.export.format":"Export formats", 
"synchronisation.label.script.export.encoding_source":"Export source encoding", 
"synchronisation.label.script.export.encoding_output":"Export output encoding", 
"synchronisation.label.script.export.delimiter":"Export delimiter", 
"synchronisation.label.script.import.format":"Import format", 
"synchronisation.label.script.import.encoding_source":"Import source encoding", 
"synchronisation.label.script.import.encoding_output":"Import output encoding", 
"synchronisation.label.script.import.delimiter":"Import delimiter", 
"support.title.generate":"Estimated reading", 
"support.title.index":"Index", 
"generate.index.delay_min_without_read_value":"Min delay without reading (value)", 
"synchronisation.label.generate.index.delay_min_without_read_type":"Min delay without reading (type)", 
"generate.index.delay_max_with_real_read_value":"Maximum age of a real reading (value)", 
"synchronisation.label.generate.index.delay_max_with_real_read_type":"Max delay with real reading (type)", 
"generate.index.delay_max_deep_value":"Max depth delay (value)", 
"synchronisation.label.generate.index.delay_max_deep_type":"Max depth delay (type)", 
"generate.index.nb_reads_min_value":"Number of real read min", 
"support.option.month":"Month", 
"support.option.week":"Week", 
"support.option.day":"Day", 
"generate.index.exclude_meters":"Meters to exclude (separated by ',')", 
"info.export.export_silex_name":"$0: Full route name <br />%0: Full route code <br />%1-8: Character 1-8 route code <br />%d: Current day <br />%H: Current time <br />%m: Current month <br />%M: Current minute <br />%S: Current second <br />%U: Current week year (Sunday) <br />%W: Current week year (Monday) <br />%w: Current weekday <br />%y: Current year (2 digits) <br />%Y: Current year (4 digits)", 
"synchrotools.option.download_round":"Load a route", 
"synchrotools.option.remove_round":"Delete a route", 
"synchrotools.option.upload_round":"Unload a route", 
"synchrotools.option.rounds":"$t(all.round.round_plural)", 
"synchrotools.button.connect":"Connection", 
"synchrotools.button.disconnect":"Unconnection", 
"synchrotools.option.command":"Orders", 
"synchrotools.button.launchCommand":"Make an order", 
"remotereading.text.read_with_no_alarm":"Read without an alarm ", 
"remotereading.text.read_with_alarm":"Read with an alarm", 
"remotereading.text.read_sort_by_model":"Read by models", 
"remotereading.text.meter_alarm":"Alarmed meters", 
"remotereading.text.without_alarm":"Without alarms", 
"remotereading.text.meter_alarm_by_model":"Alarmed meters by model", 
"remotereading.text.alarm_type":"Alarm type", 
"remotereading.text.synthese":"Synthesis", 
"remotereading.text.in_alarm":"in alarms", 
"remotereading.text.read":"Read", 
"remotereading.text.not_read":"Not read", 
"remotereading.text.no_meter_in_interval ":"No meter with this option exsists in the chosen interval", 
"remotereading.text.link_meter_now":"Associate meters now?", 
"remotereading.text.no_meter_link_to":"No meters are associated with", 
"remotereading.text.select_default":"Default selection", 
"remotereading.text.map_view":"View map", 
"remotereading.text.list_view":"View list", 
"remotereading.text.for_current_round":"For current reading", 
"remotereading.text.for_selected_period":"For the selected period", 
"remotereading.text.by_read_mode":"By mode", 
"remotereading.text.by_manufacturer":"By manufacturer", 
"remotereading.text.by_status":"by status", 
"remotereading.text.multi_choices_allow":"multiple choices below", 
"remotereading.text.read_method":"Reading mode", 
"remotereading.text.select_at_least":"Please select at least ", 
"remotereading.text.one_manufacturer":"A manufacturer", 
"remotereading.text.one_status":"A status", 
"remotereading.text.one_read_method":"A reading method", 
"remotereading.text.manufacturer":"Manufacturer", 
"remotereading.text.alarm_detail":"Alarm details", 
"remotereading.text.match_selected_meter":"Corresponding to the selection on a total of {{nbMeters}}", 
"remotereading.text.meter_geolocated":"of which {{meterGeolocated}} have tracking", 
"remotereading.text.no_meter_to_display":"No meter to display", 
"enquete.text.add_enq_page":"Add survey page", 
"enquete.text.link_enq_round":"Associate routes", 
"enquete.text.name_enquete_page":"Survey page names", 
"enquete.text.code_enquete_page":"Survey page number", 
"enquete.text.field_list":"List of fields", 
"enquete.text.field_type":"Field type", 
"enquete.field_type.A":"Text", 
"enquete.field_type.N":"Numeric", 
"enquete.field_type.C":"Choice", 
"enquete.text.delete_field":"Delete the field", 
"enquete.text.delete_field_impossible":"Unable to delete the field", 
"enquete.text.add_field":"Add survey field", 
"enquete.text.field":"$t(all.text.field)", 
"enquete.text.field_libelle":"Field label", 
"enquete.text.field_size":"Field size", 
"enquete.text.field_default_value":"Field value ", 
"enquete.text.no_field_exist":"No existing field ", 
"enquete.text.no_field_selected":"No selected choice for this field", 
"enquete.text.choices_list":"Choice list", 
"enquete.text.associated_page":"Associated survey page", 
"enquetes.text.edit_enquete":"Edit survey page", 
"enquete.text.edit_impossible_in_used":"Cannot edit survey: used by a route", 
"enquete.text.enquete_deleted_links_warning":"You are about to unpair the surveys. Are you sure you want to continue ? This action is irreversible.", 
"enquete.text.deletion_enquetes":"Deleting surveys", 
"enquete.text.placeholder_create_options":"Choice 1, Choice 2, Choice 3 ...", 
"enquete.text.remove_impossible_in_used":"Cannot delete survey: used by a route", 
"enquetes.text.remove_enquete":"Delete survey page", 
"enquetes.popover.title":"Deleting a survey page", 
"enquete.popover.confirm_text":"Are you sure you want to delete this survey?", 
"enquete.error.choice_max_length":"There can only be 8 values in that field", 
"enquete.text.round_loaded_devices_no_update_allow":"Route already loaded on devices. No changes allowed.", 
"enquete.text.select_enquete":"Select survey pages ...", 
"enquete.text.each_option_max_20_char":"Each choice can only contain 20 characters maximum", 
"enquete.text.libelle_max_16_char":"Libel must contain 16 characters maximum", 
"enquete.text.size_max_30":"La taille doit être inférieur à 30 et ne doit pas contenir de décimales", 
"enquete.text.default_value_max_size":"The default value should have less characters than the size informed, here", 
"lostpass.text.password_identifiant_lost":"username/password forgotten ?", 
"lostpass.text.get_identifants":"Retrieve login info ", 
"lostpass.text.enter_email":"Enter your email in order to recieve connection info", 
"lostpass.text.your_email":"Your email", 
"lostpass.text.enter_valid_email":"Your email must be validated", 
"lostpass.text.check_email":"Watch your email, the info has been sent. If you haven't recieved any emails within 24h, please contact your administrator", 
"alarm.type.fraud":"Fraud", 
"alarm.type.leak":"Leak", 
"alarm.type.backflow":"Backflow", 
"alarm.type.meterblock":"Blocked meter", 
"alarm.type.clock":"Clock", 
"alarm.type.moduledefect":"Module malfunction", 
"alarm.type.moduleremoved":"Dissassembled module ", 
"alarm.type.configuration":"Inconsistent configuration", 
"alarm.type.reversedmeter":"Reversed meter", 
"alarm.type.overflow":"Overflow", 
"alarm.type.underflow":"Underflow", 
"alarm.type.brokenpipe":"Broken pipe", 
"alarm.type.battery":"Battery", 
"alarm.type.pocfraud":"Fraud (past or present)", 
"alarm.type.pocleak":"Leak (past or present) ", 
"alarm.type.pocbackflow":"Backflow (past or present)", 
"alarm.type.pocmeterblock":"Blocked meter (past or present)", 
"alarm.type.pocoverflow":"$t(alarm.type.overflow.poc)", 
"alarm.type.endofbattery":"End of battery", 
"alarm.type.clockfault":"$t(alarm.type.clock)", 
"alarm.type.itronbatteryalarm":"Battery (ITRON)", 
"alarm.type.itrondetectionalarm":"Detection", 
"alarm.type.itronmagnetictamperalarm":"Magnetic fraud", 
"alarm.type.itronmemorizedremovalalarm":"Dissassembled module (previous)", 
"alarm.type.itronmemoryalarm":"Memory", 
"alarm.type.itronrealtimeremovalalarm":"Dissassembled module (in progress)", 
"alarm.type.itronmemorizedtamperalarm":"Faulty module (previous)", 
"alarm.type.itronrealtimetamperalarm":"Faulty module (in progress)", 
"alarm.type.itronnotwatermediumtype":"Unknown fluid type", 
"alarm.type.itronchecksumcodealarm":"Checksum", 
"alarm.type.itroncorruptdataalarm":"Corrupt data", 
"alarm.type.itroncurrentyearyearlyindexabovethresholdalarm":"Annual index above threshold", 
"alarm.type.itroncurrentyearyearlyindexbelowthresholdalarm":"Annual index under threshold", 
"alarm.type.itrondateandtimereconfigurationalarm":"Date/time reconfiguration", 
"alarm.type.itronmeterblockedalarm":"$t(alarm.type.meterblock)", 
"alarm.type.itronmonthlyleakagealarm":"Monthly leak", 
"alarm.type.itronpreviousyearyearlyindexabovethresholdalarm":"Previous annual index above threshold", 
"alarm.type.itronpreviousyearyearlyindexbelowthresholdalarm":"Previous annual index under threshold", 
"alarm.type.itronrfwakeupalarm":"Wake-up alarm", 
"alarm.type.itronreconfigurationalarm":"Reconfiguration", 
"alarm.type.itronreversedmeteralarm":"$t(alarm.type.reversedmeter)", 
"alarm.type.itronyearlybackflowalarm":"Annual backflow", 
"alarm.type.itronyearlyleakagealarm":"Annual leak", 
"alarm.type.itronyearlypeakalarm":"Annual consumption peak ", 
"alarm.type.itroneverbluenabled":"EverBlue activated ", 
"alarm.type.itroncablecutalarm":"Cut cable", 
"alarm.type.itron.everbluenabled":"Ever Blue mode activated", 
"alarm.type.pastleak":"Previous Leaks", 
"alarm.type.currentleak":"Leakage in process ", 
"alarm.type.pastmecafraud":"Previous mecanic fraud", 
"alarm.type.currentmecafraud":"Mecanic fraud in process", 
"alarm.type.pastmagnetfraud":"Previous magnetic fraud", 
"alarm.type.currentmagnetfraud":"Magnetic fraud in process", 
"alarm.type.pastmeterblock":"Previous blockage", 
"alarm.type.currentmeterblock":"Current blockage", 
"alarm.type.metrologyerror":"Meterology error", 
"alarm.type.mediumabsent":"Medium absent", 
"alarm.type.specificerror":"Specific error", 
"alarm.type.modulesetting":"Module setting", 
"alarm.type.temperature":"$t(all.text.temperature)", 
"alarm.type.fraud.current":"Fraud (current)", 
"alarm.type.fraud.poc":"Fraud (previous or current)", 
"alarm.type.fraud.past":"Fraud (previous)", 
"alarm.type.leak.past":"Leak (previous)", 
"alarm.type.leak.poc":"Leak (previous or current)", 
"alarm.type.leak.current":"Leak (current)", 
"alarm.type.leak.current.high":"Important leak (current)", 
"alarm.type.backflow.past":"Previous backflow", 
"alarm.type.backflow.current":"Current backflow", 
"alarm.type.backflow.poc":"Backflow (past and current)", 
"alarm.type.pastbackflow":"$t(alarm.type.backflow.past)", 
"alarm.type.currentbackflow":"$t(alarm.type.backflow.current)", 
"alarm.type.underflow.past":"Underflow (previous) ", 
"alarm.type.pocunderflow":"$t(alarm.type.underflow.poc)", 
"alarm.type.underflow.current":"Underflow (current) ", 
"alarm.type.underflow.poc":"Underflow (previous or current)", 
"alarm.type.overflow.past":"Overflow (previous)", 
"alarm.type.overflow.current":"Overflow (current)", 
"alarm.type.overflow.poc":"Overflow (past or present)", 
"alarm.type.meterblock.past":"Blocked meter (previous) ", 
"alarm.type.meterblock.current":"Blocked meter (current)", 
"alarm.type.meterblock.poc":"$t(alarm.type.pocmeterblock)", 
"alarm.type.battery.past":"Battery (previous)", 
"alarm.type.battery.current":"Battery (current)", 
"alarm.type.battery.poc":"Battery (previous or current)", 
"alarm.type.clock.past":"Clock (previous)", 
"alarm.type.clock.current":"Clock (current)", 
"alarm.type.clock.poc":"Clock (previous or current)", 
"alarm.type.moduledefect.past":"Module defect (previous)", 
"alarm.type.moduledefect.current":"Module defect (current)", 
"alarm.type.moduledefect.poc":"Module defect (past or current)", 
"alarm.type.modulesetting.past":"Module settings  (previous)", 
"alarm.type.modulesetting.current":"Module settings (current)", 
"alarm.type.modulesetting.poc":"Module settings (previous or current)", 
"alarm.type.reversedmeter.past":"Reverse meter (previous)", 
"alarm.type.reversedmeter.current":"Reverse meter (current) ", 
"alarm.type.reversedmeter.poc":"Reverse meter (previous or current) ", 
"alarm.type.mediumabsent.past":"Absent medium (previous)", 
"alarm.type.mediumabsent.current":"Absent medium (current) ", 
"alarm.type.mediumabsent.poc":"Absent medium (previous or current)", 
"alarm.type.brokenpipe.past":"Broken pipe (previous)", 
"alarm.type.brokenpipe.current":"Broken pipe (current)", 
"alarm.type.brokenpipe.poc":"Broken pipe (previous or current)", 
"alarm.type.temperature.past":"Temperature (previous)", 
"alarm.type.temperature.current":"Temperature (current)", 
"alarm.type.temperature.poc":"Temperature (previous or current) ", 
"alarm.type.specificerror.past":"Specific error (previous)", 
"alarm.type.specificerror.current":"Specific error (current)", 
"alarm.type.specificerror.poc":"Specific error (previous or current)", 
"alarm.type.fraud.mecanic.past":"Mecanic fraud (previous)", 
"alarm.type.fraud.mecanic.current":"Mecanic fraud (current)", 
"alarm.type.fraud.mecanic.poc":"Mecanic fraud (previous or current)", 
"alarm.type.fraud.magnetic.past":"Magnetic fraud (previous)", 
"alarm.type.fraud.magnetic.current":"Magnetic fraud (current)", 
"alarm.type.fraud.magnetic.poc":"Magnectic fraud (past or current)", 
"alarm.type.temperature.low.past":"Low temperature (previous)", 
"alarm.type.temperature.low.current":"Low temperature (current)", 
"alarm.type.temperature.low.poc":"Low temperature (previous or current)", 
"alarm.type.temperature.hight.past":"High temperature (previous)", 
"alarm.type.temperature.hight.current":"High temperature (current)", 
"alarm.type.temperature.hight.poc":"High temperature (previous or current)", 
"alarm.type.moduleremoved.past":"$t(alarm.type.itronmemorizedremovalalarm)", 
"alarm.type.moduleremoved.current":"Dismantled module (current)", 
"alarm.type.moduleremoved.poc":"Dismantled module (previous or current)", 
"alarm.type.itron.battery":"$t(alarm.type.itronbatteryalarm)", 
"alarm.type.itron.detection":"Detection (ITRON)", 
"alarm.type.itron.magnetic.tamper":"Magnetic fraud", 
"alarm.type.itron.memorizedremoval":"Dismantled ITRON module (previous)", 
"alarm.type.itron.memory":"Memory (ITRON)", 
"alarm.type.itron.realtimeremoval":"Dismantled ITRON module (current)", 
"alarm.type.itron.memorizedtamper":"Faulty ITRON module (previous)", 
"alarm.type.itron.realtimetamper":"Faulty ITRON module (current)", 
"alarm.type.itron.notwatermedium":"unknown fluid (ITRON)", 
"alarm.type.itron.checksumcode":"Invalid frame (ITRON)", 
"alarm.type.itron.corruptdata":"Corrupt data (ITRON)", 
"alarm.type.itron.currentyearyearlyindexabovethreshold":"Annual index above threshold (ITRON)", 
"alarm.type.itron.currentyearyearlyindexbelowthreshold":"Annual index below threshold (ITRON) ", 
"alarm.type.itron.dateandtimereconfiguration":"Date/time reconfiguration (ITRON)", 
"alarm.type.itron.meterblocked":"Blocked meter (ITRON)", 
"alarm.type.itron.monthlyleakage":"Monthly leak (ITRON)", 
"alarm.type.itron.previousyearyearlyindexabovethreshold":"Previous annual index above threshold (ITRON) ", 
"alarm.type.itron.previousyearyearlyindexbelowthreshold":"Previous annual index under threshold (ITRON)", 
"alarm.type.itron.rfwakeup":"Wakeup radio (ITRON)", 
"alarm.type.itron.reconfiguration":"Reconfiguration (ITRON)", 
"alarm.type.itron.reversedmeter":"Reversed meter (ITRON)", 
"alarm.type.itron.yearlybackflow":"Annual backflow (ITRON)", 
"alarm.type.itron.yearlyleakage":"Annual leak (ITRON)", 
"alarm.type.itron.yearlypeak":"Annual consumption peak (ITRON)", 
"alarm.type.itron.cablecut":"Cable cut (ITRON)", 
"alarm.type.birdz.reversedmountmeter":"Reversed meter (BIRDZ)", 
"alarm.type.birdz.oversizedsmalldiameter":"Oversized small diameter (BIRDZ)", 
"alarm.type.birdz.oversizedbigdiameter":"Oversized big diameter (BIRDZ)", 
"alarm.type.birdz.undersizedsmalldiameter":"Undersized small diameter (BIRDZ)", 
"alarm.type.birdz.undersizedbigdiameter":"Undersized big diameter (BIRDZ)", 
"alarm.type.birdz.irregularblock":"Irregular blockage (BIRDZ)", 
"alarm.type.birdz.lowtemperaturefreeze":"Low temperature gel (BIRDZ)", 
"alarm.type.birdz.reversedmeter":"$t(alarm.type.birdz.reversedmountmeter)", 
"alarm.type.birdz.magneticfraud":"Magnetic fraud (BIRDZ)", 
"alarm.type.birdz.unmountedmodule":"Dismounted module (BIRDZ)", 
"alarm.type.birdz.backflowl1":"Backflow L1 (BIRDZ)", 
"alarm.type.birdz.backflowl2":"Backflow L2 (BIRDZ)", 
"alarm.type.birdz.hightemperaturesmalldiameter":"High temperature small diameter (BIRDZ)", 
"alarm.type.birdz.hightemperaturebigdiameter":"High temperature big diameter (BIRDZ)", 
"alarm.type.birdz.stoppersistence":"Stop persistence (BIRDZ)", 
"alarm.type.birdz.irregularleak":"Irregular leak (BIRDZ)", 
"alarm.type.integra.meterblocked":"Blocked meter (INTEGRA)", 
"alarm.type.integra.minwatertemp":"Water temperature < 3°C (INTEGRA)", 
"alarm.type.integra.maxwatertemp":"Water temperature > 50°C (INTEGRA)", 
"alarm.type.integra.minambianttemp":"Low ambiant temperature (INTEGRA)", 
"alarm.type.integra.maxambianttemp":"Ambiant temperature > 70° (INTEGRA)", 
"alarm.type.integra.lowbattery":"Batterie faible (INTEGRA)", 
"alarm.type.integra.bubbles":"Air bubbles detected (INTEGRA)", 
"alarm.type.integra.tamper":"Tampered meter (INTEGRA)", 
"alarm.type.integra.leak":"Leak (INTEGRA)", 
"alarm.type.integra.burst":"Burst pipe (INTEGRA)", 
"alarm.type.integra.dry":"Dry pipe (INTEGRA)", 
"alarm.type.integra.reverseflow":"Reversed flow (INTEGRA)", 
"alarm.type.integra.asiccommunicationerror":"Communication error (INTEGRA)", 
"alarm.type.axioma.abdormalcondition":"Abnormal ambiant temperature (INTEGRA)", 
"alarm.type.axioma.burst":"Burst pipe (AXIOMA)", 
"alarm.type.axioma.burst.temporary":"Temporary burst pipeline (AXIOMA)", 
"alarm.type.axioma.burst.permanent":"Permanent burst pipeline (AXIOMA)", 
"alarm.type.axioma.backflow":"Backflow (AXIOMA)", 
"alarm.type.axioma.backflow.temporary":"Temporary backflow (AXIOMA)", 
"alarm.type.axioma.backflow.permanent":"Permanent backflow (AXIOMA)", 
"alarm.type.axioma.waterfreeze":"Frozen water (AXIOMA)", 
"alarm.type.axioma.waterfreeze.temporary":"Temporary frozen water (AXIOMA)", 
"alarm.type.axioma.waterfreeze.permanent":"Permanent frozen water (AXIOMA)", 
"alarm.type.axioma.leakage":"Leak (AXIOMA)", 
"alarm.type.axioma.leakage.temporary":"Temporary leak (AXIOMA)", 
"alarm.type.axioma.leakage.permanent":"Permanent leak (AXIOMA)", 
"alarm.type.axioma.lowbattery":"Low battery (AXIOMA)", 
"alarm.type.axioma.lowbattery.temporary":"Temporary low battery (AXIOMA)", 
"alarm.type.axioma.lowbattery.permanent":"Permanent low battery (AXIOMA)", 
"alarm.type.axioma.dry":"Dry pipeline (AXIOMA)", 
"alarm.type.axioma.dry.permanent":"Permanent dry pipeline (AXIOMA)", 
"alarm.type.axioma.manipulation":"Manipulation (AXIOMA)", 
"alarm.type.axioma.manipulation.permanent":"Permanent manipulation (AXIOMA)", 
"alarm.type.axioma.lowtemperature":"Low temperature (AXIOMA) ", 
"alarm.type.axioma.lowtemperature.permanent":"Permanent low temperature (AXIOMA)", 
"alarm.type.diehl.leakage":"Leak (DIEHL)", 
"alarm.type.diehl.leakagehist":"Leak history (DIEHL)", 
"alarm.type.diehl.manipulation":"Manipulation (DIEHL)", 
"alarm.type.diehl.manipulationhist":"Manipulation history (DIEHL)", 
"alarm.type.diehl.blocked":"Blocked meter (DIEHL)", 
"alarm.type.diehl.blockedhist":"Blocked meter history (DIEHL)", 
"alarm.type.diehl.unclipped":"Dismantled (DIEHL)", 
"alarm.type.diehl.unclippedhist":"Dismantled history (DIEHL)", 
"alarm.type.diehl.overflow":"Overflow (DIEHL)", 
"alarm.type.diehl.overflowhist":"Overflow history (DIEHL)", 
"alarm.type.diehl.underflow":"Underflow (DIEHL)", 
"alarm.type.diehl.underflowhist":"Underflow history (DIEHL)", 
"alarm.type.diehl.backflow":"Backflow (DIEHL)", 
"alarm.type.diehl.backflowhist":"Backflow history (DIEHL)", 
"alarm.type.diehl.tempsensor":"Temp sensor (DIEHL)", 
"alarm.type.diehl.tempsensorhist":"Temp sensor history (DIEHL)", 
"alarm.type.diehl.battery":"Battery (DIEHL)", 
"alarm.type.diehl.batteryhist":"Battery history (DIEHL)", 
"alarm.type.birdz.startflowsmalldiameter":"Start flow - small diameter (BIRDZ)", 
"alarm.type.birdz.startflowbigdiameter":"Start flow - big diameter (BIRDZ)", 
"alarm.type.birdz.undercountbigdiameter":"Undercount - big diameter (BIRDZ) ", 
"alarm.type.birdz.watchdog":"WatchDog (BIRDZ)", 
"alarm.type.birdz.hourset":"Time reset (BIRDZ)", 
"alarm.type.birdz.anormalrestflowvalue":"Abnormal restflow value (BIRDZ)", 
"alarm.type.birdz.supervisiontension":"Supervised tension (BIRDZ)", 
"alarm.type.birdz.modulereconfigured":"Reconfigured module (BIRDZ)", 
"alarm.type.birdz.solenoidvalve":"Solenoid valve (BIRDZ)", 
"alarm.type.birdz.noisedefensemaxrssicrossed":"Noise barrier: RSSi limite crossed (BIRDS)", 
"alarm.type.birdz.noisedefenseradioreceptionsuspended":"Noise barrier: radio reception suspended (BIRDZ)", 
"alarm.type.birdz.noisedefenselrlisteningsuspended":"Noise barrier: suspended LR listening (BIRDZ) ", 
"alarm.type.birdz.lowtemperatureradiostoppage":"Low temperature: radio release (BIRDZ)", 
"alarm.type.birdz.limitimmediatealarmcycle":"Limitation of immediate alarm cycles (BIRDZ)", 
"alarm.type.birdz.hoursetdelayexpiration":"Expiration of time reset delay (BIRDZ)", 
"alarm.type.birdz.acquisitionflooddefault":"Aquisition stage fault (BIRDZ)", 
"alarm.type.birdz.destinationtime":"Stay time (BIRDZ)", 
"alarm.type.kamstrup.fraud":"Fraud (KAMSTRUP)", 
"alarm.type.kamstrup.leak":"Leak (KAMSTRUP)", 
"alarm.type.kamstrup.burst":"Burst pipeline (KAMSTRUP)", 
"alarm.type.kamstrup.dry":"Dry pipeline (KAMSTRUP)", 
"alarm.type.kamstrup.reverseflow":"Reversed flow (KAMSTRUP)", 
"alarm.type.itron.wmbus.credit":"WMBUS credit (ITRON)", 
"alarm.type.itron.daily.backflow":"Backflow (ITRON)", 
"alarm.type.itron.daily.leakage":"Leak (ITRON)", 
"alarm.type.itron.daily.peak":"Consumption peak (ITRON)", 
"alarm.type.itron.daily.temperature.above":"Temperature above (ITRON)", 
"alarm.type.itron.daily.temperature.below":"Temperature below (ITRON)", 
"mask.text.select_at_least_once_meter":"Please select at least one meter", 
"mask.text.select_at_least_once_alarm":"Please select at least one alarm ", 
"columns.meter.serial":"$t(all.meter.meter_serial)", 
"columns.serial":"$t(all.meter.meter_serial)", 
"columns.radio.serial":"$t(all.meter.radio_serial)", 
"columns.withAlarm":"With alarms", 
"columns.alarms":"$t(all.alarm_meter.alarm_plural)", 
"columns.reference":"$t(all.column_text.pdi_ref)", 
"columns.state":"$t(all.pdi.state_pdi)", 
"columns.withAlarmMasked":"With masked alarms", 
"columns.triggerAlarms.leak":"Leak trigger date", 
"columns.triggerAlarms.fraud":"Fraud trigger date", 
"columns.triggerAlarms.backflow":"Backflow trigger date", 
"columns.closeAlarms.leak":"Leak period end date", 
"columns.closeAlarms.fraud":"Fraud period end date", 
"columns.closeAlarms.backflow":"Backflow period end date", 
"columns.codeTourneeReleve":"Reading route code ", 
"columns.labelTourneeReleve":"Reading route name", 
"columns.splitAlarms.Fraudpoc":"Fraud alarm ", 
"columns.splitAlarms.Fraudcurrent":"Fraud alarm activated", 
"columns.splitAlarms.Fraudpast":"Previous fraud alarm", 
"columns.splitAlarms.Leakpoc":"Leak alarm", 
"columns.splitAlarms.Leakcurrent":"Leak alarm activated", 
"columns.splitAlarms.Leakpast":"Previous leak alarm", 
"columns.splitAlarms.Backflowpoc":"Backflow alarm", 
"columns.splitAlarms.Backflowcurrent":"Current backflow alarm ", 
"columns.splitAlarms.Backflowpast":"Previous backflow alarm", 
"columns.splitAlarms.Batterycurrent":"Current battery alarm", 
"columns.splitAlarms.Batterypast":"Previous battery alarm ", 
"columns.splitAlarms.ModuleDefectcurrent":"Currect defect alarm ", 
"columns.splitAlarms.ModuleDefectpast":"Previous defect alarm", 
"columns.splitAlarms.Clockcurrent":"Current clock alarm", 
"columns.splitAlarms.Clockpast":"Previous clock alarm", 
"columns.splitAlarms.MeterBlockpoc":"Blocked meter alarm", 
"columns.splitAlarms.MeterBlockpast":"Previous blocked meter alarm", 
"columns.splitAlarms.Underflowpoc":"Underflow alarm", 
"columns.splitAlarms.Underflowpast":"Previous underflow alarm", 
"columns.splitAlarms.Underflowcurrent":"Current underflow alarm", 
"columns.splitAlarms.Overflowpoc":"Overflow alarm", 
"columns.splitAlarms.Overflowcurrent":"Current overflow alarm", 
"columns.splitAlarms.Overflowpast":"Previous overflow alarm ", 
"columns.previousMeter.installDate":"Date of last meter change", 
"columns.previousMeter.previousMountedMeterSerial":"Previous meter serial number", 
"columns.previousMeter.deposeIndex":"Deposit index", 
"columns.alarmsMasked":"Masked alarms", 
"columns.simultaneousAlarms":"Simultaneous current alarms", 
"columns.clpInformations":"Subscriber info", 
"columns.customInformation.couleur_plombage":"$t(column_list.label.sealing_color)", 
"columns.customInformation.code_reseau":"$t(column_list.label.network_code)", 
"columns.customInformation.code_tournee":"Billing route code", 
"columns.customInformation.name_tournee":"Billing route name", 
"columns.customInformation.etat_abonnement":"State of subscription ", 
"columns.customInformation.date_debut_abonnement":"Subscription beginning date ", 
"columns.customInformation.date_fin_abonnement":"Subscription end date", 
"columns.customInformation.complement_voie":"Additional details", 
"columns.read.date":"$t(columns.rdsDate)", 
"columns.read.lastDate":"$t(all.read_meter.read_last_date)", 
"columns.read.meterIndex":"$t(all.meter.meter_index)", 
"columns.read.radioIndex":"Radio index", 
"columns.read.consumption":"$t(all.read_meter.cons)", 
"columns.read.readMethod":"Reading method", 
"columns.read.source":"Reading source", 
"columns.read.previousDate":"Previous date of latest reading", 
"columns.read.previousMeterIndex":"Previous meter index ", 
"columns.read.offsetIndex":"Index de décalage radio", 
"columns.currentRead.date":"Current read date", 
"columns.currentRead.meterIndex":"Current meter index", 
"columns.currentRead.radioIndex":"Current radio index", 
"columns.currentRead.consumption":"Current consumption", 
"columns.currentRead.readMethod":"Current reading method", 
"columns.currentRead.source":"Current reading source", 
"columns.currentRead.previousDate":"Previous date of current reading", 
"columns.currentRead.previousMeterIndex":"Previous index of current counter reading", 
"columns.currentRead.offsetIndex":"Radio current index offset", 
"columns.address.city":"$t(all.address.city)", 
"columns.address.country":"$t(all.address.country)", 
"columns.address.complement":"$t(all.address.complement)", 
"columns.address.street":"$t(all.address.street)", 
"columns.address.streetNumber":"$t(all.address.street_number)", 
"columns.address.streetNumberAdditionnal":"$t(all.address.street_number_additionnal)", 
"columns.address.concatAdress":"Full address", 
"columns.address.zipcode":"$t(all.address.zipcode)", 
"columns.address.appartement":"$t(import_billing.label.pdi_appart)", 
"columns.address.batiment":"$t(import_billing.label.pdi_building)", 
"columns.address.etage":"$t(import_billing.label.pdi_floor)", 
"columns.address.escalier":"$t(import_billing.label.pdi_stair)", 
"columns.meter.diameter":"$t(all.meter.real_meter_diameter)", 
"columns.meter.model":"$t(all.meter.meter_model)", 
"columns.model":"$t(all.meter.meter_model)", 
"columns.meter.manufacturer":"$t(all.meter.meter_manufacturer)", 
"columns.manufacturer":"$t(all.meter.meter_manufacturer)", 
"columns.meter.mtrInformations":"Meter information ", 
"columns.meter.startDate":"$t(all.text.install_date)", 
"columns.meter.lastUpdateDate":"Dernière mise à jour fiche", 
"columns.meter.endDate":"Meter state", 
"columns.meter.fluidType":"Fluid type", 
"columns.gpsPosition.alt":"$t(column_list.label.altitude)", 
"columns.gpsPosition.lat":"$t(all.text.latitude)", 
"columns.gpsPosition.lng":"$t(all.text.longitude)", 
"columns.radio.manufacturer":"$t(all.radio.radio_manufacturer)", 
"columns.radio.type":"Radio type ", 
"columns.radio.composeInternalSerial":"Internal radio serial number", 
"columns.composeInternalSerial":"Interna serial number", 
"columns.updatedAt":"$t(all.date.update_date)", 
"columns.name":"$t(all.text.name)", 
"columns.average":"$t(all.text.average)", 
"columns.type":"$t(columns.radio.type)", 
"columns.location.name":"$t(identity_chooser.text.location_name)", 
"columns.meter.mtrNote":"Message libre", 
"columns.previousRadio.installUnmount":"Date de démontage ancienne radio", 
"columns.previousRadio.previousMountedRadioSerial":"Numéro de série ancienne radio", 
"columns.meter.message":"Message", 
"columns.releveTerrain.previousIndex":"Index relève précédente", 
"columns.releveTerrain.currentIndex":"Index relève courante", 
"columns.releveTerrain.previousDate":"Date relève précédente", 
"columns.releveTerrain.currentDate":"Date relève courante", 
"columns.releveTerrain.consumption":"Consommation relève", 
"columns.releveTerrain.consumptionState":"Etat consommation relève courante", 
"columns.releveTerrain.seuilOverflow":"Custom consumption anomaly", 
"columns.parentReference":"$t(pdi.PDI_PARENT_REFERENCE)", 
"columns.parentRelation":"$t(pdi.PDI_PARENT_RELATION)", 
"columns.ficheState":"Plug state ", 
"columns.cardOrder":"Order", 
"columns.note":"Note", 
"columns.marquage":"$t(all.text.marquage)", 
"columns.cptSerial":"$t(all.meter.meter_serial)", 
"columns.revisedCptSerial":"Revised serial number meter", 
"columns.installDate":"Installment year ", 
"columns.wheels":"Nb of dials ", 
"columns.cptManufacturer":"Meter manufacturer", 
"columns.mtrInformations":"$t(columns.meter.mtrInformations)", 
"columns.message":"$t(all.text.message)", 
"columns.particularMessageMeter":"$t(all.text.particular_msg)", 
"columns.hasGpsPosition":"$t(all.text.gps)", 
"columns.oldMeterEndDate":"Change", 
"columns.newMeterSerial":"SN new meter", 
"columns.installDateNewMeter":"$t(all.text.install_date)", 
"columns.radioSerial":"SN radio ", 
"columns.radioManufacturer":"Radio manufacturer", 
"columns.amrReference":"AMR reference", 
"columns.indexed":"indexed ", 
"columns.modified":"$t(all.text.modified)", 
"columns.previousDate":"Date of last reading", 
"columns.previousIndex":"Last reading index", 
"columns.currentDate":"Current reading date", 
"columns.currentIndex":"Current reading index", 
"columns.consoState":"Consumption state", 
"columns.updateDate":"$t(all.text.update_date)", 
"columns.lastUpdateByUser":"Edited by", 
"columns.facturationCode":"Billing code", 
"columns.meterSerialNumber":"$t(columns.cptSerial)", 
"columns.radioSerialNumber":"$t(columns.radioSerial)", 
"columns.rdsDate":"Date of reading", 
"columns.meterIndex":"Meter index m3", 
"columns.withCreation":"$t(all.text.indexed)", 
"columns.t1CurrentExist":"Replaced read", 
"columns.readDate":"Reading date", 
"columns.compteurSerial":"$t(columns.meter.serial)", 
"columns.readCompteurIndex":"$t(columns.read.meterIndex)", 
"columns.consumption":"$t(columns.read.consumption)", 
"columns.readRadioIndex":"$t(columns.read.radioIndex)", 
"columns.radioSensorPort":"Sensor port", 
"columns.clpReference":"$t(columns.reference)", 
"columns.readRadioPulse":"Pulse radio", 
"columns.codeTournee":"$t(columns.codeTourneeReleve)", 
"columns.codeReseau":"$t(columns.customInformation.code_reseau)", 
"columns.nomTournee":"$t(columns.labelTourneeReleve)", 
"exportpdi.text.load_reads":"Loading readings to export", 
"exportpdi.text.csv_export_processing":"CSV export processing", 
"exportpdi.text.no_read_found":"No reading found", 
"columns.withPreviousAlarm":"Previous reading alarm", 
"columns.lastUpdate":"$t(all.date.update_date)", 
"columns.cptStatusId":"Consumption status", 
"columns.newDate":"Date of new meter installation ", 
"columns.group.pdi":"$t(all.pdi.pdi)", 
"columns.group.triggeralarms":"Trigger alarm date", 
"columns.group.closealarms":"Period end of alarm", 
"columns.group.splitalarms":"Unit alarms", 
"columns.group.previousmeter":"$t(fiche_detail.title.old_meter)", 
"columns.group.custominformation":"$t(all.text.more_info_plural)", 
"columns.group.address":"$t(all.location.address)", 
"columns.group.read":"Reading", 
"columns.group.currentread":"Current reading", 
"columns.group.meter":"$t(all.meter.meter)", 
"columns.group.gpsposition":"Gps location ", 
"columns.group.radio":"$t(all.radio.radio)", 
"columns.group.previousradio":"Radio précédente", 
"columns.group.releveterrain":"Relève terrain", 
"columns.exportname":"Name of custom export", 
"columns.exportfilename":"File name", 
"columns.exportowner.username":"Creator name", 
"columns.datePreviousReleve":"$t(columns.previousDate)", 
"columns.listReleve_dateReleve":"Billing history date ", 
"linefilter.operator.date.=":"Equal to ", 
"linefilter.operator.date.>=":"Before", 
"linefilter.operator.date.<=":"After", 
"linefilter.operator.date.vide":"Empty", 
"linefilter.operator.date.notvide":"Not empty", 
"linefilter.operator.date.between":"Between", 
"linefilter.operator.text.contient":"Containing", 
"linefilter.operator.text.contientpas":"Does not contain", 
"linefilter.operator.text.commencepar":"Starts by", 
"linefilter.operator.text.finipar":"Finishes by ", 
"linefilter.operator.text.vide":"$t(linefilter.operator.date.vide)", 
"linefilter.operator.text.notvide":"$t(linefilter.operator.date.notvide)", 
"linefilter.operator.checkbox.true":"$t(all.text.yes)", 
"linefilter.operator.checkbox.false":"$t(all.text.no)", 
"linefilter.operator.state.checkbox.true":"$t(all.text.active)", 
"linefilter.operator.state.checkbox.false":"$t(all.text.inactive)", 
"linefilter.operator.number.>":"Above", 
"linefilter.operator.number.>=":"Above or equal to", 
"linefilter.operator.number.<":"Under", 
"linefilter.operator.number.<=":"Under or equal to ", 
"linefilter.operator.number.=":"$t(linefilter.operator.date.=)", 
"linefilter.operator.number.!=":"Different from", 
"linefilter.operator.number.== null":"is null", 
"linefilter.operator.number.!= null":"is not null", 
"linefilter.userinput.placeholder_input":"Option 1, Option 2, Option 3 ...", 
"linefilter.operator.selectcontvalue.=":"is", 
"linefilter.operator.selectcontvalue.!=":"is not ", 
"linefilter.operator.selectcont.=":"$t(linefilter.operator.selectcontvalue.=)", 
"linefilter.operator.selectcont.!=":"$t(linefilter.operator.selectcontvalue.!=)", 
"linefilter.operator.selectalarmtypebasic.=":"$t(linefilter.operator.selectcontvalue.=)", 
"linefilter.operator.selectalarmtypebasic.!=":"$t(linefilter.operator.selectcontvalue.!=)", 
"linefilter.operator.selectalarmtypebasic.contient":"$t(linefilter.operator.text.contient)", 
"linefilter.operator.selectalarmtypebasic.contientpas":"$t(linefilter.operator.text.contientpas)", 
"virtualmeter.text.name_already_exist":"Name already exists ", 
"permission.text.read.location":"Access location while reading", 
"permission.text.edit.dashboard":"Dashboard management", 
"permission.text.edit.virtualMeter":"Virtual meter management", 
"permission.text.edit.alert":"Alert management ", 
"permission.text.edit.maskAlarm":"Alarm mask management", 
"permission.text.edit.locationInfo":"location info modifications", 
"permission.text.edit.pdi":"POI management (association/dissassociation)", 
"permission.text.edit.subLocation":"creation of sub-locations", 
"permission.text.read.round":"Reading access to route", 
"permission.text.use.synchro":"Ability to sync / edit device names", 
"permission.text.import.synchro":"Import routes / messages / marks", 
"permission.text.edit.round":"Export / delete a route ", 
"permission.text.import.factu":"Ability to import billing files ", 
"permission.text.export.brute":"Gross export", 
"permission.text.delete.phone":"Delete a mobile device", 
"permission.text.edit.message":"Update POI informations", 
"users_list.text.view_informations":"Consult all information", 
"users_list.text.block_user":"Block user", 
"users_list.text.unblock_user":"Unblock user", 
"users_list.text.delete_user":"Delete user", 
"tournee_fiches.text.recup_telereleve":"Recovered remote reading", 
"tournee_fiches.text.recup_reads_telereleve":"Recovered remote readings ", 
"tournee_fiches.text.reads_get":"Recovered reading ", 
"tournee_fiches.text.reads_get_plural":"Recovered readings", 
"tournee_fiches.text.new_reads":"New reading", 
"tournee_fiches.text.new_reads_plural":"New readings", 
"tournee_fiches.text.replaced_reads":"Replaced reading", 
"tournee_fiches.text.replaced_reads_plural":"Replaced readings ", 
"tournee_fiches.text.recup_reads_processing":"Recover ongoing readings ", 
"tournee_fiches.text.no_read_found":"$t(exportpdi.text.no_read_found)", 
"tournee_fiches.text.replace_reads_by_more_recent":"Replace existing readings by more recent ones ", 
"tournee_fiches.text.lookup_reads_processing":"Lookup ongoing readings", 
"tournee_fiches.text.rotate_fiches":"Reads rotation", 
"tourneefiches.text.rotation_index_description":"Etes vous sur de vouloir démarrer une nouvelle relève pour cette tournée ?", 
"tourneefiches.text.rotation_index_warning":"La rotation des index est irréversible.", 
"rapport.name.amrRead":"AMR data report", 
"rapport.name.cptRead":"Reading data report", 
"rapport.name.cptReadOrderByOrder":"Reading data report sorted by order", 
"rapport.name.cptReadOrderByDate":"Reading data report sorted by date ", 
"rapport.name.cptReadOrderByStatus":"Reading data report sorted by status ", 
"rapport.name.resumeRead":"Summary of readings ", 
"rapport.name.agingMeter":"Aging of meter park ", 
"rapport.name.cptDetail":"Meter details ", 
"rapport.name.amrDetail":"Radio details", 
"rapport.name.enqueteDetail":"Survey details", 
"rapport.name.cptReadAndAmr":"Reading data and AMR data report", 
"rapport.name.quickSummary":"Data summary", 
"rapport.name.statusReleve":"Status of the data", 
"rapport.option.sort_by":"Sort by ", 
"rapport.option.sorted_by":"Sorted by ", 
"rapport.option.view_pdf":"View PDF", 
"rapport.option.full_screen":"Full screen ", 
"rapport.option.preview_pdf":"PDF preview", 
"rapport.text.no_report_selected":"No report selected ", 
"rapport.error.generic":"An error has occured during the report creation", 
"rapport.error.no_data":"Not enough data to generate a round report", 
"import_silex.text.import_rounds":"Import routes ", 
"import_silex.text.round_file":"Route file", 
"import_silex.columns.identifiant":"Login ", 
"import_silex.columns.field":"$t(enquete.text.field)", 
"import_silex.columns.temoin_value":"Control value ", 
"import_silex.columns.generated_value":"Generated value ", 
"import_silex.text.wrong_encoding":"Error: Encoding of file [{{filename}}] [{{fileCharset}}] does not match defined parameters [{{paramCharset}}]", 
"import_silex.text.load_file_failed":"Loading of Silex file [{{filename}}] failed ", 
"import_silex.text.select_round":"Select a route", 
"import_silex.text.silex_format":" Silex format", 
"import_silex.error_text.lines_incorrect_length":"The line contains a number of characters other than 512", 
"import_silex.error_text.incorrect_length":"The line contains {{value}} characters instead of the required 512", 
"import_silex.error_text.silex_error":"The file to imported contains errors", 
"import_silex.error_text.t2_export_error":"Error encountered when exporting radios", 
"import_silex.error_text.no_line_id":"$t(import_silex.error_text.missing_data.no_line_id)", 
"import_silex.error_text.missing_data.no_line_id":"The record type is missing", 
"import_silex.error_text.no_round_code":"$t(import_silex.error_text.missing_data.no_round_code)", 
"import_silex.error_text.missing_data.no_round_code":"The route code is missing", 
"import_silex.error_text.no_order_number":"$t(import_silex.error_text.missing_data.no_order_number)", 
"import_silex.error_text.missing_data.no_order_number":"The order number is missing", 
"import_silex.error_text.no_subscriber_info":"$t(import_silex.error_text.missing_data.no_subscriber_info)", 
"import_silex.error_text.missing_data.no_subscriber_info":"The subscriber information is missing", 
"import_silex.error_text.no_meter_info":"$t(import_silex.error_text.missing_data.no_meter_info)", 
"import_silex.error_text.missing_data.no_meter_info":"The meter information is missing", 
"import_silex.error_text.no_theoretical_serial":"$t(import_silex.error_text.missing_data.no_theoretical_serial)", 
"import_silex.error_text.missing_data.no_theoretical_serial":"The theoretical serial number is missing", 
"import_silex.error_text.no_theoretical_old_serial":"$t(import_silex.error_text.missing_data.no_theoretical_old_serial)", 
"import_silex.error_text.missing_data.no_theoretical_old_serial":"The old theoretical serial number is missing", 
"import_silex.error_text.no_change_meter_authorisation":"$t(import_silex.error_text.missing_data.no_change_meter_authorisation)", 
"import_silex.error_text.missing_data.no_change_meter_authorisation":"The meter change authorization is missing", 
"import_silex.error_text.no_amr_install_date":"$t(import_silex.error_text.missing_data.no_amr_install_date)", 
"import_silex.error_text.missing_data.no_amr_install_date":"The date of installation of the AMR is missing", 
"import_silex.error_text.no_amr_index_number":"$t(import_silex.error_text.missing_data.no_amr_index_number)", 
"import_silex.error_text.missing_data.no_amr_index_number":"The index number of the AMR is missing", 
"import_silex.error_text.no_meter_max_value":"$t(import_silex.error_text.missing_data.no_meter_max_value)", 
"import_silex.error_text.missing_data.no_meter_max_value":"The maximu value of the meter is missing", 
"import_silex.error_text.no_meter_unit":"$t(import_silex.error_text.missing_data.no_meter_unit)", 
"import_silex.error_text.missing_data.no_meter_unit":"The unit of the meter reading is missing", 
"import_silex.error_text.no_amr_reference":"$t(import_silex.error_text.missing_data.no_amr_reference)", 
"import_silex.error_text.missing_data.no_amr_reference":"The AMR reference is missing", 
"import_silex.error_text.no_amr_serial":"$t(import_silex.error_text.missing_data.no_amr_serial)", 
"import_silex.error_text.missing_data.no_amr_serial":"The serial number of the AMR is missing", 
"import_silex.error_text.no_amr_internal_id":"$t(import_silex.error_text.missing_data.no_amr_internal_id)", 
"import_silex.error_text.missing_data.no_amr_internal_id":"The internal AMR identifier is missing", 
"import_silex.error_text.no_amr_data":"$t(import_silex.error_text.missing_data.no_amr_data)", 
"import_silex.error_text.missing_data.no_amr_data":"AMR data is missing", 
"import_silex.error_text.no_pulse_weight":"$t(import_silex.error_text.missing_data.no_pulse_weight)", 
"import_silex.error_text.missing_data.no_pulse_weight":"The pulse weight is missing", 
"import_silex.error_text.no_amr_max_value":"$t(import_silex.error_text.missing_data.no_amr_max_value)", 
"import_silex.error_text.missing_data.no_amr_max_value":"The maximu value of the AMR is missing", 
"import_silex.error_text.no_previous_read_date":"$t(import_silex.error_text.missing_data.no_previous_read_date)", 
"import_silex.error_text.missing_data.no_previous_read_date":"The previous reading date is missing", 
"import_silex.error_text.no_previous_meter_index":"$t(import_silex.error_text.missing_data.no_previous_meter_index)", 
"import_silex.error_text.missing_data.no_previous_meter_index":"The previous meter reading is missing", 
"import_silex.error_text.no_previous_amr_index":"$t(import_silex.error_text.missing_data.no_previous_amr_index)", 
"import_silex.error_text.missing_data.no_previous_amr_index":"The previous AMR index is missing", 
"import_silex.error_text.no_previous_alarms":"$t(import_silex.error_text.missing_data.no_previous_alarms)", 
"import_silex.error_text.missing_data.no_previous_alarms":"Previous AMR alarms are missing", 
"import_silex.error_text.no_previous_status":"$t(import_silex.error_text.missing_data.no_previous_status)", 
"import_silex.error_text.missing_data.no_previous_status":"The previous status of the AMR is missing", 
"import_silex.error_text.no_new_serial":"$t(import_silex.error_text.missing_data.no_new_serial)", 
"import_silex.error_text.missing_data.no_new_serial":"The serial number of the new meter is missing", 
"import_silex.error_text.no_number_of_digits":"$t(import_silex.error_text.missing_data.no_number_of_digits)", 
"import_silex.error_text.missing_data.no_number_of_digits":"The number of digits is missing", 
"import_silex.error_text.no_start_index":"$t(import_silex.error_text.missing_data.no_start_index)", 
"import_silex.error_text.missing_data.no_start_index":"The starting index is missing", 
"import_silex.error_text.no_install_date":"$t(import_silex.error_text.missing_data.no_install_date)", 
"import_silex.error_text.missing_data.no_install_date":"The installation date is missing", 
"import_silex.error_text.no_meter_serial":"$t(import_silex.error_text.missing_data.no_meter_serial)", 
"import_silex.error_text.missing_data.no_meter_serial":"The serial number of the meter is missing", 
"import_silex.error_text.no_read_date":"$t(import_silex.error_text.missing_data.no_read_date)", 
"import_silex.error_text.missing_data.no_read_date":"The relief date is missing", 
"import_silex.error_text.no_meter_index":"$t(import_silex.error_text.missing_data.no_meter_index)", 
"import_silex.error_text.missing_data.no_meter_index":"The meter reading is missing", 
"import_silex.error_text.no_backflow_index":"$t(import_silex.error_text.missing_data.no_backflow_index)", 
"import_silex.error_text.missing_data.no_backflow_index":"The backflow index is missing", 
"import_silex.error_text.no_fraud_alarm":"$t(import_silex.error_text.missing_data.no_fraud_alarm)", 
"import_silex.error_text.missing_data.no_fraud_alarm":"The fraud alarm is missing", 
"import_silex.error_text.no_leak_alarm":"$t(import_silex.error_text.missing_data.no_leak_alarm)", 
"import_silex.error_text.missing_data.no_leak_alarm":"The leakage alarm is missing", 
"import_silex.error_text.no_backflow_alarm":"$t(import_silex.error_text.missing_data.no_backflow_alarm)", 
"import_silex.error_text.missing_data.no_backflow_alarm":"The backflow alarm is missing", 
"import_silex.error_text.no_battery_alarm":"$t(import_silex.error_text.missing_data.no_battery_alarm)", 
"import_silex.error_text.missing_data.no_battery_alarm":"The battery alarm is missing", 
"import_silex.error_text.no_faulty_module_alarm":"$t(import_silex.error_text.missing_data.no_faulty_module_alarm)", 
"import_silex.error_text.missing_data.no_faulty_module_alarm":"The module fault alarm is missing", 
"import_silex.error_text.no_clock_alarm":"$t(import_silex.error_text.missing_data.no_clock_alarm)", 
"import_silex.error_text.missing_data.no_clock_alarm":"The clock alarm is missing", 
"import_silex.error_text.no_page_number":"$t(import_silex.error_text.missing_data.no_page_number)", 
"import_silex.error_text.missing_data.no_page_number":"The survey page number is missing", 
"import_silex.error_text.no_actual_serial":"$t(import_silex.error_text.missing_data.no_actual_serial)", 
"import_silex.error_text.missing_data.no_actual_serial":"The current serial number is missing", 
"import_silex.error_text.missing_t0_empty":"$t(import_silex.error_text.missing_line.missing_t0)", 
"import_silex.error_text.missing_line.missing_t0":"No route record line found", 
"import_silex.error_text.missing_t1_empty":"$t(import_silex.error_text.missing_line.missing_t1)", 
"import_silex.error_text.missing_line.missing_t1":"No meter record line found", 
"import_silex.error_text.missing_t2_empty":"$t(import_silex.error_text.missing_line.missing_t2)", 
"import_silex.error_text.missing_line.missing_t2":"No AMR record line found", 
"import_silex.error_text.missing_t5_empty":"$t(import_silex.error_text.missing_line.missing_t5)", 
"import_silex.error_text.missing_line.missing_t5":"No survey page definition record line found", 
"import_silex.error_text.missing_t6_empty":"$t(import_silex.error_text.missing_line.missing_t6)", 
"import_silex.error_text.missing_line.missing_t6":"No survey response found", 
"import_silex.error_text.missing_t2":"Radio numbered {{serial}}", 
"import_silex.error_text.missing_line.missing_specific_t2":"No AMR recording line found associated with the radio opposite", 
"import_silex.error_text.missing_t5":"Survey {{page}} is missing", 
"import_silex.error_text.missing_line.missing_specific_t5":"No survey page definition line found for the survey opposite", 
"import_silex.error_text.missing_t6":"Response to survey {{page}} corresponding to the {{serial}} meter is missing", 
"import_silex.error_text.missing_line.missing_specific_t6":"Missing survey response for the meter opposite", 
"import_silex.error_text.wrong_data":"The data does not respect the desired format", 
"import_silex.error_text.wrong_data.round_code":"The round code '{{value}}' does not correspond to the desired format", 
"import_silex.error_text.wrong_data.round_wheels":"The default number of wheels for the round '{{value}}' does not match the desired format", 
"import_silex.error_text.wrong_data.consumption_threshold":"The consumption threshold for the round '{{value}}' does not correspond to the desired format", 
"import_silex.error_text.wrong_data.meter_info_area_pos":"The position of zone {{number}} of the meter information '{{value}}' does not correspond to the desired format.", 
"import_silex.error_text.wrong_data.meter_info_area_size":"The size of zone {{number}} of the meter information '{{value}}' does not correspond to the desired format.", 
"import_silex.error_text.wrong_data.meter_info_area_type":"The type of zone {{number}} of the meter information '{{value}}' does not correspond to the desired format.", 
"import_silex.error_text.wrong_data.subscriber_info_area_pos":"The position of zone {{number}} of the subscriber information '{{value}}' does not correspond to the desired format.", 
"import_silex.error_text.wrong_data.subscriber_info_area_size":"The size of zone {{number}} of the subscriber information '{{value}}' does not correspond to the desired format.", 
"import_silex.error_text.wrong_data.subscriber_info_area_type":"The type of zone {{number}} of the subscriber information '{{value}}' does not correspond to the desired format.", 
"import_silex.error_text.wrong_data.order_number":"The order number '{{value}}' does not correspond to the desired format.", 
"import_silex.error_text.wrong_data.read_history_date":"The date of the historical reading {{number}} of the meter '{{value}}' does not correspond to the desired format.", 
"import_silex.error_text.wrong_data.read_history_consumption":"The consumption of the historical reading {{number}} of the meter '{{value}}' does not correspond to the desired format.", 
"import_silex.error_text.wrong_data.number_theorical_wheels":"The theoretical number of wheels of the meter '{{value}}' does not correspond to the desired format.", 
"import_silex.error_text.wrong_data.number_revised_wheels":"The revised number of wheels of the meter '{{value}}' does not correspond to the desired format.", 
"import_silex.error_text.wrong_data.renovation_year":"The renovation date ofthe meter '{{value}}' does not correspond to the desired format", 
"import_silex.error_text.wrong_data.previous_read_date":"The date of the previous reading of the meter '{{value}}' does not correspond to the desired format", 
"import_silex.error_text.wrong_data.previous_read_index":"The index of the previous reading of the meter '{{value}}' does not correspond to the desired format", 
"import_silex.error_text.wrong_data.estimated_daily_consumption_min":"The estimated minimum daily consumption of the meter '{{value}}' does not correspond to the desired format", 
"import_silex.error_text.wrong_data.estimated_daily_consumption_max":"The estimated maximum daily consumption of the meter '{{value}}' does not correspond to the desired format", 
"import_silex.error_text.wrong_data.actual_read_date_time":"The current reading date for meter '{{value}}' does not match the required format", 
"import_silex.error_text.wrong_data.actual_read_index":"The current reading index for meter '{{value}}' does not match the required format", 
"import_silex.error_text.wrong_data.actual_read_status":"The current reading status for meter '{{value}}' does not match the required format", 
"import_silex.error_text.wrong_data.actual_read_consumption":"The current reading consumption for meter '{{value}}' does not match the required format", 
"import_silex.error_text.wrong_data.actual_read_method":"The method used to read the current meter reading '{{value}}' does not match the required format", 
"import_silex.error_text.wrong_data.update_date_time":"The meter update date '{{value}}' does not match the required format", 
"import_silex.error_text.wrong_data.change_meter_authorization":"The meter change authorisation '{{value}}' does not match the required format", 
"import_silex.error_text.wrong_data.radio_install_date":"The installation date of the radio '{{value}}' does not match the required format", 
"import_silex.error_text.wrong_data.radio_index":"The index of the radio '{{value}}' does not correspond to the required format", 
"import_silex.error_text.wrong_data.meter_maximal_index":"The maximum counter index linked to the radio '{{value}}' does not correspond to the desired format", 
"import_silex.error_text.wrong_data.meter_unit":"The counter index unit '{{value}}' does not match the required format", 
"import_silex.error_text.wrong_data.radio_data":"AMR data '{{value}}' does not correspond to the desired format", 
"import_silex.error_text.wrong_data.pulse_weight":"The pulse weight of the radio '{{value}}' does not correspond to the desired format", 
"import_silex.error_text.wrong_data.radio_maximal_index":"The maximum index of the radio '{{value}}' does not correspond to the desired format", 
"import_silex.error_text.wrong_data.radio_previous_date":"The date of the previous reading of the radio '{{value}}' does not correspond to the desired format", 
"import_silex.error_text.wrong_data.radio_meter_previous_index":"The meter index of the previous reading of the radio '{{value}}' does not correspond to the desired format", 
"import_silex.error_text.wrong_data.radio_previous_index":"The index of the previous radio reading '{{value}}' does not match the desired format", 
"import_silex.error_text.wrong_data.radio_previous_alarms":"The alarms for the previous radio reading '{{value}}' do not correspond to the desired format", 
"import_silex.error_text.wrong_data.radio_previous_status":"The status of the previous radio reading '{{value}}' does not correspond to the desired format", 
"import_silex.error_text.wrong_data.radio_current_read_date":"La fecha del turno de radio actual en la radio '{{value}}' no se corresponde con el formato deseado", 
"import_silex.error_text.wrong_data.radio_meter_current_index":"The meter index of the current reading of the radio '{{value}}' does not correspond to the desired format", 
"import_silex.error_text.wrong_data.radio_current_index":"The index of the current radio reading '{{value}}' does not correspond to the desired format", 
"import_silex.error_text.wrong_data.radio_current_alarms":"The alarms for the current radio reading '{{value}}' do not correspond to the desired format", 
"import_silex.error_text.wrong_data.radio_current_status":"The status of the current radio reading '{{value}}' does not correspond to the desired format", 
"import_silex.error_text.wrong_data.new_meter_wheels_number":"The number of wheels iof the new meter '{{value}}' does not match the desired format", 
"import_silex.error_text.wrong_data.new_meter_start_index":"The start index of the new meter '{{value}}' does not match the desired format", 
"import_silex.error_text.wrong_data.new_meter_install_date":"The installation date of the new meter '{{value}}' does not match the desired format", 
"import_silex.error_text.wrong_data.radio_sensor_t4":"AMR index number '{{value}}' does not match the required format", 
"import_silex.error_text.wrong_data.radio_read_date_t4":"The AMR reading date '{{value}}' does not match the desired format", 
"import_silex.error_text.wrong_data.radio_meter_current_index_t4":"The meter index for the current reading in radio '{{value}}' does not correspond to the desired format.", 
"import_silex.error_text.wrong_data.radio_backflow_index_t4":"The AMR return water index '{{value}}' does not correspond to the desired format", 
"import_silex.error_text.wrong_data.radio_alarms_t4":"AMR alarms '{{value}}' do not correspond to the desired format", 
"import_silex.error_text.wrong_data.radio_read_history_date_time_t4":"The date of the historical reading {{number}} of the radio '{{value}}' does not correspond to the desired format", 
"import_silex.error_text.wrong_data.radio_read_history_index_t4_":"The index of the historical reading {{number}} of the radio '{{value}}' does not correspond to the desired format", 
"import_silex.error_text.wrong_data.radio_doublon":"The radio ‘{{value}}’ is duplicated in the file on the same probe", 
"import_silex.error_text.wrong_data.enquiry_size_area_":"The size of the field {{number}} of the survey page '{{value}}' does not match the desired format", 
"import_silex.error_text.wrong_data.gps_coordinates":"The GPS coordinates of the meter '{{value}}' do not correspond to the desired format", 
"import_silex.error_text.wrong_data.gps_altitude":"The altitude of the meter '{{value}}' does not correspond to the desired format", 
"import_silex.error_text.wrong_data.gsp_precision":"The precision of the meter '{{value}}' does not correspond to the desired format", 
"import_silex.error_text.wrong_data.gps_update_date":"The GPS update date for the meter '{{value}}' does not match the desired format", 
"import_silex.error_text.wrong_data.incorrect_round_wheels_number":"The default number of wheels for the round '{{value}}' is not between 4 and 9", 
"import_silex.error_text.wrong_data.meter_have_been_changed":"The meter is recorded as having been changed", 
"import_silex.error_text.wrong_data.meter_revised_serial_missing":"The revised serial number is missing from the file", 
"import_silex.error_text.wrong_data.meter_revised_serial_incorrect":"The revised serial number is inconsistent with the data on the site", 
"import_silex.error_text.wrong_data.incorrect_theorical_wheels_number":"The theoretical number of wheels on the meter '{{value}}' is not between 4 and 9", 
"import_silex.error_text.wrong_data.incorrect_revised_wheels_number":"The revised number of wheels on the meter '{{value}}' is not between 4 and 9", 
"import_silex.error_text.wrong_data.previous_index_over_max":"The previous meter index '{{value}}' exceeds the maximum meter index", 
"import_silex.error_text.wrong_data.current_index_over_max":"Current meter index '{{value}}' exceeds maximum meter index", 
"import_silex.error_text.wrong_data.new_meter_serial_already_used":"The serial number of the new meter '{{value}}' is already in use", 
"import_silex.error_text.wrong_data.radios_meter_previous_index_too_high":"The previous meter index linked to the radio '{{value}}' exceeds the maximum meter index", 
"import_silex.error_text.wrong_data.radios_meter_current_index_too_high":"The current meter index linked to the radio '{{value}}' exceeds the maximum meter index", 
"import_silex.error_text.wrong_data.t3_new_meter_start_index":"The starting index of the new meter '{{value}}' exceeds the maximum index of the meter", 
"import_silex.error_text.wrong_data.incorrect_latitude_coordinate_value":"The latitude of the meter '{{value}}' is outside the range -90, 90", 
"import_silex.error_text.wrong_data.longitude_is_missing":"GPS coordinates incomplete, longitude missing", 
"import_silex.error_text.wrong_data.incorrect_longitude_coordinate_value":"The longitude of meter '{{value}}' is outside the range -180, 180", 
"import_silex.error_text.wrong_data.latitude_is_missing":"GPS coordinates incomplete, latitude missing", 
"import_silex.error_text.meter_in_round":"One or more meters are already associated with other rounds", 
"import_silex.error_text.t2_export_error.no_mrlStartDate":"Date of installation of the missing radio", 
"import_silex.column_label.errors":"Errors", 
"import_silex.column_label.lines":"Lines", 
"import_silex.column_label.types":"Types", 
"import_silex.column_label.positions":"Positions", 
"import_silex.column_label.description":"Description", 
"import_silex.column_label.associated_round":"Associated rounds", 
"provisioning.text.provisionned":"provisioned ", 
"provisioning.text.not_provisionned":"Not provisioned ", 
"provisioning.text.actions":"Actions", 
"telereleveinfo.text.network":"Network", 
"telereleveinfo.text.deveui":"DevEUI", 
"telereleveinfo.text.last_telereleve_read":"Latest remote-reading date", 
"telereleveinfo.text.meter_index":"$t(widget.display_value.meterreadingvalue)", 
"telereleveinfo.text.radio_index":"$t(widget.display_value.radioreadingvalue)", 
"telereleveinfo.text.provision_radio":"Provisioned", 
"telereleveinfo.text.deprovision_radio":"Unprovisioned", 
"telereleveinfo.text.provision_impossible":"Impossible provisioning ", 
"telereleveinfo.text.missing_informations":"Missing information", 
"telereleveinfo.text.reprovision_impossible_message":"iIf you decide to deprovision a module, you can no longer provision it because the necessary information would be missing ", 
"telereleve.text.telereleve":"Remote-reading", 
"telereleve.column.networkName":"Protocol", 
"telereleve.column.clpReference":"$t(all.pdi.pdi)", 
"telereleve.column.mtrSerialNumber":"$t(all.meter.meter_serial)", 
"telereleve.column.radioSerialNumber":"$t(all.meter.radio_serial)", 
"telereleve.column.devEUI":"$t(telereleveinfo.text.deveui)", 
"telereleve.column.providerName":"Network name", 
"telereleve.column.canProvision":"Provisionable", 
"telereleve.column.adress":"$t(all.location.address)", 
"telereleve.text.load_telereleve":"Loading remote readings ", 
"provisionning.text.provision_processing":"Provisioning in progress", 
"custom_export.title.custom_export":"Custom export", 
"custom_export.title.custom_export_plural":"Custom exports", 
"custom_export.text.add_custom_export":"Add a custom export", 
"custom_export.text.export_name":"Export name", 
"custom_export.text.export_filename":"Generated file name", 
"custom_export.text.edit_custom_export":"Edit custom export ", 
"custom_export.text.duplicate":"Duplicate custom export ", 
"custom_export.text.launch_export":"Launch custom export ", 
"custom_export.text.delete_export":"Delete custom export", 
"custom_export.text.define_at_launch":"Set at launch ", 
"custom_export.text.sorting":"Sort ", 
"custom_export.text.add_filter_value_to_filename":"Adding value filters to the file name", 
"custom_export.text.generatedDate":"Generation date ", 
"custom_export.text.generatedHour":"Generation hour", 
"custom_export.text.nameSite":"$t(identity_chooser.text.location_name)", 
"gateway.text.gateway":"Gateway ", 
"gateway_meter_captured.columns.field":"Number of meters in range ", 
"gateway_geolocated.columns.field":"Geolocated gateway", 
"gateway_name.text.map":"Gateway name", 
"gateway_geolocated_meter_captured.columns.field":"Number of geolocated meters read", 
"gateway_geolocated_meter_last_read.text":"Number of geolocated meters read on last reading", 
"gateway.registration_date":"Gateway registration date", 
"gayeway.last_communication_date":"Date of last communication from the gateway", 
"gateway.model":"Gateway model", 
"gateway.informations":"Information from the gateway", 
"manage_pdi.text.nb_meter_to_link":"{{number}} meters to link", 
"manage_pdi.text.nb_meter_to_unlink":"{{number}} meters to unlink", 
"mapbox_button.text.save_polygon":"Save polygon(s)", 
"mapbox.return_all_gateways":"Return to the display of all gateways", 
"tournee.text.export.success":"The {{tournee}} round was successfully exported", 
"tournee.text.export.error_detected":"An error was detected during the {{tournee}} round export", 
"tournee.text.export_factu.success":"The {{tournee}} round was successfully exported to billing", 
"tournee.text.forbidden":"$t(tournee.text.unauthorized)", 
"tournee.text.unauthorized":"Unauthorized", 
"tournee.text.failure":"Failure", 
"tournee.text.notfound":"Not found", 
"tournee.text.imported":"Imported", 
"tournee.text.success":"Exported", 
"tournee.text.export_round_csv_custom":"Route export", 
"tournee.text.export_round_csv_custom_choices":"Export type", 
"tournee.text.export_dat":"DAT", 
"tournee.text.export_csv":"CSV", 
"tournee.text.error.incorrect_length":"Lines with incorrect length", 
"tournee.text.error.wrong_data":"Wrong data", 
"tournee.text.error.missing_data":"Missing data", 
"tournee.text.error.no_line_id":"Missing line Id", 
"tournee.text.error.silex_format_error":"Silex format error", 
"tournee.text.error.t2_export_error":"Radio export error", 
"tournee .text.error.last_try":"Last attempt : {{time}}", 
"tournee.text.export.csv.config.not_set":"CSV export configurations not correctly filled in", 
"tournee.text.import.csv.config.not_set":"CSV import configurations not correctly filled in", 
"tournee.text.facturation.no_filter_export":"Complete export without filtering", 
"tournee.text.delete_success":"The round {{tournee}} has been successfully deleted", 
"tournee.text.delete_success_plural":"Rounds {{tournee}} have been successfully deleted", 
"tournee.text.delete_success_plural_no_details":"Rounds have been successfully deleted", 
"galery.title":"Picture", 
"galery.title_plural":"Pictures", 
"galery.others":"others", 
"galery.picture_name":"Picture name", 
"galery.fold":"fold", 
"maximo.assetnum":"$t(all.column_text.pdi_ref)", 
"maximo.parent":"Parent reference", 
"maximo.reportedby":"Reported by", 
"maximo.intitule":"$t(all.text.message)", 
"maximo.none":"No BT", 
} 
}; 
