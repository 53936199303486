import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import DownloadArrow from "../SvgComponents/DownloadArrow";
import FileDropZone from "../_components/FileDropZone";
import { Button, FormGroup, UncontrolledTooltip } from "reactstrap";
import { TFunction, WithTranslation } from "react-i18next";
import {
  Checkbox,
  Divider,
  FormControlLabel,
  FormLabel,
} from "@material-ui/core";
import { History } from "history";
import { importActions, rndTemplateActions } from "../_actions";
import Ajout from "../SvgComponents/AjoutBleu";
import FlecheAllerLarge from "../SvgComponents/FlecheAllerLarge";
import BootstrapTable from "react-bootstrap-table-next";
import { TemplateDto } from "../_interfaces/TourneeCreationTypes";
import RoundButton from "../_components/RoundButton";
import _ from "lodash";
import SvgEditionBleu from "../SvgComponents/EditionBleu";
import SvgPoubelleBleu from "../SvgComponents/PoubelleBleu";
import { RouteComponentProps } from "react-router";

const defaultSelectedLinesValue = [
  true,
  true,
  false,
  true,
  false,
  true,
  true,
  true,
];
const emptySelectedLinesValue = new Array<boolean>(8).fill(false);
const fullSelectedLineValue = new Array<boolean>(8).fill(true);

const ImportFileTab: React.FC<
  {
    dispatch: Function;
    gestionnaire: any;
    history: History;
    imports: any;
    rndTemplate: any;
    setIsSilexImport: Dispatch<SetStateAction<boolean>>;
    setLoadingMessage: Dispatch<SetStateAction<string>>;
    t: TFunction<"translation", undefined>;
    tournee: any;
    user: any;
  } & RouteComponentProps &
    WithTranslation
> = ({
  dispatch,
  gestionnaire,
  history,
  imports,
  rndTemplate,
  setIsSilexImport,
  setLoadingMessage,
  t,
  tournee,
  user,
  match,
}) => {
  const [silexFile, setSilexFile] = useState<File>();
  const [selectedLines, setSelectedLines] = useState<boolean[]>(
    defaultSelectedLinesValue
  );
  const [authorizeNewMeterAfterChange, setAuthorizeNewMeterAfterChange] =
    useState<boolean>(false);
  const [stateTimer, setStateTimer] = useState<NodeJS.Timeout>();
  const [authorizeSelection, setAuthorizeSelection] = useState<boolean>(false);
  const admin = user.role.name === "DIOPTASE";
  const extensions = ".dat, .rtr, .txt";
  const csvExtensions = ", .csv, .xlsx, .xls";

  const handleLineChoice = (line: number, newState: boolean) => {
    const targetArray = [...selectedLines];
    switch (line) {
      case 0:
      case 1:
        targetArray[0] = newState;
        targetArray[1] = newState;
        break;
      case 2:
        targetArray[2] = newState;
        if (!newState) {
          targetArray[4] = newState;
        }
        break;
      case 4:
        targetArray[4] = newState;
        if (newState) {
          targetArray[2] = newState;
        }
        break;
      case 5:
      case 6:
        targetArray[5] = newState;
        targetArray[6] = newState;
        break;
      default:
        targetArray[line] = newState;
    }
    setSelectedLines(targetArray);
  };

  const onTemplateDelete = (templateId: number) => {
    dispatch(rndTemplateActions.deleteTemplate(templateId));
  };

  const validateSilexFile = () => {
    if (silexFile) {
      const file = new FormData();
      file.append("file", silexFile);
      setLoadingMessage(t("import_silex.loading_band.silex_file_loading"));
      setIsSilexImport(true);
      dispatch(
        importActions.reworkedImport(
          file,
          gestionnaire.id,
          authorizeNewMeterAfterChange,
          admin ? selectedLines : undefined
        )
      );
      setStateTimer(setTimeout(getImportState, 5000));
    }
  };

  const getImportState = () => {
    dispatch(importActions.getImportState());
    setStateTimer(setTimeout(getImportState, 2000));
  };

  const isButtonSelected = (selectedLinesCheck: boolean[]) => {
    return selectedLinesCheck.every(
      (value: boolean, index: number) => value === selectedLines[index]
    );
  };

  const handleAuthorizeLineSelection = () => {
    if (authorizeSelection) {
      setSelectedLines(fullSelectedLineValue);
    }
    setAuthorizeSelection(!authorizeSelection);
  };

  useEffect(() => {
    if (silexFile) {
      if (
        [".csv", ".xlsx", ".xls"].filter((ext) => silexFile?.name.endsWith(ext))
          .length != 0
      ) {
        setSelectedLines(fullSelectedLineValue);
      } else {
        setAuthorizeSelection(false);
        setSelectedLines(defaultSelectedLinesValue);
      }
    } else {
      setAuthorizeSelection(false);
    }
  }, [silexFile]);

  useEffect(() => {
    if (imports && (imports.silex || imports.error || imports.errorSilex)) {
      history.push(`silex/import`, { file: silexFile, adminimport: admin });
    }
  }, [imports]);

  useEffect(() => {
    if (stateTimer != null && !imports.loading) {
      setIsSilexImport(false);
      clearTimeout(stateTimer);
    }
  }, [imports.loading]);

  const columns = [
    {
      dataField: "name",
      text: t("all.text.name"),
    },
    {
      dataField: "type",
      text: t("all.text.type"),
      formatter: (cell: any, row: TemplateDto) => {
        return row.type === "meter"
          ? t("all.meter.meter")
          : t("all.text.subscriber");
      },
    },
    {
      dataField: "actions",
      formatter: (cell: any, row: TemplateDto) => {
        return (
          <div className="template-action-cell">
            <RoundButton
              id={`template-${row.id}-edit`}
              onClick={() =>
                history.push(
                  `/gestionnaires/${_.get(
                    match,
                    "params.GestionnaireId"
                  )}/synchronisation/silex/template`,
                  {
                    template: row,
                    from: location.pathname,
                  }
                )
              }
              icon={<SvgEditionBleu height="1em" width="1em" />}
            />
            <RoundButton
              id={`template-${row.id}-remove`}
              onClick={() => {
                setLoadingMessage(
                  t("tournee_creation.loading_band.template_deletion")
                );
                onTemplateDelete(row.id!);
              }}
              disabled={
                imports.loading ||
                rndTemplate.loading ||
                (row.linkedTourneeList && row.linkedTourneeList.length !== 0)
              }
              icon={
                <SvgPoubelleBleu
                  height="1em"
                  width="1em"
                  fill={
                    row.linkedTourneeList &&
                    row.linkedTourneeList.length !== 0 &&
                    "lightgray"
                  }
                />
              }
              withPopover
              popoverTitle={t("tournee_creation.template_popover.title")}
              confirmText={t("tournee_creation.template_popover.confirm_text")}
            />
            {row.linkedTourneeList && row.linkedTourneeList.length !== 0 && (
              <UncontrolledTooltip
                placement="bottom"
                target={`template-${row.id}-remove`}
              >
                {t("tournee_creation.error_band.template_still_linked", {
                  template: row.name,
                  rndList: row.linkedTourneeList?.join(", "),
                })}
              </UncontrolledTooltip>
            )}
          </div>
        );
      },
      text: "",
    },
  ];

  return (
    <>
      <DownloadArrow
        width="40px"
        fill="#ACABAB"
        className="dl-icon import-side-icon"
      />
      <h3 className="import-side-title">{t("all.text.select_file")}</h3>
      <FileDropZone
        acceptedFilesFormat={
          tournee.importOption?.import_round_csv_enabled
            ? extensions + csvExtensions
            : extensions
        }
        file={silexFile}
        height={admin ? "150px" : "265px"}
        setFile={setSilexFile}
      />
      {admin && (
        <div className="admin-part">
          <FormGroup component="fieldset" className="admin-checkbox-container">
            <FormLabel component="legend">
              Choisir le type d'import{" "}
              <span className="admin-label-decoration">admin</span> :
            </FormLabel>
            {[".csv", ".xlsx", ".xls"].filter((ext) =>
              silexFile?.name.endsWith(ext)
            ).length != 0 && (
              <>
                Lors d'un import csv ou excel, toutes les lignes sont importées
                par défaut
                <br />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={authorizeSelection}
                      name={`authorizeSelectionCheckbox`}
                      color="primary"
                      className="admin-checkbox"
                      onClick={handleAuthorizeLineSelection}
                      disabled={!admin}
                    />
                  }
                  label={`Autoriser la selection des lignes`}
                />
                <br />
              </>
            )}
            {selectedLines.map((value: boolean, type: number) => (
              <FormControlLabel
                key={type}
                control={
                  <Checkbox
                    checked={value}
                    name={`T${type}`}
                    color="primary"
                    className="admin-checkbox"
                    onClick={() => handleLineChoice(type, !value)}
                    disabled={
                      !admin ||
                      ([".csv", ".xlsx", ".xls"].filter((ext) =>
                        silexFile?.name.endsWith(ext)
                      ).length != 0 &&
                        !authorizeSelection)
                    }
                  />
                }
                label={`Ligne T${type}`}
              />
            ))}
          </FormGroup>
          <div className="admin-selection-button-container">
            <Button
              className={
                "import-silex-selection-button" +
                (isButtonSelected(fullSelectedLineValue) ? " selected" : "")
              }
              onClick={() => setSelectedLines(fullSelectedLineValue)}
            >
              {t("all.text.select_all")}
            </Button>
            <Button
              className={
                "import-silex-selection-button" +
                (isButtonSelected(emptySelectedLinesValue) ? " selected" : "")
              }
              onClick={() => setSelectedLines(emptySelectedLinesValue)}
            >
              {t("all.text.deselect_all")}
            </Button>
            <Button
              className={
                "import-silex-selection-button" +
                (isButtonSelected(defaultSelectedLinesValue) ? " selected" : "")
              }
              onClick={() => setSelectedLines(defaultSelectedLinesValue)}
            >
              {t("remotereading.text.select_default")}
            </Button>
          </div>
          <div style={{ marginTop: "20px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={authorizeNewMeterAfterChange}
                  name="authorizeNewMeterAfterChange"
                  color="primary"
                  className="admin-checkbox"
                  onClick={() =>
                    setAuthorizeNewMeterAfterChange(
                      !authorizeNewMeterAfterChange
                    )
                  }
                  disabled={!admin}
                />
              }
              label={`Autoriser la réinstallation d'un compteur après son changement ou la correction de son numéro de série`}
            />
          </div>
        </div>
      )}
      <Button
        id="access-button"
        className={
          "col-right footer-content tournee-import-submit-button" +
          (silexFile ? " visible" : "")
        }
        onClick={validateSilexFile}
        disabled={imports.loading || rndTemplate.loading}
      >
        {t("all.button.validate")}
      </Button>

      {/* Partie création de tournée */}
      <div
        className="tournee-creation-part"
        // style={{ maxHeight: admin ? "565px" : "465px" }}
      >
        {/* Block création de tournée */}
        <div className="create-side">
          <div className="add-icon">
            <Ajout className="add" fill="#ACABAB" width="24px" />
          </div>
          <h3 className="align-start">
            {t("tournee_creation.container_label.create_tournee")}
          </h3>
          <button
            className="tournee-creation-link-button"
            onClick={() =>
              history.push(
                `/gestionnaires/${_.get(
                  match,
                  "params.GestionnaireId"
                )}/synchronisation/silex/new`
              )
            }
          >
            <div className="tournee-creation-link-label">
              {t("tournee_creation.button.start")}
              <FlecheAllerLarge
                width="14px"
                className="tournee-creation-link-icon"
              />
            </div>
          </button>
        </div>

        {/* Block gestion des templates */}
        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="create-side">
            <div className="add-icon" />
            <h3 className="align-start">
              {t("tournee_creation.container_label.template_list")}
            </h3>
            <div
              className="template-add-button-container"
              onClick={() =>
                history.push(
                  `/gestionnaires/${_.get(
                    match,
                    "params.GestionnaireId"
                  )}/synchronisation/silex/template`,
                  {
                    from: location.pathname,
                  }
                )
              }
            >
              <button className="template-add-button">
                <Ajout className="add" fill="curentcolor" width="20px" />
              </button>
            </div>
          </div>
          {rndTemplate.templates && rndTemplate.templates.length != 0 && (
            <>
              <Divider className="template-divider" />
              <div className="template-table-container">
                <BootstrapTable
                  keyField="id"
                  bootstrap4
                  bordered={false}
                  hover
                  data={rndTemplate.templates}
                  columns={columns}
                  condensed
                  headerClasses="template-table-header"
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ImportFileTab;
