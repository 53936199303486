import _ from "lodash";
import { de } from "date-fns/locale";
import tourneeConstants from "../_constants/tournee.constants";
import tourneeService from "../_services/tournee.service";
import synchroActions from "../_actions/synchro.actions";
import synchroAction from "./synchro.actions";
import { roundDeletionReport } from "../_interfaces/reducers";
import { TourneeEncodingTypes } from "../_enums/tournee";
import { TourneeCreationDto } from "../_interfaces/TourneeCreationTypes";

function getTournee(tourneeCode: any) {
  function request() {
    return { type: tourneeConstants.GETTOURNEE_REQUEST };
  }
  function success(tournee: Object) {
    return { type: tourneeConstants.GETTOURNEE_SUCCESS, tournee };
  }
  function failure(error: Object) {
    return { type: tourneeConstants.GETTOURNEE_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    tourneeService
      .getTournee(tourneeCode)
      .then((tournee: Object) => dispatch(success(tournee)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function createTournee(tournee: TourneeCreationDto, gestionnaireId: number) {
  function request() {
    return { type: tourneeConstants.CREATE_TOURNEE_REQUEST };
  }
  function success() {
    return { type: tourneeConstants.CREATE_TOURNEE_SUCCESS, tournee };
  }
  function failure(error: Object) {
    return { type: tourneeConstants.CREATE_TOURNEE_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    tourneeService
      .createTournee(tournee, gestionnaireId)
      .then(() => dispatch(success()))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function getLocationIdWithCode(tourneeCode: any) {
  function request() {
    return { type: tourneeConstants.GET_ID_WITH_CODE_REQUEST };
  }
  function success(id: any) {
    return { type: tourneeConstants.GET_ID_WITH_CODE_SUCCESS, id };
  }
  function failure(error: Object) {
    return { type: tourneeConstants.GET_ID_WITH_CODE_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    tourneeService
      .getLocationIdWithCode(tourneeCode)
      .then((tournee: Object) => dispatch(success(tournee)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function rotateFiches(tourneeCode: any) {
  function request() {
    return { type: tourneeConstants.GETTOURNEE_REQUEST };
  }
  function success(tournee: Object) {
    return { type: tourneeConstants.GETTOURNEE_SUCCESS, tournee };
  }
  function failure(error: Object) {
    return { type: tourneeConstants.GETTOURNEE_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    tourneeService
      .rotateFiches(tourneeCode)
      .then((tournee: Object) => {
        dispatch(success(tournee));
        dispatch(getAllFicheTournee(tourneeCode));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function getFicheTournee(tourneeCode: string, ficheId: number) {
  function request() {
    return { type: tourneeConstants.GETTOURNEEFICHE_REQUEST };
  }
  function success(fiche: Object) {
    return { type: tourneeConstants.GETTOURNEEFICHE_SUCCESS, fiche };
  }
  function failure(error: Object) {
    return { type: tourneeConstants.GETTOURNEEFICHE_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    tourneeService
      .getFicheTournee(tourneeCode, ficheId)
      .then((fiche: Object) => dispatch(success(fiche)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function getAllFicheTournee(tourneeCode: string) {
  function request() {
    return { type: tourneeConstants.GETALLTOURNEEFICHE_REQUEST };
  }
  function success(fiche: Object) {
    return { type: tourneeConstants.GETALLTOURNEEFICHE_SUCCESS, fiche };
  }
  function failure(error: Object) {
    return { type: tourneeConstants.GETALLTOURNEEFICHE_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    tourneeService
      .getAllFicheTournee(tourneeCode)
      .then((fiche: Object) => dispatch(success(fiche)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function invalidCache(tourneeCode: string) {
  function success(fiche: Object) {
    return { type: tourneeConstants.GETALLTOURNEEFICHE_SUCCESS, fiche };
  }
  function failure(error: Object) {
    return { type: tourneeConstants.GETALLTOURNEEFICHE_FAILURE, error };
  }
  return (dispatch: Function) => {
    tourneeService
      .invalidCache(tourneeCode)
      .then((fiche: any) => dispatch(success(fiche)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function deleteTournee(roundCode: Array<string>, gestionnaireId: number) {
  function request() {
    return { type: tourneeConstants.DELETE_TOURNEE_REQUEST };
  }

  function success(tourneeDeletionReport: roundDeletionReport) {
    return {
      type: tourneeConstants.DELETE_TOURNEE_SUCCESS,
      tourneeDeletionReport,
    };
  }

  function failure(error: Object) {
    return { type: tourneeConstants.DELETE_TOURNEE_FAILURE, error };
  }

  return (dispatch: Function) => {
    dispatch(request());
    tourneeService
      .deleteTournee(roundCode)
      .then((tourneeDeletionReport: roundDeletionReport) => {
        dispatch(success(tourneeDeletionReport));
        dispatch(synchroAction.getAllRoundFull(gestionnaireId));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

let intervalTelereleveReads: NodeJS.Timeout | null = null;
function launchIntervalTelereleveReads(
  roundCode: string,
  startDate: any,
  endDate: any,
  replaceMoreRecent: boolean
) {
  function success(tournee: Array<any>) {
    return { type: tourneeConstants.GET_TELERELEVE_TOURNEE_SUCCESS, tournee };
  }
  return (dispatch: Function) => {
    tourneeService
      .launchIntervalTelereleveReads(roundCode)
      .then((tournee: any) => {
        if (_.isNull(intervalTelereleveReads)) {
          intervalTelereleveReads = setInterval(() => {
            dispatch(
              launchIntervalTelereleveReads(
                roundCode,
                startDate,
                endDate,
                replaceMoreRecent
              )
            );
          }, 1000);
        }
        if (tournee) {
          clearInterval(intervalTelereleveReads);
          intervalTelereleveReads = null;
          dispatch(success(tournee));
        }
      })
      .catch((error: Object) => {
        console.log("launchIntervalTelereleveReads::error::167", error);
      });
  };
}

function getTelereleveReads(
  roundCode: string,
  startDate: any,
  endDate: any,
  replaceMoreRecent: boolean
) {
  function request() {
    return { type: tourneeConstants.GET_TELERELEVE_TOURNEE_REQUEST };
  }

  function failure(error: Object) {
    return { type: tourneeConstants.GET_TELERELEVE_TOURNEE_FAILURE, error };
  }

  return (dispatch: Function) => {
    dispatch(request());
    tourneeService
      .getTelereleveReads(roundCode, startDate, endDate, replaceMoreRecent)
      .then(() => {
        dispatch(
          launchIntervalTelereleveReads(
            roundCode,
            startDate,
            endDate,
            replaceMoreRecent
          )
        );
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

let intervalFillTelereleveReads: NodeJS.Timeout | null = null;
function launchIntervalFillTelereleveReads(
  roundCode: string,
  startDate: any,
  endDate: any,
  replaceMoreRecent: boolean
) {
  function success(tournee: Array<any>) {
    return { type: tourneeConstants.FILL_TELERELEVE_TOURNEE_SUCCESS, tournee };
  }
  return (dispatch: Function) => {
    tourneeService
      .launchIntervalFillTelereleveReads(roundCode)
      .then((reads: any) => {
        if (_.isNull(intervalFillTelereleveReads)) {
          intervalFillTelereleveReads = setInterval(() => {
            dispatch(
              launchIntervalFillTelereleveReads(
                roundCode,
                startDate,
                endDate,
                replaceMoreRecent
              )
            );
          }, 1000);
        }
        if (reads) {
          clearInterval(intervalFillTelereleveReads);
          intervalFillTelereleveReads = null;
          dispatch(success(reads));
          dispatch(getAllFicheTournee(roundCode));
        }
      })
      .catch((error: Object) => {});
  };
}

function fillTelereleveReads(
  roundCode: string,
  startDate: any,
  endDate: any,
  replaceMoreRecent: boolean
) {
  function request() {
    return { type: tourneeConstants.FILL_TELERELEVE_TOURNEE_REQUEST };
  }

  function success(tournee: Array<any>) {
    return { type: tourneeConstants.FILL_TELERELEVE_TOURNEE_SUCCESS, tournee };
  }

  function failure(error: Object) {
    return { type: tourneeConstants.FILL_TELERELEVE_TOURNEE_FAILURE, error };
  }

  return (dispatch: Function) => {
    dispatch(request());
    tourneeService
      .fillTelereleveReads(roundCode, startDate, endDate, replaceMoreRecent)
      .then((tournee: Array<any>) => {
        dispatch(
          launchIntervalFillTelereleveReads(
            roundCode,
            startDate,
            endDate,
            replaceMoreRecent
          )
        );
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

// Fonctions afin de tenter de convertir avec un encodage spécifique - à vérifier
function convertToWindows1252(text: string) {
  const byteArray = new Uint8Array(text.length);
  for (let i = 0; i < text.length; i++) {
    const charCode = text.charCodeAt(i);
    byteArray[i] = charCode < 256 ? charCode : 32;
  }
  return byteArray;
}

const convertToUTF16 = (text) => {
  const byteArray = new Uint8Array(text.length * 2);
  for (let i = 0; i < text.length; i++) {
    const charCode = text.charCodeAt(i);
    byteArray[i * 2] = charCode >> 8;
    byteArray[i * 2 + 1] = charCode & 255;
  }
  return byteArray;
};

const decodedData = (file: string, encoding: string | null) => {
  switch (encoding) {
    case TourneeEncodingTypes.WINDOWS1252:
      return convertToWindows1252(atob(file));
    case TourneeEncodingTypes.UTF16:
      return convertToUTF16(atob(file));
    default:
      return atob(file);
  }
};

function exportTournee(tourneeCode: Array<string>, gestionnaireId?: string) {
  function request() {
    return { type: tourneeConstants.EXPORT_TOURNEE_REQUEST };
  }
  function success(report: Object) {
    return { type: tourneeConstants.EXPORT_TOURNEE_SUCCESS, report };
  }
  function failure(error: Object) {
    return { type: tourneeConstants.EXPORT_TOURNEE_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    tourneeService
      .exportTournee(tourneeCode)
      .then((resp) => resp.json())
      .then((response: any) => {
        // on récupère l'encodage depuis le back
        const {
          file,
          fileName,
          report,
        }: { file: string; fileName: string; report: any } = response;
        // const fileBlob: Blob = new Blob([decodedData(file, encoding)]);
        const byteCharacters = atob(file);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: "text" });

        const url: string = URL.createObjectURL(blob);

        const a: HTMLAnchorElement = document.createElement("a");
        dispatch(success(report));
        a.href = url;
        a.download = fileName;

        if (
          fileName !== null &&
          report.map((el) => el.status).includes("SUCCESS")
        ) {
          a.click();
        }
        if (gestionnaireId) {
          dispatch(synchroActions.getAllRoundFull(gestionnaireId));
        }
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function exportTourneeCsv(
  tourneeCode: Array<string>,
  format: string,
  gestionnaireId?: string
) {
  function request() {
    return { type: tourneeConstants.EXPORT_TOURNEE_REQUEST };
  }
  function success(report: Object) {
    return { type: tourneeConstants.EXPORT_TOURNEE_SUCCESS, report };
  }
  function failure(error: Object) {
    return { type: tourneeConstants.EXPORT_TOURNEE_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    tourneeService
      .exportTourneeCsv(tourneeCode, format)
      .then((resp) => resp.json())
      .then((response: any) => {
        const {
          file,
          fileName,
          report,
          encoding,
        }: { file: string; fileName: string; encoding: string; report: any } =
          response;
        console.log(response);
        // const fileBlob: Blob = new Blob([decodedData(file, encoding)]);
        const byteCharacters = atob(file);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: "text" });

        dispatch(success(report));
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        if (
          fileName !== null &&
          report.map((el) => el.status).includes("SUCCESS")
        ) {
          a.click();
        }
        if (gestionnaireId) {
          dispatch(synchroActions.getAllRoundFull(gestionnaireId));
        }
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function loadSilexToFactu(roundCode: any, isForced: boolean) {
  function request() {
    return { type: tourneeConstants.SILEX_TO_FACTU_REQUEST };
  }
  function success(report: any) {
    return { type: tourneeConstants.SILEX_TO_FACTU_SUCCESS, report };
  }
  function failure(error: Object) {
    return { type: tourneeConstants.SILEX_TO_FACTU_FAILURE, error };
  }

  return (dispatch: Function) => {
    dispatch(request());
    tourneeService
      .loadSilexToFactu(roundCode, isForced)
      .then((result: any) => {
        dispatch(success(result));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function getFacturationName() {
  function request() {
    return { type: tourneeConstants.GET_FACTURATION_REQUEST };
  }
  function success(name: any) {
    return { type: tourneeConstants.GET_FACTURATION_SUCCESS, name };
  }
  function failure(error: Object) {
    return { type: tourneeConstants.GET_FACTURATION_FAILURE, error };
  }

  return (dispatch: Function) => {
    dispatch(request());
    tourneeService
      .getFacturationName()
      .then((result: any) => {
        dispatch(success(result));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function isFacturationInterventionEnabled() {
  function request() {
    return {
      type: tourneeConstants.GET_FACTURATION_INTERVENTION_ENABLED_REQUEST,
    };
  }
  function success(enabled: boolean) {
    return {
      type: tourneeConstants.GET_FACTURATION_INTERVENTION_ENABLED_SUCCESS,
      enabled,
    };
  }
  function failure(error: Object) {
    return {
      type: tourneeConstants.GET_FACTURATION_INTERVENTION_ENABLED_FAILURE,
      error,
    };
  }

  return (dispatch: Function) => {
    dispatch(request());
    tourneeService
      .isFacturationInterventionEnabled()
      .then((enabled: boolean) => {
        dispatch(success(enabled));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function exportTourneeTeleo(
  tourneeCode: Array<string>,
  gestionnaireId?: string
) {
  function request() {
    return { type: tourneeConstants.EXPORT_TOURNEE_TELEO_REQUEST };
  }
  function success(report: Object) {
    return { type: tourneeConstants.EXPORT_TOURNEE_TELEO_SUCCESS, report };
  }
  function failure(error: Object) {
    return { type: tourneeConstants.EXPORT_TOURNEE_TELEO_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    tourneeService
      .exportTourneeTeleo(tourneeCode)
      .then((result: string) => {
        dispatch(success(result));
        const report = result;
        if (gestionnaireId) {
          dispatch(synchroActions.getAllRoundFull(gestionnaireId));
        }
        document.body.click();
      })
      .catch((error: string) => {
        console.log(error);
        dispatch(failure(error));
      });
  };
}

function getStats(tourneeCode: string) {
  function request() {
    return { type: tourneeConstants.STATS_TOURNEE_REQUEST };
  }
  function success(stats: Object) {
    return { type: tourneeConstants.STATS_TOURNEE_SUCCESS, stats };
  }
  function failure(error: Object) {
    return { type: tourneeConstants.STATS_TOURNEE_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    tourneeService
      .getStats(tourneeCode)
      .then((stats: Object) => dispatch(success(stats)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}
function updateTourneeMsg(tourneeCode: string, tourneeMsg: string) {
  function request() {
    return { type: tourneeConstants.UPDATE_TOURNEEMSG_REQUEST };
  }
  function success(tourneeInfo: Object) {
    return { type: tourneeConstants.UPDATE_TOURNEEMSG_SUCCESS, tourneeInfo };
  }
  function failure(error: Object) {
    return { type: tourneeConstants.UPDATE_TOURNEEMSG_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    tourneeService
      .updateTourneeMsg(tourneeCode, tourneeMsg)
      .then((tourneeInfo: Object) => dispatch(success(tourneeInfo)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function getAllInfo() {
  function request() {
    return { type: tourneeConstants.ROUNDS_GETALL_INFO_REQUEST };
  }
  function success(allRoundsInfo: Object) {
    return { type: tourneeConstants.ROUNDS_GETALL_INFO_SUCCESS, allRoundsInfo };
  }
  function failure(error: Object) {
    return { type: tourneeConstants.ROUNDS_GETALL_INFO_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    tourneeService
      .getAllInfo()
      .then((allRoundsInfo: Object) => dispatch(success(allRoundsInfo)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function getAllTourneeInfos() {
  function request() {
    return { type: tourneeConstants.ROUNDS_GETALL_TOURNEE_INFO_REQUEST };
  }
  function success(allRoundsInfo: Object) {
    return {
      type: tourneeConstants.ROUNDS_GETALL_TOURNEE_INFO_SUCCESS,
      allRoundsInfo,
    };
  }
  function failure(error: Object) {
    return { type: tourneeConstants.ROUNDS_GETALL_TOURNEE_INFO_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    tourneeService
      .getAllTourneeInfos()
      .then((allRoundsInfo: Object) => dispatch(success(allRoundsInfo)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function getRotateOption() {
  function success(rotateOptions: Object) {
    return { type: tourneeConstants.ROTATE_OPTIONS_SUCCESS, rotateOptions };
  }
  return (dispatch: Function) => {
    tourneeService
      .getRotateOption()
      .then((rotateOptions: Object) => dispatch(success(rotateOptions)));
  };
}

function getEditInfos(tourneeCode: string) {
  function success(editInfos: Object) {
    return { type: tourneeConstants.ROUNDS_EDIT_INFO_SUCCESS, editInfos };
  }
  return (dispatch: Function) => {
    tourneeService
      .getEditInfos(tourneeCode)
      .then((editInfos: Object) => dispatch(success(editInfos)))
      .catch((error: Object) => {
        console.log("getEditInfos::tourneeaction::289", error);
      });
  };
}

function updateFieldFiche(
  tourneeCode: string,
  theoricalSerial: string,
  field: string,
  value: any
) {
  function request() {
    return {
      type: tourneeConstants.GETTOURNEEFICHE_REQUEST,
      tourneeCode,
      theoricalSerial,
      field,
      value,
    };
  }
  function success(fiche: any) {
    return { type: tourneeConstants.GETTOURNEEFICHE_SUCCESS, fiche };
  }
  return (dispatch: Function) => {
    dispatch(request());
    tourneeService
      .updateFieldFiche(tourneeCode, theoricalSerial, field, value)
      .then((fiche: any) => dispatch(success(fiche)))
      .catch((error: Object) => {
        console.log("getEditInfos::tourneeaction::289", error);
      });
  };
}

function deleteFieldFiche(
  tourneeCode: string,
  theoricalSerial: string,
  field: string,
  value: any,
  ficheId: any
) {
  function request() {
    return {
      type: tourneeConstants.DELETE_READ_REQUEST,
      tourneeCode,
      theoricalSerial,
      field,
      value,
    };
  }
  function success(fiche: any) {
    return { type: tourneeConstants.DELETE_READ_SUCCESS, fiche };
  }
  console.log("DELTE INDEX TOURNEE ACTIONS");
  return (dispatch: Function) => {
    dispatch(request());
    tourneeService
      .deleteFieldFiche(tourneeCode, theoricalSerial, field, value)
      .then((fiche: any) => {
        dispatch(success(fiche));
        dispatch(getFicheTournee(tourneeCode, ficheId));
      })
      .catch((error: Object) => {
        console.log("getEditInfos::tourneeaction::289", error);
      });
  };
}

function deleteMessage(tourneeCode: string, theoricalSerial: string) {
  function request() {
    return {
      type: tourneeConstants.DEL_MESSAGE_REQUEST,
      tourneeCode,
      theoricalSerial,
    };
  }
  function success(fiche: any) {
    return { type: tourneeConstants.GETTOURNEEFICHE_SUCCESS, fiche };
  }
  function failure(fiche: any) {
    return { type: tourneeConstants.DEL_MESSAGE_FAILURE, fiche };
  }
  return (dispatch: Function) => {
    dispatch(request());
    tourneeService
      .delMessage(tourneeCode, theoricalSerial)
      .then((fiche: any) => dispatch(success(fiche)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function deleteMarquage(tourneeCode: string, theoricalSerial: string) {
  function request() {
    return {
      type: tourneeConstants.DEL_MARQUAGE_REQUEST,
      tourneeCode,
      theoricalSerial,
    };
  }
  function success(fiche: any) {
    return { type: tourneeConstants.GETTOURNEEFICHE_SUCCESS, fiche };
  }
  function failure(fiche: any) {
    return { type: tourneeConstants.DEL_MARQUAGE_FAILURE, fiche };
  }
  return (dispatch: Function) => {
    dispatch(request());
    tourneeService
      .delMarquage(tourneeCode, theoricalSerial)
      .then((fiche: any) => dispatch(success(fiche)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function getMessagesMarquages() {
  function request() {
    return { type: tourneeConstants.GET_MESSAGES_MARQUAGES_REQUEST };
  }
  function success(options: any) {
    return { type: tourneeConstants.GET_MESSAGES_MARQUAGES_SUCCESS, options };
  }
  function failure(fiche: any) {
    return { type: tourneeConstants.GET_MESSAGES_MARQUAGES_FAILURE, fiche };
  }
  return (dispatch: Function) => {
    dispatch(request());
    tourneeService
      .getMessagesMarquages()
      .then((options: any) => dispatch(success(options)))
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function getOptionsExport() {
  function success(options: any) {
    return { type: tourneeConstants.GET_EXPORT_OPTIONS_SUCCESS, options };
  }
  return (dispatch: Function) => {
    tourneeService
      .getOptionsExport()
      .then((options: any) => dispatch(success(options)));
  };
}

function getOptionsExportFormats() {
  function success(formats: string[]) {
    return {
      type: tourneeConstants.GET_EXPORT_FORMATS_OPTIONS_SUCCESS,
      formats,
    };
  }
  return (dispatch: Function) => {
    tourneeService
      .getOptionsExportFormats()
      .then((formats: string[]) => dispatch(success(formats)));
  };
}

function getOptionsImportFormats() {
  function success(formats: string[]) {
    return {
      type: tourneeConstants.GET_IMPORT_FORMATS_OPTIONS_SUCCESS,
      formats,
    };
  }
  return (dispatch: Function) => {
    tourneeService
      .getOptionsImportFormats()
      .then((formats: string[]) => dispatch(success(formats)));
  };
}

function getImportOptions() {
  function success(importOption: { [option: string]: boolean }) {
    return {
      type: tourneeConstants.GET_IMPORT_FACTURATION_IS_ENABLED_SUCCESS,
      importOption,
    };
  }
  return (dispatch: Function) => {
    tourneeService
      .getImportOptions()
      .then((importOption: { [option: string]: boolean }) =>
        dispatch(success(importOption))
      );
  };
}

function clear() {
  return {
    type: tourneeConstants.CLEAR,
  };
}

function clearError() {
  return {
    type: tourneeConstants.CLEAR_ERROR,
  };
}

function clearTelereleveReads() {
  return {
    type: tourneeConstants.CLEAR_TELERELEVE_READS,
  };
}

const templateActions = {
  getTournee,
  createTournee,
  rotateFiches,
  clear,
  clearError,
  getFicheTournee,
  getAllFicheTournee,
  exportTournee,
  getStats,
  deleteTournee,
  updateTourneeMsg,
  getAllInfo,
  invalidCache,
  getTelereleveReads,
  fillTelereleveReads,
  clearTelereleveReads,
  getAllTourneeInfos,
  getRotateOption,
  getEditInfos,
  updateFieldFiche,
  getOptionsExport,
  getOptionsExportFormats,
  getOptionsImportFormats,
  exportTourneeCsv,
  exportTourneeTeleo,
  launchIntervalFillTelereleveReads,
  launchIntervalTelereleveReads,
  deleteMessage,
  deleteMarquage,
  getLocationIdWithCode,
  loadSilexToFactu,
  getFacturationName,
  getMessagesMarquages,
  getImportOptions,
  deleteFieldFiche,
  isFacturationInterventionEnabled,
};

export default templateActions;
