import React, { Component, Fragment, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import "./style.scss";
import moment from "moment-timezone";
import {
  Button,
  CustomInput,
  Progress,
  Spinner,
  UncontrolledTooltip,
} from "reactstrap";
import { textFilter } from "react-bootstrap-table2-filter";
import { withTranslation } from "react-i18next";
import {
  CheckBoxOutlineBlankOutlined,
  CheckBoxRounded,
  KeyboardArrowDownRounded,
  KeyboardArrowUpRounded,
} from "@material-ui/icons";
import { Checkbox } from "@material-ui/core";
import GestionTable from "../_components/TableauGestion";
import TourneesVertes from "../SvgComponents/TourneesVertes";
import synchroAction from "../_actions/synchro.actions";
import TableRow from "../_components/TableRow";
import { tourneeActions } from "../_actions";
import SvgExportBleu from "../SvgComponents/ExportBleu";
import SvgPoubelleBleu from "../SvgComponents/PoubelleBleu";
import SvgTourneeExportee from "../SvgComponents/TourneeExportee";
import SvgTourneeLoad from "../SvgComponents/TourneeLoad";
import SvgTourneeUnload from "../SvgComponents/TourneeUnload";
import SvgTourneeWaitAffect from "../SvgComponents/TourneeWaitAffect";
import SvgTourneeWaitDeaffect from "../SvgComponents/TourneeWaitDeaffect";
import SvgEye from "../SvgComponents/Eye";
import Loading from "../_animations/Loading";
import { roundDeletionReport } from "../_interfaces/reducers";
import StepPopover from "../_components/StepPopover";
import ErrorBand from "../Bands/Error";
import ExportLegacyPopup from "./ExportLegacyPopup";
import Chip from "../_components/Chip";

export interface Tournee {
  id: string;
  rndCode: string;
  content: string;
  affectList: string[];
  totalMeter: number;
  indexedMeter: number;
  modifiedMeter: number;
  used: boolean;
  exported: any;
  waitAffect: boolean;
  waitDeaffect: boolean;
  lastEditDate?: Date;
  reportStatus?: {
    code: String;
    status: String | null;
    error: Map<String, String>;
    attemptTime: Date | null;
  };
  deletionReport?: any;
}

class TourneeResources extends Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      selectCheckbox: [],
      multiSelection: false,
      loadingRounds: [],
      selectAll: false,
      isDeletionPopupOpen: false,
      isFactuExportFiltered: false,
      undeletableRoundsReport: [],
      filter: {},
    };

    this.toggleMultiSelection = this.toggleMultiSelection.bind(this);
    this.exportTournee = this.exportTournee.bind(this);
    this.deleteTournee = this.deleteTournee.bind(this);
    this.exportFactu = this.exportFactu.bind(this);
    this.exportTourneeCsv = this.exportTourneeCsv.bind(this);
    this.exportTourneeTeleo = this.exportTourneeTeleo.bind(this);
    this.filterDevices = this.filterDevices.bind(this);
    this.PopoverChild = this.PopoverChild.bind(this);
  }

  componentDidMount() {
    const { dispatch, match } = this.props;
    const { params } = match;
    dispatch(synchroAction.getAllRoundFull(params.GestionnaireId));
    dispatch(synchroAction.getAllDevice(params.GestionnaireId));
    dispatch(tourneeActions.getOptionsExport());
    dispatch(tourneeActions.getFacturationName());

    const mountFastFilters = localStorage.getItem(
      "tourneeRessourcesFilter-fast"
    );
    if (mountFastFilters) {
      this.setState({ filter: JSON.parse(mountFastFilters) });
    }
  }

  componentDidUpdate(prevProps: Readonly<{}>, prevState: any, snapshot?: any) {
    const { filter } = this.state;
    if (
      filter.current !== prevState.filter.current ||
      filter[filter.current] !== prevState.filter[prevState.filter.current]
    ) {
      localStorage.setItem(
        "tourneeRessourcesFilter-fast",
        JSON.stringify(filter)
      );
    }
  }

  toggleMultiSelection() {
    const { dispatch } = this.props;
    dispatch(tourneeActions.clear());
    this.setState({
      multiSelection: !this.state.multiSelection,
      selectCheckbox: [],
      undeletableRoundsReport: [],
    });
  }

  canAction(row: any) {
    return (
      !row.waitAffect &&
      (!row.affectList || row.affectList.length === 0) &&
      !row.waitDeaffect
    );
  }

  canDelete(row: any) {
    return (
      (!row.affectList || row.affectList.length === 0) &&
      !row.waitDeaffect &&
      !row.waitAffect &&
      ((row.exported != null &&
        moment(row.exported).isAfter(moment(row.lastEditDate))) ||
        row.lastEditDate == null)
    );
  }

  exportTournee(e: any, row: any) {
    const { selectCheckbox } = this.state;
    const { dispatch, match } = this.props;
    const { params } = match;
    this.setState({ loadingRounds: [], undeletableRoundsReport: [] });
    if (row) {
      this.setState({ selectCheckbox: [row.id] });
    }
    dispatch(tourneeActions.clear());
    e.stopPropagation();
    dispatch(
      tourneeActions.exportTournee(
        this.state.multiSelection ? selectCheckbox : [row.rndCode],
        params.GestionnaireId
      )
    );
    if (row) {
      this.setState({ loadingRounds: [row.id] });
      const root = document.getElementById("root");
      root && root.click();
    } else {
      this.setState({ loadingRounds: selectCheckbox });
    }
    this.setState({ selectCheckbox: [] });
  }

  exportTourneeCsv(e: any, row: any, format: string) {
    const { selectCheckbox } = this.state;
    const { dispatch, match } = this.props;
    const { params } = match;
    dispatch(tourneeActions.clear());
    e.stopPropagation();
    dispatch(
      tourneeActions.exportTourneeCsv(
        this.state.multiSelection ? selectCheckbox : [row.rndCode],
        format,
        params.GestionnaireId
      )
    );
    if (row) {
      this.setState({ loadingRounds: [row.id] });
      const root = document.getElementById("root");
      root && root.click();
    } else {
      this.setState({ loadingRounds: selectCheckbox });
    }
    this.setState({ selectCheckbox: [] });
  }

  exportFactu(e: any, row: any, withFilter: boolean) {
    const { selectCheckbox } = this.state;
    const { dispatch } = this.props;
    e.preventDefault();
    dispatch(tourneeActions.clear());
    dispatch(
      tourneeActions.loadSilexToFactu(
        this.state.multiSelection ? selectCheckbox : [row.rndCode],
        withFilter
      )
    );
    if (row) {
      this.setState({ loadingRounds: [row.id] });
      const root = document.getElementById("root");
      root && root.click();
    } else {
      this.setState({ loadingRounds: selectCheckbox });
    }
    this.setState({ selectCheckbox: [] });
  }

  exportTourneeTeleo(e: any, row: any) {
    const { selectCheckbox, undeletableRoundsReport } = this.state;
    const { dispatch, match } = this.props;
    const { params } = match;
    dispatch(tourneeActions.clear());
    e.stopPropagation();
    dispatch(
      tourneeActions.exportTourneeTeleo(
        this.state.multiSelection ? selectCheckbox : [row.rndCode],
        params.GestionnaireId
      )
    );
    if (row) {
      this.setState({ loadingRounds: [row.id] });
      const root = document.getElementById("root");
      root && root.click();
    } else {
      this.setState({ loadingRounds: selectCheckbox });
    }
    this.setState({ selectCheckbox: [] });
  }

  async deleteTournee(e: any, row: any) {
    const { dispatch, match, synchro, t } = this.props;
    const { selectCheckbox, undeletableRoundsReport } = this.state;
    const { params } = match;
    const newTasks =
      synchro && synchro.tasks && _.entries(synchro.tasks).map((el) => el[1]);

    const roundCodeToDeleted = newTasks
      .filter((task: any) => selectCheckbox.includes(task.id))
      .filter((el) => this.canDelete(el) && this.canAction(el))
      .map((el: any) => el.rndCode);

    const unDeletableRounds = newTasks
      .filter((task: any) => selectCheckbox.includes(task.id))
      .filter((el) => !this.canDelete(el))
      .map((el: any) => el.rndCode);

    const deletableRounds = newTasks
      .filter((task: any) => selectCheckbox.includes(task.id))
      .filter((el) => this.canDelete(el))
      .map((el: any) => el.rndCode);

    const undeletableRoundReport: Array<roundDeletionReport> = newTasks
      .filter((task: any) => selectCheckbox.includes(task.id))
      .filter((el) => !this.canDelete(el))
      .map((tournee: any) => {
        const report: roundDeletionReport = {
          tourneeCode: tournee.rndCode,
          didSucceed: false,
          attemptTime: new Date().getTime(),
          error: `La tournée ${tournee.rndCode} ne peut pas être supprimée pour le moment`,
        };
        return report;
      });
    this.setState({ undeletableRoundsReport: [] });
    if (this.state.multiSelection || row.affectList || row.exported) {
      if (
        !this.state.multiSelection ||
        (this.state.multiSelection && deletableRounds.length > 0)
      ) {
        this.setState({ loadingRounds: row ? [row.rndCode] : deletableRounds });
        dispatch(tourneeActions.clear());
        dispatch(
          tourneeActions.deleteTournee(
            this.state.multiSelection ? deletableRounds : [row.rndCode],
            params.GestionnaireId
          )
        );
        this.setState({
          selectCheckbox: [],
          undeletableRoundsReport: undeletableRoundReport,
        });
      }
    }
  }

  filterDevices(filterVal, data) {
    const {
      synchro: { devices },
    } = this.props;
    const { filter } = this.state;
    const listSerialMatch = _.values(devices)
      .filter(
        (el) =>
          el.serial.toLowerCase().includes(filterVal.toLowerCase()) ||
          el.content.toLowerCase().includes(filterVal.toLowerCase())
      )
      .map((el) => el.serial.toLowerCase());
    const cloneFilter = _.cloneDeep(filter);
    cloneFilter.affectList = filterVal;
    cloneFilter.current = "affectList";
    if (cloneFilter.affectList !== filter.affectList) {
      this.setState({ filter: cloneFilter });
    }
    return filterVal.length > 0
      ? data.filter((round) =>
          _.some(round.affectList, (portable) =>
            listSerialMatch.includes(portable.toLowerCase())
          )
        )
      : data;
  }

  PopoverChild = (props) => {
    const { t, tournee } = this.props;
    const { row } = props;
    return (
      _.get(tournee, "options.listExportRound") && (
        <div style={{ display: "grid" }}>
          <p style={{ textAlign: "center" }}>
            {t("tournee.text.export_round_csv_custom_choices")}
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              color="info"
              onClick={(e) => this.exportTournee(e, row)}
              style={{ margin: 0 }}
            >
              {t("tournee.text.export_dat")}
            </Button>
            {_.sortBy(
              JSON.parse(_.get(tournee, "options.listExportRound"))
            ).map((el) => (
              <Button
                color="info"
                onClick={(e) => this.exportTourneeCsv(e, row, el)}
                style={{ margin: 0, marginTop: "10px" }}
              >
                {el}
              </Button>
            ))}
          </div>
        </div>
      )
    );
  };

  getDeletionPermissionMessage = (row) => {
    const { t } = this.props;
    switch (true) {
      case row.waitAffect:
        return `${t("all.text.not_possible_deletion")} : ${t(
          "tournee_ressources.round_pending_affectation"
        )}`;
      case row.waitDeaffect:
        return `${t("all.text.not_possible_deletion")} : ${t(
          "tournee_ressources.round_pending_deaffectation"
        )}`;
      case row.affectList.length !== 0:
        return `${t("all.text.not_possible_deletion")} : ${t(
          "tournee_ressources.round_used_by_devices"
        )}`;
      case moment(row.exported).isBefore(moment(row.lastEditDate)) ||
        (row.exported === null && row.lastEditDate !== null):
        return `${t("all.text.not_possible_deletion")} : ${t(
          "tournee_ressources.round_modif_without_export"
        )}`;
      case row.canDelete && !row.deletionReport:
        return t("tournee_ressources.delete_round");
      default:
        return t("all.text.not_possible_deletion");
    }
  };

  UnitDeletionPopupTrigger = (props) => {
    const { t, synchro } = this.props;
    const { row } = props;
    const [open, setOpen] = useState(false);
    const newTasks =
      synchro &&
      synchro.tourneeRessources &&
      _.entries(synchro.tourneeRessources).map((el) => el[1]);
    const rowIDs = newTasks.map((el: any) => el.rndCode);
    return (
      <span
        id={`deleteTournee${row.id}`}
        className={`${row.canDelete && row.canExport ? "clickable" : ""} round`}
        role="presentation"
        style={{ marginLeft: "10px" }}
        onClick={(e) => {
          e.stopPropagation();
          rowIDs.forEach((it) => {
            if (document.getElementById(`deleteTourneePopover${it}`)) {
              if (it === row.id) {
                document.getElementById(
                  `deleteTourneePopover${it}`
                ).parentElement.style.display = "block";
              } else {
                document.getElementById(
                  `deleteTourneePopover${it}`
                ).parentElement.style.display = "none";
              }
            }
          });
          if (row.canDelete) {
            setOpen(true);
          }
        }}
      >
        <SvgPoubelleBleu
          height="1em"
          width="1em"
          fill={!row.canDelete ? "lightgray" : "#2c303b"}
        />
        <StepPopover
          id={`deleteTourneePopover${row.id}`}
          target={`deleteTournee${row.id}`}
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          permission
          action={(e) => {
            if (row.canDelete) this.deleteTournee(e, row);
            setOpen(false);
          }}
          title={t("tournee_ressources.deleting_round")}
          confirmText={t("tournee_ressources.popup_title.ask_delete_x_round", {
            count: 0,
            roundName: row.content,
          })}
          confirmElement={
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <b
                style={{
                  color: "red",
                  width: "fit-content",
                  margin: "0 auto",
                  marginBottom: 10,
                }}
              >
                {t("all.text.irreversible_action_warning")}
              </b>
            </div>
          }
        />

        <UncontrolledTooltip
          placement="bottom"
          target={`deleteTournee${row.id}`}
        >
          {this.getDeletionPermissionMessage(row)}
        </UncontrolledTooltip>
      </span>
    );
  };

  reportStatusChip(status: string | boolean) {
    switch (status) {
      case "SUCCESS":
        return {
          backgroundColor: "#26A653",
          border: "#137736",
          text: "Exportée",
        };
      case true:
        return {
          backgroundColor: "#26A653",
          border: "#137736",
          text: "Supprimée",
        };
      case "FAILURE":
        return {
          backgroundColor: "#dc3545",
          border: "#dc3545",
          text: "Echec",
        };
      case false:
        return {
          backgroundColor: "#dc3545",
          border: "#dc3545",
          text: "Echec",
        };
      case "NOTFOUND":
        return {
          backgroundColor: "#a3a2a2",
          border: "rgb(106 106 106)",
          text: "Introuvable",
        };

      case "FORBIDDEN":
        return {
          backgroundColor: "#FFBA00",
          border: "#b98700",
          text: "Interdit",
        };
      case "UNAUTHORIZED":
        return {
          backgroundColor: "#F28B0C",
          border: "#F28B0C",
          text: "Non-autorisé",
        };
      default:
        return {};
    }
  }

  render() {
    const { synchro, history, t, tournee, dispatch } = this.props;
    const {
      multiSelection,
      selectCheckbox,
      selectAll,
      loadingRounds,
      undeletableRoundsReport,
      isFactuExportFiltered,
      filter,
    } = this.state;
    const svg = (
      <TourneesVertes
        height="1em"
        width="1em"
        fill="#31c6b3"
        className="svg-table-title"
      />
    );
    const newTasks =
      synchro &&
      synchro.tourneeRessources &&
      _.entries(synchro.tourneeRessources).map((el) => el[1]);

    // Integrating can action boolean directly into data
    if (newTasks) {
      newTasks.forEach((el: any) => {
        el.canExport = this.canAction(el);
        el.canDelete = this.canDelete(el);
      });
    }

    // Integrating export report directly into data at component re-rendering
    if (tournee.report && newTasks) {
      newTasks.forEach(
        (round: any) =>
          (round.reportStatus = tournee.report.find(
            (rapport) => rapport.code === round.id
          ))
      );
    }
    if (tournee.tourneeDeletionReport && newTasks) {
      newTasks.forEach(
        (round: any) =>
          (round.deletionReport = tournee.tourneeDeletionReport.find(
            (deletionReport: roundDeletionReport) =>
              deletionReport.tourneeCode === round.id
          ))
      );
    } else if (newTasks) {
      newTasks.forEach((round: any) => {
        round.deletionReport = undeletableRoundsReport.find(
          (deletionReport: roundDeletionReport) =>
            deletionReport.tourneeCode === round.id
        );
      });
    }
    const extraColumns: {
      dataField: string;
      text?: string;
      rowClasses?: (cellContent: any, row: any) => string;
      align: string;
      style: { padding: number; verticalAlign: string; background?: string };
      headerStyle:
        | ((colum: any, colIndex: any) => { width: string })
        | (() => { width: string });
      headerFormatter?: (column: any, colIndex: any) => JSX.Element;
      formatter:
        | ((cellContent: any, row: any) => JSX.Element)
        | ((cellContent: any, row: Tournee) => JSX.Element);
    }[] = [];
    if (!multiSelection) {
      const listExportRound =
        _.get(tournee, "options.listExportRound") &&
        JSON.parse(_.get(tournee, "options.listExportRound"));
      extraColumns.push({
        align: "center",
        style: {
          padding: 0,
          verticalAlign: "middle",
        },
        dataField: "exportation",
        headerStyle: () => ({ width: "10%" }),
        formatter: (cellContent: any, row: Tournee) => {
          return (
            <div>
              <span
                id={`showTournee${row.id}`}
                className="clickable round"
                role="presentation"
                onClick={() => {
                  history.push(`tournees/${row.rndCode}`);
                }}
              >
                <SvgEye height="1em" width="1em" />
                <UncontrolledTooltip
                  placement="bottom"
                  target={`showTournee${row.id}`}
                >
                  {" "}
                  {t("tournee_ressources.tooltip.access_round_detail")}
                </UncontrolledTooltip>
              </span>
              <span
                id={`exportTournee${row.id}`}
                className="clickable round"
                role="presentation"
                style={{ marginLeft: "10px", position: "relative" }}
                onClick={(e) => {
                  e.stopPropagation();
                  (_.get(tournee, "options.export_round_csv_custom") !==
                    "true" ||
                    !(
                      Array.isArray(listExportRound) && listExportRound.length
                    )) &&
                    _.get(tournee, "options.export_round_teleo") !== "true" &&
                    _.get(tournee, "options.export_round_factu") !== "true" &&
                    this.exportTournee(e, row);
                }}
              >
                <SvgExportBleu height="1em" width="1em" />
                {(((_.get(tournee, "options.export_round_csv_custom") ===
                  "true" ||
                  _.get(tournee, "options.export_round_csv_custom") === true) &&
                  Array.isArray(listExportRound) &&
                  listExportRound.length) ||
                  _.get(tournee, "options.export_round_teleo") === "true" ||
                  _.get(tournee, "options.export_round_teleo") === true ||
                  _.get(tournee, "options.export_round_factu") === "true" ||
                  _.get(tournee, "options.export_round_factu") === true) && (
                  <ExportLegacyPopup
                    target={`exportTournee${row.id}`}
                    row={row}
                    listExportRound={listExportRound}
                    exportTournee={this.exportTournee}
                    exportTourneeCsv={this.exportTourneeCsv}
                    exportTourneeTeleo={this.exportTourneeTeleo}
                    exportFactu={this.exportFactu}
                  />
                )}
                <UncontrolledTooltip
                  placement="bottom"
                  target={`exportTournee${row.id}`}
                >
                  {" "}
                  {t("tournee_ressources.tooltip.export_round")}
                </UncontrolledTooltip>
              </span>
              <Fragment>
                <this.UnitDeletionPopupTrigger row={row} />
              </Fragment>
            </div>
          );
        },
      });
    }

    const handleFilter = (field, value) => {
      const cloneFilter = _.cloneDeep(filter);
      cloneFilter[field] = value;
      cloneFilter.current = field;
      if (cloneFilter[field] !== filter[field]) {
        this.setState({ filter: cloneFilter });
      }
    };
    const columns = [
      {
        dataField: "exported",
        text: t("all.column_text.state"),
        formatter: (cellContent: any, row: Tournee) => (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span id={`unloadIcon${row.id}`}>
              <SvgTourneeUnload
                fill={_.isEmpty(row.affectList) ? "#31c6b3" : "lightgray"}
                width="2em"
                height="2em"
              />
              <UncontrolledTooltip
                placement="bottom"
                target={`unloadIcon${row.id}`}
              >
                {t("tournee_ressources.column_exported_tooltip.unload_round")}
              </UncontrolledTooltip>
            </span>
            <span id={`affectIcon${row.id}`}>
              <SvgTourneeWaitAffect
                fill={row.waitAffect ? "#31c6b3" : "lightgray"}
                width="2em"
                height="2em"
              />
              <UncontrolledTooltip
                placement="bottom"
                target={`affectIcon${row.id}`}
              >
                {t("tournee_ressources.column_exported_tooltip.waiting_load")}
              </UncontrolledTooltip>
            </span>
            <span id={`loadIcon${row.id}`}>
              <SvgTourneeLoad
                fill={!_.isEmpty(row.affectList) ? "#31c6b3" : "lightgray"}
                width="2em"
                height="2em"
              />
              <UncontrolledTooltip
                placement="bottom"
                target={`loadIcon${row.id}`}
              >
                {t("tournee_ressources.column_exported_tooltip.load_round")}
              </UncontrolledTooltip>
            </span>
            <span id={`unaffectedIcon${row.id}`}>
              <SvgTourneeWaitDeaffect
                fill={row.waitDeaffect ? "#31c6b3" : "lightgray"}
                width="2em"
                height="2em"
              />
              <UncontrolledTooltip
                placement="bottom"
                target={`unaffectedIcon${row.id}`}
              >
                {t("tournee_ressources.column_exported_tooltip.waiting_unload")}
              </UncontrolledTooltip>
            </span>
            <span id={`exportedIcon${row.id}`}>
              <SvgTourneeExportee
                fill={row.exported ? "#31c6b3" : "lightgray"}
                width="2em"
                height="2em"
              />
              <UncontrolledTooltip
                placement="bottom"
                target={`exportedIcon${row.id}`}
              >
                {row.exported
                  ? t(
                      "tournee_ressources.column_exported_tooltip.exported_x_date",
                      {
                        date: moment(row.exported)
                          .tz(moment.tz.guess())
                          .format("DD/MM/YYYY HH:mm"),
                      }
                    )
                  : t(
                      "tournee_ressources.column_exported_tooltip.never_exported"
                    )}
              </UncontrolledTooltip>
            </span>
          </div>
        ),
        headerStyle: () => ({
          textAlign: "center",
          width: "18%",
          verticalAlign: "top",
        }),
      },
      {
        dataField: "content",
        text: t("all.column_text.name"),
        headerStyle: () => ({ width: "30ch" }),
        filter: textFilter({
          placeholder: t("all.column_text.name"),
          onFilter: (v) => handleFilter("content", v),
          defaultValue: filter.content,
        }),
        sort: true,
      },
      {
        dataField: "rndCode",
        text: t("tournee_ressources.column_text.round_code"),
        headerStyle: () => ({ width: "15ch" }),
        filter: textFilter({
          placeholder: t("tournee_ressources.column_text.round_code"),
          onFilter: (v) => handleFilter("rndCode", v),
          defaultValue: filter.rndCode,
        }),
        sort: true,
      },
      {
        dataField: "totalMeter",
        text: t("all.meter.meter_plural"),
        headerStyle: () => ({ width: "12ch", verticalAlign: "top" }),
      },
      {
        dataField: "nbRadios",
        text: t("all.radio.radio_plural"),
        headerStyle: () => ({ width: "12ch", verticalAlign: "top" }),
      },
      {
        dataField: "modifiedMeter",
        text: t("tournee_ressources.column.avancement"),
        sort: true,
        sortFunc: (a, b, order, dataField, rowA, rowB) => {
          const valA = _.floor((rowA.modifiedMeter / rowA.totalMeter) * 100);
          const valB = _.floor((rowB.modifiedMeter / rowB.totalMeter) * 100);
          if (order === "asc") {
            return valB - valA;
          }
          return valA - valB; // desc
        },
        formatter: (cellContent, row) => {
          return (
            <Progress
              style={{ height: "2em" }}
              value={_.floor((row.modifiedMeter / row.totalMeter) * 100)}
            >
              <span
                style={{ color: "black", fontWeight: "bold", fontSize: "12px" }}
              >{`${_.floor(
                (row.modifiedMeter / row.totalMeter) * 100
              )} %`}</span>
            </Progress>
          );
        },
        headerStyle: () => ({ width: "12ch", verticalAlign: "top" }),
      },
      {
        dataField: "affectList",
        isDummyField: true,
        editable: false,
        align: "center",
        text: t("tournee_ressources.column_text.affected_phone"),
        formatter: (cellContent: any, row: any) => {
          return <TableRow row={row} />;
        },
        headerStyle: () => ({ textAlign: "center", verticalAlign: "top" }),
        filter: textFilter({
          placeholder: t("tournee_ressources.column_text.affected_phone"),
          onFilter: this.filterDevices,
          defaultValue: filter.affectList,
        }),
      },
      ...extraColumns,
    ];
    if (tournee.report) {
      columns.push({
        dataField: "exportResult",
        text: "",
        align: "center",
        style: {
          padding: 0,
          verticalAlign: "middle",
        },
        headerStyle: (colum, colIndex) => {
          return { width: "9%" };
        },
        formatter: (cellContent, row) => {
          const status = this.reportStatusChip(
            _.get(row, "reportStatus.status")
          );
          return (
            this.state.loadingRounds.find((el) => el == row.id) && (
              <div
                style={{
                  width: "min-content",
                  padding: "0 10px",
                  background: "white",
                  borderRadius: 100,
                  color: status.backgroundColor,
                  margin: "auto",
                }}
              >
                {row.reportStatus &&
                  t(`tournee.text.${row.reportStatus.status.toLowerCase()}`)}
              </div>
            )
          );
        },
      });
    }
    if (
      (tournee.tourneeDeletionReport &&
        !tournee.tourneeDeletionReport.filter((report) => !report.didSucceed)
          .isEmpty) ||
      undeletableRoundsReport.size > 0
    ) {
      columns.push({
        dataField: "exportResult",
        text: "",
        align: "center",
        style: {
          padding: 0,
          verticalAlign: "middle",
        },
        headerStyle: (colum, colIndex) => {
          return { width: "9%" };
        },
        formatter: (cellContent, row) => {
          const status = this.reportStatusChip(
            _.get(row, "deletionReport.didSucceed")
          );
          return row.deletionReport ? (
            <div
              style={{
                width: "min-content",
                padding: "0 10px",
                background: "white",
                borderRadius: 100,
                color: status.backgroundColor,
                margin: "auto",
              }}
            >
              {row.deletionReport.didSucceed
                ? "Supprimée"
                : t(`tournee.text.failure`)}
            </div>
          ) : (
            <div />
          );
        },
      });
    }
    // Pushing loader in each table line
    if (tournee.exportProcessing || tournee.deletionProcessing) {
      columns.push({
        dataField: "exportProgress",
        text: "",
        align: "center",
        style: {
          padding: 0,
          verticalAlign: "middle",
          background: "rgba(255,255,255,0)",
        },
        headerStyle: (colum, colIndex) => {
          return { width: "80px" };
        },
        formatter: (cellContent, row) => {
          return (
            this.state.loadingRounds.find((el: number) => el === row.id) && (
              <Spinner
                animation="grow"
                role="status"
                aria-hidden="true"
                color={tournee.exportProcessing ? "info" : "danger"}
                size="sm"
              />
            )
          );
        },
      });
    }
    const getExpandedRows = (): string[] => {
      switch (true) {
        case Array.isArray(tournee.report):
          return tournee.report.map((rapport) => rapport.code);
        case Array.isArray(tournee.tourneeDeletionReport):
          return tournee.tourneeDeletionReport
            .filter((rapport) => !rapport.didSucceed)
            .map((rapport) => rapport.tourneeCode);
        case undeletableRoundsReport.length > 0 && !tournee.deletionProcessing:
          return undeletableRoundsReport.map((el) => el.tourneeCode);
        default:
          return [];
      }
    };
    const expandedErrorRow = {
      renderer: (row) => {
        const status = this.reportStatusChip(
          row.deletionReport
            ? row.deletionReport.didSucceed
            : row.reportStatus && row.reportStatus.status
        );

        if (row.reportStatus !== undefined) {
          if (
            row.reportStatus.status === "FAILURE" &&
            row.reportStatus.status !== undefined
          ) {
            const foundErrorsMap: Map<string, any> = new Map();
            const errorObject = row.reportStatus.error;
            if (errorObject && errorObject.hasOwnProperty("errorFound")) {
              const errorsfound = JSON.parse(errorObject.errorFound);
              errorsfound.forEach((error: string) => {
                if (_.get(errorObject, error))
                  foundErrorsMap.set(
                    error,
                    JSON.parse(_.get(errorObject, error))
                  );
              });
            }
            return (
              <div
                style={{
                  borderRight: `4px solid ${status.border}`,
                  paddingRight: 20,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "end",
                }}
              >
                {false && (
                  <p>
                    {t("tournee.text.export.error_detected", {
                      tournee: row.content,
                    })}
                  </p>
                )}
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {row.reportStatus && row.reportStatus.attemptTime && (
                    <p className="error-chip">
                      {t("tournee .text.error.last_try", {
                        time: moment(row.reportStatus.attemptTime).format(
                          " HH:mm:ss"
                        ),
                      })}
                    </p>
                  )}
                  {errorObject && errorObject[0].type && (
                    <p className="main-error-chip">
                      {t(`tournee.text.error.${errorObject[0].type}`)}
                    </p>
                  )}
                </div>
              </div>
            );
          }
          if (row.reportStatus.status === "SUCCESS") {
            return (
              <div
                style={{
                  borderRight: `4px solid green`,
                  paddingRight: 20,
                  alignItems: "end",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <p>
                  {t("tournee.text.export.success", { tournee: row.content })}
                </p>
              </div>
            );
          }
        }
        if (
          tournee.tourneeDeletionReport !== undefined &&
          !row.deletionReport.didSucceed
        ) {
          let errorObject;
          try {
            errorObject = JSON.parse(row.deletionReport.error).errorValue;
          } catch (e) {
            errorObject = null;
          }
          return (
            <div
              style={{
                borderRight: `4px solid ${status.border}`,
                paddingRight: 20,
                display: "flex",
                flexDirection: "column",
                alignItems: "end",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                }}
              >
                {row.deletionReport.attemptTime && (
                  <p className="error-chip">
                    {t("tournee .text.error.last_try", {
                      time: moment(row.deletionReport.attemptTime).format(
                        " HH:mm:ss"
                      ),
                    })}
                  </p>
                )}
                <p className="error-chip">
                  {this.getDeletionPermissionMessage(row)}
                </p>
                {errorObject && errorObject[0].type && (
                  <p className="main-error-chip">
                    {t(`tournee.text.error.${errorObject[0].type}`)}
                  </p>
                )}
              </div>
            </div>
          );
        }
      },
      onlyOneExpanding: true,
      showExpandColumn: false,
      expandByColumnOnly: true,
      expandHeaderColumnRenderer: ({ isAnyExpands }) => {
        return <></>;
      },
      expandColumnRenderer: ({ expanded }) => {
        if (expanded) {
          return <KeyboardArrowUpRounded />;
        }
        return <KeyboardArrowDownRounded />;
      },
      expanded: getExpandedRows(),
    };

    // Style for row in export result
    const rowStyle = (row, rowIndex) => {
      const style: any = {};
      if (tournee.report && row.reportStatus && row.reportStatus.status) {
        const status = this.reportStatusChip(row.reportStatus.status);
        style.background = `linear-gradient(90deg, rgba(2,0,36,0) 30%, ${status.backgroundColor} 100%)`;
      }
      if (
        !tournee.deletionProcessing &&
        row.deletionReport &&
        !row.deletionReport.didSucceed
      ) {
        const status = this.reportStatusChip(row.deletionReport.didSucceed);
        style.background = `linear-gradient(90deg, rgba(2,0,36,0) 30%, ${status.backgroundColor} 100%)`;
      }

      if (row.reportStatus === undefined && row.deletionReport === undefined) {
        style.background = "none";
      }

      return style;
    };
    // Replacement for previous selection checkbox
    const selectRow = {
      mode: "checkbox",
      selectColumnPosition: "right",
      hideSelectColumn:
        !multiSelection ||
        tournee.exportProcessing ||
        tournee.deletionProcessing,
      onSelect: (row, isSelect, rowIndex, e) => {
        e.preventDefault();
        if (isSelect)
          this.setState({ selectCheckbox: [...selectCheckbox, row.id] });
        else
          this.setState({
            selectCheckbox: selectCheckbox.filter((c) => c !== row.id),
          });
      },
      onSelectAll: (isSelect, rows) => {
        if (isSelect) {
          this.setState({ selectCheckbox: rows.map((r) => r.id) });
          return rows.map((r) => r.id);
        }
        this.setState({ selectCheckbox: [] });
        return [];
      },
      selectionRenderer: ({ mode, checked, indeterminate }) => {
        return (
          <CustomInput
            id={`selection-checkbox-${mode}`}
            className="selection-checkbox"
            type={mode}
            checked={checked}
            onClick={(e) => {
              e.stopPropagation();
            }}
            icon={<CheckBoxOutlineBlankOutlined fontSize="small" />}
            checkedIcon={
              <CheckBoxRounded fontSize="small" style={{ color: "#31c6b3" }} />
            }
            color="primary"
          />
        );
      },
      headerColumnStyle: { width: "60px" },
      clickToSelect: true,
      selectColumnStyle: { width: "60px", padding: "15px 0" },
      selectionHeaderRenderer: ({ mode, checked, indeterminate }) => {
        return (
          <Checkbox
            size="small"
            icon={<CheckBoxOutlineBlankOutlined fontSize="small" />}
            checkedIcon={
              <CheckBoxRounded fontSize="small" style={{ color: "#31c6b3" }} />
            }
            color="primary"
            indeterminate={this.state.selectCheckbox.length > 0 && !checked}
            checked={checked}
          />
        );
      },
      selected: this.state.selectCheckbox,
      nonSelectable:
        newTasks &&
        newTasks.filter((el: any) => !el.canExport).map((el: any) => el.id),
      nonSelectableClasses: "row-disabled-export",
    };
    if (
      !tournee.deletionProcessing &&
      tournee.tourneeDeletionReport &&
      !multiSelection &&
      tournee.tourneeDeletionReport.filter((report) => !report.didSucceed)
        .length === 0
    ) {
      columns.pop();
    }

    const deleteSuccess = tournee?.tourneeDeletionReport
      ?.filter((el) => el.didSucceed)
      .map((el) => el.tourneeCode);

    return (
      <div
        id="roundGestionTableContainer"
        style={{ paddingLeft: "20px", paddingRight: "20px" }}
      >
        {tournee.errorInfo && (
          <ErrorBand
            message={
              tournee.errorInfo.translationId
                ? t(`tournee.text.${tournee.errorInfo.translationId}`)
                : tournee.errorInfo
            }
          />
        )}
        {(!synchro.roundsUp || !synchro.devicesUp) && (
          <Loading message="Chargement des tournées ..." />
        )}
        {deleteSuccess?.length > 0 && (
          <Chip
            message={
              deleteSuccess.length > 5
                ? t("tournee.text.delete_success_plural_no_details")
                : deleteSuccess.length > 1
                ? t("tournee.text.delete_success_plural", {
                    tournee: deleteSuccess.join(", "),
                  })
                : t("tournee.text.delete_success", {
                    tournee: deleteSuccess[0],
                  })
            }
          />
        )}
        {newTasks && synchro.roundsUp && synchro.devicesUp && (
          <GestionTable
            deleteTournee={this.deleteTournee}
            exportTournee={this.exportTournee}
            exportTourneeCsv={this.exportTourneeCsv}
            exportFactu={this.exportFactu}
            exportTourneeTeleo={this.exportTourneeTeleo}
            multiSelection={this.state.multiSelection}
            handleMultiSelection={this.toggleMultiSelection}
            selectedRows={selectCheckbox}
            columns={columns}
            canAction={this.canAction}
            data={newTasks}
            svgTitle={svg}
            titleTab={t("tournee_ressources.title_tab.rounds")}
            link=""
            unlink=""
            withAdd
            withRedirection="silex"
            expandRow={expandedErrorRow}
            customRowStyle={rowStyle}
            selectRow={selectRow}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { users, authentication, alert, synchro, tournee } = state;
  const { user } = authentication;
  return {
    user,
    users,
    alert,
    synchro,
    tournee,
  };
}

const mapping: any = connect(mapStateToProps)(TourneeResources);

const connectedUser = withRouter(mapping);
const tr = withTranslation()(connectedUser);
export default tr;
