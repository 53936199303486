import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { textFilter } from "react-bootstrap-table2-filter";
import _, { cloneDeep } from "lodash";
import { Button, Col, Row } from "reactstrap";
import Sticky from "react-sticky-fill";
import { AvField, AvForm } from "availity-reactstrap-validation";
import _debounce from "lodash.debounce";
import {
  alertActions,
  localeActions,
  locationActions,
  pdiActions,
  vmeterActions,
} from "../_actions";
import ListTools from "../List/ListTools";
import Loading from "../_animations/Loading";
import { history, REGEXVIRTUAL } from "../_helpers";
import userActions from "../_actions/user.actions";
import {
  getFiltersRequest,
  getLightFilters,
} from "../QueryBuilder/FilterLSManager";
import { withTranslation } from "react-i18next";
import MeterSelectionComponent from "../_components/MeterSelectionComponent";

class AddVirtualMeterNew extends Component<any, any> {
  constructor(props) {
    super(props);
    const { t } = props;
    const sourceColumns: any[] = ListTools.getDefaultColumns(
      ListTools.typeOfList.VPdi
    ).map((champ: any) => {
      return {
        dataField: champ,
        text: t(`columns.${champ}`),
        sort: true,
        classes: champ.includes("address") ? "" : "crystalList-column",
        filter: textFilter({
          placeholder: t(`columns.${champ}`),
          delay: 500,
        }),
      };
    });
    this.state = {
      listMeter: {},
      displayingColumns: sourceColumns,
      vmeter: {},
      displayNoMeterSelected: false,
      allSelectButton: 0,
    };
    this.selectListMeter = this.selectListMeter.bind(this);
    this.getPdiList = this.getPdiList.bind(this);
  }

  componentDidMount() {
    const { dispatch, match, user, location } = this.props;
    const { locationId } = match.params;
    const vmeterId = location.search;
    dispatch(localeActions.load());
    dispatch(userActions.get(user.id));
    dispatch(locationActions.get(locationId));
    const keyStorage = `filter-${ListTools.typeOfList.VPdi}-${locationId}`;
    this.setState({
      ruleList: getLightFilters(keyStorage),
      keyStorage,
    });
    this.getPdiList(keyStorage);

    if (vmeterId && vmeterId.length > 0) {
      dispatch(vmeterActions.getInfos(locationId, vmeterId));
    }
    dispatch(vmeterActions.getAll(locationId));
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { vmeters, pdis } = nextProps;
    const { allSelectButton, listMeter } = prevState;
    if (
      _.get(vmeters, "fetchedVMeter.meterList") &&
      !_.get(prevState, "existingMeters") &&
      _.size(_.get(vmeters, "fetchedVMeter.meterList")) > 0
    ) {
      const meterListExisting = {};
      _.get(vmeters, "fetchedVMeter.meterList").forEach(
        (el) => (meterListExisting[el.id] = el.serial)
      );
      return {
        listMeter: meterListExisting,
        existingMeters: meterListExisting,
        vmeter: _.get(vmeters, "fetchedVMeter"),
        name: _.get(vmeters, "fetchedVMeter.general.name"),
      };
    }
    if (_.get(pdis, "clickId") === allSelectButton) {
      const copyListMeter = _.cloneDeep(listMeter);
      _.entries(_.get(pdis, "mapIdToSerial")).forEach((it) => {
        if (!(it[0] in copyListMeter)) {
          copyListMeter[Number.parseInt(it[0])] = it[1];
        }
      });
      return { listMeter: copyListMeter, allSelectButton: allSelectButton + 1 };
    }
  }

  componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
    const { saved, vmeter, listMeter } = this.state;
    const { vmeters, match } = this.props;
    const { locationId } = match.params;
    if (saved && vmeters?.fetchedVMeter?.id) {
      const link = `/locations/${locationId}/virtuals/info`;
      history.push({
        pathname: link,
        search: `?id=${_.get(vmeters, "fetchedVMeter.id")}`,
      });
    }
  }

  getPdiList(keyStorage: string) {
    const {
      dispatch,
      match: {
        params: { locationId },
      },
    } = this.props;
    const fastFiltersRequest: { [key: string]: string } = {};
    const fastFilters = localStorage.getItem(`${keyStorage}-fast`);
    if (fastFilters && fastFilters.length > 0) {
      _.entries(fastFilters).forEach((it: Array<any>) => {
        fastFiltersRequest[it[0]] = it[1].filterVal;
      });
    }

    const page = parseInt(
      localStorage.getItem(`${keyStorage}-page`) || "1",
      10
    );
    dispatch(
      pdiActions.getRemotePdi(
        locationId,
        getFiltersRequest(keyStorage),
        parseInt(localStorage.getItem(`${keyStorage}-sizeperpage`) || "10", 10),
        page,
        !(localStorage.getItem(`${keyStorage}_optionor`) === "true"),
        {},
        fastFiltersRequest,
        null,
        false,
        0,
        null
      )
    );
  }

  getHeaderOfList = () => {
    const { match, t } = this.props;
    const { name, vmeter } = this.state;
    const { params } = match;

    return (
      <Fragment>
        <Sticky style={{ zIndex: "190" }}>
          <div
            className="presentation-container virtual-meter-info"
            style={{ border: "0.5px solid #ccc" }}
          >
            <div className="presentation-header">
              <span className="presentation-title">
                {t("all.meter.virtual_meter_edition")}
              </span>
              <span className="presentation-main-title">
                {Object.keys(vmeter).length > 0
                  ? vmeter.general.name
                  : "Nouveau"}
              </span>
            </div>
            <div className="presentation-body" style={{ background: "none" }}>
              <Col md="12">
                <AvForm
                  onValidSubmit={this.handleValidSubmit}
                  onInvalidSubmit={this.handleInvalidSubmit}
                >
                  <Row>
                    <Col md="4">
                      <AvField
                        name="virtualName"
                        placeholder={t("all.meter.virtual_meter_name")}
                        type="text"
                        validate={{
                          pattern: { value: REGEXVIRTUAL },
                          maxLength: {
                            value: 100,
                            errorMessage: t("all.text.name_lenght_max_100"),
                          },
                          async: this.validateExists,
                        }}
                        required
                        errorMessage={t(
                          "all.text.required_field_character_condition"
                        )}
                        value={name}
                      />
                    </Col>
                    <Col md={{ size: 4, offset: 4 }}>
                      <div className="float-right">
                        <Button
                          type="button"
                          className="danger"
                          onClick={() =>
                            history.push(
                              `/locations/${params.locationId}/virtuals`
                            )
                          }
                        >
                          {t("all.button.giveup")}
                        </Button>
                        <Button type="submit" style={{ marginLeft: "5px" }}>
                          {t("all.button.register")}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </AvForm>
              </Col>
              <div className="clearfix" />
            </div>
          </div>
        </Sticky>
      </Fragment>
    );
  };

  validateExists = _debounce((value, ctx, input, cb) => {
    const { vmeters, t } = this.props;
    value = value.startsWith("V-") ? value : `V-${value.replace(" ", "_")}`;
    if (value === "") {
      cb(true);
    } else if (
      vmeters.items &&
      vmeters.items
        .filter((el) => el.id !== _.get(vmeters, "fetchedVMeter.general.id"))
        .find((el: any) => el.name === value)
    ) {
      cb(t("virtualmeter.text.name_already_exist"));
    } else {
      cb(true);
    }
  }, 500);

  receiveData = (dataFiltered: any, ruleList: any) => {
    const { dispatch, match } = this.props;
    const { keyStorage } = this.state;
    const { locationId } = match.params;
    if (
      !_.isEqual(this.state.ruleList, getLightFilters(keyStorage)) ||
      this.state.optionOr !==
        (localStorage.getItem(`${keyStorage}_optionor`) === "true")
    ) {
      this.setState(
        {
          ruleList: getLightFilters(keyStorage),
          optionOr: localStorage.getItem(`${keyStorage}_optionor`) === "true",
        },
        () =>
          dispatch(
            pdiActions.getRemotePdi(
              locationId,
              getFiltersRequest(keyStorage),
              10,
              1,
              !this.state.optionOr
            )
          )
      );
    }
  };

  handleValidSubmit = (event: Object, values: any) => {
    const { dispatch, vmeters, locations } = this.props;
    const { listMeter } = this.state;
    const { content } = locations.fetchedLocation;
    let body: any = {
      name: values.virtualName.startsWith("V-")
        ? values.virtualName.split("-")[1]
        : values.virtualName,
    };

    if (_.size(listMeter) === 0) {
      this.setState({ displayNoMeterSelected: true });
      return;
    }

    if (vmeters?.fetchedVMeter?.general) {
      body = cloneDeep(vmeters.fetchedVMeter.general);
      body.name = values.virtualName.startsWith("V-")
        ? values.virtualName
        : `V-${values.virtualName}`;
      body.meters = _.keys(listMeter);
      dispatch(vmeterActions.edit(body.id, body));
    } else {
      body.rndCode = content ? content.code : null;
      body.meters = _.keys(listMeter);
      dispatch(vmeterActions.create(body));
    }
    this.setState({
      name: values.virtualName.replace(" ", "_"),
      saved: _.size(listMeter) > 0,
    });
  };

  selectListMeter(event: Object, values: any) {
    const { allSelectButton, keyStorage } = this.state;
    const {
      dispatch,
      locations,
      match: {
        params: { locationId },
      },
    } = this.props;
    const { content } = locations.fetchedLocation;

    dispatch(
      pdiActions.getMapIdToSerial(
        { filters: getFiltersRequest(keyStorage), meterNotInRound: false },
        content && content.code,
        1,
        allSelectButton + 1
      )
    );
    this.setState({ allSelectButton: allSelectButton + 1 });
  }

  handleInvalidSubmit = (event: Object, errors: Object, values: any) => {
    this.setState({
      name: values.virtualName,
    });
  };

  render() {
    const {
      pdis,
      t,
      match: {
        params: { locationId },
      },
    } = this.props;
    const {
      displayingColumns,
      displayNoMeterSelected,
      listMeter,
      existingMeters,
      keyStorage,
    } = this.state;

    return pdis.items ? (
      <div
        className="col-md-12"
        style={{
          overflow: "auto",
          maxHeight: "calc(-105px + 100vh)",
          height: "100%",
        }}
      >
        {this.getHeaderOfList()}
        <MeterSelectionComponent
          displayingColumns={displayingColumns}
          displayNoMeterSelected={displayNoMeterSelected}
          existingMeters={existingMeters}
          keyStorage={keyStorage}
          idContext={ListTools.typeOfList.VPdi}
          listMeter={listMeter}
          locationId={locationId}
          pdis={pdis}
          selectListMeter={this.selectListMeter}
          setListMeter={(newListMeter: any) =>
            this.setState({ listMeter: newListMeter })
          }
          receiveData={this.receiveData}
          loading={pdis.loadingFilters || pdis.loading}
        />
      </div>
    ) : (
      <Loading />
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication, pdis, locales, vmeters, locations, alert, users } =
    state;
  const { user } = authentication;

  return {
    user,
    pdis,
    locales,
    vmeters,
    locations,
    alert,
    users,
  };
}

const wt: any = connect(mapStateToProps)(AddVirtualMeterNew);
export default withTranslation()(withRouter(wt));
