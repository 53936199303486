import React, { useState, useEffect } from "react";
import "./style.scss";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { importActions } from "../_actions";
import filterFactory from "react-bootstrap-table2-filter";
import BootstrapTable from "react-bootstrap-table-next";
import Chip from "../_components/Chip";
import ImportConflict from "./ImportConflict";
import DownloadArrow from "../SvgComponents/DownloadArrow";
import FileDropZone from "../_components/FileDropZone";
import LoadingImport from "./LoadingImport";
import { importConstants } from "../_constants";
import SvgCroixNoir from "../SvgComponents/croixNoir";
import LoadingButton from "./LoadingButton";

const style = {
  label: {
    alignItems: "center",
    padding: "2px 5px",
    borderRadius: "5px",
    width: "fit-content",
    margin: "20px 0",
    border: "1px solid",
  },
  span: {
    marginRight: "10px",
  },
  h5: {
    color: "#000",
    textAlign: "center",
    margin: "10px 0",
  },
};

const Label = ({ removeFunction, manufacturer, t }) => {
  const conflict = manufacturer?.formats.length > 0;
  const name = manufacturer?.import_filename;

  const color = conflict ? "#F2AE2E" : "#fff";
  const borderColor = conflict ? "#F2AE2E" : "lightgrey";
  return conflict ? (
    <div
      style={{ ...style.label, backgroundColor: color, borderColor }}
      className={`flex label ${conflict ? "conflict" : ""}`}
    >
      <span style={style.span}>{name}</span>
      <div className={"svg-box"} onClick={removeFunction}>
        <SvgCroixNoir height={"16px"} />
      </div>
    </div>
  ) : (
    <h5
      style={{
        ...style.h5,
        color: manufacturer.success ? "#51bd4a" : "#dc4530",
      }}
    >
      {manufacturer.success
        ? t("import_manufacturer.success", { filename: name })
        : t("import_manufacturer.failure", { filename: name })}
    </h5>
  );
};

const ImportSuccess = ({ manufacturer, callback, t }) => {
  const errorColumns = [
    {
      dataField: "meter_serial_number",
      text: t("import_silex.columns.identifiant"),
    },
    {
      dataField: "radio_serial_number",
      text: t("import_silex.columns.field"),
    },
    {
      dataField: "error_code",
      text: t("import_silex.columns.temoin_value"),
      formatter: (cell) => t(`import_manufacturer.${cell}`),
    },
  ];
  return (
    <div className={"manufacturer-wrapper"}>
      <div
        className={"span-container flex vertical"}
        style={{ color: "black", padding: "30px 0" }}
      >
        <table className={"result-table"}>
          <tr>
            <td>
              <b>{t("import_manufacturer.conflict.format_with_param")}</b>:{" "}
              {manufacturer.detected_format}
            </td>
            <td>
              <b>{t("import_manufacturer.success.lines")}</b>:{" "}
              {manufacturer.line_count}
            </td>
            <td>
              <b>{t("import_manufacturer.success.keys")}</b>:{" "}
              {manufacturer.key_added}
            </td>
          </tr>
          <tr>
            <td>
              <b>{t("import_manufacturer.success.meters")}</b>:{" "}
              {manufacturer.meter_added}
            </td>
            <td>
              <b>{t("import_manufacturer.success.radios")}</b>:{" "}
              {manufacturer.radio_added}
            </td>
            <td>
              <b>{t("import_manufacturer.success.links")}</b>:{" "}
              {manufacturer.pairing_added}
            </td>
          </tr>
        </table>
      </div>
      {manufacturer.errors.length > 0 && (
        <div className={"errors-container"}>
          <h5>
            {t("import_manufacturer.success.errors", {
              value: manufacturer?.errors?.length,
            })}
          </h5>
          <div
            className={"table-container"}
            style={{
              maxHeight: "300px",
              overflow: "auto",
              width: "calc(100% - 10px)",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <BootstrapTable
              keyField="identification"
              data={manufacturer.errors}
              columns={errorColumns}
              bootstrap4
              bordered
              condensed
              hover
              filter={filterFactory()}
              striped
              rowClasses="clickable"
            />
          </div>
        </div>
      )}
      <div className={"flex center"} style={{ marginTop: "10px" }}>
        <Button
          variant="contained"
          className={"success"}
          style={{ width: "130px" }}
          onClick={callback}
        >
          {t("all.button.reset")}
        </Button>
      </div>
    </div>
  );
};

const ImportConstructeur = ({ dispatch, t, user, imports }) => {
  const { manufacturer, loading } = imports;
  const [selectedFormat, setSelectedFormat] = useState(null);
  const [file, setFile] = useState(null);

  useEffect(() => {
    return () => {
      dispatch({ type: importConstants.CLEAR });
    };
  }, []);

  const handleSubmit = (e) => {
    const formData = new FormData();
    formData.append("file", file);
    dispatch(importActions.importConstructeur(formData, user.userName));
  };

  const removeFile = () => {
    dispatch({ type: importConstants.CLEAR });
    setFile(null);
  };

  return (
    imports?.enabled && (
      <div className={"import-constructeur-wrapper"}>
        {imports.error && <Chip message={imports.error} severity={"error"} />}
        <div className={"container"}>
          <div className={"flex vertical center"}>
            <DownloadArrow
              width="50px"
              fill="#ACABAB"
              className="dl-icon import-side-icon"
            />
            <h2>{t("all.text.import_mfr")}</h2>
          </div>
          {manufacturer?.import_filename ? (
            <Label
              removeFunction={removeFile}
              manufacturer={manufacturer}
              t={t}
            />
          ) : (
            <div className={"flex vertical center"}>
              <FileDropZone
                acceptedFilesFormat={".csv,.srf"}
                file={file}
                height={"200px"}
                setFile={setFile}
              />
              <LoadingButton
                loading={loading}
                result={manufacturer}
                error={imports?.error}
                handleCancel={removeFile}
                handleValid={handleSubmit}
                disabled={!file}
              />
            </div>
          )}
          <div>
            {manufacturer &&
              (manufacturer.formats.length === 0 ? (
                <ImportSuccess
                  manufacturer={manufacturer}
                  callback={removeFile}
                  t={t}
                />
              ) : (
                <ImportConflict file={file} />
              ))}
          </div>
        </div>
      </div>
    )
  );
};

function mapStateToProps(state) {
  const { imports, authentication } = state;
  const { user } = authentication;
  return {
    imports,
    user,
  };
}

const connectedComponent = connect(mapStateToProps)(ImportConstructeur);
const tr = withTranslation()(connectedComponent);
export default tr;
