import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { Spinner } from "reactstrap";
import SvgValidation from "../SvgComponents/ValidationVert";
import SvgCroixNoir from "../SvgComponents/croixNoir";
import SvgArrowRightVert from "../SvgComponents/ArrowRightVert";

const style = {
  spinner: {
    height: "20px",
    width: "20px",
    color: "#51BD4A",
    zIndex: 1000000,
  },
  disabled: {
    position: "absolute",
    height: "100%",
    width: "100%",
    backgroundColor: "rgba(211,211,211,0.50)",
    border: "1px solid rgba(211,211,211,0.50)",
    borderRadius: "15px",
  },
};

const DisabledFill = () => <div style={style.disabled} />;

const LoadingButton = ({
  handleCancel,
  handleValid,
  loading,
  error,
  result,
  disabled,
}) => {
  const onCancel = () => {
    !loading && !disabled && handleCancel();
  };

  const onValid = () => {
    !loading && !disabled && handleValid();
  };

  return (
    <div
      className={`loading-button ${(loading || result) && "loading"} ${
        error && "error"
      } ${disabled && "disabled"}`}
      onClick={!error ? onValid : onCancel}
    >
      {loading && <Spinner style={style.spinner} />}
      {!loading && !error && <SvgArrowRightVert width={"28px"} />}
      {!loading && result?.success && <SvgValidation height={"28px"} />}
      {error && <SvgCroixNoir height={"20px"} />}
    </div>
  );
};

const tr = withTranslation()(LoadingButton);
export default tr;
