import _ from "lodash";
import React, { Dispatch, SetStateAction } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { WithTranslation, withTranslation } from "react-i18next";

const ImportSilexWarningTable: React.FC<
  {
    imports: any;
    lineType: number;
    setLineType: Dispatch<SetStateAction<number>>;
    selectedRound: any;
    setSelectedRound: Dispatch<SetStateAction<any>>;
  } & WithTranslation
> = ({
  imports,
  t,
  i18n,
  lineType,
  setLineType,
  selectedRound,
  setSelectedRound,
}) => {
  const errorColumns = [
    {
      dataField: "identifiant",
      text: t("import_silex.columns.identifiant"),
    },
    {
      dataField: "fieldName",
      text: t("import_silex.columns.field"),
      formatter: (cell) => t(`import_silex.warning_text.${cell}`),
    },
    {
      dataField: "valueTemoin",
      text: t("import_silex.columns.temoin_value"),
      type: "number",
    },
    {
      dataField: "valueGenerated",
      text: t("import_silex.columns.generated_value"),
    },
  ];
  return (
    <>
      <div className="tab-onglet-import-container">
        <BootstrapTable
          keyField="identifiant"
          bootstrap4
          bordered
          hover
          data={imports.warnings}
          columns={errorColumns}
        />
      </div>
    </>
  );
};

const tr = withTranslation()(ImportSilexWarningTable);
export default tr;
