import { importConstants } from "../_constants";
import { SilexImportState } from "../_interfaces/imports";
import importService from "../_services/import.service";
import { InterventionRoundParameters } from "../_interfaces/TourneeCreationTypes";

/**
 * Récupère toutes les alarmes du site courant
 *
 * @methd importFile
 * @param {string} rndCode Code du site
 * @returns {Object} Les alarmes (retour ws)
 */
function importFile(file: FormData) {
  function request() {
    return { type: importConstants.IMPORT_REQUEST };
  }
  function success(data: Object) {
    return { type: importConstants.IMPORT_SUCCESS, data };
  }
  function failure(error: any) {
    return { type: importConstants.IMPORT_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    importService
      .importFile(file)
      .then((data: any) => {
        dispatch(success(data));
        dispatch(isRunning());
      })
      .catch((error: any) => {
        console.log("error", error);
        dispatch(failure(error));
      });
  };
}

function importConstructeur(file: any, username: string, format?: string) {
  function request() {
    return { type: importConstants.IMPORT_MANUFACTURER_REQUEST };
  }
  function success(data: Object) {
    return { type: importConstants.IMPORT_MANUFACTURER_SUCCESS, data };
  }
  function failure(error: any) {
    return { type: importConstants.IMPORT_MANUFACTURER_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    importService
      .importConstructeur(file, username, format)
      .then((data: any) => {
        dispatch(success(data));
      })
      .catch((error: any) => {
        console.log("error", error);
        dispatch(failure(error));
      });
  };
}

function analyseFile(file: FormData, headerSize: null | number) {
  function request() {
    return { type: importConstants.ANALYSE_REQUEST };
  }
  function success(data: Object) {
    return { type: importConstants.ANALYSE_SUCCESS, data };
  }
  function failure(error: Object) {
    return { type: importConstants.ANALYSE_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    importService
      .analyseFile(file, headerSize)
      .then((data: any) => {
        dispatch(success(data));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function getErrorLastImport() {
  function request() {
    return { type: importConstants.IMPORT_SILEX_ERROR_REQUEST };
  }
  function success(data: Object) {
    return { type: importConstants.IMPORT_SILEX_ERROR_SUCCESS, data };
  }
  function failure(error: Object) {
    return { type: importConstants.IMPORT_SILEX_ERROR_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    importService
      .getErrorLastImport()
      .then((data: any) => {
        dispatch(success(data));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

/**
 * Récupère toutes les alarmes du site courant
 *
 * @methd importFile
 * @param {string} rndCode Code du site
 * @returns {Object} Les alarmes (retour ws)
 */
function importSilexFile(file: FormData, gestionnaireId: number) {
  function request() {
    return { type: importConstants.IMPORT_SILEX_REQUEST };
  }
  function success(data: Object) {
    return { type: importConstants.IMPORT_SILEX_SUCCESS, data };
  }
  function failure(error: Object) {
    return { type: importConstants.IMPORT_SILEX_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    importService
      .importSilexFile(file, gestionnaireId)
      .then((data: any) => {
        dispatch(success(data));
      })
      .catch((error: any) => {
        dispatch(failure(error));
        dispatch(getErrorLastImport());
      });
  };
}

function importLoraFile(
  file: FormData,
  radioSerialColumnIndex: number,
  devEUIColumnIndex: number,
  loraSerialColumnIndex: number,
  appEUIColumnIndex: number,
  appKeyColumnIndex: number
) {
  function request() {
    return { type: importConstants.IMPORT_LORA_REQUEST };
  }
  function success(data: Object) {
    return { type: importConstants.IMPORT_LORA_SUCCESS, data };
  }
  function failure(error: Object) {
    return { type: importConstants.IMPORT_LORA_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    importService
      .importLoraFile(
        file,
        radioSerialColumnIndex,
        devEUIColumnIndex,
        loraSerialColumnIndex,
        appEUIColumnIndex,
        appKeyColumnIndex
      )
      .then((data: any) => {
        dispatch(success(data));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

/**
 * Récupère toutes les alarmes du site courant
 *
 * @methd importFile
 * @param {string} rndCode Code du site
 * @returns {Object} Les alarmes (retour ws)
 */
function testImport() {
  function request() {
    return { type: importConstants.IMPORT_TEST_REQUEST };
  }
  function success(data: Object) {
    return { type: importConstants.IMPORT_TEST_SUCCESS, data };
  }
  function failure(error: Object) {
    return { type: importConstants.IMPORT_TEST_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    importService
      .testImport()
      .then((data: any) => {
        dispatch(success(data));
        dispatch(isRunning());
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function restore() {
  function request() {
    return { type: importConstants.IMPORT_RESTORE_REQUEST };
  }
  function success(restore: Object) {
    return { type: importConstants.IMPORT_RESTORE_SUCCESS, restore };
  }
  function failure(error: Object) {
    return { type: importConstants.IMPORT_RESTORE_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    importService
      .restore()
      .then((data: any) => {
        dispatch(success(data));
        dispatch(isRunning());
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

/**
 * Récupère toutes les alarmes du site courant
 *
 * @methd importFile
 * @param {string} rndCode Code du site
 * @returns {Object} Les alarmes (retour ws)
 */
function confirmImport() {
  function request() {
    return { type: importConstants.IMPORT_CONFIRM_REQUEST };
  }
  function success(data: Object) {
    return { type: importConstants.IMPORT_CONFIRM_SUCCESS, data };
  }
  function failure(error: Object) {
    return { type: importConstants.IMPORT_CONFIRM_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    importService
      .confirmImport()
      .then((data: any) => {
        dispatch(success(data));
        dispatch(isRunning());
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

/**
 * Récupère toutes les alarmes du site courant
 *
 * @methd importFile
 * @param {string} rndCode Code du site
 * @returns {Object} Les alarmes (retour ws)
 */
function isRunning() {
  function request() {
    return { type: importConstants.IMPORT_RUNNING_REQUEST };
  }
  function success(data: Object) {
    return { type: importConstants.IMPORT_RUNNING_SUCCESS, data };
  }
  function failure(error: Object) {
    return { type: importConstants.IMPORT_RUNNING_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    importService
      .isRunning()
      .then((data: any) => {
        dispatch(success(data));
        if (data.processing && !data.hasError) {
          setTimeout(() => {
            dispatch(isRunning());
          }, 1000);
        }
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function importMessagesFile(file: FormData) {
  function request() {
    return { type: importConstants.IMPORT_MESSAGES_REQUEST };
  }
  function success(data: Object) {
    return { type: importConstants.IMPORT_MESSAGES_SUCCESS, data };
  }
  function failure(error: Object) {
    return { type: importConstants.IMPORT_MESSAGES_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    importService
      .importMessagesFile(file)
      .then((data: any) => {
        dispatch(success(data));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function importMarquagesFile(file: FormData) {
  function request() {
    return { type: importConstants.IMPORT_MARQUAGES_REQUEST };
  }
  function success(data: Object) {
    return { type: importConstants.IMPORT_MARQUAGES_SUCCESS, data };
  }
  function failure(error: Object) {
    return { type: importConstants.IMPORT_MARQUAGES_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    importService
      .importMarquagesFile(file)
      .then((data: any) => {
        dispatch(success(data));
      })
      .catch((error: Object) => {
        dispatch(failure(error));
      });
  };
}

function reworkedImport(
  file: FormData,
  gestionnaireID: Number,
  authorizeNewMeterAfterChange: boolean,
  binaryArray?: boolean[]
) {
  function request() {
    return { type: importConstants.IMPORT_REWORKED_REQUEST };
  }
  function success(data: Object) {
    return { type: importConstants.IMPORT_REWORKED_SUCCESS, data };
  }
  function failure(error: Object) {
    return { type: importConstants.IMPORT_REWORKED_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    importService
      .reworkedImportSilexFile(
        file,
        gestionnaireID,
        authorizeNewMeterAfterChange,
        binaryArray
      )
      .then((data: any) => {
        dispatch(success(data));
        dispatch(getImportWarnings());
      })
      .catch((error: any) => {
        dispatch(failure(error));
        if (error.translationId != "silex_format_error") {
          dispatch(getErrorLastImport());
        }
      });
  };
}

function getImportWarnings() {
  function request() {
    return { type: importConstants.GET_IMPORT_WARNINGS_REQUEST };
  }
  function success(data: Object) {
    return { type: importConstants.GET_IMPORT_WARNINGS_SUCCESS, data };
  }
  function failure(error: Object) {
    return { type: importConstants.GET_IMPORT_WARNINGS_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    importService
      .getImportWarnings()
      .then((data: any) => {
        dispatch(success(data));
      })
      .catch((error: any) => {
        dispatch(failure(error));
      });
  };
}

function getImportState() {
  function request() {
    return { type: importConstants.IMPORT_STATE_REQUEST };
  }
  function success(data: SilexImportState) {
    return { type: importConstants.IMPORT_STATE_SUCCESS, data };
  }
  function failure(error: any) {
    return { type: importConstants.IMPORT_STATE_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    importService
      .getImportState()
      .then((data: SilexImportState) => dispatch(success(data)))
      .catch((error: any) => dispatch(failure(error)));
  };
}

function cancelConfigImport() {
  function request() {
    return { type: importConstants.CANCEL_CONFIG_IMPORT_REQUEST };
  }
  return (dispatch: Function) => {
    dispatch(request());
  };
}

function downloadReport() {
  function request() {
    return { type: importConstants.REPORT_DOWNLOAD_REQUEST };
  }
  function success(data: Object) {
    return { type: importConstants.REPORT_DOWNLOAD_SUCCESS, data };
  }
  function failure(error: Object) {
    return { type: importConstants.REPORT_DOWNLOAD_FAILURE, error };
  }
  return (dispatch: Function) => {
    dispatch(request());
    importService
      .downloadReport()
      .then((blob: { content: Blob | MediaSource; filename: string }) => {
        dispatch(success(blob.content));
        const url = window.URL.createObjectURL(blob.content);
        const a = document.createElement("a");
        a.href = url;
        a.download = blob.filename;
        if (blob.filename !== null) {
          a.click();
        }
      })
      .catch((error: any) => {
        dispatch(failure(error));
      });
  };
}

function importFromFacturation(withFilter: boolean) {
  function request() {
    return { type: importConstants.LAUNCH_IMPORT_FROM_FACTURATION_REQUEST };
  }
  function success(data: Object[]) {
    return {
      type: importConstants.LAUNCH_IMPORT_FROM_FACTURATION_SUCCESS,
      data,
    };
  }
  function failure(error: any) {
    return {
      type: importConstants.LAUNCH_IMPORT_FROM_FACTURATION_ERROR,
      error,
    };
  }
  return (dispatch: Function) => {
    dispatch(request());
    importService
      .importFromFacturation(withFilter)
      .then((data: Object[]) => dispatch(success(data)))
      .catch((error: any) => dispatch(failure(error)));
  };
}

function importInterventionFromFacturation(
  parameters: Array<InterventionRoundParameters>,
  gestionnaireId: number
) {
  function request() {
    return {
      type: importConstants.LAUNCH_INTERVENTION_IMPORT_FROM_FACTURATION_REQUEST,
    };
  }
  function success(data: Object[]) {
    return {
      type: importConstants.LAUNCH_INTERVENTION_IMPORT_FROM_FACTURATION_SUCCESS,
      interventionImportResult: data,
    };
  }
  function failure(error: any) {
    return {
      type: importConstants.LAUNCH_INTERVENTION_IMPORT_FROM_FACTURATION_FAILURE,
      error,
    };
  }
  return (dispatch: Function) => {
    dispatch(request());
    importService
      .importInterventionFromFacturation(parameters, gestionnaireId)
      .then((data: Object[]) => dispatch(success(data)))
      .catch((error: any) => dispatch(failure(error)));
  };
}

function getInterventionUsers() {
  function request() {
    return { type: importConstants.GET_INTERVENTION_USERS_REQUEST };
  }
  function success(users) {
    return {
      type: importConstants.GET_INTERVENTION_USERS_SUCCESS,
      users,
    };
  }
  function failure(error: any) {
    return {
      type: importConstants.GET_INTERVENTION_USERS_FAILURE,
      error,
    };
  }
  return (dispatch: Function) => {
    dispatch(request());
    importService
      .getInterventionUsers()
      .then((data) => dispatch(success(data)))
      .catch((error: any) => dispatch(failure(error)));
  };
}

function addInterventionUsers(newUser: String) {
  function request() {
    return { type: importConstants.ADD_INTERVENTION_USERS_REQUEST };
  }
  function success(users) {
    return {
      type: importConstants.ADD_INTERVENTION_USERS_SUCCESS,
      users,
    };
  }
  function failure(error: any) {
    return {
      type: importConstants.ADD_INTERVENTION_USERS_FAILURE,
      error,
    };
  }
  return (dispatch: Function) => {
    dispatch(request());
    importService
      .addInterventionUser(newUser)
      .then((data) => dispatch(success(data)))
      .catch((error: any) => dispatch(failure(error)));
  };
}

function deleteInterventionUser(deletedUser: String) {
  function request() {
    return { type: importConstants.DELETE_INTERVENTION_USERS_REQUEST };
  }
  function success(users) {
    return {
      type: importConstants.DELETE_INTERVENTION_USERS_SUCCESS,
      users,
    };
  }
  function failure(error: any) {
    return {
      type: importConstants.DELETE_INTERVENTION_USERS_FAILURE,
      error,
    };
  }
  return (dispatch: Function) => {
    dispatch(request());
    importService
      .deleteInterventionUser(deletedUser)
      .then((data) => dispatch(success(data)))
      .catch((error: any) => dispatch(failure(error)));
  };
}

function editInterventionUser(uneditedUser: string, editedUser: string) {
  function request() {
    return { type: importConstants.EDIT_INTERVENTION_USERS_REQUEST };
  }
  function success(users) {
    return {
      type: importConstants.EDIT_INTERVENTION_USERS_SUCCESS,
      users,
    };
  }
  function failure(error: any) {
    return {
      type: importConstants.EDIT_INTERVENTION_USERS_FAILURE,
      error,
    };
  }
  return (dispatch: Function) => {
    dispatch(request());
    importService
      .editInterventionUser(uneditedUser, editedUser)
      .then((data) => dispatch(success(data)))
      .catch((error: any) => dispatch(failure(error)));
  };
}

function getImportManufacturer() {
  function request() {
    return { type: importConstants.GET_IMPORT_MANUFACTURER_REQUEST };
  }
  function success(enabled) {
    return {
      type: importConstants.GET_IMPORT_MANUFACTURER_SUCCESS,
      enabled,
    };
  }
  function failure(error: any) {
    return {
      type: importConstants.GET_IMPORT_MANUFACTURER_FAILURE,
      error,
    };
  }
  return (dispatch: Function) => {
    dispatch(request());
    importService
      .getImportManufacturer()
      .then((data) => dispatch(success(data)))
      .catch((error: any) => dispatch(failure(error)));
  };
}

function clearError() {
  return {
    type: importConstants.CLEAR_ERROR,
  };
}

/**
 * Vide le state des alarmes
 *
 * @method clear
 */
function clear() {
  return {
    type: importConstants.CLEAR,
  };
}
function clearInterventions() {
  return {
    type: importConstants.CLEAR_INTERVENTION,
  };
}

const importActions = {
  isRunning,
  importFile,
  analyseFile,
  restore,
  importSilexFile,
  testImport,
  confirmImport,
  importMessagesFile,
  importMarquagesFile,
  clearError,
  clear,
  importLoraFile,
  getErrorLastImport,
  reworkedImport,
  getImportState,
  cancelConfigImport,
  downloadReport,
  importFromFacturation,
  importInterventionFromFacturation,
  getInterventionUsers,
  addInterventionUsers,
  deleteInterventionUser,
  editInterventionUser,
  clearInterventions,
  importConstructeur,
  getImportManufacturer,
};

export default importActions;
