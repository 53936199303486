import React from "react";

const SvgArrowRightVert = (props: any) => (
  <svg viewBox="0 0 25.5 15.92" {...props}>
    <g id="Calque_2" data-name="Calque 2">
      <g id="Calque_2-2" data-name="Calque 2">
        <path
          d="M24.4,7.4c-0.1-0.2-0.2-0.3-0.3-0.5l-6.5-6.4c-0.6-0.6-1.5-0.6-2.1,0c-0.6,0.6-0.6,1.5,0,2.1l3.9,3.9H1.5
          C0.7,6.5,0,7.1,0,8s0.7,1.5,1.5,1.5h17.9l-3.9,3.9c-0.6,0.6-0.6,1.5,0,2.1c0.3,0.3,0.7,0.4,1.1,0.4c0.4,0,0.8-0.2,1-0.4L24,9
          c0.2-0.1,0.3-0.3,0.4-0.5C24.5,8.2,24.5,7.8,24.4,7.4z"
          fill={props.fill}
        />
      </g>
    </g>
  </svg>
);

export default SvgArrowRightVert;
