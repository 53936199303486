import React, { useState, useEffect, useRef } from "react";
import Checkbox from "@mui/material/Checkbox";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import synchrocomActions from "../../_actions/synchrocom.actions";

const style = {
  wrapper: {
    height: "50px",
    width: "calc(100% - 10px)",
    backgroundColor: "white",
    border: "1px solid lightgrey",
    borderRadius: "5px",
    margin: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 15px",
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  flex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    margin: 0,
    color: "#000",
  },
  span: {
    marginRight: "10px",
    color: "#000",
  },
  checkbox: {
    padding: "3px",
  },
};

const label = { inputProps: { color: "#31c6b3" } };

const colorBox = {
  color: "#31c6b3",
  "&.Mui-checked": {
    color: "#31c6b3",
  },
};

const HeaderSynchro = ({ dispatch, multi, t }) => {
  const [selection, setSelection] = useState("complete");

  useEffect(() => {
    const localMod = JSON.parse(localStorage.getItem("synchro-mode"));
    if (localMod?.selection) setSelection(localMod.selection);

    window.addEventListener("keydown", (e) => {
      if (e.keyCode === 17) {
        dispatch(synchrocomActions.multiDragChange());
      }
    });
  }, []);

  useEffect(() => {
    localStorage.setItem(
      "synchro-mode",
      JSON.stringify({
        selection,
      })
    );
    dispatch(synchrocomActions.setSynchroMode(selection));
  }, [selection]);

  return (
    <div className={"header-synchro-wrapper"} style={style.wrapper}>
      <div style={style.container}>
        <h5 style={{ ...style.title, marginRight: "10px" }}>
          {t("synchronisation.type")}
        </h5>
        <div style={style.flex}>
          <Checkbox
            {...label}
            checked={selection === "complete"}
            onChange={() => setSelection("complete")}
            style={style.checkbox}
            sx={colorBox}
          />
          <span style={style.span}>{t("synchronisation.full")}</span>
        </div>
        <div style={style.flex}>
          <Checkbox
            {...label}
            checked={selection === "partiel"}
            onChange={() => setSelection("partiel")}
            style={style.checkbox}
            sx={colorBox}
          />
          <span style={style.span}>{t("synchronisation.partial")}</span>
        </div>
        <div style={style.flex}>
          <Checkbox
            {...label}
            checked={selection === "both"}
            onChange={() => setSelection("both")}
            style={style.checkbox}
            sx={colorBox}
          />
          <span style={style.span}>{t("synchronisation.full_partial")}</span>
        </div>
      </div>
      <div style={style.container}>
        <div style={style.flex}>
          <h5 style={style.title}>{t("synchronisation.multi_selection")}</h5>
          <Checkbox
            {...label}
            checked={multi}
            onChange={() => dispatch(synchrocomActions.multiDragChange(!multi))}
            style={style.checkbox}
            sx={colorBox}
          />
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { synchrocom } = state;
  const { multi } = synchrocom;

  return {
    multi,
  };
}

const mapping = connect(mapStateToProps)(HeaderSynchro);
const connectedComponent = withRouter(mapping);
const tr = withTranslation()(connectedComponent);
export default tr;
