/* eslint-disable react/require-default-props */

import React, {
  ComponentType,
  Fragment,
  ReactElement,
  useState,
  useEffect,
} from "react";
import moment from "moment";
import { Link, withRouter } from "react-router-dom";
import { Col } from "reactstrap";
import _ from "lodash";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import { eachDayOfInterval, format, isSameDay } from "date-fns";
import { isNumber } from "util";

import DiametreVert from "../SvgComponents/DiametreVert";
import PoidsImpulsionVert from "../SvgComponents/PoidsImpulsionVert";
import ModeleVert from "../SvgComponents/ModeleVert";
import FabricantVert from "../SvgComponents/FabricantVert";
import DerniereReleveVert from "../SvgComponents/DerniereReleveVert";
import SvgCompteurVert from "../SvgComponents/CompteurVert";
import NoFilled from "../Message/NoFilled";
import ListTools from "../List/ListTools";
import InformationsBleu from "../SvgComponents/InformationsBleu";
import TeleReleveInfo from "../_components/TeleReleveInfo";
import getFluidPicto from "./Fluid_correspondance";
import { getDaysInInterval } from "../_helpers/date-helper";
import GaleryPhoto from "../SourceSheet/GaleryPhoto";
import EditionBleu from "../SvgComponents/EditionBleu";
import StyledInput from "../FicheDetail/useFullComponent/StyledInput";
import SvgCroixNoir from "../SvgComponents/croixNoir";
import SvgValidation from "../SvgComponents/ValidationVert";
import StyledSelect from "../FicheDetail/useFullComponent/StyledSelect";
import meterActions from "../_actions/meter.actions";
import { compose } from "redux";
import { tourneeActions } from "../_actions";

type Props = {
  intervalSelector: ReactElement<"IntervalSelector">;
  historic: ReactElement<"div">;
  infoGeneral: any;
  loading: boolean;
  error: string;
  gps: {
    longitude: number;
    latitude: number;
  };
  lastRead: any;
  context: string | null | undefined;
  locationId: number;
  displayLinkToPdi: boolean;
  t: Function;
  withoutDateComponent: boolean;
  meters: any;
  fullPdi: any;
};

function generateIndexDisplay(
  index: number,
  wheels: number,
  date: string,
  title: string,
  nbDec: number
) {
  const indexCasesDisplay =
    index > -1 ? Math.floor(index).toString().length : 0;
  const emptyCases = wheels - indexCasesDisplay;
  const redCases = (index || ".").toString().split(".")[1];

  return (
    <Col style={{ paddingLeft: 0, paddingTop: 5 }}>
      <div>
        {emptyCases > 0 &&
          _.range(emptyCases).map(() => (
            <span
              style={{
                padding: 5,
                margin: 3,
                background: "black",
                fontSize: 25,
                color: "black",
              }}
            >
              0
            </span>
          ))}
        {_.range(indexCasesDisplay).map((i) => (
          <span
            style={{
              padding: 5,
              margin: 3,
              background: "black",
              fontSize: 25,
              color: "white",
            }}
          >
            {Math.floor(index).toString()[i]}
          </span>
        ))}
        {_.range(nbDec).map((i) => (
          <span
            style={{
              padding: 5,
              margin: 3,
              background: "#c02020",
              fontSize: 25,
              color: "white",
            }}
          >
            {(redCases && redCases[i]) || 0}
          </span>
        ))}
      </div>
    </Col>
  );
}

function MeterWrapped(props: any) {
  const {
    intervalSelector,
    historic,
    infoGeneral,
    gps,
    lastRead,
    locationId,
    displayLinkToPdi,
    withoutDateComponent,
    t,
    meters,
    fullPdi,
    fetchedPdi,
    tournee,
    user,
    users,
    locations,
    dispatch,
  } = props;

  const [edit, setEdit]: any = useState(null);
  const [editableFieldValue, setEditableFieldValue]: any = useState(null);

  useEffect(() => {
    if (!_.get(tournee, "editInfos.messages")) {
      dispatch(tourneeActions.getMessagesMarquages());
    }
  }, []);

  const couldBeEdit = () => {
    if (
      _.get(users, "fetchedUser.profils", []).find(
        (p: any) =>
          p.locationCode === _.get(locations, "fetchedLocation.code") &&
          p.profil.permissions.find((el: any) => el.name === "edit.message")
      )
    ) {
      return true;
    }
    return ["DIOPTASE", "SUPERADMIN", "ADMIN"].includes(user.role.name);
  };

  const validChange = () => {
    const { dispatch, fetchedPdi } = props;
    let value = null;
    switch (edit) {
      case "freeMessage":
        value = editableFieldValue;
        break;
      case "message":
        value = editableFieldValue.value;
        break;
      default:
        break;
    }
    dispatch(
      meterActions.updateMessage(
        infoGeneral.pdi.id,
        edit,
        value,
        editableFieldValue,
        infoGeneral.serial
      )
    );
    setEdit(null);
    setEditableFieldValue(null);
  };

  const linkToPDI =
    infoGeneral.pdi && infoGeneral.pdi.id
      ? `/locations/${locationId}/pdi/${infoGeneral.pdi.id}`
      : "";
  const pulseWeight = infoGeneral && infoGeneral.model.pulseWeight;
  let nbDec = 0;
  if (pulseWeight && pulseWeight < 1) {
    nbDec = pulseWeight.toString().split(".")[1].length;
  }
  let meter = meters.fetchedMeter;
  if (!meter && meters.allMetersInfo) {
    meter = meters.allMetersInfo.find(
      (x: any) => x.general.serial === infoGeneral.serial
    );
  }

  const currentRead = _.get(meter, "lastRead");
  const previousRead = _.get(meter, "previousRead");

  const daysBtwCurrentAndLastRead = getDaysInInterval(
    _.get(previousRead, "date"),
    _.get(currentRead, "date")
  );
  return (
    <Fragment>
      {infoGeneral && Object.keys(infoGeneral).length > 0 && (
        <>
          <div className="clean-meter-bar">
            <div className="flex-box">
              <div
                className="c20 background-texture left"
                style={{ position: "relative" }}
              >
                <>
                  <div
                    className="absoluteVerticalCenter"
                    style={{
                      height: "50px",
                      width: "50px",
                      borderRadius: "100px",
                      backgroundColor: "white",
                      border: "4px solid #31c6b3",
                      left: 0,
                      marginLeft: "-25px",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        height: "40px",
                        width: "40px",
                      }}
                    >
                      <span className="absoluteCentered">
                        {getFluidPicto(_.get(infoGeneral, "fluidType"), {
                          fill: "#000",
                          width: "1.7em",
                          style: { marginRight: 0 },
                        })}
                      </span>
                    </div>
                  </div>
                  <div>
                    <span>{t("all.meter.serial")}</span>
                    <h3>{infoGeneral.serial}</h3>
                  </div>
                </>
              </div>
              <div className="c20 b-right">
                <div className="flex-box center">
                  <DiametreVert
                    height="2em"
                    width="2em"
                    stroke="#31c6b3"
                    fill="#31c6b3"
                    strokeWidth="0"
                  />{" "}
                  <div>
                    <span>{t("all.meter.real_meter_diameter")}</span>
                    {infoGeneral.model.diameter ? (
                      <h3>{infoGeneral.model.diameter}</h3>
                    ) : (
                      <NoFilled />
                    )}
                  </div>
                </div>
              </div>
              <div className="c20 b-right">
                <div className="flex-box center">
                  <div className="centerAlign">
                    <PoidsImpulsionVert
                      height="2em"
                      width="2em"
                      stroke="#31c6b3"
                      fill="#31c6b3"
                      strokeWidth="0"
                    />{" "}
                  </div>
                  <div className="centerAlign">
                    <span>{t("all.meter.pulse_weight")}</span>
                    <h3>{infoGeneral.model.pulseWeight}</h3>
                  </div>
                </div>
              </div>
              <div className="c20 b-right">
                <div className="flex-box center">
                  <div className="centerAlign">
                    <ModeleVert
                      height="2em"
                      width="2em"
                      stroke="#31c6b3"
                      fill="#31c6b3"
                      strokeWidth="0"
                    />{" "}
                  </div>
                  <div className="centerAlign">
                    <span>{t("all.meter.wheels_number")}</span>
                    <h3>
                      {_.get(infoGeneral, "revisedWheels") ||
                        _.get(infoGeneral, "model.wheels")}
                    </h3>
                  </div>
                </div>
              </div>
              <div className="c20 b-right">
                <div className="flex-box center">
                  <FabricantVert
                    height="2em"
                    width="2em"
                    stroke="#31c6b3"
                    fill="#31c6b3"
                    strokeWidth="0"
                  />{" "}
                  <div>
                    <span>{t("all.meter.meter_manufacturer")}</span>
                    <h3>
                      {infoGeneral.model.manufacturer.name === "UNKNOWN"
                        ? t("all.text.unknown")
                        : infoGeneral.model.manufacturer.name}
                    </h3>
                  </div>
                </div>
              </div>
              <div className="c20 right">
                <div className="flex-box center">
                  <SvgCompteurVert
                    height="2em"
                    width="2em"
                    stroke="#31c6b3"
                    fill="#31c6b3"
                    strokeWidth="0"
                  />{" "}
                  <div>
                    <span>{t("all.meter.meter_model")}</span>
                    {infoGeneral.model.model ? (
                      <h3>{infoGeneral.model.model}</h3>
                    ) : (
                      <NoFilled />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row-meter-bar" style={{ padding: 0 }}>
            {!withoutDateComponent && <TeleReleveInfo type="METER" />}
            <div className="col-lg-4">
              {displayLinkToPdi && infoGeneral.pdi && (
                <div className="clean-meter-bar">
                  <div className="flex-box">
                    <div className="c50 background-texture left">
                      <>
                        <span>{t("all.pdi.link_pdi_ref")}</span>
                        {infoGeneral.pdi.reference ? (
                          <h3>{infoGeneral.pdi.reference}</h3>
                        ) : (
                          <NoFilled />
                        )}
                      </>
                    </div>
                    <div className="c50 linkpdi">
                      <div className="flex-box center">
                        <Link
                          className="infoDisplay"
                          to={{ pathname: linkToPDI }}
                        >
                          {t("all.round.go_to_record")}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {infoGeneral.startDate && (
                <div className="clean-meter-bar one">
                  <div className="flex-box">
                    <div className="c50 background-texture left">
                      <h3 style={{ margin: 0, textAlign: "center" }}>
                        {t("all.text.install_date")}
                      </h3>
                    </div>
                    <div className="c50 right">
                      <div className="flex-box center infoDisplay">
                        {infoGeneral.startDate ? (
                          ListTools.formatDateDay(infoGeneral.startDate)
                        ) : (
                          <NoFilled />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {infoGeneral.endDate && (
                <div className="clean-meter-bar one">
                  <div className="flex-box">
                    <div className="c50 background-texture left">
                      <h3 style={{ margin: 0, textAlign: "center" }}>
                        {t("all.text.uninstall_date")}
                      </h3>
                    </div>
                    <div className="c50 right">
                      <div className="center infoDisplay">
                        {infoGeneral.endDate ? (
                          ListTools.formatDateDay(infoGeneral.endDate)
                        ) : (
                          <NoFilled />
                        )}
                        <span style={{ display: "block" }}>
                          {t("all.meter.index")}: {infoGeneral?.endRead?.index}{" "}
                          m<sup>3</sup>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {infoGeneral.informations && (
                <div>
                  <div className="table-info-container">
                    <h2>
                      <span>
                        <InformationsBleu
                          height="1em"
                          width="1em"
                          fill="#31c6b3"
                        />
                      </span>
                      {t("all.text.information_plural")}
                    </h2>
                    <div className="information-container">
                      <span
                        className="infoDisplayFit"
                        style={{ whiteSpace: "pre" }}
                      >
                        {ListTools.formatInformationsClpMtr(
                          infoGeneral.informations
                        )}
                      </span>
                      <div className={"message-container"}>
                        <p className="marginLine">
                          <div className="flex-box">
                            <span className="infoLibelle">
                              {t("all.text.message")}
                            </span>
                            {couldBeEdit() &&
                              _.get(tournee, "editInfos.messages") && (
                                <div
                                  className="svg-box"
                                  onClick={() => setEdit("message")}
                                >
                                  <EditionBleu height="1em" fill="black" />
                                </div>
                              )}
                          </div>
                          {edit === "message" ? (
                            <div className="flex-box">
                              <StyledSelect
                                optionList={_.get(
                                  tournee,
                                  `editInfos.messages`,
                                  []
                                ).map(({ code, label }) => ({
                                  label,
                                  value: code,
                                }))}
                                placeholder={t("all.text.message")}
                                handleChange={(e) => setEditableFieldValue(e)}
                                value={
                                  editableFieldValue ||
                                  _.get(tournee, "editInfos.messages", []).find(
                                    ({ label }) =>
                                      label ===
                                      (typeof infoGeneral.tag === "string"
                                        ? infoGeneral.tag
                                        : _.get(infoGeneral, "tag.text"))
                                  ) ||
                                  {}
                                }
                              />
                              <div className="button-group">
                                <div
                                  className="sticky-button"
                                  onClick={() => {
                                    setEditableFieldValue(null);
                                    setEdit(null);
                                  }}
                                >
                                  <span>{t("all.button.cancel")}</span>
                                  <SvgCroixNoir height="1.7em" />
                                </div>
                                <div
                                  className="sticky-button"
                                  onClick={validChange}
                                >
                                  <span>{t("all.button.register")}</span>
                                  <SvgValidation height="1em" />
                                </div>
                              </div>
                            </div>
                          ) : (
                            <span className="infoDisplay">
                              {typeof infoGeneral.tag === "string"
                                ? infoGeneral.tag
                                : _.get(infoGeneral, "tag.text")}
                            </span>
                          )}
                        </p>
                        <p className="marginLine">
                          <div className="flex-box">
                            <span className="infoLibelle">
                              {t("all.text.particular_msg")}
                            </span>
                            {couldBeEdit() && (
                              <div
                                className="svg-box"
                                onClick={() => {
                                  setEdit("freeMessage");
                                  setEditableFieldValue(infoGeneral.note);
                                }}
                              >
                                <EditionBleu height="1em" fill="black" />
                              </div>
                            )}
                          </div>
                          {edit === "freeMessage" ? (
                            <div className="flex-box">
                              <StyledInput
                                type="text"
                                maxlength={30}
                                value={editableFieldValue}
                                handleChange={(e: any) =>
                                  setEditableFieldValue(
                                    _.get(e, "target.value")
                                  )
                                }
                              />
                              <div className="button-group">
                                <div
                                  className="sticky-button"
                                  onClick={() => {
                                    setEditableFieldValue(null);
                                    setEdit(null);
                                  }}
                                >
                                  <span>{t("all.button.cancel")}</span>
                                  <SvgCroixNoir height="1.7em" />
                                </div>
                                <div
                                  className="sticky-button"
                                  onClick={validChange}
                                >
                                  <span>{t("all.button.register")}</span>
                                  <SvgValidation height="1em" />
                                </div>
                              </div>
                            </div>
                          ) : (
                            <span className="infoDisplay">
                              {infoGeneral.note}
                            </span>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <br />
                </div>
              )}
              {lastRead && Object.keys(lastRead).length > 0 && (
                <div>
                  <div className="table-info-container">
                    <h2>
                      <span>
                        <DerniereReleveVert
                          height="1em"
                          width="1em"
                          fill="#31c6b3"
                        />
                      </span>
                      {t("all.read_meter.last_read")}
                    </h2>
                    {moment(lastRead.date).isValid() ? (
                      <div className="information-container">
                        <p>
                          <span className="infoLibelle">
                            {t("all.read_meter.read_last_date")}
                          </span>{" "}
                          <br /> &nbsp;{" "}
                          <span className="infoDisplay">
                            {moment(lastRead.date).format("DD/MM/YYYY HH:mm")}{" "}
                          </span>
                        </p>
                        <span className="infoLibelle">
                          {t("all.meter.index")}
                        </span>
                        &nbsp;{" "}
                        {generateIndexDisplay(
                          lastRead.index,
                          infoGeneral.revisedWheels || infoGeneral.model.wheels,
                          moment(lastRead.date).format(
                            t("all.date_format.date_and_time")
                          ),
                          "Dernière relève",
                          nbDec
                        )}
                        <div
                          style={{
                            paddingTop: "20px",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p>
                            <span className="infoLibelle">
                              {t("all.read_meter.cons")}{" "}
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: t(
                                    `meter.unit.${_.get(
                                      infoGeneral,
                                      "fluidType"
                                    )}`,
                                    {
                                      interpolation: { escapeValue: false },
                                    }
                                  ),
                                }}
                              />
                            </span>
                            <br />
                            <span className="infoDisplay">
                              {Number.isFinite(lastRead.consumption) ? (
                                lastRead.consumption.toFixed(nbDec)
                              ) : (
                                <NoFilled />
                              )}
                            </span>
                          </p>
                          <div>
                            <span className="infoLibelle">
                              {t("all.read_meter.daily_avg_cons")}{" "}
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: t(
                                    `meter.unit.${_.get(
                                      meter,
                                      "general.fluidType"
                                    )}`,
                                    {
                                      interpolation: { escapeValue: false },
                                    }
                                  ),
                                }}
                              />
                            </span>
                            <br />
                            <span className="infoDisplay">
                              {Number.isFinite(
                                _.get(currentRead, "meterIndex")
                              ) &&
                              currentRead &&
                              Number.isFinite(
                                _.get(previousRead, "meterIndex")
                              ) ? (
                                (
                                  (_.get(currentRead, "meterIndex") -
                                    _.get(previousRead, "meterIndex")) /
                                  daysBtwCurrentAndLastRead
                                ).toFixed(nbDec)
                              ) : (
                                <NoFilled />
                              )}
                            </span>
                            <br />
                            {daysBtwCurrentAndLastRead >= 0 &&
                              Number.isFinite(
                                _.get(currentRead, "meterIndex")
                              ) &&
                              currentRead &&
                              Number.isFinite(
                                _.get(previousRead, "meterIndex")
                              ) && (
                                <span className="infoLibelle">
                                  (
                                  {t("all.read_meter.daily_avg_calc_info", {
                                    totalDays: daysBtwCurrentAndLastRead,
                                  })}
                                  )
                                </span>
                              )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      t("all.read_meter.no_current_read")
                    )}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "end",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          width: "fit-content",
                          color: "lightgrey",
                          margin: "0 10px",
                        }}
                      >
                        {_.get(fullPdi, "fetchedPdi.general.updateDate") ? (
                          <p style={{ margin: 0 }}>
                            <span>{t("all.date.updated_on")}</span>
                            <span
                              style={{ fontWeight: "bolder", fontSize: 14 }}
                            >
                              {format(
                                _.get(fullPdi, "fetchedPdi.general.updateDate"),
                                "dd/MM/yyyy à HH:mm"
                              )}
                            </span>
                          </p>
                        ) : (
                          <span>Aucune date de mise à jour</span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <br />
              {historic && <Fragment>{historic}</Fragment>}
            </div>
            <div className="col-lg-8">{intervalSelector}</div>
          </div>
          <div style={{ marginBottom: "20px", display: "flow-root" }}>
            <GaleryPhoto meterSerials={[infoGeneral.serial]} />
          </div>
        </>
      )}
    </Fragment>
  );
}

function mapStateToProps(state: any) {
  const { authentication, locations, meters, pdis, tournee, users } = state;
  const { user } = authentication;
  const { fetchedPdi } = pdis;
  return {
    user,
    locations,
    meters,
    fetchedPdi,
    tournee,
    users,
  };
}

MeterWrapped.defaultProps = {
  infoGeneral: {
    pdi: {},
    model: {
      manufacturer: {},
    },
  },
  loading: false,
  error: "",
  gps: {},
  lastRead: {},
};

const connectedMeterWrapped = compose<ComponentType<any>>(
  withRouter,
  connect(mapStateToProps)
)(MeterWrapped);
export default withTranslation()(connectedMeterWrapped);
