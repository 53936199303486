import svgBlockBleu from "./alarme_arret_compteur_bleu.svg";
import svgLowBatteryBleu from "./alarme_batterie_bleu.svg";
import svgErrorInstallBleu from "./alarme_erreur_installation_bleu.svg";
import svgFraudBleu from "./alarme_fraude_bleu.svg";
import svgLeakBleu from "./alarme_fuite_bleu.svg";
import svgClockBleu from "./alarme_horloge_bleu.svg";
import svgBackflowBleu from "./alarme_retour_eau_bleu.svg";
import svgUnderflowBleu from "./alarme_sous_debit_bleu.svg";
import svgOverflowBleu from "./alarme_sur_debit_bleu.svg";
import svgReversedMeterBleu from "./alarme_compteur_inverse_bleu.svg";
import svgTemperatureBasseBleu from "./alarme_temperature_basse_bleu.svg";
import svgTemperatureHauteBleu from "./alarme_temperature_haute_bleu.svg";

import svgBlockOrange from "./alarme_arret_compteur_orange.svg";
import svgLowBatteryOrange from "./alarme_batterie_orange.svg";
import svgErrorInstallOrange from "./alarme_erreur_installation_orange.svg";
import svgFraudOrange from "./alarme_fraude_orange.svg";
import svgLeakOrange from "./alarme_fuite_orange.svg";
import svgClockOrange from "./alarme_horloge_orange.svg";
import svgBackflowOrange from "./alarme_retour_eau_orange.svg";
import svgUnderflowOrange from "./alarme_sous_debit_orange.svg";
import svgOverflowOrange from "./alarme_sur_debit_orange.svg";
import svgReversedMeterOrange from "./alarme_compteur_inverse_orange.svg";
import svgTemperatureBasseOrange from "./alarme_temperature_basse_orange.svg";
import svgTemperatureHauteOrange from "./alarme_temperature_haute_orange.svg";

import svgBlockGris from "./alarme_arret_compteur_gris.svg";
import svgLowBatteryGris from "./alarme_batterie_gris.svg";
import svgErrorInstallGris from "./alarme_erreur_installation_gris.svg";
import svgFraudGris from "./alarme_fraude_gris.svg";
import svgLeakGris from "./alarme_fuite_gris.svg";
import svgClockGris from "./alarme_horloge_gris.svg";
import svgBackflowGris from "./alarme_retour_eau_gris.svg";
import svgUnderflowGris from "./alarme_sous_debit_gris.svg";
import svgOverflowGris from "./alarme_sur_debit_gris.svg";
import svgReversedMeterGris from "./alarme_compteur_inverse_gris.svg";
import svgTemperatureBasseGris from "./alarme_temperature_basse_gris.svg";
import svgTemperatureHauteGris from "./alarme_temperature_haute_gris.svg";

export default function (upperType) {
  if (upperType.includes("CURRENT")) {
    if (upperType.includes("METERBLOCK")) {
      return svgBlockOrange;
    }
    if (upperType.includes("LEAK")) {
      return svgLeakOrange;
    }
    if (upperType.includes("BATTERY")) {
      return svgLowBatteryOrange;
    }
    if (upperType.includes("MODULESETTING")) {
      return svgErrorInstallOrange;
    }
    if (upperType.includes("FRAUD")) {
      return svgFraudOrange;
    }
    if (upperType.includes("CLOCK")) {
      return svgClockOrange;
    }
    if (upperType.includes("BACKFLOW")) {
      return svgBackflowOrange;
    }
    if (upperType.includes("UNDERFLOW")) {
      return svgUnderflowOrange;
    }
    if (upperType.includes("OVERFLOW")) {
      return svgOverflowOrange;
    }
    if (upperType.includes("REVERSEDMETER")) {
      return svgReversedMeterOrange;
    }
    if (upperType.includes("TEMPERATURE.HIGH")) {
      return svgTemperatureHauteOrange;
    }
    if (upperType.includes("TEMPERATURE.LOW")) {
      return svgTemperatureBasseOrange;
    }
  }
  if (upperType.includes("PAST")) {
    if (upperType.includes("METERBLOCK")) {
      return svgBlockGris;
    }
    if (upperType.includes("LEAK")) {
      return svgLeakGris;
    }
    if (upperType.includes("BATTERY")) {
      return svgLowBatteryGris;
    }
    if (upperType.includes("MODULESETTING")) {
      return svgErrorInstallGris;
    }
    if (upperType.includes("FRAUD")) {
      return svgFraudGris;
    }
    if (upperType.includes("CLOCK")) {
      return svgClockGris;
    }
    if (upperType.includes("BACKFLOW")) {
      return svgBackflowGris;
    }
    if (upperType.includes("UNDERFLOW")) {
      return svgUnderflowGris;
    }
    if (upperType.includes("OVERFLOW")) {
      return svgOverflowGris;
    }
    if (upperType.includes("REVERSEDMETER")) {
      return svgReversedMeterGris;
    }
    if (upperType.includes("TEMPERATURE.HIGH")) {
      return svgTemperatureHauteGris;
    }
    if (upperType.includes("TEMPERATURE.LOW")) {
      return svgTemperatureBasseGris;
    }
  }
  if (upperType.includes("METERBLOCK")) {
    return svgBlockBleu;
  }
  if (upperType.includes("LEAK")) {
    return svgLeakBleu;
  }
  if (upperType.includes("BATTERY")) {
    return svgLowBatteryBleu;
  }
  if (upperType.includes("MODULESETTING")) {
    return svgErrorInstallBleu;
  }
  if (upperType.includes("FRAUD")) {
    return svgFraudBleu;
  }
  if (upperType.includes("CLOCK")) {
    return svgClockBleu;
  }
  if (upperType.includes("BACKFLOW")) {
    return svgBackflowBleu;
  }
  if (upperType.includes("UNDERFLOW")) {
    return svgUnderflowBleu;
  }
  if (upperType.includes("OVERFLOW")) {
    return svgOverflowBleu;
  }
  if (upperType.includes("REVERSEDMETER")) {
    return svgReversedMeterBleu;
  }
  if (upperType.includes("TEMPERATURE.HIGH")) {
    return svgTemperatureHauteBleu;
  }
  if (upperType.includes("TEMPERATURE.LOW")) {
    return svgTemperatureBasseBleu;
  }
  return "";
}
