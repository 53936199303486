import _ from "lodash";
import synchroComConstants from "../_constants/synchrocom.constants";
import synchroConstants from "../_constants/synchro.constants";
import synchrocomConstants from "../_constants/synchrocom.constants";

export default function synchrocom(
  state: any = {
    info: {},
    loadingList: [],
    roundLoading: [],
    rounds: {},
    multi: false,
    multiList: [],
    count: 0,
  },
  action: any
) {
  let forceRefreshCopy: any;
  let tampCopy: any;
  let copyList: any;
  const locked = {};
  switch (action.type) {
    case synchroComConstants.GET_ROUNDS_SUCCESS:
      if (state.count >= 1) {
        state.loadingLock = false;
      }
      state.count += 1;
      return {
        ...state,
        roundsLoading: action.rounds,
      };
    case synchroComConstants.GET_ROUNDS_FAILURE:
      return {
        ...state,
        error: "Error with rounds",
      };
    case synchroConstants.GETALLROUND_FULL_SUCCESS:
      const mergeData = _.merge(
        _.cloneDeep(state.rounds) || {},
        action.rounds || {}
      );
      return {
        ...state,
        rounds: mergeData,
      };
    case synchroComConstants.GETLABELS_REQUEST:
      return {
        ...state,
      };
    case synchroComConstants.GETLABELS_SUCCESS:
      if (state.labels !== action.labels) {
        state.labels = action.labels;
        return {
          ...state,
          loading: false,
        };
      }
      break;
    case synchroComConstants.GETLABELS_FAILURE:
      return {
        ...state,
        error: "Error on labels",
      };
    case synchroComConstants.ADDAFFECT_REQUEST:
      forceRefreshCopy = _.cloneDeep(state.labels);
      tampCopy = _.cloneDeep(state.labels);
      action.affect.forEach((round: any) => {
        if (
          !forceRefreshCopy.affect.find(
            (label: any) =>
              label.device === action.deviceId && label.tournee === round
          )
        ) {
          forceRefreshCopy.affect.unshift({
            tournee: round,
            device: action.deviceId,
            type: "affect",
            partialOption: false,
          });
        }
      });
      return {
        ...state,
        labels: forceRefreshCopy,
      };
    case synchroComConstants.ADDAFFECT_SUCCESS:
      return {
        ...state,
      };
    case synchroComConstants.ADDAFFECT_FAILURE:
      return {
        ...state,
        error: "Error with assignment",
        labels: tampCopy,
      };
    case synchroComConstants.DELAFFECT_REQUEST:
      forceRefreshCopy = _.cloneDeep(state.labels);
      tampCopy = _.cloneDeep(state.labels);
      forceRefreshCopy.affect = forceRefreshCopy.affect.filter(
        (label: any) =>
          !(
            label.device === action.deviceId &&
            action.affect.includes(label.tournee)
          )
      );
      return {
        ...state,
        labels: forceRefreshCopy,
      };
    case synchroComConstants.DELAFFECT_SUCCESS:
      return {
        ...state,
      };
    case synchroComConstants.DELAFFECT_FAILURE:
      return {
        ...state,
        error: "Error with assignment",
        labels: tampCopy,
      };
    case synchroComConstants.ADDDESAFFECT_REQUEST:
      forceRefreshCopy = _.cloneDeep(state.labels);
      tampCopy = _.cloneDeep(state.labels);
      action.disaffect.forEach((round: any) =>
        forceRefreshCopy.disaffect.unshift({
          tournee: round,
          device: action.deviceId,
          type: "disaffect",
          partialOption: false,
        })
      );
      forceRefreshCopy.loaded = forceRefreshCopy.loaded.filter(
        (el: any) =>
          !(
            el.device === action.deviceId &&
            action.disaffect.includes(el.tournee)
          )
      );
      return {
        ...state,
        labels: forceRefreshCopy,
      };
    case synchroComConstants.ADDDESAFFECT_SUCCESS:
      return {
        ...state,
      };
    case synchroComConstants.ADDDESAFFECT_FAILURE:
      return {
        ...state,
        error: "Error with decommissioning",
        labels: tampCopy,
      };
    case synchroComConstants.DELDESAFFECT_REQUEST:
      forceRefreshCopy = _.cloneDeep(state.labels);
      tampCopy = _.cloneDeep(state.labels);
      forceRefreshCopy.disaffect = _.cloneDeep(
        forceRefreshCopy
      ).disaffect.filter(
        (label: any) =>
          !(
            label.device === action.deviceId &&
            action.disaffect.includes(label.tournee)
          )
      );
      action.disaffect.forEach((round: string) => {
        forceRefreshCopy.loaded.unshift({
          tournee: round,
          device: action.deviceId,
          type: "disaffect",
          partialOption: false,
        });
      });
      return {
        ...state,
        labels: forceRefreshCopy,
      };
    case synchroComConstants.DELDESAFFECT_SUCCESS:
      return {
        ...state,
      };
    case synchroComConstants.DELDESAFFECT_FAILURE:
      return {
        ...state,
        error: "Error del decommissioning",
        labels: tampCopy,
      };
    case synchroComConstants.CANCEL_DEVICE_REQUEST:
      forceRefreshCopy = _.cloneDeep(state.labels);
      tampCopy = _.cloneDeep(state.labels);
      forceRefreshCopy.disaffect = _.cloneDeep(state.labels.disaffect).filter(
        (el: any) => el.device !== action.deviceId
      );
      forceRefreshCopy.affect = _.cloneDeep(state.labels.affect).filter(
        (el: any) => el.device !== action.deviceId
      );
      forceRefreshCopy.loaded = _.concat(
        state.labels.loaded,
        _.cloneDeep(
          state.labels.disaffect.filter(
            (el: any) => el.device === action.deviceId
          )
        )
      );
      return {
        ...state,
        labels: forceRefreshCopy,
      };
    case synchroComConstants.CANCEL_DEVICE_SUCCESS:
      return {
        ...state,
      };
    case synchroComConstants.CANCEL_DEVICE_FAILURE:
      return {
        error: "Error cancel device",
        labels: tampCopy,
      };
    case synchroComConstants.CANCEL_ROUND_REQUEST:
      forceRefreshCopy = _.cloneDeep(state.labels);
      tampCopy = _.cloneDeep(state.labels);
      forceRefreshCopy.disaffect = _.cloneDeep(state.labels.disaffect).filter(
        (el: any) => el.tournee !== action.rndCode
      );
      forceRefreshCopy.affect = _.cloneDeep(state.labels.affect).filter(
        (el: any) => el.tournee !== action.rndCode
      );
      forceRefreshCopy.loaded = _.concat(
        state.labels.loaded,
        _.cloneDeep(
          state.labels.disaffect.filter(
            (el: any) => el.tournee === action.rndCode
          )
        )
      );
      return {
        ...state,
        labels: forceRefreshCopy,
      };
    case synchroComConstants.CANCEL_ROUND_SUCCESS:
      return {
        ...state,
      };
    case synchroComConstants.CANCEL_ROUND_FAILURE:
      return {
        ...state,
        error: "Error cancel round",
        labels: tampCopy,
      };
    case synchroComConstants.GETCURRENTVERSION_REQUEST:
      return {
        ...state,
      };
    case synchroComConstants.GETCURRENTVERSION_SUCCESS:
      if (state && state.version) {
        state.version = _.merge(action.version, state.version);
        return {
          ...state,
        };
      }
      return {
        version: action.version,
        ...state,
      };

    case synchroComConstants.GETCURRENTVERSION_FAILURE:
      return {
        error: "Error version",
      };
    case synchroComConstants.GETMINIVERSION_REQUEST:
      return {
        ...state,
      };
    case synchroComConstants.GETMINIVERSION_SUCCESS:
      if (state && state.version) {
        state.version = _.merge(action.version, state.version);
        return {
          ...state,
        };
      }
      return {
        version: action.version,
        ...state,
      };
    case synchroComConstants.GETMINIVERSION_FAILURE:
      return {
        error: "Error version",
      };
    case synchroComConstants.SWITCH_PARTIAL_REQUEST:
      forceRefreshCopy = _.cloneDeep(state.labels);
      tampCopy = _.cloneDeep(state.labels);
      forceRefreshCopy.affect.forEach((label: any) => {
        if (
          action.rndList.includes(label.tournee) &&
          action.deviceId === label.device
        ) {
          label.partialOption = !label.partialOption;
        }
      });
      return {
        ...state,
        labels: forceRefreshCopy,
      };
    case synchroComConstants.SWITCH_PARTIAL_SUCCESS:
      return {
        ...state,
      };
    case synchroComConstants.SWITCH_PARTIAL_FAILURE:
      return {
        ...state,
        error: "Error partial",
        labels: tampCopy,
      };
    case synchroComConstants.CANCEL_ALL_REQUEST:
      forceRefreshCopy = _.cloneDeep(state.labels);
      tampCopy = _.cloneDeep(state.labels);
      state.labels.affect = [];
      state.labels.disaffect = [];
      forceRefreshCopy.disaffect.forEach((label: any) => {
        const copy = _.cloneDeep(label);
        copy.type = "loaded";
        state.labels.loaded.push(copy);
      });
      return {
        ...state,
      };
    case synchroComConstants.CANCEL_ALL_SUCCESS:
      return {
        ...state,
      };
    case synchroComConstants.CANCEL_ALL_FAILURE:
      return {
        error: "error decommissioning",
        labels: tampCopy,
      };
    case synchroComConstants.COM_DEVICE_REQUEST:
      copyList = _.cloneDeep(state.loadingList);
      console.log(copyList);
      action.idList.forEach((el) => {
        locked[el] = true;
        copyList.push(el);
      });
      copyList.push(action.deviceId);
      return {
        ...state,
        loadingList: copyList,
        loadingLock: locked,
      };
    case synchroComConstants.COM_DEVICE_SUCCESS:
      copyList = _.cloneDeep(state.loadingList);
      forceRefreshCopy = _.cloneDeep(state.labels);
      copyList = copyList.filter(
        (el) => el !== action.deviceId && !action.idList.includes(el)
      );
      forceRefreshCopy.affect
        .filter((label: any) => label.device === action.deviceId)
        .forEach((el: any) => {
          const copy = _.cloneDeep(el);
          copy.type = "loaded";
          forceRefreshCopy.loaded.push(copy);
        });
      forceRefreshCopy.affect = forceRefreshCopy.affect.filter(
        (label: any) => label.device !== action.deviceId
      );
      forceRefreshCopy.disaffect = forceRefreshCopy.disaffect.filter(
        (label: any) => label.device !== action.deviceId
      );

      return {
        ...state,
        loadingList: copyList,
        labels: forceRefreshCopy,
        count: 0,
      };
    case synchroComConstants.COM_DEVICE_FAILURE:
      return {
        ...state,
        loadingLock: false,
        loadingList: [],
        errorList: action?.error?.deviceSerial
          ? null
          : Object.values(action.error).flat(),
      };
    case synchroComConstants.COM_ROUND_REQUEST:
      copyList = _.cloneDeep(state.loadingList);
      action.idList.forEach((el) => {
        locked[el] = true;
        copyList.push(el);
      });
      copyList.push(action.rndCode);
      locked[action.rndCode] = true;
      return {
        ...state,
        loadingList: copyList,
        loadingLock: locked,
      };
    case synchroComConstants.COM_ROUND_SUCCESS:
      copyList = _.cloneDeep(state.loadingList);
      forceRefreshCopy = _.cloneDeep(state.labels);
      copyList = copyList.filter(
        (el) => el !== action.rndCode && !action.idList.includes(el)
      );
      forceRefreshCopy.affect
        .filter((label: any) => label.tournee === action.rndCode)
        .forEach((el: any) => {
          const copy = _.cloneDeep(el);
          copy.type = "loaded";
          forceRefreshCopy.loaded.push(copy);
        });
      forceRefreshCopy.affect = forceRefreshCopy.affect.filter(
        (label: any) => label.tournee !== action.rndCode
      );
      forceRefreshCopy.disaffect = forceRefreshCopy.disaffect.filter(
        (label: any) => label.tournee !== action.rndCode
      );
      return {
        ...state,
        loadingList: copyList,
        labels: forceRefreshCopy,
        count: 0,
      };
    case synchroComConstants.COM_ROUND_FAILURE:
      return {
        ...state,
        loadingLock: false,
        loadingList: [],
        errorList: action.error,
      };
    case synchroConstants.COM_ALL_REQUEST:
      copyList = _.cloneDeep(state.loadingList);
      const idList = _.concat(
        _.uniq(
          _.concat(
            state.labels.affect.map((el) => el.device),
            state.labels.disaffect.map((el) => el.device)
          )
        ),
        _.uniq(
          _.concat(
            state.labels.affect.map((el) => el.tournee),
            state.labels.disaffect.map((el) => el.tournee)
          )
        )
      );
      idList.forEach((el) => {
        locked[el] = true;
      });
      return {
        ...state,
        loadingList: idList,
        loadingLock: locked,
      };
    case synchroConstants.COM_ALL_SUCCESS:
      return {
        ...state,
        loadindList: [],
      };
    case synchroConstants.COM_ALL_FAILURE:
      return {
        ...state,
        loadingLock: false,
        loadingList: [],
        errorList: Object.values(action.error).flat(),
      };
    case synchroConstants.SYNCHRODEVICE_REQUEST:
      copyList = _.cloneDeep(state.loadingList);
      action.idList.forEach((el) => {
        copyList.push(el);
        locked[el] = true;
      });
      copyList.push(action.deviceId);
      return {
        ...state,
        loadingList: copyList,
        loadingLock: locked,
      };
    case synchroConstants.SYNCHRODEVICE_SUCCESS:
      copyList = _.cloneDeep(state.loadingList);
      copyList = copyList.filter(
        (el) => el !== action.deviceId && !action.idList.includes(el)
      );
      return {
        ...state,
        loadingList: copyList,
      };
    case synchroConstants.SYNCHRODEVICE_FAILURE:
      return {
        error: "Error synchronization",
      };
    case synchroConstants.SYNCHROROUND_REQUEST:
      return {
        ...state,
      };
    case synchroConstants.SYNCHROROUND_SUCCESS:
      return {
        ...state,
      };
    case synchroConstants.SYNCHROROUND_FAILURE:
      return {
        error: "Error synchronization",
      };
    case synchroComConstants.DISAFFECT_ALL_REQUEST:
      state.labels.loaded = _.cloneDeep(state.labels.loaded).filter(
        (el: any) => {
          if (el.device === action.serial) {
            el.type = "disaffect";
            state.labels.disaffect.push(el);
          }
          return el.device !== action.serial;
        }
      );
      return {
        ...state,
      };
    case synchroComConstants.DISAFFECT_ALL_SUCCESS:
      return {
        ...state,
      };
    case synchroComConstants.DISAFFECT_ALL_FAILURE:
      return {
        error: "Error decommissioning",
        ...state,
      };
    case synchroComConstants.DISAFFECT_ALL_FOR_ROUND_REQUEST:
      forceRefreshCopy = state.labels;
      forceRefreshCopy.loaded = _.cloneDeep(state.labels.loaded).filter(
        (el: any) => {
          if (el.tournee === action.rndCode) {
            el.type = "disaffect";
            state.labels.disaffect.push(el);
          }
          return el.tournee !== action.rndCode;
        }
      );
      return {
        ...state,
        labels: forceRefreshCopy,
      };
    case synchroComConstants.DISAFFECT_ALL_FOR_ROUND_SUCCESS:
      return {
        ...state,
      };
    case synchroComConstants.DISAFFECT_ALL_FOR_ROUND_FAILURE:
      return {
        error: "Error with decommissioning",
        ...state,
      };
    case synchroComConstants.ROTATE_ROUND_REQUEST:
      copyList = _.cloneDeep(state.loadingList);
      const copyRoundLoading = _.cloneDeep(state.roundLoading);
      copyList.push(action.roundCode);
      if (!state.labels.loaded.find((el) => el.tournee === action.roundCode)) {
        copyRoundLoading.push(action.roundCode);
      }
      copyList.forEach((el) => {
        locked[el] = true;
      });
      return {
        ...state,
        loadingList: copyList,
        roundLoading: copyRoundLoading,
        loadingLock: locked,
      };
    case synchroComConstants.ROTATE_ROUND_SUCCESS:
      copyList = _.cloneDeep(state.loadingList).filter(
        (el: any) => el !== action.roundCode
      );
      const copyRoundLoadingSuccess = _.cloneDeep(state.roundLoading).filter(
        (el) => el !== action.roundCode
      );
      return {
        ...state,
        loadingList: copyList,
        roundLoading: copyRoundLoadingSuccess,
        count: 0,
      };
    case synchroComConstants.ROTATE_ROUND_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case synchroComConstants.DRAG_START_SUCCESS:
      return {
        ...state,
        drag: action.id,
        column: action.column,
      };
    case synchroComConstants.DRAG_END_SUCCESS:
      return {
        ...state,
        drag: null,
        column: null,
        multi: null,
        multiList: null,
      };
    case synchroComConstants.MULTI_DRAG_SUCCESS:
      return {
        ...state,
        multi: action.on || !state.multi,
        multiList: [],
      };
    case synchroComConstants.SET_MULTI_SUCCESS:
      forceRefreshCopy = _.cloneDeep(state.multiList);
      if (!action.alreadyIn) {
        forceRefreshCopy = _.cloneDeep(state.multiList).filter(
          (taskId: string) => taskId !== action.id
        );
      } else {
        forceRefreshCopy.unshift(action.id);
      }
      return {
        ...state,
        multi: forceRefreshCopy.length > 0 ? action.col : true,
        multiList: forceRefreshCopy,
      };

    case synchroComConstants.UPDATELOADER_SUCCESS:
      // state.currentState[action.deviceId] = true;
      return {
        ...state,
      };
    case synchroComConstants.GETCOMMUNICATION_REQUEST:
      return {
        ...state,
      };
    case synchroComConstants.GETCOMMUNICATION_SUCCESS:
      if (action.currentState.find((el: any) => el.hasCommunication)) {
        state.inCom = true;
      } else {
        if (state.inCom) {
          state.loadingLock = false;
        }
        state.inCom = false;
      }
      return {
        ...state,
      };
    case synchroComConstants.GETCOMMUNICATION_FAILURE:
      return {
        ...state,
        error: "Error: device state",
      };
    case synchroComConstants.REMOVE_DEVICES_ERROR_REQUEST:
      return {
        ...state,
      };
    case synchroComConstants.REMOVE_DEVICES_ERROR_SUCCESS:
      return {
        ...state,
        errorList: action.devices,
      };
    case synchroComConstants.REMOVE_DEVICES_ERROR_FAILURE:
      return {
        ...state,
        error: "Action impossible",
      };
    case synchroConstants.GET_DEVICES_ERROR_REQUEST:
      return {
        ...state,
      };
    case synchroConstants.GET_DEVICES_ERROR_SUCCESS:
      return {
        ...state,
        errorList: action.devices,
      };
    case synchroConstants.GET_DEVICES_ERROR_FAILURE:
      return {
        ...state,
        error: "Problème de récupération des erreurs",
      };
    case synchroComConstants.SET_MODE_SUCCESS:
      return {
        ...state,
        mode: action.mode,
      };
    case synchrocomConstants.UNLOCK_LOADING:
      return {
        ...state,
        loadingLock: false,
      };
    case synchroComConstants.CLEAR:
      return {};
    case "CLEAR_ERROR":
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
}
