import React, { ComponentType, useRef, useState } from "react";
import {
  Button,
  Col,
  PopoverBody,
  PopoverHeader,
  UncontrolledPopover,
  UncontrolledTooltip,
} from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import _ from "lodash";
import SvgSynchroHeader from "../../../SvgComponents/SynchroHeader";
import SvgInfoVert from "../../../SvgComponents/InformationVert";
import SvgRotationIndex from "../../../SvgComponents/RotationIndex";
import synchrocomActions from "../../../_actions/synchrocom.actions";
import { compose } from "redux";

const TaskButtons = (props: any) => {
  const { task, match, history, t, tournee, dispatch, labels, synchro } = props;
  const { params } = match;
  const { GestionnaireId } = params;

  const roundWithOnlineDevice =
    labels &&
    labels.loaded
      .filter((el: any) => el.tournee === task.rndCode)
      .map((el: any) => synchro.devices[el.device])
      .find((el: any) => el?.online === true);

  const onRotationClick = () => {
    dispatch(synchrocomActions.rotateRound(task.id, params.GestionnaireId));
    document.body.click();
  };

  const synchronisation = () => {
    if (task.online || roundWithOnlineDevice) {
      task.rndCode
        ? dispatch(
            synchrocomActions.synchroRound(
              task.id,
              GestionnaireId,
              _.concat(
                labels.affect
                  .filter((label: any) => label.tournee === task.id)
                  .map((label: any) => label.device),
                labels.disaffect
                  .filter((label: any) => label.tournee === task.id)
                  .map((label: any) => label.device)
              )
            )
          )
        : dispatch(
            synchrocomActions.synchroDevice(
              task.id,
              GestionnaireId,
              _.concat(
                labels.affect
                  .filter((label: any) => label.device === task.id)
                  .map((label: any) => label.tournee),
                labels.disaffect
                  .filter((label: any) => label.device === task.id)
                  .map((label: any) => label.tournee)
              )
            )
          );
    }
  };
  return (
    <div className={"header-buttons"}>
      <div className="flex-box" style={{ width: "-moz-fit-content" }}>
        {task.rndCode && (
          <div
            id={`synchro-info-${task.id}`}
            className="clickable round"
            role="presentation"
            onClick={() =>
              history.push(
                `/gestionnaires/${GestionnaireId}/synchronisation/tournees/${task.rndCode}`
              )
            }
            style={{ marginRight: "5px" }}
          >
            <SvgInfoVert height="1.3em" style={{ marginTop: "2px" }} />
            <UncontrolledTooltip
              placement="bottom"
              target={`synchro-info-${task.id}`}
            >
              {t("all.round.go_to_round")}
            </UncontrolledTooltip>
          </div>
        )}
        <div
          id={`synchro-task-${task.id}`}
          className="clickable round"
          role="presentation"
          onClick={synchronisation}
          style={{ opacity: task.online || roundWithOnlineDevice ? 1 : 0.5 }}
        >
          <SvgSynchroHeader height="1.3em" style={{ marginTop: "2px" }} />
          <UncontrolledTooltip
            placement="bottom"
            target={`synchro-task-${task.id}`}
          >
            {t("all.text.synchro")}
          </UncontrolledTooltip>
        </div>
        {task.rndCode && _.get(tournee, "rotateOptions.active") && (
          <div
            id={`synchro-rotate-${task.id}`}
            className="clickable round"
            role="presentation"
            style={{ marginLeft: "5px" }}
          >
            <SvgRotationIndex height="1.3em" style={{ marginTop: "2px" }} />
            <UncontrolledTooltip
              placement="bottom"
              target={`synchro-rotate-${task.id}`}
            >
              {t("tournee_fiches.text.rotate_fiches")}
            </UncontrolledTooltip>
            <UncontrolledPopover
              trigger={"legacy"}
              placement="top"
              target={`synchro-rotate-${task.id}`}
            >
              <PopoverHeader style={{ color: "red" }}>
                &#x26A0; Rotation des index
              </PopoverHeader>
              <PopoverBody style={{ display: "grid" }}>
                <p>{t("tourneefiches.text.rotation_index_description")}</p>
                <p>{t("tourneefiches.text.rotation_index_warning")}</p>
                <div
                  style={{ justifyContent: "space-between", display: "flex" }}
                >
                  <Button color="danger" onClick={onRotationClick}>
                    {t("all.button.confirm")}
                  </Button>
                </div>
              </PopoverBody>
            </UncontrolledPopover>
          </div>
        )}
      </div>
    </div>
  );
};

function mapStateToProps(state: any) {
  const { synchrocom, tournee, synchro } = state;
  const { labels } = synchrocom;
  return {
    labels,
    tournee,
    synchro,
  };
}

const connectedButtons = compose<ComponentType<any>>(
  withRouter,
  connect(mapStateToProps)
)(TaskButtons);
const tr = withTranslation()(connectedButtons);
export default tr;
