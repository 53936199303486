import React, { useEffect, useState } from "react";
import { localeActions, supportActions, tourneeActions } from "../_actions";
import _ from "lodash";
import MultipleSelectInput from "./MultipleSelectInput";
import SelectInput from "./SelectInput";
import { AvForm } from "availity-reactstrap-validation";
import TextInput from "./TextInput";
import { translate } from "../_helpers";
import Switch from "./_component/Switch";
import EditionBleu from "../SvgComponents/EditionBleu";
import { InputBase, InputLabel } from "@material-ui/core";
import Loading from "../_animations/Loading";
import { Row } from "reactstrap";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";

const PropertyEditor: React.FC<{
  dispatch: any;
  locales: any;
  support: any;
  tournee: any;
  location: any;
  t: any;
  maxAuthorizedNbPath: string;
}> = ({
  dispatch,
  locales,
  support,
  tournee,
  location,
  t,
  maxAuthorizedNbPath,
}) => {
  const allowedInfos = ["export.export_silex_name"];
  const [init, setInit] = useState<boolean>(false);
  const [duplicateAuthorized, setDuplicateAuthorized] =
    useState<boolean>(false);
  const [properties, setProperties] = useState<{ [key: string]: string }>({});
  const withColumns = ["remotereading", "importbilling"].includes(
    _.last(location.pathname.split("/"))
  );
  const pathSupportDetail: string = _.last(location.pathname.split("/"));

  const handleChange = (evt: any, key: string) => {
    const { value } = evt.target;
    // Chelou mais obligé car si "false" value renvoie "true" et vice-versa
    setProperties({ ...properties, [key]: value });
  };

  const getSourceObjectForKey = (key: string) => {
    return (
      _.get(support, `${pathSupportDetail}.general.${key}`) ||
      _.get(support, `${pathSupportDetail}.column.${key}`)
    );
  };

  const checkIfType = (key: string, type: string) => {
    return _.isEqual(_.get(getSourceObjectForKey(key), "type"), type);
  };

  // Method with validity value input rules
  const valueIsValid = (value: any, type: string, key: string) => {
    let isValid: boolean;
    switch (type) {
      case "number":
        const valueIsInt = !isNaN(value);
        let isNbColValid = true;
        if (_.get(support, `${pathSupportDetail}.general.${key}`)) {
          isValid = _.toInteger(value) >= 0;
        } else {
          const maxAuthorizedNb = _.get(
            support,
            `${pathSupportDetail}.general.${maxAuthorizedNbPath}.oldValue`
          );
          if (!_.isEqual(key, maxAuthorizedNb) && !_.isEmpty(maxAuthorizedNb)) {
            isNbColValid =
              _.toInteger(value) >= 0 && _.toInteger(value) < maxAuthorizedNb;
          } else {
            isNbColValid = _.toInteger(value) >= 0;
          }
          const duplicate = duplicateAuthorized
            ? []
            : _.filter(
                properties,
                (v, k) =>
                  !(
                    _.keys(_.get(support, "generate.index")).includes(k) ||
                    _.keys(
                      _.get(support, `${pathSupportDetail}.general`)
                    ).includes(k)
                  ) &&
                  !_.isEqual(k, key) &&
                  v === value
              );
          isValid = valueIsInt && isNbColValid && _.isEmpty(duplicate);
        }

        break;
      default:
        isValid = true;
        break;
    }

    return isValid;
  };

  const generateSettingLine = (
    key: string,
    type: string,
    required: boolean
  ) => {
    const encodings = support.encodings || [];
    const encodingMethods = support.encodingMethods || [];
    const exportFormats = tournee.exportFormats;
    const importFormats = tournee.importFormats;
    const delimiters =
      (support.delimiters &&
        support.delimiters.map((delimiter: string) => ({
          key: delimiter,
          value: t(`support.option.${delimiter}`),
        }))) ||
      [];
    const frequencies = ["month", "week", "day"].map((frequency: string) => ({
      key: frequency,
      value: t(`support.option.${frequency}`),
    }));
    const itemsMap = new Map<string, Array<string>>();
    itemsMap.set("export_silex_encoding", encodings);
    itemsMap.set("export_silex_encoding_detection_method", encodingMethods);
    itemsMap.set("script.export.format", exportFormats);
    itemsMap.set("script.import.format", importFormats);
    itemsMap.set("script.export.encoding_source", encodings);
    itemsMap.set("script.import.encoding_source", encodings);
    itemsMap.set("script.export.encoding_output", encodings);
    itemsMap.set("script.import.encoding_output", encodings);
    itemsMap.set("script.export.delimiter", delimiters);
    itemsMap.set("script.import.delimiter", delimiters);
    itemsMap.set("generate.index.delay_min_without_read_type", frequencies);
    itemsMap.set("generate.index.delay_max_with_real_read_type", frequencies);
    itemsMap.set("generate.index.delay_max_deep_type", frequencies);
    const isCheckboxType = _.isEqual(type, "checkbox");
    const isNumber = _.isEqual(type, "number");
    const isTextType = _.isEqual(type, "text");
    const isDropdownType = _.isEqual(type, "dropdown");
    const isMultipleDropdownType = _.isEqual(type, "multipleDropdown");

    const value =
      (isMultipleDropdownType && typeof _.get(properties, key) === "string"
        ? JSON.parse(_.get(properties, key))
        : _.get(properties, key)) || null;
    let oldValue = _.get(
      Object.values(_.get(support, pathSupportDetail)).reduce(
        (concat: Object, catValue: Object) => ({ ...concat, ...catValue }),
        {}
      ),
      `["${key}"].oldValue`
    );

    if (oldValue && isMultipleDropdownType) {
      oldValue = JSON.parse(oldValue);
    }
    let hasChanged =
      _.toString(oldValue) !==
        (!(_.isNull(value) && _.isUndefined(value) && _.isEmpty(value))
          ? _.toString(value)
          : null) ||
      (oldValue && !value) ||
      (!oldValue && value);
    if (hasChanged === "0" || (value === null && oldValue === "false")) {
      hasChanged = false;
    }
    const isValid = _.isEmpty(value) ? null : valueIsValid(value, type, key);
    const isInvalid = _.isEmpty(value) ? null : !isValid;

    switch (true) {
      case isMultipleDropdownType:
        return (
          <div key={key} className="inputBlock">
            <MultipleSelectInput
              handleChange={(e: any) => handleChange(e, key)}
              text={t(
                translate(
                  "fr",
                  `support${_.capitalize(pathSupportDetail)}`,
                  key,
                  locales.locale
                )
              )}
              value={value || []}
              placeholder={t(
                translate(
                  "fr",
                  `support${_.capitalize(pathSupportDetail)}`,
                  key,
                  locales.locale
                )
              )}
              items={itemsMap.get(key) || []}
              isInvalid={isInvalid}
              isValid={isValid && hasChanged}
              hasChanged={hasChanged}
            />
          </div>
        );
      case isDropdownType:
        return (
          <div key={key} className="inputBlock">
            <SelectInput
              handleChange={(e: any) => handleChange(e, key)}
              text={t(`synchronisation.label.${key}`)}
              value={value}
              placeholder={t(`synchronisation.label.${key}`)}
              items={itemsMap.get(key) || []}
              isInvalid={isInvalid}
              isValid={isValid && hasChanged}
              hasChanged={hasChanged}
            />
          </div>
        );
      case isNumber:
      case isTextType:
        const info = allowedInfos.includes(pathSupportDetail + "." + key)
          ? "info." + pathSupportDetail + "." + key
          : null;
        return (
          <div key={key} className="inputBlock">
            <TextInput
              handleChange={(e: any) => handleChange(e, key)}
              text={t(
                translate(
                  "fr",
                  `support${_.capitalize(pathSupportDetail)}`,
                  key,
                  locales.locale
                )
              )}
              placeholder={t(
                translate(
                  "fr",
                  `support${_.capitalize(pathSupportDetail)}`,
                  key,
                  locales.locale
                )
              )}
              value={value}
              isInvalid={isInvalid}
              isValid={isValid && hasChanged}
              type={type}
              hasChanged={hasChanged}
              info={info && t(info)}
              infoKey={key}
            />
          </div>
        );
      case isCheckboxType:
        return (
          <div key={key} className="inputBlock">
            <div className="checkbox-container">
              <label>
                {t(
                  translate(
                    "fr",
                    `support${_.capitalize(pathSupportDetail)}`,
                    key,
                    locales.locale
                  )
                )}
              </label>
              <div className="flex-box">
                <Switch
                  handleCheck={(checked: boolean) =>
                    handleChange({ target: { value: checked } }, key)
                  }
                  check={value}
                />
                {hasChanged && (
                  <EditionBleu
                    height="16px"
                    fill={isInvalid ? "#ff2281" : "#38ef7d"}
                  />
                )}
              </div>
            </div>
          </div>
        );
      default:
        return (
          <div key={key} className="inputBlock">
            <div className="styled-input-select">
              <div className="form__group field">
                <InputLabel htmlFor="name" className={`form__label`}>
                  {t(
                    translate(
                      "fr",
                      `support${_.capitalize(pathSupportDetail)}`,
                      key,
                      locales.locale
                    )
                  )}
                </InputLabel>
                <InputBase
                  className={`form__field`}
                  placeholder={t(
                    translate(
                      "fr",
                      `support${_.capitalize(pathSupportDetail)}`,
                      key,
                      locales.locale
                    )
                  )}
                  value={value}
                  disabled
                  classes={{ input: "mui_input_text" }}
                />
              </div>
            </div>
          </div>
        );
    }
  };

  const checkIfValidField = () => {
    const invalidFields = document.getElementsByClassName("is-invalid");

    return !(_.size(_.defaultTo(invalidFields, [])) > 0);
  };

  const sendCustomSetting = () => {
    const setting: any = { general: {}, column: {} };

    const validForm = checkIfValidField();

    if (validForm) {
      _.map(_.get(support, pathSupportDetail), (catValue, catKey) => {
        _.map(catValue, (v, k) => {
          if (catKey === "column") {
            if (_.keys(properties).includes(k)) {
              setting.column[k] = properties[k];
            }
          } else {
            const isNumber = checkIfType(k, "number");
            const isArray = Array.isArray(_.get(properties, k));
            const isUnmutable = v.type === "unmutable";
            const value = _.get(properties, k);
            const intValue = _.defaultTo(parseInt(value), null);

            setting.general[k] = isUnmutable
              ? undefined
              : isNumber
              ? intValue
              : isArray
              ? JSON.stringify(value)
              : value;
          }
        });
      });

      switch (pathSupportDetail) {
        case "remotereading":
          dispatch(
            supportActions.sendRemoteReadingSetting(
              setting.general,
              setting.column
            )
          );
          break;
        case "export":
          dispatch(supportActions.sendExportSetting(setting.general));
          break;
        case "script":
          dispatch(supportActions.sendScriptSetting(setting.general));
          break;
        case "sync":
          dispatch(supportActions.sendSyncSetting(setting.general));
          break;
        case "general":
          dispatch(supportActions.sendCustomGeneralSetting(setting.general));
          break;
        case "generate":
          dispatch(supportActions.sendCustomGenerateSetting(setting.general));
          break;
        case "report":
          dispatch(supportActions.sendCustomReportSetting(setting.general));
          break;
      }
    }
  };

  const handleReset = () => {
    const newProperties = { ...properties };
    _.map(
      _.fromPairs(
        _.concat(
          _.toPairs(_.get(support, `generate.index`)),
          _.toPairs(_.get(support, `${pathSupportDetail}.general`)),
          _.toPairs(_.get(support, `${pathSupportDetail}.column`))
        )
      ),
      (v: any, k) => {
        const existingValue = _.get(properties, k);
        if (existingValue) {
          newProperties[k] = _.toString(v.oldValue);
        }
      }
    );
    setProperties(newProperties);
  };

  useEffect(() => {
    if (_.get(support, `${pathSupportDetail}`) && !init) {
      const newProperties = {};
      setInit(true);
      _.forEach(_.get(support, pathSupportDetail), (catValue, catKey) => {
        _.forEach(catValue, (v, k) => {
          if (catKey === "column") {
            if (_.isNumber(v.oldValue)) {
              newProperties[k] = v.oldValue.toString();
            }
          } else {
            let value = v.oldValue && v.oldValue.toString();
            if (_.isEqual(v.type, "checkbox")) value = v.oldValue === "true";
            if (v.oldValue) newProperties[k] = value;
          }
        });
      });
      setProperties(newProperties);
    }
  });

  useEffect(() => {
    dispatch(localeActions.load());
    switch (pathSupportDetail) {
      case "export":
        dispatch(supportActions.getExportSetting());
        dispatch(supportActions.getEncodings());
        dispatch(supportActions.getEncodingDetectionMethods());
        break;
      case "script":
        dispatch(supportActions.getScriptSetting());
        dispatch(tourneeActions.getOptionsExportFormats());
        dispatch(tourneeActions.getOptionsImportFormats());
        dispatch(supportActions.getEncodings());
        dispatch(supportActions.getSilexDelimiters());
      case "remotereading":
        dispatch(supportActions.getGeneralRemoteReadingSetting());
        dispatch(supportActions.getColumnRemoteReadingSetting());
        break;
      case "sync":
        dispatch(supportActions.getSyncSetting());
        break;
      case "report":
        dispatch(supportActions.getCustomReportSetting());
        break;
      case "general":
        dispatch(supportActions.getCustomGeneralSetting());
        break;
      case "generate":
        dispatch(supportActions.getCustomGenerateSetting());
        break;
    }
  }, []);

  return !_.isEmpty(locales) &&
    support &&
    !support.loading &&
    _.get(support, pathSupportDetail) &&
    init ? (
    <div className="col-md-12 support-import-billing">
      <div className="wrapper">
        <div className="title">
          <h2>{t(`support.title.${pathSupportDetail}`)}</h2>
          <button className="flash-button reset" onClick={handleReset}>
            Réinitialisation
          </button>
        </div>
        <AvForm
          onValidSubmit={sendCustomSetting}
          className={`custom-setting-container ${pathSupportDetail}-wrapper`}
          model={properties}
        >
          {/** Switch dont l'utilité est inconnue. Actuellement caché */}
          <Row style={{ margin: 0, display: "none" }}>
            <h3>Configuration </h3>
            <div className="duplicateAuth form-check">
              <Switch handleCheck={setDuplicateAuthorized} />
            </div>
          </Row>
          <div className="main-container">
            {_.map(_.get(support, pathSupportDetail), (catValue, catKey) => (
              <>
                <div className="block">
                  <h4 className="neon second">
                    {t(`support.title.${catKey}`)}
                  </h4>
                  <div className="allBlockInput">
                    {_.map(catValue, (v, k) =>
                      generateSettingLine(k, v.type, v.mandatory)
                    )}
                  </div>
                </div>
              </>
            ))}
          </div>
          <div>
            <button className="flash-button submit">Envoyer</button>
          </div>
        </AvForm>
      </div>
    </div>
  ) : (
    <Loading />
  );
};

function mapStateToProps(state: any) {
  const { authentication, users, locales, support, tournee } = state;
  const { user } = authentication;
  return {
    user,
    users,
    locales,
    support,
    tournee,
  };
}

const wt: any = connect(mapStateToProps)(PropertyEditor);
export default withTranslation()(withRouter(wt));
