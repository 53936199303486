/* eslint-disable react/require-default-props */

import React, { Fragment, useEffect, useRef, useState } from "react";
import DigitRoll from "digit-roll-react";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import NoFilled from "../Message/NoFilled";
import SvgCalendrierVert from "../SvgComponents/CalendrierVert";
import NoPresent from "../Message/NoPresent";
import SvgValidation from "../SvgComponents/ValidationVert";
import SvgCroixNoir from "../SvgComponents/croixNoir";
import EditableRoll from "./EditableRoll2";
import EditionBleu from "../SvgComponents/EditionBleu";
import StepPopover from "../_components/StepPopover";
import _ from "lodash";
import SvgPoubelleBleu from "../SvgComponents/PoubelleBleu";
import { tourneeActions } from "../_actions";

type Props = {
  indexType: string;
  readMethod: string;
  wheels: number;
  date: string;
  indexNumber: number;
  t: Function;
  changeInputIndex: Function;
  changeCurrentEditField: Function;
  updateIndex: Function;
  minDate: any;
  isIndexBelow?: () => boolean;
  dispatch: Function;
};

export default function IndexWrapped(props: any) {
  const {
    indexType,
    readMethod,
    wheels,
    date,
    indexNumber,
    t,
    changeInputIndex,
    changeCurrentEditField,
    updateIndex,
    minDate,
    isIndexBelow,
    deleteIndex,
  } = props;

  const [edit, setEdit] = useState(false);
  const [validIndex, setValidIndex] = useState(indexNumber);
  const [index, setIndex] = useState(indexNumber);
  const [inputDate, setInputDate] = useState(date);
  const [open, setOpen] = useState<boolean>(false);
  const [openDeletePopover, setOpenDeletePopover] = useState<boolean>(false);
  const [hasWrongInput, setWrongInput] = useState<boolean>(false);

  const currentIndex = useRef(index);
  const currentEdit = useRef(edit);

  useEffect(() => {
    setInputDate(
      date && date.length > 0
        ? date
        : inputDate != null
        ? inputDate
        : new Date()
    );
  }, [date]);

  useEffect(() => {
    currentIndex.current = index;
  }, [index]);

  useEffect(() => {
    currentEdit.current = edit;
  }, [edit]);

  useEffect(() => {
    setIndex(indexNumber);
    setValidIndex(indexNumber);
  }, [indexNumber]);

  const onEdit = () => {
    const now = new Date();
    setInputDate(now);
    changeCurrentEditField("index");
    changeInputIndex({ index, date: now });
    setEdit(true);
  };

  const onValid = (currentIndex: number) => {
    if (isIndexBelow && isIndexBelow() && !open) {
      setOpen(true);
    } else {
      updateIndex();
      setValidIndex(currentIndex || index);
      setEdit(false);
      setOpen(false);
    }
  };

  const handleIndex = (newIndex) => {
    if (isNaN(newIndex)) {
      setWrongInput(true);
    } else {
      setWrongInput(false);
      setIndex(newIndex);
      changeInputIndex({ index: newIndex, date: inputDate });
    }
  };
  const handleDelete = () => {
    deleteIndex(inputDate, index);
    setOpenDeletePopover(false);
  };

  const handleDate = (e) => {
    const copyDate = new Date(_.cloneDeep(e.target.value));
    setInputDate(copyDate);
    changeInputIndex({ index, date: copyDate });
  };

  useEffect(() => {
    window.addEventListener("keydown", (e: any) => {
      if (e.keyCode === 27) {
        setEdit(false);
      }
      if (
        e.keyCode === 13 &&
        typeof updateIndex === "function" &&
        currentEdit.current
      ) {
        onValid(currentIndex.current);
      }
    });
  }, []);
  return (
    <Fragment>
      <>
        <div className="row-meter-bar" style={{ padding: 0, width: "100%" }}>
          <div
            className="clean-meter-bar indexWrapped"
            style={{ margin: 0, height: "fit-content" }}
          >
            <div className="flex-box">
              <div
                className="c20 background-texture left"
                style={{ padding: "20px 2%" }}
              >
                <h3 className="flex-box center">{indexType}</h3>
              </div>
              <div
                className="c20 b-right"
                style={{ width: "20%", padding: "20px 2%" }}
              >
                <div className="flex-box center">
                  {readMethod ? <> </> : <> </>}
                  <div>
                    <span>{t("index_wrapped.label.read")}</span>
                    {readMethod ? (
                      <h3>
                        {readMethod === "A"
                          ? t("index_wrapped.read_method.radio")
                          : readMethod === "M"
                          ? t("index_wrapped.read_method.manual")
                          : readMethod === "T"
                          ? "Telereleve"
                          : ""}
                      </h3>
                    ) : (
                      <NoFilled />
                    )}
                  </div>
                </div>
              </div>
              <div
                className="c20 b-right"
                style={{
                  width: edit ? "auto" : "20%",
                  padding: "20px 2%",
                  margin: 0,
                }}
              >
                <div className="flex-box center">
                  <div className="centerAlign">
                    <SvgCalendrierVert
                      height="2em"
                      width="2em"
                      stroke="#31c6b3"
                      fill="#31c6b3"
                      strokeWidth="0"
                    />{" "}
                  </div>
                  <div
                    className="centerAlign"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <span>{t("index_wrapped.label.date")}</span>
                    {edit ? (
                      <TextField
                        id="date"
                        type="date"
                        className="mui-input date"
                        onChange={handleDate}
                        value={
                          inputDate && inputDate !== "-"
                            ? moment(inputDate, "DD/MM/YYYY").format(
                                "YYYY-MM-DD"
                              )
                            : moment().format("YYYY-MM-DD")
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          inputProps: {
                            min: moment(minDate).format("YYYY-MM-DD"),
                            max: moment().format("YYYY-MM-DD"),
                          },
                        }}
                      />
                    ) : index ? (
                      <h3>{moment(date).format("DD/MM/YYYY").toString()}</h3>
                    ) : (
                      <NoFilled />
                    )}
                  </div>
                </div>
              </div>
              <div
                className="c20 b-right"
                style={{ width: "40%", padding: "20px 2%", border: 0 }}
              >
                <div className="flex-box center">
                  <div
                    className={`centerAlign ${hasWrongInput && "wrongRoll"}`}
                    style={{ paddingRight: "40px", position: "relative" }}
                  >
                    {edit ? (
                      <EditableRoll
                        index={validIndex}
                        changeInputIndex={handleIndex}
                        wheels={wheels}
                      />
                    ) : Number.isInteger(validIndex) ? (
                      <DigitRoll num={validIndex} length={wheels} divider="" />
                    ) : (
                      <NoPresent
                        msg={t("index_wrapped.no_data.index_not_present")}
                        style={{ paddingTop: "20px" }}
                      />
                    )}
                    {changeInputIndex && (
                      <div
                        style={{
                          position: "absolute",
                          top: "50%",
                          right: 0,
                          transform: "translateY(-50%)",
                        }}
                      >
                        {edit ? (
                          <div
                            className={
                              open
                                ? "button-group button-group-disabled"
                                : "button-group"
                            }
                          >
                            <div
                              className="round-svg"
                              onClick={(e) => {
                                e.stopPropagation();
                                if (!open) setEdit(false);
                              }}
                            >
                              <SvgCroixNoir height="1.7em" />
                            </div>
                            <div
                              id="validate-index-button"
                              className="round-svg"
                              onClick={(e) => {
                                e.stopPropagation();
                                if (!open) onValid(index);
                              }}
                            >
                              <SvgValidation height="1em" />
                            </div>
                            <StepPopover
                              id="confirmManualPopover"
                              target="validate-index-button"
                              open={open}
                              onClose={() => setOpen(false)}
                              permission
                              action={() => onValid(index)}
                              title={t("index_wrapped.popover.title")}
                              confirmText={t(
                                "index_wrapped.popover.confirm_text"
                              )}
                              confirmButtonText={t("all.button.confirm")}
                            />
                          </div>
                        ) : (
                          <div className="round-svg" onClick={() => onEdit()}>
                            <EditionBleu height="1em" />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  {readMethod == "M" &&
                    indexType &&
                    indexType.includes("courant") && (
                      <div
                        id={"delete-index-button"}
                        className="deleteCurrentRead"
                        onClick={(e) => {
                          e.stopPropagation();
                          setOpenDeletePopover(true);
                          setEdit(false);
                          setOpen(false);
                        }}
                      >
                        <SvgPoubelleBleu height="1em" width="1em" />
                        <StepPopover
                          id="confirmReadDeletePopover"
                          target="delete-index-button"
                          open={openDeletePopover}
                          onClose={() => setOpenDeletePopover(false)}
                          permission
                          action={() => handleDelete()}
                          title={t("confirm.title.warning")}
                          confirmText={t(
                            "index_wrapped.popover.confirm_deletion_text"
                          )}
                          confirmButtonText={t("all.button.delete")}
                        />
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </Fragment>
  );
}
