import React from "react";
import EditionBleu from "../SvgComponents/EditionBleu";
import InformationsBleu from "../SvgComponents/InformationsBleu";
import { UncontrolledTooltip } from "reactstrap";

const TextInput = (props: any) => {
  const {
    handleChange,
    type,
    placeholder,
    text,
    value,
    isInvalid,
    isValid,
    hasChanged,
    info,
    infoKey,
  } = props;
  return (
    <div className="styled-input">
      <div className="form__group field">
        <input
          type={type}
          className={`form__field ${isInvalid ? "error" : ""} ${
            isValid ? "valid" : ""
          }`}
          placeholder={placeholder}
          required
          onChange={(e: any) => handleChange(e)}
          value={value}
        />
        <label
          htmlFor="name"
          className={`form__label ${isInvalid ? "error" : ""} ${
            isValid ? "valid" : ""
          }`}
        >
          {text}
          {hasChanged && (
            <EditionBleu
              height="16px"
              fill={isInvalid ? "#ff2281" : "#38ef7d"}
            />
          )}
        </label>
        {info && (
          <div style={{ position: "absolute", top: "25px", right: "0" }}>
            <span id={`info-${infoKey}`}>
              <InformationsBleu
                className="add"
                stroke="#808080"
                height="1.5em"
                width="1.5em"
                strokeWidth="1.2"
              />
            </span>
            <UncontrolledTooltip
              placement="bottom"
              target={`info-${infoKey}`}
              style={{ maxWidth: "500px", textAlign: "left" }}
            >
              <div dangerouslySetInnerHTML={{ __html: info }} />
            </UncontrolledTooltip>
          </div>
        )}
      </div>
    </div>
  );
};

export default TextInput;
