import React, { useState, useEffect } from "react";
import SvgTourneeLoad from "../../../SvgComponents/TourneeLoad";
import SvgTourneeWaitAffect from "../../../SvgComponents/TourneeWaitAffect";
import SvgTourneeWaitDeaffect from "../../../SvgComponents/TourneeWaitDeaffect";
import SvgCroixNoir from "../../../SvgComponents/croixNoir";
import SvgWarning from "../../../SvgComponents/SvgWarning";
import SvgPartielVert from "../../../SvgComponents/PartielVert";
import BestSwitch from "../../BestSwitch";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import synchrocomActions from "../../../_actions/synchrocom.actions";

const BestLabel = (props) => {
  const { label, isRound, loaded, synchro, dispatch, match, err, t } = props;
  const { params } = match;
  const { GestionnaireId } = params;
  const [hover, setHover] = useState(false);
  const [name, setName] = useState(null);
  const [error, setError] = useState(err);

  useEffect(() => {
    setName(
      isRound
        ? synchro?.devices[label.device]?.content
        : synchro?.tourneeRessources[label.tournee]?.content
    );
  }, [label]);

  useEffect(() => {
    setError(err);
  }, [err]);

  const getInfo = (type) => {
    if (err)
      return {
        svg: <SvgWarning height="1.5em" fill="white" />,
        color:
          "repeating-linear-gradient(135deg,#bf272d,#bf272d 10px,#ad1c2d 10px,#ad1c2d 20px)",
      };
    switch (type) {
      case "loaded":
        return {
          color: "#716AB4",
          svg: <SvgTourneeLoad height="1.5em" fill="white" />,
        };
      case "affect":
        return {
          color: "#31c6b3",
          svg: <SvgTourneeWaitAffect height="1.5em" fill="white" />,
        };
      default:
        return {
          color: "#dc3545",
          svg: <SvgTourneeWaitDeaffect height="1.5em" fill="white" />,
        };
    }
  };

  const removeLabel = (e) => {
    e.preventDefault();
    if (loaded) {
      dispatch(
        synchrocomActions.addDesaffect(
          [label.tournee],
          GestionnaireId,
          label.device
        )
      );
    } else {
      label.type === "affect"
        ? dispatch(
            synchrocomActions.delAffect(
              [label.tournee],
              GestionnaireId,
              label.device
            )
          )
        : dispatch(
            synchrocomActions.delDesaffect(
              [label.tournee],
              GestionnaireId,
              label.device
            )
          );
    }
  };

  const switchPartial = (isPartial) => {
    dispatch(
      synchrocomActions.switchToPartiel(
        GestionnaireId,
        label.device,
        [label.tournee],
        isPartial
      )
    );
  };
  const info = getInfo(label.type);
  return (
    <div
      className="label-container"
      style={{
        background: info.color,
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className="left">
        {info.svg}
        <span>{name}</span>
        <span>{isRound ? label.device : label.tournee}</span>
      </div>
      <div className="right">
        {label.partialOption && (
          <div style={{ marginRight: "8px" }}>
            <SvgPartielVert height="1.5em" fill="#fff" />
          </div>
        )}
        <div onClick={(e) => removeLabel(e)}>
          <SvgCroixNoir height="1.5em" fill="white" />
        </div>
      </div>
    </div>
  );
};
//label.type === 'affect'
function mapStateToProps(state) {
  const { synchrocom, synchro } = state;
  return {
    synchrocom,
    synchro,
  };
}

const mapping = connect(mapStateToProps)(BestLabel);

const connectedTask = withRouter(mapping);
const tr = withTranslation()(connectedTask);
export default tr;
