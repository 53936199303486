import React from "react";

const SvgSynchroComplete = (props: any) => (
  <svg viewBox="0 0 93.1 36.5" {...props}>
    <g>
      <path
        fill={props.fill}
        d="M89.2.6h-13.5c-1.7,0-2.9,1.3-2.9,3.2v29.2c0,1.8,1.2,3,2.9,3h13.5c1.8,0,3.2-1.3,3.2-3V3.7c0-1.7-1.5-3.2-3.2-3.2ZM75.7,2.6h13.5c.6,0,1.2.5,1.2,1.2v1s0,0,0,0h-15.6v-1c0-.1,0-1.2.9-1.2ZM89.4,6.7v22.7h-13.8V6.7h13.8ZM74.8,33v-1.6h7.8c-.7,0-1.2.6-1.2,1.3s.6,1.3,1.2,1.3h-6.9c-.7,0-.9-.6-.9-1ZM89.2,34h-6.5c.7,0,1.2-.6,1.2-1.3s-.5-1.2-1.2-1.3h7.7s0,0,0,0v1.6c0,.7-.6,1-1.2,1Z"
      />
      <path
        fill={props.fill}
        d="M79.5,3.6h6.5c.2,0,.3.1.3.3h0c0,.2-.1.3-.3.3h-6.5c-.2,0-.3-.1-.3-.3h0c0-.2.1-.3.3-.3Z"
      />
    </g>
    <g>
      <g id="Calque_1-2" data-name="Calque 1">
        <path
          fill={props.fill}
          d="M24.3,36H2.6c-1,0-1.9-.9-1.9-1.9V7.9c0-.5.2-1,.6-1.4.2-.3.6-.5.9-.6L19,.6c.6-.2,1.2,0,1.7.3.5.4.8.9.8,1.5v3.5h2.9c1,0,1.9.9,1.9,1.9v26.2c0,1-.9,1.9-1.9,1.9ZM19.5,2.2s0,0,0,0L2.7,7.5s-.2.2-.3.2c0,0,0,0,0,.2v26.2c0,.1,0,.2.2.2h21.7c.1,0,.2-.1.2-.2V7.9c0-.1,0-.2-.2-.2h-3.7c-.5,0-.8-.4-.8-.8V2.5c0,0,0-.1,0-.2,0,0,0,0-.1,0Z"
        />
      </g>
      <polygon
        fill={props.fill}
        points="18.8 6.8 8.1 6.8 18.8 3.8 18.8 6.8 18.8 6.8"
      />
    </g>
    <path
      fill={props.fill}
      d="M41.7,19.2h11.9s-2.5,2.5-2.5,2.5c-.4.4-.4,1,0,1.4h0c.4.4,1,.4,1.4,0l4.2-4.2c.4-.4.4-1,0-1.4l-4.2-4.2c-.4-.4-1-.4-1.4,0h0c-.4.4-.4,1,0,1.4l2.5,2.5h-11.9c-.5,0-1,.4-1,1h0c0,.5.4,1,1,1Z"
    />
  </svg>
);

export default SvgSynchroComplete;
